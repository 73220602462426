import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { VehicleOrigin } from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useGenerateDataOneReport = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();

  function generateDataOneReport(vehicleId: string): Promise<adminApiv1Pb.GenerateDataOneResponse> {
    const request = new adminApiv1Pb.GenerateDataOneRequest();
    request.setVehicleId(vehicleId);
    request.setVehicleOrigin(VehicleOrigin.VEHICLE_ORIGIN_CONSUMER);
    return client.generateDataOne(request, authHeader);
  }

  const generateDataOneReportFn = async (vehicleId: string) => {
    if (!isConnectionReady) return;
    return generateDataOneReport(vehicleId);
  };

  return generateDataOneReportFn;
};
