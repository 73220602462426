import React, { useEffect } from 'react';

import { DateSelectOverride, DisableDateTimeItems, TimeProps } from '../../composables/utils/types';
import useDateSelect from '../../composables/utils/useDateSelect';
import DayInput from './inputs/DayInput';
import HourInput from './inputs/HourInput';
import MinuteInput from './inputs/MinuteInput';
import MonthInput from './inputs/MonthInput';
import YearInput from './inputs/YearInput';

type DateSelectorProps = {
  setTime?: React.Dispatch<React.SetStateAction<string>>;
  defaultDateTime?: DateSelectOverride;
  disableItems?: DisableDateTimeItems;
  setTimeProps?: React.Dispatch<React.SetStateAction<TimeProps>>;
};

function DateSelector({ setTime, defaultDateTime, disableItems, setTimeProps }: DateSelectorProps) {
  const [values, events] = useDateSelect(defaultDateTime);
  const { year, month, day, hour, minute, nano } = values;
  const { handleDayChange, handleHourChange, handleMinuteChange, handleMonthChange, handleYearChange } = events;

  useEffect(() => {
    setTime && setTime(nano);
    setTimeProps && setTimeProps(values);
  }, [nano]);

  return (
    <>
      <YearInput year={year} handleYearChange={handleYearChange} isDisabled={disableItems?.year} />
      <MonthInput month={month} handleMonthChange={handleMonthChange} isDisabled={disableItems?.month} />
      <DayInput day={day} handleDayChange={handleDayChange} isDisabled={disableItems?.day} />
      <HourInput hour={hour} handleHourChange={handleHourChange} isDisabled={disableItems?.hour} />
      <MinuteInput minute={minute} handleMinuteChange={handleMinuteChange} isDisabled={disableItems?.minute} />
    </>
  );
}

export default DateSelector;
