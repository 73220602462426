import React from 'react';
import { IconType } from 'react-icons';
import { RiUpload2Fill } from 'react-icons/ri';

import styles from '../../scss/FileInput.module.scss';

interface FileInputProps {
  display: string;
  displayIcon?: IconType;
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  acceptedType?: string;
  disabled?: boolean;
}
function FileInput({ display, displayIcon, onClick, onChange, fileInputRef, acceptedType, disabled }: FileInputProps) {
  const defaultAcceptedTypes = 'image/jpeg,image/png,application/pdf';

  return (
    <>
      <button className={styles.fileUpload} onClick={onClick} aria-label="Upload">
        <div>
          {displayIcon || <RiUpload2Fill />}
          <span>{display}</span>
        </div>
      </button>
      <input
        id="fileUpload"
        ref={fileInputRef}
        type="file"
        onChange={onChange}
        accept={acceptedType || defaultAcceptedTypes}
        hidden
        multiple
        disabled={disabled}
      />
    </>
  );
}

export default FileInput;
