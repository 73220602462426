import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { AutoGroup } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useAutoGroups = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [autoGroups, setAutoGroups] = useState([] as AutoGroup[]);

  async function getAutoGroups() {
    if (!isConnectionReady) return null;

    const request = new adminApiv1Pb.ListAutoGroupsRequest();
    const response = await client.listAutoGroups(request, authHeader);
    setAutoGroups(response.toObject().autoGroupsList || []);
  }

  useEffect(() => {
    try {
      getAutoGroups();
    } catch (error) {
      console.error('getDealersByPage:', error);
    }
  }, [isConnectionReady]);

  return { getAutoGroups, autoGroups };
};
