import { useEffect, useState } from 'react';

import { Doc } from '../../composables/api/types';
import style from '../../scss/DocumentLinks.module.scss';

type DocumentProps = {
  doc: Doc;
};

export default function ViewDocuments({ doc }: DocumentProps) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    init();
  }, [doc]);

  async function init() {
    setUrl('');
    setUrl(await doc.fetchUrl());
  }

  function renderContents() {
    if (!url) {
      return <p style={{ textAlign: 'center' }}>Loading...</p>;
    }

    if (doc.isPdf) {
      return <iframe className={style.pdf} title={doc.label} src={url} />;
    }

    return <img className={style.image} src={url} alt={`${doc.label}`} />;
  }

  return renderContents();
}
