import moment from 'moment';
import { useEffect } from 'react';

import { DateSelectOverride } from './types';
import useInput from './useInput';

function useDateSelect(overrideDateTime?: DateSelectOverride) {
  const [year, { handleChange: handleYearChange, setInputVal: setInitialYear }] = useInput();
  const [month, { handleChange: handleMonthChange, setInputVal: setInitialMonth }] = useInput();
  const [day, { handleChange: handleDayChange, setInputVal: setInitialDay }] = useInput();
  const [hour, { handleChange: handleHourChange, setInputVal: setInitialHour }] = useInput();
  const [minute, { handleChange: handleMinuteChange, setInputVal: setInitialMinute }] = useInput();
  const [nano, { setInputVal: setNano }] = useInput();

  const getNanoTime = (): number => {
    const stringTime = moment(new Date(+year, +month - 1, +day, +hour, +minute)).format('x');
    return +stringTime * 1000000;
  };

  useEffect(() => {
    const now = moment().format('YYYY/MM/DD/HH/mm').split('/');
    const fns = [setInitialYear, setInitialMonth, setInitialDay, setInitialHour, setInitialMinute];
    const overrideValues = overrideDateTime
      ? [
          overrideDateTime.year,
          overrideDateTime.month,
          overrideDateTime.day,
          overrideDateTime.hour,
          overrideDateTime.minute,
        ]
      : [];

    now.forEach((timeElm, index) => {
      const newValue = overrideValues[index] || timeElm;
      return fns[index](newValue);
    });
  }, []);

  useEffect(() => {
    setNano(getNanoTime().toString());
  }, [year, month, day, hour, minute]);

  return [
    { year, month, day, hour, minute, nano },
    { handleYearChange, handleMonthChange, handleDayChange, handleHourChange, handleMinuteChange },
  ] as const;
}

export default useDateSelect;
