import { Link, useParams } from 'react-router-dom';

import { VehicleOption } from '../../composables/api/types';
import styles from '../../scss/VehicleOptionsListField.module.scss';
import { BasicFieldProps } from './types';

export default function VehicleOptionsListField({ propName }: BasicFieldProps<VehicleOption[]>) {
  const urlParams = useParams();

  return (
    <tr key={propName}>
      <td>{propName}</td>
      <td>
        <Link className={styles.link} to={`/vehicle-details/1/${urlParams.id}/options`}>
          See Options Tab
        </Link>
      </td>
    </tr>
  );
}
