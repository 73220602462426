import React from 'react';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import CreateOffer from '../dealTank/CreateOffer';

type CreateDealTankOfferProps = {
  vehicleId: string;
};

function CreateDealTankOfferButton({ vehicleId }: CreateDealTankOfferProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const openDrawer = async () => {
    openUtilityDrawer(<CreateOffer vehicleId={vehicleId} />);
  };

  return <BaseButtonOutline small display="Create Offer" onClick={openDrawer} />;
}

export default CreateDealTankOfferButton;
