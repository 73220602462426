import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as searchentityPb from '../../grpc/build/protos/searchentity_pb';
import { useApiReady } from '../utils/useApiReady';
import { Dealer, GetDealersByNameProps } from './types';
import { useAuthHeader } from './useAuthHeader';

interface GetDealersProps {
  defaultPageSize?: number;
  getAllByDefault?: boolean;
}

export const useGetDealers = ({ defaultPageSize, getAllByDefault }: GetDealersProps) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [dealers, setDealers] = useState([] as Dealer[]);
  const [dealerCount, setDealerCount] = useState(0);

  async function getAllDealers() {
    if (!isConnectionReady) return null;

    const request = new adminApiv1Pb.ListDealersRequest();
    const pagination = new adminApiv1Pb.Pagination();
    pagination.setOffset(0);
    pagination.setPageSize(defaultPageSize || 20);
    request.setPagination(pagination);
    const response = await client.listDealers(request, authHeader);
    setDealers(response.toObject().dealers?.dealersList || []);
    setDealerCount(response.toObject().total || 0);
  }

  async function getDealersWithConditions({ name, pageSize, pageNum, dateStart, dateEnd }: GetDealersByNameProps) {
    if (!isConnectionReady) return null;

    const request = new adminApiv1Pb.ListDealersRequest();
    const pagination = new adminApiv1Pb.Pagination();
    pagination.setOffset(pageNum || 0);
    pagination.setPageSize(pageSize || 20);
    request.setPagination(pagination);
    const filter = new searchentityPb.DealerUserFilter();
    filter.setLoginDateStart(dateStart || 0);
    filter.setLoginDateEnd(dateEnd || 0);
    request.setFilter(filter);

    if (name) {
      const searchTerm = new adminApiv1Pb.DealerSearchRequest();
      searchTerm.setName(name);
      request.setDealerSearchRequest(searchTerm);
    }

    const response = await client.listDealers(request, authHeader);
    setDealers(response.toObject().dealers?.dealersList || []);
    setDealerCount(response.toObject().total || 0);
  }

  useEffect(() => {
    try {
      getAllByDefault
        ? getAllDealers()
        : getDealersWithConditions({
            name: '',
            pageNum: 0,
            pageSize: defaultPageSize || 20,
            dateStart: 0,
            dateEnd: 0,
          });
    } catch (error) {
      console.error('getDealersByPage:', error);
    }
  }, [isConnectionReady]);

  return { dealers, dealerCount, getDealersWithConditions, getAllDealers };
};
