import React, { useState } from 'react';

import { useCreateDealTankOffer } from '../../composables/api/useCreateDealTankOffer';
import { useGetDealers } from '../../composables/api/useGetDealers';
import { Currency } from '../../grpc/build/protos/adminapiv1_pb';
import enumPb from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/CompleteSale.module.scss';
import dtStyles from '../../scss/DealTankInfo.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';
import NumInput from '../utils/inputs/NumInput';
import TextInput from '../utils/inputs/TextInput';
import RadioButtons from '../utils/RadioButtons';

type CreateOfferProps = {
  vehicleId: string;
};

interface Message {
  isCreated: boolean;
  text: string;
}

export default function CreateOffer({ vehicleId }: CreateOfferProps) {
  const defaultPageSize = 250;
  const [message, setMessage] = useState<Message | null>(null);
  const { pending, currency, currencyOptions, setCurrency, setPrice, price, setBuyerId, buyerId, createDealTankOffer } =
    useCreateDealTankOffer();
  const { dealers, getDealersWithConditions } = useGetDealers({ defaultPageSize });

  async function handleGetDealersByName(name: string, page = 1) {
    try {
      await getDealersWithConditions({
        name,
        pageSize: defaultPageSize,
        pageNum: page,
      });
    } catch (error) {
      console.error('handleGetDealersByName ', error);
    }
  }

  async function handleCreateOffer() {
    try {
      await createDealTankOffer(vehicleId, {
        price: +price,
        currency:
          currency == Currency.CURRENCY_USD
            ? enumPb.Currency.CURRENCY_CURRENCY_USD
            : enumPb.Currency.CURRENCY_CURRENCY_CAD,
        buyerId: buyerId,
      });

      setMessage({
        isCreated: true,
        text: 'Success! You may see the offer in consumer dashboard / dealer buying page',
      });
    } catch (error) {
      console.error('Create offer failed : ', error);

      setMessage({
        isCreated: false,
        text: 'Create offer unsuccessful. This vehicle might have completed a sale already.',
      });
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Create Offer</h3>

      <TextInput name={'vehicleId'} value={vehicleId} disabled />
      <section className={dtStyles.priceSection}>
        <NumInput
          defaultMin={0}
          value={`${price}`}
          label="Asking Price"
          invalid={price === 0}
          handleChange={(e) => handlePriceChange(e.target.valueAsNumber)}
        />
        <p className={styles.valueWithComma}>${price.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
      </section>

      <div className={styles.miscContainer}>
        <label htmlFor="currency">Currency</label>
        <div id="currency">
          <RadioButtons options={currencyOptions} currentSelected={currency} onClick={setCurrency} />
        </div>
      </div>

      <BaseSearchInput placeholder="Search Dealers by Keyword" onSearch={handleGetDealersByName} />
      <div className={styles.miscContainer}>
        <BaseExpandSelect label="Dealer" selectEvent={setBuyerId} options={dealers} selectedValue={buyerId} />
      </div>

      <BaseButtonOutline
        display="Create Offer"
        onClick={handleCreateOffer}
        small
        className={styles.createSaleBtn}
        disabled={!+price || !buyerId || pending}
      />

      {!!message && <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>}
    </div>
  );

  function handlePriceChange(val: number) {
    if (!isNaN(val)) {
      setPrice(val);
    } else {
      setPrice(0);
    }
  }
}
