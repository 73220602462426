import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as codePb from '../../grpc/build/protos/code_pb';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import { WayOfSale } from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { OfferDocumentObject } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useOfferDocuments = (vehicleId: string, offerId: string, wayOfSale: WayOfSale) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [docs, setDocs] = useState([] as OfferDocumentObject[]);
  const [isConnectionReady] = useApiReady();
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(true);

  async function listOfferDocuments() {
    if (!isConnectionReady) return;

    setLoading(true);

    try {
      const request = new adminApiv1Pb.ListOfferDocumentsRequest();
      request.setVehicleId(vehicleId);
      const response = await client.listOfferDocuments(request, authHeader);
      const docRes = response.toObject().offerDocuments?.offerDocumentsList || [];
      setDocs(docRes);
    } catch (error) {
      setDocs([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    listOfferDocuments();
  }, [isConnectionReady, vehicleId]);

  async function createOfferDocument(dealershipId?: string) {
    if (!isConnectionReady) return;

    setPending(true);
    try {
      const request = new adminApiv1Pb.SendOfferDocumentRequest();
      request.setVehicleId(vehicleId);
      request.setWayOfSale(wayOfSale);
      request.setOfferId(offerId);
      dealershipId && request.setDealershipId(dealershipId);

      const res = await client.sendOfferDocument(request, authHeader);
      const obj = res.toObject();
      if (
        obj.code !== codePb.Code.OK ||
        obj.offerDocument?.documentStatus !== enumPb.DocumentStatus.DOCUMENT_STATUS_SENT
      ) {
        throw new Error('Fail to send Buy-in Agreement');
      }

      return obj.offerDocument;
    } finally {
      setPending(false);
    }
  }

  function getOfferDocument() {
    if (!isConnectionReady) return;

    const request = new adminApiv1Pb.GetOfferDocumentRequest();
    request.setReferenceId(vehicleId);
    return client.getOfferDocument(request, authHeader);
  }

  async function cancelOfferDocument(offerDocumentId: string) {
    if (!isConnectionReady) return;

    setPending(true);
    try {
      const request = new adminApiv1Pb.CancelOfferDocumentRequest();
      request.setOfferDocumentId(offerDocumentId);

      const res = await client.cancelOfferDocument(request, authHeader);
      const obj = res.toObject();
      if (obj.code !== codePb.Code.OK) {
        throw new Error('Failed to cancel Buy-In Agreement');
      }

      return obj;
    } finally {
      setPending(false);
    }
  }

  return {
    docs,
    loading,
    pending,
    createOfferDocument,
    cancelOfferDocument,
    getOfferDocument,
    listOfferDocuments,
  };
};
