import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as entityPb from '../../grpc/build/protos/entities_pb';
import { Currency } from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { Vehicle, VehicleWithInfos } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetReadyForAuctionVehicles = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [scheduledIds, setScheduledIds] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [auctionStartPrices, setAuctionStartPrices] = useState(
    Array<[string, entityPb.AuctionVehiclePricing.AsObject]>(),
  );
  const [isConnectionReady] = useApiReady();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVehicles();
  }, [isConnectionReady]);

  useEffect(() => {
    setAllSelected(scheduledIds.length === vehicles.length);
  }, [scheduledIds]);

  async function fetchVehicles() {
    setLoading(true);

    if (!isConnectionReady) return;

    try {
      const res = await getVehicles();
      const _vehicles = res.map((info) => info.vehicle!);
      setVehicles(_vehicles);
      setScheduledIds(_vehicles.map(({ id }) => id));
    } catch (error) {
      console.error('fetchVehicles: ', error);
    } finally {
      setLoading(false);
    }
  }

  function toggleScheduleVehicle(vehicleId: string) {
    const newIds = scheduledIds.includes(vehicleId)
      ? scheduledIds.filter((id) => id !== vehicleId)
      : [...scheduledIds, vehicleId];

    setScheduledIds(newIds);
  }

  function toggleScheduleAll(all: boolean) {
    if (all) {
      setScheduledIds(vehicles.map((v) => v.id));
    } else {
      setScheduledIds([]);
    }

    setAllSelected(all);
  }

  useEffect(() => {
    vehicles
      .filter((vehicle) => vehicle.price > 0)
      .map((vehicle) => {
        const price = Math.ceil(vehicle.price);
        handleSetAuctionStartPrice(vehicle, price, vehicle.currency);
      });
  }, [vehicles]);

  function handleSetAuctionStartPrice(vehicle: Vehicle, price: number, currency: Currency) {
    vehicle.price = price;
    vehicle.currency = currency;

    const auctionVehiclePricing = new entityPb.AuctionVehiclePricing();
    auctionVehiclePricing.setStartPrice(vehicle.price);
    auctionVehiclePricing.setCurrency(vehicle.currency);
    const newPriceMap: [string, entityPb.AuctionVehiclePricing.AsObject] = [
      vehicle.id,
      auctionVehiclePricing.toObject(),
    ];
    removeDuplicatedAuctionStartPrices(vehicle.id);
    const updateArray = auctionStartPrices;
    updateArray.push(newPriceMap);
    setAuctionStartPrices(updateArray);
  }

  function removeDuplicatedAuctionStartPrices(vehicleId: string) {
    const oldArray = auctionStartPrices.filter((key) => key[0] !== vehicleId);
    setAuctionStartPrices(oldArray);
  }

  async function getVehicles(): Promise<VehicleWithInfos[]> {
    const request = new adminApiv1Pb.ListAuctionVehiclesRequest();
    const res = await client.listAuctionVehicles(request, authHeader);

    return res.toObject().vehicleInfosList || [];
  }

  return {
    vehicles,
    fetchVehicles,
    loadingVehicles: loading,
    toggleScheduleVehicle,
    toggleScheduleAll,
    allSelected,
    scheduledIds,
    handleSetAuctionStartPrice,
    auctionStartPrices,
  };
};
