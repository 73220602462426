import React from 'react';

type DayInputProps = {
  day: string;
  handleDayChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};

function DayInput({ day, handleDayChange, isDisabled }: DayInputProps) {
  // TODO: max should be updated via year/month
  return (
    <div>
      <label htmlFor="day">Day</label>
      <input type="number" min="1" max="31" id="day" value={day} onChange={handleDayChange} disabled={isDisabled} />
    </div>
  );
}

export default DayInput;
