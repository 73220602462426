import React from 'react';

type MinuteInputProps = {
  minute: string;
  handleMinuteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};

function MinuteInput({ minute, handleMinuteChange, isDisabled }: MinuteInputProps) {
  return (
    <div>
      <label htmlFor="minute">Minute</label>
      <input
        type="number"
        min="0"
        max="59"
        id="minute"
        value={minute}
        onChange={handleMinuteChange}
        disabled={isDisabled}
      />
    </div>
  );
}

export default MinuteInput;
