import useGetRegistrationAddress from '../../composables/api/useGetRegistrationAddress';
import styles from '../../scss/GloveBox.module.scss';

type RegistrationAddressProps = {
  vehicleId: string;
};

export default function ViewRegistrationAddress({ vehicleId }: RegistrationAddressProps) {
  const { address } = useGetRegistrationAddress(vehicleId);

  if (!address) {
    return <caption>No Registration Address</caption>;
  }
  return (
    <section>
      <table className={styles.simple}>
        <caption>Registration Address</caption>
        <tbody>
          <tr>
            <th>Address1</th>
            <td>{address?.address1}</td>
          </tr>
          <tr>
            <th>Address2</th>
            <td>{address?.address2 ? address.address2 : 'N/A'}</td>
          </tr>
          <tr>
            <th>City</th>
            <td>{address?.city}</td>
          </tr>
          <tr>
            <th>Province/State</th>
            <td>{address?.state}</td>
          </tr>
          <tr>
            <th>Country</th>
            <td>{address?.country}</td>
          </tr>
          <tr>
            <th>Postal Code</th>
            <td>{address?.postalCode}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
