import { OfferDocumentObject, SimpleOfferInfo } from '../../composables/api/types';
import useModal from '../../composables/utils/useModal';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/GloveBox.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import OfferDocument from './OfferDocument';
import ViewStoreList from './SelectStoreModal';

interface OfferDocumentsProps {
  offerDocuments: OfferDocumentObject[];
  acceptedOffer: SimpleOfferInfo | null;
  onCreateOfferDocument: (dealershipId?: string) => void;
  onCancelOfferDocument: (offerDocumentId: string) => void;
}

function OfferDocuments({
  offerDocuments,
  acceptedOffer,
  onCreateOfferDocument,
  onCancelOfferDocument,
}: OfferDocumentsProps) {
  const { openModal } = useModal();

  const openStoreCheckout = async () => {
    if (acceptedOffer) {
      openModal(<ViewStoreList AcceptedOffer={acceptedOffer} onCreateOfferDocument={onCreateOfferDocument} />, {
        contentLabel: `Send Buy-in Agreement`,
        shouldCloseOnOverlayClick: false,
      });
    }
  };

  return (
    <section>
      <table className={styles.simple}>
        <caption>HelloSign Document </caption>
        {offerDocuments
          .filter(({ documentStatus }) => documentStatus !== enumPb.DocumentStatus.DOCUMENT_STATUS_INVALID)
          .map((doc, index) => {
            return (
              <div key={`offer-document-${index}`} className={styles.baseOfferDocumentItem}>
                <OfferDocument doc={doc} onCancelOfferDocument={onCancelOfferDocument} />
              </div>
            );
          })}
      </table>
      {acceptedOffer && (
        <BaseButtonOutline small display="Send Offer Document" onClick={openStoreCheckout}></BaseButtonOutline>
      )}
    </section>
  );
}

export default OfferDocuments;
