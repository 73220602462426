// GetVehiclePricingByVehicleId
import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { VehiclePricing, VehicleReservePrice } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetVehiclePricing = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [vehiclePricing, setVehiclePricing] = useState<VehiclePricing | null>(null);
  const [vehicleReservePrice, setVehicleReservePrice] = useState<VehicleReservePrice | null>(null);
  const [isConnectionReady] = useApiReady();
  const [vehicleId, setVehicleId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getVehiclePricing(): Promise<adminApiv1Pb.GetVehiclePricingByVehicleIdResponse> {
    return new Promise<adminApiv1Pb.GetVehiclePricingByVehicleIdResponse>((resolve, reject) => {
      const request = new adminApiv1Pb.GetVehiclePricingByVehicleIdRequest();
      request.setVehicleId(vehicleId);

      client.getVehiclePricingByVehicleId(request, authHeader, (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    });
  }

  const fetchVehiclePricingByVehicleId = async () => {
    if (!isConnectionReady) return;

    try {
      setIsLoading(true);
      const res = await getVehiclePricing();

      if (res.toObject() === null) {
        setVehiclePricing(null);
        return;
      }
      const p = res.toObject().vehiclePricing || null;
      setVehiclePricing(p);
      setVehicleReservePrice(res.toObject().reservePrice || null);
      setIsLoading(false);
    } catch (error) {
      console.error('fetchProfile: ', error);
    }
  };

  useEffect(() => {
    fetchVehiclePricingByVehicleId();
  }, [isConnectionReady, vehicleId]);

  return {
    vehiclePricing,
    setVehiclePricing,
    vehicleReservePrice,
    setVehicleReservePrice,
    fetchVehiclePricingByVehicleId,
    vehicleId,
    setVehicleId,
    isLoading,
  };
};
