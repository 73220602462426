import { useEffect, useState } from 'react';

import { useAuthHeader } from '../../composables/api/useAuthHeader';
import { useApiReady } from '../../composables/utils/useApiReady';
import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as mlApiV1Entity from '../../grpc/build/protos/mlapiv1entity_pb';

enum Marketplace {
  MARKETPLACE_ORIGIN_INVALID = 0,
  MARKETPLACE_ORIGIN_MINTLIST = 1,
  MARKETPLACE_ORIGIN_BCAA = 2,
}

export const useQuestions = () => {
  const [questions, setQuestions] = useState<mlApiV1Entity.CarInspectionQuestion.AsObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [categories, setCategories] = useState<string[]>([]);

  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();

  const [marketplace, setMarketplace] = useState<number>(Marketplace.MARKETPLACE_ORIGIN_MINTLIST);

  async function fetchQuestions(marketplace: number) {
    if (!isConnectionReady) return;
    setLoading(true);

    try {
      const request = new adminApiv1Pb.ListCarInspectionQuestionWithoutInspectionRequest();
      request.setMarketplace(marketplace);
      const res = await client.listCarInspectionQuestionWithoutInspection(request, authHeader);
      const obj = res.toObject();

      if (obj.questions === undefined) {
        return;
      }

      setCategories(obj.questions.carInspectionQuestionsList.map((question) => question.category));

      setQuestions(obj.questions.carInspectionQuestionsList);
      setMarketplace(marketplace);
      setError('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function updateQuestion(id: string, questionText: string) {
    if (!isConnectionReady) return;
    setLoading(true);

    try {
      const pbQuestion = new mlApiV1Entity.CarInspectionQuestion();

      const request = new adminApiv1Pb.UpdateCarInspectionQuestionWithoutInspectionRequest();
      request.setMarketplace(marketplace);

      pbQuestion.setQuestionId(id);
      pbQuestion.setQuestion(questionText);

      request.setQuestion(pbQuestion);
      const res = await client.updateCarInspectionQuestionWithoutInspection(request, authHeader);

      const obj = res.toObject();

      if (obj.question === undefined) {
        return;
      }

      setError('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isConnectionReady) {
      fetchQuestions(marketplace);
    }
  }, [isConnectionReady]);

  return {
    questions,
    setQuestions,
    loading,
    setLoading,
    error,
    fetchQuestions,
    marketplace,
    setMarketplace,
    isConnectionReady,
    updateQuestion,
    categories,
  };
};
