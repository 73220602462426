import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { AuctionStatus } from '../../grpc/build/protos/adminapiv1_pb';
import * as searchEntityPb from '../../grpc/build/protos/searchentity_pb';
import { FacetFilter, FilterType } from '../../grpc/build/protos/searchentity_pb';
import { convertAuctionStatus, convertConsumerAuctionStatus } from '../../helpers/utils';
import { useApiReady } from '../utils/useApiReady';
import { EditableFacet, RemovableVehicle, Vehicle } from './types';
import { useAuthHeader } from './useAuthHeader';
import { useRemoveVehicleFromAuction } from './useRemoveVehicleFromAuction';

export type VehicleAuctionStatusItem = {
  display: string;
  value: AuctionStatus;
};

// this will appear at the top of the page
const allStatus = [
  AuctionStatus.AUCTION_STATUS_SCHEDULED,
  AuctionStatus.AUCTION_STATUS_ACTIVE,
  AuctionStatus.AUCTION_STATUS_COMPLETE,
  AuctionStatus.AUCTION_STATUS_ACCEPTED,
  AuctionStatus.AUCTION_STATUS_REJECTED,
  AuctionStatus.AUCTION_STATUS_REMOVED,
  AuctionStatus.AUCTION_STATUS_READY,
];

type AuctionVehicle = RemovableVehicle & {
  auctionStatus: adminApiv1Pb.AuctionStatus;
  status: adminApiv1Pb.VehicleStatus;
} & Pick<Vehicle, 'referrer'>;

export default function useGetAuctionVehicles(pageSize = 10) {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [facets, setFacets] = useState<EditableFacet[]>([]);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [allVehicleAuctionStatuses, setAllVehicleAuctionStatuses] = useState<VehicleAuctionStatusItem[]>([]);
  const [pending, setPending] = useState(false);
  const removeVehicleFromAuctionFn = useRemoveVehicleFromAuction();

  const vehicleList: AuctionVehicle[] = vehicles.map((vehicle) => {
    return {
      ...vehicle,
      isRemoved: false,
    };
  });

  async function removeVehicleFromAuction(vehicleId: string) {
    try {
      await removeVehicleFromAuctionFn(vehicleId);
      const vehicle = vehicleList.find((vehicle) => vehicle.id === vehicleId);
      if (vehicle) vehicle.isRemoved = true;
    } catch (error) {
      console.error(error);
      alert('Failed to remove vehicle');
    }
  }

  async function _getVehiclesByStatus(
    keyword: string,
    pageNum: number,
    _facets: EditableFacet[],
    auctionStatus?: adminApiv1Pb.AuctionStatus,
  ): Promise<adminApiv1Pb.ListVehiclesResponse> {
    const request = new adminApiv1Pb.ListVehiclesRequest();
    request.setVehicleStatus(adminApiv1Pb.VehicleStatus.VEHICLE_STATUS_CONFIRMED);
    auctionStatus && request.setAuctionStatus(auctionStatus);
    request.setKeyword(keyword);
    request.setFacetFiltersList(parseFacetTerms(_facets));
    const pagination = new adminApiv1Pb.Pagination();
    pagination.setOffset(pageNum);
    pagination.setPageSize(pageSize);
    request.setPagination(pagination);
    return client.listVehicles(request, authHeader);
  }

  function _fetchVehicleStatusesAndFacets(
    keyword: string,
    _facets?: EditableFacet[],
  ): Promise<adminApiv1Pb.ListVehiclesResponse> {
    const request = new adminApiv1Pb.ListVehiclesRequest();
    request.setVehicleStatus(adminApiv1Pb.VehicleStatus.VEHICLE_STATUS_CONFIRMED);
    request.setKeyword(keyword);
    _facets && request.setFacetFiltersList(parseFacetTerms(_facets));

    return client.listVehicles(request, authHeader);
  }

  async function getStatusCounts(keyword: string, _facets: EditableFacet[]) {
    try {
      if (!isConnectionReady) return;

      setPending(true);
      const res = await _fetchVehicleStatusesAndFacets(keyword, _facets);
      handleVehicleStatus(res.getAuctionStatus()?.getCountsList());
    } finally {
      setPending(false);
    }
  }

  function handleVehicleStatus(vehicleStatuses?: searchEntityPb.FacetCount[]) {
    const filteredVehicleStatuses = allStatus.map((status) => {
      const convertedStatus = vehicleStatuses?.find(
        (count) => status === convertConsumerAuctionStatus(count.getValue()),
      );
      return {
        display: convertAuctionStatus(status) + ' ' + (convertedStatus?.getCount() || 0),
        value: status,
      };
    });
    setAllVehicleAuctionStatuses(filteredVehicleStatuses);
  }

  async function fetchVehicles(
    keyword: string,
    pageNum: number,
    _facets: EditableFacet[],
    updateFacet: boolean = true,
    auctionStatus?: adminApiv1Pb.AuctionStatus,
  ) {
    try {
      if (!isConnectionReady) return;
      setPending(true);
      const res = await _getVehiclesByStatus(keyword, pageNum, _facets, auctionStatus);
      setVehicles(res.toObject().vehicles?.vehiclesList || []);
      setTotalVehicles(Math.ceil(res.toObject().total));
      if (updateFacet) handleFacets(res.getFacetCountsList());
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  }

  function handleFacets(facets: searchEntityPb.Facet[]) {
    const newFacets = facets.map((facet) => {
      const countList = facet.getCountsList().map((count) => {
        const newCount = {
          count: count.getCount(),
          value: count.getValue(),
          isSelected: false,
        };
        return newCount;
      });

      const newFacet: EditableFacet = {
        countsList: countList,
        fieldName: facet.getFieldName(),
      };

      return newFacet;
    });

    setFacets(newFacets);
  }

  function parseFacetTerms(_facets: EditableFacet[]) {
    return _facets
      .filter((facet) => {
        return facet.countsList.some(({ isSelected }) => {
          return isSelected;
        });
      })
      .map((facet) => {
        const facetFilter = new FacetFilter();
        facetFilter.setFieldName(facet.fieldName);

        facetFilter.setValuesList(
          facet.countsList
            .filter(({ isSelected }) => {
              return isSelected;
            })
            .map(({ value }) => value),
        );
        facetFilter.setFilterType(FilterType.FILTER_TYPE_OR);

        return facetFilter;
      });
  }

  useEffect(() => {
    try {
      // we need to pull facets in the beginning and start from page 1
      fetchVehicles('', 1, [], true);
      getStatusCounts('', []);
    } catch (error) {
      console.error('listVehicles:', error);
    }
  }, [isConnectionReady]);

  return {
    vehicles,
    facets,
    allVehicleAuctionStatuses,
    setVehicles,
    setFacets,
    totalVehicles,
    clearVehicles: () => setVehicles([]),
    fetchVehicles,
    pending,
    removeVehicleFromAuction,
    vehicleList,
    getStatusCounts,
  };
}
