import { useAuth0 } from '@auth0/auth0-react';
import { Metadata } from 'grpc-web';
import { useEffect, useState } from 'react';

export const useAuthHeader = () => {
  const [authHeader, setAuthHeader] = useState<Metadata | null>(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const buildAuthHeader = async () => {
      const accessToken = await getAccessTokenSilently({
        cacheMode: 'on',
        detailedResponse: true,
        authorizationParams: { redirect_uri: window.location.origin },
      });
      if (!accessToken?.id_token) return;
      setAuthHeader({ Authorization: 'Bearer ' + accessToken.id_token });
    };

    buildAuthHeader();
  }, [isAuthenticated, getAccessTokenSilently]);

  return [authHeader];
};
