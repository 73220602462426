import { Timestamp } from '../../composables/api/types';
import { BasicFieldProps } from './types';

export default function TimestampField(props: BasicFieldProps<Timestamp>) {
  const { viewValue: timeValue } = props;

  const timeAttributes = ['createdAt', 'updatedAt', 'deletedAt'] as const;

  return (
    <>
      {timeAttributes.map((timeAttr) => (
        <tr key={timeAttr}>
          <td>{timeAttr}</td>
          <td>{nanoToDateString(parseInt(timeValue[timeAttr]))}</td>
          <td></td>
        </tr>
      ))}
    </>
  );
}

function nanoToDateString(timeInNanoseconds: number) {
  if (!timeInNanoseconds) return 'N/A';
  return new Date(timeInNanoseconds / 1000000).toString();
}
