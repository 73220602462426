import { useEffect, useState } from 'react';

import { useAuthHeader } from '../../composables/api/useAuthHeader';
import { useApiReady } from '../../composables/utils/useApiReady';
import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as mlApiV1Entity from '../../grpc/build/protos/mlapiv1entity_pb';

export const useQuestionOptions = () => {
  const [questionOptions, setQuestionOptions] = useState<mlApiV1Entity.QuestionOption.AsObject[]>([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [errorOptions, setErrorOptions] = useState('');
  const [warningOptions, setWarningOptions] = useState('');

  const [cancel, setCancel] = useState(false);

  const [questionId, setQuestionId] = useState<string>('');

  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();

  async function fetchOptions(questionId: string) {
    if (!isConnectionReady) return;
    if (!questionId) return;

    try {
      const request = new adminApiv1Pb.ListCarInspectionQuestionOptionsRequest();
      request.setQuestionId(questionId);
      const res = await client.listCarInspectionQuestionOptions(request, authHeader);
      const obj = res.toObject();

      if (obj.questionOptionsList === undefined) {
        return;
      }
      setQuestionOptions(obj.questionOptionsList);
      setLoadingOptions(false);
      setErrorOptions('');
    } catch (err) {
      if (!cancel) {
        console.error(err);
      }
    }
  }

  async function updateOption(optionId: string, optionText: string, optionDescription: string) {
    if (!isConnectionReady) return;

    try {
      const request = new adminApiv1Pb.UpdateCarInspectionQuestionOptionRequest();

      const optionObj = new mlApiV1Entity.QuestionOption();
      optionObj.setId(optionId);
      optionObj.setOption(optionText);
      optionObj.setDescription(optionDescription);

      request.setQuestionOption(optionObj);
      const res = await client.updateCarInspectionQuestionOption(request, authHeader);
      const obj = res.toObject();

      if (obj.questionOption === undefined) {
        return;
      }
      //refresh options
      fetchOptions(questionId);
      setLoadingOptions(false);
      setErrorOptions('');
    } catch (err) {
      console.error(err);
    }
  }

  async function onUpdateQuestionId(questionId: string) {
    setQuestionId(questionId);
    if (isConnectionReady && questionId) {
      setCancel(false);
      await fetchOptions(questionId);
    }
  }

  useEffect(() => {
    return () => {
      setCancel(true);
    };
  }, []);

  return {
    questionOptions,
    loadingOptions,
    errorOptions,
    fetchOptions,
    questionId,
    setQuestionId,
    setQuestionOptions,
    onUpdateQuestionId,
    updateOption,
    warningOptions,
    setWarningOptions,
  };
};
