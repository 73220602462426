import React from 'react';

import styles from '../../../scss/Input.module.scss';

interface NumInputProps {
  defaultMin?: number;
  label?: string;
  value: string;
  invalid?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function NumInput({ defaultMin, label, value, invalid, handleChange }: NumInputProps) {
  const val = isNaN(+value) ? 0 : value;

  return (
    <div className={`${styles.inputContainer} ${invalid ? styles.invalid : ''}`}>
      {label && <label htmlFor="startPrice">{label}</label>}
      <input type="number" min={defaultMin || 0} id="startPrice" value={val} onChange={handleChange} />
    </div>
  );
}

export default NumInput;
