import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { ConsumerSignup, ConsumerSignupProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetConsumerSignups = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function getConsumerSignups(props: ConsumerSignupProps): Promise<ConsumerSignup[]> {
    if (!isConnectionReady) return [];

    const request = new adminApiv1Pb.ListConsumerSignupsRequest();
    request.setStartTime(props.startTime);
    request.setEndTime(props.endTime);

    const response = await client.listConsumerSignups(request, authHeader);
    return response.toObject().consumerSignups?.consumerSignupsList || [];
  }

  return getConsumerSignups;
};
