import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { OfferStatus } from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { SimpleOfferInfo } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useListDealTankPendingOffers = (vehicleId: string) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [offers, setOffers] = useState([] as SimpleOfferInfo[]);

  async function getAllPendingOffers() {
    if (!isConnectionReady) return null;

    const request = new adminApiv1Pb.ListDealTankOffersForVehicleRequest();
    request.setVehicleId(vehicleId);
    const response = await client.listDealTankOffersForVehicle(request, authHeader);
    let pendingOffers = response.toObject().offers?.offerList.filter((item) => {
      return item.offerStatus === OfferStatus.OFFER_STATUS_PENDING;
    });
    setOffers(pendingOffers || []);
  }

  useEffect(() => {
    try {
      getAllPendingOffers();
    } catch (error) {
      console.error('getAllPendingOffers:', error);
    }
  }, [isConnectionReady]);

  return { offers, setOffers };
};
