import { useState } from 'react';

import { AutoGroup } from '../../composables/api/types';
import { useCreateAutoGroup } from '../../composables/api/useCreateAutoGroup';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import { convertSubscriptionPlanToValue, subscriptionPlans } from '../../helpers/subscriptions';
import styles from '../../scss/CreateDealer.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import TextInput from '../utils/inputs/TextInput';
import AutoGroupListItem from './AutoGroupListItem';

function CreateAutoGroup() {
  const createAutoGroup = useCreateAutoGroup();

  const [name, { handleChange: handleNameChange, setInputVal: setName }] = useInput('');
  const [provRegNo, { handleChange: handleProvRegNoChange, setInputVal: setProvinceRegistrationNo }] = useInput('');
  const [street, { handleChange: handleStreetChange, setInputVal: setStreet }] = useInput('');
  const [city, { handleChange: handleCityChange, setInputVal: setCity }] = useInput('');
  const [state, { handleChange: handleStateChange, setInputVal: setState }] = useInput('');
  const [postal, { handleChange: handlePostalChange, setInputVal: setPostal }] = useInput('');
  const [phone, { handleChange: handlePhoneChange, setInputVal: setPhone }] = useInput('');
  const [fax, { handleChange: handleFaxChange, setInputVal: setFaxNumber }] = useInput('');
  const [accountantEmail, { handleChange: handleAccountantEmailChange, setInputVal: setAccountantEmail }] =
    useInput('');

  const [subscriptionPlan, { handleChange: handleSubscriptionPlanChange, setInputVal: setSubscriptionPlan }] =
    useInput('');

  const enableCreateBtn = [name].every((fieldValue) => !!fieldValue.trim()); //check for others?
  const [errorMsg, setErrorMsg] = useState('');

  const [autoGroup, setAutoGroup] = useState<AutoGroup | null>(null);

  const BTN_TEXT = {
    initialText: 'Create AutoGroup',
    confirmationText: 'Click again to create Auto Group',
    completionText: 'AutoGroup Created !',
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleAutoGroupCreate,
    useRestore: true,
  });

  async function handleAutoGroupCreate() {
    try {
      setErrorMsg('');

      const autoGroupResponse = await createAutoGroup({
        name: name,
        provinceRegistrationNo: provRegNo,
        subscriptionPlan: convertSubscriptionPlanToValue(subscriptionPlan),
        address: {
          state: state,
          street: street,
          city: city,
          postal: postal,
          telephoneNumber: phone,
          faxNumber: fax,
        },
        accountantEmail: accountantEmail,
      });

      setAutoGroup(autoGroupResponse);

      clearForm();
    } catch (error) {
      setErrorMsg('AutoGroup Creation Fail');
    }
  }

  const clearForm = () => {
    setName('');
    setAccountantEmail('');
    setCity('');
    setPhone('');
    setState('');
    setStreet('');
    setSubscriptionPlan('');
    setProvinceRegistrationNo('');
    setPostal('');
    setFaxNumber('');
  };

  return (
    <div className={styles.formContainer}>
      <h2>Create AutoGroup</h2>
      <div className={styles.container}>
        <fieldset>
          <legend>Basic Info</legend>
          <TextInput label="Name" name="name" value={name} handleChange={handleNameChange} required />

          <TextInput
            label="Registration Number"
            name="provinceRegistrationNo"
            value={provRegNo}
            handleChange={handleProvRegNoChange}
          />

          <TextInput label="Phone Number" name="phone" inputType="tel" value={phone} handleChange={handlePhoneChange} />
          <TextInput
            label="Accountant Email"
            name="email"
            inputType="email"
            value={accountantEmail}
            handleChange={handleAccountantEmailChange}
          />

          <BaseExpandSelect
            label="Choose Subscription Plan:"
            name="subscriptionPlan"
            onChange={handleSubscriptionPlanChange}
            options={subscriptionPlans}
            selectedValue={subscriptionPlan}
            useEmptyOption={false}
          />
        </fieldset>

        <fieldset>
          <legend>Address</legend>
          <TextInput label="Street" name="street" value={street} handleChange={handleStreetChange} />
          <div className={styles.sectionTwo}>
            <TextInput label="City" name="city" value={city} handleChange={handleCityChange} />
          </div>

          {/* autogroup only uses a state so leaving this for now..*/}
          <TextInput label="State" name="state" value={state} handleChange={handleStateChange} />

          <TextInput label="Postal Code" name="postal" value={postal} handleChange={handlePostalChange} />

          <div className={styles.sectionTwo}>
            <TextInput
              label="Telephone Number"
              name="telephoneNumber"
              value={phone}
              handleChange={handlePhoneChange}
              inputType="tel"
            />
            <TextInput
              label="Fax Number (optional)"
              name="faxNumber"
              value={fax}
              handleChange={handleFaxChange}
              inputType="tel"
            />
          </div>
        </fieldset>

        <BaseButtonOutline
          display={confirmBtnText}
          onClick={doubleCheck}
          disabled={!enableCreateBtn || isConfirmBtnDisabled}
        />

        {errorMsg && (
          <section className={styles.results}>
            <p>{errorMsg}</p>
          </section>
        )}

        {autoGroup && <AutoGroupListItem autoGroup={autoGroup} />}
      </div>
    </div>
  );
}

export default CreateAutoGroup;
