import { useEffect, useState } from 'react';

function useDashboardFileTypes({ onTypeChange, files }: FileTypesProps) {
  const [fileType, setFileType] = useState('');
  const fileTypes = files
    .filter((file) => !!file.uploadKey)
    .map((file) => {
      return {
        id: file.uploadKey,
        name: file.label,
      };
    });

  useEffect(() => {
    onTypeChange();
  }, [fileType]);

  return {
    fileType,
    fileTypes,
    setFileType,
  };
}

export default useDashboardFileTypes;

interface Doc {
  uploadKey: string;
  label: string;
}
interface FileTypesProps {
  onTypeChange: () => void;
  files: Doc[];
}
