import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import dealTankEntityPb from '../../grpc/build/protos/dealtankentity_pb';
import enumPb from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import useCurrency from '../utils/useCurrency';
import { CreateDealTankOffer } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useCreateDealTankOffer = () => {
  const [pending, setPending] = useState(false);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [price, setPrice] = useState(0);
  const [buyerId, setBuyerId] = useState('');
  const { currency, currencyOptions, setCurrency } = useCurrency();

  const createDealTankOffer = async (vehicleId: string, { price, buyerId, currency }: CreateDealTankOffer) => {
    if (!isConnectionReady || pending) return;
    try {
      setPending(true);

      const request = new adminApiv1Pb.CreateDealTankOfferRequest();
      const offerParam = new dealTankEntityPb.DealTankOffer();
      offerParam.setPrice(price);
      offerParam.setCurrency(currency);
      offerParam.setBuyerId(buyerId);
      offerParam.setOfferOrigin(enumPb.OfferOrigin.OFFER_ORIGIN_BUYER);
      offerParam.setOfferStatus(enumPb.OfferStatus.OFFER_STATUS_PENDING);
      request.setOffer(offerParam);
      request.setVehicleId(vehicleId);
      const response = await client.createDealTankOffer(request, authHeader);
      return response.toObject();
    } finally {
      setPending(false);
    }
  };

  return { createDealTankOffer, pending, price, setPrice, currency, setCurrency, buyerId, setBuyerId, currencyOptions };
};
