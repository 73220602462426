import React, { useEffect } from 'react';

import BaseSelectOption from '../../components/base/BaseSelectOption';
import { Location } from '../../composables/api/types';
import useRegions from '../../composables/utils/useRegions';
import styles from '../../scss/VehicleDetails.module.scss';
import BaseSelect from '../base/BaseSelect';
import { BasicFieldProps, ElementType } from './types';

const locationAttributes = ['address1', 'address2', 'city', 'state', 'country', 'postalCode'] as const;
type Attribute = ElementType<typeof locationAttributes>;

export default function LocationField(props: BasicFieldProps<Location, Location, Location>) {
  const { viewValue: locationValue, editValue, propName, isEditable, isEditMode, editFn } = props;
  const { countries, setCountry, divisions, setDivision, setMultiple } = useRegions();

  useEffect(() => {
    setMultiple({
      country: locationValue.country,
      division: locationValue.state,
    });
  }, [locationValue]);

  function handleChangeFactory(attribute: Attribute) {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (attribute === 'country') setCountry(e.target.value);

      if (attribute === 'state') setDivision(e.target.value);

      const updatedValue = {
        ...editValue,
        [attribute]: e.target.value,
      };

      editFn(propName, updatedValue);
    };
  }

  function createField(attribute: Attribute) {
    const address1Field = attribute === 'address1';
    const countryField = attribute === 'country';
    const divisionField = attribute === 'state';

    if (!isEditable || !isEditMode) return <td></td>;

    if (divisionField) {
      return (
        <td>
          <BaseSelect name="division" value={editValue[attribute]} onChange={handleChangeFactory(attribute)}>
            <BaseSelectOption value=""></BaseSelectOption>
            {divisions.map(({ code }) => (
              <BaseSelectOption key={code} value={code}>
                {code}
              </BaseSelectOption>
            ))}
          </BaseSelect>
        </td>
      );
    }

    if (countryField) {
      return (
        <td>
          <BaseSelect name="country" value={editValue[attribute]} onChange={handleChangeFactory(attribute)}>
            <BaseSelectOption value=""></BaseSelectOption>
            {countries.map(({ name, cca2 }) => (
              <BaseSelectOption key={cca2} value={cca2}>
                {name}
              </BaseSelectOption>
            ))}
          </BaseSelect>
        </td>
      );
    }

    return (
      <td>
        <input
          className={styles.vehicleDetails__field__input}
          type="text"
          value={editValue[attribute]}
          onChange={handleChangeFactory(attribute)}
        />

        {address1Field && (
          <span className={styles.reminder}>
            !! Please also update this field if you update city, state, country, or postal code.
          </span>
        )}
      </td>
    );
  }

  return (
    <>
      {locationAttributes.map((locAttr) => (
        <tr key={locAttr}>
          <td>{locAttr}</td>
          <td>{locationValue[locAttr]}</td>
          {createField(locAttr)}
        </tr>
      ))}
    </>
  );
}
