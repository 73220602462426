import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useState } from 'react';

import { useDealerships } from '../../composables/api/useDealerships';
import styles from '../../scss/DealershipList.module.scss';
import BaseSearchInput from '../base/BaseSearchInput';
import BaseWrapper from '../base/BaseWrapper';
import Loading from '../utils/Loading';
import Paginator from '../utils/Paginator';
import DealershipListItem from './DealershipListItem';

function DealershipList() {
  const defaultPageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { getDealershipItems, dealershipItems, dealershipItemsCount } = useDealerships(defaultPageSize);

  async function handleNewPage(newPage: number) {
    setCurrentPage(newPage);
    handleGetDealershipsByName(searchTerm, newPage);
  }

  async function handleGetDealershipsByName(name: string, page = 1) {
    setSearchTerm(name);

    try {
      await getDealershipItems({
        name,
        pageSize: defaultPageSize,
        offSet: page,
      });
    } catch (error) {
      console.error('handleGetDealershipsByName ', error);
    }
  }

  return (
    <BaseWrapper>
      <h2>Dealerships</h2>
      <div className={styles.controlPanel}>
        <BaseSearchInput placeholder="Search by keyword" onSearch={handleGetDealershipsByName} />
      </div>
      <Paginator
        currentPage={currentPage}
        maxPage={Math.ceil(dealershipItemsCount / defaultPageSize)}
        handleNewPage={handleNewPage}
      />

      {dealershipItems.map((dealership) => {
        return (
          <div key={dealership.id}>
            <h3 className={styles.title}>{dealership.name}</h3>
            <div className={styles.container}>
              <DealershipListItem dealershipInfo={dealership} />
            </div>
          </div>
        );
      })}
    </BaseWrapper>
  );
}

export default withAuthenticationRequired(DealershipList, {
  onRedirecting: () => <Loading />,
});
