import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useAutoGroups } from '../../composables/api/useAutoGroups';
import { useUpdateAutoGroup } from '../../composables/api/useUpdateAutoGroup';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import { convertSubscriptionPlanToValue, subscriptionPlans } from '../../helpers/subscriptions';
import styles from '../../scss/CreateDealer.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import TextInput from '../utils/inputs/TextInput';

function UpdateAutoGroup() {
  const updateAutoGroup = useUpdateAutoGroup();

  const [autoGroupId, { setInputVal: setAutoGroupId }] = useInput('');
  const [name, { handleChange: handleNameChange, setInputVal: setName }] = useInput('');
  const [provRegNo, { handleChange: handleProvRegNoChange, setInputVal: setProvinceRegistrationNo }] = useInput('');
  const [street, { handleChange: handleStreetChange, setInputVal: setStreet }] = useInput('');
  const [city, { handleChange: handleCityChange, setInputVal: setCity }] = useInput('');
  const [state, { handleChange: handleStateChange, setInputVal: setState }] = useInput('');
  const [postal, { handleChange: handlePostalChange, setInputVal: setPostal }] = useInput('');
  const [phone, { handleChange: handlePhoneChange, setInputVal: setPhone }] = useInput('');
  const [fax, { handleChange: handleFaxChange, setInputVal: setFaxNumber }] = useInput('');
  const [accountantEmail, { handleChange: handleAccountantEmailChange, setInputVal: setAccountantEmail }] =
    useInput('');

  const [subscriptionPlanId, { handleChange: handleSubscriptionPlanChange, setInputVal: setSubscriptionPlan }] =
    useInput('');
  const { autoGroups } = useAutoGroups();
  const BTN_TEXT = {
    initialText: 'Update AutoGroup',
    confirmationText: 'Click again to update autogroup',
    completionText: 'AutoGroup Updated!',
  };
  const handleUpdateDealership = async () => {
    const originAutoGroup = autoGroups.find((ele) => ele.id === autoGroupId);
    const subscriptionPlan = convertSubscriptionPlanToValue(subscriptionPlanId);

    if (!originAutoGroup) return;

    try {
      await updateAutoGroup({
        name: name,
        provinceRegistrationNo: provRegNo,
        subscriptionPlan: subscriptionPlan,
        address: {
          state: state,
          street: street,
          city: city,
          postal: postal,
          telephoneNumber: phone,
          faxNumber: fax,
        },
        accountantEmail: accountantEmail,
        originAutoGroup: originAutoGroup,
      });
    } catch (err) {
      console.error('handleUpdateDealership: ', err);
    }
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleUpdateDealership,
    useRestore: true,
  });

  const handleAutoGroupSelect = async (autoGroupId: string) => {
    const originAutoGroup = autoGroups.find((ele) => ele.id === autoGroupId);

    if (!originAutoGroup) return;
    setAccountantEmail(originAutoGroup.accountantEmail);
    setProvinceRegistrationNo(originAutoGroup.provinceRegistrationNo);
    setSubscriptionPlan(originAutoGroup.subscriptionPlan.toString());
    setName(originAutoGroup.name);
    setAutoGroupId(autoGroupId);

    //address
    setCity(originAutoGroup.autoGroupLocation?.city || '');
    setFaxNumber(originAutoGroup.autoGroupLocation?.faxNumber || '');
    setStreet(originAutoGroup.autoGroupLocation?.street || '');
    setPostal(originAutoGroup.autoGroupLocation?.postal || '');
    setState(originAutoGroup.autoGroupLocation?.state || '');
    setStreet(originAutoGroup.autoGroupLocation?.street || '');
    setPhone(originAutoGroup.autoGroupLocation?.telephoneNumber || '');
  };

  let { id } = useParams();

  useEffect(() => {
    if (id != undefined && autoGroups.length > 0) {
      handleAutoGroupSelect(id);
    }
  }, [autoGroups]);

  return (
    <div className={styles.formContainer}>
      <h2>Update Dealership</h2>

      <div>
        <section className={styles.settings}>
          <div>
            <label htmlFor="autoGroupId">AutoGroup ID *</label>
            <input id="autoGroupId" value={autoGroupId} disabled />

            <div className={styles.selectDealerships}>
              <BaseExpandSelect
                label="Choose AutoGroup:"
                selectEvent={handleAutoGroupSelect}
                options={autoGroups}
                selectedValue={autoGroupId}
              />
            </div>
          </div>
          <TextInput label="Name" name="name" value={name} handleChange={handleNameChange} />

          <TextInput
            label="Registration Number"
            name="provinceRegistrationNo"
            value={provRegNo}
            handleChange={handleProvRegNoChange}
          />

          <TextInput label="Phone Number" name="phone" inputType="tel" value={phone} handleChange={handlePhoneChange} />
          <TextInput
            label="Accountant Email"
            name="email"
            inputType="email"
            value={accountantEmail}
            handleChange={handleAccountantEmailChange}
          />

          <BaseExpandSelect
            label="Choose Subscription Plan:"
            name="subscriptionPlan"
            onChange={handleSubscriptionPlanChange}
            options={subscriptionPlans}
            selectedValue={subscriptionPlanId}
            useEmptyOption={false}
          />

          <legend>Address</legend>
          <TextInput label="Street" name="street" value={street} handleChange={handleStreetChange} />
          <div className={styles.sectionTwo}>
            <TextInput label="City" name="city" value={city} handleChange={handleCityChange} />
          </div>

          {/* autogroup only uses a state so leaving this for now..*/}
          <TextInput label="State" name="state" value={state} handleChange={handleStateChange} />

          <TextInput label="Postal Code" name="postal" value={postal} handleChange={handlePostalChange} />

          <div className={styles.sectionTwo}>
            <TextInput
              label="Telephone Number"
              name="telephoneNumber"
              value={phone}
              handleChange={handlePhoneChange}
              inputType="tel"
            />
            <TextInput
              label="Fax Number (optional)"
              name="faxNumber"
              value={fax}
              handleChange={handleFaxChange}
              inputType="tel"
            />
          </div>
          <div>
            <BaseButtonOutline display={confirmBtnText} onClick={doubleCheck} disabled={isConfirmBtnDisabled} />
          </div>
        </section>
      </div>
    </div>
  );
}

export default UpdateAutoGroup;
