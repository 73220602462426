import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import BaseButtonOutline from '../base/BaseButtonOutline';

type SetRemoveVehicleFromAuctionProps = {
  vehicleId: string;
  onRemove: (vehicleId: string) => void;
};

function RemoveAuctionVehicle({ vehicleId, onRemove }: SetRemoveVehicleFromAuctionProps) {
  const REMOVE_BTN_TEXT = {
    initialText: 'Remove vehicle',
    confirmationText: 'Click again to remove vehicle',
    completionText: 'Vehicle removed',
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: REMOVE_BTN_TEXT,
    fn: () => onRemove(vehicleId),
  });

  return <BaseButtonOutline small display={confirmBtnText} onClick={doubleCheck} disabled={isConfirmBtnDisabled} />;
}

export default RemoveAuctionVehicle;
