import { useState } from 'react';

import { useEditReferrer } from '../../composables/api/useUpdateVehicleReferrer';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import style from '../../scss/EditFloorPrice.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BooleanToggle from './BooleanToggle';
import TextInput from './inputs/TextInput';

const BTN_TEXT = {
  initialText: 'Submit',
  confirmationText: 'Click again to submit Vehicle Referrer',
  completionText: 'Vehicle Referrer Set!',
};

function UpdateVehicleReferrer({ vehicleId, referrerEmail }: { vehicleId: string; referrerEmail: string }) {
  const [deleteReferrer, setDeleteReferrer] = useState(false);
  const [email, { handleChange: handleEmailChange }] = useInput(referrerEmail);
  const { pending, updateReferrer } = useEditReferrer();

  const handleDeleteReferrer = () => {
    setDeleteReferrer(!deleteReferrer);
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleSetVehicleReferrer,
    useRestore: true,
  });

  async function handleSetVehicleReferrer() {
    try {
      await updateReferrer({ vehicleId, email, deleteReferrer });
    } catch (error) {
      console.log('Failed to update referrer', error);
    }
  }

  return (
    <div className={style.editFloorPrice}>
      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled />

      <TextInput name="email" value={email} label="Email" handleChange={handleEmailChange} />
      <div>
        <BooleanToggle title="Remove Referrer" id={vehicleId} checked={deleteReferrer} onClick={handleDeleteReferrer} />
      </div>
      <div>
        <BaseButtonOutline display={confirmBtnText} onClick={doubleCheck} disabled={isConfirmBtnDisabled || pending} />
      </div>
    </div>
  );
}

export default UpdateVehicleReferrer;
