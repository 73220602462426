import React, { useState } from 'react';

import { UserOrigin } from '../grpc/build/protos/enum_pb';
import styles from '../scss/Impersonation.module.scss';
import ImpersonateConsumer from './impersonation/ImpersonateConsumer';
import ImpersonateDealer from './impersonation/ImpersonateDealer';
import RadioButtons from './utils/RadioButtons';

function Impersonation() {
  const defaultUserOption = UserOrigin.USER_ORIGIN_CONSUMER;
  const [selectedUserOption, setSelectedUserOption] = useState(defaultUserOption);

  const userOptions = [
    { display: 'Consumer', value: UserOrigin.USER_ORIGIN_CONSUMER },
    { display: 'Dealer', value: UserOrigin.USER_ORIGIN_DEALER },
  ];

  function handleUserOptionClick(value: UserOrigin) {
    setSelectedUserOption(value);
  }

  return (
    <div className={styles.impersonationContainer}>
      <h2>Impersonate</h2>
      <br />
      <p>Please note that impersonator must have mintlist account under @mintlist or @trafficdriven</p>
      <p>
        In order to return your own account put your own email and then use the reset button. You will need to logout as
        well
      </p>
      <br />
      <div>
        <RadioButtons options={userOptions} onClick={handleUserOptionClick} currentSelected={selectedUserOption} />
      </div>
      <br />
      <div>{selectedUserOption === defaultUserOption ? <ImpersonateConsumer /> : <ImpersonateDealer />}</div>
    </div>
  );
}

export default Impersonation;
