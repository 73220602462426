import { useHttpService } from '../../composables/http/useHttpService';
import styles from '../../scss/FileUpload.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import { UploadStatus } from './types';
interface UploadProps {
  id: string;
  type: string;
  files: File[];
  onClear: () => void;
  onError: (err: string) => void;
  onStatusChange: (status: UploadStatus) => void;
  uploadStatus: UploadStatus;
  onUpload: (name: string) => void;
}

interface Error {
  name: string;
  message: string;
}
const ImageUploader = ({ id, type, files, onError, onClear, onStatusChange, uploadStatus, onUpload }: UploadProps) => {
  const { post } = useHttpService(type == 'dealer_id' ? '/upload-dealer-logo' : '/additional-photo-upload');

  const uploadFile = async () => {
    onStatusChange(UploadStatus.START);

    const formDataArray = files.map((file) => {
      const formData = new FormData();
      if (type == 'dealer_id') {
        formData.append('dealer-logo', file, file.name);
        formData.append('dealer_id', id);
      } else {
        formData.append('additional-photo', file, file.name);
        formData.append('vehicle_id', id);
      }

      return [file.name, formData];
    });

    try {
      for (const formDataPair of formDataArray) {
        const [fileName, formData] = formDataPair;
        await post(formData);
        onUpload(fileName as string);
      }
    } catch (error) {
      console.error('Photo Upload > ', error);
      const msg = `${(error as Error).name}: ${(error as Error).message}`;
      onError(msg);
    } finally {
      onStatusChange(UploadStatus.DONE);
    }
  };

  return (
    <>
      <div className={styles.uploadContainer}>
        <BaseButtonOutline
          small
          onClick={uploadFile}
          display={uploadStatus === UploadStatus.START ? 'Uploading' : 'Upload Images'}
          disabled={[UploadStatus.DONE, UploadStatus.START].includes(uploadStatus)}
        />
        <BaseButtonOutline
          small
          onClick={onClear}
          display="Reset"
          disabled={[UploadStatus.DONE, UploadStatus.START].includes(uploadStatus)}
        />
      </div>
    </>
  );
};

export default ImageUploader;
