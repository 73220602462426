import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { SimpleOfferContainer, SimpleOfferInfo } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useDealTankOffers = (vehicleId: string) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [offers, setOffers] = useState<SimpleOfferContainer | null>(null);
  const [offer, setOffer] = useState<SimpleOfferInfo | null>(null);
  const [loading, setLoading] = useState(true);

  async function getOffers() {
    if (!isConnectionReady) return null;

    setLoading(true);

    const request = new adminApiv1Pb.ListDealTankOffersForVehicleRequest();
    request.setVehicleId(vehicleId);
    try {
      const response = await client.listDealTankOffersForVehicle(request, authHeader);
      const offers = response.toObject().offers;

      if (offers) {
        setOffers({
          offerList: offers.offerList,
          uniqueCount: offers.uniqueCount,
        });

        const dealTankOffer = offers?.offerList.find(
          (offer) => offer.offerStatus == enumPb.OfferStatus.OFFER_STATUS_ACCEPTED,
        );
        if (dealTankOffer) {
          setOffer(dealTankOffer);
        }
      }
    } catch (e) {
      console.error('useDealTankOffers failed,', e);
      setOffers(null);
      setOffer(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOffers();

    return () => setLoading(false);
  }, [isConnectionReady, vehicleId]);

  return { offers, offer, loading, getOffers };
};
