import React, { useEffect, useState } from 'react';

import useFileSelect from '../../composables/files/useFileSelect';
import styles from '../../scss/FileUpload.module.scss';
import FileInput from './FileInput';
import ImagePreview from './ImagePreview';
import ImageReplacer from './ImageReplacer';
import { UploadStatus } from './types';

interface BaseFileUploadProps {
  id: string;
  onReplace: (mlImageUrl: string) => void;
  renderCompleteSection?: () => React.ReactElement;
  order?: number;
}

export default function ImageReplace({ id, renderCompleteSection, onReplace, order }: BaseFileUploadProps) {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.BEFORE_START);

  const { handleFileSelect, fileInputRef, selectFile, files, removeFile, resetFiles } = useFileSelect({
    isMultiple: false,
    onFileSelect: () => {
      setUploadStatus(UploadStatus.BEFORE_START);
      setErrMsg('');
    },
  });

  const [errMsg, setErrMsg] = useState('');

  const clearAll = () => {
    resetFiles();
    setErrMsg('');
    setUploadStatus(UploadStatus.BEFORE_START);
  };

  useEffect(() => {
    clearAll();
  }, [id]);

  const handleUploaded = (mlImageUrl: string) => {
    setUploadStatus(UploadStatus.DONE);
    resetFiles();
    setErrMsg('');
    onReplace && onReplace(mlImageUrl);
  };

  return (
    <div className={styles.fileUploadContainer}>
      <section>
        <p className={styles.uploadInfo}>Add photo for: {id}</p>
      </section>

      <section>
        <p>Step 1 - Select your photo</p>
        <FileInput
          display={'Click to Replace Photo'}
          onClick={selectFile}
          onChange={handleFileSelect}
          fileInputRef={fileInputRef}
          disabled={files.length > 0}
        />
      </section>

      {!!errMsg && (
        <section>
          <p className="label-error">{errMsg}</p>
        </section>
      )}

      {!!files[0] && uploadStatus === UploadStatus.BEFORE_START && (
        <section>
          <p>Step 2. Preview photo</p>
          <ImagePreview file={files[0]} onRemove={removeFile} />
        </section>
      )}

      {!!files[0] && (
        <section>
          <p>Step 3. Upload</p>
          <ImageReplacer
            id={id}
            file={files[0]}
            uploadStatus={uploadStatus}
            onClear={clearAll}
            onError={setErrMsg}
            onReplace={handleUploaded}
            order={order}
          />
        </section>
      )}

      {uploadStatus === UploadStatus.DONE && (
        <section className={styles.result}>
          {!!files[0] && files[0].name} is uploaded.
          <p className={styles.success}>Upload completed!</p>
          {renderCompleteSection && renderCompleteSection()}
        </section>
      )}
    </div>
  );
}
