import styles from '../../scss/VehicleDetails.module.scss';
import { BasicFieldProps } from './types';

export default function BasicField({
  viewValue,
  editValue,
  isEditMode,
  isEditable,
  propName,
  editFn,
}: BasicFieldProps<any>) {
  return (
    <tr key={propName}>
      <td>{propName}</td>
      <td>{viewValue}</td>

      {(() => {
        if (!isEditable || !isEditMode) return <td></td>;

        return (
          <td>
            <input
              className={styles.vehicleDetails__field__input}
              type="text"
              value={editValue}
              onChange={(e) => editFn(propName, e.target.value)}
            />
          </td>
        );
      })()}
    </tr>
  );
}
