import { ChangeEvent } from 'react';

import styles from '../../scss/BaseExpandSelect.module.scss';
import BaseExpandSelect from '../base/BaseExpandSelect';
import TextInput from '../utils/inputs/TextInput';

export function SelectReferrer({
  dealershipReferrerId,
  dealershipReferrerNotes,
  onDealershipReferrerIdChange,
  onDealershipReferrerNotesChange,

  dealershipReferrers,
}: {
  dealershipReferrerId: string;
  dealershipReferrerNotes: string;
  onDealershipReferrerIdChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onDealershipReferrerNotesChange: (e: ChangeEvent<HTMLInputElement>) => void;

  dealershipReferrers: { id: string; name: string }[];
}) {
  return (
    <div>
      <div className={styles.miscContainer}>
        <BaseExpandSelect
          name="dealershipReferrerId"
          label="Referrer"
          onChange={onDealershipReferrerIdChange}
          options={dealershipReferrers}
          selectedValue={dealershipReferrerId}
        />
      </div>
      <TextInput
        label="Referrer Notes"
        name="dealershipReferrerNotes"
        value={dealershipReferrerNotes}
        onChange={onDealershipReferrerNotesChange}
        disabled={dealershipReferrerId === ''}
      />
    </div>
  );
}
