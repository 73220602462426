import { withAuthenticationRequired } from '@auth0/auth0-react';

import useAuctions from '../composables/api/useAuctions';
import useModal from '../composables/utils/useModal';
import { getReadableDate } from '../helpers/utils';
import style from '../scss/ProxyAuctions.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseWrapper from './base/BaseWrapper';
import Loading from './utils/Loading';

function ScheduledAuctions() {
  const { auctions, pending, deleteAuction } = useAuctions();
  const { openModal, closeModal } = useModal();
  const dateTimeFormat = 'YYYY MMM DD [at] h:mm a';

  const confirmRemove = async (auctionId: string) => {
    try {
      await deleteAuction(auctionId);
      closeModal();
    } catch (error) {
      console.error(error);
      alert('Failed to remove vehicle');
    }
  };

  const cancelRemove = () => {
    closeModal();
  };

  const handleRemove = async (auctionName: string, auctionId: string) => {
    openModal(
      <div className={style.removeAuction}>
        <h2>You are removing: {auctionName}</h2>
        <div className={style.buttons}>
          <BaseButtonOutline disabled={pending} display="Remove Auction" onClick={() => confirmRemove(auctionId)} />
          <BaseButtonOutline display={'Cancel Remove'} onClick={cancelRemove} />
        </div>
      </div>,
    );
  };

  if (pending) {
    return <p style={{ textAlign: 'center' }}>Loading...</p>;
  }

  if (auctions.length === 0) {
    return (
      <section>
        <p>No Ongoing Auction Found</p>
      </section>
    );
  }
  return (
    <BaseWrapper>
      <h2>Scheduled Auctions</h2>
      {auctions
        .filter((item) => item.startTimeNano > Date.now() * 10 ** 6)
        .map((item) => (
          <div className={style.listItem} key={item.auctionId}>
            <h4>Auction title: {item.auctionTitle}</h4>
            <div>
              <p>Number of Vehicles: {item.numberOfVehicles}</p>
              <p>Start time: {getReadableDate(item.startTimeNano, dateTimeFormat)}</p>
            </div>
            <BaseButtonOutline
              disabled={pending}
              display="Remove Auction"
              onClick={() => handleRemove(item.auctionTitle, item.auctionId)}
            />
          </div>
        ))}
    </BaseWrapper>
  );
}

export default withAuthenticationRequired(ScheduledAuctions, {
  onRedirecting: () => <Loading />,
});
