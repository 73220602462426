import React, { useState } from 'react';

import { TopBidInfo } from '../../composables/api/types';
import { useGetTopBids } from '../../composables/api/useTopBids';
import styles from '../../scss/BidInfo.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface BidInfoProps {
  vehicleId: string;
  disabled?: boolean;
}
type BidInfoMap = {
  [key: string]: TopBidInfo | null;
};

function BidInfo({ vehicleId, disabled = false }: BidInfoProps) {
  const [topBidInfos, setTopBidInfos] = useState<BidInfoMap>({});
  const getTopBids = useGetTopBids();

  const bidInfo = topBidInfos[vehicleId];

  const fetchBidInfo = async (vehicleId: string) => {
    const topBidInfo = await getTopBids(vehicleId);
    setTopBidInfos({
      ...topBidInfos,
      [vehicleId]: topBidInfo,
    });
  };

  if (!bidInfo) {
    return (
      <BaseButtonOutline
        small
        display="Show bidding info"
        onClick={() => fetchBidInfo(vehicleId)}
        disabled={disabled}
      />
    );
  }

  if (!bidInfo.uniqueBidders)
    return (
      <div className={styles.bidInfo__container}>
        <h4>No bids</h4>
      </div>
    );

  return (
    <div className={styles.bidInfo__container}>
      <div>
        <h4>Bidding info</h4>
        <p>{bidInfo.uniqueBidders} unique bidder(s)</p>
      </div>

      <div>
        <h4>Top bids</h4>
        <div>
          {bidInfo.bidsList.map((bid, index) => (
            <p key={`${vehicleId}-bid-${index}`}>
              <span>{bid.bid}</span> | {bid.user?.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BidInfo;
