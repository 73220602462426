import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { RegistrationAddress } from './types';
import { useAuthHeader } from './useAuthHeader';

export default (vehicleId: string) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [address, setAddress] = useState<RegistrationAddress | null>(null);

  async function fetchRegistrationAddress() {
    if (!isConnectionReady) return;

    try {
      const request = new adminApiv1Pb.GetRegistrationAddressRequest();
      request.setVehicleId(vehicleId);
      const response = await client.getRegistrationAddress(request, authHeader);
      const registrationAddress = response.toObject().registrationAddress || null;
      setAddress(registrationAddress);
    } catch (error) {
      console.error('fetchRegistrationAddress: ', error);
    }
  }

  useEffect(() => {
    fetchRegistrationAddress();
  }, [isConnectionReady]);

  return {
    address,
  };
};
