import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { User } from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useGetConsumerByName = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [consumers, setConsumers] = useState<Array<User.AsObject>>([]);

  const searchConsumer = async (input: string) => {
    if (!isConnectionReady || input.length < 3) return;

    const request = new adminApiv1Pb.GetConsumersByNameRequest();
    request.setNameQuery(input);
    let res = await client.getConsumersByName(request, authHeader);
    let obj = res.toObject();
    if (obj.users) setConsumers(obj.users.usersList);
  };

  return { searchConsumer, consumers };
};
