import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { UserOrigin } from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useImpersonation = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const impersonate = async (
    impersonatorEmail: string,
    impersonatedEmail: string,
    isReset: boolean,
    userOrigin: UserOrigin,
  ) => {
    if (!isConnectionReady) return;

    const request = new adminApiv1Pb.ImpersonateUserRequest();
    request.setImpersonatorEmail(impersonatorEmail);
    request.setImpersonatedEmail(impersonatedEmail);
    request.setIsReset(isReset);
    request.setUserOrigin(userOrigin);
    await client.impersonateUser(request, authHeader);
  };

  return { impersonate };
};
