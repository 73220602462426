import { useEffect } from 'react';

import { useVehicle } from '../../composables/api/useVehicle';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import { useEditFields } from '../../composables/vdp/useEditFields';
import { VehicleStatus } from '../../grpc/build/protos/adminapiv1_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';

type SetListingPaymentRecievedProps = {
  vehicleId: string;
};

function SetListingPaymentRecieved({ vehicleId }: SetListingPaymentRecievedProps) {
  const BTN_TEXT = {
    initialText: 'Set Payment Received',
    confirmationText: 'Click again to confirm',
    completionText: 'Vehicle is now listing payment received',
  };

  const { vehicle, updateVehicle } = useVehicle(vehicleId || '');
  const { fieldsForEdit, setFields } = useEditFields(true);
  const { confirmBtnText, doubleCheck } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: () => setReady(),
  });

  useEffect(() => {
    setFields(vehicle);
  }, [vehicle]);

  const setReady = async () => {
    try {
      if (!fieldsForEdit) return;

      await updateVehicle({
        ...fieldsForEdit,
        status: VehicleStatus.VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED,
      });
    } catch (err) {
      alert(`Error when setting as listing payment received ${err}`);
      return;
    }
  };

  return <BaseButtonOutline small display={confirmBtnText} onClick={doubleCheck} />;
}

export default SetListingPaymentRecieved;
