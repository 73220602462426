import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import EditFloorPrice from '../auction/ChangeFloorPrice';
import BaseButtonOutline from '../base/BaseButtonOutline';

function ChangeFloorPrice({ vehicleId }: { vehicleId: string }) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const editFloorPrice = async () => {
    openUtilityDrawer(<EditFloorPrice vehicleId={vehicleId} />);
  };

  return <BaseButtonOutline small display="Change Floor Price" onClick={editFloorPrice} />;
}

export default ChangeFloorPrice;
