import { useState } from 'react';

import { DealershipItem } from '../../composables/api/types';
import { useAutoGroups } from '../../composables/api/useAutoGroups';
import { useCreateDealership } from '../../composables/api/useCreateDealership';
import { useDealershipReferrers } from '../../composables/api/useDealershipReferrers';
import useHandleGroupInput from '../../composables/createDealership/useHandleGroupInput';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import {
  convertSubscriptionPlan,
  convertSubscriptionPlanToValue,
  subscriptionPlans,
} from '../../helpers/subscriptions';
import styles from '../../scss/CreateDealer.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSelectOption from '../base/BaseSelectOption';
import SelectInput from '../utils/inputs/SelectInput';
import TextInput from '../utils/inputs/TextInput';
import DealershipListItem from './DealershipListItem';
import { SelectReferrer } from './SelectReferrer';

function CreateDealership() {
  const createDealership = useCreateDealership();
  const {
    values,
    addressValues,
    handleBasicInputChange,
    handleAddressInputChange,
    resetAddressValues,
    resetBasicValue,
    countries,
    divisions,
  } = useHandleGroupInput();

  const enableCreateBtn = [values.name].every((fieldValue) => !!fieldValue.trim());
  const [errorMsg, setErrorMsg] = useState('');

  const [dealerEntity, setDealerEntity] = useState<DealershipItem | null>(null);

  const BTN_TEXT = {
    initialText: 'Create Dealership',
    confirmationText: 'Click again to create dealership',
    completionText: 'Dealership Created !',
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleDealershipCreate,
    useRestore: true,
  });

  async function handleDealershipCreate() {
    try {
      setErrorMsg('');

      const dealerResponse = await createDealership({
        ...values,
        subscriptionPlan: convertSubscriptionPlanToValue(values.subscriptionPlan),
        address: addressValues,
      });

      setDealerEntity({
        id: dealerResponse.id,
        name: dealerResponse.name,
        referrerNotes: dealerResponse.dealershipReferral?.notes,
        referrerId: dealerResponse.dealershipReferral?.referrerId,
        subscriptionPlan: convertSubscriptionPlan(dealerResponse.subscriptionPlan),
        primaryMake: dealerResponse.primaryMake,
        gstNumber: dealerResponse.gstNumber,
        pstNumber: dealerResponse.pstNumber,
        accountantEmail: dealerResponse.accountantEmail,
        address: dealerResponse.dealerLocation,
        subscriptionDate: dealerResponse.subscriptionChangeDate,
        phone: dealerResponse.phone,
        email: dealerResponse.email,
        legalName: dealerResponse.legalName,
        carfax: dealerResponse.carfaxCredentials,
        googlePlaceId: dealerResponse.googlePlaceId,
        rin: dealerResponse.rin,
        controllerEmail: dealerResponse.controllerEmail,
        salesManagerEmail: dealerResponse.salesManagerEmail,
        gmEmail: dealerResponse.gmEmail,
      } as DealershipItem);

      clearForm();
    } catch (error) {
      setErrorMsg('Dealership Creation Fail');
    }
  }

  const clearForm = () => {
    resetAddressValues();
    resetBasicValue();
  };

  const { dealershipReferrers } = useDealershipReferrers();
  const { autoGroups } = useAutoGroups();

  return (
    <div className={styles.formContainer}>
      <h2>Create Dealership</h2>
      <div className={styles.container}>
        <fieldset>
          <legend>Basic Info</legend>
          <TextInput label="Name" name="name" value={values.name} handleChange={handleBasicInputChange} required />
          <TextInput
            label="Primary Make"
            name="primaryMake"
            value={values.primaryMake}
            handleChange={handleBasicInputChange}
          />

          <div className={styles.sectionTwo}>
            <TextInput
              label="GST Number"
              name="gstNumber"
              value={values.gstNumber}
              handleChange={handleBasicInputChange}
            />
            <TextInput
              label="PST Number"
              name="pstNumber"
              value={values.pstNumber}
              handleChange={handleBasicInputChange}
            />
          </div>

          <TextInput
            label="Registration Number"
            name="provinceRegistrationNo"
            value={values.provinceRegistrationNo}
            handleChange={handleBasicInputChange}
          />

          <TextInput
            label="RIN Number (Dealership Specific Number)"
            name="rin"
            value={values.rin}
            handleChange={handleBasicInputChange}
          />
          <TextInput
            label="Controller Email"
            name="controllerEmail"
            value={values.controllerEmail}
            handleChange={handleBasicInputChange}
            required
          />
          <TextInput
            label="Sales Manager Email"
            name="salesManagerEmail"
            value={values.salesManagerEmail}
            handleChange={handleBasicInputChange}
            required
          />
          <TextInput
            label="General Manager Email"
            name="gmEmail"
            value={values.gmEmail}
            handleChange={handleBasicInputChange}
            required
          />

          <TextInput
            label="Legal Name (Name for legal paperwork)"
            name="legalName"
            value={values.legalName}
            handleChange={handleBasicInputChange}
          />
          <TextInput
            label="Phone Number"
            name="phone"
            inputType="tel"
            value={values.phone}
            handleChange={handleBasicInputChange}
          />
          <TextInput
            label="Email"
            name="email"
            inputType="email"
            value={values.email}
            handleChange={handleBasicInputChange}
          />
          <TextInput
            label="Google Place Id"
            name="googlePlaceId"
            value={values.googlePlaceId}
            handleChange={handleBasicInputChange}
          />
          <TextInput
            label="External Dealer Id"
            name="externalDealerId"
            value={values.externalDealerId}
            handleChange={handleBasicInputChange}
          />
          <TextInput
            label="Accountant Email"
            name="accountantEmail"
            value={values.accountantEmail}
            handleChange={handleBasicInputChange}
          />
          <BaseExpandSelect
            label="Select AutoGroup:"
            name="autoGroupId"
            onChange={handleBasicInputChange}
            options={autoGroups}
            selectedValue={values.autoGroupId}
            useEmptyOption={true}
          />
          <BaseExpandSelect
            label="Choose Subscription Plan:"
            name="subscriptionPlan"
            onChange={handleBasicInputChange}
            options={subscriptionPlans}
            selectedValue={values.subscriptionPlan}
            useEmptyOption={false}
            isDisabled={values.autoGroupId !== ''}
          />
        </fieldset>

        <fieldset>
          <legend>Address</legend>
          <TextInput
            label="Street"
            name="street"
            value={addressValues.street}
            handleChange={handleAddressInputChange}
          />
          <div className={styles.sectionTwo}>
            <TextInput label="City" name="city" value={addressValues.city} handleChange={handleAddressInputChange} />
          </div>

          <div className={styles.sectionTwo}>
            <SelectInput
              label="Country"
              name="country"
              required
              id="countrySelect"
              value={addressValues.country}
              onChange={handleAddressInputChange}>
              <BaseSelectOption value=""></BaseSelectOption>
              {countries.map(({ name, cca2 }) => (
                <BaseSelectOption key={cca2} value={cca2}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
            <SelectInput
              label="Province / State"
              name="division"
              value={addressValues.division}
              required
              onChange={handleAddressInputChange}>
              <BaseSelectOption value=""></BaseSelectOption>
              {divisions.map(({ code, name }) => (
                <BaseSelectOption key={code} value={code}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
          </div>

          <TextInput
            label="Postal Code"
            name="postal"
            value={addressValues.postal}
            handleChange={handleAddressInputChange}
          />

          <div className={styles.sectionTwo}>
            <TextInput
              label="Telephone Number"
              name="telephoneNumber"
              value={addressValues.telephoneNumber}
              handleChange={handleAddressInputChange}
              inputType="tel"
            />
            <TextInput
              label="Fax Number (optional)"
              name="faxNumber"
              value={addressValues.faxNumber}
              handleChange={handleAddressInputChange}
              inputType="tel"
            />
          </div>
        </fieldset>

        <div>
          <SelectReferrer
            dealershipReferrerId={values.dealershipReferrerId}
            dealershipReferrerNotes={values.dealershipReferrerNotes}
            onDealershipReferrerIdChange={handleBasicInputChange}
            onDealershipReferrerNotesChange={handleBasicInputChange}
            dealershipReferrers={dealershipReferrers}
          />
        </div>

        <BaseButtonOutline
          display={confirmBtnText}
          onClick={doubleCheck}
          disabled={!enableCreateBtn || isConfirmBtnDisabled || addressValues.division === ''}
        />

        {errorMsg && (
          <section className={styles.results}>
            <p>{errorMsg}</p>
          </section>
        )}

        {dealerEntity && (
          <DealershipListItem
            dealershipInfo={dealerEntity}
            referrer={dealershipReferrers?.find((referrer) => referrer.id === dealerEntity.referrerId)}
          />
        )}
      </div>
    </div>
  );
}

export default CreateDealership;
