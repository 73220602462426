import { MileageUOM } from '../../grpc/build/protos/adminapiv1_pb';
import { convertMileageUom } from '../../helpers/utils';
import RadioButtons from '../utils/RadioButtons';
import { BasicFieldProps } from './types';

function MileageUomField({
  viewValue,
  editValue,
  isEditable,
  isEditMode,
  propName,
  editFn,
}: BasicFieldProps<MileageUOM, MileageUOM, MileageUOM>) {
  const allowedStatus = [MileageUOM.MILEAGE_UOM_KILOMETER, MileageUOM.MILEAGE_UOM_MILES];

  const options = allowedStatus.map((status) => {
    return { display: convertMileageUom(status), value: status };
  });

  const handleSelect = (updatedUOM: MileageUOM) => {
    editFn(propName, updatedUOM);
  };

  const getEditGrid = () => {
    if (!isEditable || !isEditMode) return <td></td>;

    return <td>{<RadioButtons options={options} currentSelected={editValue} onClick={handleSelect} />}</td>;
  };

  return (
    <tr key={propName}>
      <td>{propName}</td>
      <td>{convertMileageUom(viewValue)}</td>
      {getEditGrid()}
    </tr>
  );
}

export default MileageUomField;
