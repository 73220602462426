import AuctionGroupsListField from '../../components/vdp/AuctionGroupsListField';
import ImagesListField from '../../components/vdp/ImagesListField';
import LocationField from '../../components/vdp/LocationField';
import MileageUomField from '../../components/vdp/MileageUomField';
import StatusField from '../../components/vdp/StatusField';
import TimestampField from '../../components/vdp/TimestampField';
import { BasicFieldProps } from '../../components/vdp/types';
import VehicleOptionsListField from '../../components/vdp/VehicleOptionsListField';
import VehiclePaveAnnouncementField from '../../components/vdp/VehiclePaveAnnouncementField';
import VehicleReferrerField from '../../components/vdp/VehicleReferrerField';

const fieldMap: FieldMap = {
  location: LocationField,
  timeStamp: TimestampField,
  auctionGroupsList: AuctionGroupsListField,
  imagesList: ImagesListField,
  status: StatusField,
  vehiclePaveAnnouncement: VehiclePaveAnnouncementField,
  mileageUom: MileageUomField,
  vehicleOptionsList: VehicleOptionsListField,
  referrer: VehicleReferrerField,
};

export default fieldMap;

type FieldMap = {
  [key: string]: (props: BasicFieldProps<any, any, any>) => JSX.Element;
};
