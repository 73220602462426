import { useAdminClient } from '../../contexts/AdminClientContext';
import { AuctionStatus } from '../../grpc/build/protos/adminapiv1_pb';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useAuthHeader } from './useAuthHeader';

export type AuctionSummary = {
  auctionId: string;
  auctionTitle: string;
  startTimeNano: number;
  numberOfVehicles: number;
};

export default function () {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  async function updateAgaStatus(
    vehicleId: string,
    auctionGroupId: string,
    auctionStatus: AuctionStatus,
  ): Promise<adminApiv1Pb.UpdateAuctionGroupAssetResponse> {
    const request = new adminApiv1Pb.UpdateAuctionGroupAssetRequest();
    request.setAuctionStatus(auctionStatus);
    request.setAuctionGroupId(auctionGroupId);
    request.setReferenceId(vehicleId);
    return await client.updateAuctionGroupAsset(request, authHeader);
  }

  return {
    updateAgaStatus,
  };
}
