import { ChangeEvent, ReactNode } from 'react';

import styles from '../../scss/BaseSelect.module.scss';

export interface BaseSelectProps {
  id?: string;
  name: string;
  value: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  inputType?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  children: ReactNode | ReactNode[];
}

export default function BaseSelect({ id, name, value, onChange, required, disabled, children }: BaseSelectProps) {
  return (
    <div className={styles.baseSelectWrapper}>
      <select id={id || name} name={name} value={value} onChange={onChange} required={required} disabled={disabled}>
        {children}
      </select>
    </div>
  );
}
