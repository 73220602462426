import moment from 'moment';
import React, { useEffect, useState } from 'react';

import useCopyToClipboard from '../composables/utils/useCopyToClipboard';
import useInput from '../composables/utils/useInput';
import styles from '../scss/DevTools.module.scss';
import DateSelectorDeprecated from './utils/DateSelectorDeprecated';

function DevTools() {
  const [nanoTime, setNanoTime] = useState('0');
  const { copy, isCopied } = useCopyToClipboard();
  const [nanoInput, { handleChange: handleNanoInputChange }] = useInput('');
  const [readableTime, setReadableTime] = useState('');

  const handleCopy = () => {
    if (!nanoTime || isCopied) return;
    copy(nanoTime);
  };

  useEffect(() => {
    if (readableTime) setReadableTime('');
    if (!nanoInput) return;
    if (nanoInput.length === 19) {
      setReadableTime(getReadableDate(parseInt(nanoInput), 'YYYY / MM / DD HH: mm'));
    }
  }, [nanoInput]);

  function getReadableDate(numericTime: number, format = 'YYYY/MM/DD/HH/mm'): string {
    if (!numericTime) return '';
    const timeDenominator = 10 ** 9;
    return moment.unix(numericTime / timeDenominator).format(format);
  }

  return (
    <div className={styles.devToolsContainer}>
      <h2>Dev Tools</h2>
      <section className={styles.settings}>
        <div>
          <p className={styles.subHeading}>Convert time to 19-digit timestamp</p>

          <div className={styles.dateContainer}>
            <DateSelectorDeprecated setTime={setNanoTime} />
          </div>

          <label htmlFor="copyNanoTime">Click to copy</label>
          <input
            type="button"
            id="copyNanoTime"
            value={isCopied ? 'Copied!' : nanoTime}
            onClick={handleCopy}
            className={styles.devToolInput}
          />
        </div>
      </section>

      <section className={styles.settings}>
        <div>
          <p className={styles.subHeading}>Convert time from 19-digit timestamp</p>

          <label htmlFor="nanoTimeInput">Enter nano time</label>
          <input
            type="text"
            id="nanoTimeInput"
            value={nanoInput}
            onChange={handleNanoInputChange}
            className={styles.devToolInput}
          />

          {readableTime && (
            <div>
              <label htmlFor="convertedDateTime">Readable date / time</label>
              <input type="text" id="convertedDateTime" value={readableTime} className={styles.devToolInput} readOnly />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default DevTools;
