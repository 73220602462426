import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { VehicleOrigin } from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useCreateCarfaxReport = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();

  function generateCarfaxReport(vehicleId: string): Promise<adminApiv1Pb.CreateCarfaxReportResponse> {
    const request = new adminApiv1Pb.CreateCarfaxReportRequest();
    request.setVehicleId(vehicleId);
    request.setVehicleOrigin(VehicleOrigin.VEHICLE_ORIGIN_CONSUMER);
    return client.createCarfaxReport(request, authHeader);
  }

  const createCarfaxReportFn = async (vehicleId: string) => {
    if (!isConnectionReady) return;
    return generateCarfaxReport(vehicleId);
  };

  return createCarfaxReportFn;
};
