import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import DealTankInfo from '../dealTank/DealTankInfo';

type ComponentProps = {
  label?: string;
  vehicleId: string;
  onCommit?: () => Promise<void>;
};

function DealTankButton({ label, vehicleId, onCommit }: ComponentProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const openDrawer = async () => {
    openUtilityDrawer(<DealTankInfo vehicleId={vehicleId} onCommit={onCommit} />);
  };

  return <BaseButtonOutline small display={label || 'Deal Tank'} onClick={openDrawer} />;
}

export default DealTankButton;
