import React from 'react';

import { RemovableVehicle } from '../../composables/api/types';
import style from '../../scss/BaseVehicleList.module.scss';
import BaseVehicleListItem from './BaseVehicleListItem';

type VehicleListProps<T> = {
  vehicleList: T[];
  // eslint-disable-next-line no-unused-vars
  renderVehicleMeta?: (v: T) => React.ReactElement;
  // eslint-disable-next-line no-unused-vars
  renderActions?: (x: string, y?: number, z?: number) => React.ReactElement;
};

export default function BaseVehicleList<T extends RemovableVehicle>({
  vehicleList,
  renderVehicleMeta,
  renderActions,
}: VehicleListProps<T>) {
  return (
    <div className={style.baseVehicleList}>
      {vehicleList.map((vehicle) => (
        <BaseVehicleListItem
          key={vehicle.id}
          vehicle={vehicle}
          renderVehicleMeta={renderVehicleMeta}
          renderActions={renderActions}
        />
      ))}
    </div>
  );
}
