import { useState } from 'react';

type PaginatorProps = {
  pageSize?: number;
  onPageChange: (pageSize: number, newPage: number) => void;
};
export const usePaginator = ({ pageSize = 10, onPageChange }: PaginatorProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNewPage = (newPage: number) => {
    setCurrentPage(newPage);
    onPageChange(pageSize, newPage);
  };

  return {
    currentPage,
    pageSize,
    handleNewPage,
    setCurrentPage,
  };
};
