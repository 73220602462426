import React from 'react';

type MonthInputProps = {
  month: string;
  handleMonthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};
function MonthInput({ month, handleMonthChange, isDisabled }: MonthInputProps) {
  return (
    <div>
      <label htmlFor="month">Month</label>
      <input
        type="number"
        min="1"
        max="12"
        id="month"
        value={month}
        onChange={handleMonthChange}
        disabled={isDisabled}
      />
    </div>
  );
}

export default MonthInput;
