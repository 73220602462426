import React, { useEffect, useState } from 'react';

import { useGetVehiclePricing } from '../../composables/api/useGetVehiclePricing';
import { createMileage, getNumberWithComma } from '../../helpers/utils';
import styles from '../../scss/CompleteSale.module.scss';
import vehiclePricesStyles from '../../scss/VehiclePricesSlideOut.module.scss';
import DataOneReport from '../utils/DataOneReport';
import TextInput from '../utils/inputs/TextInput';

function VehiclePricesSlideOut({
  vehicleId,
  mileage,
  mileageUom,
}: {
  vehicleId: string;
  mileage: number;
  mileageUom: number;
}) {
  const { vehiclePricing, setVehicleId, isLoading, vehicleReservePrice } = useGetVehiclePricing();
  const [range, setRange] = useState<string>('');
  const [kms, setKms] = useState<number>(0);

  useEffect(() => {
    setVehicleId(vehicleId);
    setKms(createMileage(mileage, mileageUom).toKm());
    if (vehiclePricing) {
      setRange(determineRange(kms));
    }
  }, [vehicleId, vehiclePricing]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  function determineRange(mileage: number) {
    const cbbAvgKmThreshold = vehiclePricing?.cbbPricing?.cbbRoughKmThreshold;
    const cbbCleanKmThreshold = vehiclePricing?.cbbPricing?.cbbAvgKmThreshold;
    const cbbRoughKmThreshold = vehiclePricing?.cbbPricing?.cbbCleanKmThreshold;

    if (!cbbAvgKmThreshold || !cbbCleanKmThreshold || !cbbRoughKmThreshold) {
      return 'Unavailable';
    }
    if (mileage > cbbRoughKmThreshold) {
      return 'Rough';
    } else if (mileage > cbbAvgKmThreshold && mileage < cbbRoughKmThreshold) {
      return 'Average';
    } else if (mileage > cbbCleanKmThreshold && mileage < cbbAvgKmThreshold) {
      return 'Clean';
    } else {
      return 'Clean';
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Vehicle Pricing</h3>

      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled handleChange={() => {}} />

      {vehicleReservePrice ? (
        <div>
          <h3>Reserve Price</h3>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lower Bound</td>
                <td>${getNumberWithComma(vehicleReservePrice?.lowerBound ?? 0)}</td>
              </tr>
              <tr>
                <td>Upper Bound</td>
                <td>${getNumberWithComma(vehicleReservePrice?.upperBound ?? 0)}</td>
              </tr>
              <tr>
                <td>
                  <mark>Reserve</mark>
                </td>
                <td>
                  <mark>${getNumberWithComma(vehicleReservePrice?.reservePrice ?? 0)}</mark>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>No Reserve Price Available.</p>
        </div>
      )}

      <h3>CBB Vehicle Pricing</h3>
      {vehiclePricing ? (
        <div className={vehiclePricesStyles.tableContainer}>
          <h3>Mileage: {getNumberWithComma(kms)} KM</h3>
          <h3>Range: {range}</h3>
          <h3>MSRP: {vehiclePricing.msrp === 0 ? 'Unavailable' : `$${getNumberWithComma(vehiclePricing.msrp)}`}</h3>
          <h3>
            Cents Per KM:{' '}
            {vehiclePricing.cbbPricing?.cbbCentsPerKm
              ? getNumberWithComma(vehiclePricing.cbbPricing?.cbbCentsPerKm.toFixed(2) ?? 0)
              : 'Unavailable'}
          </h3>
          <h4>Base Price Table</h4>
          <table>
            <thead>
              <tr>
                <th>Condition</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Base Price Clean</td>
                <td
                  className={range === 'Clean' ? vehiclePricesStyles.highlightedCell : vehiclePricesStyles.normalCell}>
                  ${getNumberWithComma(vehiclePricing.cbbPricing?.cbbBasePriceClean ?? 0)}
                </td>
              </tr>
              <tr>
                <td>Base Price Avg</td>
                <td
                  className={
                    range === 'Average' ? vehiclePricesStyles.highlightedCell : vehiclePricesStyles.normalCell
                  }>
                  ${getNumberWithComma(vehiclePricing.cbbPricing?.cbbBasePriceAvg ?? 0)}
                </td>
              </tr>
              <tr>
                <td>Base Price Rough</td>
                <td
                  className={range === 'Rough' ? vehiclePricesStyles.highlightedCell : vehiclePricesStyles.normalCell}>
                  ${getNumberWithComma(vehiclePricing.cbbPricing?.cbbBasePriceRough ?? 0)}
                </td>
              </tr>
            </tbody>
          </table>
          <h4>Thresholds Table</h4>
          <table>
            <thead>
              <tr>
                <th>Condition</th>
                <th>Threshold</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>X Clean</td>
                <td>{getNumberWithComma(vehiclePricing.cbbPricing?.cbbXCleanKmThreshold ?? 0)}</td>
              </tr>
              <tr>
                <td>Clean</td>
                <td>{getNumberWithComma(vehiclePricing.cbbPricing?.cbbCleanKmThreshold ?? 0)}</td>
              </tr>
              <tr>
                <td>Avg</td>
                <td>{getNumberWithComma(vehiclePricing.cbbPricing?.cbbAvgKmThreshold ?? 0)}</td>
              </tr>
              <tr>
                <td>Rough</td>
                <td>{getNumberWithComma(vehiclePricing.cbbPricing?.cbbRoughKmThreshold ?? 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>No CBB Pricing Data Available.</p>
          <p></p>
          <DataOneReport vehicleId={vehicleId} />
        </div>
      )}
    </div>
  );
}

export default VehiclePricesSlideOut;
