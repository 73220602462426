import React, { useEffect, useState } from 'react';

import useFileSelect from '../../composables/files/useFileSelect';
import styles from '../../scss/FileUpload.module.scss';
import FileInput from './FileInput';
import ImagePreview from './ImagePreview';
import ImageUploader from './ImageUploader';
import { UploadStatus } from './types';

interface BaseFileUploadProps {
  id: string;
  type: string;
  renderCompleteSection?: () => React.ReactElement;
}

export default function ImageUpload({ id, type, renderCompleteSection }: BaseFileUploadProps) {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.BEFORE_START);

  const { handleFileSelect, fileInputRef, selectFile, files, removeFile, resetFiles } = useFileSelect({
    isMultiple: true,
    onFileSelect: () => {
      setUploadStatus(UploadStatus.BEFORE_START);
      setErrMsg('');
    },
  });

  const [errMsg, setErrMsg] = useState('');
  const [uploadedList, setUploadedList] = useState<string[]>([]);

  const clearAll = () => {
    resetFiles();
    setErrMsg('');
    setUploadStatus(UploadStatus.BEFORE_START);
    setUploadedList([]);
  };

  useEffect(() => {
    clearAll();
  }, [id]);

  useEffect(() => {
    if (uploadStatus === UploadStatus.DONE) {
      resetFiles();
      setErrMsg('');
    }
  }, [uploadStatus]);

  const handleUploaded = (uploadedFileName: string) => {
    setUploadedList((prev) => [...prev, uploadedFileName]);
  };

  return (
    <div className={styles.fileUploadContainer}>
      <section>
        <p className={styles.uploadInfo}>Add file for: {id}</p>
      </section>

      <section>
        <p>Step 1 - Select your files</p>
        <FileInput
          display={files.length ? 'Click to Add More Files' : 'Click to Add Files'}
          onClick={selectFile}
          onChange={handleFileSelect}
          fileInputRef={fileInputRef}
        />
      </section>

      {!!errMsg && (
        <section>
          <p className="label-error">{errMsg}</p>
        </section>
      )}

      {!!files.length && uploadStatus === UploadStatus.BEFORE_START && (
        <section>
          <p>Step 2. Preview {files.length} files</p>
          {files.map((file) => (
            <ImagePreview key={file.name} file={file} onRemove={removeFile} />
          ))}
        </section>
      )}

      {!!files.length && (
        <section>
          <p>Step 3. Upload</p>
          <ImageUploader
            id={id}
            type={type}
            files={files}
            uploadStatus={uploadStatus}
            onClear={clearAll}
            onError={setErrMsg}
            onStatusChange={setUploadStatus}
            onUpload={handleUploaded}
          />
        </section>
      )}

      {uploadStatus === UploadStatus.DONE && (
        <section className={styles.result}>
          {!!uploadedList.length && (
            <ul>
              {uploadedList.map((fileName) => (
                <li key={fileName}>{fileName} is uploaded</li>
              ))}
            </ul>
          )}
          <p className={styles.success}>Upload completed!</p>
          {renderCompleteSection && renderCompleteSection()}
        </section>
      )}
    </div>
  );
}
