import { useCreateCarfaxReport } from '../../composables/api/useGenerateCarfaxReport';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface CarfaxReportProps {
  vehicleId: string;
}

const CARFAX_BTN_TEXT = {
  initialText: 'Generate Carfax',
  confirmationText: 'Click again to buy/pull Carfax report',
  completionText: 'Carfax Process Kicked off Successfully',
};

function CarfaxReport({ vehicleId }: CarfaxReportProps) {
  const createCarfaxRerportFn = useCreateCarfaxReport();
  const createCarfaxReport = async () => {
    try {
      await createCarfaxRerportFn(vehicleId);
    } catch (error) {
      console.error(error);
      alert('Failed to kick off Carfax');
    }
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: CARFAX_BTN_TEXT,
    fn: createCarfaxReport,
  });
  return (
    <BaseButtonOutline
      small
      display={confirmBtnText}
      onClick={doubleCheck}
      disabled={isConfirmBtnDisabled}></BaseButtonOutline>
  );
}

export default CarfaxReport;
