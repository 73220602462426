// source: build/protos/dealtankentity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_entities_pb = require('../../build/protos/entities_pb.js');
goog.object.extend(proto, build_protos_entities_pb);
var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
goog.exportSymbol('proto.entity.dealtankentity.BasicListing', null, global);
goog.exportSymbol('proto.entity.dealtankentity.DealTankOffer', null, global);
goog.exportSymbol('proto.entity.dealtankentity.DealTankOffers', null, global);
goog.exportSymbol('proto.entity.dealtankentity.DealTankVehicle', null, global);
goog.exportSymbol('proto.entity.dealtankentity.EndListingReason', null, global);
goog.exportSymbol('proto.entity.dealtankentity.EndListingReasonCategory', null, global);
goog.exportSymbol('proto.entity.dealtankentity.Favourite', null, global);
goog.exportSymbol('proto.entity.dealtankentity.Listing', null, global);
goog.exportSymbol('proto.entity.dealtankentity.Listings', null, global);
goog.exportSymbol('proto.entity.dealtankentity.Timestamp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealtankentity.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.Timestamp.displayName = 'proto.entity.dealtankentity.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.BasicListing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealtankentity.BasicListing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.BasicListing.displayName = 'proto.entity.dealtankentity.BasicListing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.Listing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealtankentity.Listing.repeatedFields_, null);
};
goog.inherits(proto.entity.dealtankentity.Listing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.Listing.displayName = 'proto.entity.dealtankentity.Listing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.Listings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealtankentity.Listings.repeatedFields_, null);
};
goog.inherits(proto.entity.dealtankentity.Listings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.Listings.displayName = 'proto.entity.dealtankentity.Listings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.DealTankVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealtankentity.DealTankVehicle.repeatedFields_, null);
};
goog.inherits(proto.entity.dealtankentity.DealTankVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.DealTankVehicle.displayName = 'proto.entity.dealtankentity.DealTankVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.DealTankOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealtankentity.DealTankOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.DealTankOffer.displayName = 'proto.entity.dealtankentity.DealTankOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.DealTankOffers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealtankentity.DealTankOffers.repeatedFields_, null);
};
goog.inherits(proto.entity.dealtankentity.DealTankOffers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.DealTankOffers.displayName = 'proto.entity.dealtankentity.DealTankOffers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.Favourite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealtankentity.Favourite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.Favourite.displayName = 'proto.entity.dealtankentity.Favourite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealtankentity.EndListingReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealtankentity.EndListingReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealtankentity.EndListingReason.displayName = 'proto.entity.dealtankentity.EndListingReason';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.Timestamp}
 */
proto.entity.dealtankentity.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.Timestamp;
  return proto.entity.dealtankentity.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.Timestamp}
 */
proto.entity.dealtankentity.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeletedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string created_at = 1;
 * @return {string}
 */
proto.entity.dealtankentity.Timestamp.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Timestamp} returns this
 */
proto.entity.dealtankentity.Timestamp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string updated_at = 2;
 * @return {string}
 */
proto.entity.dealtankentity.Timestamp.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Timestamp} returns this
 */
proto.entity.dealtankentity.Timestamp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deleted_at = 3;
 * @return {string}
 */
proto.entity.dealtankentity.Timestamp.prototype.getDeletedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Timestamp} returns this
 */
proto.entity.dealtankentity.Timestamp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.BasicListing.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.BasicListing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.BasicListing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.BasicListing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.BasicListing}
 */
proto.entity.dealtankentity.BasicListing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.BasicListing;
  return proto.entity.dealtankentity.BasicListing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.BasicListing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.BasicListing}
 */
proto.entity.dealtankentity.BasicListing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.BasicListing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.BasicListing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.BasicListing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.BasicListing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpirationTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealtankentity.BasicListing.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.dealtankentity.BasicListing.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 3;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.dealtankentity.BasicListing.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional entity.enum.ListingStatus listing_status = 4;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.entity.dealtankentity.BasicListing.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 expiration_time = 5;
 * @return {number}
 */
proto.entity.dealtankentity.BasicListing.prototype.getExpirationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setExpirationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double price = 6;
 * @return {number}
 */
proto.entity.dealtankentity.BasicListing.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional entity.enum.Currency currency = 7;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.dealtankentity.BasicListing.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 8;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.dealtankentity.BasicListing.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.dealtankentity.BasicListing} returns this
 */
proto.entity.dealtankentity.BasicListing.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealtankentity.Listing.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.Listing.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.Listing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.Listing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Listing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isTradeIn: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    timeStamp: (f = msg.getTimeStamp()) && proto.entity.dealtankentity.Timestamp.toObject(includeInstance, f),
    dealTankOffersList: jspb.Message.toObjectList(msg.getDealTankOffersList(),
    proto.entity.dealtankentity.DealTankOffer.toObject, includeInstance),
    dealTankVehicle: (f = msg.getDealTankVehicle()) && proto.entity.dealtankentity.DealTankVehicle.toObject(includeInstance, f),
    totalNumberOfOffers: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.Listing}
 */
proto.entity.dealtankentity.Listing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.Listing;
  return proto.entity.dealtankentity.Listing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.Listing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.Listing}
 */
proto.entity.dealtankentity.Listing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTradeIn(value);
      break;
    case 10:
      var value = new proto.entity.dealtankentity.Timestamp;
      reader.readMessage(value,proto.entity.dealtankentity.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 11:
      var value = new proto.entity.dealtankentity.DealTankOffer;
      reader.readMessage(value,proto.entity.dealtankentity.DealTankOffer.deserializeBinaryFromReader);
      msg.addDealTankOffers(value);
      break;
    case 12:
      var value = new proto.entity.dealtankentity.DealTankVehicle;
      reader.readMessage(value,proto.entity.dealtankentity.DealTankVehicle.deserializeBinaryFromReader);
      msg.setDealTankVehicle(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalNumberOfOffers(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.Listing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.Listing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.Listing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Listing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpirationTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getIsTradeIn();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.entity.dealtankentity.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDealTankOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.entity.dealtankentity.DealTankOffer.serializeBinaryToWriter
    );
  }
  f = message.getDealTankVehicle();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.entity.dealtankentity.DealTankVehicle.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumberOfOffers();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealtankentity.Listing.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.dealtankentity.Listing.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 3;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.dealtankentity.Listing.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional entity.enum.ListingStatus listing_status = 4;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.entity.dealtankentity.Listing.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 expiration_time = 5;
 * @return {number}
 */
proto.entity.dealtankentity.Listing.prototype.getExpirationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setExpirationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double price = 6;
 * @return {number}
 */
proto.entity.dealtankentity.Listing.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional entity.enum.Currency currency = 7;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.dealtankentity.Listing.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 8;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.dealtankentity.Listing.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool is_trade_in = 9;
 * @return {boolean}
 */
proto.entity.dealtankentity.Listing.prototype.getIsTradeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setIsTradeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Timestamp time_stamp = 10;
 * @return {?proto.entity.dealtankentity.Timestamp}
 */
proto.entity.dealtankentity.Listing.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.dealtankentity.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealtankentity.Timestamp, 10));
};


/**
 * @param {?proto.entity.dealtankentity.Timestamp|undefined} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
*/
proto.entity.dealtankentity.Listing.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealtankentity.Listing.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated DealTankOffer deal_tank_offers = 11;
 * @return {!Array<!proto.entity.dealtankentity.DealTankOffer>}
 */
proto.entity.dealtankentity.Listing.prototype.getDealTankOffersList = function() {
  return /** @type{!Array<!proto.entity.dealtankentity.DealTankOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealtankentity.DealTankOffer, 11));
};


/**
 * @param {!Array<!proto.entity.dealtankentity.DealTankOffer>} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
*/
proto.entity.dealtankentity.Listing.prototype.setDealTankOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.entity.dealtankentity.DealTankOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealtankentity.DealTankOffer}
 */
proto.entity.dealtankentity.Listing.prototype.addDealTankOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.entity.dealtankentity.DealTankOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.clearDealTankOffersList = function() {
  return this.setDealTankOffersList([]);
};


/**
 * optional DealTankVehicle deal_tank_vehicle = 12;
 * @return {?proto.entity.dealtankentity.DealTankVehicle}
 */
proto.entity.dealtankentity.Listing.prototype.getDealTankVehicle = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankVehicle} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealtankentity.DealTankVehicle, 12));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankVehicle|undefined} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
*/
proto.entity.dealtankentity.Listing.prototype.setDealTankVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.clearDealTankVehicle = function() {
  return this.setDealTankVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealtankentity.Listing.prototype.hasDealTankVehicle = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 total_number_of_offers = 13;
 * @return {number}
 */
proto.entity.dealtankentity.Listing.prototype.getTotalNumberOfOffers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setTotalNumberOfOffers = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool is_favourite = 16;
 * @return {boolean}
 */
proto.entity.dealtankentity.Listing.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealtankentity.Listing} returns this
 */
proto.entity.dealtankentity.Listing.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealtankentity.Listings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.Listings.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.Listings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.Listings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Listings.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingsList: jspb.Message.toObjectList(msg.getListingsList(),
    proto.entity.dealtankentity.Listing.toObject, includeInstance),
    totalNumberOfListings: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.Listings}
 */
proto.entity.dealtankentity.Listings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.Listings;
  return proto.entity.dealtankentity.Listings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.Listings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.Listings}
 */
proto.entity.dealtankentity.Listings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.dealtankentity.Listing;
      reader.readMessage(value,proto.entity.dealtankentity.Listing.deserializeBinaryFromReader);
      msg.addListings(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalNumberOfListings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.Listings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.Listings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.Listings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Listings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.dealtankentity.Listing.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumberOfListings();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Listing listings = 1;
 * @return {!Array<!proto.entity.dealtankentity.Listing>}
 */
proto.entity.dealtankentity.Listings.prototype.getListingsList = function() {
  return /** @type{!Array<!proto.entity.dealtankentity.Listing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealtankentity.Listing, 1));
};


/**
 * @param {!Array<!proto.entity.dealtankentity.Listing>} value
 * @return {!proto.entity.dealtankentity.Listings} returns this
*/
proto.entity.dealtankentity.Listings.prototype.setListingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealtankentity.Listing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealtankentity.Listing}
 */
proto.entity.dealtankentity.Listings.prototype.addListings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealtankentity.Listing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealtankentity.Listings} returns this
 */
proto.entity.dealtankentity.Listings.prototype.clearListingsList = function() {
  return this.setListingsList([]);
};


/**
 * optional int64 total_number_of_listings = 2;
 * @return {number}
 */
proto.entity.dealtankentity.Listings.prototype.getTotalNumberOfListings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.Listings} returns this
 */
proto.entity.dealtankentity.Listings.prototype.setTotalNumberOfListings = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealtankentity.DealTankVehicle.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.DealTankVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.DealTankVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.DealTankVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    build_protos_entities_pb.Image.toObject, includeInstance),
    location: (f = msg.getLocation()) && build_protos_entities_pb.Location.toObject(includeInstance, f),
    mileage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 8, 0),
    year: jspb.Message.getFieldWithDefault(msg, 9, 0),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 10, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 11, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    carfaxLienUrl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.DealTankVehicle}
 */
proto.entity.dealtankentity.DealTankVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.DealTankVehicle;
  return proto.entity.dealtankentity.DealTankVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.DealTankVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.DealTankVehicle}
 */
proto.entity.dealtankentity.DealTankVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = new build_protos_entities_pb.Image;
      reader.readMessage(value,build_protos_entities_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 6:
      var value = new build_protos_entities_pb.Location;
      reader.readMessage(value,build_protos_entities_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 14:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxLienUrl(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.DealTankVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.DealTankVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.DealTankVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      build_protos_entities_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      build_protos_entities_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCarfaxLienUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated entity.entities.Image images = 5;
 * @return {!Array<!proto.entity.entities.Image>}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.entity.entities.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_entities_pb.Image, 5));
};


/**
 * @param {!Array<!proto.entity.entities.Image>} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
*/
proto.entity.dealtankentity.DealTankVehicle.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.entity.entities.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Image}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.entity.entities.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional entity.entities.Location location = 6;
 * @return {?proto.entity.entities.Location}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getLocation = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Location, 6));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
*/
proto.entity.dealtankentity.DealTankVehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 mileage = 7;
 * @return {number}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional entity.enum.MileageUOM mileage_uom = 8;
 * @return {!proto.entity.enum.MileageUOM}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.enum.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.MileageUOM} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 year = 9;
 * @return {number}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string trim_level = 10;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string vin = 11;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string user_given_name = 12;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string user_phone_number = 13;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 14;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 14));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
*/
proto.entity.dealtankentity.DealTankVehicle.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string carfax_lien_url = 15;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getCarfaxLienUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setCarfaxLienUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string user_email = 16;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankVehicle} returns this
 */
proto.entity.dealtankentity.DealTankVehicle.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.DealTankOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.DealTankOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.DealTankOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialOfferId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    offerOrigin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeStamp: (f = msg.getTimeStamp()) && proto.entity.dealtankentity.Timestamp.toObject(includeInstance, f),
    hasRequiredGloveboxDocuments: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.DealTankOffer}
 */
proto.entity.dealtankentity.DealTankOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.DealTankOffer;
  return proto.entity.dealtankentity.DealTankOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.DealTankOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.DealTankOffer}
 */
proto.entity.dealtankentity.DealTankOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialOfferId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.OfferOrigin} */ (reader.readEnum());
      msg.setOfferOrigin(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = new proto.entity.dealtankentity.Timestamp;
      reader.readMessage(value,proto.entity.dealtankentity.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRequiredGloveboxDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.DealTankOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.DealTankOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.DealTankOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialOfferId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOfferOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.entity.dealtankentity.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHasRequiredGloveboxDocuments();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string listing_id = 2;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_offer_id = 3;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getInitialOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setInitialOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional entity.enum.Currency currency = 5;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string buyer_id = 6;
 * @return {string}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional entity.enum.OfferOrigin offer_origin = 7;
 * @return {!proto.entity.enum.OfferOrigin}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getOfferOrigin = function() {
  return /** @type {!proto.entity.enum.OfferOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.OfferOrigin} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setOfferOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 8;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional Timestamp time_stamp = 10;
 * @return {?proto.entity.dealtankentity.Timestamp}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.dealtankentity.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealtankentity.Timestamp, 10));
};


/**
 * @param {?proto.entity.dealtankentity.Timestamp|undefined} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
*/
proto.entity.dealtankentity.DealTankOffer.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool has_required_glovebox_documents = 11;
 * @return {boolean}
 */
proto.entity.dealtankentity.DealTankOffer.prototype.getHasRequiredGloveboxDocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealtankentity.DealTankOffer} returns this
 */
proto.entity.dealtankentity.DealTankOffer.prototype.setHasRequiredGloveboxDocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealtankentity.DealTankOffers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.DealTankOffers.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.DealTankOffers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.DealTankOffers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.DealTankOffers.toObject = function(includeInstance, msg) {
  var f, obj = {
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    proto.entity.dealtankentity.DealTankOffer.toObject, includeInstance),
    totalNumberOfOffers: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.DealTankOffers}
 */
proto.entity.dealtankentity.DealTankOffers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.DealTankOffers;
  return proto.entity.dealtankentity.DealTankOffers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.DealTankOffers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.DealTankOffers}
 */
proto.entity.dealtankentity.DealTankOffers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.dealtankentity.DealTankOffer;
      reader.readMessage(value,proto.entity.dealtankentity.DealTankOffer.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalNumberOfOffers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.DealTankOffers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.DealTankOffers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.DealTankOffers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.DealTankOffers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.dealtankentity.DealTankOffer.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumberOfOffers();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated DealTankOffer offers = 1;
 * @return {!Array<!proto.entity.dealtankentity.DealTankOffer>}
 */
proto.entity.dealtankentity.DealTankOffers.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.entity.dealtankentity.DealTankOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealtankentity.DealTankOffer, 1));
};


/**
 * @param {!Array<!proto.entity.dealtankentity.DealTankOffer>} value
 * @return {!proto.entity.dealtankentity.DealTankOffers} returns this
*/
proto.entity.dealtankentity.DealTankOffers.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealtankentity.DealTankOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealtankentity.DealTankOffer}
 */
proto.entity.dealtankentity.DealTankOffers.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealtankentity.DealTankOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealtankentity.DealTankOffers} returns this
 */
proto.entity.dealtankentity.DealTankOffers.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};


/**
 * optional int64 total_number_of_offers = 2;
 * @return {number}
 */
proto.entity.dealtankentity.DealTankOffers.prototype.getTotalNumberOfOffers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealtankentity.DealTankOffers} returns this
 */
proto.entity.dealtankentity.DealTankOffers.prototype.setTotalNumberOfOffers = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.Favourite.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.Favourite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.Favourite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Favourite.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userOrigin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeStamp: (f = msg.getTimeStamp()) && proto.entity.dealtankentity.Timestamp.toObject(includeInstance, f),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.Favourite}
 */
proto.entity.dealtankentity.Favourite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.Favourite;
  return proto.entity.dealtankentity.Favourite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.Favourite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.Favourite}
 */
proto.entity.dealtankentity.Favourite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setUserOrigin(value);
      break;
    case 4:
      var value = new proto.entity.dealtankentity.Timestamp;
      reader.readMessage(value,proto.entity.dealtankentity.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.Favourite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.Favourite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.Favourite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.Favourite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.dealtankentity.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string listing_id = 1;
 * @return {string}
 */
proto.entity.dealtankentity.Favourite.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Favourite} returns this
 */
proto.entity.dealtankentity.Favourite.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.entity.dealtankentity.Favourite.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Favourite} returns this
 */
proto.entity.dealtankentity.Favourite.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.UserOrigin user_origin = 3;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.dealtankentity.Favourite.prototype.getUserOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.dealtankentity.Favourite} returns this
 */
proto.entity.dealtankentity.Favourite.prototype.setUserOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Timestamp time_stamp = 4;
 * @return {?proto.entity.dealtankentity.Timestamp}
 */
proto.entity.dealtankentity.Favourite.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.dealtankentity.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealtankentity.Timestamp, 4));
};


/**
 * @param {?proto.entity.dealtankentity.Timestamp|undefined} value
 * @return {!proto.entity.dealtankentity.Favourite} returns this
*/
proto.entity.dealtankentity.Favourite.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealtankentity.Favourite} returns this
 */
proto.entity.dealtankentity.Favourite.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealtankentity.Favourite.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string vehicle_id = 5;
 * @return {string}
 */
proto.entity.dealtankentity.Favourite.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.Favourite} returns this
 */
proto.entity.dealtankentity.Favourite.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealtankentity.EndListingReason.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealtankentity.EndListingReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealtankentity.EndListingReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.EndListingReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: jspb.Message.getFieldWithDefault(msg, 1, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealtankentity.EndListingReason}
 */
proto.entity.dealtankentity.EndListingReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealtankentity.EndListingReason;
  return proto.entity.dealtankentity.EndListingReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealtankentity.EndListingReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealtankentity.EndListingReason}
 */
proto.entity.dealtankentity.EndListingReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealtankentity.EndListingReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealtankentity.EndListingReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealtankentity.EndListingReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealtankentity.EndListingReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string question = 1;
 * @return {string}
 */
proto.entity.dealtankentity.EndListingReason.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.EndListingReason} returns this
 */
proto.entity.dealtankentity.EndListingReason.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string answer = 2;
 * @return {string}
 */
proto.entity.dealtankentity.EndListingReason.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealtankentity.EndListingReason} returns this
 */
proto.entity.dealtankentity.EndListingReason.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.entity.dealtankentity.EndListingReasonCategory = {
  END_LISTING_REASON_INVALID: 0,
  END_LISTING_REASON_NOT_SELLING: 1,
  END_LISTING_REASON_SOLD_ELSEWHERE: 2,
  END_LISTING_REASON_OTHER: 3
};

goog.object.extend(exports, proto.entity.dealtankentity);
