import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { EditableVehicle, Vehicle } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useVehicle = (vehicleId: string) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [pbVehicle, setPbVehicle] = useState<adminApiv1Pb.Vehicle | null>(null);
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);

  function _getVehicle(): Promise<adminApiv1Pb.GetVehicleResponse> {
    const request = new adminApiv1Pb.GetVehicleRequest();
    request.setVehicleId(vehicleId);
    return client.getVehicle(request, authHeader);
  }

  function _updateVehicle(updatedVehicle: EditableVehicle): Promise<adminApiv1Pb.UpdateVehicleResponse> {
    if (!pbVehicle) return Promise.reject('Invalid pbVehicle');

    // Note: Currently there is no function to convert "Vehicle.AsObject" object to "Vehicle" object,
    // hence setting each (editable) field of the original "Vehicle" object separately.
    pbVehicle.setTitle(`${updatedVehicle.make} ${updatedVehicle.model} ${updatedVehicle.trimLevel}`);
    pbVehicle.setMake(updatedVehicle.make);
    pbVehicle.setYear(updatedVehicle.year);
    pbVehicle.setModel(updatedVehicle.model);
    pbVehicle.setTrimLevel(updatedVehicle.trimLevel);
    pbVehicle.setInteriorColor(updatedVehicle.interiorColor);
    pbVehicle.setInteriorType(updatedVehicle.interiorType);
    pbVehicle.setExteriorColor(updatedVehicle.exteriorColor);
    pbVehicle.setEngine(updatedVehicle.engine);
    pbVehicle.setEngineSize(updatedVehicle.engineSize);
    pbVehicle.setStatus(updatedVehicle.status);
    pbVehicle.setMileage(updatedVehicle.mileage);
    pbVehicle.setMileageUom(updatedVehicle.mileageUom);
    pbVehicle.setVin(updatedVehicle.vin);

    pbVehicle.setPrice(updatedVehicle.price);
    pbVehicle.setTransmission(updatedVehicle.transmission);
    pbVehicle.setFuelType(updatedVehicle.fuelType);
    pbVehicle.setDriveTrain(updatedVehicle.driveTrain);
    pbVehicle.setTitle(updatedVehicle.title);

    const updatedLocation = updateLocation(updatedVehicle);
    pbVehicle.setLocation(updatedLocation);

    const annoucement = updatePaveAnnouncement(updatedVehicle);
    pbVehicle.setVehiclePaveAnnouncement(annoucement);
    const newOptions = updatedVehicle.vehicleOptionsList.map((option) => {
      const vehicleOption = new adminApiv1Pb.VehicleOption();
      vehicleOption.setCode(option.code);
      vehicleOption.setType(option.type);
      vehicleOption.setName(option.name);
      vehicleOption.setHighValue(option.highValue);
      return vehicleOption;
    });

    if (newOptions.length === 0) {
      const vehicleOption = new adminApiv1Pb.VehicleOption();
      vehicleOption.setCode('DELETE');
      vehicleOption.setType('DELETE');
      vehicleOption.setName('DELETE');
      vehicleOption.setHighValue(false);
      newOptions.push(vehicleOption);
    }
    pbVehicle.setVehicleOptionsList(newOptions);

    const request = new adminApiv1Pb.UpdateVehicleRequest();

    request.setVehicle(pbVehicle);
    return client.updateVehicle(request, authHeader);
  }

  const updatePaveAnnouncement = (updatedVehicle: EditableVehicle): adminApiv1Pb.VehiclePaveAnnouncement => {
    const annoucement = new adminApiv1Pb.VehiclePaveAnnouncement();
    annoucement.setAllKeysPresent(updatedVehicle.vehiclePaveAnnouncement?.allKeysPresent || '');
    annoucement.setBooksPresent(updatedVehicle.vehiclePaveAnnouncement?.booksPresent || false);
    annoucement.setDiscsPresent(updatedVehicle.vehiclePaveAnnouncement?.discsPresent || false);
    annoucement.setOtherAnnouncements(updatedVehicle.vehiclePaveAnnouncement?.otherAnnouncements || '');
    annoucement.setRimsTires(updatedVehicle.vehiclePaveAnnouncement?.rimsTires || false);
    annoucement.setTitlePresent(updatedVehicle.vehiclePaveAnnouncement?.titlePresent || false);
    return annoucement;
  };

  const updateLocation = (updatedVehicle: EditableVehicle): adminApiv1Pb.VehicleLocation => {
    const updatedLocation = new adminApiv1Pb.VehicleLocation();
    updatedLocation.setAddress1(updatedVehicle.location?.address1 || '');
    updatedLocation.setAddress2(updatedVehicle.location?.address2 || '');
    updatedLocation.setCity(updatedVehicle.location?.city || '');
    updatedLocation.setCountry(updatedVehicle.location?.country || '');
    updatedLocation.setPostalCode(updatedVehicle.location?.postalCode || '');
    updatedLocation.setState(updatedVehicle.location?.state || '');
    return updatedLocation;
  };

  const getVehicle = async () => {
    if (!isConnectionReady) return;

    const res = await _getVehicle();
    setPbVehicle(res.getVehicle() || null);
    setVehicle(res.toObject().vehicle || null);
  };

  const updateVehicle = async (updatedVehicle: EditableVehicle) => {
    if (!authHeader) return;

    await _updateVehicle(updatedVehicle);
    await getVehicle();
  };

  useEffect(() => {
    try {
      getVehicle();
    } catch (error) {
      console.error('getVehicle', error);
    }
  }, [isConnectionReady]);

  return { vehicle, updateVehicle, getVehicle };
};
