import { FiExternalLink } from 'react-icons/fi';

interface ViewDealTankVdpProps {
  vehicleId: string;
}
function ViewDealTankVdp({ vehicleId }: ViewDealTankVdpProps) {
  return (
    <a href={process.env.REACT_APP_DEALER + '/deal-tank/' + vehicleId} target="_blank" rel="noreferrer">
      <FiExternalLink /> View VDP
    </a>
  );
}

export default ViewDealTankVdp;
