import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { User } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetProfile = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [user, setUser] = useState<User | null>(null);
  const [isConnectionReady] = useApiReady();

  useEffect(() => {
    function getUserProfile(): Promise<adminApiv1Pb.GetProfileResponse> {
      return new Promise<adminApiv1Pb.GetProfileResponse>((resolve, reject) => {
        const request = new adminApiv1Pb.GetProfileRequest();
        request.setUserId('me');

        client.getProfile(request, authHeader, (err, response) => {
          if (err) {
            reject(err);
          } else {
            resolve(response);
          }
        });
      });
    }

    const fetchProfile = async () => {
      if (!isConnectionReady) return;

      try {
        const res = await getUserProfile();
        const resProfile = res.toObject().user || null;
        setUser(resProfile);
      } catch (error) {
        console.error('fetchProfile: ', error);
      }
    };

    fetchProfile();
  }, [isConnectionReady]);

  return user;
};
