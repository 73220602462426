import moment from 'moment';
import { useEffect, useState } from 'react';

function useDateSinglePicker(displayFormat = 'YYYY-MM-DD') {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateInNumber, setSelectedDateInNumber] = useState(0);

  useEffect(() => {
    const dateInString = (selectedDate && moment(selectedDate).format(displayFormat)) || '';

    setSelectedDateInNumber(convertTo(dateInString));
  }, [selectedDate]);

  function resetDate() {
    setSelectedDate(new Date());
  }

  return {
    selectedDate,
    setSelectedDate,
    selectedDateInNumber,
    resetDate,
  };
}

export default useDateSinglePicker;

function convertTo(date: string) {
  const stringTime = moment(date).format('x');
  return +stringTime * 10 ** 6;
}
