import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useVehicleImageProcess = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [loading, setLoading] = useState(true);

  async function processVehicleImage(vehicleId: string, isDealer: boolean) {
    if (!isConnectionReady) return;

    setLoading(true);
    const request = new adminApiv1Pb.ProcessImagesForVehicleRequest();
    request.setVehicleId(vehicleId);
    if (isDealer) {
      request.setVehicleOrigin(enumPb.VehicleOrigin.VEHICLE_ORIGIN_DEALER);
    } else {
      request.setVehicleOrigin(enumPb.VehicleOrigin.VEHICLE_ORIGIN_CONSUMER);
    }

    try {
      const response = await client.processImagesForVehicle(request, authHeader);
      const result = response.toObject();
      return result;
    } catch (error) {
      console.error('processImagesForVehicle:', error);
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    processVehicleImage,
  };
};
