import { useState } from 'react';

type CopiedValue = string | null;
// eslint-disable-next-line no-unused-vars
type CopyFn = (text: string) => Promise<boolean>;

export const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      setIsCopied(true);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    } finally {
      setTimeout(() => {
        setCopiedText(null);
        setIsCopied(false);
      }, 3000);
    }
  };

  return { copiedText, copy, isCopied };
};

export default useCopyToClipboard;
