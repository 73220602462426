import { useEffect, useState } from 'react';

import { useCompleteSale } from '../../composables/api/useCompleteSale';
import { useGetDealers } from '../../composables/api/useGetDealers';
import useCurrency from '../../composables/utils/useCurrency';
import useDateSinglePicker from '../../composables/utils/useDateSinglePicker';
import useInput from '../../composables/utils/useInput';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';
import DateSinglePicker from '../utils/DateSinglePicker';
import NumInput from '../utils/inputs/NumInput';
import TextInput from '../utils/inputs/TextInput';
import RadioButtons from '../utils/RadioButtons';

interface Message {
  isCreated: boolean;
  text: string;
}

type CompleteConsumerSaleProps = {
  vehicleId: string;
  wayOfSale: enumPb.WayOfSale;
};

function CompleteConsumerSale({ vehicleId, wayOfSale }: CompleteConsumerSaleProps) {
  const defaultPageSize = 250;
  const [message, setMessage] = useState({} as Message);

  const [price, { handleChange: handlePriceChange, setInputVal: setPrice }] = useInput('0');
  const [dealerId, { setInputVal: setDealerId }] = useInput('');
  const { dealers, getDealersWithConditions } = useGetDealers({ defaultPageSize });
  const { currency, currencyOptions, setCurrency, resetCurrency } = useCurrency();
  const { selectedDate, setSelectedDate, selectedDateInNumber, resetDate } = useDateSinglePicker();
  const { createSale } = useCompleteSale();

  async function handleGetDealersByName(name: string, page = 1) {
    try {
      await getDealersWithConditions({
        name,
        pageSize: defaultPageSize,
        pageNum: page,
      });
    } catch (error) {
      console.error('handleGetDealersByName ', error);
    }
  }

  useEffect(() => {
    reset();
  }, [vehicleId]);

  function reset() {
    resetDate();
    setPrice('0');
    resetCurrency();
    setDealerId('');
    setMessage({} as Message);
  }

  async function handleCreateSale() {
    try {
      await createSale({
        price: +price,
        currency,
        vehicleId,
        buyerId: dealerId,
        buyerOrigin: enumPb.UserOrigin.USER_ORIGIN_DEALER,
        effectiveDate: selectedDateInNumber,
        wayOfSale: wayOfSale,
        vehicleOrigin: enumPb.VehicleOrigin.VEHICLE_ORIGIN_CONSUMER,
        offerId: '',
      });

      setMessage({
        isCreated: true,
        text: 'Success! Please go to "Offer Accepted" view for this updated vehicle.',
      });
    } catch (error) {
      console.error('Create sale failed: ', error);

      setMessage({
        isCreated: false,
        text: 'Create sale unsuccessful. This vehicle might have completed a sale already.',
      });
    }
  }

  return (
    <div className={styles.CompleteConsumerSaleContainer}>
      <h3>Complete Sale</h3>

      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled handleChange={() => {}} />

      <div className={styles.miscContainer}>
        <label htmlFor="date">Sale date</label>
        <DateSinglePicker label="Sale complete date" selectedDate={selectedDate} onDateSelect={setSelectedDate} />
      </div>

      <NumInput defaultMin={0} value={price} label="Price" handleChange={handlePriceChange} />

      <div className={styles.miscContainer}>
        <label htmlFor="currency">Currency</label>
        <div id="currency">
          <RadioButtons options={currencyOptions} currentSelected={currency} onClick={setCurrency} />
        </div>
      </div>

      <BaseSearchInput placeholder="Search Dealers by Keyword" onSearch={handleGetDealersByName} />
      <div className={styles.miscContainer}>
        <label htmlFor="dealerId">Dealer</label>
        <BaseExpandSelect label="" selectEvent={setDealerId} options={dealers} selectedValue={dealerId} />
      </div>

      <BaseButtonOutline
        display="Create Sale"
        onClick={handleCreateSale}
        small
        className={styles.createSaleBtn}
        disabled={!+price || !dealerId}
      />

      {!!message.text && (
        <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>
      )}
    </div>
  );
}

export default CompleteConsumerSale;
