import './scss/App.scss';

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { Outlet, useLocation } from 'react-router-dom';

import Navbar from './components/navigation/Navbar';
import NavDrawer from './components/navigation/NavDrawer';
import LandingPage from './components/utils/LandingPage';
import Loading from './components/utils/Loading';
import Modal from './components/utils/Modal';
import UtilityDrawer from './components/utils/UtilityDrawer';
import Welcome from './components/utils/Welcome';
import { useNavDrawer } from './contexts/NavDrawerContext';
import { useViewport } from './contexts/ViewPortContext';

function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  const { isDrawerOpen, setIsDrawerOpen } = useNavDrawer();
  const { isTablet, isMobile } = useViewport();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsDrawerOpen(isAuthenticated && !isTablet && !isMobile);

    ReactModal.setAppElement('.App');
  }, [isTablet, isMobile, isAuthenticated]);

  if (isLoading) {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }
  return (
    <div className="App">
      <Navbar />
      <NavDrawer />
      <UtilityDrawer />
      <Modal />
      <main className={isDrawerOpen ? 'withDrawer' : 'noDrawer'}>
        {isAuthenticated && pathname === '/' && <Welcome />}
        {isAuthenticated && <Outlet />}
        {!isAuthenticated && <LandingPage />}
      </main>
    </div>
  );
}

export default App;
