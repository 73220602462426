import { useAdminClient } from '../../contexts/AdminClientContext';
import { AddVehiclesToAuctionRequest } from '../../grpc/build/protos/adminapiv1_pb';
import { AuctionVehiclePricing } from '../../grpc/build/protos/entities_pb';
import { useApiReady } from '../utils/useApiReady';
import { AuctionVehiclePriceMap } from './types';
import { useAuthHeader } from './useAuthHeader';

type AddVehiclesProps = {
  auctionId: string;
  vehicleIds: string[];
  pricingMap: AuctionVehiclePriceMap;
};

export default function () {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function addVehiclesToAuction({ auctionId, vehicleIds, pricingMap }: AddVehiclesProps) {
    if (!isConnectionReady) return;

    const request = new AddVehiclesToAuctionRequest();
    request.setAuctionId(auctionId);
    request.setVehicleIdsList(vehicleIds);

    pricingMap.forEach(([key, value]) => {
      const auctionVehiclePricing = new AuctionVehiclePricing();
      auctionVehiclePricing.setStartPrice(value.startPrice);
      auctionVehiclePricing.setCurrency(value.currency);
      request.getPricingMap().set(key, auctionVehiclePricing);
    });

    await client.addVehiclesToAuction(request, authHeader);
  }

  return addVehiclesToAuction;
}
