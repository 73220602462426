import { RiImageAddLine } from 'react-icons/ri';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import ImageUpload from '../upload/ImageUpload';
import ViewAuctionVdp from './ViewAuctionVdp';

interface AddImageProps {
  vehicleId: string;
}
function AddImageVehicle({ vehicleId }: AddImageProps) {
  const { openUtilityDrawer } = useUtilityDrawer();

  const addImages = async (id: string) => {
    openUtilityDrawer(
      <ImageUpload id={id} type="vehicle_id" renderCompleteSection={() => <ViewAuctionVdp vehicleId={id} />} />,
    );
  };

  return (
    <BaseButtonOutline small display="" onClick={() => addImages(vehicleId)}>
      <>
        <RiImageAddLine /> Add Images
      </>
    </BaseButtonOutline>
  );
}

export default AddImageVehicle;
