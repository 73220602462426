import { OfferDocumentObject } from '../../composables/api/types';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface OfferDocumentProps {
  doc: OfferDocumentObject;
  onCancelOfferDocument: (offerDocumentId: string) => void;
}

function OfferDocument({ doc, onCancelOfferDocument }: OfferDocumentProps) {
  const offerDocStatus = getOfferDocStatus();
  const hasOfferDoc = doc.documentStatus === enumPb.DocumentStatus.DOCUMENT_STATUS_SENT;

  function getOfferDocStatus() {
    if (doc.documentStatus === enumPb.DocumentStatus.DOCUMENT_STATUS_COMPLETE) {
      return 'Complete';
    }

    if (doc.documentStatus === enumPb.DocumentStatus.DOCUMENT_STATUS_SENT) {
      return 'Pending';
    }

    if (doc.documentStatus === enumPb.DocumentStatus.DOCUMENT_STATUS_DECLINE) {
      return 'Canceled';
    }
  }

  return (
    <tbody>
      <tr>
        <th> Document ID </th>
        <td> {doc.documentId} </td>
      </tr>
      <tr>
        <th>HelloSign Status</th>
        <td>{offerDocStatus}</td>
      </tr>
      <tr>
        <th>
          <a href={doc.documentUrl}>Click Here to Download</a>
        </th>
      </tr>
      {hasOfferDoc && (
        <BaseButtonOutline
          small
          display={'Cancel Pending Buy-In Agreement'}
          onClick={() => onCancelOfferDocument(doc.documentId)}></BaseButtonOutline>
      )}
    </tbody>
  );
}

export default OfferDocument;
