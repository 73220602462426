import styles from '../../scss/SimpleTable.module.scss';

export interface Cell {
  label: string;
  value: string;
}

interface SimpleTableProps {
  tableTitle?: string;
  displayInfo: Cell[];
}
export function SimpleTable({ tableTitle, displayInfo }: SimpleTableProps) {
  return (
    <table className={styles.simple}>
      {tableTitle && <caption>{tableTitle}</caption>}
      <tbody>
        {displayInfo.map((info) => {
          return (
            <tr key={info.label}>
              <th>{info.label}</th>
              <td>{info.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
