import { useState } from 'react';

import { AuctionVehiclePriceMap } from '../composables/api/types';
import useAddVehiclesToAuction from '../composables/api/useAddVehiclesToAuction';
import { AuctionSummary } from '../composables/api/useAuctions';
import useDoubleCheck from '../composables/utils/useDoubleCheck';
import { getReadableDate } from '../helpers/utils';
import styles from '../scss/AddVehiclesToAuction.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';

type AddVehiclesToAuctionProps = {
  vehicleIds: string[];
  auctions: AuctionSummary[];
  auctionStartPrices: AuctionVehiclePriceMap;
  onComplete: () => void;
};

export default function AddVehiclesToAuction({
  vehicleIds,
  auctions,
  auctionStartPrices,
  onComplete,
}: AddVehiclesToAuctionProps) {
  const [error, setError] = useState('');
  const [selectedAuctionId, setSelectedAuctionId] = useState<string | null>(null);
  const addVehiclesToAuction = useAddVehiclesToAuction();
  const [completed, setCompleted] = useState(false);

  const {
    confirmBtnText: confirmAddBtnText,
    doubleCheck: doubleCheckAdd,
    isConfirmBtnDisabled: isAddConfirmBtnDisabled,
  } = useDoubleCheck({
    buttonTextProp: {
      initialText: 'Add Vehicles To Auction',
      confirmationText: 'Click again to Add Vehicles',
      completionText: 'Vehicles Added!',
    },
    fn: confirmCreate,
  });

  async function confirmCreate() {
    try {
      setError('');

      if (vehicleIds.length > 0 && selectedAuctionId !== null) {
        await addVehiclesToAuction({
          auctionId: selectedAuctionId,
          vehicleIds,
          pricingMap: auctionStartPrices,
        });
      }

      setCompleted(true);
      onComplete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }

      throw err; // for useDoubleCheck
    }
  }

  if (completed) {
    return (
      <div className={styles.addVehiclesToAuctionWrapper}>
        <h2>Vehicles Added To Auction!</h2>
      </div>
    );
  }

  if (auctions.length === 0) {
    return (
      <section className={styles.addVehiclesToAuctionWrapper}>
        <p>No Auctions</p>
      </section>
    );
  }

  return (
    <section className={styles.addVehiclesToAuctionWrapper}>
      {vehicleIds.length === 0 && <p style={{ textAlign: 'center' }}>No Vehicles Selected</p>}

      <div className={styles.auctionList}>
        {auctions.map((auction) => (
          <Auction key={auction.auctionId} auction={auction} onSelect={(id: string) => setSelectedAuctionId(id)} />
        ))}

        {vehicleIds.length === 0 && <DisabledOverlay />}
      </div>

      <div>
        <BaseButtonOutline
          display={confirmAddBtnText}
          onClick={() => {
            setError('');
            doubleCheckAdd();
          }}
          disabled={selectedAuctionId === null || isAddConfirmBtnDisabled}
        />
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </section>
  );
}

type AuctionProps = {
  auction: AuctionSummary;
  onSelect: (id: string) => void;
};

function Auction({ auction, onSelect }: AuctionProps) {
  const { auctionId, auctionTitle, numberOfVehicles, startTimeNano } = auction;
  const dateTimeFormat = 'YYYY MMM DD [at] h:mm a';

  return (
    <div key={`auction-${auctionId}`} className={styles.auction}>
      <input
        className={styles.auctionRadio}
        type="radio"
        name="auctions"
        id={`auction-${auctionId}`}
        value={auctionId}
        onChange={() => onSelect(auctionId)}
      />

      <label className={styles.auctionInfo} htmlFor={`auction-${auctionId}`}>
        <h3>{auctionTitle}</h3>
        <div>
          <span>{getReadableDate(startTimeNano, dateTimeFormat)}</span>
          <span>
            {numberOfVehicles} Vehicle{numberOfVehicles === 1 ? '' : 's'}
          </span>
        </div>
      </label>
    </div>
  );
}

function DisabledOverlay() {
  return <div style={{ position: 'absolute', background: 'white', opacity: '.5', inset: '0' }}></div>;
}
