import React from 'react';

import styles from '../../scss/Input.module.scss';

export default function BaseTextInput(props: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className={styles.inputContainer}>
      <input {...props} />
    </div>
  );
}
