import moment from 'moment';

export const useDownload = () => {
  const exportToJson = (targetData: unknown) => {
    const today = new Date();
    const fileName = moment(today).format('YYYY-MM-DD');

    downloadFile({
      data: JSON.stringify(targetData, null, 2),
      fileName: `${fileName}.json`,
      fileType: 'text/json',
    });
  };

  const downloadFile = ({ data, fileName, fileType }: DownloadFile) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return {
    exportToJson,
  };
};

interface DownloadFile {
  data: string;
  fileName: string;
  fileType: string;
}
