import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useResetTerms = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function resetTermsAndCondition() {
    if (!isConnectionReady) return null;
    const request = new adminApiv1Pb.ResetTermsAndConditionsRequest();
    await client.resetTermsAndConditions(request, authHeader);
  }

  return resetTermsAndCondition;
};
