import { useHttpService } from '../../composables/http/useHttpService';
import styles from '../../scss/FileUpload.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import { UploadStatus } from './types';
interface UploadProps {
  id: string;
  file: File;
  onClear: () => void;
  onError: (err: string) => void;
  uploadStatus: UploadStatus;
  onReplace: (name: string) => void;
  order?: number;
}

interface Error {
  name: string;
  message: string;
}
const ImageReplacer = ({ id, file, onError, onClear, uploadStatus, onReplace, order }: UploadProps) => {
  const { post } = useHttpService('/replace-photo');

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append('replace-photo', file, file.name);
    formData.append('vehicle_id', id);
    if (order !== undefined) {
      formData.append('order', order.toString());
    }
    try {
      const res = await post(formData);
      const mlImageUrl = res.data;
      if (!mlImageUrl) {
        throw new Error('No image url returned from server');
      }

      onReplace(mlImageUrl);
    } catch (error) {
      console.error('Photo Upload > ..... ', error);
      const msg = `${(error as Error).name}: ${(error as Error).message}`;
      onError(msg);
    }
  };

  return (
    <>
      <div className={styles.uploadContainer}>
        <BaseButtonOutline
          small
          onClick={uploadFile}
          display={uploadStatus === UploadStatus.START ? 'Uploading' : 'Upload Image'}
          disabled={[UploadStatus.DONE, UploadStatus.START].includes(uploadStatus)}
        />
        <BaseButtonOutline
          small
          onClick={onClear}
          display="Cancel"
          disabled={[UploadStatus.DONE, UploadStatus.START].includes(uploadStatus)}
        />
      </div>
    </>
  );
};

export default ImageReplacer;
