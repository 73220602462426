import { useState } from 'react';
import { FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi';

import styles from '../../scss/ImagePreview.module.scss';

interface ImagePreviewProps {
  file: File;
  // eslint-disable-next-line no-unused-vars
  onRemove: (name: string) => void;
}

const ImagePreview = ({ file, onRemove }: ImagePreviewProps) => {
  const [preview, setPreview] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const togglePreview = () => setPreview(!preview);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className={styles.imagePreview}>
      <p aria-label={`File Name: ${file.name}`}>{file.name}</p>
      <div className={styles.wrapper}>
        <div>
          <span>{file.type}</span>
          <span>{(file.size / 1000000).toFixed(2)} mb</span>
        </div>

        <div className={styles.wrapper}>
          <button className={styles.action} onClick={() => onRemove(file.name)}>
            <FiTrash2 />
          </button>
          <button className={styles.action} onClick={togglePreview}>
            {preview ? <FiEyeOff /> : <FiEye />}
          </button>
        </div>
      </div>

      {preview && (
        <img
          src={URL.createObjectURL(file)}
          style={{ display: isLoaded ? 'block' : 'none' }}
          alt={file.name}
          onLoad={handleImageLoad}
        />
      )}
    </div>
  );
};

export default ImagePreview;
