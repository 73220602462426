import { useAuth0 } from '@auth0/auth0-react';
import { FiUser } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import BaseButtonText from '../../components/base/BaseButtonText';
import { useGetProfile } from '../../composables/api/useGetProfile';
import { useViewport } from '../../contexts/ViewPortContext';

function NavLogin() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const user = useGetProfile();

  const { pathname } = useLocation();
  const { isMobile } = useViewport();

  if (!isAuthenticated) {
    return <BaseButtonText display="Log In" onClick={() => loginWithRedirect({ appState: { returnTo: pathname } })} />;
  }

  return isMobile ? (
    <FiUser onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
  ) : (
    <BaseButtonText
      display={(user && user.name) || 'Log Out'}
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      title="Click to log out"
    />
  );
}

export default NavLogin;
