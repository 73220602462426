import { VehicleStatus } from '../../grpc/build/protos/adminapiv1_pb';
import { convertVehicleStatus } from '../../helpers/utils';
import BaseExpandSelect from '../base/BaseExpandSelect';
import { BasicFieldProps } from './types';

function StatusField({
  viewValue,
  editValue,
  isEditMode,
  propName,
  editFn,
}: BasicFieldProps<VehicleStatus, string, number>) {
  const allowedStatus = [
    VehicleStatus.VEHICLE_STATUS_INSPECTION_COMPLETED,
    VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS,
    VehicleStatus.VEHICLE_STATUS_CONFIRMED,
  ];

  const options = allowedStatus.map((status) => {
    return {
      id: status,
      name: convertVehicleStatus(status),
    };
  });

  const handleSelect = (val: string) => {
    editFn(propName, parseInt(val));
  };

  const getEditGrid = () => {
    if (!isEditMode) return <td></td>;
    if (!allowedStatus.includes(viewValue)) return <td></td>;

    return (
      <td>
        <BaseExpandSelect
          label="Choose a vehicle status:"
          selectEvent={handleSelect}
          options={options}
          selectedValue={editValue}
          useEmptyOption={false}
        />
      </td>
    );
  };

  return (
    <tr key={propName}>
      <td>{propName}</td>
      <td>{convertVehicleStatus(viewValue)}</td>
      {getEditGrid()}
    </tr>
  );
}

export default StatusField;
