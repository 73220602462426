import { FiX } from 'react-icons/fi';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import { joinClasses } from '../../helpers/utils';
import styles from '../../scss/UtilityDrawer.module.scss';

function UtilityDrawer() {
  const { isDrawerOpen, currentComp, closeUtilityDrawer } = useUtilityDrawer();

  const drawerContainerClasses = joinClasses(styles.drawerContainer, isDrawerOpen ? styles.open : '');

  return (
    <div className={drawerContainerClasses}>
      <FiX size="20" color="#2f2f2f" className={styles.closeDrawer} onClick={closeUtilityDrawer} />
      {currentComp}
    </div>
  );
}

export default UtilityDrawer;
