import { useState } from 'react';

interface Response {
  label: string;
  value: string;
}

const useDisplayResponse = (displayFields: Record<string, string>) => {
  const [display, setDisplay] = useState([] as Response[]);

  function createResponseDisplay<T>(response: T) {
    const res = Object.entries(response)
      .filter(([key]) => Object.keys(displayFields).includes(key))
      .map(([key, value]) => ({ label: displayFields[key], value }));

    setDisplay(res);
  }

  return {
    display,
    createResponseDisplay,
  };
};

export default useDisplayResponse;
