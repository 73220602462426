import React, { useState } from 'react';

import { useGetDealers } from '../../composables/api/useGetDealers';
import { useImpersonation } from '../../composables/api/useImpersonation';
import useInput from '../../composables/utils/useInput';
import { UserOrigin } from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/Impersonation.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect, { DataKey } from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';

interface Message {
  isCreated: boolean;
  text: string;
}

function ImpersonateDealer() {
  const defaultPageSize = 250;
  const [impersonatorEmail, { handleChange: handleImpersonatorEmailChange }] = useInput('');
  const { dealers: impersonatedList, getDealersWithConditions: searchImpersonateds } = useGetDealers({
    defaultPageSize,
  });
  const [impersonatedEmail, setImpersonatedEmail] = useState('');
  const [message, setMessage] = useState<Message | null>(null);
  const { impersonate } = useImpersonation();

  async function handleImpersonatedSearch(name: string, page = 1) {
    try {
      await searchImpersonateds({
        name,
        pageSize: defaultPageSize,
        pageNum: page,
      });
    } catch (error) {
      console.error('handleGetDealersByName ', error);
    }
  }

  async function handleImpersonation() {
    try {
      await impersonate(impersonatorEmail, impersonatedEmail, false, UserOrigin.USER_ORIGIN_DEALER);
      setMessage({
        isCreated: true,
        text: 'Success! You are now in impersonation mode',
      });
    } catch (error) {
      console.error('Impersonation failed : ', error);

      setMessage({
        isCreated: false,
        text: 'Impersonation was unsuccessful.',
      });
    }
  }

  async function handleImpersonationReset() {
    try {
      await impersonate(impersonatorEmail, impersonatedEmail, true, UserOrigin.USER_ORIGIN_DEALER);
      setMessage({
        isCreated: true,
        text: 'Success! Impersonation removed',
      });
    } catch (error) {
      console.error('Impersonation reset failed : ', error);

      setMessage({
        isCreated: false,
        text: 'Failed! Impersonation did not get removed',
      });
    }
  }

  return (
    <div className={styles.settings}>
      <div>
        <label htmlFor="userEmail">Impersonator email address</label>
        <input type="text" id="userEmail" value={impersonatorEmail} onChange={handleImpersonatorEmailChange} />
      </div>
      <br />
      <BaseSearchInput placeholder="Impersonated User" onSearch={handleImpersonatedSearch} />
      <div className={styles.miscContainer}>
        <BaseExpandSelect
          label="Impersonated Dealer"
          selectEvent={setImpersonatedEmail}
          options={impersonatedList.map((item) => {
            item.name = item.email;
            return item;
          })}
          selectedValue={impersonatedEmail}
          dataKey={DataKey.EMAIL}
        />
      </div>
      <BaseButtonOutline
        display="Start Impersonation"
        onClick={handleImpersonation}
        small
        className={styles.createSaleBtn}
        disabled={!impersonatorEmail || !impersonatedEmail} // add pending later
      />
      <br />
      <BaseButtonOutline
        display="Reset Impersonation"
        small
        onClick={handleImpersonationReset}
        disabled={!impersonatorEmail}
      />
      <br />
      {!!message && <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>}
    </div>
  );
}

export default ImpersonateDealer;
