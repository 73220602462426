import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { CompleteSaleProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useCompleteSale = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const createSale = async ({
    price,
    currency,
    vehicleId,
    buyerId,
    buyerOrigin,
    effectiveDate,
    wayOfSale,
    vehicleOrigin,
    offerId,
  }: CompleteSaleProps) => {
    if (!isConnectionReady) return;

    const request = new adminApiv1Pb.CreateCompletedSaleRequest();
    request.setPrice(price);
    request.setCurrency(currency);
    request.setVehicleId(vehicleId);
    request.setBuyerId(buyerId);
    request.setBuyerOrigin(buyerOrigin);
    request.setEffectiveDate(effectiveDate);
    request.setWayOfSale(wayOfSale);
    request.setVehicleOrigin(vehicleOrigin);
    request.setOfferId(offerId);
    const response = await client.createCompletedSale(request, authHeader);

    return response.toObject();
  };

  return { createSale };
};
