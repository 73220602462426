import React, { useState } from 'react';

import { useAcceptDealTankOffer } from '../../composables/api/useAcceptDealTankOffer';
import { useListDealTankPendingOffers } from '../../composables/api/useListDealTankPendingOffers';
import coStyles from '../../scss/ChangeOwnership.module.scss';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import TextInput from '../utils/inputs/TextInput';

type AcceptOfferProps = {
  vehicleId: string;
};

interface Message {
  isCreated: boolean;
  text: string;
}

export default function AcceptOffer({ vehicleId }: AcceptOfferProps) {
  const { offers } = useListDealTankPendingOffers(vehicleId);
  const [message, setMessage] = useState<Message | null>(null);
  const { acceptDealTankOffer, pending } = useAcceptDealTankOffer();

  async function handleAcceptOffer(offerId: string) {
    try {
      setMessage({
        isCreated: true,
        text: 'Success!',
      });
      await acceptDealTankOffer(offerId);
    } catch (error) {
      console.error('Accept offer failed : ', error);
      setMessage({
        isCreated: false,
        text: 'Accept offer unsuccessful!',
      });
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Accept Offer</h3>
      <TextInput name={'vehicleId'} value={vehicleId} disabled />

      <section>
        {offers.map((offer, idx) => (
          <div key={`${idx}-${offer.offerId}`} className={coStyles.listItem}>
            <h3>Offeror: {offer.dealerName ? offer.dealerName : 'Buyer'}</h3>
            <p>Price: {offer.price}</p>
            <p>Origin: {offer.origin}</p>
            <BaseButtonOutline
              display={'Accept offer'}
              onClick={() => handleAcceptOffer(offer.offerId)}
              disabled={pending}
            />

            {!!message && (
              <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>
            )}
          </div>
        ))}
      </section>
    </div>
  );
}
