import { useEffect, useState } from 'react';

import { DealTankVehicle, EditableFacet, ListingStatus } from '../../composables/api/types';
import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import * as searchEntityPb from '../../grpc/build/protos/searchentity_pb';
import { Facet, FacetFilter, FilterType } from '../../grpc/build/protos/searchentity_pb';
import { converListingStatus, converListingStatusFromString } from '../../helpers/utils';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export type ListingStatusItem = {
  display: string;
  value: ListingStatus;
};

const allListingStatus = [ListingStatus.ACTIVE, ListingStatus.INACTIVE];

type DealTankVehicleItem = DealTankVehicle & {
  listingStatus: enumPb.ListingStatus;
  vehicleOrigin: enumPb.VehicleOrigin;
  listingId: string;
  wantTradeIn: boolean;
};

type FetchVehiclesProps = {
  keyword?: string;
  listingStatus?: enumPb.ListingStatus;
  offerStatus?: enumPb.OfferStatus;
  facets?: EditableFacet[];
  pageNumber: number;
};

export default function useDealTankVehicles(pageSize = 10) {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [facets, setFacets] = useState<EditableFacet[]>([]);
  const [vehicles, setVehicles] = useState<DealTankVehicleItem[]>([]);
  const [allListingStatuses, setAllListingStatuses] = useState<ListingStatusItem[]>([]);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [pending, setPending] = useState(false);

  async function _fetchVehicles({
    keyword,
    listingStatus,
    offerStatus,
    pageNumber = 1,
  }: FetchVehiclesProps): Promise<adminApiv1Pb.ListDealTankVehiclesResponse> {
    const request = new adminApiv1Pb.ListDealTankVehiclesRequest();
    request.setIsFavourite(false);
    const pagination = new adminApiv1Pb.Pagination();
    pagination.setPageSize(pageSize);
    pagination.setOffset(pageNumber);
    request.setPagination(pagination);
    request.setListingSort(enumPb.ListingSort.LISTING_SORT_EXPIRATION_TIME_ASC);
    request.setFacetFiltersList(parseFacetTerms(facets));

    keyword && request.setKeyword(keyword);
    listingStatus && request.setListingStatus(listingStatus);

    request.setOfferStatus(offerStatus || enumPb.OfferStatus.OFFER_STATUS_INVALID);
    request.setUserId('');
    return client.listDealTankVehicles(request, authHeader);
  }

  function _fetchVehicleStatusesAndFacets(
    keyword: string,
    facetList: string,
    listingStatus: enumPb.ListingStatus,
    _facets?: EditableFacet[],
  ): Promise<adminApiv1Pb.ListDealTankVehicleFacetsResponse> {
    const request = new adminApiv1Pb.ListDealTankVehicleFacetsRequest();
    request.setKeyword(keyword);
    request.setFacetList(facetList);
    request.setListingStatus(listingStatus);
    _facets && request.setFacetFiltersList(parseFacetTerms(_facets));

    return client.listDealTankVehicleFacets(request, authHeader);
  }

  function parseFacetTerms(_facets: EditableFacet[]) {
    return _facets
      .filter((facet) => {
        return facet.countsList.some(({ isSelected }) => {
          return isSelected;
        });
      })
      .map((facet) => {
        const facetFilter = new FacetFilter();
        facetFilter.setFieldName(facet.fieldName);

        facetFilter.setValuesList(
          facet.countsList
            .filter(({ isSelected }) => {
              return isSelected;
            })
            .map(({ value }) => value),
        );
        facetFilter.setFilterType(FilterType.FILTER_TYPE_OR);

        return facetFilter;
      });
  }

  async function getFacets(keyword: string, listingStatus: enumPb.ListingStatus) {
    const dealTankFacetList = [
      'make',
      'year',
      'deal_tank_info.has_pending_offers',
      'deal_tank_info.offer_status',
      'deal_tank_info.listing_status',
      'location.state',
    ];

    try {
      if (!isConnectionReady) return;

      setPending(true);
      const res = await _fetchVehicleStatusesAndFacets(keyword, dealTankFacetList.join(','), listingStatus);
      handleFacets(res.getFacetCountsList());
    } finally {
      setPending(false);
    }
  }

  async function getListingStatus(keyword: string) {
    const listingStatusFacet = ['deal_tank_info.listing_status'];

    try {
      if (!isConnectionReady) return;

      setPending(true);
      const res = await _fetchVehicleStatusesAndFacets(keyword, listingStatusFacet.join(','), ListingStatus.INVALID);
      handleListingStatus(res.getListingStatus()?.getCountsList());
    } finally {
      setPending(false);
    }
  }

  function handleListingStatus(listingStatus?: searchEntityPb.FacetCount[]) {
    const filteredListingStatuses = allListingStatus.map((status) => {
      const convertedStatus = listingStatus?.find(
        (count) => status === converListingStatusFromString(count.getValue()),
      );

      return {
        display: converListingStatus(status) + ' ' + (convertedStatus?.getCount() || 0),
        value: status,
      };
    });

    setAllListingStatuses(filteredListingStatuses);
  }

  function handleFacets(facets: Facet[]) {
    const newFacets = facets.map((facet) => {
      const countList = facet.getCountsList().map((count) => {
        const newCount = {
          count: count.getCount(),
          value: count.getValue(),
          isSelected: false,
        };
        return newCount;
      });

      const newFacet: EditableFacet = {
        countsList: countList,
        fieldName: facet.getFieldName(),
      };

      return newFacet;
    });

    setFacets(newFacets);
  }

  async function fetchVehicles({ keyword, listingStatus, offerStatus, pageNumber = 1 }: FetchVehiclesProps) {
    try {
      if (!isConnectionReady) return;

      setPending(true);

      const res = await _fetchVehicles({ keyword, listingStatus, offerStatus, pageNumber });
      const obj = res.toObject();

      const vehicles = obj.vehiclesList
        .map((v) => {
          return (
            {
              ...v.vehicle,
              listingStatus: v.vehicle?.listingStatus,
              vehicleOrigin: v.vehicle?.vehicleOrigin,
            } || null
          );
        })
        .filter((v): v is DealTankVehicleItem => v !== null);

      setVehicles(vehicles);
      setTotalVehicles(obj.total);
    } catch (err) {
      console.error('fetchVehicles', err);
    } finally {
      setPending(false);
    }
  }

  useEffect(() => {
    try {
      fetchVehicles({
        listingStatus: ListingStatus.INVALID,
        pageNumber: 1,
      });
      getFacets('', ListingStatus.INVALID);
      getListingStatus('');
    } catch (error) {
      console.error('listVehicles:', error);
    }
  }, [isConnectionReady]);

  return {
    vehicles,
    totalVehicles,
    getFacets,
    facets,
    setFacets,
    pending,
    fetchVehicles,
    getListingStatus,
    allListingStatuses,
  };
}
