import React from 'react';

import styles from '../../scss/BaseCheckbox.module.scss';

type BaseCheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  labelPosition?: string;
  fontSize?: string;
};

// TODO: this is not really a BASE checkbox if it has a label with it (see BaseTextInput/TextInput and BaseSelect/SelectInput)
export default function BaseCheckbox({ checked, onChange, label, labelPosition, fontSize }: BaseCheckboxProps) {
  const pos = labelPosition || 'right';

  return (
    <label className={styles.container} style={{ fontSize: fontSize || '16px' }}>
      {pos === 'left' && <span>{label}</span>}
      <input type="checkbox" checked={checked} onChange={onChange} />
      {pos === 'right' && <span>{label}</span>}
    </label>
  );
}
