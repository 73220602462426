import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { CreateDealerProps, DealerAccount, UpdateDealerProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useCreateDealer = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const [pending, setPending] = useState(false);

  async function createDealerAccount(props: CreateDealerProps): Promise<DealerAccount> {
    if (!isConnectionReady) return {} as DealerAccount;

    const request = new adminApiv1Pb.CreateDealerAccountRequest();
    request.setName(props.name);
    request.setFamilyName(props.familyName);
    request.setGivenName(props.givenName);
    request.setEmail(props.email);
    request.setPhone(props.phone);
    request.setDealerId(props.dealerId);
    request.setUserRole(props.userRole);
    request.setRegistrationNumber(props.registrationNumber);

    const response = await client.createDealerAccount(request, authHeader);
    return response.toObject().dealer || ({} as DealerAccount);
  }

  async function updateDealerAccount(props: UpdateDealerProps): Promise<DealerAccount> {
    if (!isConnectionReady) return {} as DealerAccount;

    try {
      setPending(true);
      const request = new adminApiv1Pb.UpdateDealerAccountRequest();
      request.setUserId(props.userId);
      request.setFamilyName(props.familyName);
      request.setGivenName(props.givenName);
      request.setPhone(props.phone);
      request.setEmail(props.email);
      request.setIsLeadContact(props.isLeadContact);
      request.setIsResponsibleMultiStores(props.isResponsibleMultiStores);
      request.setRegistrationNumber(props.registrationNumber);

      const response = await client.updateDealerAccount(request, authHeader);
      return response.toObject().dealer || ({} as DealerAccount);
    } finally {
      setPending(false);
    }
  }

  async function resetDealerAccountPassword(props: { userId: string }): Promise<string> {
    if (!isConnectionReady) return '';
    try {
      setPending(true);

      const request = new adminApiv1Pb.ResetDealerAccountPasswordRequest();
      request.setUserId(props.userId);

      return (await client.resetDealerAccountPassword(request, authHeader)).getPassword();
    } finally {
      setPending(false);
    }
  }

  return { createDealerAccount, updateDealerAccount, resetDealerAccountPassword, pending };
};
