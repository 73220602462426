import { Dealer } from '../../composables/api/types';
import style from '../../scss/DealerListItem.module.scss';

type DealerListItemProps = {
  dealer: Dealer;
};

function nanoToDateString(timeInNanoseconds: number) {
  if (!timeInNanoseconds) return 'N/A';
  return new Date(timeInNanoseconds / 1000000).toISOString().split('T')[0];
}

function DealerListItem({ dealer }: DealerListItemProps) {
  return (
    <div className={style.listItem}>
      <h3>{dealer.givenName + ' ' + dealer.familyName}</h3>
      <p>ID: {dealer.id}</p>
      <p>Email: {dealer.email}</p>
      <p>Dealership: {dealer.dealershipName}</p>
      <p>Registration Number: {dealer.registrationNumber}</p>
      <p>Terms & Condition accepted: {dealer.isTermsAccepted ? 'Accepted' : 'Not Accepted'}</p>
      <p>
        Terms & Condition accepted date:{' '}
        {dealer.isTermsAccepted ? nanoToDateString(dealer.termsAcceptedTime) : 'Not Accepted'}
      </p>
      <p>Is Responsible For Multistores Checkout: {dealer.isResponsibleMultiStores ? 'Yes' : 'No'}</p>
      <p>Is Lead Contact: {dealer.isLeadContact ? 'Yes' : 'No'}</p>
      <small>Last sign in: {dealer.lastSignIn}</small>
    </div>
  );
}

export default DealerListItem;
