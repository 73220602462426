import { IconType } from 'react-icons';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { RiAuctionLine, RiCarLine, RiOpenArmLine, RiToolsFill, RiUserHeartLine } from 'react-icons/ri';

const auctionVehicles: SubGroup = {
  heading: 'Auction Vehicles',
  Icon: RiCarLine,
  links: [
    { to: '/vehicle-search', text: 'Vehicle Search' },
    { to: '/auction-search', text: 'Auction Search' },
    { to: '/ready-for-auction', text: 'Ready for Auction' },
    { to: '/scheduled-auctions', text: 'Scheduled Auctions' },
  ],
};

const auctions: SubGroup = {
  heading: 'Auctions',
  Icon: RiAuctionLine,
  links: [{ to: '/add-test-vehicles', text: 'Add test vehicles' }],
};

const dealTank: SubGroup = {
  heading: 'Deal Tank',
  Icon: MdOutlineLocalOffer,
  links: [{ to: '/deal-tank', text: 'Deal Tank Vehicles' }],
};

const dealers: SubGroup = {
  heading: 'Dealers',
  Icon: RiOpenArmLine,
  links: [
    { to: '/dealer-list', text: 'Dealer List' },
    { to: '/dealership-list', text: 'Dealership List' },
    { to: '/create-dealer', text: 'Create Dealership Rep' },
    { to: '/update-dealer-rep', text: 'Update Dealer Rep' },
    { to: '/create-dealership', text: 'Create Dealership' },
    { to: '/update-dealership', text: 'Update Dealership' },
    { to: '/autogroup-list', text: 'Autogroups' },
    { to: '/update-autogroup', text: 'Update Autogroup' },
    { to: '/create-autogroup', text: 'Create Autogroup' },
  ],
};

const consumers: SubGroup = {
  heading: 'Consumers',
  Icon: RiUserHeartLine,
  links: [
    { to: '/consumer-signup', text: 'New Consumer Signups' },
    { to: '/consumer-buy', text: 'Consumer Buy Vehicles' },
  ],
};

const devTools: SubGroup = {
  heading: 'Dev Tools',
  Icon: RiToolsFill,
  links: [
    { to: '/dev-tools', text: 'Time Converter' },
    { to: '/impersonation', text: 'Impersonation' },
    { to: '/inspection-questions', text: 'Inspection Questions' },
  ],
};

export type SubGroup = {
  heading: string;
  Icon: IconType;
  to?: string;
  links?: {
    to: string;
    text: string;
  }[];
};

export const NAV_LIST: SubGroup[][] = [[auctionVehicles, auctions, dealTank, dealers, consumers], [devTools]];
