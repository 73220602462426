import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as dealerApiv1Pb from '../../grpc/build/protos/dealer_pb';
import * as entitiesPb from '../../grpc/build/protos/entities_pb';
import { useApiReady } from '../utils/useApiReady';
import { DealerEntity, DealershipItem, GetDealershipsByNameProps, UpdateDealershipProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useDealerships = (pageSize: number = 20) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [dealerships, setDealerships] = useState<DealerEntity[]>([]);
  const [dealershipItems, setDealershipItems] = useState<DealershipItem[]>([]);
  const [isConnectionReady] = useApiReady();
  const [dealershipCount, setDealershipCount] = useState(0);
  const [dealershipItemsCount, setDealershipItemsCount] = useState(0);
  const [searchingDealerships, setSearchingDealerships] = useState(false);

  const updateDealership = async ({
    street,
    country,
    division,
    city,
    postal,
    phone,
    primaryMake,
    legalName,
    gst,
    pst,
    dealerRegistration,
    rin,
    originDealership,
    subscriptionPlan,
    googlePlaceId,
    externalDealerId,
    accountantEmail,
    autoGroupId,
    dealershipReferrerId,
    dealershipReferrerNotes,
    controllerEmail,
    salesManagerEmail,
    gmEmail,
  }: UpdateDealershipProps) => {
    const request = new adminApiv1Pb.UpdateDealershipRequest();
    const updatedDealership = new dealerApiv1Pb.Dealer();
    const updatedLocation = new dealerApiv1Pb.DealerLocation();
    updatedLocation.setStreet(street);
    updatedLocation.setCountry(country);
    updatedLocation.setState(division);
    updatedLocation.setCity(city);
    updatedLocation.setPostal(postal);
    updatedDealership.setDealerLocation(updatedLocation);

    updatedDealership.setPhone(phone);
    updatedDealership.setPrimaryMake(primaryMake);
    updatedDealership.setLegalName(legalName);
    updatedDealership.setGstNumber(gst);
    updatedDealership.setPstNumber(pst);
    updatedDealership.setProvinceRegistrationNo(dealerRegistration);
    updatedDealership.setRin(rin);
    updatedDealership.setControllerEmail(controllerEmail);
    updatedDealership.setSalesManagerEmail(salesManagerEmail);
    updatedDealership.setGmEmail(gmEmail);

    updatedDealership.setId(originDealership.id);
    updatedDealership.setName(originDealership.name);
    updatedDealership.setFeedId(originDealership.feedId);
    updatedDealership.setCrmDealerId(originDealership.crmDealerId);
    updatedDealership.setEmail(originDealership.email);
    updatedDealership.setAutoGroupId(originDealership.autoGroupId);
    updatedDealership.setSubscriptionPlan(subscriptionPlan);
    updatedDealership.setGooglePlaceId(googlePlaceId);
    updatedDealership.setExternalDealerId(externalDealerId);
    updatedDealership.setAccountantEmail(accountantEmail);
    updatedDealership.setAutoGroupId(autoGroupId);

    const dealershipReferral = new entitiesPb.DealershipReferral();
    dealershipReferral.setReferrerId(dealershipReferrerId);
    dealershipReferral.setNotes(dealershipReferrerNotes);

    updatedDealership.setDealershipReferral(dealershipReferral);

    request.setDealership(updatedDealership);
    await client.updateDealership(request, authHeader);

    window.location.assign('/update-dealership/' + originDealership.id);
  };

  const deleteDealershipLogo = async (dealershipId: string) => {
    const request = new adminApiv1Pb.DeleteDealershipLogoRequest();
    request.setDealershipId(dealershipId);
    await client.deleteDealershipLogo(request, authHeader);
    window.location.assign('/update-dealership/' + dealershipId);
  };

  async function getDealerships({ name, pageSize, offSet }: GetDealershipsByNameProps) {
    if (!isConnectionReady) return null;
    setSearchingDealerships(true);
    try {
      const request = new adminApiv1Pb.ListDealershipsRequest();
      const pagination = new adminApiv1Pb.Pagination();
      pagination.setOffset(offSet || 0);
      pagination.setPageSize(pageSize || 20);
      request.setPagination(pagination);

      name && request.setKeyWord(name);

      const response = await client.listDealerships(request, authHeader);
      setDealerships(response.toObject().dealers?.dealersList || []);
      setDealershipCount(response.toObject().dealers?.totalNumberOfDealerships || 0);
    } catch (error) {
      console.error('getDealerships:', error);
      alert('Failed to List Dealerships');
      setDealerships([]);
      setDealershipCount(0);
    } finally {
      setSearchingDealerships(false);
    }
  }

  useEffect(() => {
    getDealerships({
      offSet: 0,
      pageSize: pageSize,
    });
  }, [isConnectionReady]);

  async function getDealershipItems({ name, pageSize, offSet }: GetDealershipsByNameProps) {
    if (!isConnectionReady) return null;
    try {
      const request = new adminApiv1Pb.ListDealershipItemsRequest();
      const pagination = new adminApiv1Pb.Pagination();
      pagination.setOffset(offSet || 0);
      pagination.setPageSize(pageSize || 20);
      request.setPagination(pagination);

      name && request.setKeyword(name);

      const response = await client.listDealershipItems(request, authHeader);
      setDealershipItems(
        response.toObject().dealershiplistitemsList.map((item) => {
          return {
            ...item,
            address: item.address,
          } as DealershipItem;
        }) || [],
      );
      setDealershipItemsCount(response.toObject().total || 0);
    } catch (error) {
      console.error('getDealerships:', error);
      alert('Failed to List Dealerships');
      setDealerships([]);
      setDealershipCount(0);
    }
  }

  useEffect(() => {
    getDealershipItems({
      offSet: 0,
      pageSize: pageSize,
    });
  }, [isConnectionReady]);

  return {
    dealerships,
    dealershipCount,
    dealershipItemsCount,
    dealershipItems,
    searchingDealerships,
    getDealerships,
    updateDealership,
    deleteDealershipLogo,
    getDealershipItems,
  };
};
