import { useGenerateDataOneReport } from '../../composables/api/useGenerateDataOneReport';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface DataOneReportProps {
  vehicleId: string;
}

const DataOne_BTN_TEXT = {
  initialText: 'Generate DataOne',
  confirmationText: 'Click again to generate DataOne report',
  completionText: 'DataOne Process Kicked off Successfully',
};

function DataOneReport({ vehicleId }: DataOneReportProps) {
  const createDataOneRerportFn = useGenerateDataOneReport();
  const createDataOneReport = async () => {
    try {
      await createDataOneRerportFn(vehicleId);
    } catch (error) {
      console.error(error);
      alert('Failed to kick off DataOne');
    }
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: DataOne_BTN_TEXT,
    fn: createDataOneReport,
  });
  return (
    <BaseButtonOutline
      small
      display={confirmBtnText}
      onClick={doubleCheck}
      disabled={isConfirmBtnDisabled}></BaseButtonOutline>
  );
}

export default DataOneReport;
