import { useEffect, useState } from 'react';

import { DealerAccount } from '../../composables/api/types';
import { useCreateDealer } from '../../composables/api/useCreateDealer';
import { useDealerships } from '../../composables/api/useDealerships';
import { useGetDealers } from '../../composables/api/useGetDealers';
import useCreateEmail from '../../composables/utils/useCreateEmail';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import { UserRole } from '../../grpc/build/protos/adminapiv1_pb';
import styles from '../../scss/CreateDealer.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';

function CreateDealer() {
  const defaultListSize = 30;
  const [dealerId, { setInputVal: setDealerId }] = useInput('');
  const [firstName, { handleChange: handleFirstNameChange, setInputVal: setFirstName }] = useInput('');
  const [lastName, { handleChange: handleLastNameChange, setInputVal: setLastName }] = useInput('');
  const [phone, { handleChange: handlePhoneChange, setInputVal: setPhone }] = useInput('');
  const [registrationNum, { handleChange: handleRegistrationNumChange, setInputVal: setRegistrationNum }] =
    useInput('');
  const { createDealerAccount } = useCreateDealer();
  const { dealerships, getDealerships } = useDealerships();
  const { getAllDealers, dealers } = useGetDealers({});

  const handleGetAllDealers = async () => {
    try {
      await getAllDealers();
    } catch (error) {
      console.error('handleGetAllDealers: ', error);
    }
  };

  useEffect(() => {
    handleGetAllDealers();
  }, []);

  async function handleGetDealershipsByName(name: string) {
    try {
      await getDealerships({
        name,
        pageSize: defaultListSize,
        offSet: 1,
      });
    } catch (error) {
      console.error('handleGetDealershipsByName ', error);
    }
  }

  const { email, emailErrMsg, handleEmailChange, setEmail } = useCreateEmail(dealers);

  const [enableCreateBtn, setEnableCreateBtn] = useState(false);

  useEffect(() => {
    const hasAllRequired = [dealerId, firstName, lastName, email].every((elm) => !!elm.trim());
    setEnableCreateBtn(hasAllRequired && emailErrMsg === '');
  }, [dealerId, firstName, lastName, email, emailErrMsg]);

  const [dealerInfo, setDealerInfo] = useState({} as DealerAccount);
  const [errorMsg, setErrorMsg] = useState('');

  const BTN_TEXT = {
    initialText: 'Create Dealership Rep',
    confirmationText: 'Click again to create dealership rep',
    completionText: 'Dealership Rep Created!',
  };
  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleDealerCreate,
    useRestore: true,
  });

  async function handleDealerCreate() {
    try {
      const dealerResponse = await createDealerAccount({
        name: `${firstName} ${lastName}`,
        familyName: lastName,
        givenName: firstName,
        email,
        phone,
        dealerId,
        userRole: UserRole.USER_ROLE_DEALER,
        // TODO Add Toggle
        isLeadContact: false,
        isResponsibleMultiStores: false,
        registrationNumber: registrationNum,
      });

      if (!dealerResponse.password) {
        setErrorMsg('Dealer Creation Fail');
        return;
      }

      setErrorMsg('');

      setDealerInfo(dealerResponse);

      clearForm();

      handleGetAllDealers();
    } catch (error) {
      console.error('createDealer: ', error);
      setErrorMsg('Dealer Creation Fail');
    }
  }

  const clearForm = () => {
    setDealerId('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setRegistrationNum('');
  };

  return (
    <div className={styles.formContainer}>
      <h2>Create Dealership Rep</h2>

      <div>
        <section className={styles.settings}>
          <div>
            <label htmlFor="dealerId">Dealership ID *</label>
            <input id="dealerId" value={dealerId} disabled />

            <div className={styles.selectDealerships}>
              <BaseSearchInput placeholder="Search Dealerships by Keyword" onSearch={handleGetDealershipsByName} />
              <BaseExpandSelect
                label="Choose Dealership:"
                selectEvent={setDealerId}
                options={dealerships}
                selectedValue={dealerId}
              />
            </div>
          </div>
          <div>
            <label htmlFor="firstName">Given Name *</label>
            <input id="firstName" value={firstName} onChange={handleFirstNameChange} />
          </div>
          <div>
            <label htmlFor="lastName">Family Name *</label>
            <input id="lastName" value={lastName} onChange={handleLastNameChange} />
          </div>
          <div>
            <label htmlFor="email">Email *</label>
            <input id="email" value={email} onChange={handleEmailChange} />
            <p className="label-error">{emailErrMsg}</p>
          </div>
          <div>
            <label htmlFor="phone">Phone</label>
            <input id="phone" value={phone} onChange={handlePhoneChange} />
          </div>
          <div>
            <label htmlFor="registrationNum">Salesperson Registration Number</label>
            <input id="registrationNum" value={registrationNum} onChange={handleRegistrationNumChange} />
          </div>
          <div>
            <BaseButtonOutline
              display={confirmBtnText}
              onClick={doubleCheck}
              disabled={!enableCreateBtn || isConfirmBtnDisabled}
            />
          </div>
        </section>

        {errorMsg && (
          <section className={styles.results}>
            <p>{errorMsg}</p>
          </section>
        )}

        {dealerInfo.id && (
          <section className={styles.results}>
            <div>
              <p>Name: {dealerInfo.name}</p>
              <p>Email: {dealerInfo.email}</p>
              <p>Phone: {dealerInfo.phone}</p>
              <p>Id: {dealerInfo.id}</p>
              <p>PW: {dealerInfo.password}</p>
              <p>Registration Number: {dealerInfo.registrationNumber}</p>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default CreateDealer;
