import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { DealerEntity } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetDealership = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function getDealership(id: string): Promise<DealerEntity | null> {
    if (!isConnectionReady || !id) return null;

    try {
      const request = new adminApiv1Pb.GetDealershipRequest();
      request.setDealershipId(id);
      const response = await client.getDealership(request, authHeader);
      const dealership = response.toObject().dealer || null;
      return dealership || null;
    } catch (error) {
      console.error('getDealership: ', error);
      return null;
    }
  }

  return { getDealership };
};
