import { useEffect, useState } from 'react';

import { DealerAccount } from '../../composables/api/types';
import { useCreateDealer } from '../../composables/api/useCreateDealer';
import { useGetDealers } from '../../composables/api/useGetDealers';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import styles from '../../scss/CreateDealer.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';
import BooleanToggle from '../utils/BooleanToggle';

function UpdateDealer() {
  const defaultListSize = 30;
  const { dealers, getDealersWithConditions } = useGetDealers({});
  const [dealerId, { setInputVal: setDealerId }] = useInput('');
  const [dealershipId, { setInputVal: setDealershipId }] = useInput('');
  const [dealerEmail, { handleChange: handleDealerEmailChange, setInputVal: setDealerEmail }] = useInput('');
  const [firstName, { handleChange: handleFirstNameChange, setInputVal: setFirstName }] = useInput('');
  const [lastName, { handleChange: handleLastNameChange, setInputVal: setLastName }] = useInput('');
  const [phone, { handleChange: handlePhoneChange, setInputVal: setPhone }] = useInput('');
  const [registrationNum, { handleChange: handleRegistrationNumChange, setInputVal: setRegistrationNum }] =
    useInput('');
  const [isResponsibleMultiStores, setIsResponsibleMultiStores] = useState(false);
  const [isLeadContact, setIsLeadContact] = useState(false);

  const [password, setPassword] = useState('');

  const { updateDealerAccount, resetDealerAccountPassword, pending } = useCreateDealer();

  const [enableCreateBtn, setEnableCreateBtn] = useState(false);
  const [enableResetBtn, setEnableResetBtn] = useState(false);

  useEffect(() => {
    const hasAllRequired = [firstName, lastName, phone].every((elm) => !!elm.trim());
    setEnableCreateBtn(hasAllRequired);
    setEnableResetBtn(!!dealerId);
  }, [firstName, lastName, phone]);

  const [dealerInfo, setDealerInfo] = useState({} as DealerAccount);
  const [errorMsg, setErrorMsg] = useState('');

  const BTN_TEXT = {
    initialText: 'Update Dealer',
    confirmationText: 'Click again to Update dealer',
    completionText: 'Dealer Updated!',
  };
  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleDealerUpdate,
    useRestore: true,
  });

  async function handleResetPassword() {
    const password = await resetDealerAccountPassword({ userId: dealerId });
    if (!password) {
      setErrorMsg('Reset Password Fail');
      setPassword('error');
      return;
    }
    setPassword(password);
  }

  const RESET_BTN_TEXT = {
    initialText: 'Reset Dealer Password',
    confirmationText: 'Click again to reset dealer password',
    completionText: 'Dealer Password Updated!',
  };

  const {
    confirmBtnText: resetConfirmBtnText,
    doubleCheck: resetDoubleCheck,
    isConfirmBtnDisabled: isResetConfirmBtnDisabled,
  } = useDoubleCheck({
    buttonTextProp: RESET_BTN_TEXT,
    fn: handleResetPassword,
    useRestore: true,
  });

  function handleDealerSelect(dealerId: string) {
    const dealer = dealers.find((ele) => ele.id === dealerId);
    if (!dealer) return;

    setFirstName(dealer.givenName);
    setLastName(dealer.familyName);
    setPhone(dealer.phone);
    setDealerEmail(dealer.email);
    setDealershipId(dealer.dealershipId);
    setDealerId(dealer.id);
    setDealerEmail(dealer.email);
    setIsResponsibleMultiStores(dealer.isResponsibleMultiStores);
    setIsLeadContact(dealer.isLeadContact);
    setRegistrationNum(dealer.registrationNumber);

    setDealerInfo({} as DealerAccount);
    setPassword('');
  }

  async function handleGetDealersByName(name: string, page = 1) {
    try {
      await getDealersWithConditions({
        name,
        pageSize: defaultListSize,
        pageNum: page,
      });
    } catch (error) {
      console.error('handleGetDealersByName ', error);
    }
  }

  async function handleDealerUpdate() {
    try {
      const dealerResponse = await updateDealerAccount({
        userId: dealerId,
        familyName: lastName,
        givenName: firstName,
        phone,
        isLeadContact: isLeadContact,
        isResponsibleMultiStores: isResponsibleMultiStores,
        isFeeAccepted: false,
        email: dealerEmail,
        registrationNumber: registrationNum,
      });

      if (!dealerResponse.id) {
        setErrorMsg('Dealer Update Fail');
        return;
      }

      setErrorMsg('');

      setDealerInfo(dealerResponse);

      await getDealersWithConditions({});
    } catch (error) {
      console.error('UpdateDealer: ', error);
      setErrorMsg('Dealer Creation Fail');
    }
  }

  const handleIsResponsibleMultiStoreOption = () => {
    setIsResponsibleMultiStores((prevCheck) => !prevCheck);
  };

  const handleIsLeadContact = () => {
    setIsLeadContact((prevCheck) => !prevCheck);
  };

  return (
    <div className={styles.formContainer}>
      <h2>Update Dealer: {firstName + ' ' + lastName}</h2>
      <h3>{dealerEmail}</h3>
      <div>
        <section className={styles.settings}>
          <BaseSearchInput placeholder="Search Dealers by Keyword" onSearch={handleGetDealersByName} />
          <BaseExpandSelect
            label="Choose Dealer Rep:"
            selectEvent={handleDealerSelect}
            options={dealers}
            selectedValue={dealerId}
          />
          <div>
            <label htmlFor="dealerId">Dealership ID *</label>
            <input id="dealerId" value={dealershipId} disabled />
          </div>
          <div>
            <label htmlFor="firstName">Given Name *</label>
            <input id="firstName" value={firstName} onChange={handleFirstNameChange} />
          </div>
          <div>
            <label htmlFor="lastName">Family Name *</label>
            <input id="lastName" value={lastName} onChange={handleLastNameChange} />
          </div>
          <div>
            <label htmlFor="phone">Phone</label>
            <input id="phone" value={phone} onChange={handlePhoneChange} />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input id="email" value={dealerEmail} onChange={handleDealerEmailChange} />
          </div>
          <div>
            <label htmlFor="registrationNum">Salesperson Registration Number</label>
            <input id="registrationNum" value={registrationNum} onChange={handleRegistrationNumChange} />
          </div>
          <div>
            <BooleanToggle
              title="Is Responsible for Multi Store Checkout"
              id="isResponsibleForMultiStore"
              checked={isResponsibleMultiStores}
              onClick={handleIsResponsibleMultiStoreOption}
            />
          </div>
          <div>
            <BooleanToggle
              title="Is Lead Contact"
              id="isLeadContact"
              checked={isLeadContact}
              onClick={handleIsLeadContact}
            />
          </div>

          <div>
            <BaseButtonOutline
              display={confirmBtnText}
              onClick={doubleCheck}
              disabled={!enableCreateBtn || isConfirmBtnDisabled || pending}
            />
          </div>
        </section>

        {errorMsg && (
          <section className={styles.results}>
            <p>{errorMsg}</p>
          </section>
        )}

        <div>
          <BaseButtonOutline
            display={resetConfirmBtnText}
            onClick={resetDoubleCheck}
            disabled={!enableResetBtn || isResetConfirmBtnDisabled || pending}
          />
        </div>
        <div>{password && !pending ? password : pending ? 'loading...' : ''}</div>

        {dealerInfo.id && (
          <section className={styles.results}>
            <div>
              <p>Name: {dealerInfo.givenName + ' ' + dealerInfo.familyName}</p>
              <p>Email: {dealerInfo.email}</p>
              <p>Phone: {dealerInfo.phone}</p>
              <p>Id: {dealerInfo.id}</p>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default UpdateDealer;
