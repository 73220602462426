import { useEffect, useState } from 'react';
import { FiCheck, FiList } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { EditableVehicle } from '../../composables/api/types';
import { useVehicle } from '../../composables/api/useVehicle';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import { VehicleStatus } from '../../grpc/build/protos/adminapiv1_pb';
import styles from '../../scss/RelistVehicle.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface RelistVehicleProps {
  vehicleId: string;
}

function RemoveVehicleFromReadyForAuction({ vehicleId }: RelistVehicleProps) {
  const [removed, setRemoved] = useState(false);
  const { vehicle, updateVehicle } = useVehicle(vehicleId);
  const [updatedVehicle, setUpdatedVehicle] = useState({} as EditableVehicle);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (vehicle) {
      const clone = JSON.parse(JSON.stringify(vehicle)) as EditableVehicle;
      clone.status = VehicleStatus.VEHICLE_STATUS_DEACTIVATED;
      setUpdatedVehicle(clone);
    }
  }, [vehicle]);

  const handleRemoval = async () => {
    if (!updatedVehicle) return;
    setErrMsg('');

    try {
      await updateVehicle(updatedVehicle);
      setRemoved(true);
    } catch (error) {
      console.error('Fail updating the vehicle for removal: ', error);
      setErrMsg('Removal failed. Please reload and try again.');
    }
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: {
      initialText: 'Remove vehicle',
      confirmationText: 'Confirm removal',
      completionText: 'Vehicle is deactivated',
    },
    fn: handleRemoval,
  });

  async function handleClick() {
    doubleCheck();
  }

  if (errMsg !== '') {
    return <p className={styles.relistError}>{errMsg}</p>;
  }

  if (removed) {
    return (
      <p className={styles.relistedMessage}>
        {' '}
        <FiCheck /> This vehicle has been moved to <Link to="/vehicle-search">Deactivated</Link>
      </p>
    );
  }

  return (
    <>
      <BaseButtonOutline small onClick={handleClick} disabled={isConfirmBtnDisabled}>
        <>
          <FiList /> {confirmBtnText}
        </>
      </BaseButtonOutline>
    </>
  );
}

export default RemoveVehicleFromReadyForAuction;
