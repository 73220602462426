import { useEffect, useState } from 'react';

import { AuctionVehiclePriceMap } from '../composables/api/types';
import useFetchAuctions from '../composables/api/useAuctions';
import styles from '../scss/CreateAuctionWrapper.module.scss';
import AddVehiclesToAuction from './AddVehiclesToAuction';
import CreateNewAuction from './CreateNewAuction';
import RadioButtons from './utils/RadioButtons';

type ComponentProps = {
  vehicleIds: string[];
  auctionStartPrices: AuctionVehiclePriceMap;
  onComplete: () => void;
};

enum ModeOptions {
  CREATE_NEW_AUCTION = 'Create A New Auction',
  ADD_TO_AUCTION = 'Add Vehicles To Existing Auction',
}

const modeOptions = Object.entries(ModeOptions).map(([, val]) => ({
  value: val,
  display: val,
}));

export default function CreateAuction({ vehicleIds, auctionStartPrices, onComplete }: ComponentProps) {
  const [selectedMode, setSelectedMode] = useState(ModeOptions.CREATE_NEW_AUCTION);
  const { auctions, pending: fetchingAuctions } = useFetchAuctions();
  const [complete, setComplete] = useState(false);

  function handleComplete() {
    setComplete(true);
    onComplete();
  }

  useEffect(() => {
    if (auctions.length > 0 && vehicleIds.length > 0) {
      setSelectedMode(ModeOptions.ADD_TO_AUCTION);
    }
  }, [auctions]);

  if (fetchingAuctions) {
    return <p style={{ textAlign: 'center' }}>Loading...</p>;
  }

  return (
    <div className={styles.formContainer}>
      {!complete && (
        <>
          <p>Number of ready vehicles: {vehicleIds.length}</p>
          <div className={styles.modes}>
            <RadioButtons options={modeOptions} currentSelected={selectedMode} onClick={setSelectedMode} />
          </div>
        </>
      )}

      {selectedMode === ModeOptions.ADD_TO_AUCTION && (
        <AddVehiclesToAuction
          auctions={auctions}
          vehicleIds={vehicleIds}
          auctionStartPrices={auctionStartPrices}
          onComplete={handleComplete}
        />
      )}

      {selectedMode === ModeOptions.CREATE_NEW_AUCTION && (
        <CreateNewAuction vehicleIds={vehicleIds} auctionStartPrices={auctionStartPrices} onComplete={handleComplete} />
      )}
    </div>
  );
}
