import { useEffect } from 'react';

import { DealershipItem, DealershipReferrer } from '../../composables/api/types';
import useDisplayResponse from '../../composables/createDealership/useDisplayResponse';
import { addressFields, basicFields, carfaxFields, dealershipReferralFields } from '../../constants/dealership';
import { getReadableDate } from '../../helpers/utils';
import styles from '../../scss/DealershipListItem.module.scss';
import UpdateCarfaxCredentialsButton from '../inspection/UpdateCarfaxCredentialsButton';
import { SimpleTable } from '../utils/SimpleTable';

interface DealershipListItemProps {
  dealershipInfo: DealershipItem;
  onUpdate?: (name: string) => Promise<void>;
  referrer?: DealershipReferrer;
}
function DealershipListItem({ dealershipInfo, onUpdate }: DealershipListItemProps) {
  const { display: basicDisplay, createResponseDisplay: createBasicDisplay } = useDisplayResponse(basicFields);
  const { display: addressDisplay, createResponseDisplay: createAddressDisplay } = useDisplayResponse(addressFields);
  const { display: carfaxDisplay, createResponseDisplay: createCarfaxDisplay } = useDisplayResponse(carfaxFields);
  const { display: referralDisplay, createResponseDisplay: createReferralDisplay } =
    useDisplayResponse(dealershipReferralFields);

  useEffect(() => {
    createBasicDisplay({
      ...dealershipInfo,
      subscriptionChangeDate: getReadableDate(dealershipInfo.subscriptionDate),
    });
    createAddressDisplay(dealershipInfo.address);
    createReferralDisplay(dealershipInfo);
    updateCarfaxDisplay(dealershipInfo.carfax?.accountId || '', dealershipInfo.carfax?.token || '');
  }, []);

  function updateCarfaxDisplay(accountId: string, token: string) {
    if (onUpdate) onUpdate('');
    var carfaxCredentials = {
      accountId: accountId,
      token: token !== '' ? '* * * * * * * * * * *' : '',
    };

    createCarfaxDisplay(carfaxCredentials);
  }

  return (
    <>
      <SimpleTable displayInfo={basicDisplay} />
      <div>
        <SimpleTable displayInfo={addressDisplay} />
        {dealershipInfo.carfax && (
          <div className={styles.carfaxTable}>
            <SimpleTable displayInfo={carfaxDisplay} />
          </div>
        )}

        <div className={styles.carfaxTable}>
          <SimpleTable displayInfo={referralDisplay} />
        </div>
      </div>
      <div>
        <UpdateCarfaxCredentialsButton
          dealershipId={dealershipInfo.id}
          accountId={dealershipInfo.carfax?.accountId || ''}
          token={dealershipInfo.carfax?.token || ''}
          onUpdate={updateCarfaxDisplay}
        />
        <a href={'update-dealership/' + dealershipInfo.id}>
          <button>Update Dealership </button>
        </a>
      </div>
    </>
  );
}

export default DealershipListItem;
