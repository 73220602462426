import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useState } from 'react';

import { EditableFacet, RemovableVehicle } from '../composables/api/types';
import useGetVehicles, { VehicleStatusItem } from '../composables/api/useGetVehicles';
import * as adminApiv1Pb from '../grpc/build/protos/adminapiv1_pb';
import { VehicleStatus } from '../grpc/build/protos/adminapiv1_pb';
import { WayOfSale } from '../grpc/build/protos/enum_pb';
import { convertVehicleStatus } from '../helpers/utils';
import style from '../scss/AuctionSearch.module.scss';
import BidInfo from './auction/BidInfo';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseVehicleListItem from './base/BaseVehicleListItem';
import BaseWrapper from './base/BaseWrapper';
import AddImageVehicle from './utils/AddImageVehicle';
import AddOffer from './utils/AddOffer';
import AddOfferExtension from './utils/AddOfferExtension';
import CarfaxReport from './utils/CarfaxReport';
import CompleteSaleButton from './utils/CompleteSaleButton';
import DataOneReport from './utils/DataOneReport';
import DealTankButton from './utils/DealTankButton';
import EditAuctionVehicle from './utils/EditAuctionVehicle';
import Loading from './utils/Loading';
import Paginator from './utils/Paginator';
import RegisteredOwner from './utils/RegisteredOwner';
import SetListingPaymentRecieved from './utils/SetListingPaymentReceived';
import SetVehicleAuctionReady from './utils/SetVehicleAuctionReady';
import TradeInInfos from './utils/TradeInInfo';
import TransferOwnership from './utils/TransferOwnership';
import VehiclePrices from './utils/VehiclePrices';
import VehicleSearch from './utils/VehicleSearch';
import VehicleSearchFacet from './utils/VehicleSearchFacet';
import ViewAuctionVdp from './utils/ViewAuctionVdp';

function VehicleSearchPage() {
  const [viewingStatus, setViewingStatus] = useState<VehicleStatusItem>({
    display: '',
    value: VehicleStatus.VEHICLE_STATUS_INVALID,
  });

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const {
    vehicleList,
    facets,
    setFacets,
    totalVehicles,
    allVehicleStatuses,
    fetchVehicles,
    clearVehicles,
    getStatusCounts,
  } = useGetVehicles(pageSize);
  const [keyword, setKeyword] = useState('');

  async function handleVehicleStatus(vehicleStatus: VehicleStatusItem) {
    await getVehicles(keyword, 1, [], vehicleStatus.value);
    setCurrentPage(1);
    setViewingStatus(vehicleStatus);
  }

  function handleSearch(_keyword: string) {
    setKeyword(_keyword);
    setCurrentPage(1);
    getVehicles(_keyword, 1, [], viewingStatus.value);
    getStatusCounts(_keyword, []);
  }

  function handleReset() {
    setKeyword('');
    setFacets([]);
    setCurrentPage(1);
    getVehicles('', 1, [], VehicleStatus.VEHICLE_STATUS_INVALID);
    getStatusCounts('', []);
    setViewingStatus({
      display: '',
      value: VehicleStatus.VEHICLE_STATUS_INVALID,
    });
  }

  function isInpsectionCompleted(status?: VehicleStatus) {
    if (!status) return false;
    return (
      status === VehicleStatus.VEHICLE_STATUS_INSPECTION_COMPLETED ||
      status === VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS
    );
  }
  function isDeactivated(status?: VehicleStatus) {
    if (!status) return false;
    return status === VehicleStatus.VEHICLE_STATUS_DEACTIVATED;
  }

  // function isRejected(status?: VehicleStatus) {
  //   if (!status) return false;
  //
  //   return status === VehicleStatus.VEHICLE_STATUS_AUCTION_REJECTED;
  // }

  function isAuctionReady(status?: VehicleStatus) {
    if (!status) return false;

    return status === VehicleStatus.VEHICLE_STATUS_CONFIRMED;
  }

  function isVehicleCreated(status?: VehicleStatus) {
    if (!status) return false;

    return status === VehicleStatus.VEHICLE_STATUS_CREATED;
  }

  // function isFloorPriceEditable(status?: VehicleStatus) {
  //   if (!status) return false;
  //   return status === VehicleStatus.VEHICLE_STATUS_AUCTION_INCLUDED;
  // }
  //
  // function isRemovable(status?: VehicleStatus) {
  //   if (!status) return false;
  //
  //   return (
  //     status === VehicleStatus.VEHICLE_STATUS_AUCTION_INCLUDED || status === VehicleStatus.VEHICLE_STATUS_AUCTION_ACTIVE
  //   );
  // }

  // function isReferrerEditable(status?: VehicleStatus) {
  //   if (!status) return false;
  //   return (
  //     status === VehicleStatus.VEHICLE_STATUS_AUCTION_ACTIVE ||
  //     status === VehicleStatus.VEHICLE_STATUS_AUCTION_INCLUDED ||
  //     status === VehicleStatus.VEHICLE_STATUS_AUCTION_READY
  //   );
  // }

  function handleNewPage(newPage: number) {
    setCurrentPage(newPage);
    getVehicles(keyword, newPage, facets, viewingStatus.value);
  }

  // handleSelectFacets: Sets values isSelected to enable/disable the radio button
  // If the value has been selected, the value with the fieldName would get parsed into Facet Filters
  async function handleSelectFacets(fieldName: string, value: string) {
    const updatedFacetList = facets.map((item) => {
      if (item.fieldName !== fieldName) {
        return item;
      }

      // Mapping the list of values from the facet
      const values = item.countsList.map((itemValue) => {
        if (value !== itemValue.value) {
          return itemValue;
        }

        return {
          ...itemValue,
          isSelected: !itemValue.isSelected,
        };
      });

      if (values.length > 0) item.countsList = values;

      return item;
    });

    setFacets(updatedFacetList);
    setCurrentPage(1);
    await getVehicles(keyword, 1, updatedFacetList, viewingStatus.value);
  }

  async function getVehicles(
    keyword: string,
    pageNum: number,
    // updateFacet: boolean,
    _facets: EditableFacet[],
    vehicleStatus?: adminApiv1Pb.VehicleStatus,
  ) {
    clearVehicles();

    try {
      if (vehicleStatus === viewingStatus.value) {
        if (keyword) {
          await fetchVehicles(keyword, pageNum, _facets, true, vehicleStatus);
        } else {
          await fetchVehicles(keyword, pageNum, _facets, false, vehicleStatus);
        }
      } else {
        await fetchVehicles(keyword, pageNum, _facets, true, vehicleStatus);
      }
      await getStatusCounts(keyword, _facets);
    } catch (err) {
      console.error('fetchVehicles', err);
      alert('Failed to get vehicles');
    }
  }

  if (!vehicleList) return <div>Pending...</div>;

  return (
    <BaseWrapper>
      <div className={style.postAuction__categories__container}>
        {allVehicleStatuses.map((item, key) => {
          return (
            <div key={key} className={style.vehicle_status_section}>
              <BaseButtonOutline
                onClick={() => handleVehicleStatus(item)}
                display={item.display}
                disabled={viewingStatus.value === item.value}></BaseButtonOutline>
            </div>
          );
        })}
      </div>
      <VehicleSearch onSearch={handleSearch} onReset={handleReset} />
      <h5>{totalVehicles} vehicles found</h5>

      <Paginator
        key={`paginator-top--${viewingStatus}`}
        currentPage={currentPage}
        maxPage={Math.ceil(totalVehicles / pageSize)}
        handleNewPage={handleNewPage}
      />

      <div className={style.postAuction__result__section}>
        {(() => {
          return (
            <div className={style.facet_categories}>
              {facets.map((facet, key) => {
                return (
                  <div key={key}>
                    <VehicleSearchFacet
                      fieldName={facet.fieldName}
                      valueList={facet.countsList}
                      onSelect={handleSelectFacets}
                    />
                  </div>
                );
              })}
            </div>
          );
        })()}

        {(() => {
          if (!vehicleList || vehicleList?.length === 0) {
            return (
              <div>
                <p>No vehicles found with this param. Please reset or contact support</p>
              </div>
            );
          }

          return (
            <ul>
              {vehicleList.sort(sortByCreatedAt).map((vehicle) => (
                <li key={vehicle.id}>
                  <BaseVehicleListItem
                    vehicle={vehicle}
                    additionalFields={[
                      {
                        label: 'Vehicle Status',
                        value: convertVehicleStatus(vehicle.status),
                      },
                      {
                        label: 'Referrer Email',
                        value: vehicle.referrer?.email || '',
                      },
                      {
                        label: 'Want Trade In',
                        value: vehicle.wantTradeIn ? 'Yes' : 'No',
                      },
                    ]}
                    renderVehicleMeta={() => (
                      <section className={style.renderAction}>
                        <div>
                          <ViewAuctionVdp vehicleId={vehicle.id} />
                          <CarfaxReport vehicleId={vehicle.id} />
                          <DataOneReport vehicleId={vehicle.id} />
                          <CompleteSaleButton vehicleId={vehicle.id} wayOfSale={WayOfSale.WAY_OF_SALE_AUCTION} />
                          <AddOffer vehicleId={vehicle.id} />
                          <AddOfferExtension vehicleId={vehicle.id} />
                          <EditAuctionVehicle vehicleId={vehicle.id} />
                        </div>
                        <div>
                          <TransferOwnership vehicleId={vehicle.id} isInAuction={true} />
                          {/*{isRemoved(vehicle.status) && <RelistVehicle vehicle={vehicle} />}*/}
                          {(isInpsectionCompleted(vehicle.status) || isDeactivated(vehicle.status)) && (
                            <SetVehicleAuctionReady vehicleId={vehicle.id} />
                          )}
                          {isAuctionReady(vehicle.status) && (
                            <DealTankButton vehicleId={vehicle.id} onCommit={async () => handleNewPage(currentPage)} />
                          )}
                          {isVehicleCreated(vehicle.status) && <SetListingPaymentRecieved vehicleId={vehicle.id} />}
                          <AddImageVehicle vehicleId={vehicle.id} />
                          <RegisteredOwner vehicleId={vehicle.id} />
                          {vehicle.wantTradeIn && <TradeInInfos vehicleId={vehicle.id} />}
                          <BidInfo vehicleId={vehicle.id} />
                          <VehiclePrices
                            vehicleId={vehicle.id}
                            mileage={vehicle.mileage}
                            mileageUom={vehicle.mileageUom}
                          />
                        </div>
                      </section>
                    )}
                  />
                </li>
              ))}
            </ul>
          );
        })()}
      </div>
      <Paginator
        key={`paginator-bottom-${viewingStatus}`}
        currentPage={currentPage}
        maxPage={Math.ceil(totalVehicles / pageSize)}
        handleNewPage={handleNewPage}
      />
    </BaseWrapper>
  );
}

const sortByCreatedAt = (v1: RemovableVehicle, v2: RemovableVehicle) => {
  if (!v1.timeStamp) return 0;
  if (!v2.timeStamp) return 0;
  return parseInt(v2.timeStamp.createdAt) - parseInt(v1.timeStamp.createdAt);
};

export default withAuthenticationRequired(VehicleSearchPage, {
  onRedirecting: () => <Loading />,
});
