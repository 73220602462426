import React from 'react';

import { useChangeOwnership } from '../../composables/api/useChangeOwnership';
import { useGetConsumerByName } from '../../composables/api/useGetConsumerByName';
import styles from '../../scss/ChangeOwnership.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseSearchInput from '../base/BaseSearchInput';

interface SearchConsumerProps {
  vehicleId: string;
  isInAuction: boolean;
}

function ChangeOwnerConsumer({ vehicleId, isInAuction }: SearchConsumerProps) {
  const { consumers, searchConsumer } = useGetConsumerByName();
  const { changeOwnership } = useChangeOwnership();

  return (
    <div className={styles.searchConsumerContainer}>
      <section>
        <p className={styles.vehicleIdInfo}>Transfer ownership of: {vehicleId}</p>
      </section>

      <section>
        <BaseSearchInput placeholder={'type at least 3 characters'} onSearch={searchConsumer} />
      </section>
      <br />
      <section>
        {consumers.map((user, idx) => (
          <div key={`${idx}-${user.id}`} className={styles.listItem}>
            <h3>Name: {user.name}</h3>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
            <BaseButtonOutline
              display={'Change ownership'}
              onClick={() => changeOwnership(user.id, user.givenName, user.phone, isInAuction, vehicleId)}
            />
          </div>
        ))}
      </section>
    </div>
  );
}

export default ChangeOwnerConsumer;
