/* eslint-disable no-unused-vars */

import React, { createContext, useState } from 'react';

export type UtilityDrawerContent = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (c: boolean) => void;
  currentComp?: React.ReactElement;
  setCurrentComp: (r: React.ReactElement) => void;
};

export const UtilityDrawerContext = createContext<UtilityDrawerContent | null>(null);

export const UtilityDrawerProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentComp, setCurrentComp] = useState<React.ReactElement>();

  return (
    <UtilityDrawerContext.Provider value={{ isDrawerOpen, setIsDrawerOpen, currentComp, setCurrentComp }}>
      {children}
    </UtilityDrawerContext.Provider>
  );
};
