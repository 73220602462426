import { useEffect, useState } from 'react';

type TimeProps = {
  year: number;
  month: number;
  day: number;
  hours: number;
  minutes: number;
  nano: number;
};

type Props = {
  nano?: number;
  onChange: (_: TimeProps) => void;
  disabled?: boolean;
};
export default function DateTimeSelector({ nano, onChange, disabled }: Props) {
  const date = nano ? new Date(nano / 10 ** 6) : new Date();

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const [day, setDay] = useState(date.getDate());
  const [hours, setHours] = useState(date.getHours());
  const [minutes, setMinutes] = useState(date.getMinutes());

  useEffect(() => {
    if (!nano) return;

    const d = new Date(nano / 10 ** 6);
    setYear(d.getFullYear());
    setMonth(d.getMonth());
    setDay(d.getDate());
    setHours(d.getHours());
    setMinutes(d.getMinutes());
  }, [nano]);

  useEffect(() => {
    const d = new Date(year, month, day, hours, minutes);

    onChange({
      year,
      month,
      day,
      hours,
      minutes,
      nano: d.getTime() * 10 ** 6,
    });
  }, [year, month, day, hours, minutes]);

  return (
    <>
      <label>
        Year
        <input
          type="number"
          min="2021"
          max="9999"
          id="year"
          value={year}
          onChange={(e) => setYear(e.target.valueAsNumber)}
          disabled={disabled}
        />
      </label>
      <label>
        Month
        <input
          type="number"
          min="1"
          max="12"
          id="month"
          value={month + 1}
          onChange={(e) => setMonth(e.target.valueAsNumber - 1)}
          disabled={disabled}
        />
      </label>
      <label>
        Day
        <input
          type="number"
          min="1"
          max="31"
          id="day"
          value={day}
          onChange={(e) => setDay(e.target.valueAsNumber)}
          disabled={disabled}
        />
      </label>
      <label>
        Hour
        <input
          type="number"
          min="0"
          max="23"
          id="hour"
          value={hours}
          onChange={(e) => setHours(e.target.valueAsNumber)}
          disabled={disabled}
        />
      </label>
      <label>
        Minute
        <input
          type="number"
          min="0"
          max="59"
          id="minute"
          value={minutes}
          onChange={(e) => setMinutes(e.target.valueAsNumber)}
          disabled={disabled}
        />
      </label>
    </>
  );
}
