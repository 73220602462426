import React, { ComponentProps, FC } from 'react';

import { AdminClientProvider } from './AdminClientContext';
import { ModalProvider } from './ModalContext';
import { NavDrawerProvider } from './NavDrawerContext';
import { UtilityDrawerProvider } from './UtilityDrawerContext';
import { ViewportProvider } from './ViewPortContext';

const providers = [
  AdminClientProvider,
  ViewportProvider,
  NavDrawerProvider,
  UtilityDrawerProvider,
  ModalProvider,
  NavDrawerProvider,
];
const MainProvider = combineComponents(...providers);

export default MainProvider;

/* eslint-disable no-undef */
/* eslint-disable react/display-name */
function combineComponents(...components: FC[]): FC {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: ComponentProps<FC>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
}
