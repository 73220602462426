import { useState } from 'react';

import { MileageUOM } from '../../grpc/build/protos/adminapiv1_pb';

const useMileageUom = () => {
  const defaultMileageUom = MileageUOM.MILEAGE_UOM_KILOMETER;
  const [mileageUom, setMileageUom] = useState(defaultMileageUom);

  const mileageUomOptions = [
    { display: 'Km', value: MileageUOM.MILEAGE_UOM_KILOMETER },
    { display: 'Mi', value: MileageUOM.MILEAGE_UOM_MILES },
  ];

  function resetMileageUom() {
    setMileageUom(defaultMileageUom);
  }
  return {
    mileageUomOptions,
    mileageUom,
    setMileageUom,
    resetMileageUom,
  };
};

export default useMileageUom;
