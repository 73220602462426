import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { AutoGroup, DealerEntity } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useStoreCheckout = (userId?: string) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [dealerships, setDealerships] = useState<DealerEntity[]>([]);
  const [autoGroup, setAutoGroup] = useState<AutoGroup | null>(null);
  const [isConnectionReady] = useApiReady();
  const [loading, setLoading] = useState(true);

  async function getAutoGroupByDealerUserId() {
    if (!isConnectionReady) return null;

    setLoading(true);
    try {
      if (userId) {
        const request = new adminApiv1Pb.GetAutoGroupByDealerUserIdRequest();
        request.setUserId(userId);
        const response = await client.getAutoGroupByDealerUserId(request, authHeader);
        const autoGroup = response.toObject().autoGroup;
        if (autoGroup) {
          setDealerships(autoGroup.dealersList);
          setAutoGroup(autoGroup);
        }
      }
    } catch (error) {
      console.error('Get AutoGroup By Dealer User id', error);
      setAutoGroup(null);
      setDealerships([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAutoGroupByDealerUserId();
  }, [isConnectionReady]);

  return { dealerships, loading, autoGroup };
};
