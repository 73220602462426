import { ChangeEvent, useState } from 'react';

import { User } from '../../composables/api/types';
import { useCompleteSale } from '../../composables/api/useCompleteSale';
import { useConsumerUser } from '../../composables/api/useConsumerUser';
import useCurrency from '../../composables/utils/useCurrency';
import useDateSinglePicker from '../../composables/utils/useDateSinglePicker';
import useInput from '../../composables/utils/useInput';
import useRegions from '../../composables/utils/useRegions';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseSelectOption from '../base/BaseSelectOption';
import DateSinglePicker from '../utils/DateSinglePicker';
import NumInput from '../utils/inputs/NumInput';
import SelectInput from '../utils/inputs/SelectInput';
import TextInput from '../utils/inputs/TextInput';
import RadioButtons from '../utils/RadioButtons';
import SelectConsumerUser from '../utils/SelectConsumerUser';

interface Message {
  isCreated: boolean;
  text: string;
}

type CompleteDealerSaleProps = {
  vehicleId: string;
  wayOfSale: enumPb.WayOfSale;
};

function CompleteDealerSale({ vehicleId, wayOfSale }: CompleteDealerSaleProps) {
  const [message, setMessage] = useState({} as Message);
  const [street, { handleChange: handleStreetChange, setInputVal: setStreet }] = useInput('');
  const { country, setCountry, division, setDivision, countries, divisions, setMultiple: loadLocation } = useRegions();
  const [city, { handleChange: handleCityChange, setInputVal: setCity }] = useInput('');
  const [postal, { handleChange: handlePostalChange, setInputVal: setPostal }] = useInput('');

  const [price, { handleChange: handlePriceChange, setInputVal: setPrice }] = useInput('0');
  const [user, setUser] = useState<User | null>(null);
  const { currency, currencyOptions, setCurrency, resetCurrency } = useCurrency();
  const { selectedDate, setSelectedDate, selectedDateInNumber, resetDate } = useDateSinglePicker();
  const { createSale } = useCompleteSale();
  const { updateConsumerUser } = useConsumerUser();

  const handleUserSelect = (user: User | null) => {
    if (!user) return;

    setUser(user);
    setStreet(user.location?.address1 || '');
    loadLocation({
      country: user.location?.country,
      division: user.location?.state,
    });
    setCity(user.location?.city || '');
    setPostal(user.location?.postalCode || '');
  };

  function handleReset() {
    resetDate();
    setPrice('0');
    resetCurrency();
    setUser(null);
    setMessage({} as Message);
    setStreet('');
    loadLocation({
      country: '',
      division: '',
    });
    setCity('');
    setPostal('');
  }

  async function handleCreateSale() {
    try {
      await createSale({
        price: +price,
        currency,
        vehicleId,
        buyerId: user?.id || '',
        buyerOrigin: enumPb.UserOrigin.USER_ORIGIN_CONSUMER,
        effectiveDate: selectedDateInNumber,
        wayOfSale: wayOfSale,
        vehicleOrigin: enumPb.VehicleOrigin.VEHICLE_ORIGIN_DEALER,
        offerId: '',
      });

      await updateConsumerUser({
        id: user?.id || '',
        address1: street,
        address2: '',
        city: city,
        country: country,
        postalCode: postal,
        state: division,
      });

      setMessage({
        isCreated: true,
        text: 'Success! Completed Sale record has been created.',
      });
    } catch (error) {
      console.error('Create sale failed: ', error);

      setMessage({
        isCreated: false,
        text: 'Create sale unsuccessful. This vehicle might have completed a sale already.',
      });
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Complete Sale</h3>

      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled handleChange={() => {}} />

      <div className={styles.miscContainer}>
        <label htmlFor="date">Sale date</label>
        <DateSinglePicker label="Sale complete date" selectedDate={selectedDate} onDateSelect={setSelectedDate} />
      </div>

      <NumInput defaultMin={0} value={price} label="Price" handleChange={handlePriceChange} />

      <div className={styles.miscContainer}>
        <label htmlFor="currency">Currency</label>
        <div id="currency">
          <RadioButtons options={currencyOptions} currentSelected={currency} onClick={setCurrency} />
        </div>
      </div>

      {user && (
        <fieldset>
          <legend>Address</legend>
          <TextInput label="Street" name="street" value={street} handleChange={handleStreetChange} />
          <div className={styles.sectionTwo}>
            <TextInput label="City" name="city" value={city} handleChange={handleCityChange} />
          </div>

          <div className={styles.sectionTwo}>
            <SelectInput
              label="Country"
              name="country"
              required
              id="countrySelect"
              value={country}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setCountry(e.target.value);
              }}>
              <BaseSelectOption value=""></BaseSelectOption>
              {countries.map(({ name, cca2 }) => (
                <BaseSelectOption key={cca2} value={cca2}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
            <SelectInput
              label="Province / State"
              name="division"
              value={division}
              required
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setDivision(e.target.value);
              }}>
              <BaseSelectOption value=""></BaseSelectOption>
              {divisions.map(({ code, name }) => (
                <BaseSelectOption key={code} value={code}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
          </div>

          <TextInput label="Postal Code" name="postal" value={postal} handleChange={handlePostalChange} />
        </fieldset>
      )}

      <div className={styles.miscContainer}>
        <label htmlFor="Select Consumer">Consumer</label>
        <SelectConsumerUser onSelect={handleUserSelect} onReset={handleReset} />
      </div>

      <BaseButtonOutline
        display="Create Sale"
        onClick={handleCreateSale}
        small
        className={styles.createSaleBtn}
        disabled={!+price || !user}
      />

      {!!message.text && (
        <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>
      )}
    </div>
  );
}

export default CompleteDealerSale;
