import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import VehiclePricesSlideOut from '../auction/VehiclePricesSlideOut';
import BaseButtonOutline from '../base/BaseButtonOutline';

function VehiclePrices({ vehicleId, mileage, mileageUom }: { vehicleId: string; mileage: number; mileageUom: number }) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const openVehiclePrices = async () => {
    openUtilityDrawer(<VehiclePricesSlideOut vehicleId={vehicleId} mileage={mileage} mileageUom={mileageUom} />);
  };

  return <BaseButtonOutline small display="Vehicle Prices" onClick={openVehiclePrices} />;
}

export default VehiclePrices;
