import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useAutoGroups } from '../../composables/api/useAutoGroups';
import BaseWrapper from '../base/BaseWrapper';
import Loading from '../utils/Loading';
import AutoGroupListItem from './AutoGroupListItem';

function AutoGroupList() {
  const { autoGroups } = useAutoGroups();

  return (
    <BaseWrapper>
      <h2>AutoGroups</h2>

      {!autoGroups.length && <p>No Results. Please update the search term or reset.</p>}
      {autoGroups && autoGroups.map((autoGroup) => <AutoGroupListItem key={autoGroup.id} autoGroup={autoGroup} />)}
    </BaseWrapper>
  );
}

export default withAuthenticationRequired(AutoGroupList, {
  onRedirecting: () => <Loading />,
});
