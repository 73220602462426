import { useEffect } from 'react';

import { useVehicle } from '../../composables/api/useVehicle';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import { useEditFields } from '../../composables/vdp/useEditFields';
import { VehicleStatus } from '../../grpc/build/protos/adminapiv1_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';

type SetVehicleAuctionReadyProps = {
  vehicleId: string;
};

function SetVehicleAuctionReady({ vehicleId }: SetVehicleAuctionReadyProps) {
  const BTN_TEXT = {
    initialText: 'Set Auction Ready',
    confirmationText: 'Click again to confirm',
    completionText: 'Vehicle is now auction ready',
  };

  const { vehicle, updateVehicle } = useVehicle(vehicleId || '');
  const { fieldsForEdit, setFields } = useEditFields(true);
  const { confirmBtnText, doubleCheck } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: () => setReady(),
  });

  useEffect(() => {
    setFields(vehicle);
  }, [vehicle]);

  const setReady = async () => {
    try {
      if (!fieldsForEdit) return;

      await updateVehicle({
        ...fieldsForEdit,
        status: VehicleStatus.VEHICLE_STATUS_CONFIRMED,
      });
    } catch (err) {
      alert(`Error when setting vehicle ready ${err}`);
      return;
    }
  };

  return <BaseButtonOutline small display={confirmBtnText} onClick={doubleCheck} />;
}

export default SetVehicleAuctionReady;
