import { useEffect } from 'react';
import { RiAttachment2 } from 'react-icons/ri';

import { Doc, RegisteredOwner, SimpleOfferContainer, SimpleOfferInfo } from '../../composables/api/types';
import useGetRegisteredOwners from '../../composables/api/useGetRegisteredOwners';
import { useOfferDocuments } from '../../composables/api/useOfferDocuments';
import { useHttpService } from '../../composables/http/useHttpService';
import useModal from '../../composables/utils/useModal';
import * as buildProtosEnumPb from '../../grpc/build/protos/enum_pb';
import { WayOfSale } from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/GloveBox.module.scss';
import OfferInfo from '../auction/OfferInfo';
import DocumentModal from './DocumentModal';
import OfferDocuments from './OfferDocuments';
import ViewRegistrationAddress from './RegistrationAddress';

type RegsiteredOwnersProps = {
  vehicleId: string;
  offerInfo: SimpleOfferContainer | null;
  onGetOffers: () => void;
  acceptedOffer: SimpleOfferInfo | null;
  wayOfSale: WayOfSale;
};

export default function RegisteredOwnerModal({
  vehicleId,
  offerInfo,
  onGetOffers,
  acceptedOffer,
  wayOfSale,
}: RegsiteredOwnersProps) {
  const { owners, loading, fetchRegisteredOwners } = useGetRegisteredOwners(vehicleId);

  const {
    cancelOfferDocument,
    createOfferDocument,
    docs,
    listOfferDocuments: resetOfferDocuments,
  } = useOfferDocuments(vehicleId, acceptedOffer?.offerId || '', wayOfSale);

  const { openModal } = useModal();

  const { getUrl } = useHttpService();
  async function handleFrontClick(owner: RegisteredOwner) {
    const doc: Doc = {
      uploaded: true,
      isPdf: owner.driverLicenseFrontUrl.endsWith('pdf'),
      fetchUrl: () => getUrl(owner.driverLicenseFrontUrl),
      label: `Driver's License - Front`,
      docType: buildProtosEnumPb.DashboardDocumentType.DOCUMENT_TYPE_DRIVERS_FRONT,
      externalFileName: owner.driverLicenseFrontUrl,
      abbr: `Driver's License - Front`,
    };

    openModal(<DocumentModal doc={doc} />, {
      contentLabel: doc.label,
    });
  }

  const handleCreateOfferDocument = async (dealershipId?: string) => {
    try {
      await createOfferDocument(dealershipId);
      alert('Send Offer Buy-In Agreement Succesful');
    } catch (error) {
      console.error('Send Offer Document Error', error);
      alert('Failed to Send Offer Buy-In Agreement');
      throw error;
    } finally {
      resetOfferDocuments();
    }
  };

  const handleCancelOfferDocument = async (offerDocumentId: string) => {
    try {
      await cancelOfferDocument(offerDocumentId);
      alert('Cancel Offer Buy-In Agreement Succesful');
    } catch (error) {
      console.error('Cancel Offer Document', error);
      alert('Failed to Send Offer Buy-In Agreement');
      throw error;
    } finally {
      resetOfferDocuments();
    }
  };

  async function handleBackClick(owner: RegisteredOwner) {
    const doc: Doc = {
      uploaded: true,
      isPdf: owner.driverLicenseFrontUrl.endsWith('pdf'),
      fetchUrl: () => getUrl(owner.driverLicenseBackUrl),
      label: `Driver's License - Back`,
      docType: buildProtosEnumPb.DashboardDocumentType.DOCUMENT_TYPE_DRIVERS_BACK,
      externalFileName: owner.driverLicenseFrontUrl,
      abbr: `Driver's License - Back`,
    };

    openModal(<DocumentModal doc={doc} />, {
      contentLabel: doc.label,
    });
  }

  useEffect(() => {
    reset();
  }, [vehicleId]);

  function reset() {
    fetchRegisteredOwners();
  }

  if (loading) {
    return <div>Fetching...</div>;
  }
  return (
    <div className={styles.baseGloveboxItemWrapper}>
      <OfferInfo vehicleId={vehicleId} disabled={!vehicleId} offerInfo={offerInfo} onGetOffers={onGetOffers} />
      {owners.length == 0 && <div>No Registered Owners</div>}
      {owners.length > 0 &&
        owners.map((owner, index) => {
          return (
            <div key={`${index}`} className={styles.baseGloveboxItemContent}>
              <table className={styles.simple}>
                <caption>
                  {owner.isPrimary ? 'Primary Owner:' : 'Co-Owner:'} {owner.name}
                </caption>
                <tbody>
                  <tr>
                    <th>Email</th>
                    <td>{owner.email}</td>
                  </tr>
                  <tr onClick={() => handleFrontClick(owner)}>
                    <th>Driver&apos;s License Front</th>
                    <td>
                      <RiAttachment2 />
                    </td>
                  </tr>
                  <tr onClick={() => handleBackClick(owner)}>
                    <th>Driver&apos;s License Back</th>
                    <td>
                      <RiAttachment2 />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      <div>
        <ViewRegistrationAddress vehicleId={vehicleId} />
      </div>
      <div>
        <OfferDocuments
          offerDocuments={docs}
          acceptedOffer={acceptedOffer}
          onCancelOfferDocument={handleCancelOfferDocument}
          onCreateOfferDocument={handleCreateOfferDocument}
        />
      </div>
    </div>
  );
}
