import React, { useEffect, useState } from 'react';

import { useUpdateFloorPrice } from '../../composables/api/useUpdateFloorPrice';
import useInput from '../../composables/utils/useInput';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import NumInput from '../utils/inputs/NumInput';
import TextInput from '../utils/inputs/TextInput';

interface Message {
  isChanged: boolean;
  text: string;
}

function ChangeFloorPrice({ vehicleId }: { vehicleId: string }) {
  const [price, { handleChange: handlePriceChange, setInputVal: setPrice }] = useInput('0');
  const [message, setMessage] = useState<Message | null>(null);
  const { updateFloorPrice, pending } = useUpdateFloorPrice();

  useEffect(() => {
    reset();
  }, [vehicleId]);

  function reset() {
    setPrice('0');
    setMessage(null);
  }

  async function handleChangeFloorPrice() {
    try {
      await updateFloorPrice({ floorPrice: +price, vehicleId });
      setMessage({
        isChanged: true,
        text: 'Success! Floor Price Changed',
      });
    } catch (e) {
      console.error('Change floor price failed  : ', e);
      setMessage({
        isChanged: false,
        text: 'Change floor price failed!',
      });
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Change Floor Price</h3>

      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled handleChange={() => {}} />

      <NumInput defaultMin={0} value={price} label="Price" handleChange={handlePriceChange} />

      <BaseButtonOutline
        display="Change Floor Price"
        onClick={handleChangeFloorPrice}
        small
        className={styles.createSaleBtn}
        disabled={!+price || pending}
      />

      {!!message && <p className={message.isChanged ? styles.messageSuccess : styles.messageErr}>{message.text}</p>}
    </div>
  );
}

export default ChangeFloorPrice;
