import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { User } from './types';
import { useAuthHeader } from './useAuthHeader';

type UpdateConsumerUserProps = {
  id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

export const useConsumerUser = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function updateConsumerUser({
    id,
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
  }: UpdateConsumerUserProps): Promise<User> {
    if (!isConnectionReady) return {} as User;

    const request = new adminApiv1Pb.UpdateConsumerUserRequest();
    request.setId(id);

    request.setAddress1(address1);
    request.setAddress2(address2);
    request.setCountry(country);
    request.setState(state);
    request.setCity(city);
    request.setPostalCode(postalCode);
    request.setPostalCode(postalCode);

    const response = await client.updateConsumerUser(request, authHeader);
    return response.toObject().user || ({} as User);
  }

  return { updateConsumerUser };
};
