import { ReactNode } from 'react';

import styles from '../../scss/BaseLabel.module.scss';

type BaseLabelProps = {
  children: ReactNode | ReactNode[];
  required?: boolean;
  htmlFor: string;
};

export default function BaseLabel({ children, required, htmlFor }: BaseLabelProps) {
  const RequiredStar = () => (
    <abbr title="This field is mandatory" aria-label="required">
      *
    </abbr>
  );

  return (
    <label htmlFor={htmlFor} className={styles.label}>
      {children} {required && <RequiredStar />}
    </label>
  );
}
