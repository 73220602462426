import React, { useEffect, useState } from 'react';

import useDashboardFileTypes from '../../composables/files/useDashboardFileTypes';
import useFileSelect from '../../composables/files/useFileSelect';
import { orderedDocuments } from '../../constants/documents';
import styles from '../../scss/FileUpload.module.scss';
import BaseExpandSelect from '../base/BaseExpandSelect';
import FileInput from './FileInput';
import FileUploader from './FileUploader';
import { UploadStatus } from './types';

function FileUpload({ vehicleId, uploadedCallback }: FileUploadProps) {
  // FileType
  const { fileType, fileTypes, setFileType } = useDashboardFileTypes({
    onTypeChange: () => {
      resetFiles();
      setUploadStatus(UploadStatus.BEFORE_START);
    },
    files: orderedDocuments,
  });

  // Upload Status
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.BEFORE_START);

  // File
  const { handleFileSelect, fileInputRef, selectFile, files, resetFiles } = useFileSelect({
    isMultiple: false,
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrMsg('');
    handleFileSelect(e);
  };

  // Error Message
  const [errMsg, setErrMsg] = useState('');

  // Misc
  const clearAll = () => {
    setFileType('');
    resetFiles();
    setErrMsg('');
    setUploadStatus(UploadStatus.BEFORE_START);
  };

  useEffect(() => {
    clearAll();
  }, [vehicleId]);

  useEffect(() => {
    if (uploadStatus === UploadStatus.DONE) {
      resetFiles();
      uploadedCallback();
    }
  }, [uploadStatus]);

  return (
    <div className={styles.fileUploadContainer}>
      <section>
        <p className={styles.uploadInfo}>
          Add file for: {vehicleId} {uploadStatus}
        </p>
      </section>

      <section>
        <p>Step 1 - Select a file type</p>
        <BaseExpandSelect label="" selectEvent={setFileType} options={fileTypes} selectedValue={fileType} />
      </section>

      {fileType && (
        <section>
          <p>Step 2 - Select your file</p>
          <FileInput
            display={files.length ? files[0].name : 'Click to Add a file'}
            onClick={selectFile}
            onChange={onChange}
            fileInputRef={fileInputRef}
          />
        </section>
      )}

      {files.length > 0 && (
        <section>
          <p>Step 3. Upload</p>
          <FileUploader
            vehicleId={vehicleId}
            file={files[0]}
            fileType={fileType}
            onClear={clearAll}
            onError={setErrMsg}
            onStatusChange={setUploadStatus}
            uploadStatus={uploadStatus}
          />
        </section>
      )}

      {!!errMsg && (
        <section>
          <p className="label-error">{errMsg}</p>
        </section>
      )}

      {uploadStatus === UploadStatus.DONE && !errMsg && (
        <section className={styles.result}>
          <p className={styles.success}>Upload completed!</p>
        </section>
      )}
    </div>
  );
}

export default FileUpload;

interface FileUploadProps {
  vehicleId: string;
  uploadedCallback: () => unknown;
}
