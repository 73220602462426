import moment from 'moment';
import { useEffect, useState } from 'react';

import { AuctionVehiclePriceMap } from '../composables/api/types';
import useAddVehiclesToAuction from '../composables/api/useAddVehiclesToAuction';
import { useCreateAuction } from '../composables/api/useCreateAuction';
import { DateSelectOverride, TimeProps } from '../composables/utils/types';
import useCurrency from '../composables/utils/useCurrency';
import useDoubleCheck from '../composables/utils/useDoubleCheck';
import useInput from '../composables/utils/useInput';
import useRegions from '../composables/utils/useRegions';
import { getReadableDate } from '../helpers/utils';
import styles from '../scss/CreateNewAuction.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseSelectOption from './base/BaseSelectOption';
import DateSelectorDeprecated from './utils/DateSelectorDeprecated';
import DayInput from './utils/inputs/DayInput';
import HourInput from './utils/inputs/HourInput';
import MinuteInput from './utils/inputs/MinuteInput';
import NumInput from './utils/inputs/NumInput';
import SelectInput from './utils/inputs/SelectInput';
import TextInput from './utils/inputs/TextInput';
import RadioButtons from './utils/RadioButtons';

type CreateNewAuctionProps = {
  vehicleIds: string[];
  auctionStartPrices: AuctionVehiclePriceMap;
  onComplete: () => void;
};

type EndTimeProps = {
  momentTime: moment.Moment;
  displayTime: string;
};

export default function CreateAuction({ vehicleIds, auctionStartPrices, onComplete }: CreateNewAuctionProps) {
  const [startTimeProps, setStartTimeProps] = useState({} as TimeProps);
  const [endTimeProps, setEndTimeProps] = useState({} as EndTimeProps);
  const [error, setError] = useState('');
  const [createdAuction, createAuction] = useCreateAuction();
  const [auctionTitle, { handleChange: handleAuctionTitleChange }] = useInput('');
  const [antiSniping, { handleChange: handleAntiSnipingChange }] = useInput('45');
  const { currency, currencyOptions, setCurrency } = useCurrency();
  const { country, setCountry, countries, division, setDivision, divisions } = useRegions();
  const addVehiclesToAuction = useAddVehiclesToAuction();

  // DURATION
  const DEFAULT_MIN = '30';
  const [minutes, { handleChange: handleMinuteChange }] = useInput(DEFAULT_MIN);
  const [hours, { handleChange: handleHourChange }] = useInput('0');
  const [days, { handleChange: handleDayChange }] = useInput('0');

  const dateTimeFormat = 'YYYY MMM DD [at] h:mm a';

  const {
    confirmBtnText: confirmCreateBtnText,
    doubleCheck: doubleCheckCreate,
    isConfirmBtnDisabled: isCreateConfirmBtnDisabled,
  } = useDoubleCheck({
    buttonTextProp: {
      initialText: 'Create Auction',
      confirmationText: 'Click again to create auction',
      completionText: 'Auction Created!',
    },
    fn: confirmCreate,
  });

  useEffect(() => {
    const duration = moment.duration({
      days: +days,
      hours: +hours,
      minutes: +minutes,
    });
    const { year, month, day, hour, minute } = startTimeProps;
    const jsStartTime = new Date(+year, +month - 1, +day, +hour, +minute);
    const momentEndTime = moment(jsStartTime).add(duration);

    setEndTimeProps({ displayTime: momentEndTime.format('YYYY.MM.DD HH:mm'), momentTime: momentEndTime });
  }, [startTimeProps, days, hours, minutes]);

  useEffect(() => {
    setDivision('');
  }, [country]);

  async function confirmCreate() {
    try {
      setError('');

      const endTimeNano = +endTimeProps.momentTime.format('x') * 10 ** 6;

      const res = await createAuction({
        startTime: +startTimeProps.nano,
        endTime: endTimeNano,
        antiSnipingTime: +antiSniping * 1000,
        auctionTitle,
        country,
        division,
      });

      if (vehicleIds.length > 0) {
        await addVehiclesToAuction({
          auctionId: res.auctionId,
          vehicleIds,
          pricingMap: auctionStartPrices,
        });
      }

      onComplete();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }

      throw err; // for useDoubleCheck
    }
  }

  const tomorrow10am = moment().add(1, 'days').hours(10).startOf('hour');
  const defaultStartDate = {
    year: '' + tomorrow10am.year(),
    month: '' + (tomorrow10am.month() + 1),
    day: '' + tomorrow10am.date(),
    hour: '' + tomorrow10am.hour(),
    minute: '' + tomorrow10am.minute(),
  } as DateSelectOverride;

  if (createdAuction !== null) {
    return (
      <>
        <h2>Auction Created!</h2>
        <section className={styles.results}>
          <p>Auction ID: {createdAuction.auctionId}</p>
          <p>Vehicle Count: {vehicleIds.length}</p>
          <p>Start Time: {getReadableDate(createdAuction.startTimeNano, dateTimeFormat)}</p>
          <p>End Time: {getReadableDate(createdAuction.endTimeNano, dateTimeFormat)}</p>
        </section>
      </>
    );
  }

  return (
    <section className={styles.settings}>
      <div>
        <label htmlFor="startTime">Start time</label>
        <div className={styles.dateContainer}>
          <DateSelectorDeprecated setTimeProps={setStartTimeProps} defaultDateTime={defaultStartDate} />
        </div>
      </div>

      <div>
        <label htmlFor="duration">Auction Duration</label>
        <div className={styles.durationContainer}>
          <DayInput day={days} handleDayChange={handleDayChange} />
          <HourInput hour={hours} handleHourChange={handleHourChange} />
          <MinuteInput minute={minutes} handleMinuteChange={handleMinuteChange} />
        </div>
      </div>

      <div>
        Auction ends at <b>{endTimeProps.displayTime}</b>
      </div>

      <div className={styles.sectionTwo}>
        <SelectInput
          label="Country"
          name="country"
          required
          id="countrySelect"
          value={country}
          onChange={(e) => setCountry(e.target.value)}>
          <BaseSelectOption value=""></BaseSelectOption>
          {countries.map(({ cca2, name }) => (
            <BaseSelectOption key={cca2} value={cca2}>
              {name}
            </BaseSelectOption>
          ))}
        </SelectInput>
        <SelectInput
          label="Province / State"
          name="state"
          value={division}
          required
          onChange={(e) => setDivision(e.target.value)}>
          <BaseSelectOption value=""></BaseSelectOption>
          {divisions.map(({ code, name }) => (
            <BaseSelectOption key={code} value={code}>
              {name}
            </BaseSelectOption>
          ))}
        </SelectInput>
      </div>

      <div>
        <TextInput
          name="auctionTitle"
          label="Auction Title"
          value={auctionTitle}
          required
          onChange={handleAuctionTitleChange}
        />
      </div>

      <div className={styles.sectionTwo}>
        <div>
          <label htmlFor="currency">Currency</label>
          <div id="currency" className={styles.currency}>
            <RadioButtons options={currencyOptions} currentSelected={currency} onClick={setCurrency} />
          </div>
        </div>
        <NumInput label="Anti-Sniping Time (in seconds)" value={antiSniping} handleChange={handleAntiSnipingChange} />
      </div>

      <div>
        <BaseButtonOutline
          display={confirmCreateBtnText}
          onClick={doubleCheckCreate}
          disabled={auctionTitle === '' || country === '' || division === '' || isCreateConfirmBtnDisabled}
        />
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </section>
  );
}
