import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { RegisteredOwner } from './types';
import { useAuthHeader } from './useAuthHeader';

export default (vehicleId: string) => {
  const [owners, setOwners] = useState<RegisteredOwner[] | []>([]);
  const [loading, setLoading] = useState(true);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function fetchRegisteredOwners() {
    if (!isConnectionReady) return;

    setLoading(true);

    try {
      const request = new adminApiv1Pb.ListRegisteredOwnersRequest();
      request.setVehicleId(vehicleId);
      const response = await client.listRegisteredOwners(request, authHeader);
      const registeredOwnerList =
        response.toObject().registeredOwners?.registeredOwnersList || ([] as RegisteredOwner[]);
      setOwners(registeredOwnerList);
    } catch (error) {
      console.error('fetchRegisteredOwners: ', error);
      setOwners([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchRegisteredOwners();

    return () => setLoading(false);
  }, [isConnectionReady, vehicleId]);
  return {
    owners,
    loading,
    fetchRegisteredOwners,
  };
};
