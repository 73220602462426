import { useState } from 'react';

import { VehicleData } from '../../components/dealer/types';

export const useSelectVehicles = () => {
  const [selected, setSelected] = useState([] as Array<VehicleData>);

  const handleRowClick = (vehicle: VehicleData) => {
    const hasSelected = selected.find((item) => item.id === vehicle.id) !== undefined;

    if (hasSelected) {
      const updated = selected.filter((item) => item.id !== vehicle.id);
      setSelected(updated);
    } else {
      const updated = [...selected, vehicle];
      setSelected(updated);
    }
  };

  const handleBulkSelect = (vehicles: VehicleData[]) => {
    const existingIds = selected.map((item) => item.id);
    const newSelects = vehicles.filter((vehicle) => !existingIds.includes(vehicle.id));
    setSelected([...selected, ...newSelects]);
  };

  const clearAll = (clearCallback?: () => void) => {
    setSelected([]);
    clearCallback && clearCallback();
  };

  const getClassFactory = (activeClass: string) => (id: string) => {
    const isActive = selected.find((item) => item.id === id) !== undefined;
    return isActive ? activeClass : '';
  };

  return {
    getClassFactory,
    handleRowClick,
    handleBulkSelect,
    selected,
    clearAll,
  };
};
