import { createContext, FC, ReactElement, useState } from 'react';
import ReactModal from 'react-modal';

import { useViewport } from './ViewPortContext';

type ModalProps = Omit<ReactModal.Props, 'isOpen'>;

export type ModalContentType = {
  isModalOpen: boolean;
  setIsModalOpen: (c: boolean) => void;
  currentComp?: ReactElement;
  setCurrentComp: (r: ReactElement) => void;
  modalProps: ModalProps;
  setModalProps: (p: ModalProps) => void;
};

export const ModalContext = createContext<ModalContentType | null>(null);

export const ModalProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentComp, setCurrentComp] = useState<ReactElement>();

  const { isTablet, isMobile } = useViewport();

  function mergeProps(props?: ModalProps) {
    const contentStyle =
      isTablet || isMobile
        ? {
            inset: 0,
            border: 'none',
          }
        : {
            maxWidth: 'fit-content',
            margin: 'auto',
            height: 'fit-content',
          };

    const overrideProps: ModalProps = {
      ...props,
      style: {
        overlay: {
          zIndex: 999,
          ...props?.style?.overlay,
        },
        content: {
          ...contentStyle,
          borderRadius: 0,
          ...props?.style?.content,
        },
      },
    };

    return overrideProps;
  }

  const [modalProps, _setModalProps] = useState(mergeProps());

  function setModalProps(_props: ModalProps) {
    _setModalProps(mergeProps(_props));
  }

  return (
    <ModalContext.Provider
      value={{ isModalOpen, setIsModalOpen, currentComp, setCurrentComp, modalProps, setModalProps }}>
      {children}
    </ModalContext.Provider>
  );
};
