import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Vehicle } from '../composables/api/types';
import { useVehicle } from '../composables/api/useVehicle';
import styles from '../scss/VehicleDetails.module.scss';
import Images from './Images';
import Loading from './utils/Loading';
import { VehicleOptions } from './VehicleOptions';
import VehicleSpecs from './VehicleSpecs';

function VehicleDetails() {
  const { id, '*': tab } = useParams();
  const { vehicle, updateVehicle, getVehicle } = useVehicle(id || '');
  const navigate = useNavigate();

  const statuses = [
    {
      slug: 'specs',
      display: 'Specs',
      value: 'Specs',
    },
    {
      slug: 'options',
      display: 'Options',
      value: 'Options',
    },
    {
      slug: 'images',
      display: 'Images',
      value: 'Images',
    },
  ];

  const currentTab = statuses.find(({ slug }) => tab === slug)?.value || statuses[0].value;

  useEffect(() => {
    if (!tab) {
      navigate(`/vehicle-details/1/${id}/specs`);
    }
  }, []);

  async function handleOptionUpdate(vehicleOptionsList: Vehicle['vehicleOptionsList']) {
    if (!vehicle) return;

    await updateVehicle({ ...vehicle, vehicleOptionsList });
    await getVehicle();
  }

  if (!vehicle) return <div>Loading...</div>;

  return (
    <div className={styles.vehicleDetails}>
      <div>
        {statuses.map(({ slug, display, value }) => {
          return (
            <Link
              key={slug}
              to={`/vehicle-details/1/${id}/${slug}`}
              className={`${styles.tab} ${value === currentTab ? styles.active : ''}`}>
              {display}
            </Link>
          );
        })}
      </div>

      <h2>{currentTab}</h2>

      {currentTab === 'Specs' ? (
        <VehicleSpecs vehicle={vehicle} onUpdate={updateVehicle}></VehicleSpecs>
      ) : currentTab === 'Options' ? (
        <VehicleOptions options={vehicle.vehicleOptionsList} onUpdate={handleOptionUpdate} />
      ) : currentTab === 'Images' ? (
        <Images vehicle={vehicle} onUpdate={getVehicle} />
      ) : (
        'Error'
      )}
    </div>
  );
}

export default withAuthenticationRequired(VehicleDetails, {
  onRedirecting: () => <Loading />,
});
