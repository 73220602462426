import React from 'react';

import { AuctionGroup } from '../../composables/api/types';
import { nanoToIsoStringDate } from '../../helpers/utils';
import { BasicFieldProps } from './types';

export default function AuctionGroupsListField(props: BasicFieldProps<AuctionGroup[]>) {
  const { viewValue: auctionGroup } = props;
  const [auctionValue] = auctionGroup;

  return (
    <React.Fragment key="auctionGroupsList">
      <tr>
        <td>auctionId</td>
        <td>{auctionValue ? auctionValue.auctionId : ''}</td>
        <td></td>
      </tr>
      <tr>
        <td>startTime</td>
        <td>{auctionValue ? nanoToIsoStringDate(auctionValue.startTime) : 'N/A'}</td>
        <td></td>
      </tr>
      <tr>
        <td>endTime</td>
        <td>{auctionValue ? nanoToIsoStringDate(auctionValue.endTime) : 'N/A'}</td>
        <td></td>
      </tr>
    </React.Fragment>
  );
}
