import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import AddTestVehicles from './components/AddTestVehicles';
import AuctionSearch from './components/AuctionSearch';
import ConsumerSignups from './components/ConsumerSignups';
import AutoGroupList from './components/dealer/AutoGroupList';
import CreateAutoGroup from './components/dealer/CreateAutoGroup';
import CreateDealer from './components/dealer/CreateDealer';
import CreateDealership from './components/dealer/CreateDealership';
import DealerList from './components/dealer/DealerList';
import DealershipList from './components/dealer/DealershipList';
import DealerVehicles from './components/dealer/DealerVehicles';
import UpdateAutoGroup from './components/dealer/UpdateAutoGroup';
import UpdateDealer from './components/dealer/UpdateDealer';
import UpdateDealership from './components/dealer/UpdateDealership';
import DealTankList from './components/DealTankList';
import DevTools from './components/DevTools';
import Impersonation from './components/Impersonation';
import InspectionQuestions from './components/inspection/InspectionQuestions';
import ReadyForAuction from './components/ReadyForAuction';
import Auth0ProviderWithHistory from './components/route/Auth0ProviderWithHistory';
import ScheduledAuctions from './components/ScheduledAuctions';
import NoMatchRoute from './components/utils/NoMatchRoute';
import VehicleDetails from './components/VehicleDetails';
import VehicleSearchPage from './components/VehicleSearchPage';
import MainProvider from './contexts/MainProvider';
import reportWebVitals from './reportWebVitals';

function RouteComponents() {
  return (
    <Auth0ProviderWithHistory>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="ready-for-auction" element={<ReadyForAuction />} />
          <Route path="vehicle-search" element={<VehicleSearchPage />} />
          <Route path="auction-search" element={<AuctionSearch />} />
          <Route path="deal-tank" element={<DealTankList />} />
          <Route path="scheduled-auctions" element={<ScheduledAuctions />} />

          <Route path="dealer-list" element={<DealerList />} />
          <Route path="dealership-list" element={<DealershipList />} />
          <Route path="create-dealer" element={<CreateDealer />} />
          <Route path="update-dealer-rep" element={<UpdateDealer />} />
          <Route path="create-dealership" element={<CreateDealership />} />
          <Route path="update-dealership/:id" element={<UpdateDealership />} />
          <Route path="update-dealership" element={<UpdateDealership />} />
          <Route path="update-autogroup" element={<UpdateAutoGroup />} />
          <Route path="autogroup-list" element={<AutoGroupList />} />
          <Route path="create-autogroup" element={<CreateAutoGroup />} />
          <Route path="consumer-buy" element={<DealerVehicles />} />
          <Route path="add-test-vehicles" element={<AddTestVehicles />} />
          <Route path="consumer-signup" element={<ConsumerSignups />} />
          <Route path="vehicle-details/:origin/:id/*" element={<VehicleDetails />} />
          <Route path="inspection-questions" element={<InspectionQuestions />} />
          <Route path="dev-tools" element={<DevTools />} />
          <Route path="impersonation" element={<Impersonation />} />
          <Route path="*" element={<NoMatchRoute />} />
        </Route>
      </Routes>
    </Auth0ProviderWithHistory>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <MainProvider>
      <BrowserRouter>
        <RouteComponents />
      </BrowserRouter>
    </MainProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
