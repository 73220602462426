import React from 'react';
import { RiImageAddLine } from 'react-icons/ri';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import ImageUpload from '../upload/ImageUpload';

interface AddImageProps {
  dealerId: string;
}
function AddImageDealer({ dealerId }: AddImageProps) {
  const { openUtilityDrawer } = useUtilityDrawer();

  const addDealerImages = async (id: string) => {
    openUtilityDrawer(
      <ImageUpload
        id={id}
        type="dealer_id"
        renderCompleteSection={() =>
          (
            <a href={'/update-dealership/' + id}>
              <button>Update Dealership </button>
            </a>
          ) as React.ReactElement
        }
      />,
    );
  };

  return (
    <>
      <BaseButtonOutline small display="" onClick={() => addDealerImages(dealerId)}>
        <>
          <RiImageAddLine /> Add Logo
        </>
      </BaseButtonOutline>
    </>
  );
}

export default AddImageDealer;
