import React, { useState } from 'react';

import BasicField from '../../components/vdp/BasicField';
import { getTypedKeys } from '../../helpers/typing';
import { EditableVehicle, Vehicle } from '../api/types';
import specialFields from './specialFields';

const EDITABLE_FIELDS = [
  'vin',
  'year',
  'make',
  'model',
  'trimLevel',
  'interiorColor',
  'interiorType',
  'exteriorColor',
  'engine',
  'engineSize',
  // Moved to be edited in a separate component: 'vehicleOptionsList',
  'status',
  'vehiclePaveAnnouncement',
  'location',
  'mileage',
  'mileageUom',
  'price',
  'transmission',
  'fuelType',
  'driveTrain',
  'title',
];

export const useEditFields = (isEditMode: boolean) => {
  const [fieldsView, setFieldsView] = useState<Vehicle | null>(null);
  const [fieldsEdit, setFieldsEdit] = useState<EditableVehicle | null>(null);

  const setFields = (newFields: Vehicle | null) => {
    if (!newFields) return;

    setFieldsView(newFields);
    buildEditFields(newFields);
  };

  const buildEditFields = (newFields: Vehicle) => {
    setFieldsEdit({
      ...newFields,
    });
  };

  function changeEditFieldValue<N>(key: string, newValue: N) {
    if (!fieldsEdit) return;

    setFieldsEdit({
      ...fieldsEdit,
      [key]: newValue,
    });
  }

  return {
    fieldsForEdit: fieldsEdit,
    setFields: setFields,
    renderFields: () => {
      if (!fieldsView || !fieldsEdit) {
        return (
          <tr>
            <td>No fields</td>
          </tr>
        );
      }

      return getTypedKeys<Vehicle>(fieldsView).map((key) => {
        const Component = specialFields[key] || BasicField;

        return (
          <React.Fragment key={key}>
            <Component
              propName={key}
              viewValue={fieldsView[key]}
              editValue={fieldsEdit[key]}
              isEditMode={isEditMode}
              isEditable={EDITABLE_FIELDS.includes(key)}
              editFn={changeEditFieldValue}
            />
          </React.Fragment>
        );
      });
    },
  };
};
