import React, { useContext } from 'react';

import EmptyComponent from '../../components/utils/EmptyComponent';
import { UtilityDrawerContent, UtilityDrawerContext } from '../../contexts/UtilityDrawerContext';

export default function useUtilityDrawer() {
  const { isDrawerOpen, setIsDrawerOpen, currentComp, setCurrentComp } = useContext(
    UtilityDrawerContext,
  ) as UtilityDrawerContent;

  function closeUtilityDrawer() {
    setIsDrawerOpen(false);
    setCurrentComp(<EmptyComponent />);
  }

  function openUtilityDrawer(comp: React.ReactElement) {
    setCurrentComp(comp);
    setIsDrawerOpen(true);
  }

  return {
    closeUtilityDrawer,
    openUtilityDrawer,
    isDrawerOpen,
    currentComp,
  };
}
