import { AutoGroup } from '../../composables/api/types';
import { convertSubscriptionPlan } from '../../helpers/subscriptions';
import style from '../../scss/AutoGroupListItem.module.scss';

type AutoGroupListItemProps = {
  autoGroup: AutoGroup;
};

function nanoToDateString(timeInNanoseconds: number) {
  if (!timeInNanoseconds) return 'N/A';
  return new Date(timeInNanoseconds / 1000000).toISOString().split('T')[0];
}

function AutoGroupListItem({ autoGroup }: AutoGroupListItemProps) {
  return (
    <>
      <h3>{autoGroup.name}</h3>
      <div className={style.container}>
        <table className={style.item}>
          <tr>
            <th>ID:</th>
            <td>{autoGroup.id}</td>
          </tr>
          <tr>
            <th>Name:</th>
            <td>{autoGroup.name}</td>
          </tr>
          <tr>
            <th>Accountant Email:</th>
            <td>{autoGroup.accountantEmail}</td>
          </tr>
          <tr>
            <th>Province Reg No:</th>
            <td>{autoGroup.provinceRegistrationNo}</td>
          </tr>
          <tr>
            <th>Subscription Plan:</th>
            <td>{convertSubscriptionPlan(autoGroup.subscriptionPlan)}</td>
          </tr>
          <tr>
            <th>Subscription Change Date:</th>
            <td>{nanoToDateString(Number(autoGroup.subscriptionChangeDate))}</td>
          </tr>
        </table>
        <table className={style.item}>
          <tr>
            <th>Street:</th>
            <td>{autoGroup.autoGroupLocation?.street}</td>
          </tr>
          <tr>
            <th>City:</th>
            <td>{autoGroup.autoGroupLocation?.city}</td>
          </tr>
          <tr>
            <th>State:</th>
            <td>{autoGroup.autoGroupLocation?.state}</td>
          </tr>
          <tr>
            <th>Postal:</th>
            <td>{autoGroup.autoGroupLocation?.postal}</td>
          </tr>
          <tr>
            <th>Phone:</th>
            <td>{autoGroup.autoGroupLocation?.telephoneNumber}</td>
          </tr>
          <tr>
            <th>Fax:</th>
            <td>{autoGroup.autoGroupLocation?.faxNumber}</td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default AutoGroupListItem;
