import { useDealTankOffers } from '../../composables/api/useDealTankOffers';
import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import { WayOfSale } from '../../grpc/build/protos/enum_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';
import RegisteredOwnerModal from './RegisteredOwnerModal';

interface RegisteredDealTankOwnerProps {
  vehicleId: string;
}

function RegisteredDealTankOwner({ vehicleId }: RegisteredDealTankOwnerProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const { offers, getOffers, offer } = useDealTankOffers(vehicleId);

  const ShowRegisteredDealTankOwners = () => {
    openUtilityDrawer(
      <RegisteredOwnerModal
        vehicleId={vehicleId}
        offerInfo={offers}
        onGetOffers={getOffers}
        acceptedOffer={offer}
        wayOfSale={WayOfSale.WAY_OF_SALE_DEAL_TANK}
      />,
    );
  };

  return <BaseButtonOutline small display="Offers and GloveBox" onClick={ShowRegisteredDealTankOwners} />;
}

export default RegisteredDealTankOwner;
