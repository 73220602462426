/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { FiCheck, FiList } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { EditableVehicle, RemovableVehicle } from '../../composables/api/types';
import useAga from '../../composables/api/useAga';
import useDealTankVehicle from '../../composables/api/useDealTankVehicle';
import { useVehicle } from '../../composables/api/useVehicle';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useModal from '../../composables/utils/useModal';
import { VehicleStatus } from '../../grpc/build/protos/adminapiv1_pb';
import { AuctionStatus } from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/RelistVehicle.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface RelistVehicleProps {
  vehicle: RemovableVehicle;
}

function RelistVehicle({ vehicle }: RelistVehicleProps) {
  const [relisted, setRelisted] = useState(false);
  const { updateVehicle } = useVehicle(vehicle.id);
  const { updateAgaStatus } = useAga();
  const { active: isInDealTank, accepted: isDealTankListingAccepted } = useDealTankVehicle(vehicle.id);
  const [updatedVehicle, setUpdatedVehicle] = useState({} as EditableVehicle);
  const [errMsg, setErrMsg] = useState('');
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (vehicle) {
      const clone = JSON.parse(JSON.stringify(vehicle)) as EditableVehicle;
      clone.status = VehicleStatus.VEHICLE_STATUS_CONFIRMED;
      clone.auctionStatus = AuctionStatus.AUCTION_STATUS_RELIST_READY;
      setUpdatedVehicle(clone);
    }
  }, [vehicle]);

  const handleRelist = async () => {
    if (!updatedVehicle) return;
    setErrMsg('');

    try {
      await updateVehicle(updatedVehicle);
      await updateAgaStatus(updatedVehicle.id, '', updatedVehicle.auctionStatus); //todo: is agId here ?
      setRelisted(true);
    } catch (error) {
      console.error('Fail updating the vehicle for relist: ', error);
      setErrMsg('Relist failed. Please reload and try again.');
    }
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: {
      initialText: 'Relist vehicle',
      confirmationText: 'Confirm relist',
      completionText: 'Relisted',
    },
    fn: handleRelist,
  });

  async function handleClick() {
    if (isDealTankListingAccepted) {
      openModal(
        <div>
          <p>This listing has been accepted in Deal Tank; you must reject it manually first.</p>
        </div>,
      );

      return;
    }

    if (isInDealTank) {
      openModal(
        <div className={styles.deactivateDealTankListingModal}>
          <h2>Vehicle is active in Deal Tank</h2>
          <p>If you want to relist your vehicle in an auction, you will have to deactivate it in Deal Tank first.</p>
          <div className={styles.buttons}>
            <BaseButtonOutline
              className={styles.deactivateButton}
              display="Deactivate in Deal Tank and relist in auction"
              onClick={handleDeactivateDealTankListing}
            />
            <BaseButtonOutline display="Cancel" onClick={closeModal} />
          </div>
        </div>,
      );

      return;
    }

    doubleCheck();
  }

  async function handleDeactivateDealTankListing() {
    await handleRelist();
    closeModal();
  }

  if (errMsg !== '') {
    return <p className={styles.relistError}>{errMsg}</p>;
  }

  if (relisted) {
    return (
      <p className={styles.relistedMessage}>
        {' '}
        <FiCheck /> This vehicle has been moved to <Link to="/ready-for-auction">Ready for Auction</Link>
      </p>
    );
  }

  return (
    <>
      <BaseButtonOutline small onClick={handleClick} disabled={isConfirmBtnDisabled}>
        <>
          <FiList /> {confirmBtnText}
        </>
      </BaseButtonOutline>
    </>
  );
}

export default RelistVehicle;
