import { useEffect, useState } from 'react';

import useInput from './useInput';
interface DataItem {
  email: string;
}

const useCreateEmail = (list: DataItem[]) => {
  const [email, { handleChange: handleEmailChange, setInputVal: setEmail }] = useInput('');
  const [emails, setEmails] = useState<string[]>([]);
  const [emailErrMsg, setEmailErrMsg] = useState('');

  useEffect(() => {
    setEmails(list ? list.map((listItem) => listItem.email) : []);
  }, [list]);

  useEffect(() => {
    if (!email) {
      setEmailErrMsg('');
    } else if (!isValidEmail(email)) {
      setEmailErrMsg('Invalid Email');
    } else if (emails.includes(email)) {
      setEmailErrMsg('This email has been registered');
    } else {
      setEmailErrMsg('');
    }
  }, [email]);

  const isValidEmail = (email: string) => {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return regex.test(email);
  };

  return {
    email,
    emailErrMsg,
    handleEmailChange,
    setEmail,
  };
};

export default useCreateEmail;
