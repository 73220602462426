import React from 'react';

import styles from '../../scss/BaseExpandSelect.module.scss';

function BaseExpandSelect({
  label,
  name,
  selectEvent,
  onChange,
  options,
  selectedValue,
  useEmptyOption = true,
  isDisabled = false,
  dataKey = DataKey.ID,
}: MultiSelectProps) {
  return (
    <>
      <label htmlFor="customSelect">{label}</label>
      {options && (
        <div className={styles.customSelect}>
          <select
            disabled={isDisabled}
            size={4}
            id="customSelect"
            name={name}
            value={selectedValue}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
              selectEvent && selectEvent(e.target.value);
              onChange && onChange(e);
            }}>
            {useEmptyOption && <option value={''}></option>}
            {options.map((option) => (
              <option key={option['id']} value={option[dataKey]}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}

export default BaseExpandSelect;

interface Option {
  id: string | number;
  name: string;
  email?: string;
}

export enum DataKey {
  ID = 'id',
  EMAIL = 'email',
}

type MultiSelectProps = {
  label: string;
  name?: string;
  // eslint-disable-next-line no-unused-vars
  selectEvent?: (val: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  selectedValue: string | number;
  useEmptyOption?: boolean;
  isDisabled?: boolean;
  dataKey?: DataKey;
};
