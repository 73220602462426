import styles from '../../scss/BaseButtonText.module.scss';

type BaseButtonTextProps = {
  display: string;
  onClick: () => void;
  title?: string;
};

function BaseButtonText({ title, display, onClick }: BaseButtonTextProps) {
  return (
    <button className={styles.baseButtonText} onClick={onClick} title={title}>
      {display}
    </button>
  );
}

export default BaseButtonText;
