// source: build/protos/salesentity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
goog.exportSymbol('proto.entity.salesentity.AgreementQuestion', null, global);
goog.exportSymbol('proto.entity.salesentity.AgreementQuestionAnswer', null, global);
goog.exportSymbol('proto.entity.salesentity.AgreementQuestionSubRegionFilter', null, global);
goog.exportSymbol('proto.entity.salesentity.AgreementQuestionType', null, global);
goog.exportSymbol('proto.entity.salesentity.Invoice', null, global);
goog.exportSymbol('proto.entity.salesentity.MarketplaceSales', null, global);
goog.exportSymbol('proto.entity.salesentity.OfferTransaction', null, global);
goog.exportSymbol('proto.entity.salesentity.Transaction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.salesentity.AgreementQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.salesentity.AgreementQuestion.repeatedFields_, null);
};
goog.inherits(proto.entity.salesentity.AgreementQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.salesentity.AgreementQuestion.displayName = 'proto.entity.salesentity.AgreementQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.salesentity.AgreementQuestionAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.salesentity.AgreementQuestionAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.salesentity.AgreementQuestionAnswer.displayName = 'proto.entity.salesentity.AgreementQuestionAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.salesentity.MarketplaceSales = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.salesentity.MarketplaceSales, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.salesentity.MarketplaceSales.displayName = 'proto.entity.salesentity.MarketplaceSales';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.salesentity.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.salesentity.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.salesentity.Transaction.displayName = 'proto.entity.salesentity.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.salesentity.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.salesentity.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.salesentity.Invoice.displayName = 'proto.entity.salesentity.Invoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.salesentity.OfferTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.salesentity.OfferTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.salesentity.OfferTransaction.displayName = 'proto.entity.salesentity.OfferTransaction';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.salesentity.AgreementQuestion.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.salesentity.AgreementQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.salesentity.AgreementQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.salesentity.AgreementQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.AgreementQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subRegionFiltersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    question: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    answer: (f = msg.getAnswer()) && proto.entity.salesentity.AgreementQuestionAnswer.toObject(includeInstance, f),
    mergeFieldName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hasFollowUpQuestion: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    additionalQuestionDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    order: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.salesentity.AgreementQuestion}
 */
proto.entity.salesentity.AgreementQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.salesentity.AgreementQuestion;
  return proto.entity.salesentity.AgreementQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.salesentity.AgreementQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.salesentity.AgreementQuestion}
 */
proto.entity.salesentity.AgreementQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.entity.salesentity.AgreementQuestionSubRegionFilter>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubRegionFilters(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.salesentity.AgreementQuestionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = new proto.entity.salesentity.AgreementQuestionAnswer;
      reader.readMessage(value,proto.entity.salesentity.AgreementQuestionAnswer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMergeFieldName(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFollowUpQuestion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalQuestionDescription(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.salesentity.AgreementQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.salesentity.AgreementQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.salesentity.AgreementQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.AgreementQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubRegionFiltersList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.salesentity.AgreementQuestionAnswer.serializeBinaryToWriter
    );
  }
  f = message.getMergeFieldName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHasFollowUpQuestion();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAdditionalQuestionDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region = 2;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AgreementQuestionSubRegionFilter sub_region_filters = 3;
 * @return {!Array<!proto.entity.salesentity.AgreementQuestionSubRegionFilter>}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getSubRegionFiltersList = function() {
  return /** @type {!Array<!proto.entity.salesentity.AgreementQuestionSubRegionFilter>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.entity.salesentity.AgreementQuestionSubRegionFilter>} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setSubRegionFiltersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.entity.salesentity.AgreementQuestionSubRegionFilter} value
 * @param {number=} opt_index
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.addSubRegionFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.clearSubRegionFiltersList = function() {
  return this.setSubRegionFiltersList([]);
};


/**
 * optional string question = 4;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AgreementQuestionType type = 5;
 * @return {!proto.entity.salesentity.AgreementQuestionType}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getType = function() {
  return /** @type {!proto.entity.salesentity.AgreementQuestionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.salesentity.AgreementQuestionType} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional AgreementQuestionAnswer answer = 6;
 * @return {?proto.entity.salesentity.AgreementQuestionAnswer}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getAnswer = function() {
  return /** @type{?proto.entity.salesentity.AgreementQuestionAnswer} */ (
    jspb.Message.getWrapperField(this, proto.entity.salesentity.AgreementQuestionAnswer, 6));
};


/**
 * @param {?proto.entity.salesentity.AgreementQuestionAnswer|undefined} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
*/
proto.entity.salesentity.AgreementQuestion.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.salesentity.AgreementQuestion.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string merge_field_name = 7;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getMergeFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setMergeFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool has_follow_up_question = 8;
 * @return {boolean}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getHasFollowUpQuestion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setHasFollowUpQuestion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string additional_question_description = 9;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getAdditionalQuestionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setAdditionalQuestionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 order = 10;
 * @return {number}
 */
proto.entity.salesentity.AgreementQuestion.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.AgreementQuestion} returns this
 */
proto.entity.salesentity.AgreementQuestion.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.salesentity.AgreementQuestionAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.salesentity.AgreementQuestionAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.AgreementQuestionAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    agreementQuestionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.salesentity.AgreementQuestionAnswer}
 */
proto.entity.salesentity.AgreementQuestionAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.salesentity.AgreementQuestionAnswer;
  return proto.entity.salesentity.AgreementQuestionAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.salesentity.AgreementQuestionAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.salesentity.AgreementQuestionAnswer}
 */
proto.entity.salesentity.AgreementQuestionAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgreementQuestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.salesentity.AgreementQuestionAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.salesentity.AgreementQuestionAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.AgreementQuestionAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgreementQuestionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string agreement_question_id = 1;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.getAgreementQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestionAnswer} returns this
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.setAgreementQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestionAnswer} returns this
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string answer = 3;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestionAnswer} returns this
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.AgreementQuestionAnswer} returns this
 */
proto.entity.salesentity.AgreementQuestionAnswer.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.salesentity.MarketplaceSales.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.salesentity.MarketplaceSales.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.salesentity.MarketplaceSales} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.MarketplaceSales.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    marketplace: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.salesentity.MarketplaceSales}
 */
proto.entity.salesentity.MarketplaceSales.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.salesentity.MarketplaceSales;
  return proto.entity.salesentity.MarketplaceSales.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.salesentity.MarketplaceSales} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.salesentity.MarketplaceSales}
 */
proto.entity.salesentity.MarketplaceSales.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.salesentity.MarketplaceSales.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.salesentity.MarketplaceSales.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.salesentity.MarketplaceSales} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.MarketplaceSales.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string buyer_id = 4;
 * @return {string}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 5;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string vehicle_id = 6;
 * @return {string}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 7;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.Marketplace marketplace = 8;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.entity.salesentity.MarketplaceSales.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.entity.salesentity.MarketplaceSales} returns this
 */
proto.entity.salesentity.MarketplaceSales.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.salesentity.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.salesentity.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.salesentity.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    subTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    taxPercentage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tax: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userOrigin: jspb.Message.getFieldWithDefault(msg, 9, 0),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.salesentity.Transaction}
 */
proto.entity.salesentity.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.salesentity.Transaction;
  return proto.entity.salesentity.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.salesentity.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.salesentity.Transaction}
 */
proto.entity.salesentity.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSubTotal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxPercentage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTax(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setUserOrigin(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    case 11:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.salesentity.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.salesentity.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.salesentity.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTaxPercentage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTax();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.salesentity.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.common.Timestamp timestamp = 2;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.salesentity.Transaction.prototype.getTimestamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 2));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.salesentity.Transaction} returns this
*/
proto.entity.salesentity.Transaction.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.salesentity.Transaction.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double sub_total = 3;
 * @return {number}
 */
proto.entity.salesentity.Transaction.prototype.getSubTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setSubTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 tax_percentage = 4;
 * @return {number}
 */
proto.entity.salesentity.Transaction.prototype.getTaxPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setTaxPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string tax = 5;
 * @return {string}
 */
proto.entity.salesentity.Transaction.prototype.getTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setTax = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.entity.salesentity.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.entity.salesentity.Transaction.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_id = 8;
 * @return {string}
 */
proto.entity.salesentity.Transaction.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional entity.enum.UserOrigin user_origin = 9;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.salesentity.Transaction.prototype.getUserOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setUserOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 invoice_id = 10;
 * @return {number}
 */
proto.entity.salesentity.Transaction.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional entity.enum.Currency currency = 11;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.salesentity.Transaction.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.salesentity.Transaction} returns this
 */
proto.entity.salesentity.Transaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.salesentity.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.salesentity.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.salesentity.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: (f = msg.getTimestamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userOrigin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paymentCaptureDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentCaptureAttempts: jspb.Message.getFieldWithDefault(msg, 7, 0),
    transactionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    taxTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 11, 0),
    description: jspb.Message.getFieldWithDefault(msg, 12, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.salesentity.Invoice}
 */
proto.entity.salesentity.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.salesentity.Invoice;
  return proto.entity.salesentity.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.salesentity.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.salesentity.Invoice}
 */
proto.entity.salesentity.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setUserOrigin(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.PaymentStatus} */ (reader.readEnum());
      msg.setPaymentStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentCaptureDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentCaptureAttempts(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSubTotal(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxTotal(value);
      break;
    case 11:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.salesentity.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.salesentity.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.salesentity.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPaymentCaptureDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentCaptureAttempts();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTaxTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.entity.salesentity.Invoice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.common.Timestamp timestamp = 2;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.salesentity.Invoice.prototype.getTimestamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 2));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.salesentity.Invoice} returns this
*/
proto.entity.salesentity.Invoice.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.salesentity.Invoice.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.entity.salesentity.Invoice.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.enum.UserOrigin user_origin = 4;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.salesentity.Invoice.prototype.getUserOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setUserOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional entity.enum.PaymentStatus payment_status = 5;
 * @return {!proto.entity.enum.PaymentStatus}
 */
proto.entity.salesentity.Invoice.prototype.getPaymentStatus = function() {
  return /** @type {!proto.entity.enum.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.PaymentStatus} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string payment_capture_date = 6;
 * @return {string}
 */
proto.entity.salesentity.Invoice.prototype.getPaymentCaptureDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setPaymentCaptureDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 payment_capture_attempts = 7;
 * @return {number}
 */
proto.entity.salesentity.Invoice.prototype.getPaymentCaptureAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setPaymentCaptureAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string transaction_id = 8;
 * @return {string}
 */
proto.entity.salesentity.Invoice.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double sub_total = 9;
 * @return {number}
 */
proto.entity.salesentity.Invoice.prototype.getSubTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setSubTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double tax_total = 10;
 * @return {number}
 */
proto.entity.salesentity.Invoice.prototype.getTaxTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setTaxTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional entity.enum.Currency currency = 11;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.salesentity.Invoice.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string description = 12;
 * @return {string}
 */
proto.entity.salesentity.Invoice.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double amount = 13;
 * @return {number}
 */
proto.entity.salesentity.Invoice.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string dealership_id = 14;
 * @return {string}
 */
proto.entity.salesentity.Invoice.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.Invoice} returns this
 */
proto.entity.salesentity.Invoice.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.salesentity.OfferTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.salesentity.OfferTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.salesentity.OfferTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.OfferTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    acceptedOfferId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isBreakFee: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.salesentity.OfferTransaction}
 */
proto.entity.salesentity.OfferTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.salesentity.OfferTransaction;
  return proto.entity.salesentity.OfferTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.salesentity.OfferTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.salesentity.OfferTransaction}
 */
proto.entity.salesentity.OfferTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcceptedOfferId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBreakFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.salesentity.OfferTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.salesentity.OfferTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.salesentity.OfferTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.salesentity.OfferTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAcceptedOfferId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsBreakFee();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.entity.salesentity.OfferTransaction.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.OfferTransaction} returns this
 */
proto.entity.salesentity.OfferTransaction.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accepted_offer_id = 2;
 * @return {string}
 */
proto.entity.salesentity.OfferTransaction.prototype.getAcceptedOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.salesentity.OfferTransaction} returns this
 */
proto.entity.salesentity.OfferTransaction.prototype.setAcceptedOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 3;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.entity.salesentity.OfferTransaction.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.entity.salesentity.OfferTransaction} returns this
 */
proto.entity.salesentity.OfferTransaction.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_break_fee = 4;
 * @return {boolean}
 */
proto.entity.salesentity.OfferTransaction.prototype.getIsBreakFee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.salesentity.OfferTransaction} returns this
 */
proto.entity.salesentity.OfferTransaction.prototype.setIsBreakFee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.entity.salesentity.AgreementQuestionSubRegionFilter = {
  AGREEMENT_QUESTION_SUB_REGION_FILTER_INVALID: 0,
  AGREEMENT_QUESTION_SUB_REGION_FILTER_ALL: 1,
  AGREEMENT_QUESTION_SUB_REGION_FILTER_BC: 2,
  AGREEMENT_QUESTION_SUB_REGION_FILTER_AB: 3,
  AGREEMENT_QUESTION_SUB_REGION_FILTER_ON: 4
};

/**
 * @enum {number}
 */
proto.entity.salesentity.AgreementQuestionType = {
  AGREEMENT_QUESTION_TYPE_INVALID: 0,
  AGREEMENT_QUESTION_TYPE_BOOLEAN: 1,
  AGREEMENT_QUESTION_TYPE_TEXT: 2
};

goog.object.extend(exports, proto.entity.salesentity);
