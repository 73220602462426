import React, { useState } from 'react';

import styles from '../../scss/DateRangeSelector.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import DateSelectorDeprecated from './DateSelectorDeprecated';

interface DateRangeSelectorProps {
  // eslint-disable-next-line no-unused-vars
  onSearch: (startTime: number, endTime: number) => void;
}
function DateRangeSelector({ onSearch }: DateRangeSelectorProps) {
  const defaultStartTime = { hour: '01', minute: '00' };
  const defaultEndTime = { hour: '23', minute: '59' };
  const disableItems = { hour: true, minute: true };
  const [startTime, setStartTime] = useState('0');
  const [endTime, setEndTime] = useState('0');

  return (
    <section className={styles.settings}>
      <div>
        <label htmlFor="startTime">Start time</label>
        <input type="number" min="0" id="startTime" value={startTime} disabled />
        <div className={styles.dateContainer}>
          <DateSelectorDeprecated
            setTime={setStartTime}
            defaultDateTime={defaultStartTime}
            disableItems={disableItems}
          />
        </div>
      </div>

      <div>
        <label htmlFor="endTime">End time</label>
        <input type="number" min="0" id="endTime" value={endTime} disabled />
        <div className={styles.dateContainer}>
          <DateSelectorDeprecated setTime={setEndTime} defaultDateTime={defaultEndTime} disableItems={disableItems} />
        </div>
      </div>

      <div>
        <BaseButtonOutline display="Search" onClick={() => onSearch(+startTime, +endTime)} />
      </div>
    </section>
  );
}

export default DateRangeSelector;
