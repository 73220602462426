// source: build/protos/entities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
goog.exportSymbol('proto.entity.entities.AuctionGroup', null, global);
goog.exportSymbol('proto.entity.entities.AuctionGroupAsset', null, global);
goog.exportSymbol('proto.entity.entities.AuctionVehiclePricing', null, global);
goog.exportSymbol('proto.entity.entities.BasicFavourite', null, global);
goog.exportSymbol('proto.entity.entities.Bid', null, global);
goog.exportSymbol('proto.entity.entities.BidsForVehicle', null, global);
goog.exportSymbol('proto.entity.entities.BidsForVehicles', null, global);
goog.exportSymbol('proto.entity.entities.CBBPricing', null, global);
goog.exportSymbol('proto.entity.entities.CarfaxAnnouncement', null, global);
goog.exportSymbol('proto.entity.entities.CompletedSale', null, global);
goog.exportSymbol('proto.entity.entities.CompletedSales', null, global);
goog.exportSymbol('proto.entity.entities.DealTankOffer', null, global);
goog.exportSymbol('proto.entity.entities.Dealership', null, global);
goog.exportSymbol('proto.entity.entities.DealershipLocation', null, global);
goog.exportSymbol('proto.entity.entities.DealershipReferral', null, global);
goog.exportSymbol('proto.entity.entities.DealershipReferrer', null, global);
goog.exportSymbol('proto.entity.entities.DeclarationQuestion', null, global);
goog.exportSymbol('proto.entity.entities.DeclarationQuestions', null, global);
goog.exportSymbol('proto.entity.entities.Favourite', null, global);
goog.exportSymbol('proto.entity.entities.Flag', null, global);
goog.exportSymbol('proto.entity.entities.Image', null, global);
goog.exportSymbol('proto.entity.entities.Location', null, global);
goog.exportSymbol('proto.entity.entities.Marketplace', null, global);
goog.exportSymbol('proto.entity.entities.Offer', null, global);
goog.exportSymbol('proto.entity.entities.OfferDocument', null, global);
goog.exportSymbol('proto.entity.entities.OfferDocuments', null, global);
goog.exportSymbol('proto.entity.entities.PaymentForInspection', null, global);
goog.exportSymbol('proto.entity.entities.PaymentForOnboarding', null, global);
goog.exportSymbol('proto.entity.entities.PaymentForUpsell', null, global);
goog.exportSymbol('proto.entity.entities.ProxyBid', null, global);
goog.exportSymbol('proto.entity.entities.ProxyBids', null, global);
goog.exportSymbol('proto.entity.entities.RegisteredOwner', null, global);
goog.exportSymbol('proto.entity.entities.RegisteredOwners', null, global);
goog.exportSymbol('proto.entity.entities.Upsell', null, global);
goog.exportSymbol('proto.entity.entities.User', null, global);
goog.exportSymbol('proto.entity.entities.UserVehicleToProxyBids', null, global);
goog.exportSymbol('proto.entity.entities.Vehicle', null, global);
goog.exportSymbol('proto.entity.entities.VehicleCarFaxInfo', null, global);
goog.exportSymbol('proto.entity.entities.VehiclePricing', null, global);
goog.exportSymbol('proto.entity.entities.VehicleReferrer', null, global);
goog.exportSymbol('proto.entity.entities.VehicleUpsell', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.AuctionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.AuctionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.AuctionGroup.displayName = 'proto.entity.entities.AuctionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Favourite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Favourite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Favourite.displayName = 'proto.entity.entities.Favourite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.BasicFavourite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.BasicFavourite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.BasicFavourite.displayName = 'proto.entity.entities.BasicFavourite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Offer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.Offer.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.Offer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Offer.displayName = 'proto.entity.entities.Offer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.OfferDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.OfferDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.OfferDocument.displayName = 'proto.entity.entities.OfferDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.OfferDocuments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.OfferDocuments.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.OfferDocuments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.OfferDocuments.displayName = 'proto.entity.entities.OfferDocuments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Bid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Bid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Bid.displayName = 'proto.entity.entities.Bid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.ProxyBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.ProxyBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.ProxyBid.displayName = 'proto.entity.entities.ProxyBid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.ProxyBids = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.ProxyBids.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.ProxyBids, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.ProxyBids.displayName = 'proto.entity.entities.ProxyBids';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.UserVehicleToProxyBids = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.UserVehicleToProxyBids, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.UserVehicleToProxyBids.displayName = 'proto.entity.entities.UserVehicleToProxyBids';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Dealership = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Dealership, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Dealership.displayName = 'proto.entity.entities.Dealership';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.DealershipLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.DealershipLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.DealershipLocation.displayName = 'proto.entity.entities.DealershipLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Location.displayName = 'proto.entity.entities.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.RegisteredOwner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.RegisteredOwner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.RegisteredOwner.displayName = 'proto.entity.entities.RegisteredOwner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.RegisteredOwners = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.RegisteredOwners.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.RegisteredOwners, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.RegisteredOwners.displayName = 'proto.entity.entities.RegisteredOwners';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.DeclarationQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.DeclarationQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.DeclarationQuestion.displayName = 'proto.entity.entities.DeclarationQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.DeclarationQuestions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.DeclarationQuestions.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.DeclarationQuestions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.DeclarationQuestions.displayName = 'proto.entity.entities.DeclarationQuestions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.AuctionVehiclePricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.AuctionVehiclePricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.AuctionVehiclePricing.displayName = 'proto.entity.entities.AuctionVehiclePricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.CompletedSale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.CompletedSale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.CompletedSale.displayName = 'proto.entity.entities.CompletedSale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.CompletedSales = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.CompletedSales.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.CompletedSales, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.CompletedSales.displayName = 'proto.entity.entities.CompletedSales';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.AuctionGroupAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.AuctionGroupAsset.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.AuctionGroupAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.AuctionGroupAsset.displayName = 'proto.entity.entities.AuctionGroupAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Image.displayName = 'proto.entity.entities.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Vehicle.displayName = 'proto.entity.entities.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.CarfaxAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.CarfaxAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.CarfaxAnnouncement.displayName = 'proto.entity.entities.CarfaxAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Upsell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Upsell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Upsell.displayName = 'proto.entity.entities.Upsell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.PaymentForUpsell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.PaymentForUpsell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.PaymentForUpsell.displayName = 'proto.entity.entities.PaymentForUpsell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.PaymentForInspection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.PaymentForInspection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.PaymentForInspection.displayName = 'proto.entity.entities.PaymentForInspection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.PaymentForOnboarding = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.PaymentForOnboarding, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.PaymentForOnboarding.displayName = 'proto.entity.entities.PaymentForOnboarding';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.VehicleUpsell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.VehicleUpsell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.VehicleUpsell.displayName = 'proto.entity.entities.VehicleUpsell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.VehicleCarFaxInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.VehicleCarFaxInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.VehicleCarFaxInfo.displayName = 'proto.entity.entities.VehicleCarFaxInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.User.displayName = 'proto.entity.entities.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Marketplace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Marketplace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Marketplace.displayName = 'proto.entity.entities.Marketplace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.BidsForVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.BidsForVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.BidsForVehicle.displayName = 'proto.entity.entities.BidsForVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.BidsForVehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.entities.BidsForVehicles.repeatedFields_, null);
};
goog.inherits(proto.entity.entities.BidsForVehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.BidsForVehicles.displayName = 'proto.entity.entities.BidsForVehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.DealTankOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.DealTankOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.DealTankOffer.displayName = 'proto.entity.entities.DealTankOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.VehicleReferrer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.VehicleReferrer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.VehicleReferrer.displayName = 'proto.entity.entities.VehicleReferrer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.DealershipReferral = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.DealershipReferral, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.DealershipReferral.displayName = 'proto.entity.entities.DealershipReferral';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.DealershipReferrer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.DealershipReferrer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.DealershipReferrer.displayName = 'proto.entity.entities.DealershipReferrer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.Flag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.Flag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.Flag.displayName = 'proto.entity.entities.Flag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.CBBPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.CBBPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.CBBPricing.displayName = 'proto.entity.entities.CBBPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.entities.VehiclePricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.entities.VehiclePricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.entities.VehiclePricing.displayName = 'proto.entity.entities.VehiclePricing';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.AuctionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.AuctionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.AuctionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.AuctionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auctionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    region: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subRegion: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.AuctionGroup}
 */
proto.entity.entities.AuctionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.AuctionGroup;
  return proto.entity.entities.AuctionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.AuctionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.AuctionGroup}
 */
proto.entity.entities.AuctionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.AuctionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.AuctionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.AuctionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.AuctionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.AuctionGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auction_id = 2;
 * @return {string}
 */
proto.entity.entities.AuctionGroup.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.entity.entities.AuctionGroup.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.entity.entities.AuctionGroup.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional entity.enum.AuctionStatus auction_status = 5;
 * @return {!proto.entity.enum.AuctionStatus}
 */
proto.entity.entities.AuctionGroup.prototype.getAuctionStatus = function() {
  return /** @type {!proto.entity.enum.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.AuctionStatus} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string region = 6;
 * @return {string}
 */
proto.entity.entities.AuctionGroup.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sub_region = 7;
 * @return {string}
 */
proto.entity.entities.AuctionGroup.prototype.getSubRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroup} returns this
 */
proto.entity.entities.AuctionGroup.prototype.setSubRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Favourite.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Favourite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Favourite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Favourite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    auctionGroupAssetId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userOrigin: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Favourite}
 */
proto.entity.entities.Favourite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Favourite;
  return proto.entity.entities.Favourite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Favourite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Favourite}
 */
proto.entity.entities.Favourite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupAssetId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setUserOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Favourite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Favourite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Favourite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Favourite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuctionGroupAssetId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Favourite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Favourite} returns this
 */
proto.entity.entities.Favourite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.entities.Favourite.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Favourite} returns this
 */
proto.entity.entities.Favourite.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string auction_group_id = 3;
 * @return {string}
 */
proto.entity.entities.Favourite.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Favourite} returns this
 */
proto.entity.entities.Favourite.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string auction_group_asset_id = 4;
 * @return {string}
 */
proto.entity.entities.Favourite.prototype.getAuctionGroupAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Favourite} returns this
 */
proto.entity.entities.Favourite.prototype.setAuctionGroupAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.entity.entities.Favourite.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Favourite} returns this
 */
proto.entity.entities.Favourite.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional entity.enum.UserOrigin user_origin = 6;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.entities.Favourite.prototype.getUserOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.entities.Favourite} returns this
 */
proto.entity.entities.Favourite.prototype.setUserOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.BasicFavourite.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.BasicFavourite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.BasicFavourite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.BasicFavourite.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    totalFavourites: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.BasicFavourite}
 */
proto.entity.entities.BasicFavourite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.BasicFavourite;
  return proto.entity.entities.BasicFavourite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.BasicFavourite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.BasicFavourite}
 */
proto.entity.entities.BasicFavourite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalFavourites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.BasicFavourite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.BasicFavourite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.BasicFavourite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.BasicFavourite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTotalFavourites();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional bool is_favourite = 1;
 * @return {boolean}
 */
proto.entity.entities.BasicFavourite.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.BasicFavourite} returns this
 */
proto.entity.entities.BasicFavourite.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 total_favourites = 2;
 * @return {number}
 */
proto.entity.entities.BasicFavourite.prototype.getTotalFavourites = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.BasicFavourite} returns this
 */
proto.entity.entities.BasicFavourite.prototype.setTotalFavourites = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.Offer.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Offer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Offer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Offer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Offer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    auctionGroupAssetId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    origin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    updatedDate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    canCounter: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.entity.entities.OfferDocument.toObject, includeInstance),
    dealerName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hasRequiredGloveboxDocuments: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Offer}
 */
proto.entity.entities.Offer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Offer;
  return proto.entity.entities.Offer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Offer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Offer}
 */
proto.entity.entities.Offer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupAssetId(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.OfferOrigin} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedDate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedDate(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanCounter(value);
      break;
    case 13:
      var value = new proto.entity.entities.OfferDocument;
      reader.readMessage(value,proto.entity.entities.OfferDocument.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRequiredGloveboxDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Offer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Offer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Offer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Offer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAuctionGroupAssetId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCreatedDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUpdatedDate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCanCounter();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.entity.entities.OfferDocument.serializeBinaryToWriter
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHasRequiredGloveboxDocuments();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Offer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.entities.Offer.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.Offer.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string buyer_id = 4;
 * @return {string}
 */
proto.entity.entities.Offer.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 5;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.entities.Offer.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string auction_group_asset_id = 6;
 * @return {string}
 */
proto.entity.entities.Offer.prototype.getAuctionGroupAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setAuctionGroupAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional entity.enum.OfferOrigin origin = 7;
 * @return {!proto.entity.enum.OfferOrigin}
 */
proto.entity.entities.Offer.prototype.getOrigin = function() {
  return /** @type {!proto.entity.enum.OfferOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.OfferOrigin} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 8;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.entities.Offer.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.entity.entities.Offer.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 created_date = 10;
 * @return {number}
 */
proto.entity.entities.Offer.prototype.getCreatedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setCreatedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 updated_date = 11;
 * @return {number}
 */
proto.entity.entities.Offer.prototype.getUpdatedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setUpdatedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool can_counter = 12;
 * @return {boolean}
 */
proto.entity.entities.Offer.prototype.getCanCounter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setCanCounter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated OfferDocument documents = 13;
 * @return {!Array<!proto.entity.entities.OfferDocument>}
 */
proto.entity.entities.Offer.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.entity.entities.OfferDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.OfferDocument, 13));
};


/**
 * @param {!Array<!proto.entity.entities.OfferDocument>} value
 * @return {!proto.entity.entities.Offer} returns this
*/
proto.entity.entities.Offer.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.entity.entities.OfferDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.OfferDocument}
 */
proto.entity.entities.Offer.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.entity.entities.OfferDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional string dealer_name = 14;
 * @return {string}
 */
proto.entity.entities.Offer.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool has_required_glovebox_documents = 15;
 * @return {boolean}
 */
proto.entity.entities.Offer.prototype.getHasRequiredGloveboxDocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.Offer} returns this
 */
proto.entity.entities.Offer.prototype.setHasRequiredGloveboxDocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.OfferDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.OfferDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.OfferDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.OfferDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mlDocumentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    offerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    offerDocumentType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 11, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 13, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.OfferDocument}
 */
proto.entity.entities.OfferDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.OfferDocument;
  return proto.entity.entities.OfferDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.OfferDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.OfferDocument}
 */
proto.entity.entities.OfferDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlDocumentUrl(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.DocumentStatus} */ (reader.readEnum());
      msg.setDocumentStatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.enum.OfferDocumentType} */ (reader.readEnum());
      msg.setOfferDocumentType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 11:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 13:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.OfferDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.OfferDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.OfferDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.OfferDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMlDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getOfferDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_id = 2;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string document_url = 3;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ml_document_url = 4;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getMlDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setMlDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.DocumentStatus document_status = 5;
 * @return {!proto.entity.enum.DocumentStatus}
 */
proto.entity.entities.OfferDocument.prototype.getDocumentStatus = function() {
  return /** @type {!proto.entity.enum.DocumentStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.DocumentStatus} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool is_required = 6;
 * @return {boolean}
 */
proto.entity.entities.OfferDocument.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string offer_id = 7;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 8;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.entities.OfferDocument.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional entity.enum.OfferDocumentType offer_document_type = 9;
 * @return {!proto.entity.enum.OfferDocumentType}
 */
proto.entity.entities.OfferDocument.prototype.getOfferDocumentType = function() {
  return /** @type {!proto.entity.enum.OfferDocumentType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.enum.OfferDocumentType} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setOfferDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string dealership_id = 10;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 11;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.entity.entities.OfferDocument.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string buyer_id = 12;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 13;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.entities.OfferDocument.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string vehicle_id = 14;
 * @return {string}
 */
proto.entity.entities.OfferDocument.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.OfferDocument} returns this
 */
proto.entity.entities.OfferDocument.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.OfferDocuments.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.OfferDocuments.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.OfferDocuments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.OfferDocuments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.OfferDocuments.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerDocumentsList: jspb.Message.toObjectList(msg.getOfferDocumentsList(),
    proto.entity.entities.OfferDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.OfferDocuments}
 */
proto.entity.entities.OfferDocuments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.OfferDocuments;
  return proto.entity.entities.OfferDocuments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.OfferDocuments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.OfferDocuments}
 */
proto.entity.entities.OfferDocuments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.entities.OfferDocument;
      reader.readMessage(value,proto.entity.entities.OfferDocument.deserializeBinaryFromReader);
      msg.addOfferDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.OfferDocuments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.OfferDocuments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.OfferDocuments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.OfferDocuments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.entities.OfferDocument.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OfferDocument offer_documents = 1;
 * @return {!Array<!proto.entity.entities.OfferDocument>}
 */
proto.entity.entities.OfferDocuments.prototype.getOfferDocumentsList = function() {
  return /** @type{!Array<!proto.entity.entities.OfferDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.OfferDocument, 1));
};


/**
 * @param {!Array<!proto.entity.entities.OfferDocument>} value
 * @return {!proto.entity.entities.OfferDocuments} returns this
*/
proto.entity.entities.OfferDocuments.prototype.setOfferDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.OfferDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.OfferDocument}
 */
proto.entity.entities.OfferDocuments.prototype.addOfferDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.OfferDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.OfferDocuments} returns this
 */
proto.entity.entities.OfferDocuments.prototype.clearOfferDocumentsList = function() {
  return this.setOfferDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Bid.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Bid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Bid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Bid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Bid}
 */
proto.entity.entities.Bid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Bid;
  return proto.entity.entities.Bid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Bid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Bid}
 */
proto.entity.entities.Bid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Bid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Bid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Bid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Bid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Bid.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.entities.Bid.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.Bid.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.entity.entities.Bid.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 5;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.entities.Bid.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 5));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.entities.Bid} returns this
*/
proto.entity.entities.Bid.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Bid.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string auction_group_id = 6;
 * @return {string}
 */
proto.entity.entities.Bid.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.entity.entities.Bid.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dealer_name = 8;
 * @return {string}
 */
proto.entity.entities.Bid.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string organization_id = 9;
 * @return {string}
 */
proto.entity.entities.Bid.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Bid} returns this
 */
proto.entity.entities.Bid.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.ProxyBid.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.ProxyBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.ProxyBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.ProxyBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dealershipName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.ProxyBid}
 */
proto.entity.entities.ProxyBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.ProxyBid;
  return proto.entity.entities.ProxyBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.ProxyBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.ProxyBid}
 */
proto.entity.entities.ProxyBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.ProxyBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.ProxyBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.ProxyBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.ProxyBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDealershipName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.ProxyBid.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.entities.ProxyBid.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.ProxyBid.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.entity.entities.ProxyBid.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 5;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.entities.ProxyBid.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 5));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.entities.ProxyBid} returns this
*/
proto.entity.entities.ProxyBid.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.ProxyBid.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string auction_group_id = 6;
 * @return {string}
 */
proto.entity.entities.ProxyBid.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.entity.entities.ProxyBid.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dealership_name = 8;
 * @return {string}
 */
proto.entity.entities.ProxyBid.prototype.getDealershipName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setDealershipName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string organization_id = 9;
 * @return {string}
 */
proto.entity.entities.ProxyBid.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.ProxyBid} returns this
 */
proto.entity.entities.ProxyBid.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.ProxyBids.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.ProxyBids.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.ProxyBids.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.ProxyBids} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.ProxyBids.toObject = function(includeInstance, msg) {
  var f, obj = {
    proxyBidsList: jspb.Message.toObjectList(msg.getProxyBidsList(),
    proto.entity.entities.ProxyBid.toObject, includeInstance),
    totalProxyBids: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalProxyBidders: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.ProxyBids}
 */
proto.entity.entities.ProxyBids.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.ProxyBids;
  return proto.entity.entities.ProxyBids.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.ProxyBids} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.ProxyBids}
 */
proto.entity.entities.ProxyBids.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.entities.ProxyBid;
      reader.readMessage(value,proto.entity.entities.ProxyBid.deserializeBinaryFromReader);
      msg.addProxyBids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalProxyBids(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalProxyBidders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.ProxyBids.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.ProxyBids.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.ProxyBids} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.ProxyBids.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProxyBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.entities.ProxyBid.serializeBinaryToWriter
    );
  }
  f = message.getTotalProxyBids();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalProxyBidders();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated ProxyBid proxy_bids = 1;
 * @return {!Array<!proto.entity.entities.ProxyBid>}
 */
proto.entity.entities.ProxyBids.prototype.getProxyBidsList = function() {
  return /** @type{!Array<!proto.entity.entities.ProxyBid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.ProxyBid, 1));
};


/**
 * @param {!Array<!proto.entity.entities.ProxyBid>} value
 * @return {!proto.entity.entities.ProxyBids} returns this
*/
proto.entity.entities.ProxyBids.prototype.setProxyBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.ProxyBid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.ProxyBid}
 */
proto.entity.entities.ProxyBids.prototype.addProxyBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.ProxyBid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.ProxyBids} returns this
 */
proto.entity.entities.ProxyBids.prototype.clearProxyBidsList = function() {
  return this.setProxyBidsList([]);
};


/**
 * optional int64 total_proxy_bids = 2;
 * @return {number}
 */
proto.entity.entities.ProxyBids.prototype.getTotalProxyBids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.ProxyBids} returns this
 */
proto.entity.entities.ProxyBids.prototype.setTotalProxyBids = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_proxy_bidders = 3;
 * @return {number}
 */
proto.entity.entities.ProxyBids.prototype.getTotalProxyBidders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.ProxyBids} returns this
 */
proto.entity.entities.ProxyBids.prototype.setTotalProxyBidders = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.UserVehicleToProxyBids.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.UserVehicleToProxyBids.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.UserVehicleToProxyBids} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.UserVehicleToProxyBids.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleToProxyBidsMap: (f = msg.getVehicleToProxyBidsMap()) ? f.toObject(includeInstance, proto.entity.entities.ProxyBid.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.UserVehicleToProxyBids}
 */
proto.entity.entities.UserVehicleToProxyBids.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.UserVehicleToProxyBids;
  return proto.entity.entities.UserVehicleToProxyBids.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.UserVehicleToProxyBids} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.UserVehicleToProxyBids}
 */
proto.entity.entities.UserVehicleToProxyBids.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getVehicleToProxyBidsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.entity.entities.ProxyBid.deserializeBinaryFromReader, "", new proto.entity.entities.ProxyBid());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.UserVehicleToProxyBids.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.UserVehicleToProxyBids.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.UserVehicleToProxyBids} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.UserVehicleToProxyBids.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleToProxyBidsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.entity.entities.ProxyBid.serializeBinaryToWriter);
  }
};


/**
 * map<string, ProxyBid> vehicle_to_proxy_bids = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.entity.entities.ProxyBid>}
 */
proto.entity.entities.UserVehicleToProxyBids.prototype.getVehicleToProxyBidsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.entity.entities.ProxyBid>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.entity.entities.ProxyBid));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.entity.entities.UserVehicleToProxyBids} returns this
 */
proto.entity.entities.UserVehicleToProxyBids.prototype.clearVehicleToProxyBidsMap = function() {
  this.getVehicleToProxyBidsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Dealership.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Dealership.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Dealership} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Dealership.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    crmDealerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dealershipLocation: (f = msg.getDealershipLocation()) && proto.entity.entities.DealershipLocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Dealership}
 */
proto.entity.entities.Dealership.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Dealership;
  return proto.entity.entities.Dealership.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Dealership} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Dealership}
 */
proto.entity.entities.Dealership.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmDealerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    case 9:
      var value = new proto.entity.entities.DealershipLocation;
      reader.readMessage(value,proto.entity.entities.DealershipLocation.deserializeBinaryFromReader);
      msg.setDealershipLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Dealership.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Dealership.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Dealership} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Dealership.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrmDealerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDealershipLocation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.entity.entities.DealershipLocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 4;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.entities.Dealership.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 4));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.entities.Dealership} returns this
*/
proto.entity.entities.Dealership.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Dealership.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string crm_dealer_id = 5;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getCrmDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setCrmDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string external_dealer_id = 6;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string feed_id = 7;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string primary_make = 8;
 * @return {string}
 */
proto.entity.entities.Dealership.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional DealershipLocation dealership_location = 9;
 * @return {?proto.entity.entities.DealershipLocation}
 */
proto.entity.entities.Dealership.prototype.getDealershipLocation = function() {
  return /** @type{?proto.entity.entities.DealershipLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.DealershipLocation, 9));
};


/**
 * @param {?proto.entity.entities.DealershipLocation|undefined} value
 * @return {!proto.entity.entities.Dealership} returns this
*/
proto.entity.entities.Dealership.prototype.setDealershipLocation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Dealership} returns this
 */
proto.entity.entities.Dealership.prototype.clearDealershipLocation = function() {
  return this.setDealershipLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Dealership.prototype.hasDealershipLocation = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.DealershipLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.DealershipLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.DealershipLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealershipLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 4, ""),
    telephoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.DealershipLocation}
 */
proto.entity.entities.DealershipLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.DealershipLocation;
  return proto.entity.entities.DealershipLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.DealershipLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.DealershipLocation}
 */
proto.entity.entities.DealershipLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.DealershipLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.DealershipLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.DealershipLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealershipLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTelephoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.entity.entities.DealershipLocation.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipLocation} returns this
 */
proto.entity.entities.DealershipLocation.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.entity.entities.DealershipLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipLocation} returns this
 */
proto.entity.entities.DealershipLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.entity.entities.DealershipLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipLocation} returns this
 */
proto.entity.entities.DealershipLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string postal = 4;
 * @return {string}
 */
proto.entity.entities.DealershipLocation.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipLocation} returns this
 */
proto.entity.entities.DealershipLocation.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string telephone_number = 5;
 * @return {string}
 */
proto.entity.entities.DealershipLocation.prototype.getTelephoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipLocation} returns this
 */
proto.entity.entities.DealershipLocation.prototype.setTelephoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fax_number = 6;
 * @return {string}
 */
proto.entity.entities.DealershipLocation.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipLocation} returns this
 */
proto.entity.entities.DealershipLocation.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Location}
 */
proto.entity.entities.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Location;
  return proto.entity.entities.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Location}
 */
proto.entity.entities.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.entity.entities.Location.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.entity.entities.Location.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.entity.entities.Location.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.entity.entities.Location.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.entity.entities.Location.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.entity.entities.Location.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.entity.entities.Location.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double latitude = 8;
 * @return {number}
 */
proto.entity.entities.Location.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Location} returns this
 */
proto.entity.entities.Location.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.RegisteredOwner.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.RegisteredOwner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.RegisteredOwner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.RegisteredOwner.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isPrimary: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    driverLicenseFrontUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    driverLicenseBackUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.RegisteredOwner}
 */
proto.entity.entities.RegisteredOwner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.RegisteredOwner;
  return proto.entity.entities.RegisteredOwner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.RegisteredOwner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.RegisteredOwner}
 */
proto.entity.entities.RegisteredOwner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrimary(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverLicenseFrontUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverLicenseBackUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.RegisteredOwner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.RegisteredOwner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.RegisteredOwner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.RegisteredOwner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsPrimary();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDriverLicenseFrontUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDriverLicenseBackUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.RegisteredOwner.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.entities.RegisteredOwner.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.entity.entities.RegisteredOwner.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.entity.entities.RegisteredOwner.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_primary = 5;
 * @return {boolean}
 */
proto.entity.entities.RegisteredOwner.prototype.getIsPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setIsPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string driver_license_front_url = 6;
 * @return {string}
 */
proto.entity.entities.RegisteredOwner.prototype.getDriverLicenseFrontUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setDriverLicenseFrontUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string driver_license_back_url = 7;
 * @return {string}
 */
proto.entity.entities.RegisteredOwner.prototype.getDriverLicenseBackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.RegisteredOwner} returns this
 */
proto.entity.entities.RegisteredOwner.prototype.setDriverLicenseBackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.RegisteredOwners.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.RegisteredOwners.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.RegisteredOwners.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.RegisteredOwners} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.RegisteredOwners.toObject = function(includeInstance, msg) {
  var f, obj = {
    registeredOwnersList: jspb.Message.toObjectList(msg.getRegisteredOwnersList(),
    proto.entity.entities.RegisteredOwner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.RegisteredOwners}
 */
proto.entity.entities.RegisteredOwners.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.RegisteredOwners;
  return proto.entity.entities.RegisteredOwners.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.RegisteredOwners} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.RegisteredOwners}
 */
proto.entity.entities.RegisteredOwners.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.entities.RegisteredOwner;
      reader.readMessage(value,proto.entity.entities.RegisteredOwner.deserializeBinaryFromReader);
      msg.addRegisteredOwners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.RegisteredOwners.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.RegisteredOwners.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.RegisteredOwners} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.RegisteredOwners.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegisteredOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.entities.RegisteredOwner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RegisteredOwner registered_owners = 1;
 * @return {!Array<!proto.entity.entities.RegisteredOwner>}
 */
proto.entity.entities.RegisteredOwners.prototype.getRegisteredOwnersList = function() {
  return /** @type{!Array<!proto.entity.entities.RegisteredOwner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.RegisteredOwner, 1));
};


/**
 * @param {!Array<!proto.entity.entities.RegisteredOwner>} value
 * @return {!proto.entity.entities.RegisteredOwners} returns this
*/
proto.entity.entities.RegisteredOwners.prototype.setRegisteredOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.RegisteredOwner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.RegisteredOwner}
 */
proto.entity.entities.RegisteredOwners.prototype.addRegisteredOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.RegisteredOwner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.RegisteredOwners} returns this
 */
proto.entity.entities.RegisteredOwners.prototype.clearRegisteredOwnersList = function() {
  return this.setRegisteredOwnersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.DeclarationQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.DeclarationQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.DeclarationQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DeclarationQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerDescription: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.DeclarationQuestion}
 */
proto.entity.entities.DeclarationQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.DeclarationQuestion;
  return proto.entity.entities.DeclarationQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.DeclarationQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.DeclarationQuestion}
 */
proto.entity.entities.DeclarationQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.DeclarationQuestionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.DeclarationQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.DeclarationQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.DeclarationQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DeclarationQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.entity.entities.DeclarationQuestion.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DeclarationQuestion} returns this
 */
proto.entity.entities.DeclarationQuestion.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.DeclarationQuestionType type = 2;
 * @return {!proto.entity.enum.DeclarationQuestionType}
 */
proto.entity.entities.DeclarationQuestion.prototype.getType = function() {
  return /** @type {!proto.entity.enum.DeclarationQuestionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.DeclarationQuestionType} value
 * @return {!proto.entity.entities.DeclarationQuestion} returns this
 */
proto.entity.entities.DeclarationQuestion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string answer = 3;
 * @return {string}
 */
proto.entity.entities.DeclarationQuestion.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DeclarationQuestion} returns this
 */
proto.entity.entities.DeclarationQuestion.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string answer_description = 4;
 * @return {string}
 */
proto.entity.entities.DeclarationQuestion.prototype.getAnswerDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DeclarationQuestion} returns this
 */
proto.entity.entities.DeclarationQuestion.prototype.setAnswerDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.DeclarationQuestions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.DeclarationQuestions.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.DeclarationQuestions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.DeclarationQuestions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DeclarationQuestions.toObject = function(includeInstance, msg) {
  var f, obj = {
    declarationQuestionsList: jspb.Message.toObjectList(msg.getDeclarationQuestionsList(),
    proto.entity.entities.DeclarationQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.DeclarationQuestions}
 */
proto.entity.entities.DeclarationQuestions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.DeclarationQuestions;
  return proto.entity.entities.DeclarationQuestions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.DeclarationQuestions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.DeclarationQuestions}
 */
proto.entity.entities.DeclarationQuestions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.entities.DeclarationQuestion;
      reader.readMessage(value,proto.entity.entities.DeclarationQuestion.deserializeBinaryFromReader);
      msg.addDeclarationQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.DeclarationQuestions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.DeclarationQuestions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.DeclarationQuestions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DeclarationQuestions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeclarationQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.entities.DeclarationQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeclarationQuestion declaration_questions = 1;
 * @return {!Array<!proto.entity.entities.DeclarationQuestion>}
 */
proto.entity.entities.DeclarationQuestions.prototype.getDeclarationQuestionsList = function() {
  return /** @type{!Array<!proto.entity.entities.DeclarationQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.DeclarationQuestion, 1));
};


/**
 * @param {!Array<!proto.entity.entities.DeclarationQuestion>} value
 * @return {!proto.entity.entities.DeclarationQuestions} returns this
*/
proto.entity.entities.DeclarationQuestions.prototype.setDeclarationQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.DeclarationQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.DeclarationQuestion}
 */
proto.entity.entities.DeclarationQuestions.prototype.addDeclarationQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.DeclarationQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.DeclarationQuestions} returns this
 */
proto.entity.entities.DeclarationQuestions.prototype.clearDeclarationQuestionsList = function() {
  return this.setDeclarationQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.AuctionVehiclePricing.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.AuctionVehiclePricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.AuctionVehiclePricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.AuctionVehiclePricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    startPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.AuctionVehiclePricing}
 */
proto.entity.entities.AuctionVehiclePricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.AuctionVehiclePricing;
  return proto.entity.entities.AuctionVehiclePricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.AuctionVehiclePricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.AuctionVehiclePricing}
 */
proto.entity.entities.AuctionVehiclePricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPrice(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.AuctionVehiclePricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.AuctionVehiclePricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.AuctionVehiclePricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.AuctionVehiclePricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional double start_price = 1;
 * @return {number}
 */
proto.entity.entities.AuctionVehiclePricing.prototype.getStartPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.AuctionVehiclePricing} returns this
 */
proto.entity.entities.AuctionVehiclePricing.prototype.setStartPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional entity.enum.Currency currency = 2;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.AuctionVehiclePricing.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.AuctionVehiclePricing} returns this
 */
proto.entity.entities.AuctionVehiclePricing.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.CompletedSale.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.CompletedSale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.CompletedSale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CompletedSale.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 8, 0),
    contractId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    offerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    sellerId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    sellerOrigin: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.CompletedSale}
 */
proto.entity.entities.CompletedSale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.CompletedSale;
  return proto.entity.entities.CompletedSale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.CompletedSale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.CompletedSale}
 */
proto.entity.entities.CompletedSale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 11:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellerId(value);
      break;
    case 14:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setSellerOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.CompletedSale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.CompletedSale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.CompletedSale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CompletedSale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getContractId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSellerId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSellerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.CompletedSale.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.entities.CompletedSale.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.CompletedSale.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string buyer_id = 4;
 * @return {string}
 */
proto.entity.entities.CompletedSale.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 5;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.entities.CompletedSale.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string vehicle_id = 6;
 * @return {string}
 */
proto.entity.entities.CompletedSale.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 7;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.entities.CompletedSale.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 8;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.entity.entities.CompletedSale.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string contract_id = 9;
 * @return {string}
 */
proto.entity.entities.CompletedSale.prototype.getContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 effective_date = 10;
 * @return {number}
 */
proto.entity.entities.CompletedSale.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 11;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.entities.CompletedSale.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 11));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.entities.CompletedSale} returns this
*/
proto.entity.entities.CompletedSale.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.CompletedSale.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string offer_id = 12;
 * @return {string}
 */
proto.entity.entities.CompletedSale.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string seller_id = 13;
 * @return {string}
 */
proto.entity.entities.CompletedSale.prototype.getSellerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setSellerId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional entity.enum.UserOrigin seller_origin = 14;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.entities.CompletedSale.prototype.getSellerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.entities.CompletedSale} returns this
 */
proto.entity.entities.CompletedSale.prototype.setSellerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.CompletedSales.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.CompletedSales.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.CompletedSales.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.CompletedSales} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CompletedSales.toObject = function(includeInstance, msg) {
  var f, obj = {
    completedSalesList: jspb.Message.toObjectList(msg.getCompletedSalesList(),
    proto.entity.entities.CompletedSale.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.CompletedSales}
 */
proto.entity.entities.CompletedSales.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.CompletedSales;
  return proto.entity.entities.CompletedSales.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.CompletedSales} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.CompletedSales}
 */
proto.entity.entities.CompletedSales.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.entities.CompletedSale;
      reader.readMessage(value,proto.entity.entities.CompletedSale.deserializeBinaryFromReader);
      msg.addCompletedSales(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.CompletedSales.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.CompletedSales.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.CompletedSales} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CompletedSales.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompletedSalesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.entities.CompletedSale.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompletedSale completed_sales = 1;
 * @return {!Array<!proto.entity.entities.CompletedSale>}
 */
proto.entity.entities.CompletedSales.prototype.getCompletedSalesList = function() {
  return /** @type{!Array<!proto.entity.entities.CompletedSale>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.CompletedSale, 1));
};


/**
 * @param {!Array<!proto.entity.entities.CompletedSale>} value
 * @return {!proto.entity.entities.CompletedSales} returns this
*/
proto.entity.entities.CompletedSales.prototype.setCompletedSalesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.CompletedSale=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.CompletedSale}
 */
proto.entity.entities.CompletedSales.prototype.addCompletedSales = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.CompletedSale, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.CompletedSales} returns this
 */
proto.entity.entities.CompletedSales.prototype.clearCompletedSalesList = function() {
  return this.setCompletedSalesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.AuctionGroupAsset.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.AuctionGroupAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.AuctionGroupAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.AuctionGroupAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.AuctionGroupAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.entity.entities.Bid.toObject, includeInstance),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userReferenceId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    assetReferenceId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    startPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.AuctionGroupAsset}
 */
proto.entity.entities.AuctionGroupAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.AuctionGroupAsset;
  return proto.entity.entities.AuctionGroupAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.AuctionGroupAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.AuctionGroupAsset}
 */
proto.entity.entities.AuctionGroupAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 5:
      var value = new proto.entity.entities.Bid;
      reader.readMessage(value,proto.entity.entities.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserReferenceId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetReferenceId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPrice(value);
      break;
    case 14:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.AuctionGroupAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.AuctionGroupAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.AuctionGroupAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.AuctionGroupAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.entity.entities.Bid.serializeBinaryToWriter
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserReferenceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAssetReferenceId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStartPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string asset_id = 3;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.enum.AuctionStatus auction_status = 4;
 * @return {!proto.entity.enum.AuctionStatus}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getAuctionStatus = function() {
  return /** @type {!proto.entity.enum.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.AuctionStatus} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated Bid bids = 5;
 * @return {!Array<!proto.entity.entities.Bid>}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.entity.entities.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.Bid, 5));
};


/**
 * @param {!Array<!proto.entity.entities.Bid>} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
*/
proto.entity.entities.AuctionGroupAsset.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.entity.entities.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Bid}
 */
proto.entity.entities.AuctionGroupAsset.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.entity.entities.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional entity.enum.OfferStatus offer_status = 6;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string auction_group_id = 7;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_reference_id = 8;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getUserReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setUserReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_given_name = 9;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string user_phone_number = 10;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string asset_reference_id = 11;
 * @return {string}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getAssetReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setAssetReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 start_time = 12;
 * @return {number}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional double start_price = 13;
 * @return {number}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getStartPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setStartPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional entity.enum.Currency currency = 14;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.AuctionGroupAsset.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.AuctionGroupAsset} returns this
 */
proto.entity.entities.AuctionGroupAsset.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mlImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    label: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Image}
 */
proto.entity.entities.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Image;
  return proto.entity.entities.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Image}
 */
proto.entity.entities.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.ImageSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMlImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string original_url = 1;
 * @return {string}
 */
proto.entity.entities.Image.prototype.getOriginalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Image} returns this
 */
proto.entity.entities.Image.prototype.setOriginalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ml_image_url = 2;
 * @return {string}
 */
proto.entity.entities.Image.prototype.getMlImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Image} returns this
 */
proto.entity.entities.Image.prototype.setMlImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.entity.entities.Image.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Image} returns this
 */
proto.entity.entities.Image.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool processed = 4;
 * @return {boolean}
 */
proto.entity.entities.Image.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.Image} returns this
 */
proto.entity.entities.Image.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional entity.enum.ImageSource source = 5;
 * @return {!proto.entity.enum.ImageSource}
 */
proto.entity.entities.Image.prototype.getSource = function() {
  return /** @type {!proto.entity.enum.ImageSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.ImageSource} value
 * @return {!proto.entity.entities.Image} returns this
 */
proto.entity.entities.Image.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.entity.entities.Image.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Image} returns this
 */
proto.entity.entities.Image.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.Vehicle.repeatedFields_ = [15,18,19,26,28,30,33];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    make: jspb.Message.getFieldWithDefault(msg, 6, ""),
    model: jspb.Message.getFieldWithDefault(msg, 7, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mileage: jspb.Message.getFieldWithDefault(msg, 10, 0),
    location: (f = msg.getLocation()) && proto.entity.entities.Location.toObject(includeInstance, f),
    carfaxUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    inspectionId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    paveReportUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.entity.entities.Image.toObject, includeInstance),
    autoGradeRating: jspb.Message.getFieldWithDefault(msg, 16, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 17, 0),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.entity.entities.Bid.toObject, includeInstance),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.entity.entities.AuctionGroup.toObject, includeInstance),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 20, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 21, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 23, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 25, 0),
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    proto.entity.entities.Offer.toObject, includeInstance),
    isGloveboxComplete: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    registeredOwnersList: jspb.Message.toObjectList(msg.getRegisteredOwnersList(),
    proto.entity.entities.RegisteredOwner.toObject, includeInstance),
    registrationAddress: (f = msg.getRegistrationAddress()) && proto.entity.entities.Location.toObject(includeInstance, f),
    declarationQuestionsList: jspb.Message.toObjectList(msg.getDeclarationQuestionsList(),
    proto.entity.entities.DeclarationQuestion.toObject, includeInstance),
    vehicleCarfaxInfo: (f = msg.getVehicleCarfaxInfo()) && proto.entity.entities.VehicleCarFaxInfo.toObject(includeInstance, f),
    carfaxLienUrl: jspb.Message.getFieldWithDefault(msg, 32, ""),
    carfaxAnnouncementsList: jspb.Message.toObjectList(msg.getCarfaxAnnouncementsList(),
    proto.entity.entities.CarfaxAnnouncement.toObject, includeInstance),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 34, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 35, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 36, ""),
    engine: jspb.Message.getFieldWithDefault(msg, 37, ""),
    engineSize: jspb.Message.getFieldWithDefault(msg, 38, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Vehicle}
 */
proto.entity.entities.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Vehicle;
  return proto.entity.entities.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Vehicle}
 */
proto.entity.entities.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.enum.VehicleStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 11:
      var value = new proto.entity.entities.Location;
      reader.readMessage(value,proto.entity.entities.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveReportUrl(value);
      break;
    case 15:
      var value = new proto.entity.entities.Image;
      reader.readMessage(value,proto.entity.entities.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGradeRating(value);
      break;
    case 17:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 18:
      var value = new proto.entity.entities.Bid;
      reader.readMessage(value,proto.entity.entities.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 19:
      var value = new proto.entity.entities.AuctionGroup;
      reader.readMessage(value,proto.entity.entities.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 23:
      var value = /** @type {!proto.entity.enum.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 25:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 26:
      var value = new proto.entity.entities.Offer;
      reader.readMessage(value,proto.entity.entities.Offer.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGloveboxComplete(value);
      break;
    case 28:
      var value = new proto.entity.entities.RegisteredOwner;
      reader.readMessage(value,proto.entity.entities.RegisteredOwner.deserializeBinaryFromReader);
      msg.addRegisteredOwners(value);
      break;
    case 29:
      var value = new proto.entity.entities.Location;
      reader.readMessage(value,proto.entity.entities.Location.deserializeBinaryFromReader);
      msg.setRegistrationAddress(value);
      break;
    case 30:
      var value = new proto.entity.entities.DeclarationQuestion;
      reader.readMessage(value,proto.entity.entities.DeclarationQuestion.deserializeBinaryFromReader);
      msg.addDeclarationQuestions(value);
      break;
    case 31:
      var value = new proto.entity.entities.VehicleCarFaxInfo;
      reader.readMessage(value,proto.entity.entities.VehicleCarFaxInfo.deserializeBinaryFromReader);
      msg.setVehicleCarfaxInfo(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxLienUrl(value);
      break;
    case 33:
      var value = new proto.entity.entities.CarfaxAnnouncement;
      reader.readMessage(value,proto.entity.entities.CarfaxAnnouncement.deserializeBinaryFromReader);
      msg.addCarfaxAnnouncements(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngine(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngineSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.entity.entities.Location.serializeBinaryToWriter
    );
  }
  f = message.getCarfaxUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPaveReportUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.entity.entities.Image.serializeBinaryToWriter
    );
  }
  f = message.getAutoGradeRating();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.entity.entities.Bid.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.entity.entities.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.entity.entities.Offer.serializeBinaryToWriter
    );
  }
  f = message.getIsGloveboxComplete();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getRegisteredOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.entity.entities.RegisteredOwner.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationAddress();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.entity.entities.Location.serializeBinaryToWriter
    );
  }
  f = message.getDeclarationQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto.entity.entities.DeclarationQuestion.serializeBinaryToWriter
    );
  }
  f = message.getVehicleCarfaxInfo();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.entity.entities.VehicleCarFaxInfo.serializeBinaryToWriter
    );
  }
  f = message.getCarfaxLienUrl();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getCarfaxAnnouncementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      proto.entity.entities.CarfaxAnnouncement.serializeBinaryToWriter
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getEngine();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getEngineSize();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.entities.Vehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string make = 6;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string model = 7;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 seats = 8;
 * @return {number}
 */
proto.entity.entities.Vehicle.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional entity.enum.VehicleStatus status = 9;
 * @return {!proto.entity.enum.VehicleStatus}
 */
proto.entity.entities.Vehicle.prototype.getStatus = function() {
  return /** @type {!proto.entity.enum.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.enum.VehicleStatus} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 mileage = 10;
 * @return {number}
 */
proto.entity.entities.Vehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Location location = 11;
 * @return {?proto.entity.entities.Location}
 */
proto.entity.entities.Vehicle.prototype.getLocation = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.Location, 11));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Vehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string carfax_url = 12;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getCarfaxUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setCarfaxUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string inspection_id = 13;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string pave_report_url = 14;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getPaveReportUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setPaveReportUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated Image images = 15;
 * @return {!Array<!proto.entity.entities.Image>}
 */
proto.entity.entities.Vehicle.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.entity.entities.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.Image, 15));
};


/**
 * @param {!Array<!proto.entity.entities.Image>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.entity.entities.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Image}
 */
proto.entity.entities.Vehicle.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.entity.entities.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string auto_grade_rating = 16;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getAutoGradeRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setAutoGradeRating = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 17;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.entities.Vehicle.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated Bid bids = 18;
 * @return {!Array<!proto.entity.entities.Bid>}
 */
proto.entity.entities.Vehicle.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.entity.entities.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.Bid, 18));
};


/**
 * @param {!Array<!proto.entity.entities.Bid>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.entity.entities.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Bid}
 */
proto.entity.entities.Vehicle.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.entity.entities.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * repeated AuctionGroup auction_groups = 19;
 * @return {!Array<!proto.entity.entities.AuctionGroup>}
 */
proto.entity.entities.Vehicle.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.entity.entities.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.AuctionGroup, 19));
};


/**
 * @param {!Array<!proto.entity.entities.AuctionGroup>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.entity.entities.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.AuctionGroup}
 */
proto.entity.entities.Vehicle.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.entity.entities.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional string user_given_name = 20;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string user_phone_number = 21;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string user_id = 22;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional entity.enum.MileageUOM mileage_uom = 23;
 * @return {!proto.entity.enum.MileageUOM}
 */
proto.entity.entities.Vehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.enum.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.entity.enum.MileageUOM} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional double price = 24;
 * @return {number}
 */
proto.entity.entities.Vehicle.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional entity.enum.Currency currency = 25;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.Vehicle.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * repeated Offer offers = 26;
 * @return {!Array<!proto.entity.entities.Offer>}
 */
proto.entity.entities.Vehicle.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.entity.entities.Offer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.Offer, 26));
};


/**
 * @param {!Array<!proto.entity.entities.Offer>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.entity.entities.Offer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Offer}
 */
proto.entity.entities.Vehicle.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.entity.entities.Offer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};


/**
 * optional bool is_glovebox_complete = 27;
 * @return {boolean}
 */
proto.entity.entities.Vehicle.prototype.getIsGloveboxComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setIsGloveboxComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * repeated RegisteredOwner registered_owners = 28;
 * @return {!Array<!proto.entity.entities.RegisteredOwner>}
 */
proto.entity.entities.Vehicle.prototype.getRegisteredOwnersList = function() {
  return /** @type{!Array<!proto.entity.entities.RegisteredOwner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.RegisteredOwner, 28));
};


/**
 * @param {!Array<!proto.entity.entities.RegisteredOwner>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setRegisteredOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.entity.entities.RegisteredOwner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.RegisteredOwner}
 */
proto.entity.entities.Vehicle.prototype.addRegisteredOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.entity.entities.RegisteredOwner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearRegisteredOwnersList = function() {
  return this.setRegisteredOwnersList([]);
};


/**
 * optional Location registration_address = 29;
 * @return {?proto.entity.entities.Location}
 */
proto.entity.entities.Vehicle.prototype.getRegistrationAddress = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.Location, 29));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setRegistrationAddress = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearRegistrationAddress = function() {
  return this.setRegistrationAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Vehicle.prototype.hasRegistrationAddress = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * repeated DeclarationQuestion declaration_questions = 30;
 * @return {!Array<!proto.entity.entities.DeclarationQuestion>}
 */
proto.entity.entities.Vehicle.prototype.getDeclarationQuestionsList = function() {
  return /** @type{!Array<!proto.entity.entities.DeclarationQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.DeclarationQuestion, 30));
};


/**
 * @param {!Array<!proto.entity.entities.DeclarationQuestion>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setDeclarationQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.entity.entities.DeclarationQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.DeclarationQuestion}
 */
proto.entity.entities.Vehicle.prototype.addDeclarationQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.entity.entities.DeclarationQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearDeclarationQuestionsList = function() {
  return this.setDeclarationQuestionsList([]);
};


/**
 * optional VehicleCarFaxInfo vehicle_carfax_info = 31;
 * @return {?proto.entity.entities.VehicleCarFaxInfo}
 */
proto.entity.entities.Vehicle.prototype.getVehicleCarfaxInfo = function() {
  return /** @type{?proto.entity.entities.VehicleCarFaxInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.VehicleCarFaxInfo, 31));
};


/**
 * @param {?proto.entity.entities.VehicleCarFaxInfo|undefined} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setVehicleCarfaxInfo = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearVehicleCarfaxInfo = function() {
  return this.setVehicleCarfaxInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Vehicle.prototype.hasVehicleCarfaxInfo = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string carfax_lien_url = 32;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getCarfaxLienUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setCarfaxLienUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * repeated CarfaxAnnouncement carfax_announcements = 33;
 * @return {!Array<!proto.entity.entities.CarfaxAnnouncement>}
 */
proto.entity.entities.Vehicle.prototype.getCarfaxAnnouncementsList = function() {
  return /** @type{!Array<!proto.entity.entities.CarfaxAnnouncement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.CarfaxAnnouncement, 33));
};


/**
 * @param {!Array<!proto.entity.entities.CarfaxAnnouncement>} value
 * @return {!proto.entity.entities.Vehicle} returns this
*/
proto.entity.entities.Vehicle.prototype.setCarfaxAnnouncementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!proto.entity.entities.CarfaxAnnouncement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.CarfaxAnnouncement}
 */
proto.entity.entities.Vehicle.prototype.addCarfaxAnnouncements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, proto.entity.entities.CarfaxAnnouncement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.clearCarfaxAnnouncementsList = function() {
  return this.setCarfaxAnnouncementsList([]);
};


/**
 * optional string trim_level = 34;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string exterior_color = 35;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string transmission = 36;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string engine = 37;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getEngine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setEngine = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string engine_size = 38;
 * @return {string}
 */
proto.entity.entities.Vehicle.prototype.getEngineSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Vehicle} returns this
 */
proto.entity.entities.Vehicle.prototype.setEngineSize = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.CarfaxAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.CarfaxAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CarfaxAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    amountSpecified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    dateSpecified: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.CarfaxAnnouncement}
 */
proto.entity.entities.CarfaxAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.CarfaxAnnouncement;
  return proto.entity.entities.CarfaxAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.CarfaxAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.CarfaxAnnouncement}
 */
proto.entity.entities.CarfaxAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmountSpecified(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDateSpecified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.CarfaxAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.CarfaxAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CarfaxAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAmountSpecified();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDateSpecified();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CarfaxAnnouncement} returns this
 */
proto.entity.entities.CarfaxAnnouncement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CarfaxAnnouncement} returns this
 */
proto.entity.entities.CarfaxAnnouncement.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.CarfaxAnnouncement} returns this
 */
proto.entity.entities.CarfaxAnnouncement.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CarfaxAnnouncement} returns this
 */
proto.entity.entities.CarfaxAnnouncement.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool amount_specified = 5;
 * @return {boolean}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.getAmountSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.CarfaxAnnouncement} returns this
 */
proto.entity.entities.CarfaxAnnouncement.prototype.setAmountSpecified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool date_specified = 6;
 * @return {boolean}
 */
proto.entity.entities.CarfaxAnnouncement.prototype.getDateSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.CarfaxAnnouncement} returns this
 */
proto.entity.entities.CarfaxAnnouncement.prototype.setDateSpecified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Upsell.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Upsell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Upsell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Upsell.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marketplace: (f = msg.getMarketplace()) && proto.entity.entities.Marketplace.toObject(includeInstance, f),
    sku: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 7, 0),
    upsellType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceReduced: jspb.Message.getFieldWithDefault(msg, 9, 0),
    aliasOf: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isListingPromotion: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    effectiveDuration: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Upsell}
 */
proto.entity.entities.Upsell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Upsell;
  return proto.entity.entities.Upsell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Upsell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Upsell}
 */
proto.entity.entities.Upsell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.entity.entities.Marketplace;
      reader.readMessage(value,proto.entity.entities.Marketplace.deserializeBinaryFromReader);
      msg.setMarketplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.UpsellType} */ (reader.readEnum());
      msg.setUpsellType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriceReduced(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAliasOf(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsListingPromotion(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Upsell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Upsell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Upsell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Upsell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarketplace();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.entities.Marketplace.serializeBinaryToWriter
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getUpsellType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPriceReduced();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAliasOf();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsListingPromotion();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getEffectiveDuration();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Upsell.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Marketplace marketplace = 2;
 * @return {?proto.entity.entities.Marketplace}
 */
proto.entity.entities.Upsell.prototype.getMarketplace = function() {
  return /** @type{?proto.entity.entities.Marketplace} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.Marketplace, 2));
};


/**
 * @param {?proto.entity.entities.Marketplace|undefined} value
 * @return {!proto.entity.entities.Upsell} returns this
*/
proto.entity.entities.Upsell.prototype.setMarketplace = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.clearMarketplace = function() {
  return this.setMarketplace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.Upsell.prototype.hasMarketplace = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sku = 3;
 * @return {string}
 */
proto.entity.entities.Upsell.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.entity.entities.Upsell.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.entity.entities.Upsell.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 price = 6;
 * @return {number}
 */
proto.entity.entities.Upsell.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional entity.enum.Currency currency = 7;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.Upsell.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.UpsellType upsell_type = 8;
 * @return {!proto.entity.enum.UpsellType}
 */
proto.entity.entities.Upsell.prototype.getUpsellType = function() {
  return /** @type {!proto.entity.enum.UpsellType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.UpsellType} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setUpsellType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 price_reduced = 9;
 * @return {number}
 */
proto.entity.entities.Upsell.prototype.getPriceReduced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setPriceReduced = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string alias_of = 10;
 * @return {string}
 */
proto.entity.entities.Upsell.prototype.getAliasOf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setAliasOf = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_listing_promotion = 11;
 * @return {boolean}
 */
proto.entity.entities.Upsell.prototype.getIsListingPromotion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setIsListingPromotion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 effective_duration = 12;
 * @return {number}
 */
proto.entity.entities.Upsell.prototype.getEffectiveDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.Upsell} returns this
 */
proto.entity.entities.Upsell.prototype.setEffectiveDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.PaymentForUpsell.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.PaymentForUpsell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.PaymentForUpsell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.PaymentForUpsell.toObject = function(includeInstance, msg) {
  var f, obj = {
    upsellId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.PaymentForUpsell}
 */
proto.entity.entities.PaymentForUpsell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.PaymentForUpsell;
  return proto.entity.entities.PaymentForUpsell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.PaymentForUpsell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.PaymentForUpsell}
 */
proto.entity.entities.PaymentForUpsell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpsellId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.PaymentForUpsell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.PaymentForUpsell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.PaymentForUpsell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.PaymentForUpsell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpsellId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string upsell_id = 1;
 * @return {string}
 */
proto.entity.entities.PaymentForUpsell.prototype.getUpsellId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.PaymentForUpsell} returns this
 */
proto.entity.entities.PaymentForUpsell.prototype.setUpsellId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.entities.PaymentForUpsell.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.PaymentForUpsell} returns this
 */
proto.entity.entities.PaymentForUpsell.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.PaymentForInspection.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.PaymentForInspection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.PaymentForInspection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.PaymentForInspection.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.PaymentForInspection}
 */
proto.entity.entities.PaymentForInspection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.PaymentForInspection;
  return proto.entity.entities.PaymentForInspection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.PaymentForInspection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.PaymentForInspection}
 */
proto.entity.entities.PaymentForInspection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.PaymentForInspection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.PaymentForInspection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.PaymentForInspection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.PaymentForInspection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string inspection_id = 1;
 * @return {string}
 */
proto.entity.entities.PaymentForInspection.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.PaymentForInspection} returns this
 */
proto.entity.entities.PaymentForInspection.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.PaymentForOnboarding.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.PaymentForOnboarding.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.PaymentForOnboarding} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.PaymentForOnboarding.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.PaymentForOnboarding}
 */
proto.entity.entities.PaymentForOnboarding.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.PaymentForOnboarding;
  return proto.entity.entities.PaymentForOnboarding.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.PaymentForOnboarding} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.PaymentForOnboarding}
 */
proto.entity.entities.PaymentForOnboarding.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.PaymentForOnboarding.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.PaymentForOnboarding.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.PaymentForOnboarding} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.PaymentForOnboarding.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.entity.entities.PaymentForOnboarding.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.PaymentForOnboarding} returns this
 */
proto.entity.entities.PaymentForOnboarding.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.VehicleUpsell.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.VehicleUpsell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.VehicleUpsell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehicleUpsell.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    upsellId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paymentId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.VehicleUpsell}
 */
proto.entity.entities.VehicleUpsell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.VehicleUpsell;
  return proto.entity.entities.VehicleUpsell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.VehicleUpsell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.VehicleUpsell}
 */
proto.entity.entities.VehicleUpsell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpsellId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.VehicleUpsell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.VehicleUpsell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.VehicleUpsell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehicleUpsell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpsellId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaymentId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.VehicleUpsell.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleUpsell} returns this
 */
proto.entity.entities.VehicleUpsell.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.entities.VehicleUpsell.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleUpsell} returns this
 */
proto.entity.entities.VehicleUpsell.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string upsell_id = 3;
 * @return {string}
 */
proto.entity.entities.VehicleUpsell.prototype.getUpsellId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleUpsell} returns this
 */
proto.entity.entities.VehicleUpsell.prototype.setUpsellId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payment_id = 4;
 * @return {string}
 */
proto.entity.entities.VehicleUpsell.prototype.getPaymentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleUpsell} returns this
 */
proto.entity.entities.VehicleUpsell.prototype.setPaymentId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.VehicleCarFaxInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.VehicleCarFaxInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehicleCarFaxInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isStolen: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isSalvage: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hasUsaHistory: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasFrameDamage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasOpenRecalls: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    recentVhrOrder: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hasRebuiltTitle: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hasBcRegistration: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    hasDeployedAirbag: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isExportedVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isInactiveVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    potentialVinClone: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    serviceLast60Days: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    inspectionRequired: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasFireOrFloodDamage: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    firstRegistrationDate: jspb.Message.getFieldWithDefault(msg, 16, ""),
    latestRegistrationDate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    sourceSystemReferenceId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    hasInterprovincialHistory: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    hasPoliceReportedAccident: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hasPotentialOdometerProblem: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    currentRegistrationJurisdiction: jspb.Message.getFieldWithDefault(msg, 22, ""),
    hasMultipleCurrentRegistrations: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    ownershipType: jspb.Message.getFieldWithDefault(msg, 24, ""),
    latestDamageDate: jspb.Message.getFieldWithDefault(msg, 25, ""),
    latestOdometerKm: jspb.Message.getFieldWithDefault(msg, 26, 0),
    latestOdometerReadingDate: jspb.Message.getFieldWithDefault(msg, 27, ""),
    hasDamageOverThreshold: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    numberOfReportedOwners: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.VehicleCarFaxInfo}
 */
proto.entity.entities.VehicleCarFaxInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.VehicleCarFaxInfo;
  return proto.entity.entities.VehicleCarFaxInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.VehicleCarFaxInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.VehicleCarFaxInfo}
 */
proto.entity.entities.VehicleCarFaxInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStolen(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSalvage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUsaHistory(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFrameDamage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOpenRecalls(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecentVhrOrder(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRebuiltTitle(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBcRegistration(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeployedAirbag(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExportedVehicle(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInactiveVehicle(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPotentialVinClone(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServiceLast60Days(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionRequired(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFireOrFloodDamage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstRegistrationDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestRegistrationDate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceSystemReferenceId(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInterprovincialHistory(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPoliceReportedAccident(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPotentialOdometerProblem(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentRegistrationJurisdiction(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMultipleCurrentRegistrations(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnershipType(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestDamageDate(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestOdometerKm(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestOdometerReadingDate(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDamageOverThreshold(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfReportedOwners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.VehicleCarFaxInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.VehicleCarFaxInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehicleCarFaxInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsStolen();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsSalvage();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHasUsaHistory();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasFrameDamage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasOpenRecalls();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRecentVhrOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasRebuiltTitle();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHasBcRegistration();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHasDeployedAirbag();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsExportedVehicle();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsInactiveVehicle();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPotentialVinClone();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getServiceLast60Days();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getInspectionRequired();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasFireOrFloodDamage();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getFirstRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLatestRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSourceSystemReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getHasInterprovincialHistory();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getHasPoliceReportedAccident();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHasPotentialOdometerProblem();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCurrentRegistrationJurisdiction();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getHasMultipleCurrentRegistrations();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getOwnershipType();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getLatestDamageDate();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLatestOdometerKm();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getLatestOdometerReadingDate();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getHasDamageOverThreshold();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getNumberOfReportedOwners();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
};


/**
 * optional bool is_stolen = 1;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getIsStolen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setIsStolen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_salvage = 2;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getIsSalvage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setIsSalvage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool has_usa_history = 3;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasUsaHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasUsaHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_frame_damage = 4;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasFrameDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasFrameDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_open_recalls = 5;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasOpenRecalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasOpenRecalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool recent_vhr_order = 6;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getRecentVhrOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setRecentVhrOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool has_rebuilt_title = 7;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasRebuiltTitle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasRebuiltTitle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool has_bc_registration = 8;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasBcRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasBcRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool has_deployed_airbag = 9;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasDeployedAirbag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasDeployedAirbag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_exported_vehicle = 10;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getIsExportedVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setIsExportedVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_inactive_vehicle = 11;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getIsInactiveVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setIsInactiveVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool potential_vin_clone = 12;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getPotentialVinClone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setPotentialVinClone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool service_last_60_days = 13;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getServiceLast60Days = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setServiceLast60Days = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool inspection_required = 14;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getInspectionRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setInspectionRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool has_fire_or_flood_damage = 15;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasFireOrFloodDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasFireOrFloodDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string first_registration_date = 16;
 * @return {string}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getFirstRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setFirstRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string latest_registration_date = 17;
 * @return {string}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getLatestRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setLatestRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 source_system_reference_id = 18;
 * @return {number}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getSourceSystemReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setSourceSystemReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool has_interprovincial_history = 19;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasInterprovincialHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasInterprovincialHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool has_police_reported_accident = 20;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasPoliceReportedAccident = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasPoliceReportedAccident = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool has_potential_odometer_problem = 21;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasPotentialOdometerProblem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasPotentialOdometerProblem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string current_registration_jurisdiction = 22;
 * @return {string}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getCurrentRegistrationJurisdiction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setCurrentRegistrationJurisdiction = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool has_multiple_current_registrations = 23;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasMultipleCurrentRegistrations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasMultipleCurrentRegistrations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional string ownership_type = 24;
 * @return {string}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getOwnershipType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setOwnershipType = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string latest_damage_date = 25;
 * @return {string}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getLatestDamageDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setLatestDamageDate = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int64 latest_odometer_km = 26;
 * @return {number}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getLatestOdometerKm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setLatestOdometerKm = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string latest_odometer_reading_date = 27;
 * @return {string}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getLatestOdometerReadingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setLatestOdometerReadingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional bool has_damage_over_threshold = 28;
 * @return {boolean}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getHasDamageOverThreshold = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setHasDamageOverThreshold = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional int32 number_of_reported_owners = 29;
 * @return {number}
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.getNumberOfReportedOwners = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.VehicleCarFaxInfo} returns this
 */
proto.entity.entities.VehicleCarFaxInfo.prototype.setNumberOfReportedOwners = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.User.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    marketplace: (f = msg.getMarketplace()) && proto.entity.entities.Marketplace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.User}
 */
proto.entity.entities.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.User;
  return proto.entity.entities.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.User}
 */
proto.entity.entities.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.UserRole} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 7:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = new proto.entity.entities.Marketplace;
      reader.readMessage(value,proto.entity.entities.Marketplace.deserializeBinaryFromReader);
      msg.setMarketplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMarketplace();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.entity.entities.Marketplace.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.entities.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.entity.entities.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string family_name = 4;
 * @return {string}
 */
proto.entity.entities.User.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string given_name = 5;
 * @return {string}
 */
proto.entity.entities.User.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional entity.enum.UserRole user_role = 6;
 * @return {!proto.entity.enum.UserRole}
 */
proto.entity.entities.User.prototype.getUserRole = function() {
  return /** @type {!proto.entity.enum.UserRole} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.UserRole} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 7;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.entities.User.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 7));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.entities.User} returns this
*/
proto.entity.entities.User.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.User.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.entity.entities.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Marketplace marketplace = 9;
 * @return {?proto.entity.entities.Marketplace}
 */
proto.entity.entities.User.prototype.getMarketplace = function() {
  return /** @type{?proto.entity.entities.Marketplace} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.Marketplace, 9));
};


/**
 * @param {?proto.entity.entities.Marketplace|undefined} value
 * @return {!proto.entity.entities.User} returns this
*/
proto.entity.entities.User.prototype.setMarketplace = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.User} returns this
 */
proto.entity.entities.User.prototype.clearMarketplace = function() {
  return this.setMarketplace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.User.prototype.hasMarketplace = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Marketplace.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Marketplace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Marketplace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Marketplace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marketplace: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Marketplace}
 */
proto.entity.entities.Marketplace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Marketplace;
  return proto.entity.entities.Marketplace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Marketplace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Marketplace}
 */
proto.entity.entities.Marketplace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Marketplace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Marketplace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Marketplace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Marketplace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Marketplace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Marketplace} returns this
 */
proto.entity.entities.Marketplace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.entities.Marketplace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Marketplace} returns this
 */
proto.entity.entities.Marketplace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.entity.entities.Marketplace.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Marketplace} returns this
 */
proto.entity.entities.Marketplace.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string icon = 4;
 * @return {string}
 */
proto.entity.entities.Marketplace.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Marketplace} returns this
 */
proto.entity.entities.Marketplace.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.Marketplace marketplace = 5;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.entity.entities.Marketplace.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.entity.entities.Marketplace} returns this
 */
proto.entity.entities.Marketplace.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.BidsForVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.BidsForVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.BidsForVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.BidsForVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bid: (f = msg.getBid()) && proto.entity.entities.Bid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.BidsForVehicle}
 */
proto.entity.entities.BidsForVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.BidsForVehicle;
  return proto.entity.entities.BidsForVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.BidsForVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.BidsForVehicle}
 */
proto.entity.entities.BidsForVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.entity.entities.Bid;
      reader.readMessage(value,proto.entity.entities.Bid.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.BidsForVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.BidsForVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.BidsForVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.BidsForVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.entities.Bid.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.entity.entities.BidsForVehicle.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.BidsForVehicle} returns this
 */
proto.entity.entities.BidsForVehicle.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Bid bid = 2;
 * @return {?proto.entity.entities.Bid}
 */
proto.entity.entities.BidsForVehicle.prototype.getBid = function() {
  return /** @type{?proto.entity.entities.Bid} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.Bid, 2));
};


/**
 * @param {?proto.entity.entities.Bid|undefined} value
 * @return {!proto.entity.entities.BidsForVehicle} returns this
*/
proto.entity.entities.BidsForVehicle.prototype.setBid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.BidsForVehicle} returns this
 */
proto.entity.entities.BidsForVehicle.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.BidsForVehicle.prototype.hasBid = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.entities.BidsForVehicles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.BidsForVehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.BidsForVehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.BidsForVehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.BidsForVehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsForVehiclesList: jspb.Message.toObjectList(msg.getBidsForVehiclesList(),
    proto.entity.entities.BidsForVehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.BidsForVehicles}
 */
proto.entity.entities.BidsForVehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.BidsForVehicles;
  return proto.entity.entities.BidsForVehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.BidsForVehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.BidsForVehicles}
 */
proto.entity.entities.BidsForVehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.entities.BidsForVehicle;
      reader.readMessage(value,proto.entity.entities.BidsForVehicle.deserializeBinaryFromReader);
      msg.addBidsForVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.BidsForVehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.BidsForVehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.BidsForVehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.BidsForVehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsForVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.entities.BidsForVehicle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BidsForVehicle bids_for_vehicles = 1;
 * @return {!Array<!proto.entity.entities.BidsForVehicle>}
 */
proto.entity.entities.BidsForVehicles.prototype.getBidsForVehiclesList = function() {
  return /** @type{!Array<!proto.entity.entities.BidsForVehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.entities.BidsForVehicle, 1));
};


/**
 * @param {!Array<!proto.entity.entities.BidsForVehicle>} value
 * @return {!proto.entity.entities.BidsForVehicles} returns this
*/
proto.entity.entities.BidsForVehicles.prototype.setBidsForVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.BidsForVehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.BidsForVehicle}
 */
proto.entity.entities.BidsForVehicles.prototype.addBidsForVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.BidsForVehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.entities.BidsForVehicles} returns this
 */
proto.entity.entities.BidsForVehicles.prototype.clearBidsForVehiclesList = function() {
  return this.setBidsForVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.DealTankOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.DealTankOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.DealTankOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealTankOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialOfferId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    offerOrigin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.DealTankOffer}
 */
proto.entity.entities.DealTankOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.DealTankOffer;
  return proto.entity.entities.DealTankOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.DealTankOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.DealTankOffer}
 */
proto.entity.entities.DealTankOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialOfferId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.enum.OfferOrigin} */ (reader.readEnum());
      msg.setOfferOrigin(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.DealTankOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.DealTankOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.DealTankOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealTankOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInitialOfferId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOfferOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getExpirationDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.DealTankOffer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.entities.DealTankOffer.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_offer_id = 3;
 * @return {string}
 */
proto.entity.entities.DealTankOffer.prototype.getInitialOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setInitialOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.entity.entities.DealTankOffer.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional entity.enum.Currency currency = 5;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.entities.DealTankOffer.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string buyer_id = 6;
 * @return {string}
 */
proto.entity.entities.DealTankOffer.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional entity.enum.OfferOrigin offer_origin = 7;
 * @return {!proto.entity.enum.OfferOrigin}
 */
proto.entity.entities.DealTankOffer.prototype.getOfferOrigin = function() {
  return /** @type {!proto.entity.enum.OfferOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.enum.OfferOrigin} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setOfferOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 8;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.entities.DealTankOffer.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.entity.entities.DealTankOffer.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.DealTankOffer} returns this
 */
proto.entity.entities.DealTankOffer.prototype.setExpirationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.VehicleReferrer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.VehicleReferrer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.VehicleReferrer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehicleReferrer.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.VehicleReferrer}
 */
proto.entity.entities.VehicleReferrer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.VehicleReferrer;
  return proto.entity.entities.VehicleReferrer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.VehicleReferrer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.VehicleReferrer}
 */
proto.entity.entities.VehicleReferrer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.ReferrerSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.VehicleReferrer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.VehicleReferrer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.VehicleReferrer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehicleReferrer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.entity.entities.VehicleReferrer.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleReferrer} returns this
 */
proto.entity.entities.VehicleReferrer.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.entity.entities.VehicleReferrer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehicleReferrer} returns this
 */
proto.entity.entities.VehicleReferrer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.ReferrerSource source = 3;
 * @return {!proto.entity.enum.ReferrerSource}
 */
proto.entity.entities.VehicleReferrer.prototype.getSource = function() {
  return /** @type {!proto.entity.enum.ReferrerSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.ReferrerSource} value
 * @return {!proto.entity.entities.VehicleReferrer} returns this
 */
proto.entity.entities.VehicleReferrer.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.DealershipReferral.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.DealershipReferral.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.DealershipReferral} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealershipReferral.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    referrerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.DealershipReferral}
 */
proto.entity.entities.DealershipReferral.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.DealershipReferral;
  return proto.entity.entities.DealershipReferral.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.DealershipReferral} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.DealershipReferral}
 */
proto.entity.entities.DealershipReferral.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.DealershipReferral.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.DealershipReferral.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.DealershipReferral} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealershipReferral.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReferrerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.entity.entities.DealershipReferral.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipReferral} returns this
 */
proto.entity.entities.DealershipReferral.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string referrer_id = 2;
 * @return {string}
 */
proto.entity.entities.DealershipReferral.prototype.getReferrerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipReferral} returns this
 */
proto.entity.entities.DealershipReferral.prototype.setReferrerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string notes = 3;
 * @return {string}
 */
proto.entity.entities.DealershipReferral.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipReferral} returns this
 */
proto.entity.entities.DealershipReferral.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.DealershipReferrer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.DealershipReferrer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.DealershipReferrer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealershipReferrer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.DealershipReferrer}
 */
proto.entity.entities.DealershipReferrer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.DealershipReferrer;
  return proto.entity.entities.DealershipReferrer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.DealershipReferrer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.DealershipReferrer}
 */
proto.entity.entities.DealershipReferrer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.DealershipReferrer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.DealershipReferrer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.DealershipReferrer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.DealershipReferrer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.DealershipReferrer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipReferrer} returns this
 */
proto.entity.entities.DealershipReferrer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.entities.DealershipReferrer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.DealershipReferrer} returns this
 */
proto.entity.entities.DealershipReferrer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.Flag.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.Flag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.Flag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Flag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userOrigin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    category: jspb.Message.getFieldWithDefault(msg, 6, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.Flag}
 */
proto.entity.entities.Flag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.Flag;
  return proto.entity.entities.Flag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.Flag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.Flag}
 */
proto.entity.entities.Flag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setUserOrigin(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.FlagCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.Flag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.Flag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.Flag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.Flag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.entities.Flag.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.entities.Flag.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.entity.entities.Flag.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.enum.UserOrigin user_origin = 5;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.entity.entities.Flag.prototype.getUserOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setUserOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional entity.enum.FlagCategory category = 6;
 * @return {!proto.entity.enum.FlagCategory}
 */
proto.entity.entities.Flag.prototype.getCategory = function() {
  return /** @type {!proto.entity.enum.FlagCategory} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.FlagCategory} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.entity.entities.Flag.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 8;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.entities.Flag.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.entities.Flag} returns this
 */
proto.entity.entities.Flag.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.CBBPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.CBBPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.CBBPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CBBPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    cbbCentsPerKm: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    cbbBasePriceClean: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cbbBasePriceAvg: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cbbBasePriceRough: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cbbXCleanKmThreshold: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cbbCleanKmThreshold: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cbbAvgKmThreshold: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cbbRoughKmThreshold: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.CBBPricing}
 */
proto.entity.entities.CBBPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.CBBPricing;
  return proto.entity.entities.CBBPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.CBBPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.CBBPricing}
 */
proto.entity.entities.CBBPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCbbCentsPerKm(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbBasePriceClean(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbBasePriceAvg(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbBasePriceRough(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbXCleanKmThreshold(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbCleanKmThreshold(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbAvgKmThreshold(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCbbRoughKmThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.CBBPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.CBBPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.CBBPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.CBBPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCbbCentsPerKm();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getCbbBasePriceClean();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCbbBasePriceAvg();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCbbBasePriceRough();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCbbXCleanKmThreshold();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCbbCleanKmThreshold();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCbbAvgKmThreshold();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCbbRoughKmThreshold();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional float cbb_cents_per_km = 1;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbCentsPerKm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbCentsPerKm = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 cbb_base_price_clean = 2;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbBasePriceClean = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbBasePriceClean = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 cbb_base_price_avg = 3;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbBasePriceAvg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbBasePriceAvg = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 cbb_base_price_rough = 4;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbBasePriceRough = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbBasePriceRough = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 cbb_x_clean_km_threshold = 5;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbXCleanKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbXCleanKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 cbb_clean_km_threshold = 6;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbCleanKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbCleanKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 cbb_avg_km_threshold = 7;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbAvgKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbAvgKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 cbb_rough_km_threshold = 8;
 * @return {number}
 */
proto.entity.entities.CBBPricing.prototype.getCbbRoughKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.CBBPricing} returns this
 */
proto.entity.entities.CBBPricing.prototype.setCbbRoughKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.entities.VehiclePricing.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.entities.VehiclePricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.entities.VehiclePricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehiclePricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    msrp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cbbPricing: (f = msg.getCbbPricing()) && proto.entity.entities.CBBPricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.entities.VehiclePricing}
 */
proto.entity.entities.VehiclePricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.entities.VehiclePricing;
  return proto.entity.entities.VehiclePricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.entities.VehiclePricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.entities.VehiclePricing}
 */
proto.entity.entities.VehiclePricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMsrp(value);
      break;
    case 3:
      var value = new proto.entity.entities.CBBPricing;
      reader.readMessage(value,proto.entity.entities.CBBPricing.deserializeBinaryFromReader);
      msg.setCbbPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.entities.VehiclePricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.entities.VehiclePricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.entities.VehiclePricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.entities.VehiclePricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMsrp();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCbbPricing();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.entities.CBBPricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.entity.entities.VehiclePricing.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.entities.VehiclePricing} returns this
 */
proto.entity.entities.VehiclePricing.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 msrp = 2;
 * @return {number}
 */
proto.entity.entities.VehiclePricing.prototype.getMsrp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.entities.VehiclePricing} returns this
 */
proto.entity.entities.VehiclePricing.prototype.setMsrp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CBBPricing cbb_pricing = 3;
 * @return {?proto.entity.entities.CBBPricing}
 */
proto.entity.entities.VehiclePricing.prototype.getCbbPricing = function() {
  return /** @type{?proto.entity.entities.CBBPricing} */ (
    jspb.Message.getWrapperField(this, proto.entity.entities.CBBPricing, 3));
};


/**
 * @param {?proto.entity.entities.CBBPricing|undefined} value
 * @return {!proto.entity.entities.VehiclePricing} returns this
*/
proto.entity.entities.VehiclePricing.prototype.setCbbPricing = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.entities.VehiclePricing} returns this
 */
proto.entity.entities.VehiclePricing.prototype.clearCbbPricing = function() {
  return this.setCbbPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.entities.VehiclePricing.prototype.hasCbbPricing = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.entity.entities);
