import { useTradeInInfos } from '../../composables/api/useTradeInInfos';
import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import TradeInInfoModal from './TradeInInfoModal';

interface TradeInInfoProps {
  vehicleId: string;
}

function TradeInInfos({ vehicleId }: TradeInInfoProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const { tradeInInfos } = useTradeInInfos(vehicleId);

  const ShowRegisteredOwners = () => {
    openUtilityDrawer(<TradeInInfoModal vehicleId={vehicleId} tradeInInfos={tradeInInfos} />);
  };

  return <BaseButtonOutline small display="Trade-In Info" onClick={ShowRegisteredOwners} />;
}

export default TradeInInfos;
