/**
 * @fileoverview gRPC-Web generated client stub for com.mintlist.adminapiv1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as build_protos_adminapiv1_pb from '../../build/protos/adminapiv1_pb';


export class Apiv1Client {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetProfile = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetProfile',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetProfileRequest,
    build_protos_adminapiv1_pb.GetProfileResponse,
    (request: build_protos_adminapiv1_pb.GetProfileRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetProfileResponse.deserializeBinary
  );

  getProfile(
    request: build_protos_adminapiv1_pb.GetProfileRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetProfileResponse>;

  getProfile(
    request: build_protos_adminapiv1_pb.GetProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetProfileResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetProfileResponse>;

  getProfile(
    request: build_protos_adminapiv1_pb.GetProfileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetProfileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetProfile',
        request,
        metadata || {},
        this.methodDescriptorGetProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetProfile',
    request,
    metadata || {},
    this.methodDescriptorGetProfile);
  }

  methodDescriptorListAuctionVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListAuctionVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListAuctionVehiclesRequest,
    build_protos_adminapiv1_pb.ListAuctionVehiclesResponse,
    (request: build_protos_adminapiv1_pb.ListAuctionVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListAuctionVehiclesResponse.deserializeBinary
  );

  listAuctionVehicles(
    request: build_protos_adminapiv1_pb.ListAuctionVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListAuctionVehiclesResponse>;

  listAuctionVehicles(
    request: build_protos_adminapiv1_pb.ListAuctionVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAuctionVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListAuctionVehiclesResponse>;

  listAuctionVehicles(
    request: build_protos_adminapiv1_pb.ListAuctionVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAuctionVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListAuctionVehicles',
        request,
        metadata || {},
        this.methodDescriptorListAuctionVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListAuctionVehicles',
    request,
    metadata || {},
    this.methodDescriptorListAuctionVehicles);
  }

  methodDescriptorListVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListVehiclesRequest,
    build_protos_adminapiv1_pb.ListVehiclesResponse,
    (request: build_protos_adminapiv1_pb.ListVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListVehiclesResponse.deserializeBinary
  );

  listVehicles(
    request: build_protos_adminapiv1_pb.ListVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListVehiclesResponse>;

  listVehicles(
    request: build_protos_adminapiv1_pb.ListVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListVehiclesResponse>;

  listVehicles(
    request: build_protos_adminapiv1_pb.ListVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListVehicles',
        request,
        metadata || {},
        this.methodDescriptorListVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListVehicles',
    request,
    metadata || {},
    this.methodDescriptorListVehicles);
  }

  methodDescriptorListVehicleFacets = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListVehicleFacets',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListVehicleFacetsRequest,
    build_protos_adminapiv1_pb.ListVehicleFacetsResponse,
    (request: build_protos_adminapiv1_pb.ListVehicleFacetsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListVehicleFacetsResponse.deserializeBinary
  );

  listVehicleFacets(
    request: build_protos_adminapiv1_pb.ListVehicleFacetsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListVehicleFacetsResponse>;

  listVehicleFacets(
    request: build_protos_adminapiv1_pb.ListVehicleFacetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListVehicleFacetsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListVehicleFacetsResponse>;

  listVehicleFacets(
    request: build_protos_adminapiv1_pb.ListVehicleFacetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListVehicleFacetsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListVehicleFacets',
        request,
        metadata || {},
        this.methodDescriptorListVehicleFacets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListVehicleFacets',
    request,
    metadata || {},
    this.methodDescriptorListVehicleFacets);
  }

  methodDescriptorAddVehiclesToAuction = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/AddVehiclesToAuction',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.AddVehiclesToAuctionRequest,
    build_protos_adminapiv1_pb.AddVehiclesToAuctionResponse,
    (request: build_protos_adminapiv1_pb.AddVehiclesToAuctionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.AddVehiclesToAuctionResponse.deserializeBinary
  );

  addVehiclesToAuction(
    request: build_protos_adminapiv1_pb.AddVehiclesToAuctionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.AddVehiclesToAuctionResponse>;

  addVehiclesToAuction(
    request: build_protos_adminapiv1_pb.AddVehiclesToAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.AddVehiclesToAuctionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.AddVehiclesToAuctionResponse>;

  addVehiclesToAuction(
    request: build_protos_adminapiv1_pb.AddVehiclesToAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.AddVehiclesToAuctionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/AddVehiclesToAuction',
        request,
        metadata || {},
        this.methodDescriptorAddVehiclesToAuction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/AddVehiclesToAuction',
    request,
    metadata || {},
    this.methodDescriptorAddVehiclesToAuction);
  }

  methodDescriptorUpdateVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateVehiclesRequest,
    build_protos_adminapiv1_pb.UpdateVehiclesResponse,
    (request: build_protos_adminapiv1_pb.UpdateVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateVehiclesResponse.deserializeBinary
  );

  updateVehicles(
    request: build_protos_adminapiv1_pb.UpdateVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateVehiclesResponse>;

  updateVehicles(
    request: build_protos_adminapiv1_pb.UpdateVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateVehiclesResponse>;

  updateVehicles(
    request: build_protos_adminapiv1_pb.UpdateVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateVehicles',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateVehicles',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicles);
  }

  methodDescriptorUpdateVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateVehicleRequest,
    build_protos_adminapiv1_pb.UpdateVehicleResponse,
    (request: build_protos_adminapiv1_pb.UpdateVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateVehicleResponse.deserializeBinary
  );

  updateVehicle(
    request: build_protos_adminapiv1_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateVehicleResponse>;

  updateVehicle(
    request: build_protos_adminapiv1_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateVehicleResponse>;

  updateVehicle(
    request: build_protos_adminapiv1_pb.UpdateVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateVehicle',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateVehicle',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicle);
  }

  methodDescriptorIndexVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/IndexVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.IndexVehiclesRequest,
    build_protos_adminapiv1_pb.IndexVehiclesResponse,
    (request: build_protos_adminapiv1_pb.IndexVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.IndexVehiclesResponse.deserializeBinary
  );

  indexVehicles(
    request: build_protos_adminapiv1_pb.IndexVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.IndexVehiclesResponse>;

  indexVehicles(
    request: build_protos_adminapiv1_pb.IndexVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.IndexVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.IndexVehiclesResponse>;

  indexVehicles(
    request: build_protos_adminapiv1_pb.IndexVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.IndexVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/IndexVehicles',
        request,
        metadata || {},
        this.methodDescriptorIndexVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/IndexVehicles',
    request,
    metadata || {},
    this.methodDescriptorIndexVehicles);
  }

  methodDescriptorGetVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetVehicleRequest,
    build_protos_adminapiv1_pb.GetVehicleResponse,
    (request: build_protos_adminapiv1_pb.GetVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetVehicleResponse.deserializeBinary
  );

  getVehicle(
    request: build_protos_adminapiv1_pb.GetVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetVehicleResponse>;

  getVehicle(
    request: build_protos_adminapiv1_pb.GetVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetVehicleResponse>;

  getVehicle(
    request: build_protos_adminapiv1_pb.GetVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetVehicle',
        request,
        metadata || {},
        this.methodDescriptorGetVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetVehicle',
    request,
    metadata || {},
    this.methodDescriptorGetVehicle);
  }

  methodDescriptorCreateAuctionSchedule = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateAuctionSchedule',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateAuctionScheduleRequest,
    build_protos_adminapiv1_pb.CreateAuctionScheduleResponse,
    (request: build_protos_adminapiv1_pb.CreateAuctionScheduleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateAuctionScheduleResponse.deserializeBinary
  );

  createAuctionSchedule(
    request: build_protos_adminapiv1_pb.CreateAuctionScheduleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateAuctionScheduleResponse>;

  createAuctionSchedule(
    request: build_protos_adminapiv1_pb.CreateAuctionScheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateAuctionScheduleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateAuctionScheduleResponse>;

  createAuctionSchedule(
    request: build_protos_adminapiv1_pb.CreateAuctionScheduleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateAuctionScheduleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateAuctionSchedule',
        request,
        metadata || {},
        this.methodDescriptorCreateAuctionSchedule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateAuctionSchedule',
    request,
    metadata || {},
    this.methodDescriptorCreateAuctionSchedule);
  }

  methodDescriptorCreateScheduledJob = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateScheduledJob',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateScheduledJobRequest,
    build_protos_adminapiv1_pb.CreateScheduledJobResponse,
    (request: build_protos_adminapiv1_pb.CreateScheduledJobRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateScheduledJobResponse.deserializeBinary
  );

  createScheduledJob(
    request: build_protos_adminapiv1_pb.CreateScheduledJobRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateScheduledJobResponse>;

  createScheduledJob(
    request: build_protos_adminapiv1_pb.CreateScheduledJobRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateScheduledJobResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateScheduledJobResponse>;

  createScheduledJob(
    request: build_protos_adminapiv1_pb.CreateScheduledJobRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateScheduledJobResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateScheduledJob',
        request,
        metadata || {},
        this.methodDescriptorCreateScheduledJob,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateScheduledJob',
    request,
    metadata || {},
    this.methodDescriptorCreateScheduledJob);
  }

  methodDescriptorUpdateVehicleReferrer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateVehicleReferrer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateVehicleReferrerRequest,
    build_protos_adminapiv1_pb.UpdateVehicleReferrerResponse,
    (request: build_protos_adminapiv1_pb.UpdateVehicleReferrerRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateVehicleReferrerResponse.deserializeBinary
  );

  updateVehicleReferrer(
    request: build_protos_adminapiv1_pb.UpdateVehicleReferrerRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateVehicleReferrerResponse>;

  updateVehicleReferrer(
    request: build_protos_adminapiv1_pb.UpdateVehicleReferrerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateVehicleReferrerResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateVehicleReferrerResponse>;

  updateVehicleReferrer(
    request: build_protos_adminapiv1_pb.UpdateVehicleReferrerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateVehicleReferrerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateVehicleReferrer',
        request,
        metadata || {},
        this.methodDescriptorUpdateVehicleReferrer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateVehicleReferrer',
    request,
    metadata || {},
    this.methodDescriptorUpdateVehicleReferrer);
  }

  methodDescriptorSearchVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/SearchVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.SearchVehiclesRequest,
    build_protos_adminapiv1_pb.SearchVehiclesResponse,
    (request: build_protos_adminapiv1_pb.SearchVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.SearchVehiclesResponse.deserializeBinary
  );

  searchVehicles(
    request: build_protos_adminapiv1_pb.SearchVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.SearchVehiclesResponse>;

  searchVehicles(
    request: build_protos_adminapiv1_pb.SearchVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.SearchVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.SearchVehiclesResponse>;

  searchVehicles(
    request: build_protos_adminapiv1_pb.SearchVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.SearchVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/SearchVehicles',
        request,
        metadata || {},
        this.methodDescriptorSearchVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/SearchVehicles',
    request,
    metadata || {},
    this.methodDescriptorSearchVehicles);
  }

  methodDescriptorCreateAuction = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateAuction',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateAuctionRequest,
    build_protos_adminapiv1_pb.CreateAuctionResponse,
    (request: build_protos_adminapiv1_pb.CreateAuctionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateAuctionResponse.deserializeBinary
  );

  createAuction(
    request: build_protos_adminapiv1_pb.CreateAuctionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateAuctionResponse>;

  createAuction(
    request: build_protos_adminapiv1_pb.CreateAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateAuctionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateAuctionResponse>;

  createAuction(
    request: build_protos_adminapiv1_pb.CreateAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateAuctionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateAuction',
        request,
        metadata || {},
        this.methodDescriptorCreateAuction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateAuction',
    request,
    metadata || {},
    this.methodDescriptorCreateAuction);
  }

  methodDescriptorDeleteAuction = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/DeleteAuction',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.DeleteAuctionRequest,
    build_protos_adminapiv1_pb.DeleteAuctionResponse,
    (request: build_protos_adminapiv1_pb.DeleteAuctionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.DeleteAuctionResponse.deserializeBinary
  );

  deleteAuction(
    request: build_protos_adminapiv1_pb.DeleteAuctionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.DeleteAuctionResponse>;

  deleteAuction(
    request: build_protos_adminapiv1_pb.DeleteAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteAuctionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.DeleteAuctionResponse>;

  deleteAuction(
    request: build_protos_adminapiv1_pb.DeleteAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteAuctionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/DeleteAuction',
        request,
        metadata || {},
        this.methodDescriptorDeleteAuction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/DeleteAuction',
    request,
    metadata || {},
    this.methodDescriptorDeleteAuction);
  }

  methodDescriptorListAuctions = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListAuctions',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListAuctionsRequest,
    build_protos_adminapiv1_pb.ListAuctionsResponse,
    (request: build_protos_adminapiv1_pb.ListAuctionsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListAuctionsResponse.deserializeBinary
  );

  listAuctions(
    request: build_protos_adminapiv1_pb.ListAuctionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListAuctionsResponse>;

  listAuctions(
    request: build_protos_adminapiv1_pb.ListAuctionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAuctionsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListAuctionsResponse>;

  listAuctions(
    request: build_protos_adminapiv1_pb.ListAuctionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAuctionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListAuctions',
        request,
        metadata || {},
        this.methodDescriptorListAuctions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListAuctions',
    request,
    metadata || {},
    this.methodDescriptorListAuctions);
  }

  methodDescriptorDeleteVehicleFromAuction = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/DeleteVehicleFromAuction',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.DeleteVehicleFromAuctionRequest,
    build_protos_adminapiv1_pb.DeleteVehicleFromAuctionResponse,
    (request: build_protos_adminapiv1_pb.DeleteVehicleFromAuctionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.DeleteVehicleFromAuctionResponse.deserializeBinary
  );

  deleteVehicleFromAuction(
    request: build_protos_adminapiv1_pb.DeleteVehicleFromAuctionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.DeleteVehicleFromAuctionResponse>;

  deleteVehicleFromAuction(
    request: build_protos_adminapiv1_pb.DeleteVehicleFromAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteVehicleFromAuctionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.DeleteVehicleFromAuctionResponse>;

  deleteVehicleFromAuction(
    request: build_protos_adminapiv1_pb.DeleteVehicleFromAuctionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteVehicleFromAuctionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/DeleteVehicleFromAuction',
        request,
        metadata || {},
        this.methodDescriptorDeleteVehicleFromAuction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/DeleteVehicleFromAuction',
    request,
    metadata || {},
    this.methodDescriptorDeleteVehicleFromAuction);
  }

  methodDescriptorListVehicleTopBids = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListVehicleTopBids',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListVehicleTopBidsRequest,
    build_protos_adminapiv1_pb.ListVehicleTopBidsResponse,
    (request: build_protos_adminapiv1_pb.ListVehicleTopBidsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListVehicleTopBidsResponse.deserializeBinary
  );

  listVehicleTopBids(
    request: build_protos_adminapiv1_pb.ListVehicleTopBidsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListVehicleTopBidsResponse>;

  listVehicleTopBids(
    request: build_protos_adminapiv1_pb.ListVehicleTopBidsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListVehicleTopBidsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListVehicleTopBidsResponse>;

  listVehicleTopBids(
    request: build_protos_adminapiv1_pb.ListVehicleTopBidsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListVehicleTopBidsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListVehicleTopBids',
        request,
        metadata || {},
        this.methodDescriptorListVehicleTopBids,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListVehicleTopBids',
    request,
    metadata || {},
    this.methodDescriptorListVehicleTopBids);
  }

  methodDescriptorUpdateFloorPrice = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateFloorPrice',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateFloorPriceRequest,
    build_protos_adminapiv1_pb.UpdateFloorPriceResponse,
    (request: build_protos_adminapiv1_pb.UpdateFloorPriceRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateFloorPriceResponse.deserializeBinary
  );

  updateFloorPrice(
    request: build_protos_adminapiv1_pb.UpdateFloorPriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateFloorPriceResponse>;

  updateFloorPrice(
    request: build_protos_adminapiv1_pb.UpdateFloorPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateFloorPriceResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateFloorPriceResponse>;

  updateFloorPrice(
    request: build_protos_adminapiv1_pb.UpdateFloorPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateFloorPriceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateFloorPrice',
        request,
        metadata || {},
        this.methodDescriptorUpdateFloorPrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateFloorPrice',
    request,
    metadata || {},
    this.methodDescriptorUpdateFloorPrice);
  }

  methodDescriptorCreateCarfaxReport = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateCarfaxReport',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateCarfaxReportRequest,
    build_protos_adminapiv1_pb.CreateCarfaxReportResponse,
    (request: build_protos_adminapiv1_pb.CreateCarfaxReportRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateCarfaxReportResponse.deserializeBinary
  );

  createCarfaxReport(
    request: build_protos_adminapiv1_pb.CreateCarfaxReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateCarfaxReportResponse>;

  createCarfaxReport(
    request: build_protos_adminapiv1_pb.CreateCarfaxReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateCarfaxReportResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateCarfaxReportResponse>;

  createCarfaxReport(
    request: build_protos_adminapiv1_pb.CreateCarfaxReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateCarfaxReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateCarfaxReport',
        request,
        metadata || {},
        this.methodDescriptorCreateCarfaxReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateCarfaxReport',
    request,
    metadata || {},
    this.methodDescriptorCreateCarfaxReport);
  }

  methodDescriptorListOffers = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListOffers',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListOffersRequest,
    build_protos_adminapiv1_pb.ListOffersResponse,
    (request: build_protos_adminapiv1_pb.ListOffersRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListOffersResponse.deserializeBinary
  );

  listOffers(
    request: build_protos_adminapiv1_pb.ListOffersRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListOffersResponse>;

  listOffers(
    request: build_protos_adminapiv1_pb.ListOffersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListOffersResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListOffersResponse>;

  listOffers(
    request: build_protos_adminapiv1_pb.ListOffersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListOffersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListOffers',
        request,
        metadata || {},
        this.methodDescriptorListOffers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListOffers',
    request,
    metadata || {},
    this.methodDescriptorListOffers);
  }

  methodDescriptorRejectAuctionVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/RejectAuctionVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.RejectAuctionVehicleRequest,
    build_protos_adminapiv1_pb.RejectAuctionVehicleResponse,
    (request: build_protos_adminapiv1_pb.RejectAuctionVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.RejectAuctionVehicleResponse.deserializeBinary
  );

  rejectAuctionVehicle(
    request: build_protos_adminapiv1_pb.RejectAuctionVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.RejectAuctionVehicleResponse>;

  rejectAuctionVehicle(
    request: build_protos_adminapiv1_pb.RejectAuctionVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RejectAuctionVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.RejectAuctionVehicleResponse>;

  rejectAuctionVehicle(
    request: build_protos_adminapiv1_pb.RejectAuctionVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RejectAuctionVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/RejectAuctionVehicle',
        request,
        metadata || {},
        this.methodDescriptorRejectAuctionVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/RejectAuctionVehicle',
    request,
    metadata || {},
    this.methodDescriptorRejectAuctionVehicle);
  }

  methodDescriptorGenerateDataOne = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GenerateDataOne',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GenerateDataOneRequest,
    build_protos_adminapiv1_pb.GenerateDataOneResponse,
    (request: build_protos_adminapiv1_pb.GenerateDataOneRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GenerateDataOneResponse.deserializeBinary
  );

  generateDataOne(
    request: build_protos_adminapiv1_pb.GenerateDataOneRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GenerateDataOneResponse>;

  generateDataOne(
    request: build_protos_adminapiv1_pb.GenerateDataOneRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GenerateDataOneResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GenerateDataOneResponse>;

  generateDataOne(
    request: build_protos_adminapiv1_pb.GenerateDataOneRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GenerateDataOneResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GenerateDataOne',
        request,
        metadata || {},
        this.methodDescriptorGenerateDataOne,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GenerateDataOne',
    request,
    metadata || {},
    this.methodDescriptorGenerateDataOne);
  }

  methodDescriptorGetRegistrationAddress = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetRegistrationAddress',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetRegistrationAddressRequest,
    build_protos_adminapiv1_pb.GetRegistrationAddressResponse,
    (request: build_protos_adminapiv1_pb.GetRegistrationAddressRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetRegistrationAddressResponse.deserializeBinary
  );

  getRegistrationAddress(
    request: build_protos_adminapiv1_pb.GetRegistrationAddressRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetRegistrationAddressResponse>;

  getRegistrationAddress(
    request: build_protos_adminapiv1_pb.GetRegistrationAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetRegistrationAddressResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetRegistrationAddressResponse>;

  getRegistrationAddress(
    request: build_protos_adminapiv1_pb.GetRegistrationAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetRegistrationAddressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetRegistrationAddress',
        request,
        metadata || {},
        this.methodDescriptorGetRegistrationAddress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetRegistrationAddress',
    request,
    metadata || {},
    this.methodDescriptorGetRegistrationAddress);
  }

  methodDescriptorDeleteRegistrationAddress = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/DeleteRegistrationAddress',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.DeleteRegistrationAddressRequest,
    build_protos_adminapiv1_pb.DeleteRegistrationAddressResponse,
    (request: build_protos_adminapiv1_pb.DeleteRegistrationAddressRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.DeleteRegistrationAddressResponse.deserializeBinary
  );

  deleteRegistrationAddress(
    request: build_protos_adminapiv1_pb.DeleteRegistrationAddressRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.DeleteRegistrationAddressResponse>;

  deleteRegistrationAddress(
    request: build_protos_adminapiv1_pb.DeleteRegistrationAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteRegistrationAddressResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.DeleteRegistrationAddressResponse>;

  deleteRegistrationAddress(
    request: build_protos_adminapiv1_pb.DeleteRegistrationAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteRegistrationAddressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/DeleteRegistrationAddress',
        request,
        metadata || {},
        this.methodDescriptorDeleteRegistrationAddress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/DeleteRegistrationAddress',
    request,
    metadata || {},
    this.methodDescriptorDeleteRegistrationAddress);
  }

  methodDescriptorCreateRegisteredOwner = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateRegisteredOwner',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateRegisteredOwnerRequest,
    build_protos_adminapiv1_pb.CreateRegisteredOwnerResponse,
    (request: build_protos_adminapiv1_pb.CreateRegisteredOwnerRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateRegisteredOwnerResponse.deserializeBinary
  );

  createRegisteredOwner(
    request: build_protos_adminapiv1_pb.CreateRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateRegisteredOwnerResponse>;

  createRegisteredOwner(
    request: build_protos_adminapiv1_pb.CreateRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateRegisteredOwnerResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateRegisteredOwnerResponse>;

  createRegisteredOwner(
    request: build_protos_adminapiv1_pb.CreateRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateRegisteredOwnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateRegisteredOwner',
        request,
        metadata || {},
        this.methodDescriptorCreateRegisteredOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateRegisteredOwner',
    request,
    metadata || {},
    this.methodDescriptorCreateRegisteredOwner);
  }

  methodDescriptorUpdateRegisteredOwner = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateRegisteredOwner',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateRegisteredOwnerRequest,
    build_protos_adminapiv1_pb.UpdateRegisteredOwnerResponse,
    (request: build_protos_adminapiv1_pb.UpdateRegisteredOwnerRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateRegisteredOwnerResponse.deserializeBinary
  );

  updateRegisteredOwner(
    request: build_protos_adminapiv1_pb.UpdateRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateRegisteredOwnerResponse>;

  updateRegisteredOwner(
    request: build_protos_adminapiv1_pb.UpdateRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateRegisteredOwnerResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateRegisteredOwnerResponse>;

  updateRegisteredOwner(
    request: build_protos_adminapiv1_pb.UpdateRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateRegisteredOwnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateRegisteredOwner',
        request,
        metadata || {},
        this.methodDescriptorUpdateRegisteredOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateRegisteredOwner',
    request,
    metadata || {},
    this.methodDescriptorUpdateRegisteredOwner);
  }

  methodDescriptorDeleteRegisteredOwner = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/DeleteRegisteredOwner',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.DeleteRegisteredOwnerRequest,
    build_protos_adminapiv1_pb.DeleteRegisteredOwnerResponse,
    (request: build_protos_adminapiv1_pb.DeleteRegisteredOwnerRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.DeleteRegisteredOwnerResponse.deserializeBinary
  );

  deleteRegisteredOwner(
    request: build_protos_adminapiv1_pb.DeleteRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.DeleteRegisteredOwnerResponse>;

  deleteRegisteredOwner(
    request: build_protos_adminapiv1_pb.DeleteRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteRegisteredOwnerResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.DeleteRegisteredOwnerResponse>;

  deleteRegisteredOwner(
    request: build_protos_adminapiv1_pb.DeleteRegisteredOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteRegisteredOwnerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/DeleteRegisteredOwner',
        request,
        metadata || {},
        this.methodDescriptorDeleteRegisteredOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/DeleteRegisteredOwner',
    request,
    metadata || {},
    this.methodDescriptorDeleteRegisteredOwner);
  }

  methodDescriptorListRegisteredOwners = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListRegisteredOwners',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListRegisteredOwnersRequest,
    build_protos_adminapiv1_pb.ListRegisteredOwnersResponse,
    (request: build_protos_adminapiv1_pb.ListRegisteredOwnersRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListRegisteredOwnersResponse.deserializeBinary
  );

  listRegisteredOwners(
    request: build_protos_adminapiv1_pb.ListRegisteredOwnersRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListRegisteredOwnersResponse>;

  listRegisteredOwners(
    request: build_protos_adminapiv1_pb.ListRegisteredOwnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListRegisteredOwnersResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListRegisteredOwnersResponse>;

  listRegisteredOwners(
    request: build_protos_adminapiv1_pb.ListRegisteredOwnersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListRegisteredOwnersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListRegisteredOwners',
        request,
        metadata || {},
        this.methodDescriptorListRegisteredOwners,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListRegisteredOwners',
    request,
    metadata || {},
    this.methodDescriptorListRegisteredOwners);
  }

  methodDescriptorGetOfferDocument = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetOfferDocument',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetOfferDocumentRequest,
    build_protos_adminapiv1_pb.GetOfferDocumentResponse,
    (request: build_protos_adminapiv1_pb.GetOfferDocumentRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetOfferDocumentResponse.deserializeBinary
  );

  getOfferDocument(
    request: build_protos_adminapiv1_pb.GetOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetOfferDocumentResponse>;

  getOfferDocument(
    request: build_protos_adminapiv1_pb.GetOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetOfferDocumentResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetOfferDocumentResponse>;

  getOfferDocument(
    request: build_protos_adminapiv1_pb.GetOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetOfferDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetOfferDocument',
        request,
        metadata || {},
        this.methodDescriptorGetOfferDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetOfferDocument',
    request,
    metadata || {},
    this.methodDescriptorGetOfferDocument);
  }

  methodDescriptorSendOfferDocument = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/SendOfferDocument',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.SendOfferDocumentRequest,
    build_protos_adminapiv1_pb.SendOfferDocumentResponse,
    (request: build_protos_adminapiv1_pb.SendOfferDocumentRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.SendOfferDocumentResponse.deserializeBinary
  );

  sendOfferDocument(
    request: build_protos_adminapiv1_pb.SendOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.SendOfferDocumentResponse>;

  sendOfferDocument(
    request: build_protos_adminapiv1_pb.SendOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.SendOfferDocumentResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.SendOfferDocumentResponse>;

  sendOfferDocument(
    request: build_protos_adminapiv1_pb.SendOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.SendOfferDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/SendOfferDocument',
        request,
        metadata || {},
        this.methodDescriptorSendOfferDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/SendOfferDocument',
    request,
    metadata || {},
    this.methodDescriptorSendOfferDocument);
  }

  methodDescriptorUpdateOfferDocument = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateOfferDocument',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateOfferDocumentRequest,
    build_protos_adminapiv1_pb.UpdateOfferDocumentResponse,
    (request: build_protos_adminapiv1_pb.UpdateOfferDocumentRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateOfferDocumentResponse.deserializeBinary
  );

  updateOfferDocument(
    request: build_protos_adminapiv1_pb.UpdateOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateOfferDocumentResponse>;

  updateOfferDocument(
    request: build_protos_adminapiv1_pb.UpdateOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateOfferDocumentResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateOfferDocumentResponse>;

  updateOfferDocument(
    request: build_protos_adminapiv1_pb.UpdateOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateOfferDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateOfferDocument',
        request,
        metadata || {},
        this.methodDescriptorUpdateOfferDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateOfferDocument',
    request,
    metadata || {},
    this.methodDescriptorUpdateOfferDocument);
  }

  methodDescriptorCancelOfferDocument = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CancelOfferDocument',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CancelOfferDocumentRequest,
    build_protos_adminapiv1_pb.CancelOfferDocumentResponse,
    (request: build_protos_adminapiv1_pb.CancelOfferDocumentRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CancelOfferDocumentResponse.deserializeBinary
  );

  cancelOfferDocument(
    request: build_protos_adminapiv1_pb.CancelOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CancelOfferDocumentResponse>;

  cancelOfferDocument(
    request: build_protos_adminapiv1_pb.CancelOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CancelOfferDocumentResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CancelOfferDocumentResponse>;

  cancelOfferDocument(
    request: build_protos_adminapiv1_pb.CancelOfferDocumentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CancelOfferDocumentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CancelOfferDocument',
        request,
        metadata || {},
        this.methodDescriptorCancelOfferDocument,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CancelOfferDocument',
    request,
    metadata || {},
    this.methodDescriptorCancelOfferDocument);
  }

  methodDescriptorListOfferDocuments = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListOfferDocuments',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListOfferDocumentsRequest,
    build_protos_adminapiv1_pb.ListOfferDocumentsResponse,
    (request: build_protos_adminapiv1_pb.ListOfferDocumentsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListOfferDocumentsResponse.deserializeBinary
  );

  listOfferDocuments(
    request: build_protos_adminapiv1_pb.ListOfferDocumentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListOfferDocumentsResponse>;

  listOfferDocuments(
    request: build_protos_adminapiv1_pb.ListOfferDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListOfferDocumentsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListOfferDocumentsResponse>;

  listOfferDocuments(
    request: build_protos_adminapiv1_pb.ListOfferDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListOfferDocumentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListOfferDocuments',
        request,
        metadata || {},
        this.methodDescriptorListOfferDocuments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListOfferDocuments',
    request,
    metadata || {},
    this.methodDescriptorListOfferDocuments);
  }

  methodDescriptorGetAutoGroupByDealerUserId = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetAutoGroupByDealerUserId',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdRequest,
    build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdResponse,
    (request: build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdResponse.deserializeBinary
  );

  getAutoGroupByDealerUserId(
    request: build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdResponse>;

  getAutoGroupByDealerUserId(
    request: build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdResponse>;

  getAutoGroupByDealerUserId(
    request: build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetAutoGroupByDealerUserIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetAutoGroupByDealerUserId',
        request,
        metadata || {},
        this.methodDescriptorGetAutoGroupByDealerUserId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetAutoGroupByDealerUserId',
    request,
    metadata || {},
    this.methodDescriptorGetAutoGroupByDealerUserId);
  }

  methodDescriptorCreateTransaction = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateTransaction',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateTransactionRequest,
    build_protos_adminapiv1_pb.CreateTransactionResponse,
    (request: build_protos_adminapiv1_pb.CreateTransactionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateTransactionResponse.deserializeBinary
  );

  createTransaction(
    request: build_protos_adminapiv1_pb.CreateTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateTransactionResponse>;

  createTransaction(
    request: build_protos_adminapiv1_pb.CreateTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateTransactionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateTransactionResponse>;

  createTransaction(
    request: build_protos_adminapiv1_pb.CreateTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateTransaction',
        request,
        metadata || {},
        this.methodDescriptorCreateTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateTransaction',
    request,
    metadata || {},
    this.methodDescriptorCreateTransaction);
  }

  methodDescriptorCreateOfferTransaction = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateOfferTransaction',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateOfferTransactionRequest,
    build_protos_adminapiv1_pb.CreateOfferTransactionResponse,
    (request: build_protos_adminapiv1_pb.CreateOfferTransactionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateOfferTransactionResponse.deserializeBinary
  );

  createOfferTransaction(
    request: build_protos_adminapiv1_pb.CreateOfferTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateOfferTransactionResponse>;

  createOfferTransaction(
    request: build_protos_adminapiv1_pb.CreateOfferTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateOfferTransactionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateOfferTransactionResponse>;

  createOfferTransaction(
    request: build_protos_adminapiv1_pb.CreateOfferTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateOfferTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateOfferTransaction',
        request,
        metadata || {},
        this.methodDescriptorCreateOfferTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateOfferTransaction',
    request,
    metadata || {},
    this.methodDescriptorCreateOfferTransaction);
  }

  methodDescriptorCreateBreakFee = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateBreakFee',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateBreakFeeRequest,
    build_protos_adminapiv1_pb.CreateBreakFeeResponse,
    (request: build_protos_adminapiv1_pb.CreateBreakFeeRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateBreakFeeResponse.deserializeBinary
  );

  createBreakFee(
    request: build_protos_adminapiv1_pb.CreateBreakFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateBreakFeeResponse>;

  createBreakFee(
    request: build_protos_adminapiv1_pb.CreateBreakFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateBreakFeeResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateBreakFeeResponse>;

  createBreakFee(
    request: build_protos_adminapiv1_pb.CreateBreakFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateBreakFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateBreakFee',
        request,
        metadata || {},
        this.methodDescriptorCreateBreakFee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateBreakFee',
    request,
    metadata || {},
    this.methodDescriptorCreateBreakFee);
  }

  methodDescriptorGetAcceptedOffer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetAcceptedOffer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetAcceptedOfferRequest,
    build_protos_adminapiv1_pb.GetAcceptedOfferResponse,
    (request: build_protos_adminapiv1_pb.GetAcceptedOfferRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetAcceptedOfferResponse.deserializeBinary
  );

  getAcceptedOffer(
    request: build_protos_adminapiv1_pb.GetAcceptedOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetAcceptedOfferResponse>;

  getAcceptedOffer(
    request: build_protos_adminapiv1_pb.GetAcceptedOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetAcceptedOfferResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetAcceptedOfferResponse>;

  getAcceptedOffer(
    request: build_protos_adminapiv1_pb.GetAcceptedOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetAcceptedOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetAcceptedOffer',
        request,
        metadata || {},
        this.methodDescriptorGetAcceptedOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetAcceptedOffer',
    request,
    metadata || {},
    this.methodDescriptorGetAcceptedOffer);
  }

  methodDescriptorListTradeInInfos = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListTradeInInfos',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListTradeInInfosRequest,
    build_protos_adminapiv1_pb.ListTradeInInfosResponse,
    (request: build_protos_adminapiv1_pb.ListTradeInInfosRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListTradeInInfosResponse.deserializeBinary
  );

  listTradeInInfos(
    request: build_protos_adminapiv1_pb.ListTradeInInfosRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListTradeInInfosResponse>;

  listTradeInInfos(
    request: build_protos_adminapiv1_pb.ListTradeInInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListTradeInInfosResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListTradeInInfosResponse>;

  listTradeInInfos(
    request: build_protos_adminapiv1_pb.ListTradeInInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListTradeInInfosResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListTradeInInfos',
        request,
        metadata || {},
        this.methodDescriptorListTradeInInfos,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListTradeInInfos',
    request,
    metadata || {},
    this.methodDescriptorListTradeInInfos);
  }

  methodDescriptorCreateDealerAccount = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateDealerAccount',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateDealerAccountRequest,
    build_protos_adminapiv1_pb.CreateDealerAccountResponse,
    (request: build_protos_adminapiv1_pb.CreateDealerAccountRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateDealerAccountResponse.deserializeBinary
  );

  createDealerAccount(
    request: build_protos_adminapiv1_pb.CreateDealerAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateDealerAccountResponse>;

  createDealerAccount(
    request: build_protos_adminapiv1_pb.CreateDealerAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateDealerAccountResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateDealerAccountResponse>;

  createDealerAccount(
    request: build_protos_adminapiv1_pb.CreateDealerAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateDealerAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateDealerAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateDealerAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateDealerAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateDealerAccount);
  }

  methodDescriptorUpdateDealerAccount = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateDealerAccount',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateDealerAccountRequest,
    build_protos_adminapiv1_pb.UpdateDealerAccountResponse,
    (request: build_protos_adminapiv1_pb.UpdateDealerAccountRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateDealerAccountResponse.deserializeBinary
  );

  updateDealerAccount(
    request: build_protos_adminapiv1_pb.UpdateDealerAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateDealerAccountResponse>;

  updateDealerAccount(
    request: build_protos_adminapiv1_pb.UpdateDealerAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateDealerAccountResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateDealerAccountResponse>;

  updateDealerAccount(
    request: build_protos_adminapiv1_pb.UpdateDealerAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateDealerAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateDealerAccount',
        request,
        metadata || {},
        this.methodDescriptorUpdateDealerAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateDealerAccount',
    request,
    metadata || {},
    this.methodDescriptorUpdateDealerAccount);
  }

  methodDescriptorListDealers = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealers',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealersRequest,
    build_protos_adminapiv1_pb.ListDealersResponse,
    (request: build_protos_adminapiv1_pb.ListDealersRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealersResponse.deserializeBinary
  );

  listDealers(
    request: build_protos_adminapiv1_pb.ListDealersRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealersResponse>;

  listDealers(
    request: build_protos_adminapiv1_pb.ListDealersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealersResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealersResponse>;

  listDealers(
    request: build_protos_adminapiv1_pb.ListDealersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealers',
        request,
        metadata || {},
        this.methodDescriptorListDealers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealers',
    request,
    metadata || {},
    this.methodDescriptorListDealers);
  }

  methodDescriptorGetConsumersByName = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetConsumersByName',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetConsumersByNameRequest,
    build_protos_adminapiv1_pb.GetConsumersByNameResponse,
    (request: build_protos_adminapiv1_pb.GetConsumersByNameRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetConsumersByNameResponse.deserializeBinary
  );

  getConsumersByName(
    request: build_protos_adminapiv1_pb.GetConsumersByNameRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetConsumersByNameResponse>;

  getConsumersByName(
    request: build_protos_adminapiv1_pb.GetConsumersByNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetConsumersByNameResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetConsumersByNameResponse>;

  getConsumersByName(
    request: build_protos_adminapiv1_pb.GetConsumersByNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetConsumersByNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetConsumersByName',
        request,
        metadata || {},
        this.methodDescriptorGetConsumersByName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetConsumersByName',
    request,
    metadata || {},
    this.methodDescriptorGetConsumersByName);
  }

  methodDescriptorChangeVehicleOwnership = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ChangeVehicleOwnership',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ChangeVehicleOwnershipRequest,
    build_protos_adminapiv1_pb.ChangeVehicleOwnershipResponse,
    (request: build_protos_adminapiv1_pb.ChangeVehicleOwnershipRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ChangeVehicleOwnershipResponse.deserializeBinary
  );

  changeVehicleOwnership(
    request: build_protos_adminapiv1_pb.ChangeVehicleOwnershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ChangeVehicleOwnershipResponse>;

  changeVehicleOwnership(
    request: build_protos_adminapiv1_pb.ChangeVehicleOwnershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ChangeVehicleOwnershipResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ChangeVehicleOwnershipResponse>;

  changeVehicleOwnership(
    request: build_protos_adminapiv1_pb.ChangeVehicleOwnershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ChangeVehicleOwnershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ChangeVehicleOwnership',
        request,
        metadata || {},
        this.methodDescriptorChangeVehicleOwnership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ChangeVehicleOwnership',
    request,
    metadata || {},
    this.methodDescriptorChangeVehicleOwnership);
  }

  methodDescriptorUpdateConsumerUser = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateConsumerUser',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateConsumerUserRequest,
    build_protos_adminapiv1_pb.UpdateConsumerUserResponse,
    (request: build_protos_adminapiv1_pb.UpdateConsumerUserRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateConsumerUserResponse.deserializeBinary
  );

  updateConsumerUser(
    request: build_protos_adminapiv1_pb.UpdateConsumerUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateConsumerUserResponse>;

  updateConsumerUser(
    request: build_protos_adminapiv1_pb.UpdateConsumerUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateConsumerUserResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateConsumerUserResponse>;

  updateConsumerUser(
    request: build_protos_adminapiv1_pb.UpdateConsumerUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateConsumerUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateConsumerUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateConsumerUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateConsumerUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateConsumerUser);
  }

  methodDescriptorCreateDealership = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateDealership',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateDealershipRequest,
    build_protos_adminapiv1_pb.CreateDealershipResponse,
    (request: build_protos_adminapiv1_pb.CreateDealershipRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateDealershipResponse.deserializeBinary
  );

  createDealership(
    request: build_protos_adminapiv1_pb.CreateDealershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateDealershipResponse>;

  createDealership(
    request: build_protos_adminapiv1_pb.CreateDealershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateDealershipResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateDealershipResponse>;

  createDealership(
    request: build_protos_adminapiv1_pb.CreateDealershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateDealershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateDealership',
        request,
        metadata || {},
        this.methodDescriptorCreateDealership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateDealership',
    request,
    metadata || {},
    this.methodDescriptorCreateDealership);
  }

  methodDescriptorListDealerships = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealerships',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealershipsRequest,
    build_protos_adminapiv1_pb.ListDealershipsResponse,
    (request: build_protos_adminapiv1_pb.ListDealershipsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealershipsResponse.deserializeBinary
  );

  listDealerships(
    request: build_protos_adminapiv1_pb.ListDealershipsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealershipsResponse>;

  listDealerships(
    request: build_protos_adminapiv1_pb.ListDealershipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealershipsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealershipsResponse>;

  listDealerships(
    request: build_protos_adminapiv1_pb.ListDealershipsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealershipsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealerships',
        request,
        metadata || {},
        this.methodDescriptorListDealerships,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealerships',
    request,
    metadata || {},
    this.methodDescriptorListDealerships);
  }

  methodDescriptorListDealershipItems = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealershipItems',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealershipItemsRequest,
    build_protos_adminapiv1_pb.ListDealershipItemsResponse,
    (request: build_protos_adminapiv1_pb.ListDealershipItemsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealershipItemsResponse.deserializeBinary
  );

  listDealershipItems(
    request: build_protos_adminapiv1_pb.ListDealershipItemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealershipItemsResponse>;

  listDealershipItems(
    request: build_protos_adminapiv1_pb.ListDealershipItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealershipItemsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealershipItemsResponse>;

  listDealershipItems(
    request: build_protos_adminapiv1_pb.ListDealershipItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealershipItemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealershipItems',
        request,
        metadata || {},
        this.methodDescriptorListDealershipItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealershipItems',
    request,
    metadata || {},
    this.methodDescriptorListDealershipItems);
  }

  methodDescriptorGetDealership = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetDealership',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetDealershipRequest,
    build_protos_adminapiv1_pb.GetDealershipResponse,
    (request: build_protos_adminapiv1_pb.GetDealershipRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetDealershipResponse.deserializeBinary
  );

  getDealership(
    request: build_protos_adminapiv1_pb.GetDealershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetDealershipResponse>;

  getDealership(
    request: build_protos_adminapiv1_pb.GetDealershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealershipResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetDealershipResponse>;

  getDealership(
    request: build_protos_adminapiv1_pb.GetDealershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetDealership',
        request,
        metadata || {},
        this.methodDescriptorGetDealership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetDealership',
    request,
    metadata || {},
    this.methodDescriptorGetDealership);
  }

  methodDescriptorUpdateDealership = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateDealership',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateDealershipRequest,
    build_protos_adminapiv1_pb.UpdateDealershipResponse,
    (request: build_protos_adminapiv1_pb.UpdateDealershipRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateDealershipResponse.deserializeBinary
  );

  updateDealership(
    request: build_protos_adminapiv1_pb.UpdateDealershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateDealershipResponse>;

  updateDealership(
    request: build_protos_adminapiv1_pb.UpdateDealershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateDealershipResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateDealershipResponse>;

  updateDealership(
    request: build_protos_adminapiv1_pb.UpdateDealershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateDealershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateDealership',
        request,
        metadata || {},
        this.methodDescriptorUpdateDealership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateDealership',
    request,
    metadata || {},
    this.methodDescriptorUpdateDealership);
  }

  methodDescriptorGetDealershipFromDealerId = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetDealershipFromDealerId',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetDealershipFromDealerIdRequest,
    build_protos_adminapiv1_pb.GetDealershipFromDealerIdResponse,
    (request: build_protos_adminapiv1_pb.GetDealershipFromDealerIdRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetDealershipFromDealerIdResponse.deserializeBinary
  );

  getDealershipFromDealerId(
    request: build_protos_adminapiv1_pb.GetDealershipFromDealerIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetDealershipFromDealerIdResponse>;

  getDealershipFromDealerId(
    request: build_protos_adminapiv1_pb.GetDealershipFromDealerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealershipFromDealerIdResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetDealershipFromDealerIdResponse>;

  getDealershipFromDealerId(
    request: build_protos_adminapiv1_pb.GetDealershipFromDealerIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealershipFromDealerIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetDealershipFromDealerId',
        request,
        metadata || {},
        this.methodDescriptorGetDealershipFromDealerId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetDealershipFromDealerId',
    request,
    metadata || {},
    this.methodDescriptorGetDealershipFromDealerId);
  }

  methodDescriptorResetTermsAndConditions = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ResetTermsAndConditions',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ResetTermsAndConditionsRequest,
    build_protos_adminapiv1_pb.ResetTermsAndConditionsResponse,
    (request: build_protos_adminapiv1_pb.ResetTermsAndConditionsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ResetTermsAndConditionsResponse.deserializeBinary
  );

  resetTermsAndConditions(
    request: build_protos_adminapiv1_pb.ResetTermsAndConditionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ResetTermsAndConditionsResponse>;

  resetTermsAndConditions(
    request: build_protos_adminapiv1_pb.ResetTermsAndConditionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ResetTermsAndConditionsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ResetTermsAndConditionsResponse>;

  resetTermsAndConditions(
    request: build_protos_adminapiv1_pb.ResetTermsAndConditionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ResetTermsAndConditionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ResetTermsAndConditions',
        request,
        metadata || {},
        this.methodDescriptorResetTermsAndConditions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ResetTermsAndConditions',
    request,
    metadata || {},
    this.methodDescriptorResetTermsAndConditions);
  }

  methodDescriptorUpdateCarfaxCredentials = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateCarfaxCredentials',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateCarfaxCredentialsRequest,
    build_protos_adminapiv1_pb.UpdateCarfaxCredentialsResponse,
    (request: build_protos_adminapiv1_pb.UpdateCarfaxCredentialsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateCarfaxCredentialsResponse.deserializeBinary
  );

  updateCarfaxCredentials(
    request: build_protos_adminapiv1_pb.UpdateCarfaxCredentialsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateCarfaxCredentialsResponse>;

  updateCarfaxCredentials(
    request: build_protos_adminapiv1_pb.UpdateCarfaxCredentialsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateCarfaxCredentialsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateCarfaxCredentialsResponse>;

  updateCarfaxCredentials(
    request: build_protos_adminapiv1_pb.UpdateCarfaxCredentialsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateCarfaxCredentialsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateCarfaxCredentials',
        request,
        metadata || {},
        this.methodDescriptorUpdateCarfaxCredentials,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateCarfaxCredentials',
    request,
    metadata || {},
    this.methodDescriptorUpdateCarfaxCredentials);
  }

  methodDescriptorDeleteDealershipLogo = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/DeleteDealershipLogo',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.DeleteDealershipLogoRequest,
    build_protos_adminapiv1_pb.DeleteDealershipLogoResponse,
    (request: build_protos_adminapiv1_pb.DeleteDealershipLogoRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.DeleteDealershipLogoResponse.deserializeBinary
  );

  deleteDealershipLogo(
    request: build_protos_adminapiv1_pb.DeleteDealershipLogoRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.DeleteDealershipLogoResponse>;

  deleteDealershipLogo(
    request: build_protos_adminapiv1_pb.DeleteDealershipLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteDealershipLogoResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.DeleteDealershipLogoResponse>;

  deleteDealershipLogo(
    request: build_protos_adminapiv1_pb.DeleteDealershipLogoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteDealershipLogoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/DeleteDealershipLogo',
        request,
        metadata || {},
        this.methodDescriptorDeleteDealershipLogo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/DeleteDealershipLogo',
    request,
    metadata || {},
    this.methodDescriptorDeleteDealershipLogo);
  }

  methodDescriptorResetDealerAccountPassword = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ResetDealerAccountPassword',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ResetDealerAccountPasswordRequest,
    build_protos_adminapiv1_pb.ResetDealerAccountPasswordResponse,
    (request: build_protos_adminapiv1_pb.ResetDealerAccountPasswordRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ResetDealerAccountPasswordResponse.deserializeBinary
  );

  resetDealerAccountPassword(
    request: build_protos_adminapiv1_pb.ResetDealerAccountPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ResetDealerAccountPasswordResponse>;

  resetDealerAccountPassword(
    request: build_protos_adminapiv1_pb.ResetDealerAccountPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ResetDealerAccountPasswordResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ResetDealerAccountPasswordResponse>;

  resetDealerAccountPassword(
    request: build_protos_adminapiv1_pb.ResetDealerAccountPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ResetDealerAccountPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ResetDealerAccountPassword',
        request,
        metadata || {},
        this.methodDescriptorResetDealerAccountPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ResetDealerAccountPassword',
    request,
    metadata || {},
    this.methodDescriptorResetDealerAccountPassword);
  }

  methodDescriptorListAutoGroups = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListAutoGroups',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListAutoGroupsRequest,
    build_protos_adminapiv1_pb.ListAutoGroupsResponse,
    (request: build_protos_adminapiv1_pb.ListAutoGroupsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListAutoGroupsResponse.deserializeBinary
  );

  listAutoGroups(
    request: build_protos_adminapiv1_pb.ListAutoGroupsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListAutoGroupsResponse>;

  listAutoGroups(
    request: build_protos_adminapiv1_pb.ListAutoGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAutoGroupsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListAutoGroupsResponse>;

  listAutoGroups(
    request: build_protos_adminapiv1_pb.ListAutoGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAutoGroupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListAutoGroups',
        request,
        metadata || {},
        this.methodDescriptorListAutoGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListAutoGroups',
    request,
    metadata || {},
    this.methodDescriptorListAutoGroups);
  }

  methodDescriptorCreateAutoGroup = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateAutoGroup',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateAutoGroupRequest,
    build_protos_adminapiv1_pb.CreateAutoGroupResponse,
    (request: build_protos_adminapiv1_pb.CreateAutoGroupRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateAutoGroupResponse.deserializeBinary
  );

  createAutoGroup(
    request: build_protos_adminapiv1_pb.CreateAutoGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateAutoGroupResponse>;

  createAutoGroup(
    request: build_protos_adminapiv1_pb.CreateAutoGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateAutoGroupResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateAutoGroupResponse>;

  createAutoGroup(
    request: build_protos_adminapiv1_pb.CreateAutoGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateAutoGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateAutoGroup',
        request,
        metadata || {},
        this.methodDescriptorCreateAutoGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateAutoGroup',
    request,
    metadata || {},
    this.methodDescriptorCreateAutoGroup);
  }

  methodDescriptorUpdateAutoGroup = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateAutoGroup',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateAutoGroupRequest,
    build_protos_adminapiv1_pb.UpdateAutoGroupResponse,
    (request: build_protos_adminapiv1_pb.UpdateAutoGroupRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateAutoGroupResponse.deserializeBinary
  );

  updateAutoGroup(
    request: build_protos_adminapiv1_pb.UpdateAutoGroupRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateAutoGroupResponse>;

  updateAutoGroup(
    request: build_protos_adminapiv1_pb.UpdateAutoGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateAutoGroupResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateAutoGroupResponse>;

  updateAutoGroup(
    request: build_protos_adminapiv1_pb.UpdateAutoGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateAutoGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateAutoGroup',
        request,
        metadata || {},
        this.methodDescriptorUpdateAutoGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateAutoGroup',
    request,
    metadata || {},
    this.methodDescriptorUpdateAutoGroup);
  }

  methodDescriptorCreateCompletedSale = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateCompletedSale',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateCompletedSaleRequest,
    build_protos_adminapiv1_pb.CreateCompletedSaleResponse,
    (request: build_protos_adminapiv1_pb.CreateCompletedSaleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateCompletedSaleResponse.deserializeBinary
  );

  createCompletedSale(
    request: build_protos_adminapiv1_pb.CreateCompletedSaleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateCompletedSaleResponse>;

  createCompletedSale(
    request: build_protos_adminapiv1_pb.CreateCompletedSaleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateCompletedSaleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateCompletedSaleResponse>;

  createCompletedSale(
    request: build_protos_adminapiv1_pb.CreateCompletedSaleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateCompletedSaleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateCompletedSale',
        request,
        metadata || {},
        this.methodDescriptorCreateCompletedSale,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateCompletedSale',
    request,
    metadata || {},
    this.methodDescriptorCreateCompletedSale);
  }

  methodDescriptorExtendOfferForVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ExtendOfferForVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ExtendOfferForVehicleRequest,
    build_protos_adminapiv1_pb.ExtendOfferForVehicleResponse,
    (request: build_protos_adminapiv1_pb.ExtendOfferForVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ExtendOfferForVehicleResponse.deserializeBinary
  );

  extendOfferForVehicle(
    request: build_protos_adminapiv1_pb.ExtendOfferForVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ExtendOfferForVehicleResponse>;

  extendOfferForVehicle(
    request: build_protos_adminapiv1_pb.ExtendOfferForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ExtendOfferForVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ExtendOfferForVehicleResponse>;

  extendOfferForVehicle(
    request: build_protos_adminapiv1_pb.ExtendOfferForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ExtendOfferForVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ExtendOfferForVehicle',
        request,
        metadata || {},
        this.methodDescriptorExtendOfferForVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ExtendOfferForVehicle',
    request,
    metadata || {},
    this.methodDescriptorExtendOfferForVehicle);
  }

  methodDescriptorCreateOfferForVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateOfferForVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateOfferForVehicleRequest,
    build_protos_adminapiv1_pb.CreateOfferForVehicleResponse,
    (request: build_protos_adminapiv1_pb.CreateOfferForVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateOfferForVehicleResponse.deserializeBinary
  );

  createOfferForVehicle(
    request: build_protos_adminapiv1_pb.CreateOfferForVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateOfferForVehicleResponse>;

  createOfferForVehicle(
    request: build_protos_adminapiv1_pb.CreateOfferForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateOfferForVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateOfferForVehicleResponse>;

  createOfferForVehicle(
    request: build_protos_adminapiv1_pb.CreateOfferForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateOfferForVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateOfferForVehicle',
        request,
        metadata || {},
        this.methodDescriptorCreateOfferForVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateOfferForVehicle',
    request,
    metadata || {},
    this.methodDescriptorCreateOfferForVehicle);
  }

  methodDescriptorListCompletedSales = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListCompletedSales',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListCompletedSalesRequest,
    build_protos_adminapiv1_pb.ListCompletedSalesResponse,
    (request: build_protos_adminapiv1_pb.ListCompletedSalesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListCompletedSalesResponse.deserializeBinary
  );

  listCompletedSales(
    request: build_protos_adminapiv1_pb.ListCompletedSalesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListCompletedSalesResponse>;

  listCompletedSales(
    request: build_protos_adminapiv1_pb.ListCompletedSalesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListCompletedSalesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListCompletedSalesResponse>;

  listCompletedSales(
    request: build_protos_adminapiv1_pb.ListCompletedSalesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListCompletedSalesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListCompletedSales',
        request,
        metadata || {},
        this.methodDescriptorListCompletedSales,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListCompletedSales',
    request,
    metadata || {},
    this.methodDescriptorListCompletedSales);
  }

  methodDescriptorListAcceptedOffers = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListAcceptedOffers',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListAcceptedOffersRequest,
    build_protos_adminapiv1_pb.ListAcceptedOffersResponse,
    (request: build_protos_adminapiv1_pb.ListAcceptedOffersRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListAcceptedOffersResponse.deserializeBinary
  );

  listAcceptedOffers(
    request: build_protos_adminapiv1_pb.ListAcceptedOffersRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListAcceptedOffersResponse>;

  listAcceptedOffers(
    request: build_protos_adminapiv1_pb.ListAcceptedOffersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAcceptedOffersResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListAcceptedOffersResponse>;

  listAcceptedOffers(
    request: build_protos_adminapiv1_pb.ListAcceptedOffersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListAcceptedOffersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListAcceptedOffers',
        request,
        metadata || {},
        this.methodDescriptorListAcceptedOffers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListAcceptedOffers',
    request,
    metadata || {},
    this.methodDescriptorListAcceptedOffers);
  }

  methodDescriptorListRejectedOffers = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListRejectedOffers',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListRejectedOffersRequest,
    build_protos_adminapiv1_pb.ListRejectedOffersResponse,
    (request: build_protos_adminapiv1_pb.ListRejectedOffersRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListRejectedOffersResponse.deserializeBinary
  );

  listRejectedOffers(
    request: build_protos_adminapiv1_pb.ListRejectedOffersRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListRejectedOffersResponse>;

  listRejectedOffers(
    request: build_protos_adminapiv1_pb.ListRejectedOffersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListRejectedOffersResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListRejectedOffersResponse>;

  listRejectedOffers(
    request: build_protos_adminapiv1_pb.ListRejectedOffersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListRejectedOffersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListRejectedOffers',
        request,
        metadata || {},
        this.methodDescriptorListRejectedOffers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListRejectedOffers',
    request,
    metadata || {},
    this.methodDescriptorListRejectedOffers);
  }

  methodDescriptorUpdateOffer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateOffer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateOfferRequest,
    build_protos_adminapiv1_pb.UpdateOfferResponse,
    (request: build_protos_adminapiv1_pb.UpdateOfferRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateOfferResponse.deserializeBinary
  );

  updateOffer(
    request: build_protos_adminapiv1_pb.UpdateOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateOfferResponse>;

  updateOffer(
    request: build_protos_adminapiv1_pb.UpdateOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateOfferResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateOfferResponse>;

  updateOffer(
    request: build_protos_adminapiv1_pb.UpdateOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateOffer',
        request,
        metadata || {},
        this.methodDescriptorUpdateOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateOffer',
    request,
    metadata || {},
    this.methodDescriptorUpdateOffer);
  }

  methodDescriptorUpdateAuctionGroupAsset = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateAuctionGroupAsset',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateAuctionGroupAssetRequest,
    build_protos_adminapiv1_pb.UpdateAuctionGroupAssetResponse,
    (request: build_protos_adminapiv1_pb.UpdateAuctionGroupAssetRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateAuctionGroupAssetResponse.deserializeBinary
  );

  updateAuctionGroupAsset(
    request: build_protos_adminapiv1_pb.UpdateAuctionGroupAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateAuctionGroupAssetResponse>;

  updateAuctionGroupAsset(
    request: build_protos_adminapiv1_pb.UpdateAuctionGroupAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateAuctionGroupAssetResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateAuctionGroupAssetResponse>;

  updateAuctionGroupAsset(
    request: build_protos_adminapiv1_pb.UpdateAuctionGroupAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateAuctionGroupAssetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateAuctionGroupAsset',
        request,
        metadata || {},
        this.methodDescriptorUpdateAuctionGroupAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateAuctionGroupAsset',
    request,
    metadata || {},
    this.methodDescriptorUpdateAuctionGroupAsset);
  }

  methodDescriptorListDashboardDocuments = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDashboardDocuments',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDashboardDocumentsRequest,
    build_protos_adminapiv1_pb.ListDashboardDocumentsResponse,
    (request: build_protos_adminapiv1_pb.ListDashboardDocumentsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDashboardDocumentsResponse.deserializeBinary
  );

  listDashboardDocuments(
    request: build_protos_adminapiv1_pb.ListDashboardDocumentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDashboardDocumentsResponse>;

  listDashboardDocuments(
    request: build_protos_adminapiv1_pb.ListDashboardDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDashboardDocumentsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDashboardDocumentsResponse>;

  listDashboardDocuments(
    request: build_protos_adminapiv1_pb.ListDashboardDocumentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDashboardDocumentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDashboardDocuments',
        request,
        metadata || {},
        this.methodDescriptorListDashboardDocuments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDashboardDocuments',
    request,
    metadata || {},
    this.methodDescriptorListDashboardDocuments);
  }

  methodDescriptorListConsumerSignups = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListConsumerSignups',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListConsumerSignupsRequest,
    build_protos_adminapiv1_pb.ListConsumerSignupsResponse,
    (request: build_protos_adminapiv1_pb.ListConsumerSignupsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListConsumerSignupsResponse.deserializeBinary
  );

  listConsumerSignups(
    request: build_protos_adminapiv1_pb.ListConsumerSignupsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListConsumerSignupsResponse>;

  listConsumerSignups(
    request: build_protos_adminapiv1_pb.ListConsumerSignupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListConsumerSignupsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListConsumerSignupsResponse>;

  listConsumerSignups(
    request: build_protos_adminapiv1_pb.ListConsumerSignupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListConsumerSignupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListConsumerSignups',
        request,
        metadata || {},
        this.methodDescriptorListConsumerSignups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListConsumerSignups',
    request,
    metadata || {},
    this.methodDescriptorListConsumerSignups);
  }

  methodDescriptorConsumerBuyAddNonListedSale = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ConsumerBuyAddNonListedSale',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleRequest,
    build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleResponse,
    (request: build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleResponse.deserializeBinary
  );

  consumerBuyAddNonListedSale(
    request: build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleResponse>;

  consumerBuyAddNonListedSale(
    request: build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleResponse>;

  consumerBuyAddNonListedSale(
    request: build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ConsumerBuyAddNonListedSaleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ConsumerBuyAddNonListedSale',
        request,
        metadata || {},
        this.methodDescriptorConsumerBuyAddNonListedSale,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ConsumerBuyAddNonListedSale',
    request,
    metadata || {},
    this.methodDescriptorConsumerBuyAddNonListedSale);
  }

  methodDescriptorGenerateVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GenerateVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GenerateVehiclesRequest,
    build_protos_adminapiv1_pb.GenerateVehiclesResponse,
    (request: build_protos_adminapiv1_pb.GenerateVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GenerateVehiclesResponse.deserializeBinary
  );

  generateVehicles(
    request: build_protos_adminapiv1_pb.GenerateVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GenerateVehiclesResponse>;

  generateVehicles(
    request: build_protos_adminapiv1_pb.GenerateVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GenerateVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GenerateVehiclesResponse>;

  generateVehicles(
    request: build_protos_adminapiv1_pb.GenerateVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GenerateVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GenerateVehicles',
        request,
        metadata || {},
        this.methodDescriptorGenerateVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GenerateVehicles',
    request,
    metadata || {},
    this.methodDescriptorGenerateVehicles);
  }

  methodDescriptorProcessImagesForVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ProcessImagesForVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ProcessImagesForVehicleRequest,
    build_protos_adminapiv1_pb.ProcessImagesForVehicleResponse,
    (request: build_protos_adminapiv1_pb.ProcessImagesForVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ProcessImagesForVehicleResponse.deserializeBinary
  );

  processImagesForVehicle(
    request: build_protos_adminapiv1_pb.ProcessImagesForVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ProcessImagesForVehicleResponse>;

  processImagesForVehicle(
    request: build_protos_adminapiv1_pb.ProcessImagesForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ProcessImagesForVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ProcessImagesForVehicleResponse>;

  processImagesForVehicle(
    request: build_protos_adminapiv1_pb.ProcessImagesForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ProcessImagesForVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ProcessImagesForVehicle',
        request,
        metadata || {},
        this.methodDescriptorProcessImagesForVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ProcessImagesForVehicle',
    request,
    metadata || {},
    this.methodDescriptorProcessImagesForVehicle);
  }

  methodDescriptorGetVersion = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetVersion',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetVersionRequest,
    build_protos_adminapiv1_pb.Version,
    (request: build_protos_adminapiv1_pb.GetVersionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.Version.deserializeBinary
  );

  getVersion(
    request: build_protos_adminapiv1_pb.GetVersionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.Version>;

  getVersion(
    request: build_protos_adminapiv1_pb.GetVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.Version) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.Version>;

  getVersion(
    request: build_protos_adminapiv1_pb.GetVersionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.Version) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetVersion',
        request,
        metadata || {},
        this.methodDescriptorGetVersion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetVersion',
    request,
    metadata || {},
    this.methodDescriptorGetVersion);
  }

  methodDescriptorAddVehicleToDealTank = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/AddVehicleToDealTank',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.AddVehicleToDealTankRequest,
    build_protos_adminapiv1_pb.AddVehicleToDealTankResponse,
    (request: build_protos_adminapiv1_pb.AddVehicleToDealTankRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.AddVehicleToDealTankResponse.deserializeBinary
  );

  addVehicleToDealTank(
    request: build_protos_adminapiv1_pb.AddVehicleToDealTankRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.AddVehicleToDealTankResponse>;

  addVehicleToDealTank(
    request: build_protos_adminapiv1_pb.AddVehicleToDealTankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.AddVehicleToDealTankResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.AddVehicleToDealTankResponse>;

  addVehicleToDealTank(
    request: build_protos_adminapiv1_pb.AddVehicleToDealTankRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.AddVehicleToDealTankResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/AddVehicleToDealTank',
        request,
        metadata || {},
        this.methodDescriptorAddVehicleToDealTank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/AddVehicleToDealTank',
    request,
    metadata || {},
    this.methodDescriptorAddVehicleToDealTank);
  }

  methodDescriptorRemoveVehicleFromDealTank = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/RemoveVehicleFromDealTank',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.RemoveVehicleFromDealTankRequest,
    build_protos_adminapiv1_pb.RemoveVehicleFromDealTankResponse,
    (request: build_protos_adminapiv1_pb.RemoveVehicleFromDealTankRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.RemoveVehicleFromDealTankResponse.deserializeBinary
  );

  removeVehicleFromDealTank(
    request: build_protos_adminapiv1_pb.RemoveVehicleFromDealTankRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.RemoveVehicleFromDealTankResponse>;

  removeVehicleFromDealTank(
    request: build_protos_adminapiv1_pb.RemoveVehicleFromDealTankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RemoveVehicleFromDealTankResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.RemoveVehicleFromDealTankResponse>;

  removeVehicleFromDealTank(
    request: build_protos_adminapiv1_pb.RemoveVehicleFromDealTankRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RemoveVehicleFromDealTankResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/RemoveVehicleFromDealTank',
        request,
        metadata || {},
        this.methodDescriptorRemoveVehicleFromDealTank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/RemoveVehicleFromDealTank',
    request,
    metadata || {},
    this.methodDescriptorRemoveVehicleFromDealTank);
  }

  methodDescriptorEditDealTankVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/EditDealTankVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.EditDealTankVehicleRequest,
    build_protos_adminapiv1_pb.EditDealTankVehicleResponse,
    (request: build_protos_adminapiv1_pb.EditDealTankVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.EditDealTankVehicleResponse.deserializeBinary
  );

  editDealTankVehicle(
    request: build_protos_adminapiv1_pb.EditDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.EditDealTankVehicleResponse>;

  editDealTankVehicle(
    request: build_protos_adminapiv1_pb.EditDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.EditDealTankVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.EditDealTankVehicleResponse>;

  editDealTankVehicle(
    request: build_protos_adminapiv1_pb.EditDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.EditDealTankVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/EditDealTankVehicle',
        request,
        metadata || {},
        this.methodDescriptorEditDealTankVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/EditDealTankVehicle',
    request,
    metadata || {},
    this.methodDescriptorEditDealTankVehicle);
  }

  methodDescriptorReactivateDealTankVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ReactivateDealTankVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ReactivateDealTankVehicleRequest,
    build_protos_adminapiv1_pb.ReactivateDealTankVehicleResponse,
    (request: build_protos_adminapiv1_pb.ReactivateDealTankVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ReactivateDealTankVehicleResponse.deserializeBinary
  );

  reactivateDealTankVehicle(
    request: build_protos_adminapiv1_pb.ReactivateDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ReactivateDealTankVehicleResponse>;

  reactivateDealTankVehicle(
    request: build_protos_adminapiv1_pb.ReactivateDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ReactivateDealTankVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ReactivateDealTankVehicleResponse>;

  reactivateDealTankVehicle(
    request: build_protos_adminapiv1_pb.ReactivateDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ReactivateDealTankVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ReactivateDealTankVehicle',
        request,
        metadata || {},
        this.methodDescriptorReactivateDealTankVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ReactivateDealTankVehicle',
    request,
    metadata || {},
    this.methodDescriptorReactivateDealTankVehicle);
  }

  methodDescriptorGetDealTankVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetDealTankVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetDealTankVehicleRequest,
    build_protos_adminapiv1_pb.GetDealTankVehicleResponse,
    (request: build_protos_adminapiv1_pb.GetDealTankVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetDealTankVehicleResponse.deserializeBinary
  );

  getDealTankVehicle(
    request: build_protos_adminapiv1_pb.GetDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetDealTankVehicleResponse>;

  getDealTankVehicle(
    request: build_protos_adminapiv1_pb.GetDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealTankVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetDealTankVehicleResponse>;

  getDealTankVehicle(
    request: build_protos_adminapiv1_pb.GetDealTankVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealTankVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetDealTankVehicle',
        request,
        metadata || {},
        this.methodDescriptorGetDealTankVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetDealTankVehicle',
    request,
    metadata || {},
    this.methodDescriptorGetDealTankVehicle);
  }

  methodDescriptorListDealTankVehicleFacets = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealTankVehicleFacets',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealTankVehicleFacetsRequest,
    build_protos_adminapiv1_pb.ListDealTankVehicleFacetsResponse,
    (request: build_protos_adminapiv1_pb.ListDealTankVehicleFacetsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealTankVehicleFacetsResponse.deserializeBinary
  );

  listDealTankVehicleFacets(
    request: build_protos_adminapiv1_pb.ListDealTankVehicleFacetsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealTankVehicleFacetsResponse>;

  listDealTankVehicleFacets(
    request: build_protos_adminapiv1_pb.ListDealTankVehicleFacetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealTankVehicleFacetsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealTankVehicleFacetsResponse>;

  listDealTankVehicleFacets(
    request: build_protos_adminapiv1_pb.ListDealTankVehicleFacetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealTankVehicleFacetsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealTankVehicleFacets',
        request,
        metadata || {},
        this.methodDescriptorListDealTankVehicleFacets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealTankVehicleFacets',
    request,
    metadata || {},
    this.methodDescriptorListDealTankVehicleFacets);
  }

  methodDescriptorListDealTankVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealTankVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealTankVehiclesRequest,
    build_protos_adminapiv1_pb.ListDealTankVehiclesResponse,
    (request: build_protos_adminapiv1_pb.ListDealTankVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealTankVehiclesResponse.deserializeBinary
  );

  listDealTankVehicles(
    request: build_protos_adminapiv1_pb.ListDealTankVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealTankVehiclesResponse>;

  listDealTankVehicles(
    request: build_protos_adminapiv1_pb.ListDealTankVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealTankVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealTankVehiclesResponse>;

  listDealTankVehicles(
    request: build_protos_adminapiv1_pb.ListDealTankVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealTankVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealTankVehicles',
        request,
        metadata || {},
        this.methodDescriptorListDealTankVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealTankVehicles',
    request,
    metadata || {},
    this.methodDescriptorListDealTankVehicles);
  }

  methodDescriptorListDealTankOffersForVehicle = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealTankOffersForVehicle',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealTankOffersForVehicleRequest,
    build_protos_adminapiv1_pb.ListDealTankOffersForVehicleResponse,
    (request: build_protos_adminapiv1_pb.ListDealTankOffersForVehicleRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealTankOffersForVehicleResponse.deserializeBinary
  );

  listDealTankOffersForVehicle(
    request: build_protos_adminapiv1_pb.ListDealTankOffersForVehicleRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealTankOffersForVehicleResponse>;

  listDealTankOffersForVehicle(
    request: build_protos_adminapiv1_pb.ListDealTankOffersForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealTankOffersForVehicleResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealTankOffersForVehicleResponse>;

  listDealTankOffersForVehicle(
    request: build_protos_adminapiv1_pb.ListDealTankOffersForVehicleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealTankOffersForVehicleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealTankOffersForVehicle',
        request,
        metadata || {},
        this.methodDescriptorListDealTankOffersForVehicle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealTankOffersForVehicle',
    request,
    metadata || {},
    this.methodDescriptorListDealTankOffersForVehicle);
  }

  methodDescriptorCreateDealTankOffer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateDealTankOffer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateDealTankOfferRequest,
    build_protos_adminapiv1_pb.CreateDealTankOfferResponse,
    (request: build_protos_adminapiv1_pb.CreateDealTankOfferRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateDealTankOfferResponse.deserializeBinary
  );

  createDealTankOffer(
    request: build_protos_adminapiv1_pb.CreateDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateDealTankOfferResponse>;

  createDealTankOffer(
    request: build_protos_adminapiv1_pb.CreateDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateDealTankOfferResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateDealTankOfferResponse>;

  createDealTankOffer(
    request: build_protos_adminapiv1_pb.CreateDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateDealTankOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateDealTankOffer',
        request,
        metadata || {},
        this.methodDescriptorCreateDealTankOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateDealTankOffer',
    request,
    metadata || {},
    this.methodDescriptorCreateDealTankOffer);
  }

  methodDescriptorEditDealTankOffer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/EditDealTankOffer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.EditDealTankOfferRequest,
    build_protos_adminapiv1_pb.EditDealTankOfferResponse,
    (request: build_protos_adminapiv1_pb.EditDealTankOfferRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.EditDealTankOfferResponse.deserializeBinary
  );

  editDealTankOffer(
    request: build_protos_adminapiv1_pb.EditDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.EditDealTankOfferResponse>;

  editDealTankOffer(
    request: build_protos_adminapiv1_pb.EditDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.EditDealTankOfferResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.EditDealTankOfferResponse>;

  editDealTankOffer(
    request: build_protos_adminapiv1_pb.EditDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.EditDealTankOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/EditDealTankOffer',
        request,
        metadata || {},
        this.methodDescriptorEditDealTankOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/EditDealTankOffer',
    request,
    metadata || {},
    this.methodDescriptorEditDealTankOffer);
  }

  methodDescriptorGetOffersByStatus = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetOffersByStatus',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetOffersByStatusRequest,
    build_protos_adminapiv1_pb.GetOffersByStatusResponse,
    (request: build_protos_adminapiv1_pb.GetOffersByStatusRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetOffersByStatusResponse.deserializeBinary
  );

  getOffersByStatus(
    request: build_protos_adminapiv1_pb.GetOffersByStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetOffersByStatusResponse>;

  getOffersByStatus(
    request: build_protos_adminapiv1_pb.GetOffersByStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetOffersByStatusResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetOffersByStatusResponse>;

  getOffersByStatus(
    request: build_protos_adminapiv1_pb.GetOffersByStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetOffersByStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetOffersByStatus',
        request,
        metadata || {},
        this.methodDescriptorGetOffersByStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetOffersByStatus',
    request,
    metadata || {},
    this.methodDescriptorGetOffersByStatus);
  }

  methodDescriptorAcceptDealTankOffer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/AcceptDealTankOffer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.AcceptDealTankOfferRequest,
    build_protos_adminapiv1_pb.AcceptDealTankOfferResponse,
    (request: build_protos_adminapiv1_pb.AcceptDealTankOfferRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.AcceptDealTankOfferResponse.deserializeBinary
  );

  acceptDealTankOffer(
    request: build_protos_adminapiv1_pb.AcceptDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.AcceptDealTankOfferResponse>;

  acceptDealTankOffer(
    request: build_protos_adminapiv1_pb.AcceptDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.AcceptDealTankOfferResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.AcceptDealTankOfferResponse>;

  acceptDealTankOffer(
    request: build_protos_adminapiv1_pb.AcceptDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.AcceptDealTankOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/AcceptDealTankOffer',
        request,
        metadata || {},
        this.methodDescriptorAcceptDealTankOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/AcceptDealTankOffer',
    request,
    metadata || {},
    this.methodDescriptorAcceptDealTankOffer);
  }

  methodDescriptorRejectDealTankOffer = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/RejectDealTankOffer',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.RejectDealTankOfferRequest,
    build_protos_adminapiv1_pb.RejectDealTankOfferResponse,
    (request: build_protos_adminapiv1_pb.RejectDealTankOfferRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.RejectDealTankOfferResponse.deserializeBinary
  );

  rejectDealTankOffer(
    request: build_protos_adminapiv1_pb.RejectDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.RejectDealTankOfferResponse>;

  rejectDealTankOffer(
    request: build_protos_adminapiv1_pb.RejectDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RejectDealTankOfferResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.RejectDealTankOfferResponse>;

  rejectDealTankOffer(
    request: build_protos_adminapiv1_pb.RejectDealTankOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RejectDealTankOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/RejectDealTankOffer',
        request,
        metadata || {},
        this.methodDescriptorRejectDealTankOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/RejectDealTankOffer',
    request,
    metadata || {},
    this.methodDescriptorRejectDealTankOffer);
  }

  methodDescriptorRejectDealTankListing = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/RejectDealTankListing',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.RejectDealTankListingRequest,
    build_protos_adminapiv1_pb.RejectDealTankListingResponse,
    (request: build_protos_adminapiv1_pb.RejectDealTankListingRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.RejectDealTankListingResponse.deserializeBinary
  );

  rejectDealTankListing(
    request: build_protos_adminapiv1_pb.RejectDealTankListingRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.RejectDealTankListingResponse>;

  rejectDealTankListing(
    request: build_protos_adminapiv1_pb.RejectDealTankListingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RejectDealTankListingResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.RejectDealTankListingResponse>;

  rejectDealTankListing(
    request: build_protos_adminapiv1_pb.RejectDealTankListingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.RejectDealTankListingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/RejectDealTankListing',
        request,
        metadata || {},
        this.methodDescriptorRejectDealTankListing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/RejectDealTankListing',
    request,
    metadata || {},
    this.methodDescriptorRejectDealTankListing);
  }

  methodDescriptorListDealershipReferrers = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealershipReferrers',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealershipReferrersRequest,
    build_protos_adminapiv1_pb.ListDealershipReferrersResponse,
    (request: build_protos_adminapiv1_pb.ListDealershipReferrersRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealershipReferrersResponse.deserializeBinary
  );

  listDealershipReferrers(
    request: build_protos_adminapiv1_pb.ListDealershipReferrersRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealershipReferrersResponse>;

  listDealershipReferrers(
    request: build_protos_adminapiv1_pb.ListDealershipReferrersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealershipReferrersResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealershipReferrersResponse>;

  listDealershipReferrers(
    request: build_protos_adminapiv1_pb.ListDealershipReferrersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealershipReferrersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealershipReferrers',
        request,
        metadata || {},
        this.methodDescriptorListDealershipReferrers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealershipReferrers',
    request,
    metadata || {},
    this.methodDescriptorListDealershipReferrers);
  }

  methodDescriptorGetDealershipReferrerById = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetDealershipReferrerById',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetDealershipReferrerByIdRequest,
    build_protos_adminapiv1_pb.GetDealershipReferrerByIdResponse,
    (request: build_protos_adminapiv1_pb.GetDealershipReferrerByIdRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetDealershipReferrerByIdResponse.deserializeBinary
  );

  getDealershipReferrerById(
    request: build_protos_adminapiv1_pb.GetDealershipReferrerByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetDealershipReferrerByIdResponse>;

  getDealershipReferrerById(
    request: build_protos_adminapiv1_pb.GetDealershipReferrerByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealershipReferrerByIdResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetDealershipReferrerByIdResponse>;

  getDealershipReferrerById(
    request: build_protos_adminapiv1_pb.GetDealershipReferrerByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetDealershipReferrerByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetDealershipReferrerById',
        request,
        metadata || {},
        this.methodDescriptorGetDealershipReferrerById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetDealershipReferrerById',
    request,
    metadata || {},
    this.methodDescriptorGetDealershipReferrerById);
  }

  methodDescriptorCreateFlag = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CreateFlag',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CreateFlagRequest,
    build_protos_adminapiv1_pb.CreateFlagResponse,
    (request: build_protos_adminapiv1_pb.CreateFlagRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CreateFlagResponse.deserializeBinary
  );

  createFlag(
    request: build_protos_adminapiv1_pb.CreateFlagRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CreateFlagResponse>;

  createFlag(
    request: build_protos_adminapiv1_pb.CreateFlagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateFlagResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CreateFlagResponse>;

  createFlag(
    request: build_protos_adminapiv1_pb.CreateFlagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CreateFlagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CreateFlag',
        request,
        metadata || {},
        this.methodDescriptorCreateFlag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CreateFlag',
    request,
    metadata || {},
    this.methodDescriptorCreateFlag);
  }

  methodDescriptorDeleteFlag = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/DeleteFlag',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.DeleteFlagRequest,
    build_protos_adminapiv1_pb.DeleteFlagResponse,
    (request: build_protos_adminapiv1_pb.DeleteFlagRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.DeleteFlagResponse.deserializeBinary
  );

  deleteFlag(
    request: build_protos_adminapiv1_pb.DeleteFlagRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.DeleteFlagResponse>;

  deleteFlag(
    request: build_protos_adminapiv1_pb.DeleteFlagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteFlagResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.DeleteFlagResponse>;

  deleteFlag(
    request: build_protos_adminapiv1_pb.DeleteFlagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.DeleteFlagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/DeleteFlag',
        request,
        metadata || {},
        this.methodDescriptorDeleteFlag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/DeleteFlag',
    request,
    metadata || {},
    this.methodDescriptorDeleteFlag);
  }

  methodDescriptorCountFlagsByVehicleId = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CountFlagsByVehicleId',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CountFlagsByVehicleIdRequest,
    build_protos_adminapiv1_pb.CountFlagsByVehicleIdResponse,
    (request: build_protos_adminapiv1_pb.CountFlagsByVehicleIdRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CountFlagsByVehicleIdResponse.deserializeBinary
  );

  countFlagsByVehicleId(
    request: build_protos_adminapiv1_pb.CountFlagsByVehicleIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CountFlagsByVehicleIdResponse>;

  countFlagsByVehicleId(
    request: build_protos_adminapiv1_pb.CountFlagsByVehicleIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CountFlagsByVehicleIdResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CountFlagsByVehicleIdResponse>;

  countFlagsByVehicleId(
    request: build_protos_adminapiv1_pb.CountFlagsByVehicleIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CountFlagsByVehicleIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CountFlagsByVehicleId',
        request,
        metadata || {},
        this.methodDescriptorCountFlagsByVehicleId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CountFlagsByVehicleId',
    request,
    metadata || {},
    this.methodDescriptorCountFlagsByVehicleId);
  }

  methodDescriptorCheckIsFlagged = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/CheckIsFlagged',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.CheckIsFlaggedRequest,
    build_protos_adminapiv1_pb.CheckIsFlaggedResponse,
    (request: build_protos_adminapiv1_pb.CheckIsFlaggedRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.CheckIsFlaggedResponse.deserializeBinary
  );

  checkIsFlagged(
    request: build_protos_adminapiv1_pb.CheckIsFlaggedRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.CheckIsFlaggedResponse>;

  checkIsFlagged(
    request: build_protos_adminapiv1_pb.CheckIsFlaggedRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CheckIsFlaggedResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.CheckIsFlaggedResponse>;

  checkIsFlagged(
    request: build_protos_adminapiv1_pb.CheckIsFlaggedRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.CheckIsFlaggedResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/CheckIsFlagged',
        request,
        metadata || {},
        this.methodDescriptorCheckIsFlagged,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/CheckIsFlagged',
    request,
    metadata || {},
    this.methodDescriptorCheckIsFlagged);
  }

  methodDescriptorListFlaggedVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListFlaggedVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListFlaggedVehiclesRequest,
    build_protos_adminapiv1_pb.ListFlaggedVehiclesResponse,
    (request: build_protos_adminapiv1_pb.ListFlaggedVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListFlaggedVehiclesResponse.deserializeBinary
  );

  listFlaggedVehicles(
    request: build_protos_adminapiv1_pb.ListFlaggedVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListFlaggedVehiclesResponse>;

  listFlaggedVehicles(
    request: build_protos_adminapiv1_pb.ListFlaggedVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListFlaggedVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListFlaggedVehiclesResponse>;

  listFlaggedVehicles(
    request: build_protos_adminapiv1_pb.ListFlaggedVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListFlaggedVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListFlaggedVehicles',
        request,
        metadata || {},
        this.methodDescriptorListFlaggedVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListFlaggedVehicles',
    request,
    metadata || {},
    this.methodDescriptorListFlaggedVehicles);
  }

  methodDescriptorListDealerSearchVehicles = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListDealerSearchVehicles',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListDealerSearchVehiclesRequest,
    build_protos_adminapiv1_pb.ListDealerSearchVehiclesResponse,
    (request: build_protos_adminapiv1_pb.ListDealerSearchVehiclesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListDealerSearchVehiclesResponse.deserializeBinary
  );

  listDealerSearchVehicles(
    request: build_protos_adminapiv1_pb.ListDealerSearchVehiclesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListDealerSearchVehiclesResponse>;

  listDealerSearchVehicles(
    request: build_protos_adminapiv1_pb.ListDealerSearchVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealerSearchVehiclesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListDealerSearchVehiclesResponse>;

  listDealerSearchVehicles(
    request: build_protos_adminapiv1_pb.ListDealerSearchVehiclesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListDealerSearchVehiclesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListDealerSearchVehicles',
        request,
        metadata || {},
        this.methodDescriptorListDealerSearchVehicles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListDealerSearchVehicles',
    request,
    metadata || {},
    this.methodDescriptorListDealerSearchVehicles);
  }

  methodDescriptorListMarketplaces = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListMarketplaces',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListMarketplacesRequest,
    build_protos_adminapiv1_pb.ListMarketplacesResponse,
    (request: build_protos_adminapiv1_pb.ListMarketplacesRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListMarketplacesResponse.deserializeBinary
  );

  listMarketplaces(
    request: build_protos_adminapiv1_pb.ListMarketplacesRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListMarketplacesResponse>;

  listMarketplaces(
    request: build_protos_adminapiv1_pb.ListMarketplacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListMarketplacesResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListMarketplacesResponse>;

  listMarketplaces(
    request: build_protos_adminapiv1_pb.ListMarketplacesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListMarketplacesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListMarketplaces',
        request,
        metadata || {},
        this.methodDescriptorListMarketplaces,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListMarketplaces',
    request,
    metadata || {},
    this.methodDescriptorListMarketplaces);
  }

  methodDescriptorListCarInspectionQuestionWithoutInspection = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListCarInspectionQuestionWithoutInspection',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionRequest,
    build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionResponse,
    (request: build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionResponse.deserializeBinary
  );

  listCarInspectionQuestionWithoutInspection(
    request: build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionResponse>;

  listCarInspectionQuestionWithoutInspection(
    request: build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionResponse>;

  listCarInspectionQuestionWithoutInspection(
    request: build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListCarInspectionQuestionWithoutInspectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListCarInspectionQuestionWithoutInspection',
        request,
        metadata || {},
        this.methodDescriptorListCarInspectionQuestionWithoutInspection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListCarInspectionQuestionWithoutInspection',
    request,
    metadata || {},
    this.methodDescriptorListCarInspectionQuestionWithoutInspection);
  }

  methodDescriptorListCarInspectionQuestionOptions = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ListCarInspectionQuestionOptions',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsRequest,
    build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsResponse,
    (request: build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsResponse.deserializeBinary
  );

  listCarInspectionQuestionOptions(
    request: build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsResponse>;

  listCarInspectionQuestionOptions(
    request: build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsResponse>;

  listCarInspectionQuestionOptions(
    request: build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ListCarInspectionQuestionOptionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ListCarInspectionQuestionOptions',
        request,
        metadata || {},
        this.methodDescriptorListCarInspectionQuestionOptions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ListCarInspectionQuestionOptions',
    request,
    metadata || {},
    this.methodDescriptorListCarInspectionQuestionOptions);
  }

  methodDescriptorUpdateCarInspectionQuestionOption = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateCarInspectionQuestionOption',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionRequest,
    build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionResponse,
    (request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionResponse.deserializeBinary
  );

  updateCarInspectionQuestionOption(
    request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionResponse>;

  updateCarInspectionQuestionOption(
    request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionResponse>;

  updateCarInspectionQuestionOption(
    request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionOptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateCarInspectionQuestionOption',
        request,
        metadata || {},
        this.methodDescriptorUpdateCarInspectionQuestionOption,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateCarInspectionQuestionOption',
    request,
    metadata || {},
    this.methodDescriptorUpdateCarInspectionQuestionOption);
  }

  methodDescriptorUpdateCarInspectionQuestionWithoutInspection = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/UpdateCarInspectionQuestionWithoutInspection',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionRequest,
    build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionResponse,
    (request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionResponse.deserializeBinary
  );

  updateCarInspectionQuestionWithoutInspection(
    request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionResponse>;

  updateCarInspectionQuestionWithoutInspection(
    request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionResponse>;

  updateCarInspectionQuestionWithoutInspection(
    request: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.UpdateCarInspectionQuestionWithoutInspectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/UpdateCarInspectionQuestionWithoutInspection',
        request,
        metadata || {},
        this.methodDescriptorUpdateCarInspectionQuestionWithoutInspection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/UpdateCarInspectionQuestionWithoutInspection',
    request,
    metadata || {},
    this.methodDescriptorUpdateCarInspectionQuestionWithoutInspection);
  }

  methodDescriptorGetVehiclePricingByVehicleId = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/GetVehiclePricingByVehicleId',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdRequest,
    build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdResponse,
    (request: build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdResponse.deserializeBinary
  );

  getVehiclePricingByVehicleId(
    request: build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdResponse>;

  getVehiclePricingByVehicleId(
    request: build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdResponse>;

  getVehiclePricingByVehicleId(
    request: build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.GetVehiclePricingByVehicleIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/GetVehiclePricingByVehicleId',
        request,
        metadata || {},
        this.methodDescriptorGetVehiclePricingByVehicleId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/GetVehiclePricingByVehicleId',
    request,
    metadata || {},
    this.methodDescriptorGetVehiclePricingByVehicleId);
  }

  methodDescriptorImpersonateUser = new grpcWeb.MethodDescriptor(
    '/com.mintlist.adminapiv1.Apiv1/ImpersonateUser',
    grpcWeb.MethodType.UNARY,
    build_protos_adminapiv1_pb.ImpersonateUserRequest,
    build_protos_adminapiv1_pb.ImpersonateUserResponse,
    (request: build_protos_adminapiv1_pb.ImpersonateUserRequest) => {
      return request.serializeBinary();
    },
    build_protos_adminapiv1_pb.ImpersonateUserResponse.deserializeBinary
  );

  impersonateUser(
    request: build_protos_adminapiv1_pb.ImpersonateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<build_protos_adminapiv1_pb.ImpersonateUserResponse>;

  impersonateUser(
    request: build_protos_adminapiv1_pb.ImpersonateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ImpersonateUserResponse) => void): grpcWeb.ClientReadableStream<build_protos_adminapiv1_pb.ImpersonateUserResponse>;

  impersonateUser(
    request: build_protos_adminapiv1_pb.ImpersonateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: build_protos_adminapiv1_pb.ImpersonateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.mintlist.adminapiv1.Apiv1/ImpersonateUser',
        request,
        metadata || {},
        this.methodDescriptorImpersonateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.mintlist.adminapiv1.Apiv1/ImpersonateUser',
    request,
    metadata || {},
    this.methodDescriptorImpersonateUser);
  }

}

