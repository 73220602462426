import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { Currency, MileageUOM } from '../../grpc/build/protos/adminapiv1_pb';
import { Location } from '../../grpc/build/protos/entities_pb';
import { useApiReady } from '../utils/useApiReady';
import { User } from './types';
import { useAuthHeader } from './useAuthHeader';

type AddNonListedSaleProps = {
  user: User;
  vin: string;
  make: string;
  model: string;
  year: number;
  trim: string;
  mileage: number;
  mileageUom: MileageUOM;
  price: number;
  currency: Currency;
  dealerId: string;
  effectiveDate: number;
};

export const useConsumerBuyAddNonListedSale = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [addingSale, setAddingSale] = useState(false);

  const addNonListedSale = async (input: AddNonListedSaleProps) => {
    if (!isConnectionReady) return;
    try {
      setAddingSale(true);
      const request = new adminApiv1Pb.ConsumerBuyAddNonListedSaleRequest();

      const parsedUser: adminApiv1Pb.User = new adminApiv1Pb.User();
      parsedUser.setId(input.user.id);
      parsedUser.setName(input.user.name);
      parsedUser.setFamilyName(input.user.familyName);
      parsedUser.setGivenName(input.user.givenName);
      parsedUser.setPhone(input.user.phone);
      parsedUser.setEmail(input.user.email);

      const parsedLocation: Location = new Location();
      parsedLocation.setAddress1(input.user.location?.address1 || '');
      parsedLocation.setAddress2(input.user.location?.address2 || '');
      parsedLocation.setCity(input.user.location?.city || '');
      parsedLocation.setState(input.user.location?.state || '');
      parsedLocation.setCountry(input.user.location?.country || '');
      parsedLocation.setPostalCode(input.user.location?.postalCode || '');

      parsedUser.setLocation(parsedLocation);

      request.setUser(parsedUser);
      request.setVin(input.vin);
      request.setMake(input.make);
      request.setModel(input.model);
      request.setYear(input.year);
      request.setTrim(input.trim);
      request.setMileage(input.mileage);
      request.setMileageUom(input.mileageUom);
      request.setPrice(input.price);
      request.setCurrency(input.currency);
      request.setDealerId(input.dealerId);
      request.setEffectiveDate(input.effectiveDate);
      console.log(request);
      await client.consumerBuyAddNonListedSale(request, authHeader);
    } finally {
      setAddingSale(false);
    }
  };

  return { addNonListedSale, addingSale };
};
