import React, { useState } from 'react';

import { useGetDealerVehicles } from '../../composables/api/useGetDealerVehicles';
import { useVehicleImageProcess } from '../../composables/api/useVehicleImageProcess';
import { useSelectVehicles } from '../../composables/dealerVehicles/useSelectVehicles';
import { useDownload } from '../../composables/utils/useDownload';
import { usePaginator } from '../../composables/utils/usePaginator';
import { WayOfSale } from '../../grpc/build/protos/enum_pb';
import { getNumberWithComma } from '../../helpers/utils';
import styles from '../../scss/DealerVehicles.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseSearchInput from '../base/BaseSearchInput';
import BaseWrapper from '../base/BaseWrapper';
import AddNewVehicleButton from '../utils/AddNewVehicleButton';
import CompleteSaleButton from '../utils/CompleteSaleButton';
import Paginator from '../utils/Paginator';

function DealerVehicles() {
  const pageSize = 50;
  const { vehiclesInfo, getDealerVehicles, vehicles } = useGetDealerVehicles(pageSize);
  const { currentPage, handleNewPage, setCurrentPage } = usePaginator({ pageSize, onPageChange });
  const { getClassFactory, handleRowClick, handleBulkSelect, clearAll, selected } = useSelectVehicles();
  const setActiveRowClass = getClassFactory(styles.selected);
  const { exportToJson } = useDownload();
  const [isShowingAll, setIsShowingAll] = useState(true);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const { processVehicleImage } = useVehicleImageProcess();

  function onPageChange(pageSize: number, newPage: number) {
    try {
      getDealerVehicles({
        pageSize,
        offSet: (newPage - 1) * pageSize,
        keyword: currentSearchTerm ? currentSearchTerm : undefined,
      });
    } catch (error) {
      console.error('on page change: ', error);
    }
  }

  function handleSearch(searchTerm: string) {
    setCurrentPage(1);

    try {
      getDealerVehicles({
        pageSize,
        offSet: 0,
        keyword: searchTerm ? searchTerm : undefined,
      });
    } catch (error) {
      console.error('handleSearch: ', error);
    } finally {
      setCurrentSearchTerm(searchTerm);
    }
  }

  function toggleDisplay() {
    if (!selected.length) setIsShowingAll(true);
    setIsShowingAll(!isShowingAll);
  }

  function preventClickOnParent(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const processImage = async () => {
    for (let i = 0; i < selected.length; i++) {
      await processVehicleImage(selected[i].id, true);
    }
    alert('Image Proccess Triggered');
  };

  return (
    <BaseWrapper>
      <h2>Consumer Buy - Vehicle Listing</h2>

      <div className={styles.input}>
        <BaseSearchInput placeholder="Search by make/ model/ trim" onSearch={handleSearch} inline />

        {vehiclesInfo.totalSize && <p>Total: {getNumberWithComma(vehiclesInfo.totalSize)}</p>}
      </div>

      <div className={styles.controlPanel}>
        <BaseButtonOutline display="Select current page" small onClick={() => handleBulkSelect(vehicles)} />
        <BaseButtonOutline
          display={isShowingAll ? `Show selected (${selected.length})` : 'Show all'}
          small
          disabled={!selected.length}
          onClick={toggleDisplay}
        />
        <BaseButtonOutline
          display="Clear selected"
          small
          disabled={!selected.length}
          onClick={() => clearAll(() => setIsShowingAll(true))}
        />
        <BaseButtonOutline
          display="Download"
          small
          disabled={!selected.length}
          onClick={() => exportToJson(selected)}
        />
        <BaseButtonOutline
          display="Trigger Image Process"
          small
          onClick={() => processImage()}
          disabled={!selected.length}
        />
        <AddNewVehicleButton />
      </div>

      {isShowingAll && (
        <Paginator
          currentPage={currentPage}
          maxPage={Math.ceil(vehiclesInfo.totalSize / pageSize)}
          handleNewPage={handleNewPage}
        />
      )}

      <table className={styles.dataTable}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Make</th>
            <th>Model</th>
            <th>Trim</th>
            <th>Year</th>
            <th>Mileage</th>
            <th>Location</th>
            <th>Price</th>
            <th>Vin</th>
            <th>VDP</th>
            <th>Create Sale</th>
          </tr>
        </thead>
        <tbody>
          {(isShowingAll ? vehicles : selected).map((vehicle) => {
            return (
              <tr key={vehicle.id} onClick={() => handleRowClick(vehicle)} className={setActiveRowClass(vehicle.id)}>
                <td>{vehicle.id}</td>
                <td>{vehicle.make}</td>
                <td>{vehicle.model}</td>
                <td>{vehicle.trim}</td>
                <td>{vehicle.year}</td>
                <td className={styles.rightAlign}>{vehicle.mileage}</td>
                <td>{vehicle.location}</td>
                <td className={styles.rightAlign}>{vehicle.price}</td>
                <td>{vehicle.vin}</td>
                <td onClick={preventClickOnParent} aria-hidden="true">
                  <a href={vehicle.vdpUrl} target="_blank" rel="noreferrer">
                    link
                  </a>
                </td>
                <td>
                  <CompleteSaleButton vehicleId={vehicle.id} wayOfSale={WayOfSale.WAY_OF_SALE_CONSUMER_BUY} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </BaseWrapper>
  );
}

export default DealerVehicles;
