import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useChangeOwnership = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const changeOwnership = async (
    userId: string,
    givenName: string,
    phoneNumber: string,
    isAuction: boolean,
    vehicleId: string,
  ) => {
    if (!isConnectionReady) return;

    const request = new adminApiv1Pb.ChangeVehicleOwnershipRequest();
    request.setVehicleId(vehicleId);
    request.setUserId(userId);
    request.setUserGivenName(givenName);
    request.setUserPhoneNumber(phoneNumber);
    request.setIsAuction(isAuction);
    await client.changeVehicleOwnership(request, authHeader);
    window.location.reload();
  };

  return { changeOwnership };
};
