// source: build/protos/mlapiv1entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_entities_pb = require('../../build/protos/entities_pb.js');
goog.object.extend(proto, build_protos_entities_pb);
var build_protos_vehicle_pb = require('../../build/protos/vehicle_pb.js');
goog.object.extend(proto, build_protos_vehicle_pb);
goog.exportSymbol('proto.entity.mlapiv1entity.AdditionalDocument', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.AdditionalDocumentList', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.Advertisement', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.AuctionGroup', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.BasicVehicle', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.Bid', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.Bids', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarInspectionAnswer', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarInspectionAnswers', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarInspectionQuestion', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarInspectionQuestions', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarInspectionSession', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarInspectionSessions', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.CarfaxAnnouncement', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.InspectionStatus', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.LeaseInformation', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.MileageUOM', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.QuestionOption', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.QuestionType', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.ReservePrice', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.TradeInInfo', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.Vehicle', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleCarFaxInfo', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleClass', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleHighlight', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleImage', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleLocation', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleOption', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehiclePaveAnnouncement', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehiclePaveDisclosure', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VehicleStatus', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.Vehicles', null, global);
goog.exportSymbol('proto.entity.mlapiv1entity.VinLookup', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarInspectionQuestions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.CarInspectionQuestions.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarInspectionQuestions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarInspectionQuestions.displayName = 'proto.entity.mlapiv1entity.CarInspectionQuestions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarInspectionQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.CarInspectionQuestion.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarInspectionQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarInspectionQuestion.displayName = 'proto.entity.mlapiv1entity.CarInspectionQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.QuestionOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.QuestionOption.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.QuestionOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.QuestionOption.displayName = 'proto.entity.mlapiv1entity.QuestionOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarInspectionAnswers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.CarInspectionAnswers.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarInspectionAnswers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarInspectionAnswers.displayName = 'proto.entity.mlapiv1entity.CarInspectionAnswers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarInspectionAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarInspectionAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarInspectionAnswer.displayName = 'proto.entity.mlapiv1entity.CarInspectionAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VehicleLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VehicleLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VehicleLocation.displayName = 'proto.entity.mlapiv1entity.VehicleLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VehiclePaveDisclosure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VehiclePaveDisclosure.displayName = 'proto.entity.mlapiv1entity.VehiclePaveDisclosure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VehiclePaveAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VehiclePaveAnnouncement.displayName = 'proto.entity.mlapiv1entity.VehiclePaveAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VehicleCarFaxInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VehicleCarFaxInfo.displayName = 'proto.entity.mlapiv1entity.VehicleCarFaxInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VehicleOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VehicleOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VehicleOption.displayName = 'proto.entity.mlapiv1entity.VehicleOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.Vehicles.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.Vehicles.displayName = 'proto.entity.mlapiv1entity.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VehicleImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VehicleImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VehicleImage.displayName = 'proto.entity.mlapiv1entity.VehicleImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.BasicVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.BasicVehicle.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.BasicVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.BasicVehicle.displayName = 'proto.entity.mlapiv1entity.BasicVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.Vehicle.displayName = 'proto.entity.mlapiv1entity.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.LeaseInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.LeaseInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.LeaseInformation.displayName = 'proto.entity.mlapiv1entity.LeaseInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarfaxAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarfaxAnnouncement.displayName = 'proto.entity.mlapiv1entity.CarfaxAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.Bid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.Bid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.Bid.displayName = 'proto.entity.mlapiv1entity.Bid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.Bids = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.Bids.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.Bids, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.Bids.displayName = 'proto.entity.mlapiv1entity.Bids';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.AuctionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.AuctionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.AuctionGroup.displayName = 'proto.entity.mlapiv1entity.AuctionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarInspectionSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarInspectionSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarInspectionSession.displayName = 'proto.entity.mlapiv1entity.CarInspectionSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.CarInspectionSessions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.CarInspectionSessions.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.CarInspectionSessions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.CarInspectionSessions.displayName = 'proto.entity.mlapiv1entity.CarInspectionSessions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.AdditionalDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.AdditionalDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.AdditionalDocument.displayName = 'proto.entity.mlapiv1entity.AdditionalDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.AdditionalDocumentList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.AdditionalDocumentList.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.AdditionalDocumentList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.AdditionalDocumentList.displayName = 'proto.entity.mlapiv1entity.AdditionalDocumentList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.Advertisement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.mlapiv1entity.Advertisement.repeatedFields_, null);
};
goog.inherits(proto.entity.mlapiv1entity.Advertisement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.Advertisement.displayName = 'proto.entity.mlapiv1entity.Advertisement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.TradeInInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.TradeInInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.TradeInInfo.displayName = 'proto.entity.mlapiv1entity.TradeInInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.VinLookup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.VinLookup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.VinLookup.displayName = 'proto.entity.mlapiv1entity.VinLookup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.mlapiv1entity.ReservePrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.mlapiv1entity.ReservePrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.mlapiv1entity.ReservePrice.displayName = 'proto.entity.mlapiv1entity.ReservePrice';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarInspectionQuestions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionQuestionsList: jspb.Message.toObjectList(msg.getCarInspectionQuestionsList(),
    proto.entity.mlapiv1entity.CarInspectionQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestions}
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarInspectionQuestions;
  return proto.entity.mlapiv1entity.CarInspectionQuestions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestions}
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.mlapiv1entity.CarInspectionQuestion;
      reader.readMessage(value,proto.entity.mlapiv1entity.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.addCarInspectionQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarInspectionQuestions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.mlapiv1entity.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionQuestion car_inspection_questions = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.CarInspectionQuestion>}
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.prototype.getCarInspectionQuestionsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.CarInspectionQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.CarInspectionQuestion, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.CarInspectionQuestion>} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestions} returns this
*/
proto.entity.mlapiv1entity.CarInspectionQuestions.prototype.setCarInspectionQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion}
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.prototype.addCarInspectionQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.CarInspectionQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestions} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestions.prototype.clearCarInspectionQuestionsList = function() {
  return this.setCarInspectionQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarInspectionQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionSessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.entity.mlapiv1entity.QuestionOption.toObject, includeInstance),
    category: jspb.Message.getFieldWithDefault(msg, 5, ""),
    carInspectionAnswersList: jspb.Message.toObjectList(msg.getCarInspectionAnswersList(),
    proto.entity.mlapiv1entity.CarInspectionAnswer.toObject, includeInstance),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    questionType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarInspectionQuestion;
  return proto.entity.mlapiv1entity.CarInspectionQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarInspectionSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = new proto.entity.mlapiv1entity.QuestionOption;
      reader.readMessage(value,proto.entity.mlapiv1entity.QuestionOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 6:
      var value = new proto.entity.mlapiv1entity.CarInspectionAnswer;
      reader.readMessage(value,proto.entity.mlapiv1entity.CarInspectionAnswer.deserializeBinaryFromReader);
      msg.addCarInspectionAnswers(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.mlapiv1entity.QuestionType} */ (reader.readEnum());
      msg.setQuestionType(value);
      break;
    case 9:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarInspectionQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.entity.mlapiv1entity.QuestionOption.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCarInspectionAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.entity.mlapiv1entity.CarInspectionAnswer.serializeBinaryToWriter
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getQuestionType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string car_inspection_session_id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getCarInspectionSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setCarInspectionSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string question_id = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated QuestionOption options = 4;
 * @return {!Array<!proto.entity.mlapiv1entity.QuestionOption>}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.QuestionOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.QuestionOption, 4));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.QuestionOption>} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
*/
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.QuestionOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.QuestionOption}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.entity.mlapiv1entity.QuestionOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CarInspectionAnswer car_inspection_answers = 6;
 * @return {!Array<!proto.entity.mlapiv1entity.CarInspectionAnswer>}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getCarInspectionAnswersList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.CarInspectionAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.CarInspectionAnswer, 6));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.CarInspectionAnswer>} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
*/
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setCarInspectionAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.addCarInspectionAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.entity.mlapiv1entity.CarInspectionAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.clearCarInspectionAnswersList = function() {
  return this.setCarInspectionAnswersList([]);
};


/**
 * optional bool is_required = 7;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional QuestionType question_type = 8;
 * @return {!proto.entity.mlapiv1entity.QuestionType}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getQuestionType = function() {
  return /** @type {!proto.entity.mlapiv1entity.QuestionType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.mlapiv1entity.QuestionType} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 9;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 9));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
*/
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion} returns this
 */
proto.entity.mlapiv1entity.CarInspectionQuestion.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.QuestionOption.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.QuestionOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.QuestionOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.QuestionOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    option: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasDetailField: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optionType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    followUpQuestionCategoriesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    disabledByOptionId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    inspectionQuestionId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.QuestionOption}
 */
proto.entity.mlapiv1entity.QuestionOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.QuestionOption;
  return proto.entity.mlapiv1entity.QuestionOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.QuestionOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.QuestionOption}
 */
proto.entity.mlapiv1entity.QuestionOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOption(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDetailField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOptionOrder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addFollowUpQuestionCategories(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledByOptionId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.QuestionOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.QuestionOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.QuestionOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasDetailField();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptionType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFollowUpQuestionCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getDisabledByOptionId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInspectionQuestionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string option = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setOption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_detail_field = 2;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getHasDetailField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setHasDetailField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 option_order = 4;
 * @return {number}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getOptionOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setOptionOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_required = 5;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string option_type = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getOptionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setOptionType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string follow_up_question_categories = 9;
 * @return {!Array<string>}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getFollowUpQuestionCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setFollowUpQuestionCategoriesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.addFollowUpQuestionCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.clearFollowUpQuestionCategoriesList = function() {
  return this.setFollowUpQuestionCategoriesList([]);
};


/**
 * optional string disabled_by_option_id = 10;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getDisabledByOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setDisabledByOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string inspection_question_id = 11;
 * @return {string}
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.getInspectionQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.QuestionOption} returns this
 */
proto.entity.mlapiv1entity.QuestionOption.prototype.setInspectionQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarInspectionAnswers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionAnswersList: jspb.Message.toObjectList(msg.getCarInspectionAnswersList(),
    proto.entity.mlapiv1entity.CarInspectionAnswer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswers}
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarInspectionAnswers;
  return proto.entity.mlapiv1entity.CarInspectionAnswers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswers}
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.mlapiv1entity.CarInspectionAnswer;
      reader.readMessage(value,proto.entity.mlapiv1entity.CarInspectionAnswer.deserializeBinaryFromReader);
      msg.addCarInspectionAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarInspectionAnswers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.mlapiv1entity.CarInspectionAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionAnswer car_inspection_answers = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.CarInspectionAnswer>}
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.prototype.getCarInspectionAnswersList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.CarInspectionAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.CarInspectionAnswer, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.CarInspectionAnswer>} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswers} returns this
*/
proto.entity.mlapiv1entity.CarInspectionAnswers.prototype.setCarInspectionAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer}
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.prototype.addCarInspectionAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.CarInspectionAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswers} returns this
 */
proto.entity.mlapiv1entity.CarInspectionAnswers.prototype.clearCarInspectionAnswersList = function() {
  return this.setCarInspectionAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarInspectionAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionOptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    questionId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarInspectionAnswer;
  return proto.entity.mlapiv1entity.CarInspectionAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionOptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarInspectionAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarInspectionAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionOptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer} returns this
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer} returns this
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question_option_id = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.getQuestionOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer} returns this
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.setQuestionOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string question_id = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionAnswer} returns this
 */
proto.entity.mlapiv1entity.CarInspectionAnswer.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VehicleLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VehicleLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VehicleLocation}
 */
proto.entity.mlapiv1entity.VehicleLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VehicleLocation;
  return proto.entity.mlapiv1entity.VehicleLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VehicleLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VehicleLocation}
 */
proto.entity.mlapiv1entity.VehicleLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VehicleLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VehicleLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double longitude = 8;
 * @return {number}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double latitude = 9;
 * @return {number}
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VehicleLocation} returns this
 */
proto.entity.mlapiv1entity.VehicleLocation.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VehiclePaveDisclosure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.toObject = function(includeInstance, msg) {
  var f, obj = {
    outOfProvince: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    odomFaulty: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    accidentRepair: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    noFactoryWarranty: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasExtendedWarranty: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasProtectionPackages: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    previousRentalLeaseTaxiEmergency: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VehiclePaveDisclosure;
  return proto.entity.mlapiv1entity.VehiclePaveDisclosure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutOfProvince(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOdomFaulty(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccidentRepair(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoFactoryWarranty(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasExtendedWarranty(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasProtectionPackages(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreviousRentalLeaseTaxiEmergency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VehiclePaveDisclosure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutOfProvince();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOdomFaulty();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAccidentRepair();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getNoFactoryWarranty();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasExtendedWarranty();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasProtectionPackages();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPreviousRentalLeaseTaxiEmergency();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional bool out_of_province = 1;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getOutOfProvince = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setOutOfProvince = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool odom_faulty = 2;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getOdomFaulty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setOdomFaulty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool accident_repair = 3;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getAccidentRepair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setAccidentRepair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool no_factory_warranty = 4;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getNoFactoryWarranty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setNoFactoryWarranty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_extended_warranty = 5;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getHasExtendedWarranty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setHasExtendedWarranty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_protection_packages = 6;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getHasProtectionPackages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setHasProtectionPackages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool previous_rental_lease_taxi_emergency = 7;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.getPreviousRentalLeaseTaxiEmergency = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveDisclosure} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveDisclosure.prototype.setPreviousRentalLeaseTaxiEmergency = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VehiclePaveAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    titlePresent: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    allKeysPresent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    booksPresent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    discsPresent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    rimsTires: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    otherAnnouncements: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VehiclePaveAnnouncement;
  return proto.entity.mlapiv1entity.VehiclePaveAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitlePresent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllKeysPresent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBooksPresent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDiscsPresent(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRimsTires(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherAnnouncements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VehiclePaveAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitlePresent();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllKeysPresent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBooksPresent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDiscsPresent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRimsTires();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOtherAnnouncements();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool title_present = 1;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.getTitlePresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.setTitlePresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string all_keys_present = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.getAllKeysPresent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.setAllKeysPresent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool books_present = 3;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.getBooksPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.setBooksPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool discs_present = 4;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.getDiscsPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.setDiscsPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool rims_tires = 5;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.getRimsTires = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.setRimsTires = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string other_announcements = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.getOtherAnnouncements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehiclePaveAnnouncement} returns this
 */
proto.entity.mlapiv1entity.VehiclePaveAnnouncement.prototype.setOtherAnnouncements = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VehicleCarFaxInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isStolen: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isSalvage: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hasUsaHistory: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasFrameDamage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasOpenRecalls: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    recentVhrOrder: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hasRebuiltTitle: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hasBcRegistration: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    hasDeployedAirbag: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isExportedVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isInactiveVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    potentialVinClone: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    serviceLast60Days: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    inspectionRequired: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasFireOrFloodDamage: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    firstRegistrationDate: jspb.Message.getFieldWithDefault(msg, 16, ""),
    latestRegistrationDate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    sourceSystemReferenceId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    hasInterprovincialHistory: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    hasPoliceReportedAccident: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hasPotentialOdometerProblem: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    currentRegistrationJurisdiction: jspb.Message.getFieldWithDefault(msg, 22, ""),
    hasMultipleCurrentRegistrations: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    ownershipType: jspb.Message.getFieldWithDefault(msg, 24, ""),
    latestDamageDate: jspb.Message.getFieldWithDefault(msg, 25, ""),
    latestOdometerKm: jspb.Message.getFieldWithDefault(msg, 26, 0),
    latestOdometerReadingDate: jspb.Message.getFieldWithDefault(msg, 27, ""),
    hasDamageOverThreshold: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    numberOfReportedOwners: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VehicleCarFaxInfo;
  return proto.entity.mlapiv1entity.VehicleCarFaxInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStolen(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSalvage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUsaHistory(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFrameDamage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOpenRecalls(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecentVhrOrder(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRebuiltTitle(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBcRegistration(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeployedAirbag(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExportedVehicle(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInactiveVehicle(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPotentialVinClone(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServiceLast60Days(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionRequired(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFireOrFloodDamage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstRegistrationDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestRegistrationDate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceSystemReferenceId(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInterprovincialHistory(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPoliceReportedAccident(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPotentialOdometerProblem(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentRegistrationJurisdiction(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMultipleCurrentRegistrations(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnershipType(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestDamageDate(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestOdometerKm(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestOdometerReadingDate(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDamageOverThreshold(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfReportedOwners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VehicleCarFaxInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsStolen();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsSalvage();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHasUsaHistory();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasFrameDamage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasOpenRecalls();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRecentVhrOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasRebuiltTitle();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHasBcRegistration();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHasDeployedAirbag();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsExportedVehicle();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsInactiveVehicle();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPotentialVinClone();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getServiceLast60Days();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getInspectionRequired();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasFireOrFloodDamage();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getFirstRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLatestRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSourceSystemReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getHasInterprovincialHistory();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getHasPoliceReportedAccident();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHasPotentialOdometerProblem();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCurrentRegistrationJurisdiction();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getHasMultipleCurrentRegistrations();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getOwnershipType();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getLatestDamageDate();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLatestOdometerKm();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getLatestOdometerReadingDate();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getHasDamageOverThreshold();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getNumberOfReportedOwners();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
};


/**
 * optional bool is_stolen = 1;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getIsStolen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setIsStolen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_salvage = 2;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getIsSalvage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setIsSalvage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool has_usa_history = 3;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasUsaHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasUsaHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_frame_damage = 4;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasFrameDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasFrameDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_open_recalls = 5;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasOpenRecalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasOpenRecalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool recent_vhr_order = 6;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getRecentVhrOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setRecentVhrOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool has_rebuilt_title = 7;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasRebuiltTitle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasRebuiltTitle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool has_bc_registration = 8;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasBcRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasBcRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool has_deployed_airbag = 9;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasDeployedAirbag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasDeployedAirbag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_exported_vehicle = 10;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getIsExportedVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setIsExportedVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_inactive_vehicle = 11;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getIsInactiveVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setIsInactiveVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool potential_vin_clone = 12;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getPotentialVinClone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setPotentialVinClone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool service_last_60_days = 13;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getServiceLast60Days = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setServiceLast60Days = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool inspection_required = 14;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getInspectionRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setInspectionRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool has_fire_or_flood_damage = 15;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasFireOrFloodDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasFireOrFloodDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string first_registration_date = 16;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getFirstRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setFirstRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string latest_registration_date = 17;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getLatestRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setLatestRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 source_system_reference_id = 18;
 * @return {number}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getSourceSystemReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setSourceSystemReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool has_interprovincial_history = 19;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasInterprovincialHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasInterprovincialHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool has_police_reported_accident = 20;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasPoliceReportedAccident = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasPoliceReportedAccident = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool has_potential_odometer_problem = 21;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasPotentialOdometerProblem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasPotentialOdometerProblem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string current_registration_jurisdiction = 22;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getCurrentRegistrationJurisdiction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setCurrentRegistrationJurisdiction = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool has_multiple_current_registrations = 23;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasMultipleCurrentRegistrations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasMultipleCurrentRegistrations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional string ownership_type = 24;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getOwnershipType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setOwnershipType = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string latest_damage_date = 25;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getLatestDamageDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setLatestDamageDate = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int64 latest_odometer_km = 26;
 * @return {number}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getLatestOdometerKm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setLatestOdometerKm = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string latest_odometer_reading_date = 27;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getLatestOdometerReadingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setLatestOdometerReadingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional bool has_damage_over_threshold = 28;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getHasDamageOverThreshold = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setHasDamageOverThreshold = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional int32 number_of_reported_owners = 29;
 * @return {number}
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.getNumberOfReportedOwners = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VehicleCarFaxInfo} returns this
 */
proto.entity.mlapiv1entity.VehicleCarFaxInfo.prototype.setNumberOfReportedOwners = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VehicleOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VehicleOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    highValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VehicleOption}
 */
proto.entity.mlapiv1entity.VehicleOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VehicleOption;
  return proto.entity.mlapiv1entity.VehicleOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VehicleOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VehicleOption}
 */
proto.entity.mlapiv1entity.VehicleOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHighValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VehicleOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VehicleOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHighValue();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleOption} returns this
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleOption} returns this
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleOption} returns this
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool high_value = 4;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.getHighValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleOption} returns this
 */
proto.entity.mlapiv1entity.VehicleOption.prototype.setHighValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.Vehicles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.entity.mlapiv1entity.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.Vehicles}
 */
proto.entity.mlapiv1entity.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.Vehicles;
  return proto.entity.mlapiv1entity.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.Vehicles}
 */
proto.entity.mlapiv1entity.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.mlapiv1entity.Vehicle;
      reader.readMessage(value,proto.entity.mlapiv1entity.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.mlapiv1entity.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vehicle vehicles = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.Vehicle>}
 */
proto.entity.mlapiv1entity.Vehicles.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.Vehicle, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.Vehicle>} value
 * @return {!proto.entity.mlapiv1entity.Vehicles} returns this
*/
proto.entity.mlapiv1entity.Vehicles.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.Vehicle}
 */
proto.entity.mlapiv1entity.Vehicles.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicles} returns this
 */
proto.entity.mlapiv1entity.Vehicles.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VehicleImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VehicleImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mlImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    label: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VehicleImage}
 */
proto.entity.mlapiv1entity.VehicleImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VehicleImage;
  return proto.entity.mlapiv1entity.VehicleImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VehicleImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VehicleImage}
 */
proto.entity.mlapiv1entity.VehicleImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.ImageSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VehicleImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VehicleImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VehicleImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMlImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string original_url = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.getOriginalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleImage} returns this
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.setOriginalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ml_image_url = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.getMlImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleImage} returns this
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.setMlImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VehicleImage} returns this
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool processed = 4;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.VehicleImage} returns this
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional entity.enum.ImageSource source = 5;
 * @return {!proto.entity.enum.ImageSource}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.getSource = function() {
  return /** @type {!proto.entity.enum.ImageSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.ImageSource} value
 * @return {!proto.entity.mlapiv1entity.VehicleImage} returns this
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VehicleImage} returns this
 */
proto.entity.mlapiv1entity.VehicleImage.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.BasicVehicle.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.BasicVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.BasicVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.BasicVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    make: jspb.Message.getFieldWithDefault(msg, 6, ""),
    model: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mileage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.entity.mlapiv1entity.VehicleImage.toObject, includeInstance),
    userId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    location: (f = msg.getLocation()) && build_protos_entities_pb.Location.toObject(includeInstance, f),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 13, 0),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 14, 0),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.BasicVehicle}
 */
proto.entity.mlapiv1entity.BasicVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.BasicVehicle;
  return proto.entity.mlapiv1entity.BasicVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.BasicVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.BasicVehicle}
 */
proto.entity.mlapiv1entity.BasicVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.mlapiv1entity.VehicleStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 10:
      var value = new proto.entity.mlapiv1entity.VehicleImage;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehicleImage.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 12:
      var value = new build_protos_entities_pb.Location;
      reader.readMessage(value,build_protos_entities_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 13:
      var value = /** @type {!proto.entity.enum.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 14:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.BasicVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.BasicVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.BasicVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.entity.mlapiv1entity.VehicleImage.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      build_protos_entities_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string make = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string model = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional VehicleStatus status = 8;
 * @return {!proto.entity.mlapiv1entity.VehicleStatus}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getStatus = function() {
  return /** @type {!proto.entity.mlapiv1entity.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.mlapiv1entity.VehicleStatus} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 mileage = 9;
 * @return {number}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated VehicleImage images = 10;
 * @return {!Array<!proto.entity.mlapiv1entity.VehicleImage>}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.VehicleImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.VehicleImage, 10));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.VehicleImage>} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
*/
proto.entity.mlapiv1entity.BasicVehicle.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.VehicleImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.VehicleImage}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.entity.mlapiv1entity.VehicleImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string user_id = 11;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional entity.entities.Location location = 12;
 * @return {?proto.entity.entities.Location}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getLocation = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Location, 12));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
*/
proto.entity.mlapiv1entity.BasicVehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional entity.enum.MileageUOM mileage_uom = 13;
 * @return {!proto.entity.enum.MileageUOM}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.enum.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.entity.enum.MileageUOM} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 14;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string trim_level = 15;
 * @return {string}
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.BasicVehicle} returns this
 */
proto.entity.mlapiv1entity.BasicVehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.Vehicle.repeatedFields_ = [22,26,34,35,36,44,47,50,57,60,62];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    make: jspb.Message.getFieldWithDefault(msg, 6, ""),
    model: jspb.Message.getFieldWithDefault(msg, 7, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mileage: jspb.Message.getFieldWithDefault(msg, 10, 0),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    interiorType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 13, ""),
    carfaxUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    engine: jspb.Message.getFieldWithDefault(msg, 15, ""),
    engineSize: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fuelType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 18, ""),
    driveTrain: jspb.Message.getFieldWithDefault(msg, 19, ""),
    inspectionId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    paveReportUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    vehicleOptionsList: jspb.Message.toObjectList(msg.getVehicleOptionsList(),
    proto.entity.mlapiv1entity.VehicleOption.toObject, includeInstance),
    location: (f = msg.getLocation()) && proto.entity.mlapiv1entity.VehicleLocation.toObject(includeInstance, f),
    vehicleCarfaxInfo: (f = msg.getVehicleCarfaxInfo()) && proto.entity.mlapiv1entity.VehicleCarFaxInfo.toObject(includeInstance, f),
    isLowMileage: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.entity.mlapiv1entity.VehicleImage.toObject, includeInstance),
    mlInspectionUrl: jspb.Message.getFieldWithDefault(msg, 27, ""),
    vehicleClass: jspb.Message.getFieldWithDefault(msg, 28, 0),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    autoGradeRating: jspb.Message.getFieldWithDefault(msg, 30, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 31, 0),
    vehiclePaveDisclosure: (f = msg.getVehiclePaveDisclosure()) && proto.entity.mlapiv1entity.VehiclePaveDisclosure.toObject(includeInstance, f),
    vehiclePaveAnnouncement: (f = msg.getVehiclePaveAnnouncement()) && proto.entity.mlapiv1entity.VehiclePaveAnnouncement.toObject(includeInstance, f),
    carInspectionQuestionsList: jspb.Message.toObjectList(msg.getCarInspectionQuestionsList(),
    proto.entity.mlapiv1entity.CarInspectionQuestion.toObject, includeInstance),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.entity.mlapiv1entity.Bid.toObject, includeInstance),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.entity.mlapiv1entity.AuctionGroup.toObject, includeInstance),
    paveSession: jspb.Message.getFieldWithDefault(msg, 37, ""),
    paveReportHtml: jspb.Message.getFieldWithDefault(msg, 38, ""),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 39, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 40, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 41, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 42, ""),
    carfaxLienUrl: jspb.Message.getFieldWithDefault(msg, 43, ""),
    carfaxAnnouncementsList: jspb.Message.toObjectList(msg.getCarfaxAnnouncementsList(),
    proto.entity.mlapiv1entity.CarfaxAnnouncement.toObject, includeInstance),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 45, 0),
    leaseInformation: (f = msg.getLeaseInformation()) && proto.entity.mlapiv1entity.LeaseInformation.toObject(includeInstance, f),
    priceHistoryList: jspb.Message.toObjectList(msg.getPriceHistoryList(),
    build_protos_vehicle_pb.PriceHistory.toObject, includeInstance),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 49, 0),
    salesList: jspb.Message.toObjectList(msg.getSalesList(),
    build_protos_vehicle_pb.Sale.toObject, includeInstance),
    dealerId: jspb.Message.getFieldWithDefault(msg, 51, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
    doors: jspb.Message.getFieldWithDefault(msg, 53, 0),
    bodyStyle: jspb.Message.getFieldWithDefault(msg, 54, ""),
    certified: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
    vehicleCondition: jspb.Message.getFieldWithDefault(msg, 56, ""),
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    build_protos_entities_pb.Offer.toObject, includeInstance),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 58, false),
    stockNumber: jspb.Message.getFieldWithDefault(msg, 59, ""),
    registeredOwnersList: jspb.Message.toObjectList(msg.getRegisteredOwnersList(),
    build_protos_entities_pb.RegisteredOwner.toObject, includeInstance),
    registrationAddress: (f = msg.getRegistrationAddress()) && build_protos_entities_pb.Location.toObject(includeInstance, f),
    declarationQuestionsList: jspb.Message.toObjectList(msg.getDeclarationQuestionsList(),
    build_protos_entities_pb.DeclarationQuestion.toObject, includeInstance),
    isGloveboxComplete: jspb.Message.getBooleanFieldWithDefault(msg, 63, false),
    uvc: jspb.Message.getFieldWithDefault(msg, 64, ""),
    vehicleSource: jspb.Message.getFieldWithDefault(msg, 65, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.Vehicle}
 */
proto.entity.mlapiv1entity.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.Vehicle;
  return proto.entity.mlapiv1entity.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.Vehicle}
 */
proto.entity.mlapiv1entity.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.mlapiv1entity.VehicleStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngine(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngineSize(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFuelType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveTrain(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveReportUrl(value);
      break;
    case 22:
      var value = new proto.entity.mlapiv1entity.VehicleOption;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehicleOption.deserializeBinaryFromReader);
      msg.addVehicleOptions(value);
      break;
    case 23:
      var value = new proto.entity.mlapiv1entity.VehicleLocation;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehicleLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 24:
      var value = new proto.entity.mlapiv1entity.VehicleCarFaxInfo;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehicleCarFaxInfo.deserializeBinaryFromReader);
      msg.setVehicleCarfaxInfo(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLowMileage(value);
      break;
    case 26:
      var value = new proto.entity.mlapiv1entity.VehicleImage;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehicleImage.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlInspectionUrl(value);
      break;
    case 28:
      var value = /** @type {!proto.entity.mlapiv1entity.VehicleClass} */ (reader.readEnum());
      msg.setVehicleClass(value);
      break;
    case 29:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGradeRating(value);
      break;
    case 31:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 32:
      var value = new proto.entity.mlapiv1entity.VehiclePaveDisclosure;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehiclePaveDisclosure.deserializeBinaryFromReader);
      msg.setVehiclePaveDisclosure(value);
      break;
    case 33:
      var value = new proto.entity.mlapiv1entity.VehiclePaveAnnouncement;
      reader.readMessage(value,proto.entity.mlapiv1entity.VehiclePaveAnnouncement.deserializeBinaryFromReader);
      msg.setVehiclePaveAnnouncement(value);
      break;
    case 34:
      var value = new proto.entity.mlapiv1entity.CarInspectionQuestion;
      reader.readMessage(value,proto.entity.mlapiv1entity.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.addCarInspectionQuestions(value);
      break;
    case 35:
      var value = new proto.entity.mlapiv1entity.Bid;
      reader.readMessage(value,proto.entity.mlapiv1entity.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 36:
      var value = new proto.entity.mlapiv1entity.AuctionGroup;
      reader.readMessage(value,proto.entity.mlapiv1entity.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveSession(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveReportHtml(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxLienUrl(value);
      break;
    case 44:
      var value = new proto.entity.mlapiv1entity.CarfaxAnnouncement;
      reader.readMessage(value,proto.entity.mlapiv1entity.CarfaxAnnouncement.deserializeBinaryFromReader);
      msg.addCarfaxAnnouncements(value);
      break;
    case 45:
      var value = /** @type {!proto.entity.enum.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 46:
      var value = new proto.entity.mlapiv1entity.LeaseInformation;
      reader.readMessage(value,proto.entity.mlapiv1entity.LeaseInformation.deserializeBinaryFromReader);
      msg.setLeaseInformation(value);
      break;
    case 47:
      var value = new build_protos_vehicle_pb.PriceHistory;
      reader.readMessage(value,build_protos_vehicle_pb.PriceHistory.deserializeBinaryFromReader);
      msg.addPriceHistory(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 49:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 50:
      var value = new build_protos_vehicle_pb.Sale;
      reader.readMessage(value,build_protos_vehicle_pb.Sale.deserializeBinaryFromReader);
      msg.addSales(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDoors(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setBodyStyle(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCertified(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleCondition(value);
      break;
    case 57:
      var value = new build_protos_entities_pb.Offer;
      reader.readMessage(value,build_protos_entities_pb.Offer.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockNumber(value);
      break;
    case 60:
      var value = new build_protos_entities_pb.RegisteredOwner;
      reader.readMessage(value,build_protos_entities_pb.RegisteredOwner.deserializeBinaryFromReader);
      msg.addRegisteredOwners(value);
      break;
    case 61:
      var value = new build_protos_entities_pb.Location;
      reader.readMessage(value,build_protos_entities_pb.Location.deserializeBinaryFromReader);
      msg.setRegistrationAddress(value);
      break;
    case 62:
      var value = new build_protos_entities_pb.DeclarationQuestion;
      reader.readMessage(value,build_protos_entities_pb.DeclarationQuestion.deserializeBinaryFromReader);
      msg.addDeclarationQuestions(value);
      break;
    case 63:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGloveboxComplete(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setUvc(value);
      break;
    case 65:
      var value = /** @type {!proto.entity.enum.VehicleSource} */ (reader.readEnum());
      msg.setVehicleSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInteriorType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCarfaxUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEngine();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getEngineSize();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFuelType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDriveTrain();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPaveReportUrl();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getVehicleOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.entity.mlapiv1entity.VehicleOption.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.entity.mlapiv1entity.VehicleLocation.serializeBinaryToWriter
    );
  }
  f = message.getVehicleCarfaxInfo();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.entity.mlapiv1entity.VehicleCarFaxInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsLowMileage();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.entity.mlapiv1entity.VehicleImage.serializeBinaryToWriter
    );
  }
  f = message.getMlInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAutoGradeRating();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getVehiclePaveDisclosure();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.entity.mlapiv1entity.VehiclePaveDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getVehiclePaveAnnouncement();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.entity.mlapiv1entity.VehiclePaveAnnouncement.serializeBinaryToWriter
    );
  }
  f = message.getCarInspectionQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      34,
      f,
      proto.entity.mlapiv1entity.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.entity.mlapiv1entity.Bid.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.entity.mlapiv1entity.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getPaveSession();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getPaveReportHtml();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getCarfaxLienUrl();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getCarfaxAnnouncementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.entity.mlapiv1entity.CarfaxAnnouncement.serializeBinaryToWriter
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      45,
      f
    );
  }
  f = message.getLeaseInformation();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.entity.mlapiv1entity.LeaseInformation.serializeBinaryToWriter
    );
  }
  f = message.getPriceHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      47,
      f,
      build_protos_vehicle_pb.PriceHistory.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      49,
      f
    );
  }
  f = message.getSalesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      50,
      f,
      build_protos_vehicle_pb.Sale.serializeBinaryToWriter
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getDoors();
  if (f !== 0) {
    writer.writeInt32(
      53,
      f
    );
  }
  f = message.getBodyStyle();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getCertified();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getVehicleCondition();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      57,
      f,
      build_protos_entities_pb.Offer.serializeBinaryToWriter
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getStockNumber();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getRegisteredOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      60,
      f,
      build_protos_entities_pb.RegisteredOwner.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationAddress();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      build_protos_entities_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getDeclarationQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      62,
      f,
      build_protos_entities_pb.DeclarationQuestion.serializeBinaryToWriter
    );
  }
  f = message.getIsGloveboxComplete();
  if (f) {
    writer.writeBool(
      63,
      f
    );
  }
  f = message.getUvc();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getVehicleSource();
  if (f !== 0.0) {
    writer.writeEnum(
      65,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string make = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string model = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 seats = 8;
 * @return {number}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional VehicleStatus status = 9;
 * @return {!proto.entity.mlapiv1entity.VehicleStatus}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getStatus = function() {
  return /** @type {!proto.entity.mlapiv1entity.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.mlapiv1entity.VehicleStatus} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 mileage = 10;
 * @return {number}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string interior_color = 11;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string interior_type = 12;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getInteriorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setInteriorType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string exterior_color = 13;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string carfax_url = 14;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getCarfaxUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setCarfaxUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string engine = 15;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getEngine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setEngine = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string engine_size = 16;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getEngineSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setEngineSize = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string fuel_type = 17;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getFuelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setFuelType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string transmission = 18;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string drive_train = 19;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getDriveTrain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setDriveTrain = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string inspection_id = 20;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string pave_report_url = 21;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getPaveReportUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setPaveReportUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated VehicleOption vehicle_options = 22;
 * @return {!Array<!proto.entity.mlapiv1entity.VehicleOption>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehicleOptionsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.VehicleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.VehicleOption, 22));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.VehicleOption>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setVehicleOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.VehicleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.VehicleOption}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addVehicleOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.entity.mlapiv1entity.VehicleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearVehicleOptionsList = function() {
  return this.setVehicleOptionsList([]);
};


/**
 * optional VehicleLocation location = 23;
 * @return {?proto.entity.mlapiv1entity.VehicleLocation}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getLocation = function() {
  return /** @type{?proto.entity.mlapiv1entity.VehicleLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.mlapiv1entity.VehicleLocation, 23));
};


/**
 * @param {?proto.entity.mlapiv1entity.VehicleLocation|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional VehicleCarFaxInfo vehicle_carfax_info = 24;
 * @return {?proto.entity.mlapiv1entity.VehicleCarFaxInfo}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehicleCarfaxInfo = function() {
  return /** @type{?proto.entity.mlapiv1entity.VehicleCarFaxInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.mlapiv1entity.VehicleCarFaxInfo, 24));
};


/**
 * @param {?proto.entity.mlapiv1entity.VehicleCarFaxInfo|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setVehicleCarfaxInfo = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearVehicleCarfaxInfo = function() {
  return this.setVehicleCarfaxInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasVehicleCarfaxInfo = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool is_low_mileage = 25;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getIsLowMileage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setIsLowMileage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * repeated VehicleImage images = 26;
 * @return {!Array<!proto.entity.mlapiv1entity.VehicleImage>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.VehicleImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.VehicleImage, 26));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.VehicleImage>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.VehicleImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.VehicleImage}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.entity.mlapiv1entity.VehicleImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string ml_inspection_url = 27;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getMlInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setMlInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional VehicleClass vehicle_class = 28;
 * @return {!proto.entity.mlapiv1entity.VehicleClass}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehicleClass = function() {
  return /** @type {!proto.entity.mlapiv1entity.VehicleClass} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.entity.mlapiv1entity.VehicleClass} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 29;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 29));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string auto_grade_rating = 30;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getAutoGradeRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setAutoGradeRating = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 31;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional VehiclePaveDisclosure vehicle_pave_disclosure = 32;
 * @return {?proto.entity.mlapiv1entity.VehiclePaveDisclosure}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehiclePaveDisclosure = function() {
  return /** @type{?proto.entity.mlapiv1entity.VehiclePaveDisclosure} */ (
    jspb.Message.getWrapperField(this, proto.entity.mlapiv1entity.VehiclePaveDisclosure, 32));
};


/**
 * @param {?proto.entity.mlapiv1entity.VehiclePaveDisclosure|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setVehiclePaveDisclosure = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearVehiclePaveDisclosure = function() {
  return this.setVehiclePaveDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasVehiclePaveDisclosure = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional VehiclePaveAnnouncement vehicle_pave_announcement = 33;
 * @return {?proto.entity.mlapiv1entity.VehiclePaveAnnouncement}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehiclePaveAnnouncement = function() {
  return /** @type{?proto.entity.mlapiv1entity.VehiclePaveAnnouncement} */ (
    jspb.Message.getWrapperField(this, proto.entity.mlapiv1entity.VehiclePaveAnnouncement, 33));
};


/**
 * @param {?proto.entity.mlapiv1entity.VehiclePaveAnnouncement|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setVehiclePaveAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearVehiclePaveAnnouncement = function() {
  return this.setVehiclePaveAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasVehiclePaveAnnouncement = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * repeated CarInspectionQuestion car_inspection_questions = 34;
 * @return {!Array<!proto.entity.mlapiv1entity.CarInspectionQuestion>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getCarInspectionQuestionsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.CarInspectionQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.CarInspectionQuestion, 34));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.CarInspectionQuestion>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setCarInspectionQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 34, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.CarInspectionQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.CarInspectionQuestion}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addCarInspectionQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 34, opt_value, proto.entity.mlapiv1entity.CarInspectionQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearCarInspectionQuestionsList = function() {
  return this.setCarInspectionQuestionsList([]);
};


/**
 * repeated Bid bids = 35;
 * @return {!Array<!proto.entity.mlapiv1entity.Bid>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.Bid, 35));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.Bid>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.Bid}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.entity.mlapiv1entity.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * repeated AuctionGroup auction_groups = 36;
 * @return {!Array<!proto.entity.mlapiv1entity.AuctionGroup>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.AuctionGroup, 36));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.AuctionGroup>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.AuctionGroup}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.entity.mlapiv1entity.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional string pave_session = 37;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getPaveSession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setPaveSession = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string pave_report_html = 38;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getPaveReportHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setPaveReportHtml = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string trim_level = 39;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string user_given_name = 40;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string user_phone_number = 41;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string user_id = 42;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string carfax_lien_url = 43;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getCarfaxLienUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setCarfaxLienUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * repeated CarfaxAnnouncement carfax_announcements = 44;
 * @return {!Array<!proto.entity.mlapiv1entity.CarfaxAnnouncement>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getCarfaxAnnouncementsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.CarfaxAnnouncement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.CarfaxAnnouncement, 44));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.CarfaxAnnouncement>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setCarfaxAnnouncementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.CarfaxAnnouncement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addCarfaxAnnouncements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.entity.mlapiv1entity.CarfaxAnnouncement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearCarfaxAnnouncementsList = function() {
  return this.setCarfaxAnnouncementsList([]);
};


/**
 * optional entity.enum.MileageUOM mileage_uom = 45;
 * @return {!proto.entity.enum.MileageUOM}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.enum.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {!proto.entity.enum.MileageUOM} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 45, value);
};


/**
 * optional LeaseInformation lease_information = 46;
 * @return {?proto.entity.mlapiv1entity.LeaseInformation}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getLeaseInformation = function() {
  return /** @type{?proto.entity.mlapiv1entity.LeaseInformation} */ (
    jspb.Message.getWrapperField(this, proto.entity.mlapiv1entity.LeaseInformation, 46));
};


/**
 * @param {?proto.entity.mlapiv1entity.LeaseInformation|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setLeaseInformation = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearLeaseInformation = function() {
  return this.setLeaseInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasLeaseInformation = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * repeated entity.vehicle.PriceHistory price_history = 47;
 * @return {!Array<!proto.entity.vehicle.PriceHistory>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getPriceHistoryList = function() {
  return /** @type{!Array<!proto.entity.vehicle.PriceHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_vehicle_pb.PriceHistory, 47));
};


/**
 * @param {!Array<!proto.entity.vehicle.PriceHistory>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setPriceHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 47, value);
};


/**
 * @param {!proto.entity.vehicle.PriceHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.PriceHistory}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addPriceHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 47, opt_value, proto.entity.vehicle.PriceHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearPriceHistoryList = function() {
  return this.setPriceHistoryList([]);
};


/**
 * optional double price = 48;
 * @return {number}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional entity.enum.Currency currency = 49;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 49, value);
};


/**
 * repeated entity.vehicle.Sale sales = 50;
 * @return {!Array<!proto.entity.vehicle.Sale>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getSalesList = function() {
  return /** @type{!Array<!proto.entity.vehicle.Sale>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_vehicle_pb.Sale, 50));
};


/**
 * @param {!Array<!proto.entity.vehicle.Sale>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setSalesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 50, value);
};


/**
 * @param {!proto.entity.vehicle.Sale=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.Sale}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addSales = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 50, opt_value, proto.entity.vehicle.Sale, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearSalesList = function() {
  return this.setSalesList([]);
};


/**
 * optional string dealer_id = 51;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional bool is_active = 52;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional int32 doors = 53;
 * @return {number}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getDoors = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setDoors = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * optional string body_style = 54;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getBodyStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setBodyStyle = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional bool certified = 55;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getCertified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setCertified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional string vehicle_condition = 56;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehicleCondition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setVehicleCondition = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * repeated entity.entities.Offer offers = 57;
 * @return {!Array<!proto.entity.entities.Offer>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.entity.entities.Offer>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_entities_pb.Offer, 57));
};


/**
 * @param {!Array<!proto.entity.entities.Offer>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 57, value);
};


/**
 * @param {!proto.entity.entities.Offer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Offer}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 57, opt_value, proto.entity.entities.Offer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};


/**
 * optional bool is_favourite = 58;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 58, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 58, value);
};


/**
 * optional string stock_number = 59;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getStockNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setStockNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * repeated entity.entities.RegisteredOwner registered_owners = 60;
 * @return {!Array<!proto.entity.entities.RegisteredOwner>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getRegisteredOwnersList = function() {
  return /** @type{!Array<!proto.entity.entities.RegisteredOwner>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_entities_pb.RegisteredOwner, 60));
};


/**
 * @param {!Array<!proto.entity.entities.RegisteredOwner>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setRegisteredOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 60, value);
};


/**
 * @param {!proto.entity.entities.RegisteredOwner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.RegisteredOwner}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addRegisteredOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 60, opt_value, proto.entity.entities.RegisteredOwner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearRegisteredOwnersList = function() {
  return this.setRegisteredOwnersList([]);
};


/**
 * optional entity.entities.Location registration_address = 61;
 * @return {?proto.entity.entities.Location}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getRegistrationAddress = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Location, 61));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setRegistrationAddress = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearRegistrationAddress = function() {
  return this.setRegistrationAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.hasRegistrationAddress = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * repeated entity.entities.DeclarationQuestion declaration_questions = 62;
 * @return {!Array<!proto.entity.entities.DeclarationQuestion>}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getDeclarationQuestionsList = function() {
  return /** @type{!Array<!proto.entity.entities.DeclarationQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_entities_pb.DeclarationQuestion, 62));
};


/**
 * @param {!Array<!proto.entity.entities.DeclarationQuestion>} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
*/
proto.entity.mlapiv1entity.Vehicle.prototype.setDeclarationQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 62, value);
};


/**
 * @param {!proto.entity.entities.DeclarationQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.DeclarationQuestion}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.addDeclarationQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 62, opt_value, proto.entity.entities.DeclarationQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.clearDeclarationQuestionsList = function() {
  return this.setDeclarationQuestionsList([]);
};


/**
 * optional bool is_glovebox_complete = 63;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getIsGloveboxComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 63, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setIsGloveboxComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 63, value);
};


/**
 * optional string uvc = 64;
 * @return {string}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getUvc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setUvc = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional entity.enum.VehicleSource vehicle_source = 65;
 * @return {!proto.entity.enum.VehicleSource}
 */
proto.entity.mlapiv1entity.Vehicle.prototype.getVehicleSource = function() {
  return /** @type {!proto.entity.enum.VehicleSource} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {!proto.entity.enum.VehicleSource} value
 * @return {!proto.entity.mlapiv1entity.Vehicle} returns this
 */
proto.entity.mlapiv1entity.Vehicle.prototype.setVehicleSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 65, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.LeaseInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.LeaseInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.LeaseInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    leaseProvider: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlyPayment: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    termRemaining: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.LeaseInformation}
 */
proto.entity.mlapiv1entity.LeaseInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.LeaseInformation;
  return proto.entity.mlapiv1entity.LeaseInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.LeaseInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.LeaseInformation}
 */
proto.entity.mlapiv1entity.LeaseInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaseProvider(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthlyPayment(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTermRemaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.LeaseInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.LeaseInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.LeaseInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeaseProvider();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTermRemaining();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string lease_provider = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.getLeaseProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.LeaseInformation} returns this
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.setLeaseProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double monthly_payment = 2;
 * @return {number}
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.getMonthlyPayment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.LeaseInformation} returns this
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.mlapiv1entity.LeaseInformation} returns this
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 term_remaining = 4;
 * @return {number}
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.getTermRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.LeaseInformation} returns this
 */
proto.entity.mlapiv1entity.LeaseInformation.prototype.setTermRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarfaxAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarfaxAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    amountSpecified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    dateSpecified: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarfaxAnnouncement;
  return proto.entity.mlapiv1entity.CarfaxAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarfaxAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmountSpecified(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDateSpecified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarfaxAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarfaxAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAmountSpecified();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDateSpecified();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement} returns this
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement} returns this
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement} returns this
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement} returns this
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool amount_specified = 5;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.getAmountSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement} returns this
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.setAmountSpecified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool date_specified = 6;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.getDateSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.CarfaxAnnouncement} returns this
 */
proto.entity.mlapiv1entity.CarfaxAnnouncement.prototype.setDateSpecified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.Bid.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.Bid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.Bid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Bid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.Bid}
 */
proto.entity.mlapiv1entity.Bid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.Bid;
  return proto.entity.mlapiv1entity.Bid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.Bid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.Bid}
 */
proto.entity.mlapiv1entity.Bid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.Bid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.Bid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.Bid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Bid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.Bid.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.mlapiv1entity.Bid.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.mlapiv1entity.Bid.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.Bid.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 5;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.mlapiv1entity.Bid.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 5));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
*/
proto.entity.mlapiv1entity.Bid.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Bid.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string auction_group_id = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.Bid.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.Bid.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dealer_name = 8;
 * @return {string}
 */
proto.entity.mlapiv1entity.Bid.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string organization_id = 9;
 * @return {string}
 */
proto.entity.mlapiv1entity.Bid.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Bid} returns this
 */
proto.entity.mlapiv1entity.Bid.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.Bids.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.Bids.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.Bids.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.Bids} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Bids.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.entity.mlapiv1entity.Bid.toObject, includeInstance),
    totalBids: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalBidders: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.Bids}
 */
proto.entity.mlapiv1entity.Bids.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.Bids;
  return proto.entity.mlapiv1entity.Bids.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.Bids} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.Bids}
 */
proto.entity.mlapiv1entity.Bids.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.mlapiv1entity.Bid;
      reader.readMessage(value,proto.entity.mlapiv1entity.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBids(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBidders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.Bids.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.Bids.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.Bids} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Bids.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.mlapiv1entity.Bid.serializeBinaryToWriter
    );
  }
  f = message.getTotalBids();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalBidders();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Bid bids = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.Bid>}
 */
proto.entity.mlapiv1entity.Bids.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.Bid, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.Bid>} value
 * @return {!proto.entity.mlapiv1entity.Bids} returns this
*/
proto.entity.mlapiv1entity.Bids.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.Bid}
 */
proto.entity.mlapiv1entity.Bids.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Bids} returns this
 */
proto.entity.mlapiv1entity.Bids.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional int64 total_bids = 2;
 * @return {number}
 */
proto.entity.mlapiv1entity.Bids.prototype.getTotalBids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Bids} returns this
 */
proto.entity.mlapiv1entity.Bids.prototype.setTotalBids = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_bidders = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.Bids.prototype.getTotalBidders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Bids} returns this
 */
proto.entity.mlapiv1entity.Bids.prototype.setTotalBidders = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.AuctionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.AuctionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.AuctionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auctionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vehicles: (f = msg.getVehicles()) && proto.entity.mlapiv1entity.Vehicles.toObject(includeInstance, f),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    region: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subRegion: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.AuctionGroup}
 */
proto.entity.mlapiv1entity.AuctionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.AuctionGroup;
  return proto.entity.mlapiv1entity.AuctionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.AuctionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.AuctionGroup}
 */
proto.entity.mlapiv1entity.AuctionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = new proto.entity.mlapiv1entity.Vehicles;
      reader.readMessage(value,proto.entity.mlapiv1entity.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.AuctionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.AuctionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.AuctionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.mlapiv1entity.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubRegion();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auction_id = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Vehicles vehicles = 5;
 * @return {?proto.entity.mlapiv1entity.Vehicles}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getVehicles = function() {
  return /** @type{?proto.entity.mlapiv1entity.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.entity.mlapiv1entity.Vehicles, 5));
};


/**
 * @param {?proto.entity.mlapiv1entity.Vehicles|undefined} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
*/
proto.entity.mlapiv1entity.AuctionGroup.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional entity.enum.AuctionStatus auction_status = 6;
 * @return {!proto.entity.enum.AuctionStatus}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getAuctionStatus = function() {
  return /** @type {!proto.entity.enum.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.AuctionStatus} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string region = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sub_region = 8;
 * @return {string}
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.getSubRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AuctionGroup} returns this
 */
proto.entity.mlapiv1entity.AuctionGroup.prototype.setSubRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarInspectionSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarInspectionSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inspectionUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paveSessionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    smsSent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession}
 */
proto.entity.mlapiv1entity.CarInspectionSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarInspectionSession;
  return proto.entity.mlapiv1entity.CarInspectionSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarInspectionSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession}
 */
proto.entity.mlapiv1entity.CarInspectionSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarInspectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveSessionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmsSent(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.mlapiv1entity.InspectionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarInspectionSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarInspectionSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaveSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSmsSent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string car_inspection_id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getCarInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setCarInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inspection_url = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pave_session_id = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getPaveSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setPaveSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sms_sent = 5;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getSmsSent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setSmsSent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional InspectionStatus status = 6;
 * @return {!proto.entity.mlapiv1entity.InspectionStatus}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getStatus = function() {
  return /** @type {!proto.entity.mlapiv1entity.InspectionStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.mlapiv1entity.InspectionStatus} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string vehicle_id = 8;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string redirect_url = 9;
 * @return {string}
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSession.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.CarInspectionSessions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.CarInspectionSessions.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.CarInspectionSessions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.CarInspectionSessions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionSessions.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionSessionList: jspb.Message.toObjectList(msg.getCarInspectionSessionList(),
    proto.entity.mlapiv1entity.CarInspectionSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.CarInspectionSessions}
 */
proto.entity.mlapiv1entity.CarInspectionSessions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.CarInspectionSessions;
  return proto.entity.mlapiv1entity.CarInspectionSessions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.CarInspectionSessions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.CarInspectionSessions}
 */
proto.entity.mlapiv1entity.CarInspectionSessions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.mlapiv1entity.CarInspectionSession;
      reader.readMessage(value,proto.entity.mlapiv1entity.CarInspectionSession.deserializeBinaryFromReader);
      msg.addCarInspectionSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.CarInspectionSessions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.CarInspectionSessions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.CarInspectionSessions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.CarInspectionSessions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionSessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.mlapiv1entity.CarInspectionSession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionSession car_inspection_session = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.CarInspectionSession>}
 */
proto.entity.mlapiv1entity.CarInspectionSessions.prototype.getCarInspectionSessionList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.CarInspectionSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.CarInspectionSession, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.CarInspectionSession>} value
 * @return {!proto.entity.mlapiv1entity.CarInspectionSessions} returns this
*/
proto.entity.mlapiv1entity.CarInspectionSessions.prototype.setCarInspectionSessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.CarInspectionSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.CarInspectionSession}
 */
proto.entity.mlapiv1entity.CarInspectionSessions.prototype.addCarInspectionSession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.CarInspectionSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.CarInspectionSessions} returns this
 */
proto.entity.mlapiv1entity.CarInspectionSessions.prototype.clearCarInspectionSessionList = function() {
  return this.setCarInspectionSessionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.AdditionalDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.AdditionalDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.AdditionalDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    originalFilename: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument}
 */
proto.entity.mlapiv1entity.AdditionalDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.AdditionalDocument;
  return proto.entity.mlapiv1entity.AdditionalDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.AdditionalDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument}
 */
proto.entity.mlapiv1entity.AdditionalDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.AdditionalDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.AdditionalDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.AdditionalDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOriginalFilename();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string original_filename = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.getOriginalFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocument.prototype.setOriginalFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.AdditionalDocumentList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.AdditionalDocumentList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.toObject = function(includeInstance, msg) {
  var f, obj = {
    additionalDocumentsList: jspb.Message.toObjectList(msg.getAdditionalDocumentsList(),
    proto.entity.mlapiv1entity.AdditionalDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.AdditionalDocumentList}
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.AdditionalDocumentList;
  return proto.entity.mlapiv1entity.AdditionalDocumentList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.AdditionalDocumentList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.AdditionalDocumentList}
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.mlapiv1entity.AdditionalDocument;
      reader.readMessage(value,proto.entity.mlapiv1entity.AdditionalDocument.deserializeBinaryFromReader);
      msg.addAdditionalDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.AdditionalDocumentList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.AdditionalDocumentList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdditionalDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.mlapiv1entity.AdditionalDocument.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdditionalDocument additional_documents = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.AdditionalDocument>}
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.prototype.getAdditionalDocumentsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.AdditionalDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.mlapiv1entity.AdditionalDocument, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.AdditionalDocument>} value
 * @return {!proto.entity.mlapiv1entity.AdditionalDocumentList} returns this
*/
proto.entity.mlapiv1entity.AdditionalDocumentList.prototype.setAdditionalDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.AdditionalDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.AdditionalDocument}
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.prototype.addAdditionalDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.AdditionalDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.AdditionalDocumentList} returns this
 */
proto.entity.mlapiv1entity.AdditionalDocumentList.prototype.clearAdditionalDocumentsList = function() {
  return this.setAdditionalDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.mlapiv1entity.Advertisement.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.Advertisement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.Advertisement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Advertisement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ctaLink: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ctaText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    criteriasList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    marketplaceId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    startTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.Advertisement}
 */
proto.entity.mlapiv1entity.Advertisement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.Advertisement;
  return proto.entity.mlapiv1entity.Advertisement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.Advertisement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.Advertisement}
 */
proto.entity.mlapiv1entity.Advertisement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtaLink(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtaText(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.entity.enum.AdvertisementCriteria>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCriterias(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketplaceId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.Advertisement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.Advertisement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.Advertisement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCtaLink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCtaText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCriteriasList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getMarketplaceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cta_link = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getCtaLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setCtaLink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cta_text = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getCtaText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setCtaText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated entity.enum.AdvertisementCriteria criterias = 7;
 * @return {!Array<!proto.entity.enum.AdvertisementCriteria>}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getCriteriasList = function() {
  return /** @type {!Array<!proto.entity.enum.AdvertisementCriteria>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.entity.enum.AdvertisementCriteria>} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setCriteriasList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.entity.enum.AdvertisementCriteria} value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.addCriterias = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.clearCriteriasList = function() {
  return this.setCriteriasList([]);
};


/**
 * optional string marketplace_id = 8;
 * @return {string}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getMarketplaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setMarketplaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_active = 9;
 * @return {boolean}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 start_time = 10;
 * @return {number}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 end_time = 11;
 * @return {number}
 */
proto.entity.mlapiv1entity.Advertisement.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.Advertisement} returns this
 */
proto.entity.mlapiv1entity.Advertisement.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.TradeInInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.TradeInInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.TradeInInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    make: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trim: jspb.Message.getFieldWithDefault(msg, 6, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.TradeInInfo}
 */
proto.entity.mlapiv1entity.TradeInInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.TradeInInfo;
  return proto.entity.mlapiv1entity.TradeInInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.TradeInInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.TradeInInfo}
 */
proto.entity.mlapiv1entity.TradeInInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.TradeInInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.TradeInInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.TradeInInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string make = 3;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 year = 5;
 * @return {number}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string trim = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string exterior_color = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.TradeInInfo} returns this
 */
proto.entity.mlapiv1entity.TradeInInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.VinLookup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.VinLookup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VinLookup.toObject = function(includeInstance, msg) {
  var f, obj = {
    vin: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uvc: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    make: jspb.Message.getFieldWithDefault(msg, 4, ""),
    model: jspb.Message.getFieldWithDefault(msg, 5, ""),
    series: jspb.Message.getFieldWithDefault(msg, 6, ""),
    style: jspb.Message.getFieldWithDefault(msg, 7, ""),
    basePriceClean: jspb.Message.getFieldWithDefault(msg, 8, 0),
    basePriceAverage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    basePriceRough: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cleanXKmThreshold: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cleanKmThreshold: jspb.Message.getFieldWithDefault(msg, 12, 0),
    averageKmThreshold: jspb.Message.getFieldWithDefault(msg, 13, 0),
    roughKmThreshold: jspb.Message.getFieldWithDefault(msg, 14, 0),
    centsPerKm: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.VinLookup}
 */
proto.entity.mlapiv1entity.VinLookup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.VinLookup;
  return proto.entity.mlapiv1entity.VinLookup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.VinLookup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.VinLookup}
 */
proto.entity.mlapiv1entity.VinLookup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUvc(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeries(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStyle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBasePriceClean(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBasePriceAverage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBasePriceRough(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCleanXKmThreshold(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCleanKmThreshold(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAverageKmThreshold(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoughKmThreshold(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCentsPerKm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.VinLookup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.VinLookup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.VinLookup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUvc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSeries();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStyle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBasePriceClean();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBasePriceAverage();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getBasePriceRough();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCleanXKmThreshold();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCleanKmThreshold();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getAverageKmThreshold();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getRoughKmThreshold();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCentsPerKm();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
};


/**
 * optional string vin = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uvc = 2;
 * @return {string}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getUvc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setUvc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string make = 4;
 * @return {string}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string series = 6;
 * @return {string}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getSeries = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setSeries = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string style = 7;
 * @return {string}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setStyle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 base_price_clean = 8;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getBasePriceClean = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setBasePriceClean = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 base_price_average = 9;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getBasePriceAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setBasePriceAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 base_price_rough = 10;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getBasePriceRough = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setBasePriceRough = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 clean_x_km_threshold = 11;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getCleanXKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setCleanXKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 clean_km_threshold = 12;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getCleanKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setCleanKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 average_km_threshold = 13;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getAverageKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setAverageKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 rough_km_threshold = 14;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getRoughKmThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setRoughKmThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional double cents_per_km = 15;
 * @return {number}
 */
proto.entity.mlapiv1entity.VinLookup.prototype.getCentsPerKm = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.VinLookup} returns this
 */
proto.entity.mlapiv1entity.VinLookup.prototype.setCentsPerKm = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.mlapiv1entity.ReservePrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.mlapiv1entity.ReservePrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.ReservePrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lowerBound: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    upperBound: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    reservePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.mlapiv1entity.ReservePrice}
 */
proto.entity.mlapiv1entity.ReservePrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.mlapiv1entity.ReservePrice;
  return proto.entity.mlapiv1entity.ReservePrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.mlapiv1entity.ReservePrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.mlapiv1entity.ReservePrice}
 */
proto.entity.mlapiv1entity.ReservePrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowerBound(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperBound(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReservePrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.mlapiv1entity.ReservePrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.mlapiv1entity.ReservePrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.mlapiv1entity.ReservePrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLowerBound();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getUpperBound();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getReservePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.mlapiv1entity.ReservePrice} returns this
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double lower_bound = 2;
 * @return {number}
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.getLowerBound = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.ReservePrice} returns this
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.setLowerBound = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double upper_bound = 3;
 * @return {number}
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.getUpperBound = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.ReservePrice} returns this
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.setUpperBound = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double reserve_price = 4;
 * @return {number}
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.getReservePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.mlapiv1entity.ReservePrice} returns this
 */
proto.entity.mlapiv1entity.ReservePrice.prototype.setReservePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.entity.mlapiv1entity.QuestionType = {
  QUESTION_TYPE_INVALID: 0,
  QUESTION_TYPE_SINGLE_CHOICE: 1,
  QUESTION_TYPE_MULTIPLE_CHOICE: 2,
  QUESTION_TYPE_MULTIPLE_CHOICE_WITH_DETAILS: 3,
  QUESTION_TYPE_SLIDER: 4
};

/**
 * @enum {number}
 */
proto.entity.mlapiv1entity.VehicleStatus = {
  VEHICLE_STATUS_INVALID: 0,
  VEHICLE_STATUS_INSPECTION_CREATED: 1,
  VEHICLE_STATUS_INSPECTION_STARTED: 2,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE: 3,
  VEHICLE_STATUS_INSPECTION_COMPLETED: 4,
  VEHICLE_STATUS_AUCTION_INCLUDED: 5,
  VEHICLE_STATUS_AUCTION_READY: 6,
  VEHICLE_STATUS_AUCTION_ACTIVE: 7,
  VEHICLE_STATUS_AUCTION_COMPLETE: 8,
  VEHICLE_STATUS_AUCTION_ACCEPTED: 9,
  VEHICLE_STATUS_AUCTION_REJECTED: 10,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS: 11,
  VEHICLE_STATUS_AUCTION_REMOVED: 12,
  VEHICLE_STATUS_AVAILABLE: 13,
  VEHICLE_STATUS_PENDING_SALE: 14,
  VEHICLE_STATUS_SOLD: 15,
  VEHICLE_STATUS_INSPECTION_REPORT_PROCESSING: 16,
  VEHICLE_STATUS_CONFIRMED: 17,
  VEHICLE_STATUS_CREATED: 18,
  VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED: 19
};

/**
 * @enum {number}
 */
proto.entity.mlapiv1entity.MileageUOM = {
  MILEAGE_UOM_INVALID: 0,
  MILEAGE_UOM_MILES: 1,
  MILEAGE_UOM_KILOMETER: 2
};

/**
 * @enum {number}
 */
proto.entity.mlapiv1entity.VehicleClass = {
  VEHICLE_CLASS_INVALID: 0,
  VEHICLE_CLASS_REGULAR: 1,
  VEHICLE_CLASS_HIGH_LINE: 2
};

/**
 * @enum {number}
 */
proto.entity.mlapiv1entity.InspectionStatus = {
  INSPECTION_STATUS_INVALID: 0,
  INSPECTION_STATUS_INCOMPLETE: 1,
  INSPECTION_STATUS_COMPLETE: 2
};

/**
 * @enum {number}
 */
proto.entity.mlapiv1entity.VehicleHighlight = {
  VEHICLE_HIGHLIGHT_INVALID: 0,
  VEHICLE_HIGHLIGHT_NO_ACCIDENTS: 1,
  VEHICLE_HIGHLIGHT_ONE_OWNER: 2,
  VEHICLE_HIGHLIGHT_LOW_MILEAGE: 3,
  VEHICLE_HIGHLIGHT_LOCAL_CAR: 4,
  VEHICLE_HIGHLIGHT_HAS_CARFAX: 5,
  VEHICLE_HIGHLIGHT_HAS_EXTRA_TIRES: 6,
  VEHICLE_HIGHLIGHT_HAS_TWO_KEYS_OR_MORE: 7,
  VEHICLE_HIGHLIGHT_HAS_SERVICE_MANUAL: 8,
  VEHICLE_HIGHLIGHT_DAMAGE_UNDER_2500: 9,
  VEHICLE_HIGHLIGHT_DAMAGE_2500_10000: 10,
  VEHICLE_HIGHLIGHT_DAMAGE_OVER_10000: 11,
  VEHICLE_HIGHLIGHT_HAS_ONE_KEY: 12,
  VEHICLE_HIGHLIGHT_HAS_EXTRA_WHEELS: 13
};

goog.object.extend(exports, proto.entity.mlapiv1entity);
