import React, { useEffect, useState } from 'react';

import { useExtendOffer } from '../../composables/api/useExtendOffer';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import TextInput from '../utils/inputs/TextInput';

interface Message {
  isCreated: boolean;
  text: string;
}

function ExtendOffer({ vehicleId }: { vehicleId: string }) {
  const [message, setMessage] = useState<Message | null>(null);

  const { extendOffer, pending } = useExtendOffer();

  useEffect(() => {
    reset();
  }, [vehicleId]);

  function reset() {
    setMessage(null);
  }

  async function handleExtendOffer() {
    try {
      await extendOffer({
        vehicleId,
      });

      setMessage({
        isCreated: true,
        text: 'Success! The offer has been extended by 24hrs.',
      });
    } catch (error) {
      console.error('Extend offer failed : ', error);

      setMessage({
        isCreated: false,
        text: 'Extend offer unsuccessful. This vehicle might have completed a sale already.',
      });
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Extend Offer for Auction</h3>

      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled handleChange={() => {}} />

      <BaseButtonOutline
        display="Extend Offer"
        onClick={handleExtendOffer}
        small
        className={styles.createSaleBtn}
        disabled={pending}
      />

      {!!message && <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>}
    </div>
  );
}

export default ExtendOffer;
