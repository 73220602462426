import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { Auction, CreateAuctionRequest } from './types';
import { useAuthHeader } from './useAuthHeader';

type CreatedAuction = {
  auctionId: string;
  startTimeNano: number;
  endTimeNano: number;
  numberOfVehicles: number;
};

type CreateAuctionRequestProps = Omit<CreateAuctionRequest, 'region' | 'subRegion'> & {
  country: string;
  division: string;
};

export const useCreateAuction = (): [
  CreatedAuction | null,
  (p: CreateAuctionRequestProps) => Promise<CreatedAuction>,
] => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [auction, setAuction] = useState<CreatedAuction | null>(null);

  async function createAuction(props: CreateAuctionRequestProps) {
    const response = await makeRequest(props);

    const createdAuction: CreatedAuction = {
      auctionId: response.auctionId,
      startTimeNano: response.startTime,
      endTimeNano: response.endTime,
      numberOfVehicles: response.numberOfVehicles,
    };

    setAuction(createdAuction);

    return createdAuction;
  }

  async function makeRequest(props: CreateAuctionRequestProps): Promise<Auction> {
    const request = new adminApiv1Pb.CreateAuctionRequest();
    request.setStartTime(props.startTime);
    request.setEndTime(props.endTime);
    request.setAntiSnipingTime(props.antiSnipingTime);
    request.setAuctionTitle(props.auctionTitle);
    request.setRegion(props.country);
    request.setSubRegion(props.division);
    const response = await client.createAuction(request, authHeader);
    const obj = response.toObject();

    return obj.auction!;
  }

  return [auction, createAuction];
};
