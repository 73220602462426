import * as buildProtosEnumPb from '../grpc/build/protos/enum_pb';

export const DocType = buildProtosEnumPb.DashboardDocumentType;

export const orderedDocuments = [
  {
    label: 'Vehicle Registration Card',
    abbr: 'Registration',
    type: DocType.DOCUMENT_TYPE_VEHICLE_REGISTRATION,
    uploadKey: 'vehicle-registration',
  },
  {
    label: 'CarFax Report',
    abbr: 'Carfax',
    type: DocType.DOCUMENT_TYPE_CARFAX_LIEN,
    uploadKey: '',
  },
  {
    label: 'Financial Statement',
    abbr: 'Financial',
    type: DocType.DOCUMENT_TYPE_FINANCIAL_STATEMENT,
    uploadKey: 'financial-statement',
  },
];
