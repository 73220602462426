import { SyntheticEvent } from 'react';

import useInput from '../../composables/utils/useInput';
import styles from '../../scss/AuctionSearch.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
interface searchVehiclesByStatusWithFilterProps {
  onSearch: (keyword: string) => void;
  onReset: () => void;
}

const VehicleSearch = ({ onSearch, onReset }: searchVehiclesByStatusWithFilterProps) => {
  const [keyword, { handleChange: handleKeywordChange, setInputVal: setKeyword }] = useInput('');

  function handleSearchClick() {
    onSearch(keyword);
  }

  function reset() {
    setKeyword('');
    onReset();
  }

  function submitForm(e: SyntheticEvent) {
    e.preventDefault();
    handleSearchClick();
  }

  return (
    <div>
      <h3>Search Vehicles</h3>
      <form className={styles.settings} onSubmit={submitForm}>
        <div>
          <label htmlFor="keyword">Keyword</label>
          <input id="keyword" value={keyword} onChange={handleKeywordChange} />
        </div>
        <div className={styles.searchButtons}>
          <BaseButtonOutline onClick={handleSearchClick} small={true} display="Search" />
          <BaseButtonOutline onClick={reset} display="Reset" small={true}></BaseButtonOutline>
        </div>
      </form>
    </div>
  );
};

export default VehicleSearch;
