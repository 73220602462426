import { useEffect } from 'react';

import { TradeInInfo } from '../../composables/api/types';
import styles from '../../scss/TradeInInfo.module.scss';

type TradeInInfoProps = {
  vehicleId: string;
  tradeInInfos: TradeInInfo[];
};

export default function TradeInInfoModal({ vehicleId, tradeInInfos }: TradeInInfoProps) {
  useEffect(() => {}, [vehicleId]);

  return (
    <div className={styles.baseTradeInInfoItemWrapper}>
      {tradeInInfos.map((tradeInInfo, index) => {
        return (
          <div key={index} className={styles.baseTradeInInfoItemContent}>
            <h3> Trade In Info </h3>
            <table className={styles.simple}>
              <tbody>
                <tr>
                  <th>Make</th>
                  <td>{tradeInInfo.make}</td>
                </tr>
                <tr>
                  <th>Model</th>
                  <td>{tradeInInfo.model}</td>
                </tr>
                <tr>
                  <th>Year</th>
                  <td>{tradeInInfo.year}</td>
                </tr>
                <tr>
                  <th>Trim</th>
                  <td>{tradeInInfo.trim}</td>
                </tr>
                <tr>
                  <th>Exterior Colour</th>
                  <td>{tradeInInfo.exteriorColor}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{tradeInInfo.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}
