// source: build/protos/dealer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_entities_pb = require('../../build/protos/entities_pb.js');
goog.object.extend(proto, build_protos_entities_pb);
goog.exportSymbol('proto.entity.dealer.AutoGroup', null, global);
goog.exportSymbol('proto.entity.dealer.AutoGroupLocation', null, global);
goog.exportSymbol('proto.entity.dealer.CarfaxCredentials', null, global);
goog.exportSymbol('proto.entity.dealer.Dealer', null, global);
goog.exportSymbol('proto.entity.dealer.DealerLocation', null, global);
goog.exportSymbol('proto.entity.dealer.Dealers', null, global);
goog.exportSymbol('proto.entity.dealer.DealershipListItem', null, global);
goog.exportSymbol('proto.entity.dealer.FeedConfig', null, global);
goog.exportSymbol('proto.entity.dealer.FeedConfigs', null, global);
goog.exportSymbol('proto.entity.dealer.LimitedDealership', null, global);
goog.exportSymbol('proto.entity.dealer.PaymentBankInformation', null, global);
goog.exportSymbol('proto.entity.dealer.PaymentCreditInformation', null, global);
goog.exportSymbol('proto.entity.dealer.PaymentDetails', null, global);
goog.exportSymbol('proto.entity.dealer.PaymentDetails.DetailsCase', null, global);
goog.exportSymbol('proto.entity.dealer.Source', null, global);
goog.exportSymbol('proto.entity.dealer.Timestamp', null, global);
goog.exportSymbol('proto.entity.dealer.User', null, global);
goog.exportSymbol('proto.entity.dealer.UserNotification', null, global);
goog.exportSymbol('proto.entity.dealer.UserRole', null, global);
goog.exportSymbol('proto.entity.dealer.Users', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.Timestamp.displayName = 'proto.entity.dealer.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealer.Users.repeatedFields_, null);
};
goog.inherits(proto.entity.dealer.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.Users.displayName = 'proto.entity.dealer.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealer.User.repeatedFields_, null);
};
goog.inherits(proto.entity.dealer.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.User.displayName = 'proto.entity.dealer.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.UserNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.UserNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.UserNotification.displayName = 'proto.entity.dealer.UserNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.Dealer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealer.Dealer.repeatedFields_, null);
};
goog.inherits(proto.entity.dealer.Dealer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.Dealer.displayName = 'proto.entity.dealer.Dealer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.Dealers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealer.Dealers.repeatedFields_, null);
};
goog.inherits(proto.entity.dealer.Dealers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.Dealers.displayName = 'proto.entity.dealer.Dealers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.LimitedDealership = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.LimitedDealership, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.LimitedDealership.displayName = 'proto.entity.dealer.LimitedDealership';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.DealerLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.DealerLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.DealerLocation.displayName = 'proto.entity.dealer.DealerLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.FeedConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.FeedConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.FeedConfig.displayName = 'proto.entity.dealer.FeedConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.FeedConfigs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealer.FeedConfigs.repeatedFields_, null);
};
goog.inherits(proto.entity.dealer.FeedConfigs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.FeedConfigs.displayName = 'proto.entity.dealer.FeedConfigs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.AutoGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.dealer.AutoGroup.repeatedFields_, null);
};
goog.inherits(proto.entity.dealer.AutoGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.AutoGroup.displayName = 'proto.entity.dealer.AutoGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.AutoGroupLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.AutoGroupLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.AutoGroupLocation.displayName = 'proto.entity.dealer.AutoGroupLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.CarfaxCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.CarfaxCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.CarfaxCredentials.displayName = 'proto.entity.dealer.CarfaxCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.DealershipListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.DealershipListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.DealershipListItem.displayName = 'proto.entity.dealer.DealershipListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.PaymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.entity.dealer.PaymentDetails.oneofGroups_);
};
goog.inherits(proto.entity.dealer.PaymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.PaymentDetails.displayName = 'proto.entity.dealer.PaymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.PaymentBankInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.PaymentBankInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.PaymentBankInformation.displayName = 'proto.entity.dealer.PaymentBankInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.dealer.PaymentCreditInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.dealer.PaymentCreditInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.dealer.PaymentCreditInformation.displayName = 'proto.entity.dealer.PaymentCreditInformation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.Timestamp}
 */
proto.entity.dealer.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.Timestamp;
  return proto.entity.dealer.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.Timestamp}
 */
proto.entity.dealer.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeletedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string created_at = 1;
 * @return {string}
 */
proto.entity.dealer.Timestamp.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Timestamp} returns this
 */
proto.entity.dealer.Timestamp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string updated_at = 2;
 * @return {string}
 */
proto.entity.dealer.Timestamp.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Timestamp} returns this
 */
proto.entity.dealer.Timestamp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deleted_at = 3;
 * @return {string}
 */
proto.entity.dealer.Timestamp.prototype.getDeletedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Timestamp} returns this
 */
proto.entity.dealer.Timestamp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealer.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.entity.dealer.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.Users}
 */
proto.entity.dealer.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.Users;
  return proto.entity.dealer.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.Users}
 */
proto.entity.dealer.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.dealer.User;
      reader.readMessage(value,proto.entity.dealer.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.dealer.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.entity.dealer.User>}
 */
proto.entity.dealer.Users.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.entity.dealer.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.User, 1));
};


/**
 * @param {!Array<!proto.entity.dealer.User>} value
 * @return {!proto.entity.dealer.Users} returns this
*/
proto.entity.dealer.Users.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealer.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.User}
 */
proto.entity.dealer.Users.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealer.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.Users} returns this
 */
proto.entity.dealer.Users.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealer.User.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.User.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeStamp: (f = msg.getTimeStamp()) && proto.entity.dealer.Timestamp.toObject(includeInstance, f),
    crmUserId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isFeeAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isEmailSubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isSmsSubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.entity.dealer.UserNotification.toObject, includeInstance),
    isResponsibleMultiStores: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isLeadContact: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    dealer: (f = msg.getDealer()) && proto.entity.dealer.Dealer.toObject(includeInstance, f),
    isTermsAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    termsAcceptedTime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    latestLoginTimestamp: jspb.Message.getFieldWithDefault(msg, 19, 0),
    registrationNumber: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.User}
 */
proto.entity.dealer.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.User;
  return proto.entity.dealer.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.User}
 */
proto.entity.dealer.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.dealer.UserRole} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 7:
      var value = new proto.entity.dealer.Timestamp;
      reader.readMessage(value,proto.entity.dealer.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmUserId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFeeAccepted(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmailSubscribed(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSmsSubscribed(value);
      break;
    case 13:
      var value = new proto.entity.dealer.UserNotification;
      reader.readMessage(value,proto.entity.dealer.UserNotification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResponsibleMultiStores(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLeadContact(value);
      break;
    case 16:
      var value = new proto.entity.dealer.Dealer;
      reader.readMessage(value,proto.entity.dealer.Dealer.deserializeBinaryFromReader);
      msg.setDealer(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTermsAccepted(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermsAcceptedTime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestLoginTimestamp(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.entity.dealer.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrmUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsFeeAccepted();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsEmailSubscribed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsSmsSubscribed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.entity.dealer.UserNotification.serializeBinaryToWriter
    );
  }
  f = message.getIsResponsibleMultiStores();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsLeadContact();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDealer();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.entity.dealer.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getIsTermsAccepted();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getTermsAcceptedTime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getLatestLoginTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getRegistrationNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string family_name = 4;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string given_name = 5;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UserRole user_role = 6;
 * @return {!proto.entity.dealer.UserRole}
 */
proto.entity.dealer.User.prototype.getUserRole = function() {
  return /** @type {!proto.entity.dealer.UserRole} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.dealer.UserRole} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Timestamp time_stamp = 7;
 * @return {?proto.entity.dealer.Timestamp}
 */
proto.entity.dealer.User.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.dealer.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.Timestamp, 7));
};


/**
 * @param {?proto.entity.dealer.Timestamp|undefined} value
 * @return {!proto.entity.dealer.User} returns this
*/
proto.entity.dealer.User.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string crm_user_id = 8;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getCrmUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setCrmUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone = 9;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_fee_accepted = 10;
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.getIsFeeAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setIsFeeAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_email_subscribed = 11;
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.getIsEmailSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setIsEmailSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_sms_subscribed = 12;
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.getIsSmsSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setIsSmsSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated UserNotification notifications = 13;
 * @return {!Array<!proto.entity.dealer.UserNotification>}
 */
proto.entity.dealer.User.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.entity.dealer.UserNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.UserNotification, 13));
};


/**
 * @param {!Array<!proto.entity.dealer.UserNotification>} value
 * @return {!proto.entity.dealer.User} returns this
*/
proto.entity.dealer.User.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.entity.dealer.UserNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.UserNotification}
 */
proto.entity.dealer.User.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.entity.dealer.UserNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};


/**
 * optional bool is_responsible_multi_stores = 14;
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.getIsResponsibleMultiStores = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setIsResponsibleMultiStores = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_lead_contact = 15;
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.getIsLeadContact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setIsLeadContact = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional Dealer dealer = 16;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.entity.dealer.User.prototype.getDealer = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.Dealer, 16));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.entity.dealer.User} returns this
*/
proto.entity.dealer.User.prototype.setDealer = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.clearDealer = function() {
  return this.setDealer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.hasDealer = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool is_terms_accepted = 17;
 * @return {boolean}
 */
proto.entity.dealer.User.prototype.getIsTermsAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setIsTermsAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int64 terms_accepted_time = 18;
 * @return {number}
 */
proto.entity.dealer.User.prototype.getTermsAcceptedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setTermsAcceptedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 latest_login_timestamp = 19;
 * @return {number}
 */
proto.entity.dealer.User.prototype.getLatestLoginTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setLatestLoginTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string registration_number = 20;
 * @return {string}
 */
proto.entity.dealer.User.prototype.getRegistrationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.User} returns this
 */
proto.entity.dealer.User.prototype.setRegistrationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.UserNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.UserNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.UserNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.UserNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeStamp: (f = msg.getTimeStamp()) && proto.entity.dealer.Timestamp.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.UserNotification}
 */
proto.entity.dealer.UserNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.UserNotification;
  return proto.entity.dealer.UserNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.UserNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.UserNotification}
 */
proto.entity.dealer.UserNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.entity.dealer.Timestamp;
      reader.readMessage(value,proto.entity.dealer.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.UserNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.UserNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.UserNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.UserNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.entity.dealer.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.UserNotification.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.UserNotification} returns this
 */
proto.entity.dealer.UserNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Timestamp time_stamp = 2;
 * @return {?proto.entity.dealer.Timestamp}
 */
proto.entity.dealer.UserNotification.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.dealer.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.Timestamp, 2));
};


/**
 * @param {?proto.entity.dealer.Timestamp|undefined} value
 * @return {!proto.entity.dealer.UserNotification} returns this
*/
proto.entity.dealer.UserNotification.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.UserNotification} returns this
 */
proto.entity.dealer.UserNotification.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.UserNotification.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.entity.dealer.UserNotification.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.UserNotification} returns this
 */
proto.entity.dealer.UserNotification.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealer.Dealer.repeatedFields_ = [4,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.Dealer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.Dealer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.Dealer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Dealer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.entity.dealer.User.toObject, includeInstance),
    timeStamp: (f = msg.getTimeStamp()) && proto.entity.dealer.Timestamp.toObject(includeInstance, f),
    crmDealerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    feedSource: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dealerLocation: (f = msg.getDealerLocation()) && proto.entity.dealer.DealerLocation.toObject(includeInstance, f),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    email: jspb.Message.getFieldWithDefault(msg, 13, ""),
    provinceRegistrationNo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    autoGroupId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    gstNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pstNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    subscriptionPlan: jspb.Message.getFieldWithDefault(msg, 21, 0),
    subscriptionChangeDate: jspb.Message.getFieldWithDefault(msg, 22, ""),
    feedConfigList: jspb.Message.toObjectList(msg.getFeedConfigList(),
    proto.entity.dealer.FeedConfig.toObject, includeInstance),
    carfaxCredentials: (f = msg.getCarfaxCredentials()) && proto.entity.dealer.CarfaxCredentials.toObject(includeInstance, f),
    clipId: jspb.Message.getFieldWithDefault(msg, 25, 0),
    googlePlaceId: jspb.Message.getFieldWithDefault(msg, 26, ""),
    logoPath: jspb.Message.getFieldWithDefault(msg, 27, ""),
    accountantEmail: jspb.Message.getFieldWithDefault(msg, 28, ""),
    dealershipReferral: (f = msg.getDealershipReferral()) && build_protos_entities_pb.DealershipReferral.toObject(includeInstance, f),
    rin: jspb.Message.getFieldWithDefault(msg, 30, ""),
    controllerEmail: jspb.Message.getFieldWithDefault(msg, 31, ""),
    salesManagerEmail: jspb.Message.getFieldWithDefault(msg, 32, ""),
    gmEmail: jspb.Message.getFieldWithDefault(msg, 33, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.Dealer}
 */
proto.entity.dealer.Dealer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.Dealer;
  return proto.entity.dealer.Dealer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.Dealer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.Dealer}
 */
proto.entity.dealer.Dealer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = new proto.entity.dealer.User;
      reader.readMessage(value,proto.entity.dealer.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 5:
      var value = new proto.entity.dealer.Timestamp;
      reader.readMessage(value,proto.entity.dealer.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmDealerId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.dealer.Source} */ (reader.readEnum());
      msg.setFeedSource(value);
      break;
    case 10:
      var value = new proto.entity.dealer.DealerLocation;
      reader.readMessage(value,proto.entity.dealer.DealerLocation.deserializeBinaryFromReader);
      msg.setDealerLocation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceRegistrationNo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGroupId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setGstNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPstNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    case 21:
      var value = /** @type {!proto.entity.enum.SubscriptionPlan} */ (reader.readEnum());
      msg.setSubscriptionPlan(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionChangeDate(value);
      break;
    case 23:
      var value = new proto.entity.dealer.FeedConfig;
      reader.readMessage(value,proto.entity.dealer.FeedConfig.deserializeBinaryFromReader);
      msg.addFeedConfig(value);
      break;
    case 24:
      var value = new proto.entity.dealer.CarfaxCredentials;
      reader.readMessage(value,proto.entity.dealer.CarfaxCredentials.deserializeBinaryFromReader);
      msg.setCarfaxCredentials(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClipId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setGooglePlaceId(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoPath(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountantEmail(value);
      break;
    case 29:
      var value = new build_protos_entities_pb.DealershipReferral;
      reader.readMessage(value,build_protos_entities_pb.DealershipReferral.deserializeBinaryFromReader);
      msg.setDealershipReferral(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRin(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerEmail(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesManagerEmail(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setGmEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.Dealer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.Dealer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.Dealer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Dealer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.entity.dealer.User.serializeBinaryToWriter
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.dealer.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrmDealerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFeedSource();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDealerLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.entity.dealer.DealerLocation.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProvinceRegistrationNo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAutoGroupId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getGstNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPstNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSubscriptionPlan();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getSubscriptionChangeDate();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getFeedConfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.entity.dealer.FeedConfig.serializeBinaryToWriter
    );
  }
  f = message.getCarfaxCredentials();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.entity.dealer.CarfaxCredentials.serializeBinaryToWriter
    );
  }
  f = message.getClipId();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getGooglePlaceId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getLogoPath();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getAccountantEmail();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getDealershipReferral();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      build_protos_entities_pb.DealershipReferral.serializeBinaryToWriter
    );
  }
  f = message.getRin();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getControllerEmail();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSalesManagerEmail();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getGmEmail();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated User users = 4;
 * @return {!Array<!proto.entity.dealer.User>}
 */
proto.entity.dealer.Dealer.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.entity.dealer.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.User, 4));
};


/**
 * @param {!Array<!proto.entity.dealer.User>} value
 * @return {!proto.entity.dealer.Dealer} returns this
*/
proto.entity.dealer.Dealer.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.entity.dealer.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.User}
 */
proto.entity.dealer.Dealer.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.entity.dealer.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional Timestamp time_stamp = 5;
 * @return {?proto.entity.dealer.Timestamp}
 */
proto.entity.dealer.Dealer.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.dealer.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.Timestamp, 5));
};


/**
 * @param {?proto.entity.dealer.Timestamp|undefined} value
 * @return {!proto.entity.dealer.Dealer} returns this
*/
proto.entity.dealer.Dealer.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.Dealer.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string crm_dealer_id = 6;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getCrmDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setCrmDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string external_dealer_id = 7;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string feed_id = 8;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Source feed_source = 9;
 * @return {!proto.entity.dealer.Source}
 */
proto.entity.dealer.Dealer.prototype.getFeedSource = function() {
  return /** @type {!proto.entity.dealer.Source} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.dealer.Source} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setFeedSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional DealerLocation dealer_location = 10;
 * @return {?proto.entity.dealer.DealerLocation}
 */
proto.entity.dealer.Dealer.prototype.getDealerLocation = function() {
  return /** @type{?proto.entity.dealer.DealerLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.DealerLocation, 10));
};


/**
 * @param {?proto.entity.dealer.DealerLocation|undefined} value
 * @return {!proto.entity.dealer.Dealer} returns this
*/
proto.entity.dealer.Dealer.prototype.setDealerLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.clearDealerLocation = function() {
  return this.setDealerLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.Dealer.prototype.hasDealerLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string primary_make = 11;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string email = 13;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string province_registration_no = 14;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getProvinceRegistrationNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setProvinceRegistrationNo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string auto_group_id = 15;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getAutoGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setAutoGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string legal_name = 16;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string gst_number = 17;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getGstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setGstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string pst_number = 18;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getPstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setPstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string fax_number = 19;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional entity.enum.SubscriptionPlan subscription_plan = 21;
 * @return {!proto.entity.enum.SubscriptionPlan}
 */
proto.entity.dealer.Dealer.prototype.getSubscriptionPlan = function() {
  return /** @type {!proto.entity.enum.SubscriptionPlan} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.entity.enum.SubscriptionPlan} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setSubscriptionPlan = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional string subscription_change_date = 22;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getSubscriptionChangeDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setSubscriptionChangeDate = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated FeedConfig feed_config = 23;
 * @return {!Array<!proto.entity.dealer.FeedConfig>}
 */
proto.entity.dealer.Dealer.prototype.getFeedConfigList = function() {
  return /** @type{!Array<!proto.entity.dealer.FeedConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.FeedConfig, 23));
};


/**
 * @param {!Array<!proto.entity.dealer.FeedConfig>} value
 * @return {!proto.entity.dealer.Dealer} returns this
*/
proto.entity.dealer.Dealer.prototype.setFeedConfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.entity.dealer.FeedConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.FeedConfig}
 */
proto.entity.dealer.Dealer.prototype.addFeedConfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.entity.dealer.FeedConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.clearFeedConfigList = function() {
  return this.setFeedConfigList([]);
};


/**
 * optional CarfaxCredentials carfax_credentials = 24;
 * @return {?proto.entity.dealer.CarfaxCredentials}
 */
proto.entity.dealer.Dealer.prototype.getCarfaxCredentials = function() {
  return /** @type{?proto.entity.dealer.CarfaxCredentials} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.CarfaxCredentials, 24));
};


/**
 * @param {?proto.entity.dealer.CarfaxCredentials|undefined} value
 * @return {!proto.entity.dealer.Dealer} returns this
*/
proto.entity.dealer.Dealer.prototype.setCarfaxCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.clearCarfaxCredentials = function() {
  return this.setCarfaxCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.Dealer.prototype.hasCarfaxCredentials = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional int64 clip_id = 25;
 * @return {number}
 */
proto.entity.dealer.Dealer.prototype.getClipId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setClipId = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string google_place_id = 26;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getGooglePlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setGooglePlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string logo_path = 27;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getLogoPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setLogoPath = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string accountant_email = 28;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getAccountantEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setAccountantEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional entity.entities.DealershipReferral dealership_referral = 29;
 * @return {?proto.entity.entities.DealershipReferral}
 */
proto.entity.dealer.Dealer.prototype.getDealershipReferral = function() {
  return /** @type{?proto.entity.entities.DealershipReferral} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.DealershipReferral, 29));
};


/**
 * @param {?proto.entity.entities.DealershipReferral|undefined} value
 * @return {!proto.entity.dealer.Dealer} returns this
*/
proto.entity.dealer.Dealer.prototype.setDealershipReferral = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.clearDealershipReferral = function() {
  return this.setDealershipReferral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.Dealer.prototype.hasDealershipReferral = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string rin = 30;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getRin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setRin = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string controller_email = 31;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getControllerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setControllerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string sales_manager_email = 32;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getSalesManagerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setSalesManagerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string gm_email = 33;
 * @return {string}
 */
proto.entity.dealer.Dealer.prototype.getGmEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.Dealer} returns this
 */
proto.entity.dealer.Dealer.prototype.setGmEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealer.Dealers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.Dealers.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.Dealers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.Dealers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Dealers.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealersList: jspb.Message.toObjectList(msg.getDealersList(),
    proto.entity.dealer.Dealer.toObject, includeInstance),
    totalNumberOfDealerships: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.Dealers}
 */
proto.entity.dealer.Dealers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.Dealers;
  return proto.entity.dealer.Dealers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.Dealers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.Dealers}
 */
proto.entity.dealer.Dealers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.dealer.Dealer;
      reader.readMessage(value,proto.entity.dealer.Dealer.deserializeBinaryFromReader);
      msg.addDealers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalNumberOfDealerships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.Dealers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.Dealers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.Dealers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.Dealers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.dealer.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumberOfDealerships();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Dealer dealers = 1;
 * @return {!Array<!proto.entity.dealer.Dealer>}
 */
proto.entity.dealer.Dealers.prototype.getDealersList = function() {
  return /** @type{!Array<!proto.entity.dealer.Dealer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.Dealer, 1));
};


/**
 * @param {!Array<!proto.entity.dealer.Dealer>} value
 * @return {!proto.entity.dealer.Dealers} returns this
*/
proto.entity.dealer.Dealers.prototype.setDealersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealer.Dealer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.Dealer}
 */
proto.entity.dealer.Dealers.prototype.addDealers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealer.Dealer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.Dealers} returns this
 */
proto.entity.dealer.Dealers.prototype.clearDealersList = function() {
  return this.setDealersList([]);
};


/**
 * optional int64 total_number_of_dealerships = 2;
 * @return {number}
 */
proto.entity.dealer.Dealers.prototype.getTotalNumberOfDealerships = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.Dealers} returns this
 */
proto.entity.dealer.Dealers.prototype.setTotalNumberOfDealerships = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.LimitedDealership.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.LimitedDealership.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.LimitedDealership} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.LimitedDealership.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    crmDealerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dealershipLocation: (f = msg.getDealershipLocation()) && proto.entity.dealer.DealerLocation.toObject(includeInstance, f),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.LimitedDealership}
 */
proto.entity.dealer.LimitedDealership.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.LimitedDealership;
  return proto.entity.dealer.LimitedDealership.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.LimitedDealership} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.LimitedDealership}
 */
proto.entity.dealer.LimitedDealership.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmDealerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 10:
      var value = new proto.entity.dealer.DealerLocation;
      reader.readMessage(value,proto.entity.dealer.DealerLocation.deserializeBinaryFromReader);
      msg.setDealershipLocation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.LimitedDealership.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.LimitedDealership.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.LimitedDealership} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.LimitedDealership.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCrmDealerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDealershipLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.entity.dealer.DealerLocation.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string crm_dealer_id = 7;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getCrmDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setCrmDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string external_dealer_id = 8;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string feed_id = 9;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional DealerLocation dealership_location = 10;
 * @return {?proto.entity.dealer.DealerLocation}
 */
proto.entity.dealer.LimitedDealership.prototype.getDealershipLocation = function() {
  return /** @type{?proto.entity.dealer.DealerLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.DealerLocation, 10));
};


/**
 * @param {?proto.entity.dealer.DealerLocation|undefined} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
*/
proto.entity.dealer.LimitedDealership.prototype.setDealershipLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.clearDealershipLocation = function() {
  return this.setDealershipLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.LimitedDealership.prototype.hasDealershipLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string primary_make = 11;
 * @return {string}
 */
proto.entity.dealer.LimitedDealership.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.LimitedDealership} returns this
 */
proto.entity.dealer.LimitedDealership.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.DealerLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.DealerLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.DealerLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.DealerLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 4, ""),
    telephoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    country: jspb.Message.getFieldWithDefault(msg, 7, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.DealerLocation}
 */
proto.entity.dealer.DealerLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.DealerLocation;
  return proto.entity.dealer.DealerLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.DealerLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.DealerLocation}
 */
proto.entity.dealer.DealerLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.DealerLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.DealerLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.DealerLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.DealerLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTelephoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string postal = 4;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string telephone_number = 5;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getTelephoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setTelephoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fax_number = 6;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country = 7;
 * @return {string}
 */
proto.entity.dealer.DealerLocation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double longitude = 8;
 * @return {number}
 */
proto.entity.dealer.DealerLocation.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double latitude = 9;
 * @return {number}
 */
proto.entity.dealer.DealerLocation.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.DealerLocation} returns this
 */
proto.entity.dealer.DealerLocation.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.FeedConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.FeedConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.FeedConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.FeedConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    host: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    feedSource: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.FeedConfig}
 */
proto.entity.dealer.FeedConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.FeedConfig;
  return proto.entity.dealer.FeedConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.FeedConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.FeedConfig}
 */
proto.entity.dealer.FeedConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.dealer.Source} */ (reader.readEnum());
      msg.setFeedSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.FeedConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.FeedConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.FeedConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.FeedConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFeedSource();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string feed_id = 2;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string host = 5;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string filename = 6;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dealer_id = 7;
 * @return {string}
 */
proto.entity.dealer.FeedConfig.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Source feed_source = 8;
 * @return {!proto.entity.dealer.Source}
 */
proto.entity.dealer.FeedConfig.prototype.getFeedSource = function() {
  return /** @type {!proto.entity.dealer.Source} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.dealer.Source} value
 * @return {!proto.entity.dealer.FeedConfig} returns this
 */
proto.entity.dealer.FeedConfig.prototype.setFeedSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealer.FeedConfigs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.FeedConfigs.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.FeedConfigs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.FeedConfigs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.FeedConfigs.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedConfigsList: jspb.Message.toObjectList(msg.getFeedConfigsList(),
    proto.entity.dealer.FeedConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.FeedConfigs}
 */
proto.entity.dealer.FeedConfigs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.FeedConfigs;
  return proto.entity.dealer.FeedConfigs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.FeedConfigs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.FeedConfigs}
 */
proto.entity.dealer.FeedConfigs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.dealer.FeedConfig;
      reader.readMessage(value,proto.entity.dealer.FeedConfig.deserializeBinaryFromReader);
      msg.addFeedConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.FeedConfigs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.FeedConfigs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.FeedConfigs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.FeedConfigs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.dealer.FeedConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FeedConfig feed_configs = 1;
 * @return {!Array<!proto.entity.dealer.FeedConfig>}
 */
proto.entity.dealer.FeedConfigs.prototype.getFeedConfigsList = function() {
  return /** @type{!Array<!proto.entity.dealer.FeedConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.FeedConfig, 1));
};


/**
 * @param {!Array<!proto.entity.dealer.FeedConfig>} value
 * @return {!proto.entity.dealer.FeedConfigs} returns this
*/
proto.entity.dealer.FeedConfigs.prototype.setFeedConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealer.FeedConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.FeedConfig}
 */
proto.entity.dealer.FeedConfigs.prototype.addFeedConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealer.FeedConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.FeedConfigs} returns this
 */
proto.entity.dealer.FeedConfigs.prototype.clearFeedConfigsList = function() {
  return this.setFeedConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.dealer.AutoGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.AutoGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.AutoGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.AutoGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.AutoGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealersList: jspb.Message.toObjectList(msg.getDealersList(),
    proto.entity.dealer.Dealer.toObject, includeInstance),
    autoGroupLocation: (f = msg.getAutoGroupLocation()) && proto.entity.dealer.AutoGroupLocation.toObject(includeInstance, f),
    provinceRegistrationNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subscriptionPlan: jspb.Message.getFieldWithDefault(msg, 6, 0),
    subscriptionChangeDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    accountantEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    numFreeTransactions: jspb.Message.getFieldWithDefault(msg, 9, 0),
    rin: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.AutoGroup}
 */
proto.entity.dealer.AutoGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.AutoGroup;
  return proto.entity.dealer.AutoGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.AutoGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.AutoGroup}
 */
proto.entity.dealer.AutoGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.entity.dealer.Dealer;
      reader.readMessage(value,proto.entity.dealer.Dealer.deserializeBinaryFromReader);
      msg.addDealers(value);
      break;
    case 4:
      var value = new proto.entity.dealer.AutoGroupLocation;
      reader.readMessage(value,proto.entity.dealer.AutoGroupLocation.deserializeBinaryFromReader);
      msg.setAutoGroupLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceRegistrationNo(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.SubscriptionPlan} */ (reader.readEnum());
      msg.setSubscriptionPlan(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionChangeDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountantEmail(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumFreeTransactions(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.AutoGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.AutoGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.AutoGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.AutoGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.entity.dealer.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getAutoGroupLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.entity.dealer.AutoGroupLocation.serializeBinaryToWriter
    );
  }
  f = message.getProvinceRegistrationNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubscriptionPlan();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSubscriptionChangeDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccountantEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNumFreeTransactions();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRin();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.AutoGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.dealer.AutoGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Dealer dealers = 3;
 * @return {!Array<!proto.entity.dealer.Dealer>}
 */
proto.entity.dealer.AutoGroup.prototype.getDealersList = function() {
  return /** @type{!Array<!proto.entity.dealer.Dealer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.dealer.Dealer, 3));
};


/**
 * @param {!Array<!proto.entity.dealer.Dealer>} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
*/
proto.entity.dealer.AutoGroup.prototype.setDealersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.dealer.Dealer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.Dealer}
 */
proto.entity.dealer.AutoGroup.prototype.addDealers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.dealer.Dealer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.clearDealersList = function() {
  return this.setDealersList([]);
};


/**
 * optional AutoGroupLocation auto_group_location = 4;
 * @return {?proto.entity.dealer.AutoGroupLocation}
 */
proto.entity.dealer.AutoGroup.prototype.getAutoGroupLocation = function() {
  return /** @type{?proto.entity.dealer.AutoGroupLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.AutoGroupLocation, 4));
};


/**
 * @param {?proto.entity.dealer.AutoGroupLocation|undefined} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
*/
proto.entity.dealer.AutoGroup.prototype.setAutoGroupLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.clearAutoGroupLocation = function() {
  return this.setAutoGroupLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.AutoGroup.prototype.hasAutoGroupLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string province_registration_no = 5;
 * @return {string}
 */
proto.entity.dealer.AutoGroup.prototype.getProvinceRegistrationNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setProvinceRegistrationNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional entity.enum.SubscriptionPlan subscription_plan = 6;
 * @return {!proto.entity.enum.SubscriptionPlan}
 */
proto.entity.dealer.AutoGroup.prototype.getSubscriptionPlan = function() {
  return /** @type {!proto.entity.enum.SubscriptionPlan} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.SubscriptionPlan} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setSubscriptionPlan = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string subscription_change_date = 7;
 * @return {string}
 */
proto.entity.dealer.AutoGroup.prototype.getSubscriptionChangeDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setSubscriptionChangeDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string accountant_email = 8;
 * @return {string}
 */
proto.entity.dealer.AutoGroup.prototype.getAccountantEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setAccountantEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 num_free_transactions = 9;
 * @return {number}
 */
proto.entity.dealer.AutoGroup.prototype.getNumFreeTransactions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setNumFreeTransactions = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string rin = 10;
 * @return {string}
 */
proto.entity.dealer.AutoGroup.prototype.getRin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroup} returns this
 */
proto.entity.dealer.AutoGroup.prototype.setRin = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.AutoGroupLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.AutoGroupLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.AutoGroupLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.AutoGroupLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 4, ""),
    telephoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.AutoGroupLocation}
 */
proto.entity.dealer.AutoGroupLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.AutoGroupLocation;
  return proto.entity.dealer.AutoGroupLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.AutoGroupLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.AutoGroupLocation}
 */
proto.entity.dealer.AutoGroupLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.AutoGroupLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.AutoGroupLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.AutoGroupLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.AutoGroupLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTelephoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.entity.dealer.AutoGroupLocation.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroupLocation} returns this
 */
proto.entity.dealer.AutoGroupLocation.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.entity.dealer.AutoGroupLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroupLocation} returns this
 */
proto.entity.dealer.AutoGroupLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.entity.dealer.AutoGroupLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroupLocation} returns this
 */
proto.entity.dealer.AutoGroupLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string postal = 4;
 * @return {string}
 */
proto.entity.dealer.AutoGroupLocation.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroupLocation} returns this
 */
proto.entity.dealer.AutoGroupLocation.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string telephone_number = 5;
 * @return {string}
 */
proto.entity.dealer.AutoGroupLocation.prototype.getTelephoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroupLocation} returns this
 */
proto.entity.dealer.AutoGroupLocation.prototype.setTelephoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fax_number = 6;
 * @return {string}
 */
proto.entity.dealer.AutoGroupLocation.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.AutoGroupLocation} returns this
 */
proto.entity.dealer.AutoGroupLocation.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.CarfaxCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.CarfaxCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.CarfaxCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.CarfaxCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.CarfaxCredentials}
 */
proto.entity.dealer.CarfaxCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.CarfaxCredentials;
  return proto.entity.dealer.CarfaxCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.CarfaxCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.CarfaxCredentials}
 */
proto.entity.dealer.CarfaxCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.CarfaxCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.CarfaxCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.CarfaxCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.CarfaxCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.entity.dealer.CarfaxCredentials.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.CarfaxCredentials} returns this
 */
proto.entity.dealer.CarfaxCredentials.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.entity.dealer.CarfaxCredentials.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.CarfaxCredentials} returns this
 */
proto.entity.dealer.CarfaxCredentials.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.DealershipListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.DealershipListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.DealershipListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.DealershipListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    registrationNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gstNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pstNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subscriptionPlan: jspb.Message.getFieldWithDefault(msg, 11, ""),
    subscriptionDate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    accountantEmail: jspb.Message.getFieldWithDefault(msg, 13, ""),
    address: (f = msg.getAddress()) && proto.entity.dealer.DealerLocation.toObject(includeInstance, f),
    carfax: (f = msg.getCarfax()) && proto.entity.dealer.CarfaxCredentials.toObject(includeInstance, f),
    autogroupName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    referrerName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    referrerNotes: jspb.Message.getFieldWithDefault(msg, 18, ""),
    rin: jspb.Message.getFieldWithDefault(msg, 19, ""),
    controllerEmail: jspb.Message.getFieldWithDefault(msg, 20, ""),
    salesManagerEmail: jspb.Message.getFieldWithDefault(msg, 21, ""),
    gmEmail: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.DealershipListItem}
 */
proto.entity.dealer.DealershipListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.DealershipListItem;
  return proto.entity.dealer.DealershipListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.DealershipListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.DealershipListItem}
 */
proto.entity.dealer.DealershipListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGstNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPstNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionPlan(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionDate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountantEmail(value);
      break;
    case 14:
      var value = new proto.entity.dealer.DealerLocation;
      reader.readMessage(value,proto.entity.dealer.DealerLocation.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 15:
      var value = new proto.entity.dealer.CarfaxCredentials;
      reader.readMessage(value,proto.entity.dealer.CarfaxCredentials.deserializeBinaryFromReader);
      msg.setCarfax(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutogroupName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerNotes(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setRin(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerEmail(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesManagerEmail(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setGmEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.DealershipListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.DealershipListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.DealershipListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.DealershipListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegistrationNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGstNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPstNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubscriptionPlan();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSubscriptionDate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAccountantEmail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.entity.dealer.DealerLocation.serializeBinaryToWriter
    );
  }
  f = message.getCarfax();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.entity.dealer.CarfaxCredentials.serializeBinaryToWriter
    );
  }
  f = message.getAutogroupName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getReferrerName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getReferrerNotes();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getRin();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getControllerEmail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSalesManagerEmail();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getGmEmail();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_dealer_id = 3;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string primary_make = 4;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string registration_number = 7;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getRegistrationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setRegistrationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string legal_name = 8;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string gst_number = 9;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getGstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setGstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string pst_number = 10;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getPstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setPstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string subscription_plan = 11;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getSubscriptionPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setSubscriptionPlan = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string subscription_date = 12;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getSubscriptionDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setSubscriptionDate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string accountant_email = 13;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getAccountantEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setAccountantEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional DealerLocation address = 14;
 * @return {?proto.entity.dealer.DealerLocation}
 */
proto.entity.dealer.DealershipListItem.prototype.getAddress = function() {
  return /** @type{?proto.entity.dealer.DealerLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.DealerLocation, 14));
};


/**
 * @param {?proto.entity.dealer.DealerLocation|undefined} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
*/
proto.entity.dealer.DealershipListItem.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.DealershipListItem.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional CarfaxCredentials carfax = 15;
 * @return {?proto.entity.dealer.CarfaxCredentials}
 */
proto.entity.dealer.DealershipListItem.prototype.getCarfax = function() {
  return /** @type{?proto.entity.dealer.CarfaxCredentials} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.CarfaxCredentials, 15));
};


/**
 * @param {?proto.entity.dealer.CarfaxCredentials|undefined} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
*/
proto.entity.dealer.DealershipListItem.prototype.setCarfax = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.clearCarfax = function() {
  return this.setCarfax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.DealershipListItem.prototype.hasCarfax = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string autogroup_name = 16;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getAutogroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setAutogroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string referrer_name = 17;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getReferrerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setReferrerName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string referrer_notes = 18;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getReferrerNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setReferrerNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string rin = 19;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getRin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setRin = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string controller_email = 20;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getControllerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setControllerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string sales_manager_email = 21;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getSalesManagerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setSalesManagerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string gm_email = 22;
 * @return {string}
 */
proto.entity.dealer.DealershipListItem.prototype.getGmEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.DealershipListItem} returns this
 */
proto.entity.dealer.DealershipListItem.prototype.setGmEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.entity.dealer.PaymentDetails.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.entity.dealer.PaymentDetails.DetailsCase = {
  DETAILS_NOT_SET: 0,
  BANK_INFO: 5,
  CARD_INFO: 6
};

/**
 * @return {proto.entity.dealer.PaymentDetails.DetailsCase}
 */
proto.entity.dealer.PaymentDetails.prototype.getDetailsCase = function() {
  return /** @type {proto.entity.dealer.PaymentDetails.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.entity.dealer.PaymentDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.PaymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.PaymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.PaymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.PaymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isUserDefault: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    bankInfo: (f = msg.getBankInfo()) && proto.entity.dealer.PaymentBankInformation.toObject(includeInstance, f),
    cardInfo: (f = msg.getCardInfo()) && proto.entity.dealer.PaymentCreditInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.PaymentDetails}
 */
proto.entity.dealer.PaymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.PaymentDetails;
  return proto.entity.dealer.PaymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.PaymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.PaymentDetails}
 */
proto.entity.dealer.PaymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUserDefault(value);
      break;
    case 5:
      var value = new proto.entity.dealer.PaymentBankInformation;
      reader.readMessage(value,proto.entity.dealer.PaymentBankInformation.deserializeBinaryFromReader);
      msg.setBankInfo(value);
      break;
    case 6:
      var value = new proto.entity.dealer.PaymentCreditInformation;
      reader.readMessage(value,proto.entity.dealer.PaymentCreditInformation.deserializeBinaryFromReader);
      msg.setCardInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.PaymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.PaymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.PaymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.PaymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsUserDefault();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBankInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.dealer.PaymentBankInformation.serializeBinaryToWriter
    );
  }
  f = message.getCardInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.entity.dealer.PaymentCreditInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.dealer.PaymentDetails.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentDetails} returns this
 */
proto.entity.dealer.PaymentDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.entity.dealer.PaymentDetails.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentDetails} returns this
 */
proto.entity.dealer.PaymentDetails.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dealer_id = 3;
 * @return {string}
 */
proto.entity.dealer.PaymentDetails.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentDetails} returns this
 */
proto.entity.dealer.PaymentDetails.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_user_default = 4;
 * @return {boolean}
 */
proto.entity.dealer.PaymentDetails.prototype.getIsUserDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.dealer.PaymentDetails} returns this
 */
proto.entity.dealer.PaymentDetails.prototype.setIsUserDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional PaymentBankInformation bank_info = 5;
 * @return {?proto.entity.dealer.PaymentBankInformation}
 */
proto.entity.dealer.PaymentDetails.prototype.getBankInfo = function() {
  return /** @type{?proto.entity.dealer.PaymentBankInformation} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.PaymentBankInformation, 5));
};


/**
 * @param {?proto.entity.dealer.PaymentBankInformation|undefined} value
 * @return {!proto.entity.dealer.PaymentDetails} returns this
*/
proto.entity.dealer.PaymentDetails.prototype.setBankInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.entity.dealer.PaymentDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.PaymentDetails} returns this
 */
proto.entity.dealer.PaymentDetails.prototype.clearBankInfo = function() {
  return this.setBankInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.PaymentDetails.prototype.hasBankInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PaymentCreditInformation card_info = 6;
 * @return {?proto.entity.dealer.PaymentCreditInformation}
 */
proto.entity.dealer.PaymentDetails.prototype.getCardInfo = function() {
  return /** @type{?proto.entity.dealer.PaymentCreditInformation} */ (
    jspb.Message.getWrapperField(this, proto.entity.dealer.PaymentCreditInformation, 6));
};


/**
 * @param {?proto.entity.dealer.PaymentCreditInformation|undefined} value
 * @return {!proto.entity.dealer.PaymentDetails} returns this
*/
proto.entity.dealer.PaymentDetails.prototype.setCardInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.entity.dealer.PaymentDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.dealer.PaymentDetails} returns this
 */
proto.entity.dealer.PaymentDetails.prototype.clearCardInfo = function() {
  return this.setCardInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.dealer.PaymentDetails.prototype.hasCardInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.PaymentBankInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.PaymentBankInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.PaymentBankInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.PaymentBankInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastFour: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transitNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    institutionNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    microDepositVerificationUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expectedMicroDepositDate: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.PaymentBankInformation}
 */
proto.entity.dealer.PaymentBankInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.PaymentBankInformation;
  return proto.entity.dealer.PaymentBankInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.PaymentBankInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.PaymentBankInformation}
 */
proto.entity.dealer.PaymentBankInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFour(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransitNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMicroDepositVerificationUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpectedMicroDepositDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.PaymentBankInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.PaymentBankInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.PaymentBankInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.PaymentBankInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastFour();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransitNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstitutionNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMicroDepositVerificationUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpectedMicroDepositDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string bank_name = 1;
 * @return {string}
 */
proto.entity.dealer.PaymentBankInformation.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentBankInformation} returns this
 */
proto.entity.dealer.PaymentBankInformation.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_four = 2;
 * @return {string}
 */
proto.entity.dealer.PaymentBankInformation.prototype.getLastFour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentBankInformation} returns this
 */
proto.entity.dealer.PaymentBankInformation.prototype.setLastFour = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transit_number = 3;
 * @return {string}
 */
proto.entity.dealer.PaymentBankInformation.prototype.getTransitNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentBankInformation} returns this
 */
proto.entity.dealer.PaymentBankInformation.prototype.setTransitNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string institution_number = 4;
 * @return {string}
 */
proto.entity.dealer.PaymentBankInformation.prototype.getInstitutionNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentBankInformation} returns this
 */
proto.entity.dealer.PaymentBankInformation.prototype.setInstitutionNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string micro_deposit_verification_url = 5;
 * @return {string}
 */
proto.entity.dealer.PaymentBankInformation.prototype.getMicroDepositVerificationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentBankInformation} returns this
 */
proto.entity.dealer.PaymentBankInformation.prototype.setMicroDepositVerificationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 expected_micro_deposit_date = 6;
 * @return {number}
 */
proto.entity.dealer.PaymentBankInformation.prototype.getExpectedMicroDepositDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.dealer.PaymentBankInformation} returns this
 */
proto.entity.dealer.PaymentBankInformation.prototype.setExpectedMicroDepositDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.dealer.PaymentCreditInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.dealer.PaymentCreditInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.dealer.PaymentCreditInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.PaymentCreditInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    brand: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastFour: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 3, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.dealer.PaymentCreditInformation}
 */
proto.entity.dealer.PaymentCreditInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.dealer.PaymentCreditInformation;
  return proto.entity.dealer.PaymentCreditInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.dealer.PaymentCreditInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.dealer.PaymentCreditInformation}
 */
proto.entity.dealer.PaymentCreditInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFour(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.dealer.PaymentCreditInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.dealer.PaymentCreditInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.dealer.PaymentCreditInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.dealer.PaymentCreditInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastFour();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiryYear();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string brand = 1;
 * @return {string}
 */
proto.entity.dealer.PaymentCreditInformation.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentCreditInformation} returns this
 */
proto.entity.dealer.PaymentCreditInformation.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_four = 2;
 * @return {string}
 */
proto.entity.dealer.PaymentCreditInformation.prototype.getLastFour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentCreditInformation} returns this
 */
proto.entity.dealer.PaymentCreditInformation.prototype.setLastFour = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string expiry_year = 3;
 * @return {string}
 */
proto.entity.dealer.PaymentCreditInformation.prototype.getExpiryYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentCreditInformation} returns this
 */
proto.entity.dealer.PaymentCreditInformation.prototype.setExpiryYear = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string expiry_month = 4;
 * @return {string}
 */
proto.entity.dealer.PaymentCreditInformation.prototype.getExpiryMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.dealer.PaymentCreditInformation} returns this
 */
proto.entity.dealer.PaymentCreditInformation.prototype.setExpiryMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.entity.dealer.UserRole = {
  USER_ROLE_INVALID: 0,
  USER_ROLE_DEALER: 1,
  USER_ROLE_DEALER_ADMIN: 2,
  USER_ROLE_SERVICE: 3
};

/**
 * @enum {number}
 */
proto.entity.dealer.Source = {
  SOURCE_INVALID: 0,
  SOURCE_TRAFFICDRIVEN: 1,
  SOURCE_CDK: 2,
  SOURCE_AIM: 3,
  SOURCE_180: 4,
  SOURCE_360: 5,
  SOURCE_BOOST: 6,
  SOURCE_EZRESULTS: 7,
  SOURCE_PBS: 8,
  SOURCE_D2C: 9,
  SOURCE_EDEALER: 10,
  SOURCE_LEADBOX: 11
};

goog.object.extend(exports, proto.entity.dealer);
