export const basicFields: Record<string, string> = {
  id: 'ID',
  name: 'Name',
  primaryMake: 'Primary Make',
  gstNumber: 'GST Number',
  pstNumber: 'PST Number',
  registrationNumber: 'Provincial Registration No',
  legalName: 'Legal Name',
  phone: 'Phone',
  email: 'Email',
  subscriptionPlan: 'Subscription Plan',
  subscriptionChangeDate: 'Subscription Date',
  externalDealerId: 'External Dealer ID',
  accountantEmail: 'Accountant Email',
  autogroupName: 'Autogroup Name',
  rin: 'RIN Number',
  controllerEmail: 'Controller Email',
  salesManagerEmail: 'Sales Manager Email',
  gmEmail: 'General Manager Email',
};

export const addressFields: Record<string, string> = {
  street: 'Street',
  city: 'City',
  country: 'Country',
  state: 'State',
  postal: 'Postal Code',
  telephoneNumber: 'Phone (Address)',
  faxNumber: 'Fax',
};

export const carfaxFields: Record<string, string> = {
  accountId: 'Account Id',
  token: 'Token',
};

export const dealershipReferralFields: Record<string, string> = {
  referrerName: 'Referrer Name',
  referrerNotes: 'Referrer Notes',
};
