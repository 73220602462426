import React, { useEffect, useState } from 'react';

import { useQuestionOptions } from '../../composables/api/useQuestionOptions';
import * as mlApiV1Entity from '../../grpc/build/protos/mlapiv1entity_pb';
import inspectionQuestionsStyles from '../../scss/InspectionQuestions.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseTextInput from '../base/BaseTextInput';

type OptionListTableProps = {
  onUpdate: (questionId: string) => void;
  options: mlApiV1Entity.QuestionOption.AsObject[];
  questionId: string;
  error: string;
  warning: string;
  categories: string[];
};

export default function InspectionQuestionOptionsTable(props: OptionListTableProps) {
  const { options, error, warning, questionId } = props;
  const { updateOption } = useQuestionOptions();

  const [tableOptions, setTableOptions] = useState<mlApiV1Entity.QuestionOption.AsObject[]>(options);

  const [editIndex, setEditIndex] = useState(-1);
  const [optionString, setOptionString] = useState('');
  const [description, setDescription] = useState('');
  const [optionId, setOptionId] = useState('');

  function startEditing(index: number, currentOptionId: string, currentOption: string, currentDescription: string) {
    setEditIndex(index);
    setOptionId(currentOptionId);
    setOptionString(currentOption);
    setDescription(currentDescription);
  }

  async function save(optionId: string, optionText: string, optionDescription: string) {
    await updateOption(optionId, optionText, optionDescription);
    props.onUpdate(questionId);
    setEditIndex(-1);
  }

  function clear() {
    setEditIndex(-1);

    setOptionString('');
    setDescription('');
  }

  useEffect(() => {
    setTableOptions(options);
  }, [props.options]);

  return (
    <div>
      {tableOptions && (
        <div>
          <table className={inspectionQuestionsStyles.dataTable}>
            <thead>
              <tr>
                <th style={{ width: '10%', textAlign: 'center' }}>Option</th>
                <th style={{ width: '20%', textAlign: 'center' }}>Question</th>
                <th style={{ width: '10%', textAlign: 'center' }}>Type</th>
                <th style={{ width: '5%', textAlign: 'center' }}>Order</th>
                <th style={{ width: '10%', textAlign: 'center' }}>Is Required</th>
                <th style={{ width: '10%', textAlign: 'center' }}>Has Detail Field</th>
                <th style={{ width: '10%', textAlign: 'center' }}>Follow Up Question Categories</th>
                <th style={{ width: '20%', textAlign: 'center' }}>Description</th>
                <th style={{ width: '5%', textAlign: 'center' }}>Edit</th>
              </tr>
            </thead>
            <tbody>
              {tableOptions.map((option, index) => {
                const isEditingThisRow = index === editIndex;

                return (
                  <tr key={index}>
                    <td style={{ width: '10%' }}>
                      {isEditingThisRow ? (
                        <BaseTextInput
                          id="aaa"
                          value={optionString}
                          required
                          onChange={(event) => setOptionString(event.target.value)}
                          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        />
                      ) : (
                        option.option
                      )}
                    </td>
                    <td style={{ width: '20%' }}>{option.question}</td>
                    <td style={{ width: '10%' }}>{option.optionType}</td>
                    <td style={{ width: '5%' }}>{option.optionOrder}</td>
                    <td align="center" style={{ width: '10%' }}>
                      {<p>{option.isRequired ? 'Yes' : 'No'}</p>}
                    </td>
                    <td align="center" style={{ width: '10%' }}>
                      {<p>{option.hasDetailField ? 'Yes' : 'No'}</p>}
                    </td>
                    <td style={{ width: '10%' }}>{option.followUpQuestionCategoriesList.join(', ')}</td>

                    <td style={{ width: '20%' }}>
                      {isEditingThisRow ? (
                        <BaseTextInput
                          id="aaa"
                          value={description}
                          required
                          onChange={(event) => setDescription(event.target.value)}
                          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        />
                      ) : (
                        option.description
                      )}
                    </td>
                    <td style={{ width: '5%' }}>
                      <div>
                        {isEditingThisRow ? (
                          <>
                            <BaseButtonOutline
                              display="Save"
                              xSmall
                              onClick={() => save(optionId, optionString, description)}
                            />
                            <BaseButtonOutline display="Cancel" xSmall onClick={clear} />
                          </>
                        ) : option.option.toLowerCase() === 'custom' ? (
                          <p style={{ fontSize: '.8em' }}>Custom options cannot be changed</p>
                        ) : (
                          <BaseButtonOutline
                            display="Edit"
                            xSmall
                            onClick={() => {
                              startEditing(index, option.id, option.option, option.description);
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {error && <p style={{ color: 'red', fontSize: 24 }}>{error}</p>}
          {warning && <p style={{ color: 'orange', fontSize: 24 }}>{warning}</p>}
        </div>
      )}
    </div>
  );
}
