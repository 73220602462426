import React from 'react';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';
import CompleteNewVehicleSale from '../completeSale/CompleteNewVehicleSale';

function AddNewVehicleButton() {
  const { openUtilityDrawer } = useUtilityDrawer();
  const completeSale = async () => {
    openUtilityDrawer(<CompleteNewVehicleSale wayOfSale={enumPb.WayOfSale.WAY_OF_SALE_CONSUMER_BUY} />);
  };
  return <BaseButtonOutline small display="Add New Non Listing" onClick={completeSale} />;
}

export default AddNewVehicleButton;
