import 'react-datepicker/dist/react-datepicker.css';

import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import { joinClasses } from '../../helpers/utils';
import styles from '../../scss/DateRangePicker.module.scss';

const DateSinglePicker = ({ selectedDate, onDateSelect, label }: DateRangePickerProps) => {
  const CustomContainer = ({ className, children }: CustomContainerProps) => {
    return (
      <div style={{ background: 'transparent', border: '1px solid transparent' }}>
        <div className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </div>
      </div>
    );
  };

  const btnClasses = joinClasses(styles.dateDisplayFullWidth, selectedDate ? '' : styles.error);

  const CustomDisplay = forwardRef(({ value, onClick }: React.HTMLProps<any>, ref: React.Ref<HTMLButtonElement>) => (
    <button className={btnClasses} onClick={onClick} ref={ref}>
      {label || 'Date'}: {value}
    </button>
  ));
  CustomDisplay.displayName = 'CustomDisplay';

  return (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      selected={selectedDate}
      onChange={onDateSelect}
      calendarContainer={CustomContainer}
      customInput={<CustomDisplay />}
      disabledKeyboardNavigation
    />
  );
};

export default DateSinglePicker;

interface DateRangePickerProps {
  selectedDate: Date;
  onDateSelect: (d: Date) => void;
  label?: string;
}
interface CustomContainerProps {
  className: string;
  children: React.ReactNode[];
}
