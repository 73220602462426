import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as dealerEntityPb from '../../grpc/build/protos/dealer_pb';
import * as entitiesPb from '../../grpc/build/protos/entities_pb';
import { useApiReady } from '../utils/useApiReady';
import { CreateDealershipProps, DealerEntity } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useCreateDealership = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function createDealership(props: CreateDealershipProps): Promise<DealerEntity> {
    if (!isConnectionReady) return {} as DealerEntity;
    const {
      name,
      legalName,
      gstNumber,
      pstNumber,
      provinceRegistrationNo,
      phone,
      email,
      primaryMake,
      subscriptionPlan,
      googlePlaceId,
      address,
      externalDealerId,
      accountantEmail,
      autoGroupId,
      dealershipReferrerId,
      dealershipReferrerNotes,
      controllerEmail,
      salesManagerEmail,
      gmEmail,
    } = props;

    const dealershipReferral = new entitiesPb.DealershipReferral();
    dealershipReferral.setReferrerId(dealershipReferrerId);
    dealershipReferral.setNotes(dealershipReferrerNotes);

    const request = new adminApiv1Pb.CreateDealershipRequest();
    const ds = new dealerEntityPb.Dealer();
    ds.setName(name);
    ds.setLegalName(legalName);
    ds.setGstNumber(gstNumber);
    ds.setPstNumber(pstNumber);
    ds.setProvinceRegistrationNo(provinceRegistrationNo);
    ds.setPhone(phone);
    ds.setEmail(email);
    ds.setPrimaryMake(primaryMake);
    ds.setSubscriptionPlan(subscriptionPlan);
    ds.setGooglePlaceId(googlePlaceId);
    ds.setExternalDealerId(externalDealerId);
    ds.setAccountantEmail(accountantEmail);
    ds.setAutoGroupId(autoGroupId);
    ds.setDealershipReferral(dealershipReferral);
    ds.setControllerEmail(controllerEmail);
    ds.setSalesManagerEmail(salesManagerEmail);
    ds.setGmEmail(gmEmail);

    const { street, city, division, postal, telephoneNumber, faxNumber, country } = address;
    const loc = new dealerEntityPb.DealerLocation();
    loc.setStreet(street);
    loc.setCity(city);
    loc.setState(division);
    loc.setCountry(country);
    loc.setPostal(postal);
    loc.setTelephoneNumber(telephoneNumber);
    faxNumber && loc.setFaxNumber(faxNumber);

    ds.setDealerLocation(loc);

    request.setDealer(ds);

    const response = await client.createDealership(request, authHeader);
    return response.toObject().dealer || ({} as DealerEntity);
  }

  return createDealership;
};
