import { ChangeEvent, useState } from 'react';

import { User } from '../../composables/api/types';
import { useCompleteSale } from '../../composables/api/useCompleteSale';
import { useConsumerBuyAddNonListedSale } from '../../composables/api/useConsumerBuyAddNonListedSale';
import { useDealerships } from '../../composables/api/useDealerships';
import useCurrency from '../../composables/utils/useCurrency';
import useDateSinglePicker from '../../composables/utils/useDateSinglePicker';
import useInput from '../../composables/utils/useInput';
import useMileageUom from '../../composables/utils/useMileageUom';
import useNumberInput from '../../composables/utils/useNumberInput';
import useRegions from '../../composables/utils/useRegions';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';
import BaseSelectOption from '../base/BaseSelectOption';
import DateSinglePicker from '../utils/DateSinglePicker';
import NumInput from '../utils/inputs/NumInput';
import SelectInput from '../utils/inputs/SelectInput';
import TextInput from '../utils/inputs/TextInput';
import RadioButtons from '../utils/RadioButtons';
import SelectConsumerUser from '../utils/SelectConsumerUser';

type CompleteConsumerSaleProps = {
  wayOfSale: enumPb.WayOfSale;
};

function CompleteNewVehicleSale({ wayOfSale }: CompleteConsumerSaleProps) {
  const defaultListSize = 30;

  const [price, { handleChange: handlePriceChange, setInputVal: setPrice }] = useNumberInput();
  const [user, setUser] = useState<User | null>(null);
  const [street, { handleChange: handleStreetChange, setInputVal: setStreet }] = useInput('');
  const { country, setCountry, division, setDivision, countries, divisions, setMultiple: loadLocation } = useRegions();
  const [city, { handleChange: handleCityChange, setInputVal: setCity }] = useInput('');
  const [postal, { handleChange: handlePostalChange, setInputVal: setPostal }] = useInput('');
  const [dealerId, { handleChange: handleDealerIdChange, setInputVal: setDealerId }] = useInput('');
  const { dealerships, getDealerships, searchingDealerships } = useDealerships();
  const { currency, currencyOptions, setCurrency, resetCurrency } = useCurrency();
  const { selectedDate, setSelectedDate, selectedDateInNumber, resetDate } = useDateSinglePicker();
  const { createSale } = useCompleteSale();
  const [vin, { handleChange: handleVinChange, setInputVal: setVin }] = useInput('');
  const [make, { handleChange: handleMakeChange, setInputVal: setMake }] = useInput('');
  const [model, { handleChange: handleModelChange, setInputVal: setModel }] = useInput('');
  const [year, { handleChange: handleYearChange, setInputVal: setYear }] = useInput('');
  const [trim, { handleChange: handleTrimChange, setInputVal: setTrim }] = useInput('');
  const [mileage, { handleChange: handleMileageChange, setInputVal: setMileage }] = useNumberInput(0);
  const { mileageUom, mileageUomOptions, setMileageUom, resetMileageUom } = useMileageUom();
  const { addNonListedSale, addingSale } = useConsumerBuyAddNonListedSale();
  const [createdStatus, setCreatedStatus] = useState<'new' | 'created' | 'error'>('new');

  async function handleGetDealershipsByName(name: string) {
    try {
      await getDealerships({
        name,
        pageSize: defaultListSize,
        offSet: 1,
      });
    } catch (error) {
      console.error('handleGetDealershipsByName ', error);
    }
  }

  function handleUserSelect(user: User | null) {
    if (!user) return;

    setUser(user);
    setStreet(user.location?.address1 || '');
    loadLocation({
      country: user.location?.country,
      division: user.location?.state,
    });
    setCity(user.location?.city || '');
    setPostal(user.location?.postalCode || '');
  }

  function handleReset() {
    resetDate();
    setPrice(0);
    resetCurrency();
    setDealerId('');
    setUser(null);
    setStreet('');
    loadLocation({
      country: '',
      division: '',
    });
    setCity('');
    setPostal('');
    setVin('');
    setMake('');
    setModel('');
    setYear('');
    setTrim('');
    setMileage(0);
    resetMileageUom();
  }

  async function handleCreateSale() {
    // todo: remove console.log after QA testing
    console.log(user, dealerId, price, currency, vin, wayOfSale, selectedDateInNumber, createSale);
    try {
      if (user === null) return;
      await addNonListedSale({
        user,
        vin,
        make,
        model,
        year: +year,
        trim,
        mileage,
        mileageUom,
        price,
        currency,
        dealerId,
        effectiveDate: selectedDateInNumber,
      });
      setCreatedStatus('created');
    } catch (error) {
      console.error('handleCreateSale ', error);
      setCreatedStatus('error');
    }
  }

  return (
    <div className={addingSale ? `${styles.disabledOverlay}` : `${styles.CompleteConsumerSaleContainer}`}>
      <h3>Complete Non Listed Sale</h3>
      <div className={styles.miscContainer}>
        <label htmlFor="date">Sale date*</label>
        <DateSinglePicker label="Sale complete date" selectedDate={selectedDate} onDateSelect={setSelectedDate} />
      </div>

      {user && (
        <fieldset>
          <legend>Address</legend>
          <TextInput
            label="Street"
            name="street"
            value={street}
            handleChange={handleStreetChange}
            disabled={addingSale}
          />
          <div className={styles.sectionTwo}>
            <TextInput label="City" name="city" value={city} handleChange={handleCityChange} />
          </div>

          <div className={styles.sectionTwo}>
            <SelectInput
              label="Country"
              name="country"
              required
              id="countrySelect"
              value={country}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setCountry(e.target.value);
              }}>
              <BaseSelectOption value=""></BaseSelectOption>
              {countries.map(({ name, cca2 }) => (
                <BaseSelectOption key={cca2} value={cca2}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
            <SelectInput
              label="Province / State"
              name="division"
              value={division}
              required
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setDivision(e.target.value);
              }}>
              <BaseSelectOption value=""></BaseSelectOption>
              {divisions.map(({ code, name }) => (
                <BaseSelectOption key={code} value={code}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
          </div>

          <TextInput label="Postal Code" name="postal" value={postal} handleChange={handlePostalChange} />
        </fieldset>
      )}

      <div className={styles.miscContainer}>
        <label htmlFor="Select Consumer">Consumer</label>
        <SelectConsumerUser onSelect={handleUserSelect} onReset={handleReset} />
      </div>
      <div className={styles.miscContainer}>
        <label htmlFor="vin">VIN*</label>
        <TextInput label="" name="vin" value={vin} onChange={handleVinChange} />
      </div>
      <div className={styles.miscContainer}>
        <label htmlFor="make">Make*</label>
        <TextInput label="" name="make" value={make} onChange={handleMakeChange} />
      </div>
      <div className={styles.miscContainer}>
        <label htmlFor="model">Model*</label>
        <TextInput label="" name="model" value={model} onChange={handleModelChange} />
      </div>
      <div className={styles.miscContainer}>
        <NumInput value={year} label="Year*" handleChange={handleYearChange} />
      </div>
      <div className={styles.miscContainer}>
        <label htmlFor="trim">Trim*</label>
        <TextInput label="" name="trim" value={trim} onChange={handleTrimChange} />
      </div>
      <NumInput value={mileage.toString()} label="Mileage*" handleChange={handleMileageChange} />
      <div className={styles.miscContainer}>
        <label htmlFor="mileageUom">Mileage Units of Measure</label>
        <div id="mileageUom">
          <RadioButtons options={mileageUomOptions} currentSelected={mileageUom} onClick={setMileageUom} />
        </div>
      </div>
      <NumInput value={price.toString()} label="Price*" handleChange={handlePriceChange} />
      <div className={styles.miscContainer}>
        <label htmlFor="currency">Currency</label>
        <div id="currency">
          <RadioButtons options={currencyOptions} currentSelected={currency} onClick={setCurrency} />
        </div>
      </div>
      <div className={styles.miscContainer}>
        <label htmlFor="dealerId">Dealership ID</label>
        <input id="dealerId" value={dealerId} disabled />
        <div className={searchingDealerships ? `${styles.disabledOverlay}` : `${styles.selectDealerships}`}>
          <BaseSearchInput
            placeholder="Search Dealerships by Keyword"
            onSearch={handleGetDealershipsByName}
            disabled={searchingDealerships}
          />
          <BaseExpandSelect
            label="Choose Dealership*:"
            onChange={handleDealerIdChange}
            options={dealerships}
            selectedValue={dealerId}
            isDisabled={searchingDealerships}
          />
        </div>
      </div>
      <BaseButtonOutline
        display="Create Non Listed Sale"
        onClick={handleCreateSale}
        small
        className={styles.createSaleBtn}
        disabled={!+price || !dealerId}
      />
      {createdStatus === 'created' && (
        <p className={styles.messageSuccess}>Success! Completed Sale record has been created.</p>
      )}

      {createdStatus === 'error' && (
        <p className={styles.messageErr}>Create sale unsuccessful. This vehicle might have completed a sale already.</p>
      )}
    </div>
  );
}

export default CompleteNewVehicleSale;
