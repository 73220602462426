import { SubscriptionPlan } from '../grpc/build/protos/enum_pb';

const subscriptionPlanMap = {
  [SubscriptionPlan.SUBSCRIPTION_PLAN_INVALID]: 'No Plan Selected',
  [SubscriptionPlan.SUBSCRIPTION_PLAN_DEMO]: 'Demo',
  [SubscriptionPlan.SUBSCRIPTION_PLAN_FREE]: 'Free',
  [SubscriptionPlan.SUBSCRIPTION_PLAN_STARTER]: 'Starter',
  [SubscriptionPlan.SUBSCRIPTION_PLAN_PREMIUM]: 'Premium',
  [SubscriptionPlan.SUBSCRIPTION_PLAN_UNLIMITED]: 'Unlimited',
};

export const subscriptionPlans = Object.keys(subscriptionPlanMap).map((plan) => {
  return {
    id: plan,
    name: convertSubscriptionPlan(plan as unknown as SubscriptionPlan),
  };
});

export function convertSubscriptionPlan(plan: SubscriptionPlan): string {
  return subscriptionPlanMap[plan] || 'No Plan Selected';
}

export function convertSubscriptionPlanToValue(plan: string): SubscriptionPlan {
  return parseInt(plan);
}
