import React from 'react';
import { RiArrowDropRightLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import { NAV_LIST } from '../../constants/navigation';
import { useNavDrawer } from '../../contexts/NavDrawerContext';
import { useViewport } from '../../contexts/ViewPortContext';
import { joinClasses } from '../../helpers/utils';
import styles from '../../scss/NavDrawer.module.scss';

interface ActiveNavLink {
  isActive: boolean;
}

function NavDrawer() {
  const { isDrawerOpen, setIsDrawerOpen } = useNavDrawer();
  const { isTablet, isMobile } = useViewport();
  const drawerContainerClasses = joinClasses(styles.navDrawer, isDrawerOpen ? styles.open : '');
  const { closeUtilityDrawer } = useUtilityDrawer();

  const setActiveStyle =
    (color: string) =>
    ({ isActive }: ActiveNavLink) => ({
      color: isActive ? color : '',
      borderColor: isActive ? color : '',
    });

  const onClickMenuItem = () => {
    closeUtilityDrawer();
    (isTablet || isMobile) && setIsDrawerOpen(false);
  };

  return (
    <div className={drawerContainerClasses}>
      {NAV_LIST.map((group, idx) => (
        <div key={idx} className={styles.navGroup}>
          {group.map((subGroup, idx) => (
            <span key={idx} className={styles.navSubGroup}>
              <GroupHeading key={subGroup.heading} icon={<subGroup.Icon />} text={subGroup.heading} to={subGroup.to} />

              {subGroup.links &&
                subGroup.links.map((menu) => (
                  <NavLink
                    className={styles.navLink}
                    key={menu.text}
                    style={setActiveStyle('#028365')}
                    to={menu.to}
                    title={menu.text}
                    onClick={onClickMenuItem}>
                    <span className={styles.icon}>
                      <RiArrowDropRightLine />
                    </span>
                    <span>{menu.text}</span>
                  </NavLink>
                ))}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}

interface NavExpanderProps {
  icon: React.ReactElement;
  text: string;
  to?: string;
}
function GroupHeading({ icon, text, to }: NavExpanderProps) {
  if (to) {
    return (
      <NavLink to={to} className={`${styles.navLinkHead} ${styles.navLink}`} role="list">
        <span className={styles.icon}>{icon}</span>
        <span>{text}</span>
      </NavLink>
    );
  }

  return (
    <div className={styles.navLinkHead} role="list">
      <span className={styles.icon}>{icon}</span>
      <span>{text}</span>
    </div>
  );
}

export default NavDrawer;
