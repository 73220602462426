import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const domain = `${process.env.REACT_APP_ADMIN_AUTH0_DOMAIN}`;
  const clientId = `${process.env.REACT_APP_ADMIN_AUTH0_CLIENT_ID}`;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage">
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
