import { ChangeEvent, useEffect } from 'react';
import { useParams } from 'react-router';

import { useAutoGroups } from '../../composables/api/useAutoGroups';
import { useDealershipReferrers } from '../../composables/api/useDealershipReferrers';
import { useDealerships } from '../../composables/api/useDealerships';
import { useGetDealership } from '../../composables/api/UseGetDealership';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import useRegions from '../../composables/utils/useRegions';
import { convertSubscriptionPlanToValue, subscriptionPlans } from '../../helpers/subscriptions';
import styles from '../../scss/CreateDealer.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';
import BaseSearchInput from '../base/BaseSearchInput';
import BaseSelectOption from '../base/BaseSelectOption';
import AddImageDealer from '../utils/AddImageDealer';
import SelectInput from '../utils/inputs/SelectInput';
import { SelectReferrer } from './SelectReferrer';

function UpdateDealership() {
  const defaultListSize = 30;
  const [dealershipId, { setInputVal: setDealershipId }] = useInput('');
  const [street, { handleChange: handleStreetChange, setInputVal: setStreet }] = useInput('');
  const { country, setCountry, division, setDivision, countries, divisions, setMultiple: loadLocation } = useRegions();
  const [city, { handleChange: handleCityChange, setInputVal: setCity }] = useInput('');
  const [postal, { handleChange: handlePostalChange, setInputVal: setPostal }] = useInput('');
  const [phone, { handleChange: handlePhoneChange, setInputVal: setPhone }] = useInput('');
  const [primaryMake, { handleChange: handlePrimaryMakeChange, setInputVal: setPrimaryMake }] = useInput('');
  const [legalName, { handleChange: handleLegalNameChange, setInputVal: setLegalName }] = useInput('');
  const [gst, { handleChange: handleGstChange, setInputVal: setGst }] = useInput('');
  const [autoGroupId, { setInputVal: setAutoGroupId }] = useInput('');
  const [pst, { handleChange: handlePstChange, setInputVal: setPst }] = useInput('');
  const [rin, { handleChange: handleRinChange, setInputVal: setRin }] = useInput('');
  const [controllerEmail, { handleChange: handleControllerEmailChange, setInputVal: setControllerEmail }] =
    useInput('');
  const [salesManagerEmail, { handleChange: handleSalesManagerEmailChange, setInputVal: setSalesManagerEmail }] =
    useInput('');
  const [gmEmail, { handleChange: handleGmEmailChange, setInputVal: setGmEmail }] = useInput('');
  const [dealerRegistration, { handleChange: handleDealerRegistration, setInputVal: setDealerRegistration }] =
    useInput('');
  const [subscriptionPlanId, { setInputVal: setSubscriptionPlan }] = useInput('');
  const [logoPath, { setInputVal: setLogoPath }] = useInput('');
  const [googlePlaceId, { handleChange: handleGooglePlaceIdChange, setInputVal: setGooglePlaceId }] = useInput('');
  const [externalDealerId, { handleChange: handleExternalDealerIdChange, setInputVal: setExternalDealerId }] =
    useInput('');
  const [accountantEmail, { handleChange: handleAccountantEmailChange, setInputVal: setAccountantEmail }] =
    useInput('');
  const [
    dealershipReferrerId,
    { handleChange: handleDealershipReferrerIdChange, setInputVal: setDealershipReferrerId },
  ] = useInput('');
  const [
    dealershipReferrerNotes,
    { handleChange: handleDealershipReferrerNotesChange, setInputVal: setDealershipReferrerNotes },
  ] = useInput('');
  const { dealerships, updateDealership, deleteDealershipLogo, getDealerships } = useDealerships();
  const { getDealership } = useGetDealership();
  const enableCreateBtn = [country, division, dealershipId].every((fieldValue) => !!fieldValue.trim());
  const BTN_TEXT = {
    initialText: 'Update Dealership',
    confirmationText: 'Click again to update dealership',
    completionText: 'Dealership Updated!',
  };
  const handleUpdateDealership = async () => {
    try {
      const originDealership = await getDealership(dealershipId);
      const subscriptionPlan = convertSubscriptionPlanToValue(subscriptionPlanId);

      if (!originDealership) return;

      await updateDealership({
        street,
        country,
        division,
        city,
        postal,
        phone,
        primaryMake,
        legalName,
        gst,
        pst,
        dealerRegistration,
        rin,
        originDealership,
        subscriptionPlan,
        googlePlaceId,
        externalDealerId,
        accountantEmail,
        autoGroupId,
        dealershipReferrerId,
        dealershipReferrerNotes,
        controllerEmail,
        salesManagerEmail,
        gmEmail,
      });
    } catch (error) {
      console.error('handleUpdateDealership ', error);
    }
  };

  const handleDeleteDealershipLogo = async () => {
    await deleteDealershipLogo(dealershipId);
  };

  const handleGetDealershipsByName = async (name: string) => {
    try {
      await getDealerships({
        name,
        pageSize: defaultListSize,
        offSet: 1,
      });
    } catch (error) {
      console.error('handleGetDealershipsByName ', error);
    }
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleUpdateDealership,
    useRestore: true,
  });

  const handleDealershipSelect = async (selectedDealershipId: string) => {
    const originalId = dealershipId;
    setDealershipId(selectedDealershipId);

    try {
      const dealership = await getDealership(selectedDealershipId);
      if (!dealership) {
        setDealershipId(originalId);
        return;
      }

      setStreet(dealership.dealerLocation?.street || '');
      loadLocation({
        country: dealership.dealerLocation?.country,
        division: dealership.dealerLocation?.state,
      });
      setCountry(dealership.dealerLocation?.country || '');
      setDivision(dealership.dealerLocation?.state || '');
      setCity(dealership.dealerLocation?.city || '');
      setPostal(dealership.dealerLocation?.postal || '');
      setPhone(dealership.phone);
      setPrimaryMake(dealership.primaryMake);
      setLegalName(dealership.legalName);
      setGst(dealership.gstNumber);
      setPst(dealership.pstNumber);
      setDealerRegistration(dealership.provinceRegistrationNo);
      setSubscriptionPlan(dealership.subscriptionPlan.toString());
      setGooglePlaceId(dealership.googlePlaceId);
      setLogoPath(dealership.logoPath);
      setExternalDealerId(dealership.externalDealerId);
      setAccountantEmail(dealership.accountantEmail);
      setAutoGroupId(dealership.autoGroupId);
      setRin(dealership.rin);
      setControllerEmail(dealership.controllerEmail);
      setSalesManagerEmail(dealership.salesManagerEmail);
      setGmEmail(dealership.gmEmail);

      setDealershipReferrerId(dealership.dealershipReferral?.referrerId || '');
      setDealershipReferrerNotes(dealership.dealershipReferral?.notes || '');
    } catch (error) {
      console.error('handleDealershipSelect ', error);
    }
  };

  const { dealershipReferrers } = useDealershipReferrers();
  const { autoGroups } = useAutoGroups();

  let { id } = useParams();

  useEffect(() => {
    if (id != undefined && dealerships.length > 0) {
      handleDealershipSelect(id);
    }
  }, [dealerships]);

  return (
    <div className={styles.formContainer}>
      <h2>Update Dealership</h2>
      {logoPath && (
        <>
          <img
            className={styles.logoPreview}
            src={`${process.env.REACT_APP_IMAGE_SERVER_ADDRESS}/${logoPath}`}
            alt="tmp"></img>
          <BaseButtonOutline display="Delete Logo" onClick={handleDeleteDealershipLogo}></BaseButtonOutline>
        </>
      )}
      <div>
        <AddImageDealer dealerId={dealershipId} />
        <section className={styles.settings}>
          <div>
            <label htmlFor="dealerId">Dealership ID *</label>
            <input id="dealerId" value={dealershipId} disabled />

            <div className={styles.selectDealerships}>
              <BaseSearchInput placeholder="Search Dealerships by Keyword" onSearch={handleGetDealershipsByName} />
              <BaseExpandSelect
                label="Choose Dealership:"
                selectEvent={handleDealershipSelect}
                options={dealerships}
                selectedValue={dealershipId}
              />
            </div>
          </div>
          <div>
            <label htmlFor="legalName">Legal Name</label>
            <input id="legalName" value={legalName} onChange={handleLegalNameChange} />
          </div>
          <div>
            <label htmlFor="street">Street</label>
            <input id="street" value={street} onChange={handleStreetChange} />
          </div>

          <div>
            <SelectInput
              label="Country"
              name="country"
              required
              id="countrySelect"
              value={country}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setCountry(e.target.value);
              }}>
              <BaseSelectOption value=""></BaseSelectOption>
              {countries.map(({ name, cca2 }) => (
                <BaseSelectOption key={cca2} value={cca2}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              label="Province / State"
              name="division"
              value={division}
              required
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setDivision(e.target.value);
              }}>
              <BaseSelectOption value=""></BaseSelectOption>
              {divisions.map(({ code, name }) => (
                <BaseSelectOption key={code} value={code}>
                  {name}
                </BaseSelectOption>
              ))}
            </SelectInput>
          </div>

          <div>
            <label htmlFor="city">City</label>
            <input id="city" value={city} onChange={handleCityChange} />
          </div>
          <div>
            <label htmlFor="postal">Postal</label>
            <input id="postal" value={postal} onChange={handlePostalChange} />
          </div>
          <div>
            <label htmlFor="primaryMake">Primary Make</label>
            <input id="primaryMake" value={primaryMake} onChange={handlePrimaryMakeChange} />
          </div>
          <div>
            <label htmlFor="rin">RIN Number (Dealership Specific Number)</label>
            <input id="rin" value={rin} onChange={handleRinChange} />
          </div>
          <div>
            <label htmlFor="controllerEmail">Controller Email</label>
            <input id="controllerEmail" value={controllerEmail} onChange={handleControllerEmailChange} />
          </div>
          <div>
            <label htmlFor="salesManagerEmail">Sales Manager Email</label>
            <input id="salesManagerEmail" value={salesManagerEmail} onChange={handleSalesManagerEmailChange} />
          </div>
          <div>
            <label htmlFor="gmEmail">General Manager Email</label>
            <input id="gmEmail" value={gmEmail} onChange={handleGmEmailChange} />
          </div>
          <div>
            <label htmlFor="phoneNumber">Phone Number</label>
            <input id="phoneNumber" value={phone} onChange={handlePhoneChange} />
          </div>
          <div>
            <label htmlFor="gstNumber">GST Number</label>
            <input id="gstNumber" value={gst} onChange={handleGstChange} />
          </div>
          <div>
            <label htmlFor="pstNumber">PST Number</label>
            <input id="pstNumber" value={pst} onChange={handlePstChange} />
          </div>
          <div>
            <label htmlFor="dealerRegistrationNumber">Dealer Registration Number</label>
            <input id="dealerRegistrationNumber" value={dealerRegistration} onChange={handleDealerRegistration} />
          </div>
          <div>
            <label htmlFor="googlePlaceId">Google Place ID</label>
            <input id="googlePlaceId" value={googlePlaceId} onChange={handleGooglePlaceIdChange} />
          </div>
          <div>
            <label htmlFor="externalDealerId">External Dealer ID</label>
            <input id="externalDealerId" value={externalDealerId} onChange={handleExternalDealerIdChange} />
          </div>
          <div>
            <label htmlFor="accountantEmail">Accountant Email</label>
            <input id="accountantEmail" value={accountantEmail} onChange={handleAccountantEmailChange} />
          </div>
          <div>
            <SelectReferrer
              dealershipReferrerId={dealershipReferrerId}
              dealershipReferrerNotes={dealershipReferrerNotes}
              onDealershipReferrerIdChange={handleDealershipReferrerIdChange}
              onDealershipReferrerNotesChange={handleDealershipReferrerNotesChange}
              dealershipReferrers={dealershipReferrers}
            />
          </div>
          <div>
            <BaseExpandSelect
              label="Select AutoGroup:"
              selectEvent={setAutoGroupId}
              options={autoGroups}
              selectedValue={autoGroupId}
              useEmptyOption={true}
            />
          </div>
          <div>
            <BaseExpandSelect
              label="Choose Subscription Plan:"
              selectEvent={setSubscriptionPlan}
              options={subscriptionPlans}
              selectedValue={subscriptionPlanId}
              useEmptyOption={false}
              isDisabled={autoGroupId !== ''}
            />
          </div>
          <div>
            <BaseButtonOutline
              display={confirmBtnText}
              onClick={doubleCheck}
              disabled={!enableCreateBtn || isConfirmBtnDisabled}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default UpdateDealership;
