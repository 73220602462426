import React, { createContext, useContext } from 'react';

import { Apiv1Client } from '../grpc/build/protos/Adminapiv1ServiceClientPb';

type AdminClientContent = {
  client: Apiv1Client;
};

const client = new Apiv1Client(process.env.REACT_APP_ADMIN_APIV1_ADDRESS as string, null, null);

export const AdminClientContext = createContext<AdminClientContent>({
  client,
});

export const AdminClientProvider: React.FC = ({ children }) => {
  return <AdminClientContext.Provider value={{ client }}>{children}</AdminClientContext.Provider>;
};

export const useAdminClient = () => useContext(AdminClientContext);
