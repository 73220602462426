// source: build/protos/enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.entity.enum.AdvertisementCriteria', null, global);
goog.exportSymbol('proto.entity.enum.AuctionStatus', null, global);
goog.exportSymbol('proto.entity.enum.BidErrorCode', null, global);
goog.exportSymbol('proto.entity.enum.Currency', null, global);
goog.exportSymbol('proto.entity.enum.DashboardDocumentType', null, global);
goog.exportSymbol('proto.entity.enum.DeclarationQuestionType', null, global);
goog.exportSymbol('proto.entity.enum.DocumentStatus', null, global);
goog.exportSymbol('proto.entity.enum.ErrorMessage', null, global);
goog.exportSymbol('proto.entity.enum.FlagCategory', null, global);
goog.exportSymbol('proto.entity.enum.ImageSource', null, global);
goog.exportSymbol('proto.entity.enum.InspectionProgress', null, global);
goog.exportSymbol('proto.entity.enum.ListingSort', null, global);
goog.exportSymbol('proto.entity.enum.ListingStatus', null, global);
goog.exportSymbol('proto.entity.enum.Marketplace', null, global);
goog.exportSymbol('proto.entity.enum.MessageTemplateType', null, global);
goog.exportSymbol('proto.entity.enum.MileageUOM', null, global);
goog.exportSymbol('proto.entity.enum.OfferDocumentType', null, global);
goog.exportSymbol('proto.entity.enum.OfferOrigin', null, global);
goog.exportSymbol('proto.entity.enum.OfferStatus', null, global);
goog.exportSymbol('proto.entity.enum.PaymentMethod', null, global);
goog.exportSymbol('proto.entity.enum.PaymentStatus', null, global);
goog.exportSymbol('proto.entity.enum.ReferrerSource', null, global);
goog.exportSymbol('proto.entity.enum.ScheduleOption', null, global);
goog.exportSymbol('proto.entity.enum.SortVehicles', null, global);
goog.exportSymbol('proto.entity.enum.SubscriptionPlan', null, global);
goog.exportSymbol('proto.entity.enum.TransactionStatus', null, global);
goog.exportSymbol('proto.entity.enum.UpsellType', null, global);
goog.exportSymbol('proto.entity.enum.UserOrigin', null, global);
goog.exportSymbol('proto.entity.enum.UserRole', null, global);
goog.exportSymbol('proto.entity.enum.VehicleBadge', null, global);
goog.exportSymbol('proto.entity.enum.VehicleOrigin', null, global);
goog.exportSymbol('proto.entity.enum.VehicleOwnerType', null, global);
goog.exportSymbol('proto.entity.enum.VehicleRejectionCategory', null, global);
goog.exportSymbol('proto.entity.enum.VehicleSensitiveInformationCategory', null, global);
goog.exportSymbol('proto.entity.enum.VehicleSource', null, global);
goog.exportSymbol('proto.entity.enum.VehicleStatus', null, global);
goog.exportSymbol('proto.entity.enum.WayOfSale', null, global);
/**
 * @enum {number}
 */
proto.entity.enum.InspectionProgress = {
  INSPECTION_PROGRESS_INVALID: 0,
  INSPECTION_PROGRESS_CREATED: 1,
  INSPECTION_PROGRESS_STARTED: 2,
  INSPECTION_PROGRESS_PROCESSING: 3,
  INSPECTION_PROGRESS_QC_PASSED: 4,
  INSPECTION_PROGRESS_CONFIRM: 5,
  INSPECTION_PROGRESS_COMPLETE: 6
};

/**
 * @enum {number}
 */
proto.entity.enum.UserOrigin = {
  USER_ORIGIN_INVALID: 0,
  USER_ORIGIN_DEALER: 1,
  USER_ORIGIN_CONSUMER: 2,
  USER_ORIGIN_ADMIN: 3,
  USER_ORIGIN_BCAA: 4
};

/**
 * @enum {number}
 */
proto.entity.enum.UserRole = {
  USER_ROLE_INVALID: 0,
  USER_ROLE_DEALER: 1,
  USER_ROLE_DEALER_ADMIN: 2,
  USER_ROLE_SERVICE: 3,
  USER_ROLE_ADMIN: 4,
  USER_ROLE_CONSUMER: 5
};

/**
 * @enum {number}
 */
proto.entity.enum.Marketplace = {
  MARKETPLACE_ORIGIN_INVALID: 0,
  MARKETPLACE_ORIGIN_MINTLIST: 1,
  MARKETPLACE_ORIGIN_BCAA: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.Currency = {
  CURRENCY_INVALID: 0,
  CURRENCY_CURRENCY_CAD: 1,
  CURRENCY_CURRENCY_USD: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.OfferOrigin = {
  OFFER_ORIGIN_INVALID: 0,
  OFFER_ORIGIN_AUCTION: 1,
  OFFER_ORIGIN_BUYER: 2,
  OFFER_ORIGIN_SELLER: 3
};

/**
 * @enum {number}
 */
proto.entity.enum.AuctionStatus = {
  AUCTION_STATUS_INVALID: 0,
  AUCTION_STATUS_ACTIVE: 1,
  AUCTION_STATUS_COMPLETE: 2,
  AUCTION_STATUS_SCHEDULED: 3,
  AUCTION_STATUS_REMOVED: 4,
  AUCTION_STATUS_ACCEPTED: 5,
  AUCTION_STATUS_REJECTED: 6,
  AUCTION_STATUS_NONE: 7,
  AUCTION_STATUS_RELIST_READY: 8,
  AUCTION_STATUS_READY: 9
};

/**
 * @enum {number}
 */
proto.entity.enum.OfferStatus = {
  OFFER_STATUS_INVALID: 0,
  OFFER_STATUS_PENDING: 1,
  OFFER_STATUS_ACCEPTED: 2,
  OFFER_STATUS_REJECTED: 3,
  OFFER_STATUS_COMPLETED: 4
};

/**
 * @enum {number}
 */
proto.entity.enum.MileageUOM = {
  MILEAGE_UOM_INVALID: 0,
  MILEAGE_UOM_MILES: 1,
  MILEAGE_UOM_KILOMETER: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.DocumentStatus = {
  DOCUMENT_STATUS_INVALID: 0,
  DOCUMENT_STATUS_SENT: 1,
  DOCUMENT_STATUS_DECLINE: 2,
  DOCUMENT_STATUS_COMPLETE: 3,
  DOCUMENT_STATUS_ERROR: 4
};

/**
 * @enum {number}
 */
proto.entity.enum.BidErrorCode = {
  BID_ERROR_CODE_UNKNOWN_ERROR: 0,
  BID_ERROR_HAS_DUPILICATE: 1,
  BID_ERROR_HAS_CONCURRENT: 2,
  BID_ERROR_LOWER_THAN_FLOOR_PRICE: 3,
  BID_ERROR_INSUFFICIENT_SUBSCRIPTION_PLAN: 4
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleOrigin = {
  VEHICLE_ORIGIN_INVALID: 0,
  VEHICLE_ORIGIN_CONSUMER: 1,
  VEHICLE_ORIGIN_DEALER: 2,
  VEHICLE_ORIGIN_BCAA: 3
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleSource = {
  VEHICLE_SOURCE_INVALID: 0,
  VEHICLE_SOURCE_MINTLIST: 1,
  VEHICLE_SOURCE_BCAA: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleOwnerType = {
  VEHICLE_OWNER_TYPE_INVALID: 0,
  VEHICLE_OWNER_TYPE_PRIMARY_OWNER: 1,
  VEHICLE_OWNER_TYPE_CO_OWNER: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleStatus = {
  VEHICLE_STATUS_INVALID: 0,
  VEHICLE_STATUS_INSPECTION_CREATED: 1,
  VEHICLE_STATUS_INSPECTION_STARTED: 2,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE: 3,
  VEHICLE_STATUS_INSPECTION_COMPLETED: 4,
  VEHICLE_STATUS_AUCTION_INCLUDED: 5,
  VEHICLE_STATUS_AUCTION_READY: 6,
  VEHICLE_STATUS_AUCTION_ACTIVE: 7,
  VEHICLE_STATUS_AUCTION_COMPLETE: 8,
  VEHICLE_STATUS_AUCTION_ACCEPTED: 9,
  VEHICLE_STATUS_AUCTION_REJECTED: 10,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS: 11,
  VEHICLE_STATUS_AUCTION_REMOVED: 12,
  VEHICLE_STATUS_AVAILABLE: 13,
  VEHICLE_STATUS_PENDING_SALE: 14,
  VEHICLE_STATUS_SOLD: 15,
  VEHICLE_STATUS_INSPECTION_REPORT_PROCESSING: 16,
  VEHICLE_STATUS_CONFIRMED: 17,
  VEHICLE_STATUS_DEACTIVATED: 18,
  VEHICLE_STATUS_CREATED: 19,
  VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED: 20
};

/**
 * @enum {number}
 */
proto.entity.enum.DashboardDocumentType = {
  DOCUMENT_TYPE_INVALID: 0,
  DOCUMENT_TYPE_DRIVERS_FRONT: 1,
  DOCUMENT_TYPE_DRIVERS_BACK: 2,
  DOCUMENT_TYPE_VEHICLE_REGISTRATION: 3,
  DOCUMENT_TYPE_PAVE_REPORT: 4,
  DOCUMENT_TYPE_CARFAX_LIEN: 5,
  DOCUMENT_TYPE_CARFAX_VHR: 6,
  DOCUMENT_TYPE_FINANCIAL_STATEMENT: 7
};

/**
 * @enum {number}
 */
proto.entity.enum.ImageSource = {
  IMAGE_SOURCE_INVALID: 0,
  IMAGE_SOURCE_PAVE: 1,
  IMAGE_SOURCE_USER: 2,
  IMAGE_SOURCE_VIN_DECODER: 3,
  IMAGE_SOURCE_FEED: 4
};

/**
 * @enum {number}
 */
proto.entity.enum.DeclarationQuestionType = {
  DECLARATION_QUESTION_TYPE_INVALID: 0,
  DECLARATION_QUESTION_TYPE_COMMERCIAL: 1,
  DECLARATION_QUESTION_TYPE_AIRBAG: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.ScheduleOption = {
  SCHEDULE_OPTION_INVALID: 0,
  SCHEDULE_OPTION_MORNING: 1,
  SCHEDULE_OPTION_AFTERNOON: 2,
  SCHEDULE_OPTION_EVENING: 3,
  SCHEDULE_OPTION_WEEKDAY: 4,
  SCHEDULE_OPTION_WEEKEND: 5
};

/**
 * @enum {number}
 */
proto.entity.enum.SubscriptionPlan = {
  SUBSCRIPTION_PLAN_INVALID: 0,
  SUBSCRIPTION_PLAN_DEMO: 1,
  SUBSCRIPTION_PLAN_FREE: 2,
  SUBSCRIPTION_PLAN_STARTER: 3,
  SUBSCRIPTION_PLAN_PREMIUM: 4,
  SUBSCRIPTION_PLAN_UNLIMITED: 5
};

/**
 * @enum {number}
 */
proto.entity.enum.OfferDocumentType = {
  OFFER_DOCUMENT_TYPE_INVALID: 0,
  OFFER_DOCUMENT_TYPE_BUY_IN_AGREEMENT: 1
};

/**
 * @enum {number}
 */
proto.entity.enum.MessageTemplateType = {
  MESSAGE_TEMPLAET_TYPE_INVALID: 0,
  MESSAGE_TEMPLATE_TYPE_CHECKOUT: 1,
  MESSAGE_TEMPLATE_TYPE_LEAD: 2,
  MESSAGE_TEMPLATE_TYPE_LEAD_CONSUMER_CONFIRMATION: 3,
  MESSAGE_TEMPLATE_TYPE_LEAD_DEALER: 4,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_OFFER_ACCEPTED: 5,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_OFFER_REJECTED: 6,
  MESSAGE_TEMPLATE_TYPE_AUCTION_OFFER_ACCEPTED: 7,
  MESSAGE_TEMPLATE_TYPE_AUCTION_OFFER_REJECTED: 8,
  MESSAGE_TEMPLATE_TYPE_OFFER_RECEIVED: 9,
  MESSAGE_TEMPLATE_TYPE_OFFER_SENT: 10,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_LISTED: 11,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_LISTING_EXPIRED: 12,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_COUNTER_OFFER_ACCEPTED: 13,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_COUNTER_OFFER_REJECTED: 14,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_COUNTER_OFFER_RECEIVED: 15,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_COUNTER_OFFER_SENT: 16,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_COUNTER_OFFER_EXPIRED: 17,
  MESSAGE_TEMPLATE_TYPE_DEAL_TANK_OFFER_EXPIRED: 18,
  MESSAGE_TEMPLATE_TYPE_ONBOARDING_PAYMENT: 19
};

/**
 * @enum {number}
 */
proto.entity.enum.TransactionStatus = {
  TRANSACTION_STATUS_ALL: 0,
  TRANSACTION_STATUS_SALE_IN_PROGRESS: 1,
  TRANSACTION_STATUS_SIGNED: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.ListingStatus = {
  INVALID: 0,
  INACTIVE: 1,
  ACTIVE: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.WayOfSale = {
  WAY_OF_SALE_INVALID: 0,
  WAY_OF_SALE_AUCTION: 1,
  WAY_OF_SALE_DEAL_TANK: 2,
  WAY_OF_SALE_CONSUMER_BUY: 3
};

/**
 * @enum {number}
 */
proto.entity.enum.ListingSort = {
  LISTING_SORT_INVALID: 0,
  LISTING_SORT_PRICE_DESC: 1,
  LISTING_SORT_PRICE_ASC: 2,
  LISTING_SORT_EXPIRATION_TIME_DESC: 3,
  LISTING_SORT_EXPIRATION_TIME_ASC: 4,
  LISTING_SORT_MILEAGE_ASC: 5
};

/**
 * @enum {number}
 */
proto.entity.enum.ReferrerSource = {
  REFFERER_SOURCE_INVALID: 0,
  REFERRER_SOURCE_INTERNAL: 1
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleRejectionCategory = {
  VEHICLE_REJECTION_CATEGORY_INVALID: 0,
  VEHICLE_REJECTION_CATEGORY_CONDITION: 1,
  VEHICLE_REJECTION_CATEGORY_MILEAGE: 2,
  VEHICLE_REJECTION_CATEGORY_VEHICLE_AGE: 3,
  VEHICLE_REJECTION_CATEGORY_LEASED: 4,
  VEHICLE_REJECTION_CATEGORY_MISSING_FIELDS: 5,
  VEHICLE_REJECTION_CATEGORY_MISSING_PHOTOS: 6,
  VEHICLE_REJECTION_CATEGORY_CONDITION_SALVAGE: 7,
  VEHICLE_REJECTION_CATEGORY_CONDITION_PARTS: 8,
  VEHICLE_REJECTION_CATEGORY_CONDITION_REBUILD: 9
};

/**
 * @enum {number}
 */
proto.entity.enum.SortVehicles = {
  SORT_VEHICLE_INVALID: 0,
  SORT_VEHICLE_PRICE_DESC: 1,
  SORT_VEHICLE_PRICE_ASC: 2,
  SORT_VEHICLE_YEAR_DESC: 3,
  SORT_VEHICLE_YEAR_ASC: 4,
  SORT_VEHICLE_MILEAGE_ASC: 5,
  SORT_VEHICLE_RECENT: 6,
  SORT_VEHICLE_CITY_ASC: 7,
  SORT_VEHICLE_MILEAGE_DESC: 8,
  SORT_VEHICLE_LOCATION_GEO_POINT_ASC: 9,
  SORT_VEHICLE_EXPIRATION_TIME_DESC: 10,
  SORT_VEHICLE_EXPIRATION_TIME_ASC: 11,
  SORT_VEHICLE_LISTING_PRICE_DESC: 12,
  SORT_VEHICLE_LISTING_PRICE_ASC: 13,
  SORT_VEHICLE_AUCTION_EXPIRATION_TIME_DESC: 14,
  SORT_VEHICLE_ENHANCED_SCORE: 15,
  SORT_VEHICLE_DEFAULT: 16
};

/**
 * @enum {number}
 */
proto.entity.enum.AdvertisementCriteria = {
  ADVERTISEMENT_CRITERIA_ALL: 0,
  ADVERTISEMENT_CRITERIA_VLP: 1,
  ADVERTISEMENT_CRITERIA_VDP: 2,
  ADVERTISEMENT_CRITERIA_MEMBER: 3,
  ADVERTISEMENT_CRITERIA_NON_MEMBER: 4
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleSensitiveInformationCategory = {
  VEHICLE_SENSITIVE_INFORMATION_CATEGORY_INVALID: 0,
  VEHICLE_SENSITIVE_INFORMATION_CATEGORY_CARFAX_VHR: 1,
  VEHICLE_SENSITIVE_INFORMATION_CATEGORY_INSPECTION_REPORT: 2,
  VEHICLE_SENSITIVE_INFORMATION_CATEGORY_ADDITIONAL_DOCUMENT: 3
};

/**
 * @enum {number}
 */
proto.entity.enum.ErrorMessage = {
  ERROR_INVALID: 0,
  OVER_MAXIMUM_FAVOURITES: 1
};

/**
 * @enum {number}
 */
proto.entity.enum.FlagCategory = {
  FLAG_CATEGORY_INVALID: 0,
  FLAG_CATEGORY_INACCURATE_PRICE_OR_DESCRIPTION: 1,
  FLAG_CATEGORY_MISLEADING_PHOTOS: 2,
  FLAG_CATEGORY_SELLER_STOPPED_RESPONDING: 3,
  FLAG_CATEGORY_ALREADY_SOLD: 4,
  FLAG_CATEGORY_OTHER: 5
};

/**
 * @enum {number}
 */
proto.entity.enum.UpsellType = {
  REGULAR: 0,
  FOR_BUNDLE_ONLY: 1,
  BUNDLE: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.VehicleBadge = {
  VEHICLE_BADGE_INVALID: 0,
  VEHICLE_BADGE_PROMOTED: 1,
  VEHICLE_BADGE_PREMIUM: 2
};

/**
 * @enum {number}
 */
proto.entity.enum.PaymentStatus = {
  PAYMENT_STATUS_INVALID: 0,
  PAYMENT_STATUS_PENDING: 1,
  PAYMENT_STATUS_FAILED: 2,
  PAYMENT_STATUS_SUCCEEDED: 3,
  PAYMENT_STATUS_MANUAL: 4,
  PAYMENT_STATUS_PROCESSING: 5
};

/**
 * @enum {number}
 */
proto.entity.enum.PaymentMethod = {
  DEBIT: 0,
  CARD: 1
};

goog.object.extend(exports, proto.entity.enum);
