import React from 'react';

import { useQuestionOptions } from '../../composables/api/useQuestionOptions';
import { useQuestions } from '../../composables/api/useQuestions';
import inspectionQuestionsStyles from '../../scss/InspectionQuestions.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseSelectOption from '../base/BaseSelectOption';
import SelectInput from '../utils/inputs/SelectInput';
import InspectionQuestionInstructions from './InspectionQuestionInstructions';
import InspectionQuestionOptionsTable from './InspectionQuestionOptionsTable';
import InspectionQuestionsTable from './InspectionQuestionsTable';

enum Marketplace {
  MARKETPLACE_ORIGIN_INVALID = 0,
  MARKETPLACE_ORIGIN_MINTLIST = 1,
  MARKETPLACE_ORIGIN_BCAA = 2,
}

const marketplaceOptions = [
  {
    slug: 'mintlist',
    display: 'MintList',
    value: Marketplace.MARKETPLACE_ORIGIN_MINTLIST,
  },
  {
    slug: 'bcaa',
    display: 'BCAA',
    value: Marketplace.MARKETPLACE_ORIGIN_BCAA,
  },
];

export default function InspectionQuestions() {
  const { marketplace, fetchQuestions, questions, loading, error, categories } = useQuestions();

  const { questionId, questionOptions, onUpdateQuestionId, errorOptions, warningOptions } = useQuestionOptions();

  async function onUpdate() {
    fetchQuestions(marketplace);
  }

  function onUpdateMarketplace(marketplace: number) {
    fetchQuestions(marketplace);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={inspectionQuestionsStyles.container} style={{ overflow: 'scroll' }}>
      {questionId && questionId.length > 0 ? (
        <>
          <h2>Question Id: {questionId}</h2>
          <InspectionQuestionOptionsTable
            options={questionOptions}
            onUpdate={onUpdateQuestionId}
            questionId={questionId}
            error={errorOptions}
            warning={warningOptions}
            categories={categories}
          />
          <BaseButtonOutline
            className={inspectionQuestionsStyles.backToQuestionsButton}
            onClick={() => onUpdateQuestionId('')}
            display={'Back to Questions'}
          />
        </>
      ) : (
        <>
          <SelectInput
            label="Marketplace"
            name="marketplace"
            value={`${marketplace}`}
            required
            onChange={(e) => onUpdateMarketplace(parseInt(e.target.value))}>
            <BaseSelectOption value=""></BaseSelectOption>
            {marketplaceOptions.map(({ slug: code, display: name, value }) => (
              <BaseSelectOption key={code} value={`${value}`}>
                {name}
              </BaseSelectOption>
            ))}
          </SelectInput>
          <InspectionQuestionsTable
            questions={questions}
            marketplace={marketplace}
            onUpdate={onUpdate}
            onUpdateQuestionId={onUpdateQuestionId}
            error={error}
          />
        </>
      )}
      <InspectionQuestionInstructions />
    </div>
  );
}
