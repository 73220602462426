import { AuctionStatus, VehicleStatus } from '../grpc/build/protos/adminapiv1_pb';
import { ListingStatus } from '../grpc/build/protos/enum_pb';

export const DEALTANK_VEHICLE_LISTING_STATUS_MAP = {
  [ListingStatus.ACTIVE]: 'Active',
  [ListingStatus.INACTIVE]: 'Inactive',
  [ListingStatus.INVALID]: 'Invalid',
};

export const DEALTANK_VEHICLE_LISTING_STATUS_MAP_FROM_STRING: Record<string, ListingStatus> = {
  ['active']: ListingStatus.ACTIVE,
  ['inactive']: ListingStatus.INACTIVE,
  ['invalid']: ListingStatus.INVALID,
};

export const VEHICLE_STATUS_MAP = {
  [VehicleStatus.VEHICLE_STATUS_CREATED]: 'Vehicle Created',
  [VehicleStatus.VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED]: 'Listing Payment Received',
  [VehicleStatus.VEHICLE_STATUS_INSPECTION_CREATED]: 'Inspection created',
  [VehicleStatus.VEHICLE_STATUS_INSPECTION_STARTED]: 'Inspection started',
  [VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE]: 'Inspection incomplete',
  [VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS]: 'Inspection questions incomplete',
  [VehicleStatus.VEHICLE_STATUS_INSPECTION_COMPLETED]: `Inspection completed`,
  [VehicleStatus.VEHICLE_STATUS_CONFIRMED]: 'Confirmed',
  [VehicleStatus.VEHICLE_STATUS_INVALID]: `Invalid status`,
  [VehicleStatus.VEHICLE_STATUS_DEACTIVATED]: 'Deactivated',
  // Consumer Buy Related
  [VehicleStatus.VEHICLE_STATUS_AVAILABLE]: 'Available',
  [VehicleStatus.VEHICLE_STATUS_PENDING_SALE]: 'Pending Sale',
  [VehicleStatus.VEHICLE_STATUS_INSPECTION_REPORT_PROCESSING]: 'Inspection Report Processing',
  [VehicleStatus.VEHICLE_STATUS_SOLD]: 'Sold',
};

export const CONSUMER_VEHICLE_STATUS_MAP_TO_STRING: Record<string, VehicleStatus> = {
  ['created']: VehicleStatus.VEHICLE_STATUS_CREATED,
  ['listingPaymentReceived']: VehicleStatus.VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED,
  ['inspectionCreated']: VehicleStatus.VEHICLE_STATUS_INSPECTION_CREATED,
  ['inspectionStarted']: VehicleStatus.VEHICLE_STATUS_INSPECTION_STARTED,
  ['inspectionIncomplete']: VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE,
  ['inspectionReportProcessing']: VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE,
  ['inspectionIncompleteMissingQuestions']: VehicleStatus.VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS,
  ['inspectionCompleted']: VehicleStatus.VEHICLE_STATUS_INSPECTION_COMPLETED,
  ['invalid']: VehicleStatus.VEHICLE_STATUS_INVALID,
  ['confirmed']: VehicleStatus.VEHICLE_STATUS_CONFIRMED,
  ['deactivated']: VehicleStatus.VEHICLE_STATUS_DEACTIVATED,
};

export const AUCTION_VEHICLE_STATUS_MAP = {
  [AuctionStatus.AUCTION_STATUS_SCHEDULED]: `Auction scheduled`,
  [AuctionStatus.AUCTION_STATUS_ACTIVE]: `In-auction`,
  [AuctionStatus.AUCTION_STATUS_COMPLETE]: `Awaiting offer acceptance`,
  [AuctionStatus.AUCTION_STATUS_ACCEPTED]: `Offer accepted`,
  [AuctionStatus.AUCTION_STATUS_REJECTED]: `Offer declined`,
  [AuctionStatus.AUCTION_STATUS_INVALID]: `Invalid status`,
  [AuctionStatus.AUCTION_STATUS_REMOVED]: 'Removed',
  [AuctionStatus.AUCTION_STATUS_READY]: 'Ready for auction',
  // comply with ts
  [AuctionStatus.AUCTION_STATUS_NONE]: 'Ready for auction', // todo: delete later
  [AuctionStatus.AUCTION_STATUS_RELIST_READY]: 'Ready for auction', // todo: delete later
};

export const AUCTION_VEHICLE_STATUS_MAP_TO_STRING: Record<string, AuctionStatus> = {
  [`scheduled`]: AuctionStatus.AUCTION_STATUS_SCHEDULED,
  [`active`]: AuctionStatus.AUCTION_STATUS_ACTIVE,
  [`complete`]: AuctionStatus.AUCTION_STATUS_COMPLETE,
  [`accepted`]: AuctionStatus.AUCTION_STATUS_ACCEPTED,
  [`rejected`]: AuctionStatus.AUCTION_STATUS_REJECTED,
  [`invalid`]: AuctionStatus.AUCTION_STATUS_INVALID,
  ['removed']: AuctionStatus.AUCTION_STATUS_REMOVED,
  ['none']: AuctionStatus.AUCTION_STATUS_NONE,
  ['relistReady']: AuctionStatus.AUCTION_STATUS_RELIST_READY,
  ['auctionReady']: AuctionStatus.AUCTION_STATUS_READY,
};
