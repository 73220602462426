import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import BaseButtonOutline from '../base/BaseButtonOutline';

type SetRejectVehicleOfferFromAuctionProps = {
  vehicleId: string;
  OnReject: (vehicleId: string) => void;
};

function RejectAuctionOffer({ vehicleId, OnReject }: SetRejectVehicleOfferFromAuctionProps) {
  const REMOVE_BTN_TEXT = {
    initialText: 'Reject Offer',
    confirmationText: 'Click again to reject offer',
    completionText: 'Vehicle Rejected and Moved to Offer Declined',
  };

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: REMOVE_BTN_TEXT,
    fn: () => OnReject(vehicleId),
  });

  return <BaseButtonOutline small display={confirmBtnText} onClick={doubleCheck} disabled={isConfirmBtnDisabled} />;
}

export default RejectAuctionOffer;
