import React from 'react';

type HourInputProps = {
  hour: string;
  handleHourChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};

function HourInput({ hour, handleHourChange, isDisabled }: HourInputProps) {
  return (
    <div>
      <label htmlFor="hour">Hour</label>
      <input type="number" min="0" max="23" id="hour" value={hour} onChange={handleHourChange} disabled={isDisabled} />
    </div>
  );
}

export default HourInput;
