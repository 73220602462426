import { useEffect, useState } from 'react';

import { Vehicle } from '../composables/api/types';
import { useEditFields } from '../composables/vdp/useEditFields';
import styles from '../scss/VehicleDetails.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';

export default function VehicleSpecs({
  vehicle,
  onUpdate,
}: {
  vehicle: Vehicle;
  onUpdate: (vehicle: Vehicle) => Promise<void>;
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { fieldsForEdit, setFields, renderFields } = useEditFields(isEditMode);

  useEffect(() => {
    setFields(vehicle);
  }, [vehicle]);

  const saveChanges = async (exitAfterUpdate = false) => {
    if (!fieldsForEdit) return;

    try {
      await onUpdate(fieldsForEdit);
    } catch (err) {
      alert(`Error when updating vehicle ${err}`);
      console.error(`Error when updating vehicle ${err}`);
      return;
    }

    if (exitAfterUpdate) setIsEditMode(false);
  };

  const cancelAndExitEdit = () => {
    setFields(vehicle);
    setIsEditMode(false);
  };

  const ActionButtons = () => {
    return isEditMode ? (
      <>
        <BaseButtonOutline small display="Save" onClick={() => saveChanges()} />
        <BaseButtonOutline small display="Save and Exit" onClick={() => saveChanges(true)} />
        <BaseButtonOutline small display="Cancel and Exit" onClick={cancelAndExitEdit} />
      </>
    ) : (
      <BaseButtonOutline small display="Enter edit mode" onClick={() => setIsEditMode(true)} />
    );
  };

  return (
    <>
      <div className={styles.vehicleDetails__controls__container}>
        <ActionButtons />
      </div>

      <h2>
        {vehicle.make} {vehicle.model} {vehicle.trimLevel}
      </h2>
      <h3 className={styles.updateNote}>
        Important Note: Drive Train, Transmission, Fuel Type, Year and Interior Type may be updated automatically when
        vehicle is added to auction
      </h3>
      <table className={styles.vehicleDetails__field__container}>
        <colgroup>
          <col span={1} style={{ width: '10%' }} />
          <col span={1} style={{ width: '45%' }} />
          <col span={1} style={{ width: '45%' }} />
        </colgroup>

        <thead>
          <tr>
            <th>Field name</th>
            <th>Current value</th>
            <th>{isEditMode ? 'New value' : ''}</th>
          </tr>
        </thead>
        <tbody>{renderFields()}</tbody>
      </table>

      <div className={styles.vehicleDetails__controls__container}>
        <ActionButtons />
      </div>
    </>
  );
}
