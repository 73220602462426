import { withAuthenticationRequired } from '@auth0/auth0-react';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useGetDealers } from '../../composables/api/useGetDealers';
import { useResetTerms } from '../../composables/api/useResetTerms';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import style from '../../scss/DealerList.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseSearchInput from '../base/BaseSearchInput';
import BaseWrapper from '../base/BaseWrapper';
import Loading from '../utils/Loading';
import Paginator from '../utils/Paginator';
import DealerListItem from './DealerListItem';

function DealerList() {
  const defaultPageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const resetTermsAndConditions = useResetTerms();
  const BTN_TEXT = {
    initialText: 'Reset Terms and Conditions for all',
    confirmationText: 'Click again to confirm',
    completionText: 'Terms and Conditions Reset Success!',
  };

  const handleNewPage = async (newPage: number) => {
    setCurrentPage(newPage);
    handleGetDealersByName(searchTerm, newPage);
  };

  const handleResetTerms = async () => {
    await resetTermsAndConditions();
    await handleGetDealersByName('');
  };

  const { confirmBtnText, doubleCheck } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleResetTerms,
    useRestore: true,
  });

  const { dealers, dealerCount, getDealersWithConditions } = useGetDealers({ defaultPageSize });
  const [filteredDealers, setFilteredDealers] = useState(dealers);
  const [showTodaySignIn] = useState(false);

  async function handleGetDealersByName(name: string, page = 1) {
    const date = showTodaySignIn ? moment().startOf('day').unix() * 10 ** 9 : 0;
    const dateNow = showTodaySignIn ? moment().unix() * 10 ** 9 : 0;
    setSearchTerm(name);

    try {
      await getDealersWithConditions({
        name,
        pageSize: defaultPageSize,
        pageNum: page,
        dateStart: date,
        dateEnd: dateNow,
      });
    } catch (error) {
      console.error('handleGetDealersByName ', error);
    }
  }

  useEffect(() => {
    if (!dealers) return;

    if (showTodaySignIn) {
      const todaySignedInDealers = dealers.filter((dealer) =>
        moment().isSame(moment(dealer.lastSignIn, 'YYYY-MMM-DD hh:mm:ss'), 'day'),
      );
      setFilteredDealers(todaySignedInDealers);
    } else {
      setFilteredDealers(dealers);
    }
  }, [dealers, showTodaySignIn]);

  return (
    <BaseWrapper>
      <h2>Dealers</h2>
      <div className={style.divider}>
        <div className={style.controlPanel}>
          <BaseButtonOutline
            small
            display={"Show Today's login only (external link)"}
            onClick={() =>
              window.open(
                'https://lookerstudio.google.com/reporting/1f6e2443-4586-4121-ae11-b57f77f52681/page/1fUGD',
                '_blank',
                'noreferrer',
              )
            }
          />
          <BaseSearchInput placeholder="Search by keyword" onSearch={handleGetDealersByName} />
        </div>
        <div>
          <BaseButtonOutline small display={confirmBtnText} onClick={doubleCheck} />
        </div>
      </div>

      <Paginator
        currentPage={currentPage}
        maxPage={Math.ceil(dealerCount / defaultPageSize)}
        handleNewPage={handleNewPage}
      />

      {!dealers.length && <p>No Results. Please update the search term or reset.</p>}
      {filteredDealers && filteredDealers.map((dealer) => <DealerListItem key={dealer.id} dealer={dealer} />)}
    </BaseWrapper>
  );
}

export default withAuthenticationRequired(DealerList, {
  onRedirecting: () => <Loading />,
});
