import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { TradeInInfo } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useTradeInInfos = (vehicleId: string) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [loading, setLoading] = useState(true);
  const [tradeInInfos, setTradeInInfos] = useState<TradeInInfo[]>([]);

  async function getTradeInInfos() {
    if (!isConnectionReady) return null;

    setLoading(true);

    const request = new adminApiv1Pb.ListTradeInInfosRequest();
    request.setVehicleId(vehicleId);
    try {
      const response = await client.listTradeInInfos(request, authHeader);
      const tradeInInfos = response.toObject().tradeInInfosList;

      if (tradeInInfos) {
        setTradeInInfos(tradeInInfos);
      }
    } catch (e) {
      console.error('useTradeInInfos failed,', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTradeInInfos();

    return () => setLoading(false);
  }, [isConnectionReady, vehicleId]);

  return { getTradeInInfos, loading, tradeInInfos };
};
