import styles from '../../scss/BooleanToggle.module.scss';

type BooleanToggleProps = {
  id: string;
  title: string;
  checked: boolean;
  onClick: (c: any) => void;
};

function BooleanToggle({ id, title, checked, onClick }: BooleanToggleProps) {
  return (
    <>
      <label className={styles.formSwitch} htmlFor={`toggle-${id}`} key={`toggle-${id}`}>
        {title}
        <input id={`toggle-${id}`} type="checkbox" className="mobileToggle" checked={checked} onChange={onClick} />
        <i></i>
      </label>
    </>
  );
}

export default BooleanToggle;
