import loadingImg from '../../assets/images/loading.gif';
import styles from '../../scss/Loading.module.scss';

function Loading() {
  return (
    <div className={styles.loadingContainer}>
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
}

export default Loading;
