import { useState } from 'react';

import { EditableFacetCount } from '../../composables/api/types';
import style from '../../scss/AuctionSearch.module.scss';
import BaseButtonText from '../base/BaseButtonText';
import BaseCheckbox from '../base/BaseCheckbox';

interface VehicleSearchFacetProps {
  fieldName: string;
  valueList: EditableFacetCount[];
  onSelect: (fieldName: string, value: string) => void;
}

const VehicleSearchFacet = ({ fieldName, valueList, onSelect }: VehicleSearchFacetProps) => {
  const defaultShowListSize = 10;
  const [showMore, setShowMore] = useState(false);
  const filteredValueList = valueList.filter((count) => count.value !== 'invalid');

  const countsListForDisplay = showMore ? filteredValueList : filteredValueList.slice(0, defaultShowListSize);
  const title = fieldName
    .substring(fieldName.indexOf('.') + 1)
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  if (countsListForDisplay.length === 0) return <></>;

  return (
    <div className={style.facet_categories}>
      <h2>{title}</h2>
      {countsListForDisplay.map((count) => {
        if (count.value !== '')
          return (
            <div key={count.value} className={style.facet_values}>
              <BaseCheckbox
                checked={count.isSelected}
                label={count.value + ' (' + count.count + ')'}
                onChange={() => onSelect(fieldName, count.value)}
              />
            </div>
          );
      })}
      {countsListForDisplay.length > defaultShowListSize && (
        <BaseButtonText
          onClick={() => setShowMore(!showMore)}
          display={showMore ? 'show less' : 'show more'}></BaseButtonText>
      )}
    </div>
  );
};

export default VehicleSearchFacet;
