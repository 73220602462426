import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import ExtendOffer from '../auction/ExtendOffer';
import BaseButtonOutline from '../base/BaseButtonOutline';

function AddOfferExtension({ vehicleId }: { vehicleId: string }) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const extendOffer = async () => {
    openUtilityDrawer(<ExtendOffer vehicleId={vehicleId} />);
  };

  return <BaseButtonOutline small display="Extend Offer" onClick={extendOffer} />;
}

export default AddOfferExtension;
