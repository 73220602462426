import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { TopBidInfo } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetTopBids = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function getTopBids(vehicleId: string, numberBids = 3): Promise<TopBidInfo | null> {
    if (!isConnectionReady) return null;

    const request = new adminApiv1Pb.ListVehicleTopBidsRequest();
    request.setVehicleId(vehicleId);
    request.setNumberBids(numberBids);

    const response = await client.listVehicleTopBids(request, authHeader);
    const topBidInfo = response.toObject().topBidInfo;

    return topBidInfo || null;
  }

  return getTopBids;
};
