import useModal from '../../composables/utils/useModal';
import BaseButtonOutline from '../base/BaseButtonOutline';
import EditVehicleReferrer from './EditVehicleReferrer';

function EditVehicleReferrerModal({ vehicleId, referrerEmail }: { vehicleId: string; referrerEmail: string }) {
  const { openModal } = useModal();

  const openEditReferrer = () => {
    openModal(<EditVehicleReferrer vehicleId={vehicleId} referrerEmail={referrerEmail} />, {
      contentLabel: `Add Referrer for Vehicle : ${vehicleId}`,
      shouldCloseOnOverlayClick: false,
    });
  };

  return <BaseButtonOutline small display="Edit Vehicle Referrer" onClick={openEditReferrer} />;
}

export default EditVehicleReferrerModal;
