import { useEffect, useState } from 'react';

import { VehicleData } from '../../components/dealer/types';
import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as searchEntityPb from '../../grpc/build/protos/searchentity_pb';
import { createMileage, getNumberWithComma, parseLocation } from '../../helpers/utils';
import { useApiReady } from '../utils/useApiReady';
import { VehicleSearchProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useGetDealerVehicles = (defaultPageSize = 50) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();
  const [vehiclesInfo, setRes] = useState({} as adminApiv1Pb.ListDealerSearchVehiclesResponse.AsObject);
  const [vehicles, setVehicles] = useState([] as VehicleData[]);

  async function getDealerVehicles(props: VehicleSearchProps) {
    if (!isConnectionReady) return null;

    const request = new adminApiv1Pb.ListDealerSearchVehiclesRequest();
    props.offSet && request.setOffSet(props.offSet);
    props.pageSize && request.setPageSize(props.pageSize);
    props.keyword && request.setKeyword(props.keyword);
    props.yearStart && request.setYearStart(props.yearStart);
    props.yearEnd && request.setYearEnd(props.yearEnd);
    props.mileageStart && request.setMileageStart(props.mileageStart);
    props.mileageEnd && request.setMileageEnd(props.mileageEnd);
    props.priceStart && request.setPriceStart(props.priceStart);
    props.priceEnd && request.setPriceEnd(props.priceEnd);
    props.sortVehicle && request.setSortVehicle(props.sortVehicle);

    const response = await client.listDealerSearchVehicles(request, authHeader);
    setRes(response.toObject());
    return response.toObject();
  }

  useEffect(() => {
    getDealerVehicles({
      pageSize: defaultPageSize || 50,
      offSet: 0,
    });
  }, [isConnectionReady]);

  useEffect(() => {
    if ('vehiclesList' in vehiclesInfo) {
      setVehicles(formatData(vehiclesInfo.vehiclesList));
    }
  }, [vehiclesInfo]);

  function formatData(originalData: searchEntityPb.Vehicle.AsObject[]): VehicleData[] {
    return originalData.map((info) => {
      return {
        title: info.title,
        make: info.make,
        model: info.model,
        trim: info.trimLevel,
        year: info.year,
        mileage: createMileage(info.mileage, info.mileageUom).toKmWithComma(),
        price: `$${getNumberWithComma(info.price || 0)}`,
        location: info.location ? parseLocation(info.location) : '',
        imageUrl: `${process.env.REACT_APP_IMAGE_SERVER_ADDRESS}/h=200/${info.imageUrl}`,
        vdpUrl: prepareVdpLink(info),
        vin: info.vin,
        id: info.id,
      };
    });
  }

  function prepareVdpLink(info: searchEntityPb.Vehicle.AsObject) {
    const link = `${process.env.REACT_APP_CONSUMER}/vehicle/${info.year}/${info.make}/${info.model}/${info.id}`;
    return link.replace(' ', '-');
  }

  return {
    getDealerVehicles,
    vehiclesInfo,
    vehicles,
  };
};
