// source: build/protos/searchentity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_entities_pb = require('../../build/protos/entities_pb.js');
goog.object.extend(proto, build_protos_entities_pb);
var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
var build_protos_vehicle_pb = require('../../build/protos/vehicle_pb.js');
goog.object.extend(proto, build_protos_vehicle_pb);
goog.exportSymbol('proto.entity.searchentity.AuctionStatus', null, global);
goog.exportSymbol('proto.entity.searchentity.BasicFacet', null, global);
goog.exportSymbol('proto.entity.searchentity.CurrentAuctionInfo', null, global);
goog.exportSymbol('proto.entity.searchentity.DealerUserFilter', null, global);
goog.exportSymbol('proto.entity.searchentity.DealershipDocument', null, global);
goog.exportSymbol('proto.entity.searchentity.Facet', null, global);
goog.exportSymbol('proto.entity.searchentity.FacetCount', null, global);
goog.exportSymbol('proto.entity.searchentity.FacetFilter', null, global);
goog.exportSymbol('proto.entity.searchentity.FacetStats', null, global);
goog.exportSymbol('proto.entity.searchentity.Field', null, global);
goog.exportSymbol('proto.entity.searchentity.FilterType', null, global);
goog.exportSymbol('proto.entity.searchentity.Location', null, global);
goog.exportSymbol('proto.entity.searchentity.MakeAndModel', null, global);
goog.exportSymbol('proto.entity.searchentity.Marketplace', null, global);
goog.exportSymbol('proto.entity.searchentity.Vehicle', null, global);
goog.exportSymbol('proto.entity.searchentity.VehicleDocument', null, global);
goog.exportSymbol('proto.entity.searchentity.VehicleStatus', null, global);
goog.exportSymbol('proto.entity.searchentity.Vehicles', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.Field.displayName = 'proto.entity.searchentity.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.DealerUserFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.DealerUserFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.DealerUserFilter.displayName = 'proto.entity.searchentity.DealerUserFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.VehicleDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.searchentity.VehicleDocument.repeatedFields_, null);
};
goog.inherits(proto.entity.searchentity.VehicleDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.VehicleDocument.displayName = 'proto.entity.searchentity.VehicleDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.Marketplace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.Marketplace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.Marketplace.displayName = 'proto.entity.searchentity.Marketplace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.DealershipDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.DealershipDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.DealershipDocument.displayName = 'proto.entity.searchentity.DealershipDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.Facet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.searchentity.Facet.repeatedFields_, null);
};
goog.inherits(proto.entity.searchentity.Facet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.Facet.displayName = 'proto.entity.searchentity.Facet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.FacetCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.FacetCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.FacetCount.displayName = 'proto.entity.searchentity.FacetCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.FacetStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.FacetStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.FacetStats.displayName = 'proto.entity.searchentity.FacetStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.FacetFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.searchentity.FacetFilter.repeatedFields_, null);
};
goog.inherits(proto.entity.searchentity.FacetFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.FacetFilter.displayName = 'proto.entity.searchentity.FacetFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.MakeAndModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.searchentity.MakeAndModel.repeatedFields_, null);
};
goog.inherits(proto.entity.searchentity.MakeAndModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.MakeAndModel.displayName = 'proto.entity.searchentity.MakeAndModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.BasicFacet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.BasicFacet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.BasicFacet.displayName = 'proto.entity.searchentity.BasicFacet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.Location.displayName = 'proto.entity.searchentity.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.searchentity.Vehicles.repeatedFields_, null);
};
goog.inherits(proto.entity.searchentity.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.Vehicles.displayName = 'proto.entity.searchentity.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.searchentity.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.entity.searchentity.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.Vehicle.displayName = 'proto.entity.searchentity.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.searchentity.CurrentAuctionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.searchentity.CurrentAuctionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.searchentity.CurrentAuctionInfo.displayName = 'proto.entity.searchentity.CurrentAuctionInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    drop: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    facet: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    index: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    infix: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    locale: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optional: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sort: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    type: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.Field}
 */
proto.entity.searchentity.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.Field;
  return proto.entity.searchentity.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.Field}
 */
proto.entity.searchentity.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrop(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFacet(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndex(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInfix(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptional(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSort(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrop();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFacet();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIndex();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getInfix();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptional();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSort();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bool drop = 1;
 * @return {boolean}
 */
proto.entity.searchentity.Field.prototype.getDrop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setDrop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool facet = 2;
 * @return {boolean}
 */
proto.entity.searchentity.Field.prototype.getFacet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setFacet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool index = 3;
 * @return {boolean}
 */
proto.entity.searchentity.Field.prototype.getIndex = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setIndex = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool infix = 4;
 * @return {boolean}
 */
proto.entity.searchentity.Field.prototype.getInfix = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setInfix = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string locale = 5;
 * @return {string}
 */
proto.entity.searchentity.Field.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.entity.searchentity.Field.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool optional = 7;
 * @return {boolean}
 */
proto.entity.searchentity.Field.prototype.getOptional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setOptional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool sort = 8;
 * @return {boolean}
 */
proto.entity.searchentity.Field.prototype.getSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.entity.searchentity.Field.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Field} returns this
 */
proto.entity.searchentity.Field.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.DealerUserFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.DealerUserFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.DealerUserFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.DealerUserFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginDateStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    loginDateEnd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.DealerUserFilter}
 */
proto.entity.searchentity.DealerUserFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.DealerUserFilter;
  return proto.entity.searchentity.DealerUserFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.DealerUserFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.DealerUserFilter}
 */
proto.entity.searchentity.DealerUserFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoginDateStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoginDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.DealerUserFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.DealerUserFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.DealerUserFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.DealerUserFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginDateStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLoginDateEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 login_date_start = 1;
 * @return {number}
 */
proto.entity.searchentity.DealerUserFilter.prototype.getLoginDateStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.DealerUserFilter} returns this
 */
proto.entity.searchentity.DealerUserFilter.prototype.setLoginDateStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 login_date_end = 2;
 * @return {number}
 */
proto.entity.searchentity.DealerUserFilter.prototype.getLoginDateEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.DealerUserFilter} returns this
 */
proto.entity.searchentity.DealerUserFilter.prototype.setLoginDateEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.searchentity.VehicleDocument.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.VehicleDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.VehicleDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.VehicleDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.VehicleDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    coverImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    make: jspb.Message.getFieldWithDefault(msg, 5, ""),
    model: jspb.Message.getFieldWithDefault(msg, 6, ""),
    trim: jspb.Message.getFieldWithDefault(msg, 7, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 8, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mileage: jspb.Message.getFieldWithDefault(msg, 11, 0),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 12, 0),
    user: (f = msg.getUser()) && build_protos_entities_pb.User.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && build_protos_entities_pb.Location.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 17, 0),
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 18, 0),
    dealTankListingStatus: jspb.Message.getFieldWithDefault(msg, 19, 0),
    referrerEmail: jspb.Message.getFieldWithDefault(msg, 20, ""),
    dealTankAskingPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    dealTankCurrency: jspb.Message.getFieldWithDefault(msg, 22, 0),
    dealTankHasPendingOffers: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    dealTankOfferStatus: jspb.Message.getFieldWithDefault(msg, 24, 0),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    carfaxUrl: jspb.Message.getFieldWithDefault(msg, 26, ""),
    mlInspectionUrl: jspb.Message.getFieldWithDefault(msg, 27, ""),
    source: jspb.Message.getFieldWithDefault(msg, 28, ""),
    marketplace: (f = msg.getMarketplace()) && proto.entity.searchentity.Marketplace.toObject(includeInstance, f),
    currentAuctionInfo: (f = msg.getCurrentAuctionInfo()) && proto.entity.searchentity.CurrentAuctionInfo.toObject(includeInstance, f),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 33, 0),
    vehicleBadge: jspb.Message.getFieldWithDefault(msg, 34, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.VehicleDocument}
 */
proto.entity.searchentity.VehicleDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.VehicleDocument;
  return proto.entity.searchentity.VehicleDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.VehicleDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.VehicleDocument}
 */
proto.entity.searchentity.VehicleDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 12:
      var value = /** @type {!proto.entity.enum.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 13:
      var value = new build_protos_entities_pb.User;
      reader.readMessage(value,build_protos_entities_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 15:
      var value = new build_protos_entities_pb.Location;
      reader.readMessage(value,build_protos_entities_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 18:
      var value = /** @type {!proto.entity.enum.VehicleStatus} */ (reader.readEnum());
      msg.setVehicleStatus(value);
      break;
    case 19:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setDealTankListingStatus(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerEmail(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDealTankAskingPrice(value);
      break;
    case 22:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setDealTankCurrency(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDealTankHasPendingOffers(value);
      break;
    case 24:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setDealTankOfferStatus(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxUrl(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlInspectionUrl(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 31:
      var value = new proto.entity.searchentity.Marketplace;
      reader.readMessage(value,proto.entity.searchentity.Marketplace.deserializeBinaryFromReader);
      msg.setMarketplace(value);
      break;
    case 32:
      var value = new proto.entity.searchentity.CurrentAuctionInfo;
      reader.readMessage(value,proto.entity.searchentity.CurrentAuctionInfo.deserializeBinaryFromReader);
      msg.setCurrentAuctionInfo(value);
      break;
    case 33:
      var value = /** @type {!proto.entity.enum.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 34:
      var value = /** @type {!proto.entity.enum.VehicleBadge} */ (reader.readEnum());
      msg.setVehicleBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.VehicleDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.VehicleDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.VehicleDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.VehicleDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCoverImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      build_protos_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      build_protos_entities_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getVehicleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getDealTankListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getReferrerEmail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDealTankAskingPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getDealTankCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getDealTankHasPendingOffers();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getDealTankOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getCarfaxUrl();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getMlInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getMarketplace();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.entity.searchentity.Marketplace.serializeBinaryToWriter
    );
  }
  f = message.getCurrentAuctionInfo();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.entity.searchentity.CurrentAuctionInfo.serializeBinaryToWriter
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      33,
      f
    );
  }
  f = message.getVehicleBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.searchentity.VehicleDocument.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cover_image_url = 4;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getCoverImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setCoverImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string make = 5;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string trim = 7;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string transmission = 8;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string exterior_color = 9;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string interior_color = 10;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 mileage = 11;
 * @return {number}
 */
proto.entity.searchentity.VehicleDocument.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional entity.enum.MileageUOM mileage_uom = 12;
 * @return {!proto.entity.enum.MileageUOM}
 */
proto.entity.searchentity.VehicleDocument.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.enum.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.entity.enum.MileageUOM} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional entity.entities.User user = 13;
 * @return {?proto.entity.entities.User}
 */
proto.entity.searchentity.VehicleDocument.prototype.getUser = function() {
  return /** @type{?proto.entity.entities.User} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.User, 13));
};


/**
 * @param {?proto.entity.entities.User|undefined} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
*/
proto.entity.searchentity.VehicleDocument.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.VehicleDocument.prototype.hasUser = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional entity.entities.Location location = 15;
 * @return {?proto.entity.entities.Location}
 */
proto.entity.searchentity.VehicleDocument.prototype.getLocation = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Location, 15));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
*/
proto.entity.searchentity.VehicleDocument.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.VehicleDocument.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 created_at = 16;
 * @return {number}
 */
proto.entity.searchentity.VehicleDocument.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 updated_at = 17;
 * @return {number}
 */
proto.entity.searchentity.VehicleDocument.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional entity.enum.VehicleStatus vehicle_status = 18;
 * @return {!proto.entity.enum.VehicleStatus}
 */
proto.entity.searchentity.VehicleDocument.prototype.getVehicleStatus = function() {
  return /** @type {!proto.entity.enum.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.entity.enum.VehicleStatus} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional entity.enum.ListingStatus deal_tank_listing_status = 19;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.entity.searchentity.VehicleDocument.prototype.getDealTankListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setDealTankListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string referrer_email = 20;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getReferrerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setReferrerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional float deal_tank_asking_price = 21;
 * @return {number}
 */
proto.entity.searchentity.VehicleDocument.prototype.getDealTankAskingPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setDealTankAskingPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional entity.enum.Currency deal_tank_currency = 22;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.searchentity.VehicleDocument.prototype.getDealTankCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setDealTankCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional float deal_tank_has_pending_offers = 23;
 * @return {number}
 */
proto.entity.searchentity.VehicleDocument.prototype.getDealTankHasPendingOffers = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setDealTankHasPendingOffers = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional entity.enum.OfferStatus deal_tank_offer_status = 24;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.entity.searchentity.VehicleDocument.prototype.getDealTankOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setDealTankOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * repeated string images = 25;
 * @return {!Array<string>}
 */
proto.entity.searchentity.VehicleDocument.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string carfax_url = 26;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getCarfaxUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setCarfaxUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string ml_inspection_url = 27;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getMlInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setMlInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string source = 28;
 * @return {string}
 */
proto.entity.searchentity.VehicleDocument.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional Marketplace marketplace = 31;
 * @return {?proto.entity.searchentity.Marketplace}
 */
proto.entity.searchentity.VehicleDocument.prototype.getMarketplace = function() {
  return /** @type{?proto.entity.searchentity.Marketplace} */ (
    jspb.Message.getWrapperField(this, proto.entity.searchentity.Marketplace, 31));
};


/**
 * @param {?proto.entity.searchentity.Marketplace|undefined} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
*/
proto.entity.searchentity.VehicleDocument.prototype.setMarketplace = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.clearMarketplace = function() {
  return this.setMarketplace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.VehicleDocument.prototype.hasMarketplace = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional CurrentAuctionInfo current_auction_info = 32;
 * @return {?proto.entity.searchentity.CurrentAuctionInfo}
 */
proto.entity.searchentity.VehicleDocument.prototype.getCurrentAuctionInfo = function() {
  return /** @type{?proto.entity.searchentity.CurrentAuctionInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.searchentity.CurrentAuctionInfo, 32));
};


/**
 * @param {?proto.entity.searchentity.CurrentAuctionInfo|undefined} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
*/
proto.entity.searchentity.VehicleDocument.prototype.setCurrentAuctionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.clearCurrentAuctionInfo = function() {
  return this.setCurrentAuctionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.VehicleDocument.prototype.hasCurrentAuctionInfo = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional entity.enum.AuctionStatus auction_status = 33;
 * @return {!proto.entity.enum.AuctionStatus}
 */
proto.entity.searchentity.VehicleDocument.prototype.getAuctionStatus = function() {
  return /** @type {!proto.entity.enum.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {!proto.entity.enum.AuctionStatus} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 33, value);
};


/**
 * optional entity.enum.VehicleBadge vehicle_badge = 34;
 * @return {!proto.entity.enum.VehicleBadge}
 */
proto.entity.searchentity.VehicleDocument.prototype.getVehicleBadge = function() {
  return /** @type {!proto.entity.enum.VehicleBadge} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.entity.enum.VehicleBadge} value
 * @return {!proto.entity.searchentity.VehicleDocument} returns this
 */
proto.entity.searchentity.VehicleDocument.prototype.setVehicleBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.Marketplace.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.Marketplace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.Marketplace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Marketplace.toObject = function(includeInstance, msg) {
  var f, obj = {
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    membershipType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.Marketplace}
 */
proto.entity.searchentity.Marketplace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.Marketplace;
  return proto.entity.searchentity.Marketplace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.Marketplace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.Marketplace}
 */
proto.entity.searchentity.Marketplace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembershipType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.Marketplace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.Marketplace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.Marketplace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Marketplace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMembershipType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_active = 1;
 * @return {boolean}
 */
proto.entity.searchentity.Marketplace.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.searchentity.Marketplace} returns this
 */
proto.entity.searchentity.Marketplace.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.searchentity.Marketplace.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.Marketplace} returns this
 */
proto.entity.searchentity.Marketplace.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string membership_type = 3;
 * @return {string}
 */
proto.entity.searchentity.Marketplace.prototype.getMembershipType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Marketplace} returns this
 */
proto.entity.searchentity.Marketplace.prototype.setMembershipType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.DealershipDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.DealershipDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.DealershipDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.DealershipDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    crmDealerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    telephoneNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    email: jspb.Message.getFieldWithDefault(msg, 10, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    provinceRegistrationNo: jspb.Message.getFieldWithDefault(msg, 12, ""),
    autoGroupName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    gstNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pstNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    street: jspb.Message.getFieldWithDefault(msg, 17, ""),
    country: jspb.Message.getFieldWithDefault(msg, 18, ""),
    state: jspb.Message.getFieldWithDefault(msg, 19, ""),
    city: jspb.Message.getFieldWithDefault(msg, 20, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 21, ""),
    accountantEmail: jspb.Message.getFieldWithDefault(msg, 22, ""),
    clipId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    subscriptionPlan: jspb.Message.getFieldWithDefault(msg, 24, ""),
    subscriptionChangeDate: jspb.Message.getFieldWithDefault(msg, 25, ""),
    logoPath: jspb.Message.getFieldWithDefault(msg, 26, ""),
    googlePlaceId: jspb.Message.getFieldWithDefault(msg, 27, ""),
    carfaxAccountId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    dealershipReferralId: jspb.Message.getFieldWithDefault(msg, 29, ""),
    dealershipReferralName: jspb.Message.getFieldWithDefault(msg, 30, ""),
    dealershipReferralNotes: jspb.Message.getFieldWithDefault(msg, 31, ""),
    rin: jspb.Message.getFieldWithDefault(msg, 32, ""),
    controllerEmail: jspb.Message.getFieldWithDefault(msg, 33, ""),
    salesManagerEmail: jspb.Message.getFieldWithDefault(msg, 34, ""),
    gmEmail: jspb.Message.getFieldWithDefault(msg, 35, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.DealershipDocument}
 */
proto.entity.searchentity.DealershipDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.DealershipDocument;
  return proto.entity.searchentity.DealershipDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.DealershipDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.DealershipDocument}
 */
proto.entity.searchentity.DealershipDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmDealerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephoneNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceRegistrationNo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGroupName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setGstNumber(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPstNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountantEmail(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setClipId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionPlan(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionChangeDate(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoPath(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setGooglePlaceId(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxAccountId(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipReferralId(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipReferralName(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipReferralNotes(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setRin(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerEmail(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesManagerEmail(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setGmEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.DealershipDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.DealershipDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.DealershipDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.DealershipDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCrmDealerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTelephoneNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProvinceRegistrationNo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAutoGroupName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getGstNumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPstNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAccountantEmail();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getClipId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSubscriptionPlan();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSubscriptionChangeDate();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLogoPath();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getGooglePlaceId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCarfaxAccountId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getDealershipReferralId();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getDealershipReferralName();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getDealershipReferralNotes();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getRin();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getControllerEmail();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getSalesManagerEmail();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getGmEmail();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string crm_dealer_id = 4;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getCrmDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setCrmDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string external_dealer_id = 5;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string feed_id = 6;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string primary_make = 7;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string telephone_number = 9;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getTelephoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setTelephoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string fax_number = 11;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string province_registration_no = 12;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getProvinceRegistrationNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setProvinceRegistrationNo = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string auto_group_name = 13;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getAutoGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setAutoGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string legal_name = 14;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string gst_number = 15;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getGstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setGstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string pst_number = 16;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getPstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setPstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string street = 17;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string country = 18;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string state = 19;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string city = 20;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string postal = 21;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string accountant_email = 22;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getAccountantEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setAccountantEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string clip_id = 23;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getClipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setClipId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string subscription_plan = 24;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getSubscriptionPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setSubscriptionPlan = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string subscription_change_date = 25;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getSubscriptionChangeDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setSubscriptionChangeDate = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string logo_path = 26;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getLogoPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setLogoPath = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string google_place_id = 27;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getGooglePlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setGooglePlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string carfax_account_id = 28;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getCarfaxAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setCarfaxAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string dealership_referral_id = 29;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getDealershipReferralId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setDealershipReferralId = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string dealership_referral_name = 30;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getDealershipReferralName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setDealershipReferralName = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string dealership_referral_notes = 31;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getDealershipReferralNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setDealershipReferralNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string rin = 32;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getRin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setRin = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string controller_email = 33;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getControllerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setControllerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string sales_manager_email = 34;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getSalesManagerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setSalesManagerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string gm_email = 35;
 * @return {string}
 */
proto.entity.searchentity.DealershipDocument.prototype.getGmEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.DealershipDocument} returns this
 */
proto.entity.searchentity.DealershipDocument.prototype.setGmEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.searchentity.Facet.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.Facet.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.Facet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.Facet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Facet.toObject = function(includeInstance, msg) {
  var f, obj = {
    countsList: jspb.Message.toObjectList(msg.getCountsList(),
    proto.entity.searchentity.FacetCount.toObject, includeInstance),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stats: (f = msg.getStats()) && proto.entity.searchentity.FacetStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.Facet}
 */
proto.entity.searchentity.Facet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.Facet;
  return proto.entity.searchentity.Facet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.Facet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.Facet}
 */
proto.entity.searchentity.Facet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.searchentity.FacetCount;
      reader.readMessage(value,proto.entity.searchentity.FacetCount.deserializeBinaryFromReader);
      msg.addCounts(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = new proto.entity.searchentity.FacetStats;
      reader.readMessage(value,proto.entity.searchentity.FacetStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.Facet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.Facet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.Facet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Facet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.searchentity.FacetCount.serializeBinaryToWriter
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.entity.searchentity.FacetStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FacetCount counts = 1;
 * @return {!Array<!proto.entity.searchentity.FacetCount>}
 */
proto.entity.searchentity.Facet.prototype.getCountsList = function() {
  return /** @type{!Array<!proto.entity.searchentity.FacetCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.searchentity.FacetCount, 1));
};


/**
 * @param {!Array<!proto.entity.searchentity.FacetCount>} value
 * @return {!proto.entity.searchentity.Facet} returns this
*/
proto.entity.searchentity.Facet.prototype.setCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.searchentity.FacetCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.FacetCount}
 */
proto.entity.searchentity.Facet.prototype.addCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.searchentity.FacetCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.searchentity.Facet} returns this
 */
proto.entity.searchentity.Facet.prototype.clearCountsList = function() {
  return this.setCountsList([]);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.entity.searchentity.Facet.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Facet} returns this
 */
proto.entity.searchentity.Facet.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FacetStats stats = 3;
 * @return {?proto.entity.searchentity.FacetStats}
 */
proto.entity.searchentity.Facet.prototype.getStats = function() {
  return /** @type{?proto.entity.searchentity.FacetStats} */ (
    jspb.Message.getWrapperField(this, proto.entity.searchentity.FacetStats, 3));
};


/**
 * @param {?proto.entity.searchentity.FacetStats|undefined} value
 * @return {!proto.entity.searchentity.Facet} returns this
*/
proto.entity.searchentity.Facet.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.Facet} returns this
 */
proto.entity.searchentity.Facet.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.Facet.prototype.hasStats = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.FacetCount.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.FacetCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.FacetCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.FacetCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    highlighted: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.FacetCount}
 */
proto.entity.searchentity.FacetCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.FacetCount;
  return proto.entity.searchentity.FacetCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.FacetCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.FacetCount}
 */
proto.entity.searchentity.FacetCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHighlighted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.FacetCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.FacetCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.FacetCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.FacetCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHighlighted();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.entity.searchentity.FacetCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetCount} returns this
 */
proto.entity.searchentity.FacetCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string highlighted = 2;
 * @return {string}
 */
proto.entity.searchentity.FacetCount.prototype.getHighlighted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.FacetCount} returns this
 */
proto.entity.searchentity.FacetCount.prototype.setHighlighted = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.entity.searchentity.FacetCount.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.FacetCount} returns this
 */
proto.entity.searchentity.FacetCount.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.FacetStats.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.FacetStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.FacetStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.FacetStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    avg: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    sum: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalValues: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.FacetStats}
 */
proto.entity.searchentity.FacetStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.FacetStats;
  return proto.entity.searchentity.FacetStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.FacetStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.FacetStats}
 */
proto.entity.searchentity.FacetStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAvg(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMax(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.FacetStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.FacetStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.FacetStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.FacetStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvg();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSum();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalValues();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional float avg = 1;
 * @return {number}
 */
proto.entity.searchentity.FacetStats.prototype.getAvg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetStats} returns this
 */
proto.entity.searchentity.FacetStats.prototype.setAvg = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float max = 2;
 * @return {number}
 */
proto.entity.searchentity.FacetStats.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetStats} returns this
 */
proto.entity.searchentity.FacetStats.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float min = 3;
 * @return {number}
 */
proto.entity.searchentity.FacetStats.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetStats} returns this
 */
proto.entity.searchentity.FacetStats.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float sum = 4;
 * @return {number}
 */
proto.entity.searchentity.FacetStats.prototype.getSum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetStats} returns this
 */
proto.entity.searchentity.FacetStats.prototype.setSum = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 total_values = 5;
 * @return {number}
 */
proto.entity.searchentity.FacetStats.prototype.getTotalValues = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetStats} returns this
 */
proto.entity.searchentity.FacetStats.prototype.setTotalValues = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.searchentity.FacetFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.FacetFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.FacetFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.FacetFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.FacetFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    filterType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    groupJoinerType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.FacetFilter}
 */
proto.entity.searchentity.FacetFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.FacetFilter;
  return proto.entity.searchentity.FacetFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.FacetFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.FacetFilter}
 */
proto.entity.searchentity.FacetFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.searchentity.FilterType} */ (reader.readEnum());
      msg.setFilterType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupOrder(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.searchentity.FilterType} */ (reader.readEnum());
      msg.setGroupJoinerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.FacetFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.FacetFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.FacetFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.FacetFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getFilterType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGroupOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGroupJoinerType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.entity.searchentity.FacetFilter.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.entity.searchentity.FacetFilter.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional FilterType filter_type = 3;
 * @return {!proto.entity.searchentity.FilterType}
 */
proto.entity.searchentity.FacetFilter.prototype.getFilterType = function() {
  return /** @type {!proto.entity.searchentity.FilterType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.searchentity.FilterType} value
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.setFilterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 group_order = 4;
 * @return {number}
 */
proto.entity.searchentity.FacetFilter.prototype.getGroupOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.setGroupOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional FilterType group_joiner_type = 5;
 * @return {!proto.entity.searchentity.FilterType}
 */
proto.entity.searchentity.FacetFilter.prototype.getGroupJoinerType = function() {
  return /** @type {!proto.entity.searchentity.FilterType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.searchentity.FilterType} value
 * @return {!proto.entity.searchentity.FacetFilter} returns this
 */
proto.entity.searchentity.FacetFilter.prototype.setGroupJoinerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.searchentity.MakeAndModel.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.MakeAndModel.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.MakeAndModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.MakeAndModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.MakeAndModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    makeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.MakeAndModel}
 */
proto.entity.searchentity.MakeAndModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.MakeAndModel;
  return proto.entity.searchentity.MakeAndModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.MakeAndModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.MakeAndModel}
 */
proto.entity.searchentity.MakeAndModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMakeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addModelNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.MakeAndModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.MakeAndModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.MakeAndModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.MakeAndModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMakeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string make_name = 1;
 * @return {string}
 */
proto.entity.searchentity.MakeAndModel.prototype.getMakeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.MakeAndModel} returns this
 */
proto.entity.searchentity.MakeAndModel.prototype.setMakeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string model_names = 2;
 * @return {!Array<string>}
 */
proto.entity.searchentity.MakeAndModel.prototype.getModelNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.entity.searchentity.MakeAndModel} returns this
 */
proto.entity.searchentity.MakeAndModel.prototype.setModelNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.MakeAndModel} returns this
 */
proto.entity.searchentity.MakeAndModel.prototype.addModelNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.searchentity.MakeAndModel} returns this
 */
proto.entity.searchentity.MakeAndModel.prototype.clearModelNamesList = function() {
  return this.setModelNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.BasicFacet.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.BasicFacet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.BasicFacet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.BasicFacet.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.BasicFacet}
 */
proto.entity.searchentity.BasicFacet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.BasicFacet;
  return proto.entity.searchentity.BasicFacet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.BasicFacet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.BasicFacet}
 */
proto.entity.searchentity.BasicFacet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.BasicFacet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.BasicFacet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.BasicFacet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.BasicFacet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.entity.searchentity.BasicFacet.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.BasicFacet} returns this
 */
proto.entity.searchentity.BasicFacet.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.entity.searchentity.BasicFacet.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.BasicFacet} returns this
 */
proto.entity.searchentity.BasicFacet.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.Location}
 */
proto.entity.searchentity.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.Location;
  return proto.entity.searchentity.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.Location}
 */
proto.entity.searchentity.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.entity.searchentity.Location.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Location} returns this
 */
proto.entity.searchentity.Location.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.entity.searchentity.Location.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Location} returns this
 */
proto.entity.searchentity.Location.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.entity.searchentity.Location.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Location} returns this
 */
proto.entity.searchentity.Location.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.entity.searchentity.Location.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Location} returns this
 */
proto.entity.searchentity.Location.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.entity.searchentity.Location.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Location} returns this
 */
proto.entity.searchentity.Location.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.entity.searchentity.Location.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Location} returns this
 */
proto.entity.searchentity.Location.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.searchentity.Vehicles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.entity.searchentity.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.Vehicles}
 */
proto.entity.searchentity.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.Vehicles;
  return proto.entity.searchentity.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.Vehicles}
 */
proto.entity.searchentity.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.searchentity.Vehicle;
      reader.readMessage(value,proto.entity.searchentity.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.searchentity.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vehicle vehicles = 1;
 * @return {!Array<!proto.entity.searchentity.Vehicle>}
 */
proto.entity.searchentity.Vehicles.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.entity.searchentity.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.searchentity.Vehicle, 1));
};


/**
 * @param {!Array<!proto.entity.searchentity.Vehicle>} value
 * @return {!proto.entity.searchentity.Vehicles} returns this
*/
proto.entity.searchentity.Vehicles.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.searchentity.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.Vehicle}
 */
proto.entity.searchentity.Vehicles.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.searchentity.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.searchentity.Vehicles} returns this
 */
proto.entity.searchentity.Vehicles.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.searchentity.Vehicle.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    make: jspb.Message.getFieldWithDefault(msg, 5, ""),
    model: jspb.Message.getFieldWithDefault(msg, 6, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 7, 0),
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mileage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 11, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    location: (f = msg.getLocation()) && proto.entity.searchentity.Location.toObject(includeInstance, f),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 15, ""),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 16, ""),
    priceHistoryList: jspb.Message.toObjectList(msg.getPriceHistoryList(),
    build_protos_vehicle_pb.PriceHistory.toObject, includeInstance),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 18, 0),
    currentAuctionInfo: (f = msg.getCurrentAuctionInfo()) && proto.entity.searchentity.CurrentAuctionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.Vehicle}
 */
proto.entity.searchentity.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.Vehicle;
  return proto.entity.searchentity.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.Vehicle}
 */
proto.entity.searchentity.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.searchentity.VehicleStatus} */ (reader.readEnum());
      msg.setVehicleStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 13:
      var value = new proto.entity.searchentity.Location;
      reader.readMessage(value,proto.entity.searchentity.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 14:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleOrigin(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 17:
      var value = new build_protos_vehicle_pb.PriceHistory;
      reader.readMessage(value,build_protos_vehicle_pb.PriceHistory.deserializeBinaryFromReader);
      msg.addPriceHistory(value);
      break;
    case 18:
      var value = /** @type {!proto.entity.enum.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 19:
      var value = new proto.entity.searchentity.CurrentAuctionInfo;
      reader.readMessage(value,proto.entity.searchentity.CurrentAuctionInfo.deserializeBinaryFromReader);
      msg.setCurrentAuctionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getVehicleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.entity.searchentity.Location.serializeBinaryToWriter
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVehicleOrigin();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPriceHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      build_protos_vehicle_pb.PriceHistory.serializeBinaryToWriter
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getCurrentAuctionInfo();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.entity.searchentity.CurrentAuctionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.searchentity.Vehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string make = 5;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 seats = 7;
 * @return {number}
 */
proto.entity.searchentity.Vehicle.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional VehicleStatus vehicle_status = 8;
 * @return {!proto.entity.searchentity.VehicleStatus}
 */
proto.entity.searchentity.Vehicle.prototype.getVehicleStatus = function() {
  return /** @type {!proto.entity.searchentity.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.searchentity.VehicleStatus} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 mileage = 9;
 * @return {number}
 */
proto.entity.searchentity.Vehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double price = 10;
 * @return {number}
 */
proto.entity.searchentity.Vehicle.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional entity.enum.Currency currency = 11;
 * @return {!proto.entity.enum.Currency}
 */
proto.entity.searchentity.Vehicle.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string image_url = 12;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Location location = 13;
 * @return {?proto.entity.searchentity.Location}
 */
proto.entity.searchentity.Vehicle.prototype.getLocation = function() {
  return /** @type{?proto.entity.searchentity.Location} */ (
    jspb.Message.getWrapperField(this, proto.entity.searchentity.Location, 13));
};


/**
 * @param {?proto.entity.searchentity.Location|undefined} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
*/
proto.entity.searchentity.Vehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.Vehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional entity.common.Timestamp time_stamp = 14;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.searchentity.Vehicle.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 14));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
*/
proto.entity.searchentity.Vehicle.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.Vehicle.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string vehicle_origin = 15;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getVehicleOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string trim_level = 16;
 * @return {string}
 */
proto.entity.searchentity.Vehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated entity.vehicle.PriceHistory price_history = 17;
 * @return {!Array<!proto.entity.vehicle.PriceHistory>}
 */
proto.entity.searchentity.Vehicle.prototype.getPriceHistoryList = function() {
  return /** @type{!Array<!proto.entity.vehicle.PriceHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_vehicle_pb.PriceHistory, 17));
};


/**
 * @param {!Array<!proto.entity.vehicle.PriceHistory>} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
*/
proto.entity.searchentity.Vehicle.prototype.setPriceHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.entity.vehicle.PriceHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.PriceHistory}
 */
proto.entity.searchentity.Vehicle.prototype.addPriceHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.entity.vehicle.PriceHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.clearPriceHistoryList = function() {
  return this.setPriceHistoryList([]);
};


/**
 * optional entity.enum.MileageUOM mileage_uom = 18;
 * @return {!proto.entity.enum.MileageUOM}
 */
proto.entity.searchentity.Vehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.enum.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.entity.enum.MileageUOM} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional CurrentAuctionInfo current_auction_info = 19;
 * @return {?proto.entity.searchentity.CurrentAuctionInfo}
 */
proto.entity.searchentity.Vehicle.prototype.getCurrentAuctionInfo = function() {
  return /** @type{?proto.entity.searchentity.CurrentAuctionInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.searchentity.CurrentAuctionInfo, 19));
};


/**
 * @param {?proto.entity.searchentity.CurrentAuctionInfo|undefined} value
 * @return {!proto.entity.searchentity.Vehicle} returns this
*/
proto.entity.searchentity.Vehicle.prototype.setCurrentAuctionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.searchentity.Vehicle} returns this
 */
proto.entity.searchentity.Vehicle.prototype.clearCurrentAuctionInfo = function() {
  return this.setCurrentAuctionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.searchentity.Vehicle.prototype.hasCurrentAuctionInfo = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.searchentity.CurrentAuctionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.searchentity.CurrentAuctionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.searchentity.CurrentAuctionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.CurrentAuctionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.searchentity.CurrentAuctionInfo}
 */
proto.entity.searchentity.CurrentAuctionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.searchentity.CurrentAuctionInfo;
  return proto.entity.searchentity.CurrentAuctionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.searchentity.CurrentAuctionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.searchentity.CurrentAuctionInfo}
 */
proto.entity.searchentity.CurrentAuctionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.searchentity.AuctionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.searchentity.CurrentAuctionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.searchentity.CurrentAuctionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.searchentity.CurrentAuctionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.searchentity.CurrentAuctionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AuctionStatus status = 1;
 * @return {!proto.entity.searchentity.AuctionStatus}
 */
proto.entity.searchentity.CurrentAuctionInfo.prototype.getStatus = function() {
  return /** @type {!proto.entity.searchentity.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.searchentity.AuctionStatus} value
 * @return {!proto.entity.searchentity.CurrentAuctionInfo} returns this
 */
proto.entity.searchentity.CurrentAuctionInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.entity.searchentity.AuctionStatus = {
  AUCTION_STATUS_INVALID: 0,
  AUCTION_STATUS_ACTIVE: 1,
  AUCTION_STATUS_COMPLETE: 2,
  AUCTION_STATUS_SCHEDULED: 3,
  AUCTION_STATUS_REMOVED: 4,
  AUCTION_STATUS_ACCEPTED: 5,
  AUCTION_STATUS_REJECTED: 6,
  AUCTION_STATUS_NONE: 7,
  AUCTION_STATUS_RELIST_READY: 8
};

/**
 * @enum {number}
 */
proto.entity.searchentity.FilterType = {
  FILTER_TYPE_INVALID: 0,
  FILTER_TYPE_AND: 1,
  FILTER_TYPE_OR: 2
};

/**
 * @enum {number}
 */
proto.entity.searchentity.VehicleStatus = {
  VEHICLE_STATUS_INVALID: 0,
  VEHICLE_STATUS_AVAILABLE: 1,
  VEHICLE_STATUS_PENDING_SALE: 2,
  VEHICLE_STATUS_SOLD: 3
};

goog.object.extend(exports, proto.entity.searchentity);
