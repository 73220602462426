import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { AddTestVehiclesProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useAddTestVehicles = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();

  function addTestVehicle(props: AddTestVehiclesProps): Promise<adminApiv1Pb.GenerateVehiclesResponse> {
    return new Promise<adminApiv1Pb.GenerateVehiclesResponse>((resolve, reject) => {
      const request = new adminApiv1Pb.GenerateVehiclesRequest();
      request.setConsumerEmail(props.consumerEmail);
      request.setNumberVehicles(props.numberVehicles);

      client.generateVehicles(request, authHeader, (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    });
  }

  const addTestVehiclesFn = async (props: AddTestVehiclesProps) => {
    if (!isConnectionReady) return;

    try {
      const res = await addTestVehicle(props);
      const vehicles = res.toObject().vehicles;

      if (vehicles) {
        return vehicles.vehiclesList;
      } else {
        return [];
      }
    } catch (error) {
      console.error('addTestVehicles:', error);
    }
  };

  return [addTestVehiclesFn];
};
