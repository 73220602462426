import { NavLink } from 'react-router-dom';

import { useGetProfile } from '../../composables/api/useGetProfile';
import { NAV_LIST } from '../../constants/navigation';
import { useViewport } from '../../contexts/ViewPortContext';
import styles from '../../scss/Welcome.module.scss';

function Welcome() {
  const { isTablet, isMobile } = useViewport();
  const user = useGetProfile();

  return (
    <div className={styles.welcomeInfo}>
      <h3>Hi {user && user.name}!</h3>
      {(isTablet || isMobile) && (
        <>
          <p>Please select an item to start.</p>
          <div className={styles.quickMenuContainer}>
            {NAV_LIST.map((group) =>
              group.map((subGroup, idx) => (
                <div key={idx} className={styles.subGroup}>
                  <GroupHeading heading={subGroup.heading} to={subGroup.to} />
                  {subGroup.links &&
                    subGroup.links.map((menu) => (
                      <NavLink key={menu.text} to={menu.to} title={menu.text} className={styles.link}>
                        <div>{menu.text}</div>
                      </NavLink>
                    ))}
                </div>
              )),
            )}
          </div>
        </>
      )}
      {!isTablet && !isMobile && <p>Please select an item from the sidebar to start.</p>}
    </div>
  );
}

function GroupHeading({ heading, to }: { heading: string; to?: string }) {
  if (to) {
    return (
      <NavLink to={to}>
        <h2 className={styles.subGroupHeading}>{heading}</h2>
      </NavLink>
    );
  }
  return <h2 className={styles.subGroupHeading}>{heading}</h2>;
}

export default Welcome;
