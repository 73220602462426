import { useState } from 'react';

import { REGIONS } from '../../constants/regions';

export default function useRegions() {
  const [country, _setCountry] = useState('');
  const [division, setDivision] = useState('');

  // a province/state/etc is not a subregion: https://en.wikipedia.org/wiki/Administrative_division
  const divisions = getDivisions();

  const countries = REGIONS.map(({ cca2, name }) => ({
    cca2,
    name,
  }));

  function getDivisions() {
    const found = REGIONS.find((r) => r.cca2 === country);

    if (found) {
      return found.divisions;
    }

    return [];
  }

  function setCountry(c: string) {
    _setCountry(c);
    setDivision('');
  }

  function setMultiple(params: { country?: string; division?: string }) {
    params.country && _setCountry(params.country);
    params.division && setDivision(params.division);
  }

  return {
    country,
    setCountry,
    division,
    setDivision,
    divisions,
    countries,
    setMultiple,
  };
}
