import { useEffect, useState } from 'react';

import { useHttpService } from '../../composables/http/useHttpService';
import { orderedDocuments } from '../../constants/documents';
import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { Doc } from './types';
import { useAuthHeader } from './useAuthHeader';

export default (vehicleId: string) => {
  const [docs, setDocs] = useState([] as Doc[]);
  const [loading, setLoading] = useState(true);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const { getUrl } = useHttpService();

  async function fetchDocuments() {
    if (!isConnectionReady) return;

    setLoading(true);

    try {
      const request = new adminApiv1Pb.ListDashboardDocumentsRequest();
      request.setVehicleId(vehicleId);
      const response = await client.listDashboardDocuments(request, authHeader);
      const dashboardDocumentList = response.toObject().dashboardDocuments?.dashboardDocumentList || [];

      const _docs: Promise<Doc>[] = orderedDocuments.map(async (requiredDoc) => {
        const obj = dashboardDocumentList.find(
          ({ dashboardDocumentType }) => dashboardDocumentType === requiredDoc.type,
        );

        if (obj) {
          return {
            uploaded: true,
            label: requiredDoc.label,
            abbr: requiredDoc.abbr,
            isPdf: obj.filename.toLowerCase().endsWith('pdf'),
            docType: obj.dashboardDocumentType,
            externalFileName: obj.filename,
            fetchUrl: () => getUrl(obj.filename),
          };
        }

        return {
          uploaded: false,
          isPdf: false,
          label: requiredDoc.label,
          abbr: requiredDoc.abbr,
          docType: requiredDoc.type,
          externalFileName: '',
          fetchUrl: () => Promise.resolve(''),
        };
      });

      setDocs(await Promise.all(_docs));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDocuments();

    return () => setLoading(false);
  }, [isConnectionReady]);

  return {
    docs,
    loading,
    refetch: fetchDocuments,
  };
};
