import style from '../../scss/CreateBreakFee.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface CreateBreakFeeProps {
  onCreateBreakFee: () => Promise<void>;
  closeModel: () => void;
}

function CreateBreakFee({ onCreateBreakFee, closeModel }: CreateBreakFeeProps): JSX.Element {
  async function handleCreateBreakFee(): Promise<void> {
    await onCreateBreakFee();
  }

  return (
    <div>
      <span>Looks like we have an existing offer, Should we charge a break fee for previous buyer?</span>
      <div className={style.breakFeeOptions}>
        <BaseButtonOutline display="Yes" onClick={handleCreateBreakFee} />
        <BaseButtonOutline display="No" onClick={closeModel} />
      </div>
    </div>
  );
}

export default CreateBreakFee;
