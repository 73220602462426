import React, { useEffect, useState } from 'react';

import { VehicleOption } from '../composables/api/types';
import stylesDealerVehicles from '../scss/DealerVehicles.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseCheckbox from './base/BaseCheckbox';
import BaseSelect from './base/BaseSelect';
import BaseSelectOption from './base/BaseSelectOption';
import BaseTextInput from './base/BaseTextInput';

const optionCodes = [
  'Interior',
  'Performance',
  'Safety and Security',
  'Entertainment and Technology',
  'Exterior',
  'Custom',
];

type Props = {
  options: VehicleOption[];
  onUpdate: (options: VehicleOption[]) => void;
};

export function VehicleOptions({ options, onUpdate }: Props) {
  const [tableOptions, setTableOptions] = useState<VehicleOption[]>([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [optionName, setOptionName] = useState('');
  const [optionCode, setOptionCode] = useState('');
  const [optionType, setOptionType] = useState('');
  const [highValue, setHighValue] = useState(false);
  const [adding, setAdding] = useState(false);
  const canSave = optionName && optionCode && optionType;

  useEffect(() => {
    setTableOptions(
      options.sort((a, b) => {
        if (a.code === b.code) {
          return a.name.localeCompare(b.name);
        }
        return a.code.localeCompare(b.code);
      }),
    );

    clear();
  }, [options]);

  function startEditing(
    index: number,
    currentOptionName: string,
    currentOptionCode: string,
    currentOptionType: string,
    currentHighValue: boolean,
  ) {
    setEditIndex(index);
    setOptionName(currentOptionName);
    setOptionCode(currentOptionCode);
    setOptionType(currentOptionType);
    setHighValue(currentHighValue);
  }

  function clear() {
    setEditIndex(-1);

    setOptionName('');
    setOptionCode('');
    setOptionType('');
    setHighValue(false);

    if (adding) {
      setTableOptions(tableOptions.slice(0, tableOptions.length - 1));
    }

    setAdding(false);
  }

  async function save(
    index: number,
    newOptionName: string,
    newOptionCode: string,
    newOptionType: string,
    newHighValue: boolean,
  ) {
    const vehicleOptionsList = tableOptions.map((option, idx) => {
      if (idx === index) {
        return {
          name: newOptionName,
          code: newOptionCode,
          type: newOptionType,
          highValue: newHighValue,
        };
      }
      return option;
    });

    await onUpdate(vehicleOptionsList);
    clear();
  }

  function addOptionRow() {
    const newOptions = [...tableOptions, { name: '', code: '', type: '', highValue: false }];
    setTableOptions(newOptions);
    setEditIndex(newOptions.length - 1);
    setAdding(true);
  }

  async function deleteOption(index: number) {
    await onUpdate(tableOptions.filter((_, idx) => idx !== index));
    clear();
  }

  return (
    <div>
      <table className={stylesDealerVehicles.dataTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Type</th>
            <th>High Value</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {tableOptions.map((option, index) => {
            const isEditingThisRow = index === editIndex;

            return (
              <tr key={index}>
                <td>
                  {isEditingThisRow ? (
                    <BaseTextInput
                      id="aaa"
                      value={optionName}
                      required
                      onChange={(event) => setOptionName(event.target.value)}
                      autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    />
                  ) : (
                    option.name
                  )}
                </td>
                <td>
                  {isEditingThisRow ? (
                    <>
                      <BaseSelect
                        value={optionCode}
                        id="optionCode"
                        name="optionCode"
                        required
                        onChange={(event) => setOptionCode(event.target.value)}>
                        <BaseSelectOption value="" />

                        {optionCodes.map((code) => (
                          <BaseSelectOption key={code} value={code}>
                            {code}
                          </BaseSelectOption>
                        ))}
                      </BaseSelect>

                      {optionCode.toLowerCase() === 'custom' && (
                        <span style={{ color: 'red' }}>WARNING: Custom Options cannot be edited OR removed!</span>
                      )}
                    </>
                  ) : (
                    option.code
                  )}
                </td>
                <td>
                  {isEditingThisRow ? (
                    <BaseTextInput
                      value={optionType}
                      required
                      onChange={(event) => setOptionType(event.target.value)}
                    />
                  ) : (
                    option.type
                  )}
                </td>
                <td align="center">
                  {isEditingThisRow ? (
                    <BaseCheckbox
                      label="High value"
                      fontSize=".8rem"
                      checked={highValue}
                      required
                      onChange={(event) => setHighValue(event.target.checked)}></BaseCheckbox>
                  ) : (
                    <p>{option.highValue ? 'Yes' : 'No'}</p>
                  )}
                </td>
                <td>
                  <div>
                    {isEditingThisRow ? (
                      <>
                        <BaseButtonOutline
                          display="Save"
                          xSmall
                          onClick={() => save(index, optionName, optionCode, optionType, highValue)}
                          disabled={!canSave}
                        />
                        <BaseButtonOutline display="Cancel" xSmall onClick={clear} />
                        {!adding && <BaseButtonOutline display="Delete" xSmall onClick={() => deleteOption(index)} />}
                      </>
                    ) : option.code.toLowerCase() === 'custom' ? (
                      <p style={{ fontSize: '.8em' }}>Custom options cannot be changed</p>
                    ) : (
                      <BaseButtonOutline
                        display="Edit"
                        xSmall
                        onClick={() => startEditing(index, option.name, option.code, option.type, option.highValue)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <BaseButtonOutline display="Add Option" small onClick={addOptionRow} disabled={adding} />
    </div>
  );
}
