import { useEffect, useState } from 'react';

import useRegions from '../utils/useRegions';

type GroupInputEvent = {
  target: {
    name: string;
    value: string;
  };
};

const useHandleGroupInput = () => {
  const initBasicValues = {
    name: '',
    legalName: '',
    gstNumber: '',
    pstNumber: '',
    provinceRegistrationNo: '',
    phone: '',
    email: '',
    primaryMake: '',
    subscriptionPlan: '0',
    googlePlaceId: '',
    externalDealerId: '',
    accountantEmail: '',
    autoGroupId: '',
    dealershipReferrerId: '',
    dealershipReferrerNotes: '',
    rin: '',
    controllerEmail: '',
    salesManagerEmail: '',
    gmEmail: '',
  };

  const initAddrValues = {
    street: '',
    city: '',
    division: '',
    postal: '',
    telephoneNumber: '',
    faxNumber: '',
    country: '',
  };

  const [values, setValues] = useState(initBasicValues);
  const [addressValues, setAddressValues] = useState(initAddrValues);
  const { setCountry, setDivision, countries, divisions } = useRegions();

  useEffect(() => {
    setAddressValues({ ...addressValues, division: '' });
    setCountry(addressValues.country);
    setDivision('');
  }, [addressValues.country]);

  const handleBasicInputChange = (e: GroupInputEvent) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleAddressInputChange = (e: GroupInputEvent) => {
    const { name, value } = e.target;
    setAddressValues({ ...addressValues, [name]: value });
  };

  const resetBasicValue = () => setValues(initBasicValues);
  const resetAddressValues = () => setAddressValues(initAddrValues);

  return {
    values,
    addressValues,
    handleBasicInputChange,
    handleAddressInputChange,
    resetAddressValues,
    resetBasicValue,
    countries,
    divisions,
  };
};

export default useHandleGroupInput;
