import React, { ChangeEvent } from 'react';

import styles from '../../../scss/Input.module.scss';

interface NumInputProps {
  label?: string;
  name: string;
  value: string | number;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputType?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
}

function TextInput({
  name,
  label,
  value,
  handleChange,
  onChange,
  inputType,
  placeholder,
  required,
  disabled,
}: NumInputProps) {
  const RequiredStar = () => (
    <abbr title="This field is mandatory" aria-label="required">
      *
    </abbr>
  );

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={name}>
        {label} {required && <RequiredStar />}
      </label>

      <input
        id={name}
        name={name}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange && onChange(e);
          handleChange && handleChange(e);
        }}
        type={inputType || 'text'}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}

export default TextInput;
