import React, { useState } from 'react';

function useNumberInput(initialValue = 0) {
  const [inputVal, setInputVal] = useState(initialValue);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.valueAsNumber);
  };
  const resetInput = () => setInputVal(initialValue);

  return [inputVal, { handleChange, resetInput, setInputVal }] as const;
}

export default useNumberInput;
