import { withAuthenticationRequired } from '@auth0/auth0-react';

import { Vehicle } from '../composables/api/types';
import { useGetReadyForAuctionVehicles } from '../composables/api/useGetReadyForAuctionVehicles';
import useModal from '../composables/utils/useModal';
import { getNumberWithComma } from '../helpers/utils';
import styles from '../scss/ReadyForAuction.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseCheckbox from './base/BaseCheckbox';
import BaseVehicleList from './base/BaseVehicleList';
import BaseWrapper from './base/BaseWrapper';
import ReadyForAuctionHeader from './ReadyForAuctionHeader';
import AddImageVehicle from './utils/AddImageVehicle';
import EditAuctionVehicle from './utils/EditAuctionVehicle';
import EditFloorPrice from './utils/EditFloorPrice';
import EditVehicleReferrerModal from './utils/EditVehicleReferrerModal';
import Loading from './utils/Loading';
import RemoveVehicleFromReadyForAuction from './utils/RemoveVehicleFromReadyForAuction';
import TransferOwnership from './utils/TransferOwnership';
import VehiclePrices from './utils/VehiclePrices';
import ViewAuctionVdp from './utils/ViewAuctionVdp';

function ReadyForAuction() {
  const {
    vehicles,
    loadingVehicles,
    fetchVehicles,
    toggleScheduleAll,
    toggleScheduleVehicle,
    scheduledIds,
    allSelected,
    handleSetAuctionStartPrice,
    auctionStartPrices,
  } = useGetReadyForAuctionVehicles();

  const { openModal } = useModal();

  const openEditFloorPrice = (vehicle: Vehicle) => {
    openModal(<EditFloorPrice vehicle={vehicle} onSetAuctionStartPrice={handleSetAuctionStartPrice} />, {
      contentLabel: `Add Floor Price for Vehicle: ${vehicle.id}`,
      shouldCloseOnOverlayClick: false,
    });
  };

  if (loadingVehicles) {
    return <Loading />;
  }

  return (
    <BaseWrapper>
      <ReadyForAuctionHeader
        scheduledIds={scheduledIds}
        vehicles={vehicles}
        onSelectAll={toggleScheduleAll}
        allSelected={allSelected}
        onComplete={fetchVehicles}
        auctionStartPrices={auctionStartPrices}
      />

      {vehicles.length === 0 && <p className={styles.noVehicles}>No vehicles found in this category</p>}

      <BaseVehicleList
        vehicleList={vehicles}
        renderVehicleMeta={(vehicle: Vehicle) => (
          <div>
            <BaseButtonOutline small display="Edit Floor Price" onClick={() => openEditFloorPrice(vehicle)} />
            <div>
              <h4>Auction Floor Price</h4>
              <div>${getNumberWithComma(vehicle.price)}</div>
            </div>
          </div>
        )}
        renderActions={(vehicleId: string, vehicleMileage: number = 0, vehicleMileageUom: number = 0) => (
          <div className={styles.renderAction}>
            <ViewAuctionVdp vehicleId={vehicleId} />

            <EditAuctionVehicle vehicleId={vehicleId} />

            <RemoveVehicleFromReadyForAuction vehicleId={vehicleId} />

            <AddImageVehicle vehicleId={vehicleId} />
            <TransferOwnership vehicleId={vehicleId} isInAuction={true} />
            <EditVehicleReferrerModal vehicleId={vehicleId} referrerEmail={''} />
            <VehiclePrices vehicleId={vehicleId} mileage={vehicleMileage} mileageUom={vehicleMileageUom} />
            <span className={styles.toggleSchedule}>
              <BaseCheckbox
                checked={scheduledIds.includes(vehicleId)}
                label="Schedule for auction"
                onChange={() => toggleScheduleVehicle(vehicleId)}
                fontSize=".8rem"
              />
            </span>
          </div>
        )}
      />
    </BaseWrapper>
  );
}

export default withAuthenticationRequired(ReadyForAuction, {
  onRedirecting: () => <Loading />,
});
