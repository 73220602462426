import { AuctionVehiclePriceMap, Vehicle } from '../composables/api/types';
import useModal from '../composables/utils/useModal';
import styles from '../scss/ReadyForAuctionHeader.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseCheckbox from './base/BaseCheckbox';
import CreateAuctionWrapper from './CreateAuctionWrapper';

type Props = {
  scheduledIds: string[];
  vehicles: Vehicle[];
  onSelectAll: (a: boolean) => void;
  allSelected: boolean;
  onComplete: () => void;
  auctionStartPrices: AuctionVehiclePriceMap;
};

export default function ReadyForAuctionHeader({
  scheduledIds,
  vehicles,
  onSelectAll,
  allSelected,
  onComplete,
  auctionStartPrices,
}: Props) {
  const { openModal } = useModal();

  const openCreateAuction = () => {
    openModal(
      <CreateAuctionWrapper
        vehicleIds={scheduledIds}
        auctionStartPrices={auctionStartPrices}
        onComplete={onComplete}
      />,
      {
        shouldCloseOnOverlayClick: false,
      },
    );
  };

  return (
    <header className={styles.header}>
      <h2>Ready for Auction Schedule</h2>

      <div className={styles.createAuctionRegion}>
        <BaseButtonOutline
          className={styles.createAuctionButton}
          display={scheduledIds.length ? 'Add Vehicles To An Auction' : 'Set Up A New Auction'}
          onClick={openCreateAuction}
        />

        {vehicles.length > 0 && (
          <>
            <p>Vehicles scheduled for auction: {scheduledIds.length}</p>
            <BaseCheckbox
              checked={allSelected}
              label="Schedule all for auction"
              onChange={() => onSelectAll(!allSelected)}
            />
          </>
        )}
      </div>
    </header>
  );
}
