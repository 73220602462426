import { SimpleOfferInfo } from '../../composables/api/types';
import { useStoreCheckout } from '../../composables/api/useStoreCheckout';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useInput from '../../composables/utils/useInput';
import styles from '../../scss/StoreCheckout.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseExpandSelect from '../base/BaseExpandSelect';

type SelectStoreProps = {
  AcceptedOffer: SimpleOfferInfo;
  onCreateOfferDocument: (dealershipId?: string) => void;
};

const SEND_OFFER_DOCUMENT_BTN_TEXT = {
  initialText: 'Send Offer Document',
  confirmationText: 'Click again to send offer document',
  completionText: 'Offer Document Triggered!',
};

export default function ViewStoreList({ AcceptedOffer, onCreateOfferDocument }: SelectStoreProps) {
  const { autoGroup, loading, dealerships } = useStoreCheckout(AcceptedOffer?.buyerId);
  const [dealershipId, { setInputVal: setDealershipId }] = useInput('');

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: SEND_OFFER_DOCUMENT_BTN_TEXT,
    fn: () => onCreateOfferDocument(dealershipId),
  });

  if (loading) {
    return <div>Fetching...</div>;
  }

  return (
    <div className={styles.chooseStore}>
      {!autoGroup && (
        <div>
          <h2>To: {AcceptedOffer?.dealerName}</h2>
        </div>
      )}

      {autoGroup && (
        <div>
          <div>
            <BaseExpandSelect
              label="Dealership"
              selectEvent={setDealershipId}
              options={dealerships}
              selectedValue={dealershipId}
            />
          </div>
        </div>
      )}

      <BaseButtonOutline small display={confirmBtnText} onClick={doubleCheck} disabled={isConfirmBtnDisabled} />
    </div>
  );
}
