import React from 'react';

type YearInputProps = {
  year: string;
  handleYearChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};

function YearInput({ year, handleYearChange, isDisabled }: YearInputProps) {
  return (
    <div>
      <label htmlFor="year">Year</label>
      <input
        type="number"
        min="2021"
        max="9999"
        id="year"
        value={year}
        onChange={handleYearChange}
        disabled={isDisabled}
      />
    </div>
  );
}

export default YearInput;
