import axios, { AxiosInstance } from 'axios';

import { useAuthHeader } from '../api/useAuthHeader';

const baseURL = `${process.env.REACT_APP_FILE_SERVER_ADDRESS}`;

export const useHttpService = (endPoint: string = '') => {
  const [authHeader] = useAuthHeader();

  const client: AxiosInstance = axios.create({
    baseURL: `${baseURL}${endPoint}`,
    headers: {
      ...authHeader,
    },
    withCredentials: true,
  });

  const post = (data: any) => {
    return client.post(`${baseURL}${endPoint}`, data, {
      headers: {
        'Content-type': 'multipart/form-data',
        ...authHeader,
      },
    });
  };

  const getUrl = async (filename: string) => {
    const res = await client.get(`secure/${filename}`, {
      responseType: 'blob',
    });

    return window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
  };

  return {
    post,
    getUrl,
  };
};
