import { RiSearchLine } from 'react-icons/ri';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import ChangeOwnerConsumer from './ChangeOwnerConsumer';

interface TransferOwnershipProps {
  vehicleId: string;
  isInAuction: boolean;
}

function TransferOwnership({ vehicleId, isInAuction }: TransferOwnershipProps) {
  const { openUtilityDrawer } = useUtilityDrawer();

  const handleDrawer = async (id: string) => {
    openUtilityDrawer(<ChangeOwnerConsumer vehicleId={id} isInAuction={isInAuction} />);
  };
  return (
    <BaseButtonOutline small display="" onClick={() => handleDrawer(vehicleId)}>
      <>
        <RiSearchLine /> Transfer Ownership
      </>
    </BaseButtonOutline>
  );
}

export default TransferOwnership;
