import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useState } from 'react';

import { ListingStatus, SelectedDealTankListingStatus } from '../composables/api/types';
import useDealTankVehicles, { ListingStatusItem } from '../composables/api/useDealTankVehicles';
import { WayOfSale } from '../grpc/build/protos/enum_pb';
import { converListingStatus, convertVehicleOrigin } from '../helpers/utils';
import styles from '../scss/DealTank.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import BaseVehicleListItem from './base/BaseVehicleListItem';
import BaseWrapper from './base/BaseWrapper';
import AcceptDealTankOfferButton from './utils/AcceptDealTankOfferButton';
import CarfaxReport from './utils/CarfaxReport';
import CompleteSaleButton from './utils/CompleteSaleButton';
import CreateDealTankOfferButton from './utils/CreateDealTankOfferButton';
import DealTankButton from './utils/DealTankButton';
import Loading from './utils/Loading';
import Paginator from './utils/Paginator';
import RegisteredDealTankOwner from './utils/RegisteredDealTankOwner';
import TradeInInfos from './utils/TradeInInfo';
import TransferOwnership from './utils/TransferOwnership';
import VehicleSearch from './utils/VehicleSearch';
import VehicleSearchFacet from './utils/VehicleSearchFacet';
import ViewDealTankVdp from './utils/ViewDealTankVdp';

function DealTankList() {
  const defaultPageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { vehicles, totalVehicles, setFacets, allListingStatuses, facets, fetchVehicles, getFacets, getListingStatus } =
    useDealTankVehicles(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [viewingStatus, setViewingStatus] = useState<SelectedDealTankListingStatus>({
    display: '',
    value: ListingStatus.INVALID,
  });

  function handleReset() {
    handleSearch('');

    setViewingStatus({
      display: '',
      value: ListingStatus.INVALID,
    });
  }

  function handleSearch(_keyword: string) {
    setKeyword(_keyword);
    setCurrentPage(1);

    fetchVehicles({ keyword: _keyword, listingStatus: viewingStatus.value, pageNumber: 1 });
    getFacets(_keyword, viewingStatus.value);
    getListingStatus(_keyword);
  }

  function handleListingSatatus(listingStatus: ListingStatusItem) {
    setViewingStatus(listingStatus);

    fetchVehicles({
      keyword: keyword,
      listingStatus: listingStatus.value,
      pageNumber: currentPage,
    });
    getFacets(keyword, listingStatus.value);
  }

  function handleNewPage(newPage: number) {
    setCurrentPage(newPage);
    fetchVehicles({
      keyword: keyword,
      listingStatus: viewingStatus.value,
      pageNumber: newPage,
    });
  }

  // handleSelectFacets: Sets values isSelected to enable/disable the radio button
  // If the value has been selected, the value with the fieldName would get parsed into Facet Filters
  function handleSelectFacets(fieldName: string, value: string) {
    const updatedFacetList = facets.map((item) => {
      if (item.fieldName !== fieldName) {
        return item;
      }

      // Mapping the list of values from the facet
      const values = item.countsList.map((itemValue) => {
        if (value !== itemValue.value) {
          return itemValue;
        }

        return {
          ...itemValue,
          isSelected: !itemValue.isSelected,
        };
      });

      if (values.length > 0) item.countsList = values;

      return item;
    });

    setFacets(updatedFacetList);

    fetchVehicles({
      keyword: keyword,
      listingStatus: viewingStatus.value,
      pageNumber: currentPage,
    });
  }

  return (
    <BaseWrapper>
      <div className={styles.categories__container}>
        {allListingStatuses.map((status, key) => {
          return (
            <div key={key} className={styles.listingStatusSection}>
              <BaseButtonOutline
                onClick={() => handleListingSatatus(status)}
                display={status.display}
                disabled={status.value === viewingStatus.value}></BaseButtonOutline>
            </div>
          );
        })}
      </div>
      <VehicleSearch onSearch={handleSearch} onReset={handleReset} />
      <h5>{totalVehicles} Deal Tank Vehicles found</h5>

      {totalVehicles > defaultPageSize && (
        <Paginator
          currentPage={currentPage}
          maxPage={Math.ceil(totalVehicles / defaultPageSize)}
          handleNewPage={handleNewPage}
        />
      )}

      <div className={styles.result__section}>
        {(() => {
          return (
            <div className={styles.facetCategories}>
              {facets.map((facet, key) => {
                return (
                  <div key={key}>
                    <VehicleSearchFacet
                      fieldName={facet.fieldName}
                      valueList={facet.countsList}
                      onSelect={handleSelectFacets}
                    />
                  </div>
                );
              })}
            </div>
          );
        })()}
        {vehicles.length === 0 && <p>No Vehicles</p>}
        <ul>
          {vehicles.map((v) => (
            <li key={v.id}>
              <BaseVehicleListItem
                vehicle={v}
                additionalFields={[
                  {
                    label: 'Listing Status',
                    value: converListingStatus(v.listingStatus),
                  },
                  {
                    label: 'Vehicle Origin',
                    value: convertVehicleOrigin(v.vehicleOrigin),
                  },
                  {
                    label: 'Want Trade In',
                    value: v.wantTradeIn ? 'Yes' : 'No',
                  },
                ]}
                renderActions={() => (
                  <section className={styles.renderAction}>
                    <div>
                      <ViewDealTankVdp vehicleId={v.id} />
                      <CarfaxReport vehicleId={v.id} />
                      <TransferOwnership vehicleId={v.id} isInAuction={true} />
                      <DealTankButton label="Edit Deal Tank" vehicleId={v.id} />
                      <CompleteSaleButton vehicleId={v.id} wayOfSale={WayOfSale.WAY_OF_SALE_DEAL_TANK} />
                      <RegisteredDealTankOwner vehicleId={v.id} />
                      <CreateDealTankOfferButton vehicleId={v.id} />
                      <AcceptDealTankOfferButton vehicleId={v.id} />
                      {v.wantTradeIn && <TradeInInfos vehicleId={v.id} />}
                    </div>
                  </section>
                )}
              />
            </li>
          ))}
        </ul>
      </div>

      {totalVehicles > defaultPageSize && (
        <Paginator
          currentPage={currentPage}
          maxPage={Math.ceil(totalVehicles / defaultPageSize)}
          handleNewPage={handleNewPage}
        />
      )}
    </BaseWrapper>
  );
}

export default withAuthenticationRequired(DealTankList, {
  onRedirecting: () => <Loading />,
});
