import React, { useEffect, useRef, useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';

import styles from '../../scss/BaseWrapper.module.scss';

interface BaseWrapperProps {
  children?: React.ReactNode;
}

const BaseWrapper = ({ children }: BaseWrapperProps) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    wrapper.current?.addEventListener('scroll', toggleVisible);
    return () => wrapper.current?.removeEventListener('scroll', toggleVisible);
  }, []);

  function toggleVisible() {
    const scrolled = wrapper.current?.scrollTop || 0;
    setVisible(scrolled > 100);
  }

  function goTop() {
    if (!wrapper.current) return;
    wrapper.current.scrollTop = 0;
  }

  return (
    <div ref={wrapper} className={styles.wrapper}>
      {children}
      <button
        aria-label="Go to Top"
        onClick={goTop}
        className={styles.goTopButton}
        style={{ display: visible ? 'block' : 'none' }}>
        <FiChevronUp />
      </button>
    </div>
  );
};

export default BaseWrapper;
