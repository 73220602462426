import React, { useEffect, useState } from 'react';

import { useUpdateCarfax } from '../../composables/api/useUpdateCarfax';
import useInput from '../../composables/utils/useInput';
import styles from '../../scss/CompleteSale.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import TextInput from '../utils/inputs/TextInput';

interface Message {
  isCreated: boolean;
  text: string;
}

function UpdateCredentialsForCarfax({
  dealershipId,
  accountId,
  token,
  onUpdate,
}: {
  dealershipId: string;
  accountId: string;
  token: string;
  onUpdate: (accountId: string, token: string) => void;
}) {
  const [message, setMessage] = useState<Message | null>(null);

  var [accountIdVal, { handleChange: handleAccountIdChange }] = useInput(accountId);
  var [tokenVal, { handleChange: handleTokenChange }] = useInput(token);

  const { updateCarfax, pending } = useUpdateCarfax();

  useEffect(() => {
    reset();
  }, [dealershipId]);

  function reset() {
    setMessage(null);
  }

  async function handleUpdateCarfax() {
    try {
      await updateCarfax({
        dealershipId,
        accountId: accountIdVal,
        token: tokenVal,
      });

      onUpdate(accountIdVal, tokenVal);

      setMessage({
        isCreated: true,
        text: 'Success! The Carfax credentials have been updated.',
      });
    } catch (error) {
      console.error('Carfax credentials update failed : ', error);

      setMessage({
        isCreated: false,
        text: 'Carfax credentials update unsuccessful.',
      });
    }
  }

  return (
    <div className={styles.completeSaleContainer}>
      <h3>Update Carfax Credentials</h3>

      <fieldset>
        <div>
          <TextInput name="accountId" label="Account Id" value={accountIdVal} onChange={handleAccountIdChange} />
          <TextInput name="token" label="Token" value={tokenVal} onChange={handleTokenChange} />
        </div>
      </fieldset>

      <BaseButtonOutline
        disabled={pending}
        display="Update Carfax Credentials"
        onClick={handleUpdateCarfax}
        small
        className={styles.createSaleBtn}
      />

      {!!message && <p className={message.isCreated ? styles.messageSuccess : styles.messageErr}>{message.text}</p>}
    </div>
  );
}

export default UpdateCredentialsForCarfax;
