import React, { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';

import useInput from '../../composables/utils/useInput';
import styles from '../../scss/BaseSearchInput.module.scss';

type SearchFn = (x: string) => void;
interface BaseSearchInputProps {
  placeholder: string;
  onSearch: SearchFn;
  inline?: boolean;
  disabled?: boolean;
}

function BaseSearchInput({ placeholder, onSearch, inline, disabled }: BaseSearchInputProps) {
  const [input, { handleChange, resetInput }] = useInput();

  const handleSearch = () => {
    if (!input) return;
    onSearch(input);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(input);
    }
  };

  useEffect(() => {
    if (!input) {
      onSearch('');
    }
  }, [input]);

  return (
    <div className={inline ? styles.searchWrapperInline : ''}>
      <div className={styles.baseSearchInput}>
        <input
          className={styles.searchInput}
          placeholder={placeholder}
          value={input}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />

        <button className={styles.searchBtn} onClick={handleSearch} disabled={!input}>
          <FiSearch />
        </button>
      </div>
      {!!input && (
        <button className={styles.resetBtn} onClick={resetInput}>
          Reset
        </button>
      )}
    </div>
  );
}

export default BaseSearchInput;
