import React from 'react';

import { VehiclePaveAnnouncement as VP } from '../../composables/api/types';
import styles from '../../scss/VehicleDetails.module.scss';
import { BasicFieldProps } from './types';

export default function VehiclePaveAnnouncementField({
  viewValue,
  editValue,
  isEditMode,
  isEditable,
  propName,
  editFn,
}: BasicFieldProps<VP, VP, VP>) {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedValue = {
      ...viewValue,
      otherAnnouncements: e.target.value,
    };
    editFn(propName, updatedValue);
  };

  return (
    <tr key={propName}>
      <td>{propName}</td>
      <td className={styles.renderText}>{viewValue?.otherAnnouncements}</td>

      {(() => {
        if (!isEditable || !isEditMode) return <td></td>;

        return (
          <td>
            <textarea
              className={styles.vehicleDetails__field__textarea__large}
              value={editValue.otherAnnouncements}
              onChange={handleChange}
            />
          </td>
        );
      })()}
    </tr>
  );
}
