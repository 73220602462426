import React, { useContext } from 'react';
import ReactModal from 'react-modal';

import EmptyComponent from '../../components/utils/EmptyComponent';
import { ModalContentType, ModalContext } from '../../contexts/ModalContext';

export type ModalProps = Omit<ReactModal.Props, 'isOpen'>;

export default function useModal() {
  const { isModalOpen, setIsModalOpen, currentComp, setCurrentComp, setModalProps, modalProps } = useContext(
    ModalContext,
  ) as ModalContentType;

  function closeModal() {
    setIsModalOpen(false);
    setCurrentComp(<EmptyComponent />);
  }

  function openModal(comp: React.ReactElement, props?: ModalProps) {
    setModalProps({
      onRequestClose: closeModal,
      ...props,
    });

    setCurrentComp(comp);
    setIsModalOpen(true);
  }

  return {
    closeModal,
    openModal,
    isModalOpen,
    currentComp,
    modalProps,
  };
}
