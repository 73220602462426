import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { ExtendOfferProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useExtendOffer = () => {
  const [pending, setPending] = useState(false);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const extendOffer = async ({ vehicleId }: ExtendOfferProps) => {
    if (!isConnectionReady || pending) return;

    try {
      setPending(true);
      const request = new adminApiv1Pb.ExtendOfferForVehicleRequest();

      request.setVehicleId(vehicleId);

      const response = await client.extendOfferForVehicle(request, authHeader);
      return response.toObject();
    } finally {
      setPending(false);
    }
  };

  return { extendOffer, pending };
};
