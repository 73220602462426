import { FiExternalLink } from 'react-icons/fi';

interface ViewAuctionVdpProps {
  vehicleId: string;
}
function ViewAuctionVdp({ vehicleId }: ViewAuctionVdpProps) {
  return (
    <a href={process.env.REACT_APP_DEALER + '/vehicle-details/1/' + vehicleId} target="_blank" rel="noreferrer">
      <FiExternalLink /> View VDP
    </a>
  );
}

export default ViewAuctionVdp;
