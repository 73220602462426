import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

import LogoBlock from '../../assets/logos/logo-black.svg';
import styles from '../../scss/Navbar.module.scss';
import NavLogin from './NavLogin';
import NavMenu from './NavMenu';

function Navbar() {
  const { isAuthenticated } = useAuth0();

  return (
    <div className={styles.navContainer}>
      <div className={styles.mainNav}>
        <div className={styles.controller}>
          {isAuthenticated && <NavMenu />}
          <Link to="/">
            <img src={LogoBlock} alt="MintList" title="Go to home page" />
          </Link>
        </div>

        <NavLogin />
      </div>
    </div>
  );
}

export default Navbar;
