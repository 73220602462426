import React from 'react';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import AcceptOffer from '../dealTank/AcceptOffer';

type AcceptDealTankOfferProps = {
  vehicleId: string;
};

function AcceptDealTankOfferButton({ vehicleId }: AcceptDealTankOfferProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const openDrawer = async () => {
    openUtilityDrawer(<AcceptOffer vehicleId={vehicleId} />);
  };

  return <BaseButtonOutline small display="Accept Offer" onClick={openDrawer} />;
}

export default AcceptDealTankOfferButton;
