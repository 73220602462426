import { useEffect, useState } from 'react';

import { GrpcCode } from '../../constants/enum';
import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { Currency, ListingStatus, OfferStatus } from './types';
import { useAuthHeader } from './useAuthHeader';

export default function useDealTankVehicle(vehicleId: string) {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const [fetching, setFetching] = useState(false);
  const [askingPrice, setAskingPrice] = useState<number>(0);
  const [isTradeIn, setIsTradeIn] = useState(false);
  const [added, setAdded] = useState(false);
  const [active, setActive] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [currency, setCurrency] = useState(Currency.CURRENCY_CURRENCY_CAD);
  const [adding, setAdding] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [timeNano, setTimeNano] = useState(getDefaultExpirationTime());
  const [listingId, setListingId] = useState('');

  useEffect(() => {
    fetchDealTankInfo();
  }, [isConnectionReady, vehicleId]);

  async function fetchDealTankInfo() {
    if (!isConnectionReady) return;

    try {
      setFetching(true);
      const request = new adminApiv1Pb.GetDealTankVehicleRequest();
      request.setVehicleId(vehicleId);
      const res = await client.getDealTankVehicle(request, authHeader);
      const obj = res.toObject();

      if (obj.listing) {
        const listing = obj.listing;
        setAskingPrice(listing.price);
        setIsTradeIn(listing.isTradeIn);
        setCurrency(listing.currency);
        setTimeNano(listing.expirationTime);
        setAdded(true);
        setActive(listing.listingStatus === ListingStatus.ACTIVE);
        setAccepted(listing.offerStatus === OfferStatus.OFFER_STATUS_ACCEPTED);
        setListingId(listing.id);
      }
    } catch (err) {
      if (hasCode(err) && err.code === GrpcCode.NOT_FOUND) {
        setAskingPrice(0);
        setIsTradeIn(false);
        setCurrency(Currency.CURRENCY_CURRENCY_CAD);
        setTimeNano(getDefaultExpirationTime());
        setAdded(false);
        setActive(false);
        setAccepted(false);
        setListingId('');
      } else {
        throw err;
      }
    } finally {
      setFetching(false);
    }
  }

  async function addToDealTank() {
    if (!isConnectionReady) return;

    try {
      setAdding(true);
      const request = new adminApiv1Pb.AddVehicleToDealTankRequest();
      request.setVehicleId(vehicleId);
      request.setAskingPrice(askingPrice);
      request.setCurrency(currency);
      request.setIsTradeIn(isTradeIn);
      request.setExpirationTime(timeNano);
      await client.addVehicleToDealTank(request, authHeader);
    } finally {
      await fetchDealTankInfo();
      setAdding(false);
    }
  }

  async function reactivate() {
    if (!isConnectionReady) return;

    try {
      setAdding(true);
      const request = new adminApiv1Pb.ReactivateDealTankVehicleRequest();
      request.setVehicleId(vehicleId);
      request.setAskingPrice(askingPrice);
      request.setCurrency(currency);
      request.setIsTradeIn(isTradeIn);
      request.setExpirationTime(timeNano);
      await client.reactivateDealTankVehicle(request, authHeader);
    } finally {
      await fetchDealTankInfo();
      setAdding(false);
    }
  }

  async function rejectListing() {
    if (!isConnectionReady) return;

    try {
      setRejecting(true);
      const request = new adminApiv1Pb.RejectDealTankListingRequest();
      request.setListingId(listingId);
      await client.rejectDealTankListing(request, authHeader);
    } finally {
      await fetchDealTankInfo();
      setRejecting(false);
    }
  }

  async function update() {
    if (!isConnectionReady) return;

    try {
      setUpdating(true);
      const request = new adminApiv1Pb.EditDealTankVehicleRequest();
      request.setVehicleId(vehicleId);
      request.setAskingPrice(askingPrice);
      request.setCurrency(currency);
      request.setIsTradeIn(isTradeIn);
      request.setExpirationTime(timeNano);
      await client.editDealTankVehicle(request, authHeader);
    } finally {
      await fetchDealTankInfo();
      setUpdating(false);
    }
  }

  async function deactivate() {
    if (!isConnectionReady) return;

    try {
      setRemoving(true);
      const request = new adminApiv1Pb.RemoveVehicleFromDealTankRequest();
      request.setVehicleId(vehicleId);
      await client.removeVehicleFromDealTank(request, authHeader);
    } finally {
      await fetchDealTankInfo();
      setRemoving(false);
    }
  }

  function getDefaultExpirationTime() {
    const weekInMs = 7 * 24 * 60 * 60 * 1000;

    return new Date(new Date().getTime() + weekInMs * 8).getTime() * 10 ** 6;
  }

  return {
    fetching,
    askingPrice,
    setAskingPrice,
    isTradeIn,
    setIsTradeIn,
    addToDealTank,
    adding,
    added,
    reactivate,
    active,
    accepted,
    update,
    updating,
    deactivate,
    removing,
    rejectListing,
    rejecting,
    timeNano,
    setTimeNano,
  };
}

function hasCode(e: any): e is { code: GrpcCode } {
  return 'code' in e;
}
