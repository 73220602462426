import { ChangeEvent, useRef, useState } from 'react';

interface UseFileProps {
  isMultiple: boolean;
  onFileSelect?: () => void;
}
function useFile({ isMultiple, onFileSelect }: UseFileProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<File[]>([]);

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const fileList = e.target.files;
    if (!fileList) return;

    const list = Array.from(fileList);

    if (isMultiple) {
      const existingFileNames = files.map((file) => file.name);
      const filteredNewImageList = existingFileNames.length
        ? list.filter((file) => !existingFileNames.includes(file.name))
        : list;

      setFiles([...files, ...filteredNewImageList]);
    } else {
      setFiles(list);
    }
  };

  const selectFile = () => {
    fileInputRef.current && fileInputRef.current.click();
    if (onFileSelect) onFileSelect();
  };

  const removeFile = (name: string) => {
    const updatedFiles = files.filter((file) => file.name !== name);
    setFiles(updatedFiles);
  };

  const resetFiles = () => setFiles([]);

  return {
    fileInputRef,
    files,
    handleFileSelect,
    selectFile,
    removeFile,
    resetFiles,
  };
}

export default useFile;
