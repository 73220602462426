import { useOffers } from '../../composables/api/useOffers';
import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import { WayOfSale } from '../../grpc/build/protos/enum_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';
import RegisteredOwnerModal from './RegisteredOwnerModal';

interface RegisteredOwnerProps {
  vehicleId: string;
}

function RegisteredOwner({ vehicleId }: RegisteredOwnerProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const { offerInfo, getOffers, offer } = useOffers(vehicleId);

  const ShowRegisteredOwners = () => {
    openUtilityDrawer(
      <RegisteredOwnerModal
        vehicleId={vehicleId}
        offerInfo={offerInfo}
        onGetOffers={getOffers}
        acceptedOffer={offer}
        wayOfSale={WayOfSale.WAY_OF_SALE_AUCTION}
      />,
    );
  };

  return <BaseButtonOutline small display="Offers and GloveBox" onClick={ShowRegisteredOwners} />;
}

export default RegisteredOwner;
