import { useEffect, useState } from 'react';

import { useQuestions } from '../../composables/api/useQuestions';
import * as mlApiV1Entity from '../../grpc/build/protos/mlapiv1entity_pb';
import inspectionQuestionsStyles from '../../scss/InspectionQuestions.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseTextInput from '../base/BaseTextInput';

type InspectionQuestionsTableProps = {
  questions: mlApiV1Entity.CarInspectionQuestion.AsObject[];
  onUpdate: (questions: mlApiV1Entity.CarInspectionQuestion.AsObject[]) => void;
  marketplace: number;
  onUpdateQuestionId: (questionId: string) => void;
  error: string;
};

export default function InspectionQuestionsTable(props: InspectionQuestionsTableProps) {
  const error = props.error;
  const [tableQuestions, setTableQuestions] = useState<mlApiV1Entity.CarInspectionQuestion.AsObject[]>(props.questions);

  const marketplace = props.marketplace;

  const onUpdateQuestionId = props.onUpdateQuestionId;

  const { updateQuestion } = useQuestions();
  const [editIndex, setEditIndex] = useState(-1);
  const [questionString, setQuestionString] = useState('');

  function startEditing(index: number, currentQuestionString: string) {
    setEditIndex(index);
    setQuestionString(currentQuestionString);
  }

  useEffect(() => {
    setTableQuestions(props.questions);
    console.log(props.questions);
  }, [props.questions]);

  async function save(id: string, question: string) {
    await updateQuestion(id, question);
    setEditIndex(-1);
    props.onUpdate(props.questions);
  }
  function clear() {
    setEditIndex(-1);
    setQuestionString('');
  }

  if (marketplace === -1) {
    return <div>Please select a marketplace first.</div>;
  }

  if (tableQuestions.length === 0) {
    return <div>No questions available for this marketplace.</div>;
  }

  return (
    <>
      {tableQuestions && (
        <div style={{ overflow: 'auto' }}>
          <table className={inspectionQuestionsStyles.dataTable}>
            <thead>
              <tr>
                <th style={{ width: '20%' }}>Question</th>
                <th style={{ width: '25%' }}>Options</th>
                <th style={{ width: '10%' }}>Category</th>
                <th style={{ width: '10%' }}>Type</th>
                <th style={{ width: '5%' }}>Is Required</th>
                <th style={{ width: '5%' }}>Edit</th>
              </tr>
            </thead>
            <tbody>
              {tableQuestions.map((question, index) => {
                const isEditingThisRow = index === editIndex;
                return (
                  <tr key={index}>
                    <td style={{ width: '20%' }}>
                      {isEditingThisRow ? (
                        <BaseTextInput
                          id="aaa"
                          value={questionString}
                          required
                          onChange={(event) => setQuestionString(event.target.value)}
                          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        />
                      ) : (
                        question.question
                      )}
                    </td>
                    <td style={{ width: '25%' }}>
                      <>
                        <div
                          role={'button'}
                          onClick={() => {
                            onUpdateQuestionId(question.questionId);
                          }}
                          onKeyDown={() => {
                            onUpdateQuestionId(question.questionId);
                          }}
                          tabIndex={0}>
                          <BaseButtonOutline display="View/Edit Options" onClick={() => {}} />
                        </div>
                      </>
                    </td>
                    <td style={{ width: '10%' }}>{question.category}</td>
                    <td style={{ width: '10%' }}>{question.questionType}</td>
                    <td align="center" style={{ width: '5%' }}>
                      <p>{question.isRequired ? 'Yes' : 'No'}</p>
                    </td>

                    <td style={{ width: '5%' }}>
                      <div>
                        {isEditingThisRow ? (
                          <>
                            <BaseButtonOutline
                              display="Save"
                              xSmall
                              onClick={() => save(question.questionId, questionString)}
                            />
                            <BaseButtonOutline display="Cancel" xSmall onClick={clear} />
                          </>
                        ) : question.question.toLowerCase() === 'custom' ? (
                          <p style={{ fontSize: '.8em' }}>Custom options cannot be changed</p>
                        ) : (
                          <BaseButtonOutline
                            display="Edit"
                            xSmall
                            onClick={() => {
                              startEditing(index, question.question);
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {error && <p style={{ color: 'red', fontSize: 24 }}>{error}</p>}
        </div>
      )}
    </>
  );
}
