import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useState } from 'react';

import { useAddTestVehicles } from '../composables/api/useAddTestVehicles';
import useInput from '../composables/utils/useInput';
import styles from '../scss/AddTestVehicles.module.scss';
import BaseButtonOutline from './base/BaseButtonOutline';
import Loading from './utils/Loading';

function AddTestVehicles() {
  const [addTestVehiclesFn] = useAddTestVehicles();
  const [isAdding, setIsAdding] = useState(false);
  const [userEmail, { handleChange: handleUserEmailChange }] = useInput('');
  const [numberOfCars, { handleChange: handleNumberOfCarsChange }] = useInput('1');

  const isAddEnabled = () => {
    return !!userEmail.trim() && !!numberOfCars && !isAdding;
  };

  const addTestVehicles = async () => {
    setIsAdding(true);

    await addTestVehiclesFn({
      consumerEmail: userEmail.trim(),
      numberVehicles: +numberOfCars,
    });

    setIsAdding(false);
  };

  return (
    <div className={styles.formContainer}>
      <h2>Add Test Vehicles</h2>

      <div>
        <section className={styles.settings}>
          <div>
            <label htmlFor="userEmail">Consumer email address</label>
            <input type="text" id="userEmail" value={userEmail} onChange={handleUserEmailChange} />
          </div>

          <div>
            <label htmlFor="numberOfCars">Number of test vehicles to add</label>
            <input type="number" min="1" id="numberOfCars" value={numberOfCars} onChange={handleNumberOfCarsChange} />
          </div>

          <div>
            <BaseButtonOutline
              display={isAdding ? 'Adding ...' : `Click to add ${numberOfCars} test vehicles`}
              onClick={addTestVehicles}
              disabled={!isAddEnabled()}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(AddTestVehicles, {
  onRedirecting: () => <Loading />,
});
