import { RiCloseFill } from 'react-icons/ri';
import ReactModal from 'react-modal';

import useModal from '../../composables/utils/useModal';
import style from '../../scss/Modal.module.scss';

function Modal() {
  const { isModalOpen, currentComp, closeModal, modalProps } = useModal();

  return (
    <ReactModal closeTimeoutMS={200} isOpen={isModalOpen} {...modalProps}>
      <div className={style.header}>
        <h2>{modalProps.contentLabel}</h2>
        <span className={style.close}>
          <RiCloseFill size="1.5em" onClick={closeModal} />
        </span>
      </div>

      <div className={style.content}>{currentComp}</div>
    </ReactModal>
  );
}

export default Modal;
