import { useState } from 'react';

import { Currency } from '../../grpc/build/protos/adminapiv1_pb';

const useCurrency = () => {
  const defaultCurrency = Currency.CURRENCY_CAD;
  const [currency, setCurrency] = useState(defaultCurrency);

  const currencyOptions = [
    { display: 'CAD', value: Currency.CURRENCY_CAD },
    { display: 'USD', value: Currency.CURRENCY_USD },
  ];

  function resetCurrency() {
    setCurrency(defaultCurrency);
  }
  return {
    currencyOptions,
    currency,
    setCurrency,
    resetCurrency,
  };
};

export default useCurrency;
