import { RiAttachment2 } from 'react-icons/ri';

import { Doc } from '../../composables/api/types';
import useDocuments from '../../composables/api/useDocuments';
import useModal from '../../composables/utils/useModal';
import { useViewport } from '../../contexts/ViewPortContext';
import * as buildProtosEnumPb from '../../grpc/build/protos/enum_pb';
import style from '../../scss/DocumentLinks.module.scss';
import AddFile from './AddFile';
import DocumentModal from './DocumentModal';

type DocumentsProps = {
  vehicleId: string;
};

export default function DocumentLinks({ vehicleId }: DocumentsProps) {
  const { docs, loading, refetch } = useDocuments(vehicleId);
  const { openModal } = useModal();
  const { isMobile } = useViewport();

  async function handleClick(doc: Doc) {
    if (doc.docType == buildProtosEnumPb.DashboardDocumentType.DOCUMENT_TYPE_CARFAX_LIEN) {
      window.open(doc.externalFileName, '_blank');
    } else {
      openModal(<DocumentModal doc={doc} />, {
        contentLabel: doc.label,
      });
    }
  }

  if (loading) {
    return <p className={style.fetching}>Fetching documents...</p>;
  }

  return (
    <div className={style.docs}>
      {docs.map((doc, idx) => (
        <button
          key={idx}
          onClick={() => handleClick(doc)}
          aria-label={doc.label}
          disabled={!doc.uploaded}
          className={style.docBtn}>
          <RiAttachment2 />
          <span>{isMobile ? doc.label : doc.abbr}</span>
        </button>
      ))}
      <div className={style.uploadBtn}>
        <AddFile vehicleId={vehicleId} uploadedCallback={refetch} />
      </div>
    </div>
  );
}
