import React from 'react';

import { SimpleOfferContainer } from '../../composables/api/types';
import { OfferStatus } from '../../grpc/build/protos/enum_pb';
import { getNumberWithComma } from '../../helpers/utils';
import styles from '../../scss/BidInfo.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';

interface OfferInfoProps {
  vehicleId: string;
  disabled: boolean;
  offerInfo: SimpleOfferContainer | null;
  onGetOffers: () => void;
}

const ORIGIN_MAPPING = {
  buyer: 'Dealer Buyer Counter from',
  auction: 'Auction High Bid from',
  seller: 'Seller Counter to',
  unknown: 'Unknown',
};

function OfferInfo({ vehicleId, disabled, offerInfo, onGetOffers }: OfferInfoProps) {
  if (!offerInfo) {
    return <BaseButtonOutline small display="Show Offers" onClick={onGetOffers} disabled={disabled} />;
  }

  if (!offerInfo.offerList.length)
    return (
      <div className={styles.bidInfo__container}>
        <h4>No offers</h4>
      </div>
    );
  return (
    <div className={styles.bidInfo__container}>
      <div>
        <h4>Offer info</h4>
        <p>{offerInfo.uniqueCount} unique offeror(s)</p>
      </div>

      <div>
        <h4>Offers</h4>
        <div>
          {offerInfo.offerList.map((offer, index) => (
            <div key={`${vehicleId}-offer-${index}`}>
              <p
                className={
                  offer.offerStatus === OfferStatus.OFFER_STATUS_REJECTED ? styles.otherOffers : styles.latestOffer
                }>
                <span>${getNumberWithComma(offer.price)}</span>{' '}
                {ORIGIN_MAPPING[offer.origin as keyof typeof ORIGIN_MAPPING] || ORIGIN_MAPPING.unknown}{' '}
                {offer.dealerName} / {offer.dealershipName}
              </p>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OfferInfo;
