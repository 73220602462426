import { Currency, Vehicle } from '../../composables/api/types';
import useCommonCurrency from '../../composables/utils/useCommonCurrency';
import useDoubleCheck from '../../composables/utils/useDoubleCheck';
import useNumberInput from '../../composables/utils/useNumberInput';
import style from '../../scss/EditFloorPrice.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import NumInput from './inputs/NumInput';
import RadioButtons from './RadioButtons';

interface EditFloorPriceProps {
  vehicle: Vehicle;
  onSetAuctionStartPrice: (a: Vehicle, p: number, c: Currency) => void;
}

const BTN_TEXT = {
  initialText: 'Submit',
  confirmationText: 'Click again to submit floor price',
  completionText: 'Floor Price Set!',
};

function EditFloorPrice({ vehicle, onSetAuctionStartPrice }: EditFloorPriceProps) {
  const [startPrice, { handleChange: handleStartPriceChangeChange }] = useNumberInput(vehicle.price);
  const { currency, currencyOptions, setCurrency } = useCommonCurrency();

  const { confirmBtnText, doubleCheck, isConfirmBtnDisabled } = useDoubleCheck({
    buttonTextProp: BTN_TEXT,
    fn: handleSetAuctionStartPrice,
    useRestore: true,
  });

  function handleSetAuctionStartPrice() {
    onSetAuctionStartPrice(vehicle, startPrice, currency);
  }

  return (
    <div className={style.editFloorPrice}>
      <NumInput
        defaultMin={0}
        value={`${startPrice}`}
        label="Floor Price"
        handleChange={handleStartPriceChangeChange}
      />
      <div>
        <label htmlFor="currency">Currency</label>
        <div id="currency">
          <RadioButtons options={currencyOptions} currentSelected={currency} onClick={setCurrency} />
        </div>
      </div>
      <div>
        <BaseButtonOutline display={confirmBtnText} onClick={doubleCheck} disabled={isConfirmBtnDisabled} />
      </div>
    </div>
  );
}

export default EditFloorPrice;
