import { MdMenu, MdMenuOpen } from 'react-icons/md';

import { useNavDrawer } from '../../contexts/NavDrawerContext';
import styles from '../../scss/NavMenu.module.scss';

function NavMenu() {
  const { setIsDrawerOpen, isDrawerOpen } = useNavDrawer();

  if (isDrawerOpen) {
    return (
      <MdMenuOpen size="20" color="#2f2f2f" className={styles.menuToggle} onClick={() => setIsDrawerOpen(false)} />
    );
  }
  return <MdMenu size="20" color="#2f2f2f" className={styles.menuToggle} onClick={() => setIsDrawerOpen(true)} />;
}

export default NavMenu;
