import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { Offer } from './types';
import { useAuthHeader } from './useAuthHeader';

interface GetAcceptedOfferProps {
  vehicleId: string;
  wayOfSale: enumPb.WayOfSale;
}

export const useGetAcceptedOffer = ({ vehicleId, wayOfSale }: GetAcceptedOfferProps) => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [offer, setOffer] = useState<Offer | null>(null);
  const [loading, setLoading] = useState(true);

  async function getAcceptedOffer() {
    if (!isConnectionReady) return null;

    setLoading(true);

    const request = new adminApiv1Pb.GetAcceptedOfferRequest();
    request.setVehicleId(vehicleId);
    request.setWayOfSale(wayOfSale);

    try {
      const response = await client.getAcceptedOffer(request, authHeader);
      const offer = response.toObject().offer || null;

      setOffer(offer);
    } catch (e) {
      console.error('getAcceptedOffer failed,', e);
      setOffer(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAcceptedOffer();
  }, [isConnectionReady, vehicleId]);

  return { getAcceptedOffer, offer, loading };
};
