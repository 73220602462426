import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { VehicleStatus } from '../../grpc/build/protos/adminapiv1_pb';
import * as searchEntityPb from '../../grpc/build/protos/searchentity_pb';
import { FacetFilter, FilterType } from '../../grpc/build/protos/searchentity_pb';
import { convertConsumerVehicleStatus, convertVehicleStatus } from '../../helpers/utils';
import { useApiReady } from '../utils/useApiReady';
import { EditableFacet, RemovableVehicle, Vehicle } from './types';
import { useAuthHeader } from './useAuthHeader';
import { useRemoveVehicleFromAuction } from './useRemoveVehicleFromAuction';

export type VehicleStatusItem = {
  display: string;
  value: VehicleStatus;
};

const allStatus = [
  VehicleStatus.VEHICLE_STATUS_CREATED,
  VehicleStatus.VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED,
  VehicleStatus.VEHICLE_STATUS_INSPECTION_CREATED,
  VehicleStatus.VEHICLE_STATUS_INSPECTION_COMPLETED,
  VehicleStatus.VEHICLE_STATUS_CONFIRMED,
  VehicleStatus.VEHICLE_STATUS_DEACTIVATED,
];

type AuctionVehicle = RemovableVehicle & {
  status: adminApiv1Pb.VehicleStatus;
} & Pick<Vehicle, 'referrer'>;

export default function useGetVehicles(pageSize = 10) {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [facets, setFacets] = useState<EditableFacet[]>([]);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [allVehicleStatuses, setAllVehicleStatuses] = useState<VehicleStatusItem[]>([]);
  const [pending, setPending] = useState(false);
  const removeVehicleFromAuctionFn = useRemoveVehicleFromAuction();

  const vehicleList: AuctionVehicle[] = vehicles.map((vehicle) => {
    return {
      ...vehicle,
      isRemoved: false,
    };
  });

  async function removeVehicleFromAuction(vehicleId: string) {
    try {
      await removeVehicleFromAuctionFn(vehicleId);
      const vehicle = vehicleList.find((vehicle) => vehicle.id === vehicleId);
      if (vehicle) vehicle.isRemoved = true;
    } catch (error) {
      console.error(error);
      alert('Failed to remove vehicle');
    }
  }

  async function _getVehiclesByStatus(
    keyword: string,
    pageNum: number,
    _facets: EditableFacet[],
    vehicleStatus?: adminApiv1Pb.VehicleStatus,
  ): Promise<adminApiv1Pb.ListVehiclesResponse> {
    const request = new adminApiv1Pb.ListVehiclesRequest();
    vehicleStatus && request.setVehicleStatus(vehicleStatus);
    request.setKeyword(keyword);
    request.setFacetFiltersList(parseFacetTerms(_facets));
    const pagination = new adminApiv1Pb.Pagination();
    pagination.setOffset(pageNum);
    pagination.setPageSize(pageSize);
    request.setPagination(pagination);

    return client.listVehicles(request, authHeader);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleVehicleStatus(vehicleStatuses?: searchEntityPb.FacetCount[]) {
    const filteredVehicleStatuses = allStatus.map((status) => {
      const convertedStatus = vehicleStatuses?.find(
        (count) => status === convertConsumerVehicleStatus(count.getValue()),
      );
      return {
        display: convertVehicleStatus(status) + ' ' + (convertedStatus?.getCount() || 0),
        value: status,
      };
    });

    setAllVehicleStatuses(filteredVehicleStatuses);
  }

  async function fetchVehicles(
    keyword: string,
    pageNum: number,
    _facets: EditableFacet[],
    updateFacet?: boolean,
    vehicleStatus?: adminApiv1Pb.VehicleStatus,
  ) {
    try {
      if (!isConnectionReady) return;
      setPending(true);
      const res = await _getVehiclesByStatus(keyword, pageNum, _facets, vehicleStatus);
      setVehicles(res.toObject().vehicles?.vehiclesList || []);
      setTotalVehicles(Math.ceil(res.toObject().total));
      if (updateFacet) handleFacets(res.getFacetCountsList());
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleFacets(facets: searchEntityPb.Facet[]) {
    const newFacets = facets.map((facet) => {
      const countList = facet.getCountsList().map((count) => {
        const newCount = {
          count: count.getCount(),
          value: count.getValue(),
          isSelected: false,
        };
        return newCount;
      });

      const newFacet: EditableFacet = {
        countsList: countList,
        fieldName: facet.getFieldName(),
      };

      return newFacet;
    });

    setFacets(newFacets);
  }

  function parseFacetTerms(_facets: EditableFacet[]) {
    return _facets
      .filter((facet) => {
        return facet.countsList.some(({ isSelected }) => {
          return isSelected;
        });
      })
      .map((facet) => {
        const facetFilter = new FacetFilter();
        facetFilter.setFieldName(facet.fieldName);

        facetFilter.setValuesList(
          facet.countsList
            .filter(({ isSelected }) => {
              return isSelected;
            })
            .map(({ value }) => value),
        );
        facetFilter.setFilterType(FilterType.FILTER_TYPE_OR);

        return facetFilter;
      });
  }

  async function getStatusCounts(keyword: string, _facets: EditableFacet[]) {
    try {
      if (!isConnectionReady) return;

      setPending(true);
      const res = await _fetchVehicleStatusesAndFacets(keyword, _facets);
      handleVehicleStatus(res.getVehicleStatus()?.getCountsList());
    } finally {
      setPending(false);
    }
  }

  async function _fetchVehicleStatusesAndFacets(
    keyword: string,
    _facets?: EditableFacet[],
  ): Promise<adminApiv1Pb.ListVehicleFacetsResponse> {
    const request = new adminApiv1Pb.ListVehiclesRequest();
    request.setKeyword(keyword);
    _facets && request.setFacetFiltersList(parseFacetTerms(_facets));

    return client.listVehicles(request, authHeader);
  }

  useEffect(() => {
    try {
      // we need to pull facets in the beginning and start from page 1
      fetchVehicles('', 1, [], true);
      getStatusCounts('', []);
    } catch (error) {
      console.error('listVehicles:', error);
    }
  }, [isConnectionReady]);

  return {
    vehicles,
    facets,
    allVehicleStatuses,
    setVehicles,
    setFacets,
    totalVehicles,
    clearVehicles: () => setVehicles([]),
    fetchVehicles,
    pending,
    getStatusCounts,
    removeVehicleFromAuction,
    vehicleList,
  };
}
