import { useState } from 'react';

import { Vehicle, VehicleImage } from '../composables/api/types';
import { ImageSource } from '../grpc/build/protos/enum_pb';
import style from '../scss/Images.module.scss';
import ImageReplace from './upload/ImageReplace';

const imageSourceMap = {
  [ImageSource.IMAGE_SOURCE_INVALID]: 'Invalid',
  [ImageSource.IMAGE_SOURCE_PAVE]: 'PAVE',
  [ImageSource.IMAGE_SOURCE_USER]: 'User',
  [ImageSource.IMAGE_SOURCE_VIN_DECODER]: 'VIN Decoder',
  [ImageSource.IMAGE_SOURCE_FEED]: 'Feed',
};

const orderMapping: Record<number, string> = {
  1: 'VIN', // image of VIN (can be manually entered)
  2: 'Interior', // driver side of the interior
  3: 'Cluster', // view of the odometer and instrument panel
  4: 'Left side', // horizontal view of the driver side
  5: 'Front', // view of the front
  6: 'Tires', // angled view of the tires to illustrate tread depth
  7: 'Right side', // horizontal view of the passenger side
  8: 'Rear view', // back side view of the vehicle
  9: 'Windshield', // angled view of the windshield
  10: 'Front left', // angled view of the front left side
  11: 'Front right', // angled view of the right side
  12: 'Rear right', // angled view of right rear
  13: 'Rear left', // angled view of the left
};

function Images({ vehicle, onUpdate }: { vehicle: Vehicle; onUpdate: () => void }) {
  const images = vehicle.imagesList.sort((a, b) => a.order - b.order);

  return (
    <div className={style.container}>
      {images.map((image) => (
        <Image key={image.mlImageUrl} image={image} vehicleId={vehicle.id} onUpdate={onUpdate} />
      ))}
    </div>
  );
}

function Image({ image, vehicleId, onUpdate }: { image: VehicleImage; vehicleId: string; onUpdate: () => void }) {
  const [mlImageUrl, setMlImageUrl] = useState(image.mlImageUrl);

  async function handleOnReplace(mlImageUrl: string) {
    setMlImageUrl(mlImageUrl);
    await onUpdate();
  }

  return (
    <div className={style.listItem}>
      <h3>
        {imageSourceMap[image.source]} {orderMapping[image.order]}
      </h3>
      <img
        className={style.thumbnail}
        src={`${process.env.REACT_APP_IMAGE_SERVER_ADDRESS}/${mlImageUrl}`}
        alt={image.label}
      />
      <p>Order: {image.order}</p>
      <ImageReplace id={vehicleId} onReplace={handleOnReplace} order={image.order} />
    </div>
  );
}

export default Images;
