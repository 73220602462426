import BaseLabel from '../../base/BaseLabel';
import BaseSelect, { BaseSelectProps } from '../../base/BaseSelect';

interface SelectInputProps extends BaseSelectProps {
  label: string;
}

export default function SelectInput({ children, id, required, label, name, ...selectProps }: SelectInputProps) {
  return (
    <div>
      <BaseLabel htmlFor={id || name} required={required}>
        {label}
      </BaseLabel>
      <BaseSelect id={id || name} required={required} name={name} {...selectProps}>
        {children}
      </BaseSelect>
    </div>
  );
}
