import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

import { useViewport } from '../../contexts/ViewPortContext';
import styles from '../../scss/Paginator.module.scss';
import BaseButtonText from '../base/BaseButtonText';

type PaginatorProps = {
  currentPage: number;
  maxPage: number;
  // eslint-disable-next-line no-unused-vars
  handleNewPage: (newPage: number) => void;
};

function Paginator({ currentPage, maxPage, handleNewPage }: PaginatorProps) {
  const [pageSelections, setPageSelections] = useState<number[]>([]);
  const { isMobile } = useViewport();

  useEffect(() => {
    const MAX_PAGE_SELECTIONS = isMobile ? 5 : 10;
    const center = Math.ceil(MAX_PAGE_SELECTIONS / 2);
    const newMin = currentPage - center;
    const newMax = currentPage + center;

    const start = newMin < 1 ? 1 : newMin;
    const end = newMax > maxPage ? maxPage : newMax;

    setPageSelections(Array.from(new Array(end - start + 1), (x, i) => start + i));
  }, [currentPage, maxPage, isMobile]);

  const changePage = (delta: -1 | 1) => {
    const newPage = currentPage + delta;
    if (newPage < 1) return;
    if (newPage > maxPage) return;
    handleNewPage(newPage);
  };

  if (!maxPage) return <div></div>;
  return (
    <div className={styles.paginator}>
      <FiChevronsLeft className={styles.paginator__navigation} onClick={() => 1 !== currentPage && handleNewPage(1)} />

      <FiChevronLeft className={styles.paginator__navigation} onClick={() => changePage(-1)} />

      {(() => {
        const [firstPage] = pageSelections;
        if (firstPage && firstPage !== 1) return <div>...</div>;
      })()}

      {pageSelections.map((pageNum) => {
        const isCurrentPage = pageNum === currentPage;
        const pageItemClass = isCurrentPage ? styles.paginator__selectedPage : '';

        return (
          <div key={`page-select-${pageNum}`} className={pageItemClass}>
            <BaseButtonText display={`${pageNum}`} onClick={() => !isCurrentPage && handleNewPage(pageNum)} />
          </div>
        );
      })}

      {(() => {
        const [lastPage] = pageSelections.slice(-1);
        if (lastPage && lastPage !== maxPage) return <div>...</div>;
      })()}

      <FiChevronRight className={styles.paginator__navigation} onClick={() => changePage(1)} />

      <FiChevronsRight
        className={styles.paginator__navigation}
        onClick={() => maxPage !== currentPage && handleNewPage(maxPage)}
      />
    </div>
  );
}

export default Paginator;
