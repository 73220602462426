import { ReactNode } from 'react';

type BaseSelectOptionProps = {
  value?: string;
  children?: ReactNode | ReactNode[];
};

export default function BaseSelectOption({ value, children }: BaseSelectOptionProps) {
  return <option value={value}>{children}</option>;
}
