import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import { useAuthHeader } from '../api/useAuthHeader';

export const useApiReady = () => {
  const [isConnectionReady, setIsConnectionReady] = useState(false);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  useEffect(() => {
    const isReady = !!client && !!authHeader;
    setIsConnectionReady(isReady);
  }, [client, authHeader]);

  return [isConnectionReady];
};
