import { VehicleImage } from '../../composables/api/types';
import { BasicFieldProps } from './types';

export default function ImagesListField(props: BasicFieldProps<VehicleImage[]>) {
  const { viewValue: images } = props;
  return (
    <tr key="imagesList">
      <td>imagesList</td>
      <td>
        {images.map((image, index) => (
          <p key={`image-${index}`}>{image.mlImageUrl}</p>
        ))}
      </td>
      <td></td>
    </tr>
  );
}
