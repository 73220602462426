import React from 'react';

import { useHttpService } from '../../composables/http/useHttpService';
import styles from '../../scss/FileUpload.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import { UploadStatus } from './types';

const FileUploader = ({ vehicleId, file, fileType, onError, onClear, onStatusChange, uploadStatus }: UploadProps) => {
  const { post } = useHttpService('/dashboard-upload');

  const uploadFile = async () => {
    onStatusChange(UploadStatus.START);
    const formData = new FormData();
    formData.append(fileType, file, file.name);
    formData.append('vehicle_id', vehicleId);

    try {
      await post(formData);
    } catch (error) {
      console.error('File Upload > ', error);
      const msg = `${(error as Error).name}: ${(error as Error).message}`;
      onError(msg);
    } finally {
      onStatusChange(UploadStatus.DONE);
    }
  };

  return (
    <>
      <div className={styles.uploadContainer}>
        <BaseButtonOutline
          small
          onClick={uploadFile}
          display={uploadStatus === UploadStatus.START ? 'Uploading' : 'Upload'}
          disabled={[UploadStatus.DONE, UploadStatus.START].includes(uploadStatus)}
        />
        <BaseButtonOutline
          small
          onClick={onClear}
          display="Reset"
          disabled={[UploadStatus.DONE, UploadStatus.START].includes(uploadStatus)}
        />
      </div>
    </>
  );
};

export default FileUploader;

interface UploadProps {
  vehicleId: string;
  file: File;
  fileType: string;
  onClear: () => void;
  onError: React.Dispatch<React.SetStateAction<string>>;
  onStatusChange: (status: UploadStatus) => void;
  uploadStatus: UploadStatus;
}

interface Error {
  name: string;
  message: string;
}
