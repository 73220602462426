import React from 'react';

import { RemovableVehicle } from '../../composables/api/types';
import { getReadableDate } from '../../helpers/utils';
import { convertMileageUom } from '../../helpers/utils';
import style from '../../scss/BaseVehicleListItem.module.scss';
import DocumentLinks from '../utils/DocumentLinks';

type VehicleListItemProps<T> = {
  vehicle: T;
  // eslint-disable-next-line no-unused-vars
  renderVehicleMeta?: (v: T) => React.ReactElement;
  // eslint-disable-next-line no-unused-vars
  renderActions?: (x: string, y?: number, z?: number) => React.ReactElement;
  additionalFields?: { label: string; value: string }[];
};

export default function BaseVehicleListItem<T extends RemovableVehicle>({
  vehicle,
  renderVehicleMeta,
  renderActions,
  additionalFields,
}: VehicleListItemProps<T>) {
  return (
    <div className={style.baseVehicleItem}>
      {vehicle.isRemoved && (
        <div className={style.removedOverlay}>
          <p>Please confirm vehicle deletion via the Auction channel in slack!</p>
        </div>
      )}

      <div className={style.baseVehicleItem__content}>
        <section>
          <table className={style.simple}>
            <caption>
              {vehicle.make} {vehicle.model} - <span>{vehicle.trimLevel || '*** missing trim ***'}</span>
            </caption>
            <tbody>
              {additionalFields &&
                additionalFields.map((field) => (
                  <tr key={field.label}>
                    <th>{field.label}</th>
                    <td>{field.value}</td>
                  </tr>
                ))}
              <tr>
                <th>VIN</th>
                <td>{vehicle.vin}</td>
              </tr>
              <tr>
                <th>Year</th>
                <td>{vehicle.year}</td>
              </tr>
              <tr>
                <th>Mileage</th>
                <td>
                  {vehicle.mileage} {convertMileageUom(vehicle.mileageUom)}
                </td>
              </tr>
              <tr>
                <th>Owner Name</th>
                <td>{vehicle.userGivenName}</td>
              </tr>
              <tr>
                <th>Owner Phone</th>
                <td>{vehicle.userPhoneNumber}</td>
              </tr>
              <tr>
                <th>Owner Email</th>
                <td>{vehicle.userEmail}</td>
              </tr>
              <tr style={vehicle.location?.address1 ? {} : { background: 'red' }}>
                <th>Location</th>
                <td>{vehicle.location && vehicle.location.address1}</td>
              </tr>
              <tr>
                <th>Created on</th>
                <td>{getReadableDate(vehicle.timeStamp?.createdAt || '')}</td>
              </tr>
              <tr>
                <th>Updated on</th>
                <td>{getReadableDate(vehicle.timeStamp?.updatedAt || '')}</td>
              </tr>
              <tr>
                <th>Vehicle ID</th>
                <td>{vehicle.id}</td>
              </tr>
            </tbody>
          </table>
          <DocumentLinks vehicleId={vehicle.id} />
        </section>

        <section className={style.baseVehicleItem__meta__container}>
          <div>{!!renderActions && renderActions(vehicle.id, vehicle.mileage, vehicle.mileageUom)}</div>
          <div>{!!renderVehicleMeta && renderVehicleMeta(vehicle)}</div>
        </section>
      </div>
    </div>
  );
}
