import React from 'react';

import { joinClasses } from '../../helpers/utils';
import styles from '../../scss/BaseButtonOutline.module.scss';

type BaseButtonOutlineProps = {
  display?: string;
  onClick: () => void;
  disabled?: boolean;
  small?: boolean;
  xSmall?: boolean;
  children?: React.ReactElement;
  className?: string;
};

function BaseButtonOutline({ display, onClick, disabled, small, xSmall, children, className }: BaseButtonOutlineProps) {
  const buttonClasses = joinClasses(
    styles.baseButtonOutline,
    disabled ? styles.disabled : '',
    small ? styles.small : '',
    xSmall ? styles.xSmall : '',
    className || '',
  );

  const onClickEvent = disabled ? () => {} : onClick;

  return (
    <button className={buttonClasses} onClick={onClickEvent}>
      {children || display}
    </button>
  );
}

export default BaseButtonOutline;
