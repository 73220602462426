// source: build/protos/adminapiv1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var third_party_github_com_mwitkow_go$proto$validators_validator_pb = require('../../third_party/github.com/mwitkow/go-proto-validators/validator_pb.js');
goog.object.extend(proto, third_party_github_com_mwitkow_go$proto$validators_validator_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var build_protos_enum_pb = require('../../build/protos/enum_pb.js');
goog.object.extend(proto, build_protos_enum_pb);
var build_protos_entities_pb = require('../../build/protos/entities_pb.js');
goog.object.extend(proto, build_protos_entities_pb);
var build_protos_mlapiv1entity_pb = require('../../build/protos/mlapiv1entity_pb.js');
goog.object.extend(proto, build_protos_mlapiv1entity_pb);
var build_protos_code_pb = require('../../build/protos/code_pb.js');
goog.object.extend(proto, build_protos_code_pb);
var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
var build_protos_dealer_pb = require('../../build/protos/dealer_pb.js');
goog.object.extend(proto, build_protos_dealer_pb);
var build_protos_searchentity_pb = require('../../build/protos/searchentity_pb.js');
goog.object.extend(proto, build_protos_searchentity_pb);
var build_protos_dealtankentity_pb = require('../../build/protos/dealtankentity_pb.js');
goog.object.extend(proto, build_protos_dealtankentity_pb);
var build_protos_salesentity_pb = require('../../build/protos/salesentity_pb.js');
goog.object.extend(proto, build_protos_salesentity_pb);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AdminUserRole', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Asset', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AssetImage', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AssetLocation', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AssetOrigin', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Assets', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Auction', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AuctionClass', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AuctionFilter', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AuctionGroup', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AuctionGroupAsset', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AuctionInfo', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.AuctionStatus', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Auctions', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Bid', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.BidID', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Bids', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CarInspectionAnswer', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CarInspectionAnswers', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CarInspectionQuestion', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CarInspectionQuestions', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CarInspectionSession', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CarInspectionSessions', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ConsumerSignup', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ConsumerSignups', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAuctionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAuctionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAuctionResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAutoGroupRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateAutoGroupResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateBreakFeeRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateBreakFeeResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealerAccountRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealerAccountResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealershipRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealershipResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateDealershipResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateFlagRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateFlagResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateScheduledJobRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateScheduledJobResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateTransactionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.CreateTransactionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Currency', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DashboardDocument', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DashboardDocuments', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealReportDetail', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealReportDetails', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealTankVehicle', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealerAccount', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealerInfo', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealerSearchRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealersInfo', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Dealership', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DealershipLocation', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Dealerships', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteAuctionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteAuctionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteFlagRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteFlagResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.EditDealTankOfferRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.EditDealTankOfferResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ErrorResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.FilterAndQuery', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GenerateDataOneRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GenerateDataOneResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GenerateVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GenerateVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetConsumersByNameRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetConsumersByNameResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetDealershipResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetOfferDocumentRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetOfferDocumentResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetOffersByStatusRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetOffersByStatusResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetProfileRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetProfileResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetProfileResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.GetVersionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ImageSource', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ImpersonateUserRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ImpersonateUserResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.IndexVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.IndexVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.InspectionStatus', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.LimitedDealership', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAuctionsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAuctionsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAuctionsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAutoGroupsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListAutoGroupsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCompletedSalesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCompletedSalesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealersRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealersResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealersResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipItemsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipItemsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListDealershipsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListMarketplacesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListMarketplacesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListOffersRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListOffersResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListOffersResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListRejectedOffersRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListRejectedOffersResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListTradeInInfosRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListTradeInInfosResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ListVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.MileageUOM', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Offer', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.OfferStatus', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Pagination', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.QuestionOption', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.QuestionType', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RejectDealTankListingRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RejectDealTankListingResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.ScheduledJobType', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SearchVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SearchVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SearchVehiclesResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SendOfferDocumentRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SendOfferDocumentResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SimpleOfferContainer', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SimpleOfferInfo', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.SuccessResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Timestamp', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.TopBidInfo', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateDealershipRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateDealershipResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateDealershipResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateOfferRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateOfferResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateOfferResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehicleRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehicleResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehicleResponse.ResponseCase', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehiclesRequest', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UpdateVehiclesResponse', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.User', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UserOrigin', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.UserRole', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Users', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Vehicle', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleClass', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleImage', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleLocation', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleOption', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleOrigin', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleStatus', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.VehicleWithInfos', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Vehicles', null, global);
goog.exportSymbol('proto.com.mintlist.adminapiv1.Version', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ErrorResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ErrorResponse.displayName = 'proto.com.mintlist.adminapiv1.ErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SuccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.SuccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SuccessResponse.displayName = 'proto.com.mintlist.adminapiv1.SuccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Timestamp.displayName = 'proto.com.mintlist.adminapiv1.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Pagination.displayName = 'proto.com.mintlist.adminapiv1.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.FilterAndQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.FilterAndQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.FilterAndQuery.displayName = 'proto.com.mintlist.adminapiv1.FilterAndQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetProfileRequest.displayName = 'proto.com.mintlist.adminapiv1.GetProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetProfileResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetProfileResponse.displayName = 'proto.com.mintlist.adminapiv1.GetProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateVehicleResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.IndexVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.IndexVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.IndexVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.IndexVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.IndexVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.IndexVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.IndexVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.GetVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.GetVehicleResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.GetVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.VehicleWithInfos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.VehicleWithInfos.displayName = 'proto.com.mintlist.adminapiv1.VehicleWithInfos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.displayName = 'proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.displayName = 'proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.displayName = 'proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.displayName = 'proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GenerateDataOneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GenerateDataOneRequest.displayName = 'proto.com.mintlist.adminapiv1.GenerateDataOneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GenerateDataOneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GenerateDataOneResponse.displayName = 'proto.com.mintlist.adminapiv1.GenerateDataOneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.TopBidInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.TopBidInfo.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.TopBidInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.TopBidInfo.displayName = 'proto.com.mintlist.adminapiv1.TopBidInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealershipsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealershipsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealershipsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Dealerships = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Dealerships.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Dealerships, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Dealerships.displayName = 'proto.com.mintlist.adminapiv1.Dealerships';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealershipItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealershipItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealershipItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealershipItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListDealershipsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealershipsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealershipsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealershipsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListRejectedOffersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.displayName = 'proto.com.mintlist.adminapiv1.ListRejectedOffersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListRejectedOffersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.displayName = 'proto.com.mintlist.adminapiv1.ListRejectedOffersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealerAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealerAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealerAccount.displayName = 'proto.com.mintlist.adminapiv1.DealerAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Dealership = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Dealership.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Dealership, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Dealership.displayName = 'proto.com.mintlist.adminapiv1.Dealership';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.LimitedDealership = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.LimitedDealership, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.LimitedDealership.displayName = 'proto.com.mintlist.adminapiv1.LimitedDealership';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealershipLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealershipLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealershipLocation.displayName = 'proto.com.mintlist.adminapiv1.DealershipLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.displayName = 'proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.displayName = 'proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.displayName = 'proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.displayName = 'proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.displayName = 'proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.displayName = 'proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.displayName = 'proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.displayName = 'proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetOfferDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.displayName = 'proto.com.mintlist.adminapiv1.GetOfferDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetOfferDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.displayName = 'proto.com.mintlist.adminapiv1.GetOfferDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.SendOfferDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.displayName = 'proto.com.mintlist.adminapiv1.SendOfferDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.SendOfferDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.displayName = 'proto.com.mintlist.adminapiv1.SendOfferDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.displayName = 'proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.displayName = 'proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.displayName = 'proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.displayName = 'proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateTransactionRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateTransactionResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateBreakFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateBreakFeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateBreakFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateBreakFeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.displayName = 'proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.displayName = 'proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListTradeInInfosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.displayName = 'proto.com.mintlist.adminapiv1.ListTradeInInfosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListTradeInInfosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.displayName = 'proto.com.mintlist.adminapiv1.ListTradeInInfosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateDealerAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateDealerAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateDealerAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateDealerAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealerInfo.displayName = 'proto.com.mintlist.adminapiv1.DealerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealersInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.DealersInfo.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealersInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealersInfo.displayName = 'proto.com.mintlist.adminapiv1.DealersInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealerSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealerSearchRequest.displayName = 'proto.com.mintlist.adminapiv1.DealerSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealersRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListDealersResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealersResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateDealershipRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateDealershipResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateDealershipResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealershipRequest.displayName = 'proto.com.mintlist.adminapiv1.GetDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetDealershipResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealershipResponse.displayName = 'proto.com.mintlist.adminapiv1.GetDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.displayName = 'proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.displayName = 'proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListCompletedSalesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListCompletedSalesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListCompletedSalesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListCompletedSalesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateDealershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateDealershipRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateDealershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateDealershipResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateDealershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateDealershipResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateDealershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateOfferRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateOfferResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateOfferResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.displayName = 'proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.displayName = 'proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DashboardDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DashboardDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DashboardDocument.displayName = 'proto.com.mintlist.adminapiv1.DashboardDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DashboardDocuments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.DashboardDocuments.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DashboardDocuments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DashboardDocuments.displayName = 'proto.com.mintlist.adminapiv1.DashboardDocuments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ConsumerSignup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ConsumerSignup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ConsumerSignup.displayName = 'proto.com.mintlist.adminapiv1.ConsumerSignup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ConsumerSignups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ConsumerSignups.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ConsumerSignups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ConsumerSignups.displayName = 'proto.com.mintlist.adminapiv1.ConsumerSignups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateScheduledJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateScheduledJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateScheduledJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateScheduledJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetVersionRequest.displayName = 'proto.com.mintlist.adminapiv1.GetVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AuctionFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AuctionFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AuctionFilter.displayName = 'proto.com.mintlist.adminapiv1.AuctionFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateAuctionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateAuctionRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateAuctionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.CreateAuctionResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateAuctionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateAuctionResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateAuctionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAuctionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAuctionsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListAuctionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListAuctionsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAuctionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAuctionsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListAuctionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AuctionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AuctionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AuctionGroup.displayName = 'proto.com.mintlist.adminapiv1.AuctionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AuctionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.AuctionInfo.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AuctionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AuctionInfo.displayName = 'proto.com.mintlist.adminapiv1.AuctionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Auction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Auction.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Auction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Auction.displayName = 'proto.com.mintlist.adminapiv1.Auction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Offer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Offer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Offer.displayName = 'proto.com.mintlist.adminapiv1.Offer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AuctionGroupAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AuctionGroupAsset.displayName = 'proto.com.mintlist.adminapiv1.AuctionGroupAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Auctions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Auctions.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Auctions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Auctions.displayName = 'proto.com.mintlist.adminapiv1.Auctions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.BidID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.BidID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.BidID.displayName = 'proto.com.mintlist.adminapiv1.BidID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Bid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Bid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Bid.displayName = 'proto.com.mintlist.adminapiv1.Bid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Bids = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Bids.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Bids, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Bids.displayName = 'proto.com.mintlist.adminapiv1.Bids';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.User.displayName = 'proto.com.mintlist.adminapiv1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealReportDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.DealReportDetails.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealReportDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealReportDetails.displayName = 'proto.com.mintlist.adminapiv1.DealReportDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealReportDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealReportDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealReportDetail.displayName = 'proto.com.mintlist.adminapiv1.DealReportDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Version.displayName = 'proto.com.mintlist.adminapiv1.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.VehicleImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.VehicleImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.VehicleImage.displayName = 'proto.com.mintlist.adminapiv1.VehicleImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.displayName = 'proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Vehicle.displayName = 'proto.com.mintlist.adminapiv1.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.VehicleOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.VehicleOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.VehicleOption.displayName = 'proto.com.mintlist.adminapiv1.VehicleOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.VehicleLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.VehicleLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.VehicleLocation.displayName = 'proto.com.mintlist.adminapiv1.VehicleLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Vehicles.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Vehicles.displayName = 'proto.com.mintlist.adminapiv1.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CarInspectionSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CarInspectionSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CarInspectionSession.displayName = 'proto.com.mintlist.adminapiv1.CarInspectionSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.CarInspectionSessions.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CarInspectionSessions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CarInspectionSessions.displayName = 'proto.com.mintlist.adminapiv1.CarInspectionSessions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.CarInspectionAnswers.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CarInspectionAnswers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CarInspectionAnswers.displayName = 'proto.com.mintlist.adminapiv1.CarInspectionAnswers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CarInspectionAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CarInspectionAnswer.displayName = 'proto.com.mintlist.adminapiv1.CarInspectionAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.QuestionOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.QuestionOption.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.QuestionOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.QuestionOption.displayName = 'proto.com.mintlist.adminapiv1.QuestionOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.CarInspectionQuestions.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CarInspectionQuestions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CarInspectionQuestions.displayName = 'proto.com.mintlist.adminapiv1.CarInspectionQuestions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.CarInspectionQuestion.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CarInspectionQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CarInspectionQuestion.displayName = 'proto.com.mintlist.adminapiv1.CarInspectionQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AssetLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AssetLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AssetLocation.displayName = 'proto.com.mintlist.adminapiv1.AssetLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Asset.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Asset.displayName = 'proto.com.mintlist.adminapiv1.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Assets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Assets.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Assets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Assets.displayName = 'proto.com.mintlist.adminapiv1.Assets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AssetImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AssetImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AssetImage.displayName = 'proto.com.mintlist.adminapiv1.AssetImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GenerateVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.GenerateVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GenerateVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.GenerateVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.SimpleOfferInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SimpleOfferInfo.displayName = 'proto.com.mintlist.adminapiv1.SimpleOfferInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.SimpleOfferContainer.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.SimpleOfferContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SimpleOfferContainer.displayName = 'proto.com.mintlist.adminapiv1.SimpleOfferContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListOffersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListOffersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListOffersRequest.displayName = 'proto.com.mintlist.adminapiv1.ListOffersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListOffersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ListOffersResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListOffersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListOffersResponse.displayName = 'proto.com.mintlist.adminapiv1.ListOffersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetConsumersByNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.displayName = 'proto.com.mintlist.adminapiv1.GetConsumersByNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.Users.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.Users.displayName = 'proto.com.mintlist.adminapiv1.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetConsumersByNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.displayName = 'proto.com.mintlist.adminapiv1.GetConsumersByNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.displayName = 'proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.displayName = 'proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.SearchVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SearchVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.SearchVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.SearchVehiclesResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.SearchVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.SearchVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.SearchVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.displayName = 'proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.oneofGroups_);
};
goog.inherits(proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.displayName = 'proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.displayName = 'proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.displayName = 'proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.displayName = 'proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.displayName = 'proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DealTankVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DealTankVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DealTankVehicle.displayName = 'proto.com.mintlist.adminapiv1.DealTankVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.EditDealTankOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.displayName = 'proto.com.mintlist.adminapiv1.EditDealTankOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.EditDealTankOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.displayName = 'proto.com.mintlist.adminapiv1.EditDealTankOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetOffersByStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.displayName = 'proto.com.mintlist.adminapiv1.GetOffersByStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetOffersByStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.displayName = 'proto.com.mintlist.adminapiv1.GetOffersByStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.displayName = 'proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.displayName = 'proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.displayName = 'proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.displayName = 'proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.displayName = 'proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.displayName = 'proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RejectDealTankListingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.displayName = 'proto.com.mintlist.adminapiv1.RejectDealTankListingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.RejectDealTankListingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.displayName = 'proto.com.mintlist.adminapiv1.RejectDealTankListingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.displayName = 'proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.displayName = 'proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteAuctionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteAuctionRequest.displayName = 'proto.com.mintlist.adminapiv1.DeleteAuctionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteAuctionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteAuctionResponse.displayName = 'proto.com.mintlist.adminapiv1.DeleteAuctionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.displayName = 'proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.displayName = 'proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.displayName = 'proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.displayName = 'proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.displayName = 'proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.displayName = 'proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAutoGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListAutoGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListAutoGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListAutoGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateAutoGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateAutoGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateAutoGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateAutoGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateFlagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateFlagRequest.displayName = 'proto.com.mintlist.adminapiv1.CreateFlagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CreateFlagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CreateFlagResponse.displayName = 'proto.com.mintlist.adminapiv1.CreateFlagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteFlagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteFlagRequest.displayName = 'proto.com.mintlist.adminapiv1.DeleteFlagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.DeleteFlagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.DeleteFlagResponse.displayName = 'proto.com.mintlist.adminapiv1.DeleteFlagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.displayName = 'proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.displayName = 'proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.displayName = 'proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.displayName = 'proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListMarketplacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListMarketplacesRequest.displayName = 'proto.com.mintlist.adminapiv1.ListMarketplacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListMarketplacesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListMarketplacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListMarketplacesResponse.displayName = 'proto.com.mintlist.adminapiv1.ListMarketplacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.displayName = 'proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.displayName = 'proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.displayName = 'proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.displayName = 'proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.displayName = 'proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.displayName = 'proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.displayName = 'proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.displayName = 'proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.displayName = 'proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.displayName = 'proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ImpersonateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ImpersonateUserRequest.displayName = 'proto.com.mintlist.adminapiv1.ImpersonateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.mintlist.adminapiv1.ImpersonateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.mintlist.adminapiv1.ImpersonateUserResponse.displayName = 'proto.com.mintlist.adminapiv1.ImpersonateUserResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ErrorResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    google_protobuf_any_pb.Any.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ErrorResponse;
  return proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ErrorResponse} returns this
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated google.protobuf.Any details = 2;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.com.mintlist.adminapiv1.ErrorResponse} returns this
*/
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.Any, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ErrorResponse} returns this
 */
proto.com.mintlist.adminapiv1.ErrorResponse.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SuccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SuccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SuccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SuccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SuccessResponse}
 */
proto.com.mintlist.adminapiv1.SuccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SuccessResponse;
  return proto.com.mintlist.adminapiv1.SuccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SuccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SuccessResponse}
 */
proto.com.mintlist.adminapiv1.SuccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SuccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SuccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SuccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SuccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SuccessResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SuccessResponse} returns this
 */
proto.com.mintlist.adminapiv1.SuccessResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Timestamp;
  return proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeletedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string created_at = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Timestamp} returns this
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string updated_at = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Timestamp} returns this
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deleted_at = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.getDeletedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Timestamp} returns this
 */
proto.com.mintlist.adminapiv1.Timestamp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Pagination;
  return proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Pagination.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Pagination} returns this
 */
proto.com.mintlist.adminapiv1.Pagination.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 offset = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Pagination.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Pagination} returns this
 */
proto.com.mintlist.adminapiv1.Pagination.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.FilterAndQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.FilterAndQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.FilterAndQuery}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.FilterAndQuery;
  return proto.com.mintlist.adminapiv1.FilterAndQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.FilterAndQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.FilterAndQuery}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.FilterAndQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.FilterAndQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string filter = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.FilterAndQuery} returns this
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.FilterAndQuery} returns this
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_by = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.FilterAndQuery} returns this
 */
proto.com.mintlist.adminapiv1.FilterAndQuery.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetProfileRequest}
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetProfileRequest;
  return proto.com.mintlist.adminapiv1.GetProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetProfileRequest}
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetProfileRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetProfileRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  USER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetProfileResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetProfileResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetProfileResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    user: (f = msg.getUser()) && proto.com.mintlist.adminapiv1.User.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetProfileResponse;
  return proto.com.mintlist.adminapiv1.GetProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.getUser = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.User} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.User, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.User|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetProfileResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetProfileResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateVehicleRequest;
  return proto.com.mintlist.adminapiv1.UpdateVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest} returns this
*/
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  VEHICLE: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateVehicleResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateVehicleResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateVehicleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateVehicleResponse;
  return proto.com.mintlist.adminapiv1.UpdateVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vehicle vehicle = 2;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.IndexVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.IndexVehiclesRequest;
  return proto.com.mintlist.adminapiv1.IndexVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.IndexVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string vehicle_ids = 1;
 * @return {!Array<string>}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.IndexVehiclesRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.IndexVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.IndexVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.IndexVehiclesResponse;
  return proto.com.mintlist.adminapiv1.IndexVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.IndexVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.IndexVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.IndexVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.IndexVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.IndexVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    referrer: (f = msg.getReferrer()) && build_protos_entities_pb.VehicleReferrer.toObject(includeInstance, f),
    deletereferrer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest;
  return proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.VehicleReferrer;
      reader.readMessage(value,build_protos_entities_pb.VehicleReferrer.deserializeBinaryFromReader);
      msg.setReferrer(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeletereferrer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferrer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.VehicleReferrer.serializeBinaryToWriter
    );
  }
  f = message.getDeletereferrer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional entity.entities.VehicleReferrer Referrer = 1;
 * @return {?proto.entity.entities.VehicleReferrer}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.getReferrer = function() {
  return /** @type{?proto.entity.entities.VehicleReferrer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.VehicleReferrer, 1));
};


/**
 * @param {?proto.entity.entities.VehicleReferrer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest} returns this
*/
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.setReferrer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.clearReferrer = function() {
  return this.setReferrer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.hasReferrer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool DeleteReferrer = 2;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.getDeletereferrer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerRequest.prototype.setDeletereferrer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    referrer: (f = msg.getReferrer()) && build_protos_entities_pb.VehicleReferrer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse;
  return proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.VehicleReferrer;
      reader.readMessage(value,build_protos_entities_pb.VehicleReferrer.deserializeBinaryFromReader);
      msg.setReferrer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferrer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.VehicleReferrer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.VehicleReferrer Referrer = 1;
 * @return {?proto.entity.entities.VehicleReferrer}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.prototype.getReferrer = function() {
  return /** @type{?proto.entity.entities.VehicleReferrer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.VehicleReferrer, 1));
};


/**
 * @param {?proto.entity.entities.VehicleReferrer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.prototype.setReferrer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.prototype.clearReferrer = function() {
  return this.setReferrer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateVehicleReferrerResponse.prototype.hasReferrer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleRequest}
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetVehicleRequest;
  return proto.com.mintlist.adminapiv1.GetVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleRequest}
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.com.mintlist.adminapiv1.AuctionGroup.toObject, includeInstance),
    listing: (f = msg.getListing()) && build_protos_dealtankentity_pb.Listing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetVehicleResponse;
  return proto.com.mintlist.adminapiv1.GetVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 4:
      var value = new build_protos_dealtankentity_pb.Listing;
      reader.readMessage(value,build_protos_dealtankentity_pb.Listing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      build_protos_dealtankentity_pb.Listing.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vehicle vehicle = 2;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated AuctionGroup auction_groups = 3;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroup, 3));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.mintlist.adminapiv1.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional entity.dealtankentity.Listing listing = 4;
 * @return {?proto.entity.dealtankentity.Listing}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.getListing = function() {
  return /** @type{?proto.entity.dealtankentity.Listing} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.Listing, 4));
};


/**
 * @param {?proto.entity.dealtankentity.Listing|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetVehicleResponse.prototype.hasListing = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest;
  return proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleInfosList: jspb.Message.toObjectList(msg.getVehicleInfosList(),
    proto.com.mintlist.adminapiv1.VehicleWithInfos.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse;
  return proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.VehicleWithInfos;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.VehicleWithInfos.deserializeBinaryFromReader);
      msg.addVehicleInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.VehicleWithInfos.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated VehicleWithInfos vehicle_infos = 2;
 * @return {!Array<!proto.com.mintlist.adminapiv1.VehicleWithInfos>}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.getVehicleInfosList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.VehicleWithInfos>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.VehicleWithInfos, 2));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.VehicleWithInfos>} value
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.setVehicleInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleWithInfos=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos}
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.addVehicleInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.mintlist.adminapiv1.VehicleWithInfos, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAuctionVehiclesResponse.prototype.clearVehicleInfosList = function() {
  return this.setVehicleInfosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.VehicleWithInfos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.VehicleWithInfos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f),
    auctionGroup: (f = msg.getAuctionGroup()) && proto.com.mintlist.adminapiv1.AuctionGroup.toObject(includeInstance, f),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.VehicleWithInfos;
  return proto.com.mintlist.adminapiv1.VehicleWithInfos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.VehicleWithInfos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader);
      msg.setAuctionGroup(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.VehicleWithInfos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.VehicleWithInfos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos} returns this
*/
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos} returns this
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AuctionGroup auction_group = 2;
 * @return {?proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.getAuctionGroup = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.AuctionGroup} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroup, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.AuctionGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos} returns this
*/
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.setAuctionGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos} returns this
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.clearAuctionGroup = function() {
  return this.setAuctionGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.hasAuctionGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional entity.enum.ListingStatus listing_status = 3;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleWithInfos} returns this
 */
proto.com.mintlist.adminapiv1.VehicleWithInfos.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagination: (f = msg.getPagination()) && proto.com.mintlist.adminapiv1.Pagination.toObject(includeInstance, f),
    startDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 5, ""),
    facetFiltersList: jspb.Message.toObjectList(msg.getFacetFiltersList(),
    build_protos_searchentity_pb.FacetFilter.toObject, includeInstance),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListVehiclesRequest;
  return proto.com.mintlist.adminapiv1.ListVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (reader.readEnum());
      msg.setVehicleStatus(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Pagination;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 6:
      var value = new build_protos_searchentity_pb.FacetFilter;
      reader.readMessage(value,build_protos_searchentity_pb.FacetFilter.deserializeBinaryFromReader);
      msg.addFacetFilters(value);
      break;
    case 7:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFacetFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      build_protos_searchentity_pb.FacetFilter.serializeBinaryToWriter
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional VehicleStatus vehicle_status = 1;
 * @return {!proto.com.mintlist.adminapiv1.VehicleStatus}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getVehicleStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getPagination = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Pagination, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string start_date = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_date = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string keyword = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated entity.searchentity.FacetFilter facet_filters = 6;
 * @return {!Array<!proto.entity.searchentity.FacetFilter>}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getFacetFiltersList = function() {
  return /** @type{!Array<!proto.entity.searchentity.FacetFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.FacetFilter, 6));
};


/**
 * @param {!Array<!proto.entity.searchentity.FacetFilter>} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setFacetFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.entity.searchentity.FacetFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.FacetFilter}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.addFacetFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.entity.searchentity.FacetFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.clearFacetFiltersList = function() {
  return this.setFacetFiltersList([]);
};


/**
 * optional AuctionStatus auction_status = 7;
 * @return {!proto.com.mintlist.adminapiv1.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getAuctionStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional entity.enum.ListingStatus listing_status = 8;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesRequest.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicles: (f = msg.getVehicles()) && proto.com.mintlist.adminapiv1.Vehicles.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    facetCountsList: jspb.Message.toObjectList(msg.getFacetCountsList(),
    build_protos_searchentity_pb.Facet.toObject, includeInstance),
    vehicleStatus: (f = msg.getVehicleStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f),
    auctionStatus: (f = msg.getAuctionStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f),
    listingStatus: (f = msg.getListingStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListVehiclesResponse;
  return proto.com.mintlist.adminapiv1.ListVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Vehicles;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.addFacetCounts(value);
      break;
    case 4:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setVehicleStatus(value);
      break;
    case 5:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setAuctionStatus(value);
      break;
    case 6:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFacetCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getVehicleStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getAuctionStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getListingStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicles vehicles = 1;
 * @return {?proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.getVehicles = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicles, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicles|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated entity.searchentity.Facet facet_counts = 3;
 * @return {!Array<!proto.entity.searchentity.Facet>}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.getFacetCountsList = function() {
  return /** @type{!Array<!proto.entity.searchentity.Facet>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.Facet, 3));
};


/**
 * @param {!Array<!proto.entity.searchentity.Facet>} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.setFacetCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.searchentity.Facet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.addFacetCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.searchentity.Facet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.clearFacetCountsList = function() {
  return this.setFacetCountsList([]);
};


/**
 * optional entity.searchentity.Facet vehicle_status = 4;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.getVehicleStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 4));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.clearVehicleStatus = function() {
  return this.setVehicleStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.hasVehicleStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional entity.searchentity.Facet auction_status = 5;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.getAuctionStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 5));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.clearAuctionStatus = function() {
  return this.setAuctionStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.hasAuctionStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional entity.searchentity.Facet listing_status = 6;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.getListingStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 6));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.setListingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.clearListingStatus = function() {
  return this.setListingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehiclesResponse.prototype.hasListingStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facetFiltersList: jspb.Message.toObjectList(msg.getFacetFiltersList(),
    build_protos_searchentity_pb.FacetFilter.toObject, includeInstance),
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    facetList: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest;
  return proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = new build_protos_searchentity_pb.FacetFilter;
      reader.readMessage(value,build_protos_searchentity_pb.FacetFilter.deserializeBinaryFromReader);
      msg.addFacetFilters(value);
      break;
    case 3:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (reader.readEnum());
      msg.setVehicleStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacetList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacetFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      build_protos_searchentity_pb.FacetFilter.serializeBinaryToWriter
    );
  }
  f = message.getVehicleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFacetList();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated entity.searchentity.FacetFilter facet_filters = 2;
 * @return {!Array<!proto.entity.searchentity.FacetFilter>}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.getFacetFiltersList = function() {
  return /** @type{!Array<!proto.entity.searchentity.FacetFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.FacetFilter, 2));
};


/**
 * @param {!Array<!proto.entity.searchentity.FacetFilter>} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.setFacetFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.searchentity.FacetFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.FacetFilter}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.addFacetFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.searchentity.FacetFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.clearFacetFiltersList = function() {
  return this.setFacetFiltersList([]);
};


/**
 * optional VehicleStatus vehicle_status = 3;
 * @return {!proto.com.mintlist.adminapiv1.VehicleStatus}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.getVehicleStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string facet_list = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.getFacetList = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsRequest.prototype.setFacetList = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facetCountsList: jspb.Message.toObjectList(msg.getFacetCountsList(),
    build_protos_searchentity_pb.Facet.toObject, includeInstance),
    vehicleStatus: (f = msg.getVehicleStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f),
    listingStatus: (f = msg.getListingStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse;
  return proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.addFacetCounts(value);
      break;
    case 2:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setVehicleStatus(value);
      break;
    case 3:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacetCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getVehicleStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getListingStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.searchentity.Facet facet_counts = 1;
 * @return {!Array<!proto.entity.searchentity.Facet>}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.getFacetCountsList = function() {
  return /** @type{!Array<!proto.entity.searchentity.Facet>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.Facet, 1));
};


/**
 * @param {!Array<!proto.entity.searchentity.Facet>} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.setFacetCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.searchentity.Facet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.addFacetCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.searchentity.Facet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.clearFacetCountsList = function() {
  return this.setFacetCountsList([]);
};


/**
 * optional entity.searchentity.Facet vehicle_status = 2;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.getVehicleStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 2));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.clearVehicleStatus = function() {
  return this.setVehicleStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.hasVehicleStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional entity.searchentity.Facet listing_status = 3;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.getListingStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 3));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.setListingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.clearListingStatus = function() {
  return this.setListingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehicleFacetsResponse.prototype.hasListingStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    pricingMap: (f = msg.getPricingMap()) ? f.toObject(includeInstance, proto.entity.entities.AuctionVehiclePricing.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest;
  return proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    case 3:
      var value = msg.getPricingMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.entity.entities.AuctionVehiclePricing.deserializeBinaryFromReader, "", new proto.entity.entities.AuctionVehiclePricing());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getPricingMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.entity.entities.AuctionVehiclePricing.serializeBinaryToWriter);
  }
};


/**
 * optional string auction_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string vehicle_ids = 2;
 * @return {!Array<string>}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};


/**
 * map<string, entity.entities.AuctionVehiclePricing> pricing = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.entity.entities.AuctionVehiclePricing>}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.getPricingMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.entity.entities.AuctionVehiclePricing>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.entity.entities.AuctionVehiclePricing));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionRequest.prototype.clearPricingMap = function() {
  this.getPricingMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse;
  return proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.AddVehiclesToAuctionResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest;
  return proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  ERROR_RESPONSE: 2,
  SUCCESS: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse;
  return proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ErrorResponse error_response = 2;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} returns this
*/
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool success = 3;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.clearSuccess = function() {
  return jspb.Message.setOneofField(this, 3, proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DeleteVehicleFromAuctionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberBids: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest;
  return proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberBids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberBids();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 number_bids = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.prototype.getNumberBids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsRequest.prototype.setNumberBids = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest;
  return proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 2;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportRequest.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse;
  return proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateCarfaxReportResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GenerateDataOneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GenerateDataOneRequest;
  return proto.com.mintlist.adminapiv1.GenerateDataOneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GenerateDataOneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest} returns this
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 2;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneRequest} returns this
 */
proto.com.mintlist.adminapiv1.GenerateDataOneRequest.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GenerateDataOneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GenerateDataOneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneResponse}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GenerateDataOneResponse;
  return proto.com.mintlist.adminapiv1.GenerateDataOneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GenerateDataOneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneResponse}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GenerateDataOneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GenerateDataOneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateDataOneResponse} returns this
 */
proto.com.mintlist.adminapiv1.GenerateDataOneResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.TopBidInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.TopBidInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.TopBidInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.TopBidInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.com.mintlist.adminapiv1.Bid.toObject, includeInstance),
    uniqueBidders: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.TopBidInfo}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.TopBidInfo;
  return proto.com.mintlist.adminapiv1.TopBidInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.TopBidInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.TopBidInfo}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Bid;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueBidders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.TopBidInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.TopBidInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.TopBidInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter
    );
  }
  f = message.getUniqueBidders();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Bid bids = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Bid>}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Bid, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Bid>} value
 * @return {!proto.com.mintlist.adminapiv1.TopBidInfo} returns this
*/
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.TopBidInfo} returns this
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional int64 unique_bidders = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.getUniqueBidders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.TopBidInfo} returns this
 */
proto.com.mintlist.adminapiv1.TopBidInfo.prototype.setUniqueBidders = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  ERROR_RESPONSE: 2,
  TOP_BID_INFO: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f),
    topBidInfo: (f = msg.getTopBidInfo()) && proto.com.mintlist.adminapiv1.TopBidInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse;
  return proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.TopBidInfo;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.TopBidInfo.deserializeBinaryFromReader);
      msg.setTopBidInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTopBidInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.TopBidInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ErrorResponse error_response = 2;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TopBidInfo top_bid_info = 3;
 * @return {?proto.com.mintlist.adminapiv1.TopBidInfo}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.getTopBidInfo = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.TopBidInfo} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.TopBidInfo, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.TopBidInfo|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.setTopBidInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.clearTopBidInfo = function() {
  return this.setTopBidInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListVehicleTopBidsResponse.prototype.hasTopBidInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealershipsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && build_protos_common_pb.Pagination.toObject(includeInstance, f),
    autoGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyWord: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsRequest}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealershipsRequest;
  return proto.com.mintlist.adminapiv1.ListDealershipsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsRequest}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_common_pb.Pagination;
      reader.readMessage(value,build_protos_common_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealershipsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_common_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getAutoGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyWord();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional entity.common.Pagination pagination = 1;
 * @return {?proto.entity.common.Pagination}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.getPagination = function() {
  return /** @type{?proto.entity.common.Pagination} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Pagination, 1));
};


/**
 * @param {?proto.entity.common.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string auto_group_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.getAutoGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.setAutoGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key_word = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.getKeyWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsRequest.prototype.setKeyWord = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Dealerships.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Dealerships.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Dealerships.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Dealerships} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Dealerships.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipsList: jspb.Message.toObjectList(msg.getDealershipsList(),
    proto.com.mintlist.adminapiv1.Dealership.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Dealerships}
 */
proto.com.mintlist.adminapiv1.Dealerships.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Dealerships;
  return proto.com.mintlist.adminapiv1.Dealerships.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Dealerships} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Dealerships}
 */
proto.com.mintlist.adminapiv1.Dealerships.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Dealership;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Dealership.deserializeBinaryFromReader);
      msg.addDealerships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Dealerships.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Dealerships.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Dealerships} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Dealerships.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dealership dealerships = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Dealership>}
 */
proto.com.mintlist.adminapiv1.Dealerships.prototype.getDealershipsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Dealership>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Dealership, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Dealership>} value
 * @return {!proto.com.mintlist.adminapiv1.Dealerships} returns this
*/
proto.com.mintlist.adminapiv1.Dealerships.prototype.setDealershipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Dealership=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Dealership}
 */
proto.com.mintlist.adminapiv1.Dealerships.prototype.addDealerships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Dealership, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Dealerships} returns this
 */
proto.com.mintlist.adminapiv1.Dealerships.prototype.clearDealershipsList = function() {
  return this.setDealershipsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && build_protos_common_pb.Pagination.toObject(includeInstance, f),
    autoGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealershipItemsRequest;
  return proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_common_pb.Pagination;
      reader.readMessage(value,build_protos_common_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_common_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getAutoGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional entity.common.Pagination pagination = 1;
 * @return {?proto.entity.common.Pagination}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.getPagination = function() {
  return /** @type{?proto.entity.common.Pagination} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Pagination, 1));
};


/**
 * @param {?proto.entity.common.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string auto_group_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.getAutoGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.setAutoGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string keyword = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershiplistitemsList: jspb.Message.toObjectList(msg.getDealershiplistitemsList(),
    build_protos_dealer_pb.DealershipListItem.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealershipItemsResponse;
  return proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.DealershipListItem;
      reader.readMessage(value,build_protos_dealer_pb.DealershipListItem.deserializeBinaryFromReader);
      msg.addDealershiplistitems(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershiplistitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_dealer_pb.DealershipListItem.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated entity.dealer.DealershipListItem DealershipListItems = 1;
 * @return {!Array<!proto.entity.dealer.DealershipListItem>}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.getDealershiplistitemsList = function() {
  return /** @type{!Array<!proto.entity.dealer.DealershipListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_dealer_pb.DealershipListItem, 1));
};


/**
 * @param {!Array<!proto.entity.dealer.DealershipListItem>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.setDealershiplistitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealer.DealershipListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.DealershipListItem}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.addDealershiplistitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealer.DealershipListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.clearDealershiplistitemsList = function() {
  return this.setDealershiplistitemsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipItemsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipItemsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALERS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListDealershipsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListDealershipsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListDealershipsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealershipsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealers: (f = msg.getDealers()) && build_protos_dealer_pb.Dealers.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealershipsResponse;
  return proto.com.mintlist.adminapiv1.ListDealershipsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_dealer_pb.Dealers;
      reader.readMessage(value,build_protos_dealer_pb.Dealers.deserializeBinaryFromReader);
      msg.setDealers(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealershipsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_dealer_pb.Dealers.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.dealer.Dealers dealers = 2;
 * @return {?proto.entity.dealer.Dealers}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.getDealers = function() {
  return /** @type{?proto.entity.dealer.Dealers} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealers, 2));
};


/**
 * @param {?proto.entity.dealer.Dealers|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.setDealers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListDealershipsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.clearDealers = function() {
  return this.setDealers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.hasDealers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListDealershipsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 total = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerRejectedDateStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerRejectedDateEnd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListRejectedOffersRequest;
  return proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferRejectedDateStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferRejectedDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferRejectedDateStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOfferRejectedDateEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 offer_rejected_date_start = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.prototype.getOfferRejectedDateStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.prototype.setOfferRejectedDateStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 offer_rejected_date_end = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.prototype.getOfferRejectedDateEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersRequest.prototype.setOfferRejectedDateEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEAL_REPORT_DETAILS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealReportDetails: (f = msg.getDealReportDetails()) && proto.com.mintlist.adminapiv1.DealReportDetails.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListRejectedOffersResponse;
  return proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DealReportDetails;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealReportDetails.deserializeBinaryFromReader);
      msg.setDealReportDetails(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealReportDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DealReportDetails.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealReportDetails deal_report_details = 2;
 * @return {?proto.com.mintlist.adminapiv1.DealReportDetails}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.getDealReportDetails = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealReportDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealReportDetails, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealReportDetails|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.setDealReportDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.clearDealReportDetails = function() {
  return this.setDealReportDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.hasDealReportDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListRejectedOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListRejectedOffersResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealerAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealerAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealerAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 6, 0),
    crmUserId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isNotificationSubscribed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    password: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isLeadContact: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isResponsibleMultiStores: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    registrationNumber: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount}
 */
proto.com.mintlist.adminapiv1.DealerAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealerAccount;
  return proto.com.mintlist.adminapiv1.DealerAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealerAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount}
 */
proto.com.mintlist.adminapiv1.DealerAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 6:
      var value = /** @type {!proto.com.mintlist.adminapiv1.UserRole} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNotificationSubscribed(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLeadContact(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResponsibleMultiStores(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealerAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealerAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealerAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCrmUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsNotificationSubscribed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsLeadContact();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsResponsibleMultiStores();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getRegistrationNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string family_name = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string given_name = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UserRole user_role = 6;
 * @return {!proto.com.mintlist.adminapiv1.UserRole}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getUserRole = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.UserRole} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.UserRole} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string crm_user_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getCrmUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setCrmUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string dealer_id = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_notification_subscribed = 10;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getIsNotificationSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setIsNotificationSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string password = 11;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_lead_contact = 12;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getIsLeadContact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setIsLeadContact = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_responsible_multi_stores = 13;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getIsResponsibleMultiStores = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setIsResponsibleMultiStores = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string registration_number = 14;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.getRegistrationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount} returns this
 */
proto.com.mintlist.adminapiv1.DealerAccount.prototype.setRegistrationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Dealership.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Dealership.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Dealership} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Dealership.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dealersList: jspb.Message.toObjectList(msg.getDealersList(),
    proto.com.mintlist.adminapiv1.DealerAccount.toObject, includeInstance),
    vehicles: (f = msg.getVehicles()) && proto.com.mintlist.adminapiv1.Vehicles.toObject(includeInstance, f),
    timeStamp: (f = msg.getTimeStamp()) && proto.com.mintlist.adminapiv1.Timestamp.toObject(includeInstance, f),
    crmDealerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dealershipLocation: (f = msg.getDealershipLocation()) && proto.com.mintlist.adminapiv1.DealershipLocation.toObject(includeInstance, f),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    email: jspb.Message.getFieldWithDefault(msg, 13, ""),
    provinceRegistrationNo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    autoGroupId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    gstNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pstNumber: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Dealership}
 */
proto.com.mintlist.adminapiv1.Dealership.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Dealership;
  return proto.com.mintlist.adminapiv1.Dealership.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Dealership} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Dealership}
 */
proto.com.mintlist.adminapiv1.Dealership.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 4:
      var value = new proto.com.mintlist.adminapiv1.DealerAccount;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealerAccount.deserializeBinaryFromReader);
      msg.addDealers(value);
      break;
    case 5:
      var value = new proto.com.mintlist.adminapiv1.Vehicles;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 6:
      var value = new proto.com.mintlist.adminapiv1.Timestamp;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmDealerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 10:
      var value = new proto.com.mintlist.adminapiv1.DealershipLocation;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealershipLocation.deserializeBinaryFromReader);
      msg.setDealershipLocation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceRegistrationNo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGroupId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setGstNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPstNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Dealership.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Dealership} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Dealership.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDealersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.mintlist.adminapiv1.DealerAccount.serializeBinaryToWriter
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrmDealerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDealershipLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.mintlist.adminapiv1.DealershipLocation.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProvinceRegistrationNo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAutoGroupId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getGstNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPstNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DealerAccount dealers = 4;
 * @return {!Array<!proto.com.mintlist.adminapiv1.DealerAccount>}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getDealersList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.DealerAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.DealerAccount, 4));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.DealerAccount>} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
*/
proto.com.mintlist.adminapiv1.Dealership.prototype.setDealersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.DealerAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.DealerAccount}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.addDealers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.mintlist.adminapiv1.DealerAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.clearDealersList = function() {
  return this.setDealersList([]);
};


/**
 * optional Vehicles vehicles = 5;
 * @return {?proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getVehicles = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicles, 5));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicles|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
*/
proto.com.mintlist.adminapiv1.Dealership.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Timestamp time_stamp = 6;
 * @return {?proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getTimeStamp = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Timestamp, 6));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Timestamp|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
*/
proto.com.mintlist.adminapiv1.Dealership.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string crm_dealer_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getCrmDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setCrmDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string external_dealer_id = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string feed_id = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional DealershipLocation dealership_location = 10;
 * @return {?proto.com.mintlist.adminapiv1.DealershipLocation}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getDealershipLocation = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealershipLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealershipLocation, 10));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealershipLocation|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
*/
proto.com.mintlist.adminapiv1.Dealership.prototype.setDealershipLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.clearDealershipLocation = function() {
  return this.setDealershipLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.hasDealershipLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string primary_make = 11;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string email = 13;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string province_registration_no = 14;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getProvinceRegistrationNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setProvinceRegistrationNo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string auto_group_id = 15;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getAutoGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setAutoGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string legal_name = 16;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string gst_number = 17;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getGstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setGstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string pst_number = 18;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.getPstNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Dealership} returns this
 */
proto.com.mintlist.adminapiv1.Dealership.prototype.setPstNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.LimitedDealership.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.LimitedDealership} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.LimitedDealership.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    crmDealerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    externalDealerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    feedId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dealershipLocation: (f = msg.getDealershipLocation()) && proto.com.mintlist.adminapiv1.DealershipLocation.toObject(includeInstance, f),
    primaryMake: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.LimitedDealership;
  return proto.com.mintlist.adminapiv1.LimitedDealership.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.LimitedDealership} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmDealerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDealerId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedId(value);
      break;
    case 10:
      var value = new proto.com.mintlist.adminapiv1.DealershipLocation;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealershipLocation.deserializeBinaryFromReader);
      msg.setDealershipLocation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryMake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.LimitedDealership.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.LimitedDealership} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.LimitedDealership.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCrmDealerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExternalDealerId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFeedId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDealershipLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.mintlist.adminapiv1.DealershipLocation.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryMake();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_url = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string crm_dealer_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getCrmDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setCrmDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string external_dealer_id = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getExternalDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setExternalDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string feed_id = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getFeedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setFeedId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional DealershipLocation dealership_location = 10;
 * @return {?proto.com.mintlist.adminapiv1.DealershipLocation}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getDealershipLocation = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealershipLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealershipLocation, 10));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealershipLocation|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
*/
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setDealershipLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.clearDealershipLocation = function() {
  return this.setDealershipLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.hasDealershipLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string primary_make = 11;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.getPrimaryMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.LimitedDealership} returns this
 */
proto.com.mintlist.adminapiv1.LimitedDealership.prototype.setPrimaryMake = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealershipLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealershipLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealershipLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 4, ""),
    telephoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealershipLocation;
  return proto.com.mintlist.adminapiv1.DealershipLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealershipLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealershipLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealershipLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealershipLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTelephoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation} returns this
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation} returns this
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation} returns this
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string postal = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation} returns this
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string telephone_number = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.getTelephoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation} returns this
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.setTelephoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fax_number = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealershipLocation} returns this
 */
proto.com.mintlist.adminapiv1.DealershipLocation.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest;
  return proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string vehicle_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  VEHICLE: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse;
  return proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Vehicle vehicle = 2;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateRegistrationAddressResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest;
  return proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  REGISTRATION_ADDRESS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registrationAddress: (f = msg.getRegistrationAddress()) && build_protos_entities_pb.Location.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse;
  return proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.Location;
      reader.readMessage(value,build_protos_entities_pb.Location.deserializeBinaryFromReader);
      msg.setRegistrationAddress(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRegistrationAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.Location registration_address = 2;
 * @return {?proto.entity.entities.Location}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.getRegistrationAddress = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Location, 2));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.setRegistrationAddress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.clearRegistrationAddress = function() {
  return this.setRegistrationAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.hasRegistrationAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetRegistrationAddressResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest;
  return proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest} returns this
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse;
  return proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteRegistrationAddressResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isPrimary: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    driverLicenseFrontUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    driverLicenseBackUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest;
  return proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrimary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverLicenseFrontUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverLicenseBackUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsPrimary();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDriverLicenseFrontUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDriverLicenseBackUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_primary = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.getIsPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.setIsPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string driver_license_front_url = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.getDriverLicenseFrontUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.setDriverLicenseFrontUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string driver_license_back_url = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.getDriverLicenseBackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerRequest.prototype.setDriverLicenseBackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  REGISTERED_OWNER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registeredOwner: (f = msg.getRegisteredOwner()) && build_protos_entities_pb.RegisteredOwner.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse;
  return proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.RegisteredOwner;
      reader.readMessage(value,build_protos_entities_pb.RegisteredOwner.deserializeBinaryFromReader);
      msg.setRegisteredOwner(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRegisteredOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.RegisteredOwner.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.RegisteredOwner registered_owner = 2;
 * @return {?proto.entity.entities.RegisteredOwner}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.getRegisteredOwner = function() {
  return /** @type{?proto.entity.entities.RegisteredOwner} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.RegisteredOwner, 2));
};


/**
 * @param {?proto.entity.entities.RegisteredOwner|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.setRegisteredOwner = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.clearRegisteredOwner = function() {
  return this.setRegisteredOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.hasRegisteredOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateRegisteredOwnerResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isPrimary: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    driverLicenseFrontUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    driverLicenseBackUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest;
  return proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrimary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverLicenseFrontUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverLicenseBackUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsPrimary();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDriverLicenseFrontUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDriverLicenseBackUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_primary = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.getIsPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.setIsPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string driver_license_front_url = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.getDriverLicenseFrontUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.setDriverLicenseFrontUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string driver_license_back_url = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.getDriverLicenseBackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerRequest.prototype.setDriverLicenseBackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  REGISTERED_OWNER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registeredOwner: (f = msg.getRegisteredOwner()) && build_protos_entities_pb.RegisteredOwner.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse;
  return proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.RegisteredOwner;
      reader.readMessage(value,build_protos_entities_pb.RegisteredOwner.deserializeBinaryFromReader);
      msg.setRegisteredOwner(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRegisteredOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.RegisteredOwner.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.RegisteredOwner registered_owner = 2;
 * @return {?proto.entity.entities.RegisteredOwner}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.getRegisteredOwner = function() {
  return /** @type{?proto.entity.entities.RegisteredOwner} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.RegisteredOwner, 2));
};


/**
 * @param {?proto.entity.entities.RegisteredOwner|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.setRegisteredOwner = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.clearRegisteredOwner = function() {
  return this.setRegisteredOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.hasRegisteredOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateRegisteredOwnerResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registeredOwnerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest;
  return proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegisteredOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegisteredOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string registered_owner_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.prototype.getRegisteredOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest} returns this
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerRequest.prototype.setRegisteredOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse;
  return proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteRegisteredOwnerResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest;
  return proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  REGISTERED_OWNERS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registeredOwners: (f = msg.getRegisteredOwners()) && build_protos_entities_pb.RegisteredOwners.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse;
  return proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.RegisteredOwners;
      reader.readMessage(value,build_protos_entities_pb.RegisteredOwners.deserializeBinaryFromReader);
      msg.setRegisteredOwners(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRegisteredOwners();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.RegisteredOwners.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.RegisteredOwners registered_owners = 2;
 * @return {?proto.entity.entities.RegisteredOwners}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.getRegisteredOwners = function() {
  return /** @type{?proto.entity.entities.RegisteredOwners} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.RegisteredOwners, 2));
};


/**
 * @param {?proto.entity.entities.RegisteredOwners|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.setRegisteredOwners = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.clearRegisteredOwners = function() {
  return this.setRegisteredOwners(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.hasRegisteredOwners = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListRegisteredOwnersResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    referenceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetOfferDocumentRequest;
  return proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string offer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reference_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentRequest.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER_DOCUMENT: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerDocument: (f = msg.getOfferDocument()) && build_protos_entities_pb.OfferDocument.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetOfferDocumentResponse;
  return proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.OfferDocument;
      reader.readMessage(value,build_protos_entities_pb.OfferDocument.deserializeBinaryFromReader);
      msg.setOfferDocument(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOfferDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.OfferDocument.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.OfferDocument offer_document = 2;
 * @return {?proto.entity.entities.OfferDocument}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.getOfferDocument = function() {
  return /** @type{?proto.entity.entities.OfferDocument} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.OfferDocument, 2));
};


/**
 * @param {?proto.entity.entities.OfferDocument|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.setOfferDocument = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.clearOfferDocument = function() {
  return this.setOfferDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.hasOfferDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetOfferDocumentResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SendOfferDocumentRequest;
  return proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string offer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 3;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER_DOCUMENT: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerDocument: (f = msg.getOfferDocument()) && build_protos_entities_pb.OfferDocument.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SendOfferDocumentResponse;
  return proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.OfferDocument;
      reader.readMessage(value,build_protos_entities_pb.OfferDocument.deserializeBinaryFromReader);
      msg.setOfferDocument(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOfferDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.OfferDocument.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.OfferDocument offer_document = 2;
 * @return {?proto.entity.entities.OfferDocument}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.getOfferDocument = function() {
  return /** @type{?proto.entity.entities.OfferDocument} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.OfferDocument, 2));
};


/**
 * @param {?proto.entity.entities.OfferDocument|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} returns this
*/
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.setOfferDocument = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.clearOfferDocument = function() {
  return this.setOfferDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.hasOfferDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} returns this
*/
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.SendOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.SendOfferDocumentResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerDocumentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offerDocumentStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest;
  return proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferDocumentId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.DocumentStatus} */ (reader.readEnum());
      msg.setOfferDocumentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfferDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string offer_document_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.prototype.getOfferDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.prototype.setOfferDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.DocumentStatus offer_document_status = 2;
 * @return {!proto.entity.enum.DocumentStatus}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.prototype.getOfferDocumentStatus = function() {
  return /** @type {!proto.entity.enum.DocumentStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.DocumentStatus} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentRequest.prototype.setOfferDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER_DOCUMENT: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerDocument: (f = msg.getOfferDocument()) && build_protos_entities_pb.OfferDocument.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse;
  return proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.OfferDocument;
      reader.readMessage(value,build_protos_entities_pb.OfferDocument.deserializeBinaryFromReader);
      msg.setOfferDocument(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOfferDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.OfferDocument.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.OfferDocument offer_document = 2;
 * @return {?proto.entity.entities.OfferDocument}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.getOfferDocument = function() {
  return /** @type{?proto.entity.entities.OfferDocument} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.OfferDocument, 2));
};


/**
 * @param {?proto.entity.entities.OfferDocument|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.setOfferDocument = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.clearOfferDocument = function() {
  return this.setOfferDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.hasOfferDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateOfferDocumentResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerDocumentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest;
  return proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string offer_document_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.prototype.getOfferDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest} returns this
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentRequest.prototype.setOfferDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse;
  return proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse} returns this
 */
proto.com.mintlist.adminapiv1.CancelOfferDocumentResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offerDocumentStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagination: (f = msg.getPagination()) && proto.com.mintlist.adminapiv1.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest;
  return proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.DocumentStatus} */ (reader.readEnum());
      msg.setOfferDocumentStatus(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.Pagination;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfferDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.DocumentStatus offer_document_status = 2;
 * @return {!proto.entity.enum.DocumentStatus}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.getOfferDocumentStatus = function() {
  return /** @type {!proto.entity.enum.DocumentStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.DocumentStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.setOfferDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.getPagination = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Pagination, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER_DOCUMENTS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerDocuments: (f = msg.getOfferDocuments()) && build_protos_entities_pb.OfferDocuments.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse;
  return proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rpc.code.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.OfferDocuments;
      reader.readMessage(value,build_protos_entities_pb.OfferDocuments.deserializeBinaryFromReader);
      msg.setOfferDocuments(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOfferDocuments();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.OfferDocuments.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional rpc.code.Code code = 1;
 * @return {!proto.rpc.code.Code}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.getCode = function() {
  return /** @type {!proto.rpc.code.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rpc.code.Code} value
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.entities.OfferDocuments offer_documents = 2;
 * @return {?proto.entity.entities.OfferDocuments}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.getOfferDocuments = function() {
  return /** @type{?proto.entity.entities.OfferDocuments} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.OfferDocuments, 2));
};


/**
 * @param {?proto.entity.entities.OfferDocuments|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.setOfferDocuments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.clearOfferDocuments = function() {
  return this.setOfferDocuments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.hasOfferDocuments = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListOfferDocumentsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest;
  return proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  AUTO_GROUP: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    autoGroup: (f = msg.getAutoGroup()) && build_protos_dealer_pb.AutoGroup.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse;
  return proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_dealer_pb.AutoGroup;
      reader.readMessage(value,build_protos_dealer_pb.AutoGroup.deserializeBinaryFromReader);
      msg.setAutoGroup(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAutoGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_dealer_pb.AutoGroup.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.dealer.AutoGroup auto_group = 2;
 * @return {?proto.entity.dealer.AutoGroup}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.getAutoGroup = function() {
  return /** @type{?proto.entity.dealer.AutoGroup} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.AutoGroup, 2));
};


/**
 * @param {?proto.entity.dealer.AutoGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.setAutoGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.clearAutoGroup = function() {
  return this.setAutoGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.hasAutoGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetAutoGroupByDealerUserIdResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && build_protos_salesentity_pb.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionRequest}
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateTransactionRequest;
  return proto.com.mintlist.adminapiv1.CreateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionRequest}
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_salesentity_pb.Transaction;
      reader.readMessage(value,build_protos_salesentity_pb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_salesentity_pb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.salesentity.Transaction transaction = 1;
 * @return {?proto.entity.salesentity.Transaction}
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.prototype.getTransaction = function() {
  return /** @type{?proto.entity.salesentity.Transaction} */ (
    jspb.Message.getWrapperField(this, build_protos_salesentity_pb.Transaction, 1));
};


/**
 * @param {?proto.entity.salesentity.Transaction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateTransactionRequest.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateTransactionRequest.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && build_protos_salesentity_pb.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionResponse}
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateTransactionResponse;
  return proto.com.mintlist.adminapiv1.CreateTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionResponse}
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_salesentity_pb.Transaction;
      reader.readMessage(value,build_protos_salesentity_pb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_salesentity_pb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.salesentity.Transaction transaction = 1;
 * @return {?proto.entity.salesentity.Transaction}
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.prototype.getTransaction = function() {
  return /** @type{?proto.entity.salesentity.Transaction} */ (
    jspb.Message.getWrapperField(this, build_protos_salesentity_pb.Transaction, 1));
};


/**
 * @param {?proto.entity.salesentity.Transaction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateTransactionResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateTransactionResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateTransactionResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerTransaction: (f = msg.getOfferTransaction()) && build_protos_salesentity_pb.OfferTransaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest;
  return proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_salesentity_pb.OfferTransaction;
      reader.readMessage(value,build_protos_salesentity_pb.OfferTransaction.deserializeBinaryFromReader);
      msg.setOfferTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_salesentity_pb.OfferTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.salesentity.OfferTransaction offer_transaction = 1;
 * @return {?proto.entity.salesentity.OfferTransaction}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.prototype.getOfferTransaction = function() {
  return /** @type{?proto.entity.salesentity.OfferTransaction} */ (
    jspb.Message.getWrapperField(this, build_protos_salesentity_pb.OfferTransaction, 1));
};


/**
 * @param {?proto.entity.salesentity.OfferTransaction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.prototype.setOfferTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.prototype.clearOfferTransaction = function() {
  return this.setOfferTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionRequest.prototype.hasOfferTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerTransaction: (f = msg.getOfferTransaction()) && build_protos_salesentity_pb.OfferTransaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse;
  return proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_salesentity_pb.OfferTransaction;
      reader.readMessage(value,build_protos_salesentity_pb.OfferTransaction.deserializeBinaryFromReader);
      msg.setOfferTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_salesentity_pb.OfferTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.salesentity.OfferTransaction offer_transaction = 1;
 * @return {?proto.entity.salesentity.OfferTransaction}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.prototype.getOfferTransaction = function() {
  return /** @type{?proto.entity.salesentity.OfferTransaction} */ (
    jspb.Message.getWrapperField(this, build_protos_salesentity_pb.OfferTransaction, 1));
};


/**
 * @param {?proto.entity.salesentity.OfferTransaction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.prototype.setOfferTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.prototype.clearOfferTransaction = function() {
  return this.setOfferTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateOfferTransactionResponse.prototype.hasOfferTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateBreakFeeRequest;
  return proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string offer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 2;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeRequest.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && build_protos_salesentity_pb.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateBreakFeeResponse;
  return proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_salesentity_pb.Transaction;
      reader.readMessage(value,build_protos_salesentity_pb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_salesentity_pb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.salesentity.Transaction transaction = 1;
 * @return {?proto.entity.salesentity.Transaction}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.prototype.getTransaction = function() {
  return /** @type{?proto.entity.salesentity.Transaction} */ (
    jspb.Message.getWrapperField(this, build_protos_salesentity_pb.Transaction, 1));
};


/**
 * @param {?proto.entity.salesentity.Transaction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateBreakFeeResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateBreakFeeResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest;
  return proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 3;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferRequest.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offer: (f = msg.getOffer()) && build_protos_entities_pb.Offer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse;
  return proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.Offer;
      reader.readMessage(value,build_protos_entities_pb.Offer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.Offer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.Offer offer = 1;
 * @return {?proto.entity.entities.Offer}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.prototype.getOffer = function() {
  return /** @type{?proto.entity.entities.Offer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Offer, 1));
};


/**
 * @param {?proto.entity.entities.Offer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.prototype.setOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetAcceptedOfferResponse.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    make: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListTradeInInfosRequest;
  return proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 page = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 page_size = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string make = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeInInfosList: jspb.Message.toObjectList(msg.getTradeInInfosList(),
    build_protos_mlapiv1entity_pb.TradeInInfo.toObject, includeInstance),
    totalNumberOfInfos: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListTradeInInfosResponse;
  return proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_mlapiv1entity_pb.TradeInInfo;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.TradeInInfo.deserializeBinaryFromReader);
      msg.addTradeInInfos(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalNumberOfInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeInInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_mlapiv1entity_pb.TradeInInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalNumberOfInfos();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated entity.mlapiv1entity.TradeInInfo trade_in_infos = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.TradeInInfo>}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.getTradeInInfosList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.TradeInInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_mlapiv1entity_pb.TradeInInfo, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.TradeInInfo>} value
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.setTradeInInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.TradeInInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.TradeInInfo}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.addTradeInInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.TradeInInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.clearTradeInInfosList = function() {
  return this.setTradeInInfosList([]);
};


/**
 * optional int64 total_number_of_infos = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.getTotalNumberOfInfos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListTradeInInfosResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListTradeInInfosResponse.prototype.setTotalNumberOfInfos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isLeadContact: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isResponsibleMultiStores: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    registrationNumber: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateDealerAccountRequest;
  return proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 7:
      var value = /** @type {!proto.com.mintlist.adminapiv1.UserRole} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLeadContact(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResponsibleMultiStores(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsLeadContact();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsResponsibleMultiStores();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getRegistrationNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string family_name = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string given_name = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dealer_id = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UserRole user_role = 7;
 * @return {!proto.com.mintlist.adminapiv1.UserRole}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getUserRole = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.UserRole} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.UserRole} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_lead_contact = 8;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getIsLeadContact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setIsLeadContact = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_responsible_multi_stores = 9;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getIsResponsibleMultiStores = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setIsResponsibleMultiStores = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string registration_number = 10;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.getRegistrationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountRequest.prototype.setRegistrationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealer: (f = msg.getDealer()) && proto.com.mintlist.adminapiv1.DealerAccount.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateDealerAccountResponse;
  return proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DealerAccount;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealerAccount.deserializeBinaryFromReader);
      msg.setDealer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DealerAccount.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealerAccount dealer = 2;
 * @return {?proto.com.mintlist.adminapiv1.DealerAccount}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.getDealer = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealerAccount} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealerAccount, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealerAccount|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.setDealer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.clearDealer = function() {
  return this.setDealer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.hasDealer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealerAccountResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealerAccountResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    familyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isFeeAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isResponsibleMultiStores: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isLeadContact: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    email: jspb.Message.getFieldWithDefault(msg, 8, ""),
    registrationNumber: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest;
  return proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFeeAccepted(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResponsibleMultiStores(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLeadContact(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsFeeAccepted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsResponsibleMultiStores();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsLeadContact();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRegistrationNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string family_name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string given_name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_fee_accepted = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getIsFeeAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setIsFeeAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_responsible_multi_stores = 5;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getIsResponsibleMultiStores = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setIsResponsibleMultiStores = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_lead_contact = 6;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getIsLeadContact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setIsLeadContact = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string registration_number = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.getRegistrationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountRequest.prototype.setRegistrationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealer: (f = msg.getDealer()) && proto.com.mintlist.adminapiv1.DealerAccount.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse;
  return proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DealerAccount;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealerAccount.deserializeBinaryFromReader);
      msg.setDealer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DealerAccount.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealerAccount dealer = 2;
 * @return {?proto.com.mintlist.adminapiv1.DealerAccount}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.getDealer = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealerAccount} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealerAccount, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealerAccount|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.setDealer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.clearDealer = function() {
  return this.setDealer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.hasDealer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealerAccountResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isFeeAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    lastSignIn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dealershipName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isLeadContact: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isTermsAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    termsAcceptedTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isResponsibleMultiStores: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    phone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 15, 0),
    registrationNumber: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo}
 */
proto.com.mintlist.adminapiv1.DealerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealerInfo;
  return proto.com.mintlist.adminapiv1.DealerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo}
 */
proto.com.mintlist.adminapiv1.DealerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFeeAccepted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSignIn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipName(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLeadContact(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTermsAccepted(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermsAcceptedTime(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResponsibleMultiStores(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 15:
      var value = /** @type {!proto.com.mintlist.adminapiv1.UserRole} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsFeeAccepted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLastSignIn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDealershipName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsLeadContact();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsTermsAccepted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTermsAcceptedTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIsResponsibleMultiStores();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getRegistrationNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_fee_accepted = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getIsFeeAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setIsFeeAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string last_sign_in = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getLastSignIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setLastSignIn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dealership_id = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dealership_name = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getDealershipName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setDealershipName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_lead_contact = 8;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getIsLeadContact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setIsLeadContact = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_terms_accepted = 9;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getIsTermsAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setIsTermsAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 terms_accepted_time = 10;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getTermsAcceptedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setTermsAcceptedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_responsible_multi_stores = 11;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getIsResponsibleMultiStores = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setIsResponsibleMultiStores = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string family_name = 13;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string given_name = 14;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional UserRole user_role = 15;
 * @return {!proto.com.mintlist.adminapiv1.UserRole}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getUserRole = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.UserRole} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.UserRole} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string registration_number = 16;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.getRegistrationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealerInfo.prototype.setRegistrationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.DealersInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealersInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealersInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealersInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealersInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealersList: jspb.Message.toObjectList(msg.getDealersList(),
    proto.com.mintlist.adminapiv1.DealerInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealersInfo}
 */
proto.com.mintlist.adminapiv1.DealersInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealersInfo;
  return proto.com.mintlist.adminapiv1.DealersInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealersInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealersInfo}
 */
proto.com.mintlist.adminapiv1.DealersInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.DealerInfo;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealerInfo.deserializeBinaryFromReader);
      msg.addDealers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealersInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealersInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealersInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealersInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.DealerInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DealerInfo dealers = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.DealerInfo>}
 */
proto.com.mintlist.adminapiv1.DealersInfo.prototype.getDealersList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.DealerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.DealerInfo, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.DealerInfo>} value
 * @return {!proto.com.mintlist.adminapiv1.DealersInfo} returns this
*/
proto.com.mintlist.adminapiv1.DealersInfo.prototype.setDealersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.DealerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.DealerInfo}
 */
proto.com.mintlist.adminapiv1.DealersInfo.prototype.addDealers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.DealerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.DealersInfo} returns this
 */
proto.com.mintlist.adminapiv1.DealersInfo.prototype.clearDealersList = function() {
  return this.setDealersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealerSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealerSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealerSearchRequest}
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealerSearchRequest;
  return proto.com.mintlist.adminapiv1.DealerSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealerSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealerSearchRequest}
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealerSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealerSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealerSearchRequest} returns this
 */
proto.com.mintlist.adminapiv1.DealerSearchRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerSearchRequest: (f = msg.getDealerSearchRequest()) && proto.com.mintlist.adminapiv1.DealerSearchRequest.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.com.mintlist.adminapiv1.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && build_protos_searchentity_pb.DealerUserFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealersRequest;
  return proto.com.mintlist.adminapiv1.ListDealersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.DealerSearchRequest;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealerSearchRequest.deserializeBinaryFromReader);
      msg.setDealerSearchRequest(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Pagination;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new build_protos_searchentity_pb.DealerUserFilter;
      reader.readMessage(value,build_protos_searchentity_pb.DealerUserFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerSearchRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.DealerSearchRequest.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      build_protos_searchentity_pb.DealerUserFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional DealerSearchRequest dealer_search_request = 1;
 * @return {?proto.com.mintlist.adminapiv1.DealerSearchRequest}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.getDealerSearchRequest = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealerSearchRequest} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealerSearchRequest, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealerSearchRequest|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.setDealerSearchRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.clearDealerSearchRequest = function() {
  return this.setDealerSearchRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.hasDealerSearchRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.getPagination = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Pagination, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional entity.searchentity.DealerUserFilter filter = 3;
 * @return {?proto.entity.searchentity.DealerUserFilter}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.getFilter = function() {
  return /** @type{?proto.entity.searchentity.DealerUserFilter} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.DealerUserFilter, 3));
};


/**
 * @param {?proto.entity.searchentity.DealerUserFilter|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALERS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListDealersResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListDealersResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListDealersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealers: (f = msg.getDealers()) && proto.com.mintlist.adminapiv1.DealersInfo.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealersResponse;
  return proto.com.mintlist.adminapiv1.ListDealersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DealersInfo;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealersInfo.deserializeBinaryFromReader);
      msg.setDealers(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DealersInfo.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealersInfo dealers = 2;
 * @return {?proto.com.mintlist.adminapiv1.DealersInfo}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.getDealers = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealersInfo} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealersInfo, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealersInfo|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.setDealers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListDealersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.clearDealers = function() {
  return this.setDealers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.hasDealers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListDealersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 total = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealersResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealer: (f = msg.getDealer()) && build_protos_dealer_pb.Dealer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipRequest}
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateDealershipRequest;
  return proto.com.mintlist.adminapiv1.CreateDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipRequest}
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.Dealer;
      reader.readMessage(value,build_protos_dealer_pb.Dealer.deserializeBinaryFromReader);
      msg.setDealer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealer_pb.Dealer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealer.Dealer dealer = 1;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.prototype.getDealer = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealer, 1));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealershipRequest.prototype.setDealer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.prototype.clearDealer = function() {
  return this.setDealer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealershipRequest.prototype.hasDealer = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateDealershipResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateDealershipResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateDealershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealer: (f = msg.getDealer()) && build_protos_dealer_pb.Dealer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateDealershipResponse;
  return proto.com.mintlist.adminapiv1.CreateDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_dealer_pb.Dealer;
      reader.readMessage(value,build_protos_dealer_pb.Dealer.deserializeBinaryFromReader);
      msg.setDealer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_dealer_pb.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.dealer.Dealer dealer = 2;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.getDealer = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealer, 2));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.setDealer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateDealershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.clearDealer = function() {
  return this.setDealer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.hasDealer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateDealershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealershipResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipRequest}
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealershipRequest;
  return proto.com.mintlist.adminapiv1.GetDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipRequest}
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetDealershipResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetDealershipResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetDealershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealer: (f = msg.getDealer()) && build_protos_dealer_pb.Dealer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealershipResponse;
  return proto.com.mintlist.adminapiv1.GetDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_dealer_pb.Dealer;
      reader.readMessage(value,build_protos_dealer_pb.Dealer.deserializeBinaryFromReader);
      msg.setDealer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_dealer_pb.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.dealer.Dealer dealer = 2;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.getDealer = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealer, 2));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.setDealer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetDealershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.clearDealer = function() {
  return this.setDealer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.hasDealer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetDealershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetDealershipResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerAcceptedDateStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerAcceptedDateEnd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest;
  return proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferAcceptedDateStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferAcceptedDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferAcceptedDateStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOfferAcceptedDateEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 offer_accepted_date_start = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.prototype.getOfferAcceptedDateStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.prototype.setOfferAcceptedDateStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 offer_accepted_date_end = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.prototype.getOfferAcceptedDateEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersRequest.prototype.setOfferAcceptedDateEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEAL_REPORT_DETAILS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealReportDetails: (f = msg.getDealReportDetails()) && proto.com.mintlist.adminapiv1.DealReportDetails.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse;
  return proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DealReportDetails;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealReportDetails.deserializeBinaryFromReader);
      msg.setDealReportDetails(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealReportDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DealReportDetails.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealReportDetails deal_report_details = 2;
 * @return {?proto.com.mintlist.adminapiv1.DealReportDetails}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.getDealReportDetails = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealReportDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealReportDetails, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealReportDetails|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.setDealReportDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.clearDealReportDetails = function() {
  return this.setDealReportDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.hasDealReportDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListAcceptedOffersResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest;
  return proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offer: (f = msg.getOffer()) && build_protos_entities_pb.Offer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse;
  return proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.Offer;
      reader.readMessage(value,build_protos_entities_pb.Offer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.Offer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.entities.Offer offer = 2;
 * @return {?proto.entity.entities.Offer}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.getOffer = function() {
  return /** @type{?proto.entity.entities.Offer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Offer, 2));
};


/**
 * @param {?proto.entity.entities.Offer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.setOffer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ExtendOfferForVehicleResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buyerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest;
  return proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional double price = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Currency currency = 2;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string buyer_id = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 5;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string user_name = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dealer_name = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleRequest.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offer: (f = msg.getOffer()) && build_protos_entities_pb.Offer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse;
  return proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_entities_pb.Offer;
      reader.readMessage(value,build_protos_entities_pb.Offer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_entities_pb.Offer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.entities.Offer offer = 2;
 * @return {?proto.entity.entities.Offer}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.getOffer = function() {
  return /** @type{?proto.entity.entities.Offer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Offer, 2));
};


/**
 * @param {?proto.entity.entities.Offer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.setOffer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateOfferForVehicleResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buyerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    wayOfSale: jspb.Message.getFieldWithDefault(msg, 9, 0),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 10, 0),
    offerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    sellerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    sellerOrigin: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest;
  return proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.enum.WayOfSale} */ (reader.readEnum());
      msg.setWayOfSale(value);
      break;
    case 10:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellerId(value);
      break;
    case 13:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setSellerOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWayOfSale();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSellerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSellerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional double price = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Currency currency = 2;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string buyer_id = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 5;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 effective_date = 6;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dealer_name = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional entity.enum.WayOfSale way_of_sale = 9;
 * @return {!proto.entity.enum.WayOfSale}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getWayOfSale = function() {
  return /** @type {!proto.entity.enum.WayOfSale} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.enum.WayOfSale} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setWayOfSale = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 10;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string offer_id = 11;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string seller_id = 12;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getSellerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setSellerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional entity.enum.UserOrigin seller_origin = 13;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.getSellerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleRequest.prototype.setSellerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  SUCCESS_RESPONSE: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    successResponse: (f = msg.getSuccessResponse()) && proto.com.mintlist.adminapiv1.SuccessResponse.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse;
  return proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.SuccessResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.SuccessResponse.deserializeBinaryFromReader);
      msg.setSuccessResponse(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSuccessResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.SuccessResponse.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SuccessResponse success_response = 2;
 * @return {?proto.com.mintlist.adminapiv1.SuccessResponse}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.getSuccessResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.SuccessResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.SuccessResponse, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.SuccessResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.setSuccessResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.clearSuccessResponse = function() {
  return this.setSuccessResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.hasSuccessResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateCompletedSaleResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerAcceptedDateStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerAcceptedDateEnd: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListCompletedSalesRequest;
  return proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferAcceptedDateStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfferAcceptedDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferAcceptedDateStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOfferAcceptedDateEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 offer_accepted_date_start = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.prototype.getOfferAcceptedDateStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.prototype.setOfferAcceptedDateStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 offer_accepted_date_end = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.prototype.getOfferAcceptedDateEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesRequest.prototype.setOfferAcceptedDateEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEAL_REPORT_DETAILS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealReportDetails: (f = msg.getDealReportDetails()) && proto.com.mintlist.adminapiv1.DealReportDetails.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListCompletedSalesResponse;
  return proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DealReportDetails;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealReportDetails.deserializeBinaryFromReader);
      msg.setDealReportDetails(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealReportDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DealReportDetails.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DealReportDetails deal_report_details = 2;
 * @return {?proto.com.mintlist.adminapiv1.DealReportDetails}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.getDealReportDetails = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DealReportDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DealReportDetails, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DealReportDetails|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.setDealReportDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.clearDealReportDetails = function() {
  return this.setDealReportDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.hasDealReportDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListCompletedSalesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListCompletedSalesResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateDealershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && build_protos_dealer_pb.Dealer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateDealershipRequest;
  return proto.com.mintlist.adminapiv1.UpdateDealershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.Dealer;
      reader.readMessage(value,build_protos_dealer_pb.Dealer.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateDealershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealer_pb.Dealer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealer.Dealer dealership = 1;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.prototype.getDealership = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealer, 1));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest} returns this
*/
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipRequest.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALERSHIP: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateDealershipResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateDealershipResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateDealershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateDealershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealership: (f = msg.getDealership()) && build_protos_dealer_pb.Dealer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateDealershipResponse;
  return proto.com.mintlist.adminapiv1.UpdateDealershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_dealer_pb.Dealer;
      reader.readMessage(value,build_protos_dealer_pb.Dealer.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateDealershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_dealer_pb.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.dealer.Dealer dealership = 2;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.getDealership = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealer, 2));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.setDealership = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateDealershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateDealershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateDealershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateDealershipResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    highestOfferPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contractId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateOfferRequest;
  return proto.com.mintlist.adminapiv1.UpdateOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestOfferPrice(value);
      break;
    case 2:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHighestOfferPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContractId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional double highest_offer_price = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.getHighestOfferPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.setHighestOfferPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Currency currency = 2;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string buyer_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string effective_date = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.getEffectiveDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contract_id = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.getContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferRequest.prototype.setContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFER: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateOfferResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateOfferResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateOfferResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offer: (f = msg.getOffer()) && proto.com.mintlist.adminapiv1.Offer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateOfferResponse;
  return proto.com.mintlist.adminapiv1.UpdateOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Offer;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Offer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Offer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Offer offer = 2;
 * @return {?proto.com.mintlist.adminapiv1.Offer}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.getOffer = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Offer} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Offer, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Offer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.setOffer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateOfferResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateOfferResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateOfferResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest;
  return proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DEALERSHIP: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealership: (f = msg.getDealership()) && build_protos_dealer_pb.Dealer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse;
  return proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new build_protos_dealer_pb.Dealer;
      reader.readMessage(value,build_protos_dealer_pb.Dealer.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_dealer_pb.Dealer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional entity.dealer.Dealer dealership = 2;
 * @return {?proto.entity.dealer.Dealer}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.getDealership = function() {
  return /** @type{?proto.entity.dealer.Dealer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.Dealer, 2));
};


/**
 * @param {?proto.entity.dealer.Dealer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.setDealership = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetDealershipFromDealerIdResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest;
  return proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 3:
      var value = /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string reference_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auction_group_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OfferStatus offer_status = 3;
 * @return {!proto.com.mintlist.adminapiv1.OfferStatus}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.getOfferStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional AuctionStatus auction_status = 4;
 * @return {!proto.com.mintlist.adminapiv1.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.getAuctionStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetRequest.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  AUCTION_INFO: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    auctionInfo: (f = msg.getAuctionInfo()) && proto.com.mintlist.adminapiv1.AuctionInfo.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse;
  return proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.AuctionInfo;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionInfo.deserializeBinaryFromReader);
      msg.setAuctionInfo(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuctionInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.AuctionInfo.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AuctionInfo auction_info = 2;
 * @return {?proto.com.mintlist.adminapiv1.AuctionInfo}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.getAuctionInfo = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.AuctionInfo} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.AuctionInfo, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.AuctionInfo|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.setAuctionInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.clearAuctionInfo = function() {
  return this.setAuctionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.hasAuctionInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateAuctionGroupAssetResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DashboardDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DashboardDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DashboardDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardDocumentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocument}
 */
proto.com.mintlist.adminapiv1.DashboardDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DashboardDocument;
  return proto.com.mintlist.adminapiv1.DashboardDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocument}
 */
proto.com.mintlist.adminapiv1.DashboardDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.enum.DashboardDocumentType} */ (reader.readEnum());
      msg.setDashboardDocumentType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DashboardDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DashboardDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DashboardDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional entity.enum.DashboardDocumentType dashboard_document_type = 1;
 * @return {!proto.entity.enum.DashboardDocumentType}
 */
proto.com.mintlist.adminapiv1.DashboardDocument.prototype.getDashboardDocumentType = function() {
  return /** @type {!proto.entity.enum.DashboardDocumentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.enum.DashboardDocumentType} value
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocument} returns this
 */
proto.com.mintlist.adminapiv1.DashboardDocument.prototype.setDashboardDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DashboardDocument.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocument} returns this
 */
proto.com.mintlist.adminapiv1.DashboardDocument.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DashboardDocuments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocuments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardDocumentList: jspb.Message.toObjectList(msg.getDashboardDocumentList(),
    proto.com.mintlist.adminapiv1.DashboardDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocuments}
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DashboardDocuments;
  return proto.com.mintlist.adminapiv1.DashboardDocuments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocuments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocuments}
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.DashboardDocument;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DashboardDocument.deserializeBinaryFromReader);
      msg.addDashboardDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DashboardDocuments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocuments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardDocumentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.DashboardDocument.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardDocument dashboard_document = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.DashboardDocument>}
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.prototype.getDashboardDocumentList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.DashboardDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.DashboardDocument, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.DashboardDocument>} value
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocuments} returns this
*/
proto.com.mintlist.adminapiv1.DashboardDocuments.prototype.setDashboardDocumentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.DashboardDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocument}
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.prototype.addDashboardDocument = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.DashboardDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.DashboardDocuments} returns this
 */
proto.com.mintlist.adminapiv1.DashboardDocuments.prototype.clearDashboardDocumentList = function() {
  return this.setDashboardDocumentList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest;
  return proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  DASHBOARD_DOCUMENTS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dashboardDocuments: (f = msg.getDashboardDocuments()) && proto.com.mintlist.adminapiv1.DashboardDocuments.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse;
  return proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.DashboardDocuments;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DashboardDocuments.deserializeBinaryFromReader);
      msg.setDashboardDocuments(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDashboardDocuments();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.DashboardDocuments.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DashboardDocuments dashboard_documents = 2;
 * @return {?proto.com.mintlist.adminapiv1.DashboardDocuments}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.getDashboardDocuments = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.DashboardDocuments} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.DashboardDocuments, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.DashboardDocuments|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.setDashboardDocuments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.clearDashboardDocuments = function() {
  return this.setDashboardDocuments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.hasDashboardDocuments = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDashboardDocumentsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest;
  return proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ConsumerSignup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userPhone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ConsumerSignup;
  return proto.com.mintlist.adminapiv1.ConsumerSignup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedDate(value);
      break;
    case 5:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ConsumerSignup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_email = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_phone = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.getUserPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.setUserPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created_date = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.getCreatedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.setCreatedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Vehicle vehicle = 5;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 5));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup} returns this
*/
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ConsumerSignup.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ConsumerSignups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.toObject = function(includeInstance, msg) {
  var f, obj = {
    consumerSignupsList: jspb.Message.toObjectList(msg.getConsumerSignupsList(),
    proto.com.mintlist.adminapiv1.ConsumerSignup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignups}
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ConsumerSignups;
  return proto.com.mintlist.adminapiv1.ConsumerSignups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignups}
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.ConsumerSignup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ConsumerSignup.deserializeBinaryFromReader);
      msg.addConsumerSignups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ConsumerSignups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsumerSignupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.ConsumerSignup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConsumerSignup consumer_signups = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.ConsumerSignup>}
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.prototype.getConsumerSignupsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.ConsumerSignup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.ConsumerSignup, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.ConsumerSignup>} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignups} returns this
*/
proto.com.mintlist.adminapiv1.ConsumerSignups.prototype.setConsumerSignupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.ConsumerSignup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignup}
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.prototype.addConsumerSignups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.ConsumerSignup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerSignups} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerSignups.prototype.clearConsumerSignupsList = function() {
  return this.setConsumerSignupsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  CONSUMER_SIGNUPS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    consumerSignups: (f = msg.getConsumerSignups()) && proto.com.mintlist.adminapiv1.ConsumerSignups.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse;
  return proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.ConsumerSignups;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ConsumerSignups.deserializeBinaryFromReader);
      msg.setConsumerSignups(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getConsumerSignups();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.ConsumerSignups.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ConsumerSignups consumer_signups = 2;
 * @return {?proto.com.mintlist.adminapiv1.ConsumerSignups}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.getConsumerSignups = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ConsumerSignups} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ConsumerSignups, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ConsumerSignups|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.setConsumerSignups = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.clearConsumerSignups = function() {
  return this.setConsumerSignups(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.hasConsumerSignups = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListConsumerSignupsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateVehiclesRequest;
  return proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    case 2:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (reader.readEnum());
      msg.setVehicleStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getVehicleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string vehicle_ids = 1;
 * @return {!Array<string>}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};


/**
 * optional VehicleStatus vehicle_status = 2;
 * @return {!proto.com.mintlist.adminapiv1.VehicleStatus}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.getVehicleStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleStatus} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesRequest.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateVehiclesResponse;
  return proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateVehiclesResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionGroupAssetsList: jspb.Message.toObjectList(msg.getAuctionGroupAssetsList(),
    proto.com.mintlist.adminapiv1.AuctionGroupAsset.toObject, includeInstance),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    antiSnipingTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest;
  return proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroupAsset;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroupAsset.deserializeBinaryFromReader);
      msg.addAuctionGroupAssets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAntiSnipingTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionGroupAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroupAsset.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAntiSnipingTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated AuctionGroupAsset auction_group_assets = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AuctionGroupAsset>}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.getAuctionGroupAssetsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AuctionGroupAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroupAsset, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AuctionGroupAsset>} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.setAuctionGroupAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroupAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.addAuctionGroupAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.AuctionGroupAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.clearAuctionGroupAssetsList = function() {
  return this.setAuctionGroupAssetsList([]);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 anti_sniping_time = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.getAntiSnipingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleRequest.prototype.setAntiSnipingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse;
  return proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionScheduleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionGroupAssetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetReferenceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    antiSnipingTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    scheduledJobType: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateScheduledJobRequest;
  return proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetReferenceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAntiSnipingTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 10:
      var value = /** @type {!proto.com.mintlist.adminapiv1.ScheduledJobType} */ (reader.readEnum());
      msg.setScheduledJobType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionGroupAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetReferenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAntiSnipingTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getScheduledJobType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string auction_group_asset_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getAuctionGroupAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setAuctionGroupAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_reference_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getAssetReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setAssetReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string user_given_name = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_phone_number = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 anti_sniping_time = 8;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getAntiSnipingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setAntiSnipingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string auction_group_id = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional ScheduledJobType scheduled_job_type = 10;
 * @return {!proto.com.mintlist.adminapiv1.ScheduledJobType}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.getScheduledJobType = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.ScheduledJobType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.ScheduledJobType} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobRequest.prototype.setScheduledJobType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateScheduledJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobResponse}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateScheduledJobResponse;
  return proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateScheduledJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobResponse}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateScheduledJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateScheduledJobResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateScheduledJobResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetVersionRequest}
 */
proto.com.mintlist.adminapiv1.GetVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetVersionRequest;
  return proto.com.mintlist.adminapiv1.GetVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetVersionRequest}
 */
proto.com.mintlist.adminapiv1.GetVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AuctionFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AuctionFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AuctionFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AuctionFilter}
 */
proto.com.mintlist.adminapiv1.AuctionFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AuctionFilter;
  return proto.com.mintlist.adminapiv1.AuctionFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AuctionFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AuctionFilter}
 */
proto.com.mintlist.adminapiv1.AuctionFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AuctionFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AuctionFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AuctionFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AuctionStatus auction_status = 1;
 * @return {!proto.com.mintlist.adminapiv1.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.AuctionFilter.prototype.getAuctionStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionFilter} returns this
 */
proto.com.mintlist.adminapiv1.AuctionFilter.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateAuctionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    antiSnipingTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    auctionTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    region: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subRegion: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateAuctionRequest;
  return proto.com.mintlist.adminapiv1.CreateAuctionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAntiSnipingTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateAuctionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAntiSnipingTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAuctionTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubRegion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 anti_sniping_time = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.getAntiSnipingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.setAntiSnipingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string auction_title = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.getAuctionTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.setAuctionTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string region = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sub_region = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.getSubRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionRequest.prototype.setSubRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  AUCTION: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.CreateAuctionResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.CreateAuctionResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.CreateAuctionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateAuctionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    auction: (f = msg.getAuction()) && proto.com.mintlist.adminapiv1.Auction.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateAuctionResponse;
  return proto.com.mintlist.adminapiv1.CreateAuctionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Auction;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Auction.deserializeBinaryFromReader);
      msg.setAuction(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateAuctionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Auction.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Auction auction = 2;
 * @return {?proto.com.mintlist.adminapiv1.Auction}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.getAuction = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Auction} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Auction, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Auction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.setAuction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.CreateAuctionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.clearAuction = function() {
  return this.setAuction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.hasAuction = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.CreateAuctionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateAuctionResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateAuctionResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAuctionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsRequest}
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAuctionsRequest;
  return proto.com.mintlist.adminapiv1.ListAuctionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsRequest}
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAuctionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  AUCTIONS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListAuctionsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListAuctionsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListAuctionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAuctionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    auctions: (f = msg.getAuctions()) && proto.com.mintlist.adminapiv1.Auctions.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAuctionsResponse;
  return proto.com.mintlist.adminapiv1.ListAuctionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Auctions;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Auctions.deserializeBinaryFromReader);
      msg.setAuctions(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAuctionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAuctions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Auctions.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Auctions auctions = 2;
 * @return {?proto.com.mintlist.adminapiv1.Auctions}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.getAuctions = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Auctions} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Auctions, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Auctions|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.setAuctions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListAuctionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.clearAuctions = function() {
  return this.setAuctions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.hasAuctions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListAuctionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListAuctionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListAuctionsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AuctionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auctionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vehicles: (f = msg.getVehicles()) && proto.com.mintlist.adminapiv1.Vehicles.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AuctionGroup;
  return proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = new proto.com.mintlist.adminapiv1.Vehicles;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auction_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Vehicles vehicles = 5;
 * @return {?proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.getVehicles = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicles, 5));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicles|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup} returns this
*/
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AuctionGroup.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.AuctionInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AuctionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AuctionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.com.mintlist.adminapiv1.Bid.toObject, includeInstance),
    auctionGroup: (f = msg.getAuctionGroup()) && proto.com.mintlist.adminapiv1.AuctionGroup.toObject(includeInstance, f),
    auction: (f = msg.getAuction()) && proto.com.mintlist.adminapiv1.Auction.toObject(includeInstance, f),
    assetReferenceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    asset: (f = msg.getAsset()) && proto.com.mintlist.adminapiv1.Asset.toObject(includeInstance, f),
    userBid: (f = msg.getUserBid()) && proto.com.mintlist.adminapiv1.Bid.toObject(includeInstance, f),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AuctionInfo;
  return proto.com.mintlist.adminapiv1.AuctionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AuctionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Bid;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader);
      msg.setAuctionGroup(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.Auction;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Auction.deserializeBinaryFromReader);
      msg.setAuction(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetReferenceId(value);
      break;
    case 5:
      var value = /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 6:
      var value = new proto.com.mintlist.adminapiv1.Asset;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    case 7:
      var value = new proto.com.mintlist.adminapiv1.Bid;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader);
      msg.setUserBid(value);
      break;
    case 8:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AuctionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AuctionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getAuction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.Auction.serializeBinaryToWriter
    );
  }
  f = message.getAssetReferenceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.mintlist.adminapiv1.Asset.serializeBinaryToWriter
    );
  }
  f = message.getUserBid();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * repeated Bid bids = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Bid>}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Bid, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Bid>} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
*/
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional AuctionGroup auction_group = 2;
 * @return {?proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getAuctionGroup = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.AuctionGroup} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroup, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.AuctionGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
*/
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setAuctionGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.clearAuctionGroup = function() {
  return this.setAuctionGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.hasAuctionGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Auction auction = 3;
 * @return {?proto.com.mintlist.adminapiv1.Auction}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getAuction = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Auction} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Auction, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Auction|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
*/
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setAuction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.clearAuction = function() {
  return this.setAuction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.hasAuction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string asset_reference_id = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getAssetReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setAssetReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional OfferStatus offer_status = 5;
 * @return {!proto.com.mintlist.adminapiv1.OfferStatus}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getOfferStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Asset asset = 6;
 * @return {?proto.com.mintlist.adminapiv1.Asset}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getAsset = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Asset} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Asset, 6));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Asset|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
*/
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Bid user_bid = 7;
 * @return {?proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getUserBid = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Bid} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Bid, 7));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Bid|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
*/
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setUserBid = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.clearUserBid = function() {
  return this.setUserBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.hasUserBid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AuctionStatus auction_status = 8;
 * @return {!proto.com.mintlist.adminapiv1.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.getAuctionStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionInfo} returns this
 */
proto.com.mintlist.adminapiv1.AuctionInfo.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Auction.repeatedFields_ = [4,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Auction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Auction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Auction.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.com.mintlist.adminapiv1.AuctionGroup.toObject, includeInstance),
    antiSnipingTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    auctionClass: jspb.Message.getFieldWithDefault(msg, 6, 0),
    auctionTitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timeStamp: (f = msg.getTimeStamp()) && proto.com.mintlist.adminapiv1.Timestamp.toObject(includeInstance, f),
    numberOfVehicles: jspb.Message.getFieldWithDefault(msg, 9, 0),
    auctionGroupAssetsList: jspb.Message.toObjectList(msg.getAuctionGroupAssetsList(),
    proto.com.mintlist.adminapiv1.AuctionGroupAsset.toObject, includeInstance),
    region: jspb.Message.getFieldWithDefault(msg, 11, ""),
    subRegion: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Auction}
 */
proto.com.mintlist.adminapiv1.Auction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Auction;
  return proto.com.mintlist.adminapiv1.Auction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Auction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Auction}
 */
proto.com.mintlist.adminapiv1.Auction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAntiSnipingTime(value);
      break;
    case 6:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionClass} */ (reader.readEnum());
      msg.setAuctionClass(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionTitle(value);
      break;
    case 8:
      var value = new proto.com.mintlist.adminapiv1.Timestamp;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfVehicles(value);
      break;
    case 10:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroupAsset;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroupAsset.deserializeBinaryFromReader);
      msg.addAuctionGroupAssets(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Auction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Auction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Auction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getAntiSnipingTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAuctionClass();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAuctionTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfVehicles();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAuctionGroupAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroupAsset.serializeBinaryToWriter
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSubRegion();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string auction_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated AuctionGroup auction_groups = 4;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroup, 4));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
*/
proto.com.mintlist.adminapiv1.Auction.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.mintlist.adminapiv1.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional int64 anti_sniping_time = 5;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getAntiSnipingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setAntiSnipingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional AuctionClass auction_class = 6;
 * @return {!proto.com.mintlist.adminapiv1.AuctionClass}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getAuctionClass = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionClass} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionClass} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setAuctionClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string auction_title = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getAuctionTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setAuctionTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Timestamp time_stamp = 8;
 * @return {?proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getTimeStamp = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Timestamp, 8));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Timestamp|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
*/
proto.com.mintlist.adminapiv1.Auction.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 number_of_vehicles = 9;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getNumberOfVehicles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setNumberOfVehicles = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated AuctionGroupAsset auction_group_assets = 10;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AuctionGroupAsset>}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getAuctionGroupAssetsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AuctionGroupAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroupAsset, 10));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AuctionGroupAsset>} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
*/
proto.com.mintlist.adminapiv1.Auction.prototype.setAuctionGroupAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroupAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.addAuctionGroupAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.mintlist.adminapiv1.AuctionGroupAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.clearAuctionGroupAssetsList = function() {
  return this.setAuctionGroupAssetsList([]);
};


/**
 * optional string region = 11;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string sub_region = 12;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Auction.prototype.getSubRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Auction} returns this
 */
proto.com.mintlist.adminapiv1.Auction.prototype.setSubRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Offer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Offer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Offer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    counterOfferPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    counterOfferCurrency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    highestOfferPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    highestOfferCurrency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    auctionGroupAssetId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    contractId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Offer}
 */
proto.com.mintlist.adminapiv1.Offer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Offer;
  return proto.com.mintlist.adminapiv1.Offer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Offer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Offer}
 */
proto.com.mintlist.adminapiv1.Offer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCounterOfferPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCounterOfferCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHighestOfferPrice(value);
      break;
    case 5:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setHighestOfferCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 7:
      var value = /** @type {!proto.com.mintlist.adminapiv1.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupAssetId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Offer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Offer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Offer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCounterOfferPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCounterOfferCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getHighestOfferPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getHighestOfferCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAuctionGroupAssetId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getContractId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double counter_offer_price = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getCounterOfferPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setCounterOfferPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Currency counter_offer_currency = 3;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getCounterOfferCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setCounterOfferCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double highest_offer_price = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getHighestOfferPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setHighestOfferPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Currency highest_offer_currency = 5;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getHighestOfferCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setHighestOfferCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string buyer_id = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UserOrigin buyer_origin = 7;
 * @return {!proto.com.mintlist.adminapiv1.UserOrigin}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.UserOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string auction_group_asset_id = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getAuctionGroupAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setAuctionGroupAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string contract_id = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 effective_date = 10;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Offer.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Offer} returns this
 */
proto.com.mintlist.adminapiv1.Offer.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AuctionGroupAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionGroupAssetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userReferenceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    assetReferenceId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    startPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AuctionGroupAsset;
  return proto.com.mintlist.adminapiv1.AuctionGroupAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupAssetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 4:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserReferenceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetReferenceId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartPrice(value);
      break;
    case 12:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AuctionGroupAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionGroupAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserReferenceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssetReferenceId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStartPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string auction_group_asset_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getAuctionGroupAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setAuctionGroupAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string asset_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AuctionStatus auction_status = 4;
 * @return {!proto.com.mintlist.adminapiv1.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getAuctionStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional OfferStatus offer_status = 5;
 * @return {!proto.com.mintlist.adminapiv1.OfferStatus}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getOfferStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string auction_group_id = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_reference_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getUserReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setUserReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_given_name = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_phone_number = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string asset_reference_id = 10;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getAssetReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setAssetReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double start_price = 11;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getStartPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setStartPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional Currency currency = 12;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroupAsset} returns this
 */
proto.com.mintlist.adminapiv1.AuctionGroupAsset.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Auctions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Auctions.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Auctions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Auctions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Auctions.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionsList: jspb.Message.toObjectList(msg.getAuctionsList(),
    proto.com.mintlist.adminapiv1.Auction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Auctions}
 */
proto.com.mintlist.adminapiv1.Auctions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Auctions;
  return proto.com.mintlist.adminapiv1.Auctions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Auctions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Auctions}
 */
proto.com.mintlist.adminapiv1.Auctions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Auction;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Auction.deserializeBinaryFromReader);
      msg.addAuctions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Auctions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Auctions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Auctions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Auctions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Auction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Auction auctions = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Auction>}
 */
proto.com.mintlist.adminapiv1.Auctions.prototype.getAuctionsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Auction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Auction, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Auction>} value
 * @return {!proto.com.mintlist.adminapiv1.Auctions} returns this
*/
proto.com.mintlist.adminapiv1.Auctions.prototype.setAuctionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Auction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Auction}
 */
proto.com.mintlist.adminapiv1.Auctions.prototype.addAuctions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Auction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Auctions} returns this
 */
proto.com.mintlist.adminapiv1.Auctions.prototype.clearAuctionsList = function() {
  return this.setAuctionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.BidID.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.BidID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.BidID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.BidID.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.BidID}
 */
proto.com.mintlist.adminapiv1.BidID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.BidID;
  return proto.com.mintlist.adminapiv1.BidID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.BidID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.BidID}
 */
proto.com.mintlist.adminapiv1.BidID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.BidID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.BidID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.BidID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.BidID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string bid_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.BidID.prototype.getBidId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.BidID} returns this
 */
proto.com.mintlist.adminapiv1.BidID.prototype.setBidId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Bid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Bid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Bid.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidId: (f = msg.getBidId()) && proto.com.mintlist.adminapiv1.BidID.toObject(includeInstance, f),
    bid: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    user: (f = msg.getUser()) && proto.com.mintlist.adminapiv1.User.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.Bid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Bid;
  return proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Bid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.BidID;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.BidID.deserializeBinaryFromReader);
      msg.setBidId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBid(value);
      break;
    case 3:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Bid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.BidID.serializeBinaryToWriter
    );
  }
  f = message.getBid();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional BidID bid_id = 1;
 * @return {?proto.com.mintlist.adminapiv1.BidID}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.getBidId = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.BidID} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.BidID, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.BidID|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
*/
proto.com.mintlist.adminapiv1.Bid.prototype.setBidId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
 */
proto.com.mintlist.adminapiv1.Bid.prototype.clearBidId = function() {
  return this.setBidId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.hasBidId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double bid = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.getBid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
 */
proto.com.mintlist.adminapiv1.Bid.prototype.setBid = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Currency currency = 3;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
 */
proto.com.mintlist.adminapiv1.Bid.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional User user = 4;
 * @return {?proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.getUser = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.User} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.User, 4));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.User|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
*/
proto.com.mintlist.adminapiv1.Bid.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
 */
proto.com.mintlist.adminapiv1.Bid.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Bid.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Bid} returns this
 */
proto.com.mintlist.adminapiv1.Bid.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Bids.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Bids.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Bids.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Bids} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Bids.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.com.mintlist.adminapiv1.Bid.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Bids}
 */
proto.com.mintlist.adminapiv1.Bids.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Bids;
  return proto.com.mintlist.adminapiv1.Bids.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Bids} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Bids}
 */
proto.com.mintlist.adminapiv1.Bids.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Bid;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Bids.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Bids.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Bids} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Bids.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Bid bids = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Bid>}
 */
proto.com.mintlist.adminapiv1.Bids.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Bid, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Bid>} value
 * @return {!proto.com.mintlist.adminapiv1.Bids} returns this
*/
proto.com.mintlist.adminapiv1.Bids.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.Bids.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Bids} returns this
 */
proto.com.mintlist.adminapiv1.Bids.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userRole: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeStamp: (f = msg.getTimeStamp()) && proto.com.mintlist.adminapiv1.Timestamp.toObject(includeInstance, f),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    location: (f = msg.getLocation()) && build_protos_entities_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.User;
  return proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 6:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AdminUserRole} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 7:
      var value = new proto.com.mintlist.adminapiv1.Timestamp;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = new build_protos_entities_pb.Location;
      reader.readMessage(value,build_protos_entities_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      build_protos_entities_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string family_name = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.User.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string given_name = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.User.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AdminUserRole user_role = 6;
 * @return {!proto.com.mintlist.adminapiv1.AdminUserRole}
 */
proto.com.mintlist.adminapiv1.User.prototype.getUserRole = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AdminUserRole} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AdminUserRole} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Timestamp time_stamp = 7;
 * @return {?proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.User.prototype.getTimeStamp = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Timestamp, 7));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Timestamp|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
*/
proto.com.mintlist.adminapiv1.User.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.User.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional entity.entities.Location location = 9;
 * @return {?proto.entity.entities.Location}
 */
proto.com.mintlist.adminapiv1.User.prototype.getLocation = function() {
  return /** @type{?proto.entity.entities.Location} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Location, 9));
};


/**
 * @param {?proto.entity.entities.Location|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
*/
proto.com.mintlist.adminapiv1.User.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.User} returns this
 */
proto.com.mintlist.adminapiv1.User.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.User.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.DealReportDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealReportDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealReportDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealReportDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealReportDetailsList: jspb.Message.toObjectList(msg.getDealReportDetailsList(),
    proto.com.mintlist.adminapiv1.DealReportDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetails}
 */
proto.com.mintlist.adminapiv1.DealReportDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealReportDetails;
  return proto.com.mintlist.adminapiv1.DealReportDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetails}
 */
proto.com.mintlist.adminapiv1.DealReportDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.DealReportDetail;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealReportDetail.deserializeBinaryFromReader);
      msg.addDealReportDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealReportDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealReportDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealReportDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealReportDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.DealReportDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DealReportDetail deal_report_details = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.DealReportDetail>}
 */
proto.com.mintlist.adminapiv1.DealReportDetails.prototype.getDealReportDetailsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.DealReportDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.DealReportDetail, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.DealReportDetail>} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetails} returns this
*/
proto.com.mintlist.adminapiv1.DealReportDetails.prototype.setDealReportDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail}
 */
proto.com.mintlist.adminapiv1.DealReportDetails.prototype.addDealReportDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.DealReportDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetails} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetails.prototype.clearDealReportDetailsList = function() {
  return this.setDealReportDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealReportDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealReportDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerAcceptedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehicle: (f = msg.getVehicle()) && proto.com.mintlist.adminapiv1.Vehicle.toObject(includeInstance, f),
    buyer: (f = msg.getBuyer()) && proto.com.mintlist.adminapiv1.Dealership.toObject(includeInstance, f),
    seller: (f = msg.getSeller()) && proto.com.mintlist.adminapiv1.User.toObject(includeInstance, f),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    contractId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    acceptedDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    buyerUser: (f = msg.getBuyerUser()) && proto.com.mintlist.adminapiv1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealReportDetail;
  return proto.com.mintlist.adminapiv1.DealReportDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOfferAcceptedPrice(value);
      break;
    case 2:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 4:
      var value = new proto.com.mintlist.adminapiv1.Dealership;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Dealership.deserializeBinaryFromReader);
      msg.setBuyer(value);
      break;
    case 5:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    case 6:
      var value = /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedDate(value);
      break;
    case 11:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.setBuyerUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealReportDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealReportDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealReportDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferAcceptedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getBuyer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.mintlist.adminapiv1.Dealership.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getContractId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAcceptedDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getBuyerUser();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional double offer_accepted_price = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getOfferAcceptedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setOfferAcceptedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Currency currency = 2;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Vehicle vehicle = 3;
 * @return {?proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getVehicle = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicle|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
*/
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Dealership buyer = 4;
 * @return {?proto.com.mintlist.adminapiv1.Dealership}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getBuyer = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Dealership} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Dealership, 4));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Dealership|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
*/
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setBuyer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.clearBuyer = function() {
  return this.setBuyer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.hasBuyer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User seller = 5;
 * @return {?proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getSeller = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.User} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.User, 5));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.User|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
*/
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OfferStatus offer_status = 6;
 * @return {!proto.com.mintlist.adminapiv1.OfferStatus}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getOfferStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional AuctionStatus auction_status = 7;
 * @return {!proto.com.mintlist.adminapiv1.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getAuctionStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 effective_date = 8;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string contract_id = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 accepted_date = 10;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getAcceptedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setAcceptedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional User buyer_user = 11;
 * @return {?proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.getBuyerUser = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.User} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.User, 11));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.User|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
*/
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.setBuyerUser = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DealReportDetail} returns this
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.clearBuyerUser = function() {
  return this.setBuyerUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealReportDetail.prototype.hasBuyerUser = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Version}
 */
proto.com.mintlist.adminapiv1.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Version;
  return proto.com.mintlist.adminapiv1.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Version}
 */
proto.com.mintlist.adminapiv1.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Version.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Version} returns this
 */
proto.com.mintlist.adminapiv1.Version.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.VehicleImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.VehicleImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mlImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    label: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage}
 */
proto.com.mintlist.adminapiv1.VehicleImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.VehicleImage;
  return proto.com.mintlist.adminapiv1.VehicleImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.VehicleImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage}
 */
proto.com.mintlist.adminapiv1.VehicleImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    case 5:
      var value = /** @type {!proto.com.mintlist.adminapiv1.ImageSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.VehicleImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.VehicleImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMlImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string original_url = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.getOriginalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage} returns this
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.setOriginalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ml_image_url = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.getMlImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage} returns this
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.setMlImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage} returns this
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool processed = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage} returns this
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional ImageSource source = 5;
 * @return {!proto.com.mintlist.adminapiv1.ImageSource}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.getSource = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.ImageSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.ImageSource} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage} returns this
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage} returns this
 */
proto.com.mintlist.adminapiv1.VehicleImage.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    titlePresent: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    allKeysPresent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    booksPresent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    discsPresent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    rimsTires: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    otherAnnouncements: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement;
  return proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitlePresent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllKeysPresent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBooksPresent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDiscsPresent(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRimsTires(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherAnnouncements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitlePresent();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllKeysPresent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBooksPresent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDiscsPresent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRimsTires();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOtherAnnouncements();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool title_present = 1;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.getTitlePresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} returns this
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.setTitlePresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string all_keys_present = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.getAllKeysPresent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} returns this
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.setAllKeysPresent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool books_present = 3;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.getBooksPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} returns this
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.setBooksPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool discs_present = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.getDiscsPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} returns this
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.setDiscsPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool rims_tires = 5;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.getRimsTires = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} returns this
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.setRimsTires = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string other_announcements = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.getOtherAnnouncements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} returns this
 */
proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.prototype.setOtherAnnouncements = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Vehicle.repeatedFields_ = [8,13,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mileage: jspb.Message.getFieldWithDefault(msg, 6, 0),
    location: (f = msg.getLocation()) && proto.com.mintlist.adminapiv1.VehicleLocation.toObject(includeInstance, f),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.com.mintlist.adminapiv1.VehicleImage.toObject, includeInstance),
    mlInspectionUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    vehicleClass: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timeStamp: (f = msg.getTimeStamp()) && proto.com.mintlist.adminapiv1.Timestamp.toObject(includeInstance, f),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 12, 0),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.com.mintlist.adminapiv1.AuctionGroup.toObject, includeInstance),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 14, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    driveTrain: jspb.Message.getFieldWithDefault(msg, 17, ""),
    fuelType: jspb.Message.getFieldWithDefault(msg, 18, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 19, ""),
    make: jspb.Message.getFieldWithDefault(msg, 20, ""),
    model: jspb.Message.getFieldWithDefault(msg, 21, ""),
    engine: jspb.Message.getFieldWithDefault(msg, 22, ""),
    vehicleOptionsList: jspb.Message.toObjectList(msg.getVehicleOptionsList(),
    proto.com.mintlist.adminapiv1.VehicleOption.toObject, includeInstance),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 24, ""),
    interiorType: jspb.Message.getFieldWithDefault(msg, 25, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 26, ""),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 27, 0),
    engineSize: jspb.Message.getFieldWithDefault(msg, 28, ""),
    vehiclePaveAnnouncement: (f = msg.getVehiclePaveAnnouncement()) && proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.toObject(includeInstance, f),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 31, 0),
    referrer: (f = msg.getReferrer()) && build_protos_entities_pb.VehicleReferrer.toObject(includeInstance, f),
    userEmail: jspb.Message.getFieldWithDefault(msg, 33, ""),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 34, 0),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 35, 0),
    wantTradeIn: jspb.Message.getBooleanFieldWithDefault(msg, 36, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Vehicle;
  return proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 7:
      var value = new proto.com.mintlist.adminapiv1.VehicleLocation;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.VehicleLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 8:
      var value = new proto.com.mintlist.adminapiv1.VehicleImage;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.VehicleImage.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlInspectionUrl(value);
      break;
    case 10:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleClass} */ (reader.readEnum());
      msg.setVehicleClass(value);
      break;
    case 11:
      var value = new proto.com.mintlist.adminapiv1.Timestamp;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 12:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 13:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveTrain(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFuelType(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngine(value);
      break;
    case 23:
      var value = new proto.com.mintlist.adminapiv1.VehicleOption;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.VehicleOption.deserializeBinaryFromReader);
      msg.addVehicleOptions(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorType(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 27:
      var value = /** @type {!proto.com.mintlist.adminapiv1.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngineSize(value);
      break;
    case 29:
      var value = new proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.deserializeBinaryFromReader);
      msg.setVehiclePaveAnnouncement(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 31:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 32:
      var value = new build_protos_entities_pb.VehicleReferrer;
      reader.readMessage(value,build_protos_entities_pb.VehicleReferrer.deserializeBinaryFromReader);
      msg.setReferrer(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 34:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    case 35:
      var value = /** @type {!proto.entity.enum.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWantTradeIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.mintlist.adminapiv1.VehicleLocation.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.com.mintlist.adminapiv1.VehicleImage.serializeBinaryToWriter
    );
  }
  f = message.getMlInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDriveTrain();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getFuelType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getEngine();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getVehicleOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.com.mintlist.adminapiv1.VehicleOption.serializeBinaryToWriter
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getInteriorType();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getEngineSize();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getVehiclePaveAnnouncement();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getReferrer();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      build_protos_entities_pb.VehicleReferrer.serializeBinaryToWriter
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
  f = message.getWantTradeIn();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional VehicleStatus status = 5;
 * @return {!proto.com.mintlist.adminapiv1.VehicleStatus}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleStatus} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 mileage = 6;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional VehicleLocation location = 7;
 * @return {?proto.com.mintlist.adminapiv1.VehicleLocation}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getLocation = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.VehicleLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.VehicleLocation, 7));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.VehicleLocation|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated VehicleImage images = 8;
 * @return {!Array<!proto.com.mintlist.adminapiv1.VehicleImage>}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.VehicleImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.VehicleImage, 8));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.VehicleImage>} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.VehicleImage}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.mintlist.adminapiv1.VehicleImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string ml_inspection_url = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getMlInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setMlInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional VehicleClass vehicle_class = 10;
 * @return {!proto.com.mintlist.adminapiv1.VehicleClass}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getVehicleClass = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleClass} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleClass} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Timestamp time_stamp = 11;
 * @return {?proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getTimeStamp = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Timestamp, 11));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Timestamp|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional VehicleOrigin vehicle_origin = 12;
 * @return {!proto.com.mintlist.adminapiv1.VehicleOrigin}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated AuctionGroup auction_groups = 13;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroup, 13));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.com.mintlist.adminapiv1.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional string trim_level = 14;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string user_given_name = 15;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string user_phone_number = 16;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string drive_train = 17;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getDriveTrain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setDriveTrain = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string fuel_type = 18;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getFuelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setFuelType = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string transmission = 19;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string make = 20;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string model = 21;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string engine = 22;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getEngine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setEngine = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated VehicleOption vehicle_options = 23;
 * @return {!Array<!proto.com.mintlist.adminapiv1.VehicleOption>}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getVehicleOptionsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.VehicleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.VehicleOption, 23));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.VehicleOption>} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setVehicleOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.addVehicleOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.com.mintlist.adminapiv1.VehicleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearVehicleOptionsList = function() {
  return this.setVehicleOptionsList([]);
};


/**
 * optional string interior_color = 24;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string interior_type = 25;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getInteriorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setInteriorType = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string exterior_color = 26;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional MileageUOM mileage_uom = 27;
 * @return {!proto.com.mintlist.adminapiv1.MileageUOM}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.MileageUOM} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional string engine_size = 28;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getEngineSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setEngineSize = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional VehiclePaveAnnouncement vehicle_pave_announcement = 29;
 * @return {?proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getVehiclePaveAnnouncement = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement, 29));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.VehiclePaveAnnouncement|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setVehiclePaveAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearVehiclePaveAnnouncement = function() {
  return this.setVehiclePaveAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.hasVehiclePaveAnnouncement = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional double price = 30;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional entity.enum.Currency currency = 31;
 * @return {!proto.entity.enum.Currency}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional entity.entities.VehicleReferrer Referrer = 32;
 * @return {?proto.entity.entities.VehicleReferrer}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getReferrer = function() {
  return /** @type{?proto.entity.entities.VehicleReferrer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.VehicleReferrer, 32));
};


/**
 * @param {?proto.entity.entities.VehicleReferrer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
*/
proto.com.mintlist.adminapiv1.Vehicle.prototype.setReferrer = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.clearReferrer = function() {
  return this.setReferrer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.hasReferrer = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional string user_email = 33;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional entity.enum.ListingStatus listing_status = 34;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional entity.enum.AuctionStatus auction_status = 35;
 * @return {!proto.entity.enum.AuctionStatus}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getAuctionStatus = function() {
  return /** @type {!proto.entity.enum.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.entity.enum.AuctionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};


/**
 * optional bool want_trade_in = 36;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.getWantTradeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicle} returns this
 */
proto.com.mintlist.adminapiv1.Vehicle.prototype.setWantTradeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.VehicleOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.VehicleOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    highValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption}
 */
proto.com.mintlist.adminapiv1.VehicleOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.VehicleOption;
  return proto.com.mintlist.adminapiv1.VehicleOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.VehicleOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption}
 */
proto.com.mintlist.adminapiv1.VehicleOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHighValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.VehicleOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.VehicleOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHighValue();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption} returns this
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption} returns this
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption} returns this
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool high_value = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.getHighValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleOption} returns this
 */
proto.com.mintlist.adminapiv1.VehicleOption.prototype.setHighValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.VehicleLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.VehicleLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.VehicleLocation;
  return proto.com.mintlist.adminapiv1.VehicleLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.VehicleLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.VehicleLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.VehicleLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.VehicleLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation} returns this
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation} returns this
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation} returns this
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation} returns this
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation} returns this
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.VehicleLocation} returns this
 */
proto.com.mintlist.adminapiv1.VehicleLocation.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Vehicles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.com.mintlist.adminapiv1.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Vehicles;
  return proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Vehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vehicle vehicles = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Vehicle>}
 */
proto.com.mintlist.adminapiv1.Vehicles.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Vehicle, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Vehicle>} value
 * @return {!proto.com.mintlist.adminapiv1.Vehicles} returns this
*/
proto.com.mintlist.adminapiv1.Vehicles.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Vehicle}
 */
proto.com.mintlist.adminapiv1.Vehicles.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Vehicles} returns this
 */
proto.com.mintlist.adminapiv1.Vehicles.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CarInspectionSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inspectionUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paveSessionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    smsSent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CarInspectionSession;
  return proto.com.mintlist.adminapiv1.CarInspectionSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarInspectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveSessionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmsSent(value);
      break;
    case 6:
      var value = /** @type {!proto.com.mintlist.adminapiv1.InspectionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CarInspectionSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaveSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSmsSent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string car_inspection_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getCarInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setCarInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inspection_url = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pave_session_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getPaveSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setPaveSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sms_sent = 5;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getSmsSent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setSmsSent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional InspectionStatus status = 6;
 * @return {!proto.com.mintlist.adminapiv1.InspectionStatus}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.InspectionStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.InspectionStatus} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string vehicle_id = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string redirect_url = 9;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSession.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CarInspectionSessions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSessions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionSessionList: jspb.Message.toObjectList(msg.getCarInspectionSessionList(),
    proto.com.mintlist.adminapiv1.CarInspectionSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSessions}
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CarInspectionSessions;
  return proto.com.mintlist.adminapiv1.CarInspectionSessions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSessions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSessions}
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.CarInspectionSession;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.CarInspectionSession.deserializeBinaryFromReader);
      msg.addCarInspectionSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CarInspectionSessions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSessions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionSessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.CarInspectionSession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionSession car_inspection_session = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.CarInspectionSession>}
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.prototype.getCarInspectionSessionList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.CarInspectionSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.CarInspectionSession, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.CarInspectionSession>} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSessions} returns this
*/
proto.com.mintlist.adminapiv1.CarInspectionSessions.prototype.setCarInspectionSessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSession}
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.prototype.addCarInspectionSession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.CarInspectionSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionSessions} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionSessions.prototype.clearCarInspectionSessionList = function() {
  return this.setCarInspectionSessionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CarInspectionAnswers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionAnswersList: jspb.Message.toObjectList(msg.getCarInspectionAnswersList(),
    proto.com.mintlist.adminapiv1.CarInspectionAnswer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswers}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CarInspectionAnswers;
  return proto.com.mintlist.adminapiv1.CarInspectionAnswers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswers}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.CarInspectionAnswer;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.CarInspectionAnswer.deserializeBinaryFromReader);
      msg.addCarInspectionAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CarInspectionAnswers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.CarInspectionAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionAnswer car_inspection_answers = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.CarInspectionAnswer>}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.prototype.getCarInspectionAnswersList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.CarInspectionAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.CarInspectionAnswer, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.CarInspectionAnswer>} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswers} returns this
*/
proto.com.mintlist.adminapiv1.CarInspectionAnswers.prototype.setCarInspectionAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.prototype.addCarInspectionAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.CarInspectionAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswers} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswers.prototype.clearCarInspectionAnswersList = function() {
  return this.setCarInspectionAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CarInspectionAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionOptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CarInspectionAnswer;
  return proto.com.mintlist.adminapiv1.CarInspectionAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionOptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CarInspectionAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionOptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question_option_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.getQuestionOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionAnswer.prototype.setQuestionOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.QuestionOption.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.QuestionOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.QuestionOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.QuestionOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    option: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasDetailField: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optionType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    followUpQuestionCategoriesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    disabledByOptionId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption}
 */
proto.com.mintlist.adminapiv1.QuestionOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.QuestionOption;
  return proto.com.mintlist.adminapiv1.QuestionOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.QuestionOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption}
 */
proto.com.mintlist.adminapiv1.QuestionOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOption(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDetailField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOptionOrder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addFollowUpQuestionCategories(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledByOptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.QuestionOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.QuestionOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.QuestionOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasDetailField();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptionType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFollowUpQuestionCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getDisabledByOptionId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string option = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setOption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_detail_field = 2;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getHasDetailField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setHasDetailField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 option_order = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getOptionOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setOptionOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_required = 5;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string option_type = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getOptionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setOptionType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string follow_up_question_categories = 9;
 * @return {!Array<string>}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getFollowUpQuestionCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setFollowUpQuestionCategoriesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.addFollowUpQuestionCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.clearFollowUpQuestionCategoriesList = function() {
  return this.setFollowUpQuestionCategoriesList([]);
};


/**
 * optional string disabled_by_option_id = 10;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.getDisabledByOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption} returns this
 */
proto.com.mintlist.adminapiv1.QuestionOption.prototype.setDisabledByOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CarInspectionQuestions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionQuestionsList: jspb.Message.toObjectList(msg.getCarInspectionQuestionsList(),
    proto.com.mintlist.adminapiv1.CarInspectionQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestions}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CarInspectionQuestions;
  return proto.com.mintlist.adminapiv1.CarInspectionQuestions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestions}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.CarInspectionQuestion;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.addCarInspectionQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CarInspectionQuestions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionQuestion car_inspection_questions = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.CarInspectionQuestion>}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.prototype.getCarInspectionQuestionsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.CarInspectionQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.CarInspectionQuestion, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.CarInspectionQuestion>} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestions} returns this
*/
proto.com.mintlist.adminapiv1.CarInspectionQuestions.prototype.setCarInspectionQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.prototype.addCarInspectionQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.CarInspectionQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestions} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestions.prototype.clearCarInspectionQuestionsList = function() {
  return this.setCarInspectionQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CarInspectionQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionSessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.com.mintlist.adminapiv1.QuestionOption.toObject, includeInstance),
    category: jspb.Message.getFieldWithDefault(msg, 5, ""),
    carInspectionAnswersList: jspb.Message.toObjectList(msg.getCarInspectionAnswersList(),
    proto.com.mintlist.adminapiv1.CarInspectionAnswer.toObject, includeInstance),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    questionType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    timeStamp: (f = msg.getTimeStamp()) && proto.com.mintlist.adminapiv1.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CarInspectionQuestion;
  return proto.com.mintlist.adminapiv1.CarInspectionQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarInspectionSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = new proto.com.mintlist.adminapiv1.QuestionOption;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.QuestionOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 6:
      var value = new proto.com.mintlist.adminapiv1.CarInspectionAnswer;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.CarInspectionAnswer.deserializeBinaryFromReader);
      msg.addCarInspectionAnswers(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 8:
      var value = /** @type {!proto.com.mintlist.adminapiv1.QuestionType} */ (reader.readEnum());
      msg.setQuestionType(value);
      break;
    case 9:
      var value = new proto.com.mintlist.adminapiv1.Timestamp;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CarInspectionQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.mintlist.adminapiv1.QuestionOption.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCarInspectionAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.mintlist.adminapiv1.CarInspectionAnswer.serializeBinaryToWriter
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getQuestionType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string car_inspection_session_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getCarInspectionSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setCarInspectionSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string question_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated QuestionOption options = 4;
 * @return {!Array<!proto.com.mintlist.adminapiv1.QuestionOption>}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.QuestionOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.QuestionOption, 4));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.QuestionOption>} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
*/
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.QuestionOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.QuestionOption}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.mintlist.adminapiv1.QuestionOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CarInspectionAnswer car_inspection_answers = 6;
 * @return {!Array<!proto.com.mintlist.adminapiv1.CarInspectionAnswer>}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getCarInspectionAnswersList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.CarInspectionAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.CarInspectionAnswer, 6));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.CarInspectionAnswer>} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
*/
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setCarInspectionAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.CarInspectionAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionAnswer}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.addCarInspectionAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.mintlist.adminapiv1.CarInspectionAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.clearCarInspectionAnswersList = function() {
  return this.setCarInspectionAnswersList([]);
};


/**
 * optional bool is_required = 7;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional QuestionType question_type = 8;
 * @return {!proto.com.mintlist.adminapiv1.QuestionType}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getQuestionType = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.QuestionType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.QuestionType} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Timestamp time_stamp = 9;
 * @return {?proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getTimeStamp = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Timestamp, 9));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Timestamp|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
*/
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CarInspectionQuestion} returns this
 */
proto.com.mintlist.adminapiv1.CarInspectionQuestion.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AssetLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AssetLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AssetLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation}
 */
proto.com.mintlist.adminapiv1.AssetLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AssetLocation;
  return proto.com.mintlist.adminapiv1.AssetLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AssetLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation}
 */
proto.com.mintlist.adminapiv1.AssetLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AssetLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AssetLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AssetLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation} returns this
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation} returns this
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation} returns this
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation} returns this
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation} returns this
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetLocation} returns this
 */
proto.com.mintlist.adminapiv1.AssetLocation.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Asset.repeatedFields_ = [6,8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assetClass: jspb.Message.getFieldWithDefault(msg, 5, 0),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.com.mintlist.adminapiv1.AuctionGroup.toObject, includeInstance),
    timeStamp: (f = msg.getTimeStamp()) && proto.com.mintlist.adminapiv1.Timestamp.toObject(includeInstance, f),
    assetImagesList: jspb.Message.toObjectList(msg.getAssetImagesList(),
    proto.com.mintlist.adminapiv1.AssetImage.toObject, includeInstance),
    mileage: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    assetLocation: (f = msg.getAssetLocation()) && proto.com.mintlist.adminapiv1.AssetLocation.toObject(includeInstance, f),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.com.mintlist.adminapiv1.Bid.toObject, includeInstance),
    referenceId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assetOrigin: jspb.Message.getFieldWithDefault(msg, 13, 0),
    userReferenceId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 17, ""),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Asset}
 */
proto.com.mintlist.adminapiv1.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Asset;
  return proto.com.mintlist.adminapiv1.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Asset}
 */
proto.com.mintlist.adminapiv1.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AuctionClass} */ (reader.readEnum());
      msg.setAssetClass(value);
      break;
    case 6:
      var value = new proto.com.mintlist.adminapiv1.AuctionGroup;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 7:
      var value = new proto.com.mintlist.adminapiv1.Timestamp;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 8:
      var value = new proto.com.mintlist.adminapiv1.AssetImage;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AssetImage.deserializeBinaryFromReader);
      msg.addAssetImages(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMileage(value);
      break;
    case 10:
      var value = new proto.com.mintlist.adminapiv1.AssetLocation;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.AssetLocation.deserializeBinaryFromReader);
      msg.setAssetLocation(value);
      break;
    case 11:
      var value = new proto.com.mintlist.adminapiv1.Bid;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 13:
      var value = /** @type {!proto.com.mintlist.adminapiv1.AssetOrigin} */ (reader.readEnum());
      msg.setAssetOrigin(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserReferenceId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 18:
      var value = /** @type {!proto.com.mintlist.adminapiv1.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetClass();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.mintlist.adminapiv1.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.mintlist.adminapiv1.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAssetImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.com.mintlist.adminapiv1.AssetImage.serializeBinaryToWriter
    );
  }
  f = message.getMileage();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getAssetLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.mintlist.adminapiv1.AssetLocation.serializeBinaryToWriter
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.com.mintlist.adminapiv1.Bid.serializeBinaryToWriter
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getUserReferenceId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AuctionClass asset_class = 5;
 * @return {!proto.com.mintlist.adminapiv1.AuctionClass}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getAssetClass = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AuctionClass} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionClass} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setAssetClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated AuctionGroup auction_groups = 6;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AuctionGroup, 6));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AuctionGroup>} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
*/
proto.com.mintlist.adminapiv1.Asset.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AuctionGroup}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.mintlist.adminapiv1.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional Timestamp time_stamp = 7;
 * @return {?proto.com.mintlist.adminapiv1.Timestamp}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getTimeStamp = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Timestamp, 7));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Timestamp|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
*/
proto.com.mintlist.adminapiv1.Asset.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated AssetImage asset_images = 8;
 * @return {!Array<!proto.com.mintlist.adminapiv1.AssetImage>}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getAssetImagesList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.AssetImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.AssetImage, 8));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.AssetImage>} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
*/
proto.com.mintlist.adminapiv1.Asset.prototype.setAssetImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AssetImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.AssetImage}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.addAssetImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.mintlist.adminapiv1.AssetImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.clearAssetImagesList = function() {
  return this.setAssetImagesList([]);
};


/**
 * optional float mileage = 9;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setMileage = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional AssetLocation asset_location = 10;
 * @return {?proto.com.mintlist.adminapiv1.AssetLocation}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getAssetLocation = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.AssetLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.AssetLocation, 10));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.AssetLocation|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
*/
proto.com.mintlist.adminapiv1.Asset.prototype.setAssetLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.clearAssetLocation = function() {
  return this.setAssetLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.hasAssetLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated Bid bids = 11;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Bid>}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Bid, 11));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Bid>} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
*/
proto.com.mintlist.adminapiv1.Asset.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Bid}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.mintlist.adminapiv1.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional string reference_id = 12;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional AssetOrigin asset_origin = 13;
 * @return {!proto.com.mintlist.adminapiv1.AssetOrigin}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getAssetOrigin = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.AssetOrigin} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.AssetOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setAssetOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string user_reference_id = 14;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getUserReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setUserReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string user_given_name = 15;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string user_phone_number = 16;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string trim_level = 17;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional MileageUOM mileage_uom = 18;
 * @return {!proto.com.mintlist.adminapiv1.MileageUOM}
 */
proto.com.mintlist.adminapiv1.Asset.prototype.getMileageUom = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.MileageUOM} value
 * @return {!proto.com.mintlist.adminapiv1.Asset} returns this
 */
proto.com.mintlist.adminapiv1.Asset.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Assets.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Assets.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Assets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Assets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Assets.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.com.mintlist.adminapiv1.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Assets}
 */
proto.com.mintlist.adminapiv1.Assets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Assets;
  return proto.com.mintlist.adminapiv1.Assets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Assets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Assets}
 */
proto.com.mintlist.adminapiv1.Assets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Asset;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Assets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Assets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Assets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Assets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.Asset>}
 */
proto.com.mintlist.adminapiv1.Assets.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.Asset, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.Asset>} value
 * @return {!proto.com.mintlist.adminapiv1.Assets} returns this
*/
proto.com.mintlist.adminapiv1.Assets.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.Asset}
 */
proto.com.mintlist.adminapiv1.Assets.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Assets} returns this
 */
proto.com.mintlist.adminapiv1.Assets.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AssetImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AssetImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AssetImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mlImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    label: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AssetImage}
 */
proto.com.mintlist.adminapiv1.AssetImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AssetImage;
  return proto.com.mintlist.adminapiv1.AssetImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AssetImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AssetImage}
 */
proto.com.mintlist.adminapiv1.AssetImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AssetImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AssetImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AssetImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMlImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string original_url = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.getOriginalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetImage} returns this
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.setOriginalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ml_image_url = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.getMlImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetImage} returns this
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.setMlImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AssetImage} returns this
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AssetImage} returns this
 */
proto.com.mintlist.adminapiv1.AssetImage.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberVehicles: jspb.Message.getFieldWithDefault(msg, 1, 0),
    consumerEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GenerateVehiclesRequest;
  return proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberVehicles(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberVehicles();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getConsumerEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 number_vehicles = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.prototype.getNumberVehicles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.prototype.setNumberVehicles = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string consumer_email = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.prototype.getConsumerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesRequest.prototype.setConsumerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  ERROR_RESPONSE: 2,
  VEHICLES: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f),
    vehicles: (f = msg.getVehicles()) && proto.com.mintlist.adminapiv1.Vehicles.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GenerateVehiclesResponse;
  return proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.Vehicles;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ErrorResponse error_response = 2;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vehicles vehicles = 3;
 * @return {?proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.getVehicles = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicles, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicles|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.setVehicles = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GenerateVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GenerateVehiclesResponse.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest;
  return proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.VehicleOrigin vehicle_origin = 2;
 * @return {!proto.entity.enum.VehicleOrigin}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.enum.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.VehicleOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleRequest.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  SUCCESS_RESPONSE: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    successResponse: (f = msg.getSuccessResponse()) && proto.com.mintlist.adminapiv1.SuccessResponse.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse;
  return proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.SuccessResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.SuccessResponse.deserializeBinaryFromReader);
      msg.setSuccessResponse(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSuccessResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.SuccessResponse.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SuccessResponse success_response = 2;
 * @return {?proto.com.mintlist.adminapiv1.SuccessResponse}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.getSuccessResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.SuccessResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.SuccessResponse, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.SuccessResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.setSuccessResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.clearSuccessResponse = function() {
  return this.setSuccessResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.hasSuccessResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ProcessImagesForVehicleResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SimpleOfferInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    origin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    buyerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dealershipName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SimpleOfferInfo;
  return proto.com.mintlist.adminapiv1.SimpleOfferInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrigin(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerId(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SimpleOfferInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOrigin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBuyerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDealershipName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string dealer_name = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string origin = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 4;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string buyer_id = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getBuyerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setBuyerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional entity.enum.UserOrigin buyer_origin = 6;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string offer_id = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dealership_name = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.getDealershipName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferInfo.prototype.setDealershipName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SimpleOfferContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerList: jspb.Message.toObjectList(msg.getOfferList(),
    proto.com.mintlist.adminapiv1.SimpleOfferInfo.toObject, includeInstance),
    uniqueCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferContainer}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SimpleOfferContainer;
  return proto.com.mintlist.adminapiv1.SimpleOfferContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferContainer}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.SimpleOfferInfo;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.SimpleOfferInfo.deserializeBinaryFromReader);
      msg.addOffer(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SimpleOfferContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.SimpleOfferInfo.serializeBinaryToWriter
    );
  }
  f = message.getUniqueCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated SimpleOfferInfo offer = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.SimpleOfferInfo>}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.getOfferList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.SimpleOfferInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.SimpleOfferInfo, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.SimpleOfferInfo>} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferContainer} returns this
*/
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.setOfferList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.SimpleOfferInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferInfo}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.addOffer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.SimpleOfferInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferContainer} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.clearOfferList = function() {
  return this.setOfferList([]);
};


/**
 * optional int32 unique_count = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.getUniqueCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.SimpleOfferContainer} returns this
 */
proto.com.mintlist.adminapiv1.SimpleOfferContainer.prototype.setUniqueCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListOffersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListOffersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListOffersRequest}
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListOffersRequest;
  return proto.com.mintlist.adminapiv1.ListOffersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListOffersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListOffersRequest}
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListOffersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListOffersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListOffersRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListOffersRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  OFFERS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ListOffersResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ListOffersResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ListOffersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListOffersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListOffersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offers: (f = msg.getOffers()) && proto.com.mintlist.adminapiv1.SimpleOfferContainer.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListOffersResponse;
  return proto.com.mintlist.adminapiv1.ListOffersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListOffersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.SimpleOfferContainer;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.SimpleOfferContainer.deserializeBinaryFromReader);
      msg.setOffers(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListOffersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListOffersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.SimpleOfferContainer.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SimpleOfferContainer offers = 2;
 * @return {?proto.com.mintlist.adminapiv1.SimpleOfferContainer}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.getOffers = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.SimpleOfferContainer} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.SimpleOfferContainer, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.SimpleOfferContainer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.setOffers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.ListOffersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.clearOffers = function() {
  return this.setOffers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.hasOffers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ListOffersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListOffersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListOffersResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest;
  return proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse;
  return proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectAuctionVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetConsumersByNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameQuery: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameRequest}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetConsumersByNameRequest;
  return proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetConsumersByNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameRequest}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNameQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetConsumersByNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name_query = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.prototype.getNameQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameRequest.prototype.setNameQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.com.mintlist.adminapiv1.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.Users}
 */
proto.com.mintlist.adminapiv1.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.Users;
  return proto.com.mintlist.adminapiv1.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.Users}
 */
proto.com.mintlist.adminapiv1.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.User>}
 */
proto.com.mintlist.adminapiv1.Users.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.User, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.User>} value
 * @return {!proto.com.mintlist.adminapiv1.Users} returns this
*/
proto.com.mintlist.adminapiv1.Users.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.Users.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.Users} returns this
 */
proto.com.mintlist.adminapiv1.Users.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  USERS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    users: (f = msg.getUsers()) && proto.com.mintlist.adminapiv1.Users.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetConsumersByNameResponse;
  return proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Users;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Users.deserializeBinaryFromReader);
      msg.setUsers(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUsers();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Users.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Users users = 2;
 * @return {?proto.com.mintlist.adminapiv1.Users}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.getUsers = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Users} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Users, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Users|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.setUsers = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.clearUsers = function() {
  return this.setUsers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.hasUsers = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetConsumersByNameResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetConsumersByNameResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isAuction: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest;
  return proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsAuction();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_given_name = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_phone_number = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_auction = 5;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.getIsAuction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipRequest.prototype.setIsAuction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  SUCCESS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse;
  return proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.clearSuccess = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} returns this
*/
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse} returns this
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ChangeVehicleOwnershipResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest;
  return proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address1 = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address2 = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postal_code = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.mintlist.adminapiv1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse;
  return proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.prototype.getUser = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.User} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.User, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.User|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateConsumerUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SearchVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagination: (f = msg.getPagination()) && proto.com.mintlist.adminapiv1.Pagination.toObject(includeInstance, f),
    startDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 5, ""),
    make: jspb.Message.getFieldWithDefault(msg, 6, ""),
    model: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SearchVehiclesRequest;
  return proto.com.mintlist.adminapiv1.SearchVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (reader.readEnum());
      msg.setVehicleStatus(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Pagination;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SearchVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional VehicleStatus vehicle_status = 1;
 * @return {!proto.com.mintlist.adminapiv1.VehicleStatus}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getVehicleStatus = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.VehicleStatus} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getPagination = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Pagination, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string start_date = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_date = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string owner = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string make = 6;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string model = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  VEHICLES: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.SearchVehiclesResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.SearchVehiclesResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.SearchVehiclesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.SearchVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicles: (f = msg.getVehicles()) && proto.com.mintlist.adminapiv1.Vehicles.toObject(includeInstance, f),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.SearchVehiclesResponse;
  return proto.com.mintlist.adminapiv1.SearchVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.mintlist.adminapiv1.Vehicles;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.SearchVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vehicles vehicles = 2;
 * @return {?proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.getVehicles = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicles, 2));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicles|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.setVehicles = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.mintlist.adminapiv1.SearchVehiclesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.SearchVehiclesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 total = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.SearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.SearchVehiclesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest;
  return proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  SUCCESS: 2,
  ERROR_RESPONSE: 3
};

/**
 * @return {proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.ResponseCase}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.getResponseCase = function() {
  return /** @type {proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorResponse: (f = msg.getErrorResponse()) && proto.com.mintlist.adminapiv1.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse;
  return proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 3:
      var value = new proto.com.mintlist.adminapiv1.ErrorResponse;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.ErrorResponse.deserializeBinaryFromReader);
      msg.setErrorResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.mintlist.adminapiv1.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.clearSuccess = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse error_response = 3;
 * @return {?proto.com.mintlist.adminapiv1.ErrorResponse}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.getErrorResponse = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.ErrorResponse, 3));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.ErrorResponse|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.setErrorResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.clearErrorResponse = function() {
  return this.setErrorResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ResetTermsAndConditionsResponse.prototype.hasErrorResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    askingPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isTradeIn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    expirationTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest;
  return proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAskingPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTradeIn(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAskingPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsTradeIn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExpirationTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double asking_price = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.getAskingPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.setAskingPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_trade_in = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.getIsTradeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.setIsTradeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 expiration_time = 5;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.getExpirationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest} returns this
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankRequest.prototype.setExpirationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse;
  return proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string listing_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse} returns this
 */
proto.com.mintlist.adminapiv1.AddVehicleToDealTankResponse.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest;
  return proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest} returns this
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse;
  return proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string listing_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse} returns this
 */
proto.com.mintlist.adminapiv1.RemoveVehicleFromDealTankResponse.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    askingPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isTradeIn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    expirationTime: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest;
  return proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAskingPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTradeIn(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAskingPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsTradeIn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExpirationTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double asking_price = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.getAskingPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.setAskingPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional entity.enum.Currency currency = 3;
 * @return {!proto.entity.enum.Currency}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_trade_in = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.getIsTradeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.setIsTradeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 expiration_time = 5;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.getExpirationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleRequest.prototype.setExpirationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listing: (f = msg.getListing()) && build_protos_dealtankentity_pb.Listing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse;
  return proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.Listing;
      reader.readMessage(value,build_protos_dealtankentity_pb.Listing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.Listing.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.Listing listing = 1;
 * @return {?proto.entity.dealtankentity.Listing}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.prototype.getListing = function() {
  return /** @type{?proto.entity.dealtankentity.Listing} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.Listing, 1));
};


/**
 * @param {?proto.entity.dealtankentity.Listing|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.EditDealTankVehicleResponse.prototype.hasListing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest;
  return proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listing: (f = msg.getListing()) && build_protos_dealtankentity_pb.Listing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse;
  return proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.Listing;
      reader.readMessage(value,build_protos_dealtankentity_pb.Listing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.Listing.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.Listing listing = 1;
 * @return {?proto.entity.dealtankentity.Listing}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.prototype.getListing = function() {
  return /** @type{?proto.entity.dealtankentity.Listing} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.Listing, 1));
};


/**
 * @param {?proto.entity.dealtankentity.Listing|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetDealTankVehicleResponse.prototype.hasListing = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facetFiltersList: jspb.Message.toObjectList(msg.getFacetFiltersList(),
    build_protos_searchentity_pb.FacetFilter.toObject, includeInstance),
    facetList: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest;
  return proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = new build_protos_searchentity_pb.FacetFilter;
      reader.readMessage(value,build_protos_searchentity_pb.FacetFilter.deserializeBinaryFromReader);
      msg.addFacetFilters(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacetList(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacetFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      build_protos_searchentity_pb.FacetFilter.serializeBinaryToWriter
    );
  }
  f = message.getFacetList();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated entity.searchentity.FacetFilter facet_filters = 2;
 * @return {!Array<!proto.entity.searchentity.FacetFilter>}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.getFacetFiltersList = function() {
  return /** @type{!Array<!proto.entity.searchentity.FacetFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.FacetFilter, 2));
};


/**
 * @param {!Array<!proto.entity.searchentity.FacetFilter>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.setFacetFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.entity.searchentity.FacetFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.FacetFilter}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.addFacetFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.entity.searchentity.FacetFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.clearFacetFiltersList = function() {
  return this.setFacetFiltersList([]);
};


/**
 * optional string facet_list = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.getFacetList = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.setFacetList = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.enum.ListingStatus listing_status = 4;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsRequest.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facetCountsList: jspb.Message.toObjectList(msg.getFacetCountsList(),
    build_protos_searchentity_pb.Facet.toObject, includeInstance),
    listingStatus: (f = msg.getListingStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse;
  return proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.addFacetCounts(value);
      break;
    case 2:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacetCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getListingStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.searchentity.Facet facet_counts = 1;
 * @return {!Array<!proto.entity.searchentity.Facet>}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.getFacetCountsList = function() {
  return /** @type{!Array<!proto.entity.searchentity.Facet>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.Facet, 1));
};


/**
 * @param {!Array<!proto.entity.searchentity.Facet>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.setFacetCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.searchentity.Facet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.addFacetCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.searchentity.Facet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.clearFacetCountsList = function() {
  return this.setFacetCountsList([]);
};


/**
 * optional entity.searchentity.Facet listing_status = 2;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.getListingStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 2));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.setListingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.clearListingStatus = function() {
  return this.setListingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehicleFacetsResponse.prototype.hasListingStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pagination: (f = msg.getPagination()) && build_protos_common_pb.Pagination.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    listingSort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    listingStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offerStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    facetFiltersList: jspb.Message.toObjectList(msg.getFacetFiltersList(),
    build_protos_searchentity_pb.FacetFilter.toObject, includeInstance),
    keyword: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest;
  return proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 2:
      var value = new build_protos_common_pb.Pagination;
      reader.readMessage(value,build_protos_common_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.entity.enum.ListingSort} */ (reader.readEnum());
      msg.setListingSort(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.enum.ListingStatus} */ (reader.readEnum());
      msg.setListingStatus(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    case 7:
      var value = new build_protos_searchentity_pb.FacetFilter;
      reader.readMessage(value,build_protos_searchentity_pb.FacetFilter.deserializeBinaryFromReader);
      msg.addFacetFilters(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_common_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getListingSort();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getListingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getFacetFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      build_protos_searchentity_pb.FacetFilter.serializeBinaryToWriter
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional bool is_favourite = 1;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional entity.common.Pagination pagination = 2;
 * @return {?proto.entity.common.Pagination}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getPagination = function() {
  return /** @type{?proto.entity.common.Pagination} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Pagination, 2));
};


/**
 * @param {?proto.entity.common.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional entity.enum.ListingSort listing_sort = 4;
 * @return {!proto.entity.enum.ListingSort}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getListingSort = function() {
  return /** @type {!proto.entity.enum.ListingSort} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.entity.enum.ListingSort} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setListingSort = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional entity.enum.ListingStatus listing_status = 5;
 * @return {!proto.entity.enum.ListingStatus}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getListingStatus = function() {
  return /** @type {!proto.entity.enum.ListingStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.enum.ListingStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setListingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional entity.enum.OfferStatus offer_status = 6;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated entity.searchentity.FacetFilter facet_filters = 7;
 * @return {!Array<!proto.entity.searchentity.FacetFilter>}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getFacetFiltersList = function() {
  return /** @type{!Array<!proto.entity.searchentity.FacetFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.FacetFilter, 7));
};


/**
 * @param {!Array<!proto.entity.searchentity.FacetFilter>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setFacetFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.entity.searchentity.FacetFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.FacetFilter}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.addFacetFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.entity.searchentity.FacetFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.clearFacetFiltersList = function() {
  return this.setFacetFiltersList([]);
};


/**
 * optional string keyword = 8;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.com.mintlist.adminapiv1.DealTankVehicle.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    facetCountsList: jspb.Message.toObjectList(msg.getFacetCountsList(),
    build_protos_searchentity_pb.Facet.toObject, includeInstance),
    listingStatus: (f = msg.getListingStatus()) && build_protos_searchentity_pb.Facet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse;
  return proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.DealTankVehicle;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.DealTankVehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.addFacetCounts(value);
      break;
    case 4:
      var value = new build_protos_searchentity_pb.Facet;
      reader.readMessage(value,build_protos_searchentity_pb.Facet.deserializeBinaryFromReader);
      msg.setListingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.DealTankVehicle.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFacetCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
  f = message.getListingStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      build_protos_searchentity_pb.Facet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DealTankVehicle vehicles = 1;
 * @return {!Array<!proto.com.mintlist.adminapiv1.DealTankVehicle>}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.com.mintlist.adminapiv1.DealTankVehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.mintlist.adminapiv1.DealTankVehicle, 1));
};


/**
 * @param {!Array<!proto.com.mintlist.adminapiv1.DealTankVehicle>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.mintlist.adminapiv1.DealTankVehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.DealTankVehicle}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.mintlist.adminapiv1.DealTankVehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated entity.searchentity.Facet facet_counts = 3;
 * @return {!Array<!proto.entity.searchentity.Facet>}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.getFacetCountsList = function() {
  return /** @type{!Array<!proto.entity.searchentity.Facet>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.Facet, 3));
};


/**
 * @param {!Array<!proto.entity.searchentity.Facet>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.setFacetCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.entity.searchentity.Facet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.addFacetCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.entity.searchentity.Facet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.clearFacetCountsList = function() {
  return this.setFacetCountsList([]);
};


/**
 * optional entity.searchentity.Facet listing_status = 4;
 * @return {?proto.entity.searchentity.Facet}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.getListingStatus = function() {
  return /** @type{?proto.entity.searchentity.Facet} */ (
    jspb.Message.getWrapperField(this, build_protos_searchentity_pb.Facet, 4));
};


/**
 * @param {?proto.entity.searchentity.Facet|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.setListingStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.clearListingStatus = function() {
  return this.setListingStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealTankVehiclesResponse.prototype.hasListingStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DealTankVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DealTankVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && build_protos_dealtankentity_pb.Listing.toObject(includeInstance, f),
    wantTradeIn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DealTankVehicle}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DealTankVehicle;
  return proto.com.mintlist.adminapiv1.DealTankVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DealTankVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DealTankVehicle}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.Listing;
      reader.readMessage(value,build_protos_dealtankentity_pb.Listing.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWantTradeIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DealTankVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DealTankVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.Listing.serializeBinaryToWriter
    );
  }
  f = message.getWantTradeIn();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional entity.dealtankentity.Listing vehicle = 1;
 * @return {?proto.entity.dealtankentity.Listing}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.getVehicle = function() {
  return /** @type{?proto.entity.dealtankentity.Listing} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.Listing, 1));
};


/**
 * @param {?proto.entity.dealtankentity.Listing|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DealTankVehicle} returns this
*/
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DealTankVehicle} returns this
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool want_trade_in = 2;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.getWantTradeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DealTankVehicle} returns this
 */
proto.com.mintlist.adminapiv1.DealTankVehicle.prototype.setWantTradeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest;
  return proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offers: (f = msg.getOffers()) && proto.com.mintlist.adminapiv1.SimpleOfferContainer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse;
  return proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.SimpleOfferContainer;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.SimpleOfferContainer.deserializeBinaryFromReader);
      msg.setOffers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffers();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.SimpleOfferContainer.serializeBinaryToWriter
    );
  }
};


/**
 * optional SimpleOfferContainer offers = 1;
 * @return {?proto.com.mintlist.adminapiv1.SimpleOfferContainer}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.prototype.getOffers = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.SimpleOfferContainer} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.SimpleOfferContainer, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.SimpleOfferContainer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.prototype.setOffers = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.prototype.clearOffers = function() {
  return this.setOffers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListDealTankOffersForVehicleResponse.prototype.hasOffers = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offer: (f = msg.getOffer()) && build_protos_dealtankentity_pb.DealTankOffer.toObject(includeInstance, f),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest;
  return proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional entity.dealtankentity.DealTankOffer offer = 1;
 * @return {?proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.getOffer = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankOffer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankOffer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.setOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offer: (f = msg.getOffer()) && build_protos_dealtankentity_pb.DealTankOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse;
  return proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.DealTankOffer offer = 1;
 * @return {?proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.prototype.getOffer = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankOffer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankOffer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.prototype.setOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateDealTankOfferResponse.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offer: (f = msg.getOffer()) && build_protos_dealtankentity_pb.DealTankOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.EditDealTankOfferRequest;
  return proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.DealTankOffer offer = 1;
 * @return {?proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.prototype.getOffer = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankOffer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankOffer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest} returns this
*/
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.prototype.setOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferRequest.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offer: (f = msg.getOffer()) && build_protos_dealtankentity_pb.DealTankOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.EditDealTankOfferResponse;
  return proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.setOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.DealTankOffer offer = 1;
 * @return {?proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.prototype.getOffer = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankOffer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankOffer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.prototype.setOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.EditDealTankOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.prototype.clearOffer = function() {
  return this.setOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.EditDealTankOfferResponse.prototype.hasOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetOffersByStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerStatus: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusRequest}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetOffersByStatusRequest;
  return proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetOffersByStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusRequest}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.enum.OfferStatus} */ (reader.readEnum());
      msg.setOfferStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetOffersByStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional entity.enum.OfferStatus offer_status = 1;
 * @return {!proto.entity.enum.OfferStatus}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.prototype.getOfferStatus = function() {
  return /** @type {!proto.entity.enum.OfferStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.enum.OfferStatus} value
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusRequest.prototype.setOfferStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    offersList: jspb.Message.toObjectList(msg.getOffersList(),
    build_protos_dealtankentity_pb.DealTankOffer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetOffersByStatusResponse;
  return proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.addOffers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.dealtankentity.DealTankOffer offers = 1;
 * @return {!Array<!proto.entity.dealtankentity.DealTankOffer>}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.prototype.getOffersList = function() {
  return /** @type{!Array<!proto.entity.dealtankentity.DealTankOffer>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {!Array<!proto.entity.dealtankentity.DealTankOffer>} value
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.prototype.setOffersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealtankentity.DealTankOffer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.prototype.addOffers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealtankentity.DealTankOffer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.GetOffersByStatusResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetOffersByStatusResponse.prototype.clearOffersList = function() {
  return this.setOffersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expirationTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    askingPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    isTradeIn: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest;
  return proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAskingPrice(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTradeIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getExpirationTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAskingPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getIsTradeIn();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional entity.enum.Currency currency = 2;
 * @return {!proto.entity.enum.Currency}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.getCurrency = function() {
  return /** @type {!proto.entity.enum.Currency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 expiration_time = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.getExpirationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.setExpirationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double asking_price = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.getAskingPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.setAskingPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool is_trade_in = 5;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.getIsTradeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleRequest.prototype.setIsTradeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse;
  return proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string listing_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ReactivateDealTankVehicleResponse.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest;
  return proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string offer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealTankOffer: (f = msg.getDealTankOffer()) && build_protos_dealtankentity_pb.DealTankOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse;
  return proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.setDealTankOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealTankOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.DealTankOffer deal_tank_offer = 1;
 * @return {?proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.prototype.getDealTankOffer = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankOffer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankOffer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.prototype.setDealTankOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.prototype.clearDealTankOffer = function() {
  return this.setDealTankOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.AcceptDealTankOfferResponse.prototype.hasDealTankOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest;
  return proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string offer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.prototype.getOfferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest} returns this
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealTankOffer: (f = msg.getDealTankOffer()) && build_protos_dealtankentity_pb.DealTankOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse;
  return proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.DealTankOffer;
      reader.readMessage(value,build_protos_dealtankentity_pb.DealTankOffer.deserializeBinaryFromReader);
      msg.setDealTankOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealTankOffer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.DealTankOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.DealTankOffer deal_tank_offer = 1;
 * @return {?proto.entity.dealtankentity.DealTankOffer}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.prototype.getDealTankOffer = function() {
  return /** @type{?proto.entity.dealtankentity.DealTankOffer} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.DealTankOffer, 1));
};


/**
 * @param {?proto.entity.dealtankentity.DealTankOffer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse} returns this
*/
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.prototype.setDealTankOffer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse} returns this
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.prototype.clearDealTankOffer = function() {
  return this.setDealTankOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.RejectDealTankOfferResponse.prototype.hasDealTankOffer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankListingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listingId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingRequest}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RejectDealTankListingRequest;
  return proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankListingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingRequest}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankListingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string listing_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.prototype.getListingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingRequest} returns this
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingRequest.prototype.setListingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listing: (f = msg.getListing()) && build_protos_dealtankentity_pb.Listing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.RejectDealTankListingResponse;
  return proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealtankentity_pb.Listing;
      reader.readMessage(value,build_protos_dealtankentity_pb.Listing.deserializeBinaryFromReader);
      msg.setListing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealtankentity_pb.Listing.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealtankentity.Listing listing = 1;
 * @return {?proto.entity.dealtankentity.Listing}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.prototype.getListing = function() {
  return /** @type{?proto.entity.dealtankentity.Listing} */ (
    jspb.Message.getWrapperField(this, build_protos_dealtankentity_pb.Listing, 1));
};


/**
 * @param {?proto.entity.dealtankentity.Listing|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse} returns this
*/
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.prototype.setListing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.RejectDealTankListingResponse} returns this
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.prototype.clearListing = function() {
  return this.setListing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.RejectDealTankListingResponse.prototype.hasListing = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    yearStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    yearEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageStart: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mileageEnd: jspb.Message.getFieldWithDefault(msg, 5, 0),
    priceStart: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    priceEnd: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    makeModelList: jspb.Message.toObjectList(msg.getMakeModelList(),
    build_protos_searchentity_pb.MakeAndModel.toObject, includeInstance),
    offSet: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sortVehicle: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest;
  return proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageStart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageEnd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceEnd(value);
      break;
    case 8:
      var value = new build_protos_searchentity_pb.MakeAndModel;
      reader.readMessage(value,build_protos_searchentity_pb.MakeAndModel.deserializeBinaryFromReader);
      msg.addMakeModel(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffSet(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 11:
      var value = /** @type {!proto.entity.enum.SortVehicles} */ (reader.readEnum());
      msg.setSortVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYearStart();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getYearEnd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageStart();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMileageEnd();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPriceStart();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPriceEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMakeModelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      build_protos_searchentity_pb.MakeAndModel.serializeBinaryToWriter
    );
  }
  f = message.getOffSet();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getSortVehicle();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year_start = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getYearStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setYearStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 year_end = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getYearEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setYearEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_start = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getMileageStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setMileageStart = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 mileage_end = 5;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getMileageEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setMileageEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double price_start = 6;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getPriceStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setPriceStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double price_end = 7;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getPriceEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setPriceEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated entity.searchentity.MakeAndModel make_model = 8;
 * @return {!Array<!proto.entity.searchentity.MakeAndModel>}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getMakeModelList = function() {
  return /** @type{!Array<!proto.entity.searchentity.MakeAndModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.MakeAndModel, 8));
};


/**
 * @param {!Array<!proto.entity.searchentity.MakeAndModel>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setMakeModelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.entity.searchentity.MakeAndModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.MakeAndModel}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.addMakeModel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.entity.searchentity.MakeAndModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.clearMakeModelList = function() {
  return this.setMakeModelList([]);
};


/**
 * optional int32 off_set = 9;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getOffSet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setOffSet = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 page_size = 10;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional entity.enum.SortVehicles sort_vehicle = 11;
 * @return {!proto.entity.enum.SortVehicles}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.getSortVehicle = function() {
  return /** @type {!proto.entity.enum.SortVehicles} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.entity.enum.SortVehicles} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesRequest.prototype.setSortVehicle = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.repeatedFields_ = [1,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    build_protos_searchentity_pb.Vehicle.toObject, includeInstance),
    yearStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    yearEnd: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mileageStart: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mileageEnd: jspb.Message.getFieldWithDefault(msg, 5, 0),
    priceStart: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    priceEnd: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalSize: jspb.Message.getFieldWithDefault(msg, 8, 0),
    makeList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    modelList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse;
  return proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_searchentity_pb.Vehicle;
      reader.readMessage(value,build_protos_searchentity_pb.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageStart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageEnd(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceEnd(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addMake(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_searchentity_pb.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getYearStart();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getYearEnd();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMileageStart();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMileageEnd();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPriceStart();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPriceEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getMakeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getModelList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * repeated entity.searchentity.Vehicle vehicles = 1;
 * @return {!Array<!proto.entity.searchentity.Vehicle>}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.entity.searchentity.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_searchentity_pb.Vehicle, 1));
};


/**
 * @param {!Array<!proto.entity.searchentity.Vehicle>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.searchentity.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.searchentity.Vehicle}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.searchentity.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};


/**
 * optional int32 year_start = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getYearStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setYearStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 year_end = 3;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getYearEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setYearEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 mileage_start = 4;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getMileageStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setMileageStart = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 mileage_end = 5;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getMileageEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setMileageEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double price_start = 6;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getPriceStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setPriceStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double price_end = 7;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getPriceEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setPriceEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 total_size = 8;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated string make = 9;
 * @return {!Array<string>}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getMakeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setMakeList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.addMake = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.clearMakeList = function() {
  return this.setMakeList([]);
};


/**
 * repeated string model = 10;
 * @return {!Array<string>}
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.getModelList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.setModelList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.addModel = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealerSearchVehiclesResponse.prototype.clearModelList = function() {
  return this.setModelList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest;
  return proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse;
  return proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarfaxCredentialsResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealershipId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest;
  return proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string dealership_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest} returns this
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoRequest.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse;
  return proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteDealershipLogoResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteAuctionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteAuctionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auctionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteAuctionRequest}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteAuctionRequest;
  return proto.com.mintlist.adminapiv1.DeleteAuctionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteAuctionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteAuctionRequest}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteAuctionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteAuctionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auction_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteAuctionRequest} returns this
 */
proto.com.mintlist.adminapiv1.DeleteAuctionRequest.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteAuctionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteAuctionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteAuctionResponse}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteAuctionResponse;
  return proto.com.mintlist.adminapiv1.DeleteAuctionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteAuctionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteAuctionResponse}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteAuctionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteAuctionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteAuctionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    floorPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest;
  return proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloorPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFloorPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double floor_price = 2;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.prototype.getFloorPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceRequest.prototype.setFloorPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse;
  return proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateFloorPriceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest;
  return proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    referrersList: jspb.Message.toObjectList(msg.getReferrersList(),
    build_protos_entities_pb.DealershipReferrer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse;
  return proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.DealershipReferrer;
      reader.readMessage(value,build_protos_entities_pb.DealershipReferrer.deserializeBinaryFromReader);
      msg.addReferrers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferrersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_entities_pb.DealershipReferrer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.entities.DealershipReferrer referrers = 1;
 * @return {!Array<!proto.entity.entities.DealershipReferrer>}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.prototype.getReferrersList = function() {
  return /** @type{!Array<!proto.entity.entities.DealershipReferrer>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_entities_pb.DealershipReferrer, 1));
};


/**
 * @param {!Array<!proto.entity.entities.DealershipReferrer>} value
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.prototype.setReferrersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.DealershipReferrer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.DealershipReferrer}
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.prototype.addReferrers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.DealershipReferrer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListDealershipReferrersResponse.prototype.clearReferrersList = function() {
  return this.setReferrersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    referrerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest;
  return proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferrerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string referrer_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.prototype.getReferrerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdRequest.prototype.setReferrerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    referrer: (f = msg.getReferrer()) && build_protos_entities_pb.DealershipReferrer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse;
  return proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.DealershipReferrer;
      reader.readMessage(value,build_protos_entities_pb.DealershipReferrer.deserializeBinaryFromReader);
      msg.setReferrer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferrer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.DealershipReferrer.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.DealershipReferrer referrer = 1;
 * @return {?proto.entity.entities.DealershipReferrer}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.prototype.getReferrer = function() {
  return /** @type{?proto.entity.entities.DealershipReferrer} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.DealershipReferrer, 1));
};


/**
 * @param {?proto.entity.entities.DealershipReferrer|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.prototype.setReferrer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.prototype.clearReferrer = function() {
  return this.setReferrer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetDealershipReferrerByIdResponse.prototype.hasReferrer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest;
  return proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest} returns this
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse;
  return proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse} returns this
 */
proto.com.mintlist.adminapiv1.ResetDealerAccountPasswordResponse.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAutoGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAutoGroupsRequest}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAutoGroupsRequest;
  return proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAutoGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAutoGroupsRequest}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAutoGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoGroupsList: jspb.Message.toObjectList(msg.getAutoGroupsList(),
    build_protos_dealer_pb.AutoGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListAutoGroupsResponse;
  return proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.AutoGroup;
      reader.readMessage(value,build_protos_dealer_pb.AutoGroup.deserializeBinaryFromReader);
      msg.addAutoGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_dealer_pb.AutoGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.dealer.AutoGroup auto_groups = 1;
 * @return {!Array<!proto.entity.dealer.AutoGroup>}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.prototype.getAutoGroupsList = function() {
  return /** @type{!Array<!proto.entity.dealer.AutoGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_dealer_pb.AutoGroup, 1));
};


/**
 * @param {!Array<!proto.entity.dealer.AutoGroup>} value
 * @return {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.prototype.setAutoGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.dealer.AutoGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.dealer.AutoGroup}
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.prototype.addAutoGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.dealer.AutoGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListAutoGroupsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListAutoGroupsResponse.prototype.clearAutoGroupsList = function() {
  return this.setAutoGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoGroup: (f = msg.getAutoGroup()) && build_protos_dealer_pb.AutoGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateAutoGroupRequest;
  return proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.AutoGroup;
      reader.readMessage(value,build_protos_dealer_pb.AutoGroup.deserializeBinaryFromReader);
      msg.setAutoGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealer_pb.AutoGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealer.AutoGroup auto_group = 1;
 * @return {?proto.entity.dealer.AutoGroup}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.prototype.getAutoGroup = function() {
  return /** @type{?proto.entity.dealer.AutoGroup} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.AutoGroup, 1));
};


/**
 * @param {?proto.entity.dealer.AutoGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.prototype.setAutoGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.prototype.clearAutoGroup = function() {
  return this.setAutoGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupRequest.prototype.hasAutoGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoGroup: (f = msg.getAutoGroup()) && build_protos_dealer_pb.AutoGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateAutoGroupResponse;
  return proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.AutoGroup;
      reader.readMessage(value,build_protos_dealer_pb.AutoGroup.deserializeBinaryFromReader);
      msg.setAutoGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealer_pb.AutoGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealer.AutoGroup auto_group = 1;
 * @return {?proto.entity.dealer.AutoGroup}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.prototype.getAutoGroup = function() {
  return /** @type{?proto.entity.dealer.AutoGroup} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.AutoGroup, 1));
};


/**
 * @param {?proto.entity.dealer.AutoGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.prototype.setAutoGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateAutoGroupResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.prototype.clearAutoGroup = function() {
  return this.setAutoGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateAutoGroupResponse.prototype.hasAutoGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoGroup: (f = msg.getAutoGroup()) && build_protos_dealer_pb.AutoGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest;
  return proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.AutoGroup;
      reader.readMessage(value,build_protos_dealer_pb.AutoGroup.deserializeBinaryFromReader);
      msg.setAutoGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealer_pb.AutoGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealer.AutoGroup auto_group = 1;
 * @return {?proto.entity.dealer.AutoGroup}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.prototype.getAutoGroup = function() {
  return /** @type{?proto.entity.dealer.AutoGroup} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.AutoGroup, 1));
};


/**
 * @param {?proto.entity.dealer.AutoGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest} returns this
*/
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.prototype.setAutoGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.prototype.clearAutoGroup = function() {
  return this.setAutoGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupRequest.prototype.hasAutoGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoGroup: (f = msg.getAutoGroup()) && build_protos_dealer_pb.AutoGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse;
  return proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_dealer_pb.AutoGroup;
      reader.readMessage(value,build_protos_dealer_pb.AutoGroup.deserializeBinaryFromReader);
      msg.setAutoGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_dealer_pb.AutoGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.dealer.AutoGroup auto_group = 1;
 * @return {?proto.entity.dealer.AutoGroup}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.prototype.getAutoGroup = function() {
  return /** @type{?proto.entity.dealer.AutoGroup} */ (
    jspb.Message.getWrapperField(this, build_protos_dealer_pb.AutoGroup, 1));
};


/**
 * @param {?proto.entity.dealer.AutoGroup|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.prototype.setAutoGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.prototype.clearAutoGroup = function() {
  return this.setAutoGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateAutoGroupResponse.prototype.hasAutoGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateFlagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateFlagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flag: (f = msg.getFlag()) && build_protos_entities_pb.Flag.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagRequest}
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateFlagRequest;
  return proto.com.mintlist.adminapiv1.CreateFlagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateFlagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagRequest}
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.Flag;
      reader.readMessage(value,build_protos_entities_pb.Flag.deserializeBinaryFromReader);
      msg.setFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateFlagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateFlagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.Flag.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.Flag flag = 1;
 * @return {?proto.entity.entities.Flag}
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.prototype.getFlag = function() {
  return /** @type{?proto.entity.entities.Flag} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Flag, 1));
};


/**
 * @param {?proto.entity.entities.Flag|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagRequest} returns this
*/
proto.com.mintlist.adminapiv1.CreateFlagRequest.prototype.setFlag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagRequest} returns this
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.prototype.clearFlag = function() {
  return this.setFlag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateFlagRequest.prototype.hasFlag = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CreateFlagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CreateFlagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flag: (f = msg.getFlag()) && build_protos_entities_pb.Flag.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagResponse}
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CreateFlagResponse;
  return proto.com.mintlist.adminapiv1.CreateFlagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CreateFlagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagResponse}
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.Flag;
      reader.readMessage(value,build_protos_entities_pb.Flag.deserializeBinaryFromReader);
      msg.setFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CreateFlagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CreateFlagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.Flag.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.Flag flag = 1;
 * @return {?proto.entity.entities.Flag}
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.prototype.getFlag = function() {
  return /** @type{?proto.entity.entities.Flag} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Flag, 1));
};


/**
 * @param {?proto.entity.entities.Flag|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagResponse} returns this
*/
proto.com.mintlist.adminapiv1.CreateFlagResponse.prototype.setFlag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.CreateFlagResponse} returns this
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.prototype.clearFlag = function() {
  return this.setFlag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CreateFlagResponse.prototype.hasFlag = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteFlagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteFlagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flagId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagRequest}
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteFlagRequest;
  return proto.com.mintlist.adminapiv1.DeleteFlagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteFlagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagRequest}
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteFlagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteFlagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlagId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string flag_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.prototype.getFlagId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagRequest} returns this
 */
proto.com.mintlist.adminapiv1.DeleteFlagRequest.prototype.setFlagId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.DeleteFlagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.DeleteFlagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flag: (f = msg.getFlag()) && build_protos_entities_pb.Flag.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagResponse}
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.DeleteFlagResponse;
  return proto.com.mintlist.adminapiv1.DeleteFlagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.DeleteFlagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagResponse}
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.Flag;
      reader.readMessage(value,build_protos_entities_pb.Flag.deserializeBinaryFromReader);
      msg.setFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.DeleteFlagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.DeleteFlagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.Flag.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.Flag flag = 1;
 * @return {?proto.entity.entities.Flag}
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.prototype.getFlag = function() {
  return /** @type{?proto.entity.entities.Flag} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.Flag, 1));
};


/**
 * @param {?proto.entity.entities.Flag|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagResponse} returns this
*/
proto.com.mintlist.adminapiv1.DeleteFlagResponse.prototype.setFlag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.DeleteFlagResponse} returns this
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.prototype.clearFlag = function() {
  return this.setFlag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.DeleteFlagResponse.prototype.hasFlag = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest;
  return proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest} returns this
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse;
  return proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.CountFlagsByVehicleIdResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest;
  return proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest} returns this
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isflagged: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse;
  return proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsflagged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsflagged();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool isFlagged = 1;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.prototype.getIsflagged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse} returns this
 */
proto.com.mintlist.adminapiv1.CheckIsFlaggedResponse.prototype.setIsflagged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && proto.com.mintlist.adminapiv1.Pagination.toObject(includeInstance, f),
    marketplace: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest;
  return proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Pagination;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Pagination pagination = 1;
 * @return {?proto.com.mintlist.adminapiv1.Pagination}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.getPagination = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Pagination, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Pagination|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest} returns this
*/
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional entity.enum.Marketplace marketplace = 2;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicles: (f = msg.getVehicles()) && proto.com.mintlist.adminapiv1.Vehicles.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse;
  return proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.Vehicles;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.Vehicles.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicles vehicles = 1;
 * @return {?proto.com.mintlist.adminapiv1.Vehicles}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.prototype.getVehicles = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.Vehicles, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.Vehicles|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListFlaggedVehiclesResponse.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListMarketplacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListMarketplacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListMarketplacesRequest}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListMarketplacesRequest;
  return proto.com.mintlist.adminapiv1.ListMarketplacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListMarketplacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListMarketplacesRequest}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListMarketplacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListMarketplacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListMarketplacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListMarketplacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplacesList: jspb.Message.toObjectList(msg.getMarketplacesList(),
    build_protos_entities_pb.Marketplace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListMarketplacesResponse;
  return proto.com.mintlist.adminapiv1.ListMarketplacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.Marketplace;
      reader.readMessage(value,build_protos_entities_pb.Marketplace.deserializeBinaryFromReader);
      msg.addMarketplaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListMarketplacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_entities_pb.Marketplace.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.entities.Marketplace marketplaces = 1;
 * @return {!Array<!proto.entity.entities.Marketplace>}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.prototype.getMarketplacesList = function() {
  return /** @type{!Array<!proto.entity.entities.Marketplace>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_entities_pb.Marketplace, 1));
};


/**
 * @param {!Array<!proto.entity.entities.Marketplace>} value
 * @return {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.prototype.setMarketplacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.entities.Marketplace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.entities.Marketplace}
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.prototype.addMarketplaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.entities.Marketplace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListMarketplacesResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListMarketplacesResponse.prototype.clearMarketplacesList = function() {
  return this.setMarketplacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest;
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional entity.enum.Marketplace marketplace = 1;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questions: (f = msg.getQuestions()) && build_protos_mlapiv1entity_pb.CarInspectionQuestions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse;
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_mlapiv1entity_pb.CarInspectionQuestions;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.CarInspectionQuestions.deserializeBinaryFromReader);
      msg.setQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestions();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_mlapiv1entity_pb.CarInspectionQuestions.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.mlapiv1entity.CarInspectionQuestions questions = 1;
 * @return {?proto.entity.mlapiv1entity.CarInspectionQuestions}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.prototype.getQuestions = function() {
  return /** @type{?proto.entity.mlapiv1entity.CarInspectionQuestions} */ (
    jspb.Message.getWrapperField(this, build_protos_mlapiv1entity_pb.CarInspectionQuestions, 1));
};


/**
 * @param {?proto.entity.mlapiv1entity.CarInspectionQuestions|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.prototype.setQuestions = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.prototype.clearQuestions = function() {
  return this.setQuestions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionWithoutInspectionResponse.prototype.hasQuestions = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest;
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional entity.enum.Marketplace marketplace = 1;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string question_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest} returns this
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionOptionsList: jspb.Message.toObjectList(msg.getQuestionOptionsList(),
    build_protos_mlapiv1entity_pb.QuestionOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse;
  return proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_mlapiv1entity_pb.QuestionOption;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.QuestionOption.deserializeBinaryFromReader);
      msg.addQuestionOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      build_protos_mlapiv1entity_pb.QuestionOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated entity.mlapiv1entity.QuestionOption question_options = 1;
 * @return {!Array<!proto.entity.mlapiv1entity.QuestionOption>}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.prototype.getQuestionOptionsList = function() {
  return /** @type{!Array<!proto.entity.mlapiv1entity.QuestionOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, build_protos_mlapiv1entity_pb.QuestionOption, 1));
};


/**
 * @param {!Array<!proto.entity.mlapiv1entity.QuestionOption>} value
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse} returns this
*/
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.prototype.setQuestionOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.mlapiv1entity.QuestionOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.mlapiv1entity.QuestionOption}
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.prototype.addQuestionOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.mlapiv1entity.QuestionOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse} returns this
 */
proto.com.mintlist.adminapiv1.ListCarInspectionQuestionOptionsResponse.prototype.clearQuestionOptionsList = function() {
  return this.setQuestionOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionOption: (f = msg.getQuestionOption()) && build_protos_mlapiv1entity_pb.QuestionOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest;
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    case 3:
      var value = new build_protos_mlapiv1entity_pb.QuestionOption;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.QuestionOption.deserializeBinaryFromReader);
      msg.setQuestionOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionOption();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      build_protos_mlapiv1entity_pb.QuestionOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.enum.Marketplace marketplace = 1;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string question_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.mlapiv1entity.QuestionOption question_option = 3;
 * @return {?proto.entity.mlapiv1entity.QuestionOption}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.getQuestionOption = function() {
  return /** @type{?proto.entity.mlapiv1entity.QuestionOption} */ (
    jspb.Message.getWrapperField(this, build_protos_mlapiv1entity_pb.QuestionOption, 3));
};


/**
 * @param {?proto.entity.mlapiv1entity.QuestionOption|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} returns this
*/
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.setQuestionOption = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.clearQuestionOption = function() {
  return this.setQuestionOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionRequest.prototype.hasQuestionOption = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionOption: (f = msg.getQuestionOption()) && build_protos_mlapiv1entity_pb.QuestionOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse;
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_mlapiv1entity_pb.QuestionOption;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.QuestionOption.deserializeBinaryFromReader);
      msg.setQuestionOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionOption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_mlapiv1entity_pb.QuestionOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.mlapiv1entity.QuestionOption question_option = 1;
 * @return {?proto.entity.mlapiv1entity.QuestionOption}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.prototype.getQuestionOption = function() {
  return /** @type{?proto.entity.mlapiv1entity.QuestionOption} */ (
    jspb.Message.getWrapperField(this, build_protos_mlapiv1entity_pb.QuestionOption, 1));
};


/**
 * @param {?proto.entity.mlapiv1entity.QuestionOption|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.prototype.setQuestionOption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.prototype.clearQuestionOption = function() {
  return this.setQuestionOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionOptionResponse.prototype.hasQuestionOption = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    question: (f = msg.getQuestion()) && build_protos_mlapiv1entity_pb.CarInspectionQuestion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest;
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.entity.enum.Marketplace} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = new build_protos_mlapiv1entity_pb.CarInspectionQuestion;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_mlapiv1entity_pb.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.enum.Marketplace marketplace = 1;
 * @return {!proto.entity.enum.Marketplace}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.getMarketplace = function() {
  return /** @type {!proto.entity.enum.Marketplace} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.entity.enum.Marketplace} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional entity.mlapiv1entity.CarInspectionQuestion question = 2;
 * @return {?proto.entity.mlapiv1entity.CarInspectionQuestion}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.getQuestion = function() {
  return /** @type{?proto.entity.mlapiv1entity.CarInspectionQuestion} */ (
    jspb.Message.getWrapperField(this, build_protos_mlapiv1entity_pb.CarInspectionQuestion, 2));
};


/**
 * @param {?proto.entity.mlapiv1entity.CarInspectionQuestion|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest} returns this
*/
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && build_protos_mlapiv1entity_pb.CarInspectionQuestion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse;
  return proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_mlapiv1entity_pb.CarInspectionQuestion;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_mlapiv1entity_pb.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.mlapiv1entity.CarInspectionQuestion question = 1;
 * @return {?proto.entity.mlapiv1entity.CarInspectionQuestion}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.prototype.getQuestion = function() {
  return /** @type{?proto.entity.mlapiv1entity.CarInspectionQuestion} */ (
    jspb.Message.getWrapperField(this, build_protos_mlapiv1entity_pb.CarInspectionQuestion, 1));
};


/**
 * @param {?proto.entity.mlapiv1entity.CarInspectionQuestion|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse} returns this
*/
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse} returns this
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.UpdateCarInspectionQuestionWithoutInspectionResponse.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest;
  return proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest} returns this
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclePricing: (f = msg.getVehiclePricing()) && build_protos_entities_pb.VehiclePricing.toObject(includeInstance, f),
    reservePrice: (f = msg.getReservePrice()) && build_protos_mlapiv1entity_pb.ReservePrice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse;
  return proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new build_protos_entities_pb.VehiclePricing;
      reader.readMessage(value,build_protos_entities_pb.VehiclePricing.deserializeBinaryFromReader);
      msg.setVehiclePricing(value);
      break;
    case 2:
      var value = new build_protos_mlapiv1entity_pb.ReservePrice;
      reader.readMessage(value,build_protos_mlapiv1entity_pb.ReservePrice.deserializeBinaryFromReader);
      msg.setReservePrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclePricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      build_protos_entities_pb.VehiclePricing.serializeBinaryToWriter
    );
  }
  f = message.getReservePrice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      build_protos_mlapiv1entity_pb.ReservePrice.serializeBinaryToWriter
    );
  }
};


/**
 * optional entity.entities.VehiclePricing vehicle_pricing = 1;
 * @return {?proto.entity.entities.VehiclePricing}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.getVehiclePricing = function() {
  return /** @type{?proto.entity.entities.VehiclePricing} */ (
    jspb.Message.getWrapperField(this, build_protos_entities_pb.VehiclePricing, 1));
};


/**
 * @param {?proto.entity.entities.VehiclePricing|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.setVehiclePricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.clearVehiclePricing = function() {
  return this.setVehiclePricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.hasVehiclePricing = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional entity.mlapiv1entity.ReservePrice reserve_price = 2;
 * @return {?proto.entity.mlapiv1entity.ReservePrice}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.getReservePrice = function() {
  return /** @type{?proto.entity.mlapiv1entity.ReservePrice} */ (
    jspb.Message.getWrapperField(this, build_protos_mlapiv1entity_pb.ReservePrice, 2));
};


/**
 * @param {?proto.entity.mlapiv1entity.ReservePrice|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} returns this
*/
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.setReservePrice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse} returns this
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.clearReservePrice = function() {
  return this.setReservePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.GetVehiclePricingByVehicleIdResponse.prototype.hasReservePrice = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.mintlist.adminapiv1.User.toObject(includeInstance, f),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    make: jspb.Message.getFieldWithDefault(msg, 4, ""),
    model: jspb.Message.getFieldWithDefault(msg, 5, ""),
    year: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trim: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mileage: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 9, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 11, 0),
    dealerId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest;
  return proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.mintlist.adminapiv1.User;
      reader.readMessage(value,proto.com.mintlist.adminapiv1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrim(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileage(value);
      break;
    case 9:
      var value = /** @type {!proto.com.mintlist.adminapiv1.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEffectiveDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.mintlist.adminapiv1.User.serializeBinaryToWriter
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTrim();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEffectiveDate();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.com.mintlist.adminapiv1.User}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getUser = function() {
  return /** @type{?proto.com.mintlist.adminapiv1.User} */ (
    jspb.Message.getWrapperField(this, proto.com.mintlist.adminapiv1.User, 1));
};


/**
 * @param {?proto.com.mintlist.adminapiv1.User|undefined} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
*/
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vin = 3;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string make = 4;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 year = 6;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string trim = 7;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getTrim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setTrim = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 mileage = 8;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional MileageUOM mileage_uom = 9;
 * @return {!proto.com.mintlist.adminapiv1.MileageUOM}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getMileageUom = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.MileageUOM} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional double price = 10;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional Currency currency = 11;
 * @return {!proto.com.mintlist.adminapiv1.Currency}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getCurrency = function() {
  return /** @type {!proto.com.mintlist.adminapiv1.Currency} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.com.mintlist.adminapiv1.Currency} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string dealer_id = 12;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 effective_date = 13;
 * @return {number}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.getEffectiveDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleRequest.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse;
  return proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse} returns this
 */
proto.com.mintlist.adminapiv1.ConsumerBuyAddNonListedSaleResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ImpersonateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    impersonatorEmail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    impersonatedEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userOrigin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isReset: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ImpersonateUserRequest;
  return proto.com.mintlist.adminapiv1.ImpersonateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpersonatorEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpersonatedEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.enum.UserOrigin} */ (reader.readEnum());
      msg.setUserOrigin(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ImpersonateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImpersonatorEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImpersonatedEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsReset();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string impersonator_email = 1;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.getImpersonatorEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.setImpersonatorEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string impersonated_email = 2;
 * @return {string}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.getImpersonatedEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.setImpersonatedEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional entity.enum.UserOrigin user_origin = 3;
 * @return {!proto.entity.enum.UserOrigin}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.getUserOrigin = function() {
  return /** @type {!proto.entity.enum.UserOrigin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.enum.UserOrigin} value
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.setUserOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_reset = 4;
 * @return {boolean}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.getIsReset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserRequest} returns this
 */
proto.com.mintlist.adminapiv1.ImpersonateUserRequest.prototype.setIsReset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.mintlist.adminapiv1.ImpersonateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.mintlist.adminapiv1.ImpersonateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserResponse}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.mintlist.adminapiv1.ImpersonateUserResponse;
  return proto.com.mintlist.adminapiv1.ImpersonateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.mintlist.adminapiv1.ImpersonateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.mintlist.adminapiv1.ImpersonateUserResponse}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.mintlist.adminapiv1.ImpersonateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.mintlist.adminapiv1.ImpersonateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.mintlist.adminapiv1.ImpersonateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UserRole = {
  USER_ROLE_INVALID: 0,
  USER_ROLE_DEALER: 1,
  USER_ROLE_DEALER_ADMIN: 2,
  USER_ROLE_SERVICE: 3,
  USER_ROLE_ADMIN: 4,
  USER_ROLE_CONSUMER: 5
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.AuctionClass = {
  AUCTION_CLASS_INVALID: 0,
  AUCTION_CLASS_REGULAR: 1,
  AUCTION_CLASS_HIGH_LINE: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.AuctionStatus = {
  AUCTION_STATUS_INVALID: 0,
  AUCTION_STATUS_ACTIVE: 1,
  AUCTION_STATUS_COMPLETE: 2,
  AUCTION_STATUS_SCHEDULED: 3,
  AUCTION_STATUS_REMOVED: 4,
  AUCTION_STATUS_ACCEPTED: 5,
  AUCTION_STATUS_REJECTED: 6,
  AUCTION_STATUS_NONE: 7,
  AUCTION_STATUS_RELIST_READY: 8,
  AUCTION_STATUS_READY: 9
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.OfferStatus = {
  OFFER_STATUS_INVALID: 0,
  OFFER_STATUS_PENDING: 1,
  OFFER_STATUS_ACCEPTED: 2,
  OFFER_STATUS_REJECTED: 3
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.UserOrigin = {
  USER_ORIGIN_INVALID: 0,
  USER_ORIGIN_DEALER: 1,
  USER_ORIGIN_CONSUMER: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.Currency = {
  CURRENCY_INVALID: 0,
  CURRENCY_CAD: 1,
  CURRENCY_USD: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ScheduledJobType = {
  SCHEDULED_JOB_TYPE_INVALID: 0,
  SCHEDULED_JOB_TYPE_AUCTION_NOTIFICATION: 1,
  SCHEDULED_JOB_TYPE_AUCTION_START: 2,
  SCHEDULED_JOB_TYPE_AUCTION_END: 3,
  SCHEDULED_JOB_TYPE_DEALER_HOUR_BEFORE_START: 4,
  SCHEDULED_JOB_TYPE_DEALER_QUARTER_BEFORE_START: 5
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.AdminUserRole = {
  ADMIN_USER_ROLE_INVALID: 0,
  ADMIN_USER_ROLE_ADMIN: 1,
  ADMIN_USER_ROLE_SERVICE: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.ImageSource = {
  IMAGE_SOURCE_INVALID: 0,
  IMAGE_SOURCE_PAVE: 1,
  IMAGE_SOURCE_USER: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.MileageUOM = {
  MILEAGE_UOM_INVALID: 0,
  MILEAGE_UOM_MILES: 1,
  MILEAGE_UOM_KILOMETER: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.VehicleStatus = {
  VEHICLE_STATUS_INVALID: 0,
  VEHICLE_STATUS_INSPECTION_CREATED: 1,
  VEHICLE_STATUS_INSPECTION_STARTED: 2,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE: 3,
  VEHICLE_STATUS_INSPECTION_COMPLETED: 4,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS: 11,
  VEHICLE_STATUS_AVAILABLE: 13,
  VEHICLE_STATUS_PENDING_SALE: 14,
  VEHICLE_STATUS_SOLD: 15,
  VEHICLE_STATUS_INSPECTION_REPORT_PROCESSING: 16,
  VEHICLE_STATUS_CONFIRMED: 17,
  VEHICLE_STATUS_DEACTIVATED: 18,
  VEHICLE_STATUS_CREATED: 19,
  VEHICLE_STATUS_LISTING_PAYMENT_RECEIVED: 20
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.VehicleOrigin = {
  VEHICLE_ORIGIN_INVALID: 0,
  VEHICLE_ORIGIN_CONSUMER: 1,
  VEHICLE_ORIGIN_DEALER: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.VehicleClass = {
  VEHICLE_CLASS_INVALID: 0,
  VEHICLE_CLASS_REGULAR: 1,
  VEHICLE_CLASS_HIGH_LINE: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.InspectionStatus = {
  INSPECTION_STATUS_INVALID: 0,
  INSPECTION_STATUS_INCOMPLETE: 1,
  INSPECTION_STATUS_COMPLETE: 2
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.QuestionType = {
  QUESTION_TYPE_INVALID: 0,
  QUESTION_TYPE_SINGLE_CHOICE: 1,
  QUESTION_TYPE_MULTIPLE_CHOICE: 2,
  QUESTION_TYPE_MULTIPLE_CHOICE_WITH_DETAILS: 3,
  QUESTION_TYPE_SLIDER: 4
};

/**
 * @enum {number}
 */
proto.com.mintlist.adminapiv1.AssetOrigin = {
  ASSET_ORIGIN_INVALID: 0,
  ASSET_ORIGIN_CONSUMER: 1,
  ASSET_ORIGIN_DEALER: 2
};

goog.object.extend(exports, proto.com.mintlist.adminapiv1);
