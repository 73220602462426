import React from 'react';

import backgroundImgBottom from '../../assets/images/green-blob-2.svg';
import styles from '../../scss/LandingPage.module.scss';

function LandingPage() {
  return (
    <div className={styles.landingContainer} style={{ backgroundImage: `url(${backgroundImgBottom})` }}>
      <h2>Welcome</h2>
      <p>Please sign in with your MintList email via Google</p>
    </div>
  );
}

export default LandingPage;
