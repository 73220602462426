import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import CreateOffer from '../auction/CreateOffer';
import BaseButtonOutline from '../base/BaseButtonOutline';

function AddOffer({ vehicleId }: { vehicleId: string }) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const createOffer = async () => {
    openUtilityDrawer(<CreateOffer vehicleId={vehicleId} />);
  };

  return <BaseButtonOutline small display="Create Offer" onClick={createOffer} />;
}

export default AddOffer;
