// source: build/protos/vehicle.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var build_protos_common_pb = require('../../build/protos/common_pb.js');
goog.object.extend(proto, build_protos_common_pb);
goog.exportSymbol('proto.entity.vehicle.AuctionGroup', null, global);
goog.exportSymbol('proto.entity.vehicle.AuctionStatus', null, global);
goog.exportSymbol('proto.entity.vehicle.Bid', null, global);
goog.exportSymbol('proto.entity.vehicle.Bids', null, global);
goog.exportSymbol('proto.entity.vehicle.CancelReasonType', null, global);
goog.exportSymbol('proto.entity.vehicle.CarInspectionAnswer', null, global);
goog.exportSymbol('proto.entity.vehicle.CarInspectionAnswers', null, global);
goog.exportSymbol('proto.entity.vehicle.CarInspectionQuestion', null, global);
goog.exportSymbol('proto.entity.vehicle.CarInspectionQuestions', null, global);
goog.exportSymbol('proto.entity.vehicle.CarInspectionSession', null, global);
goog.exportSymbol('proto.entity.vehicle.CarInspectionSessions', null, global);
goog.exportSymbol('proto.entity.vehicle.CarfaxAnnouncement', null, global);
goog.exportSymbol('proto.entity.vehicle.Currency', null, global);
goog.exportSymbol('proto.entity.vehicle.ImageSource', null, global);
goog.exportSymbol('proto.entity.vehicle.InspectionStatus', null, global);
goog.exportSymbol('proto.entity.vehicle.LeaseInformation', null, global);
goog.exportSymbol('proto.entity.vehicle.MileageUOM', null, global);
goog.exportSymbol('proto.entity.vehicle.PriceHistory', null, global);
goog.exportSymbol('proto.entity.vehicle.QuestionOption', null, global);
goog.exportSymbol('proto.entity.vehicle.QuestionType', null, global);
goog.exportSymbol('proto.entity.vehicle.Sale', null, global);
goog.exportSymbol('proto.entity.vehicle.SaleStatus', null, global);
goog.exportSymbol('proto.entity.vehicle.Source', null, global);
goog.exportSymbol('proto.entity.vehicle.Vehicle', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleAvailability', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleCarFaxInfo', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleClass', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleImage', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleLocation', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleOption', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleOrigin', null, global);
goog.exportSymbol('proto.entity.vehicle.VehiclePaveAnnouncement', null, global);
goog.exportSymbol('proto.entity.vehicle.VehiclePaveDisclosure', null, global);
goog.exportSymbol('proto.entity.vehicle.VehicleStatus', null, global);
goog.exportSymbol('proto.entity.vehicle.Vehicles', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.PriceHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.PriceHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.PriceHistory.displayName = 'proto.entity.vehicle.PriceHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.VehicleLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.VehicleLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.VehicleLocation.displayName = 'proto.entity.vehicle.VehicleLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.VehiclePaveDisclosure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.VehiclePaveDisclosure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.VehiclePaveDisclosure.displayName = 'proto.entity.vehicle.VehiclePaveDisclosure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.VehiclePaveAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.VehiclePaveAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.VehiclePaveAnnouncement.displayName = 'proto.entity.vehicle.VehiclePaveAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.VehicleCarFaxInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.VehicleCarFaxInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.VehicleCarFaxInfo.displayName = 'proto.entity.vehicle.VehicleCarFaxInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.Vehicles.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.Vehicles.displayName = 'proto.entity.vehicle.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.VehicleOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.VehicleOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.VehicleOption.displayName = 'proto.entity.vehicle.VehicleOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.Vehicle.displayName = 'proto.entity.vehicle.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.AuctionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.AuctionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.AuctionGroup.displayName = 'proto.entity.vehicle.AuctionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarInspectionSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.CarInspectionSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarInspectionSession.displayName = 'proto.entity.vehicle.CarInspectionSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarInspectionSessions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.CarInspectionSessions.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.CarInspectionSessions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarInspectionSessions.displayName = 'proto.entity.vehicle.CarInspectionSessions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarInspectionAnswers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.CarInspectionAnswers.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.CarInspectionAnswers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarInspectionAnswers.displayName = 'proto.entity.vehicle.CarInspectionAnswers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarInspectionAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.CarInspectionAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarInspectionAnswer.displayName = 'proto.entity.vehicle.CarInspectionAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.QuestionOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.QuestionOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.QuestionOption.displayName = 'proto.entity.vehicle.QuestionOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarInspectionQuestions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.CarInspectionQuestions.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.CarInspectionQuestions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarInspectionQuestions.displayName = 'proto.entity.vehicle.CarInspectionQuestions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarInspectionQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.CarInspectionQuestion.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.CarInspectionQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarInspectionQuestion.displayName = 'proto.entity.vehicle.CarInspectionQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.LeaseInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.LeaseInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.LeaseInformation.displayName = 'proto.entity.vehicle.LeaseInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.CarfaxAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.CarfaxAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.CarfaxAnnouncement.displayName = 'proto.entity.vehicle.CarfaxAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.VehicleImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.VehicleImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.VehicleImage.displayName = 'proto.entity.vehicle.VehicleImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.Bid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.Bid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.Bid.displayName = 'proto.entity.vehicle.Bid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.Bids = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.entity.vehicle.Bids.repeatedFields_, null);
};
goog.inherits(proto.entity.vehicle.Bids, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.Bids.displayName = 'proto.entity.vehicle.Bids';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.entity.vehicle.Sale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.entity.vehicle.Sale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.entity.vehicle.Sale.displayName = 'proto.entity.vehicle.Sale';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.PriceHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.PriceHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.PriceHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.PriceHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.PriceHistory}
 */
proto.entity.vehicle.PriceHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.PriceHistory;
  return proto.entity.vehicle.PriceHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.PriceHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.PriceHistory}
 */
proto.entity.vehicle.PriceHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.vehicle.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.PriceHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.PriceHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.PriceHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.PriceHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.vehicle.PriceHistory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.PriceHistory} returns this
 */
proto.entity.vehicle.PriceHistory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.vehicle.PriceHistory.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.PriceHistory} returns this
 */
proto.entity.vehicle.PriceHistory.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Currency currency = 3;
 * @return {!proto.entity.vehicle.Currency}
 */
proto.entity.vehicle.PriceHistory.prototype.getCurrency = function() {
  return /** @type {!proto.entity.vehicle.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.vehicle.Currency} value
 * @return {!proto.entity.vehicle.PriceHistory} returns this
 */
proto.entity.vehicle.PriceHistory.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string vehicle_id = 4;
 * @return {string}
 */
proto.entity.vehicle.PriceHistory.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.PriceHistory} returns this
 */
proto.entity.vehicle.PriceHistory.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.VehicleLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.VehicleLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.VehicleLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    address1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.VehicleLocation}
 */
proto.entity.vehicle.VehicleLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.VehicleLocation;
  return proto.entity.vehicle.VehicleLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.VehicleLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.VehicleLocation}
 */
proto.entity.vehicle.VehicleLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.VehicleLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.VehicleLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.VehicleLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string address1 = 1;
 * @return {string}
 */
proto.entity.vehicle.VehicleLocation.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address2 = 2;
 * @return {string}
 */
proto.entity.vehicle.VehicleLocation.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.entity.vehicle.VehicleLocation.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.entity.vehicle.VehicleLocation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.entity.vehicle.VehicleLocation.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.entity.vehicle.VehicleLocation.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.entity.vehicle.VehicleLocation.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double latitude = 8;
 * @return {number}
 */
proto.entity.vehicle.VehicleLocation.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.VehicleLocation} returns this
 */
proto.entity.vehicle.VehicleLocation.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.VehiclePaveDisclosure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.VehiclePaveDisclosure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehiclePaveDisclosure.toObject = function(includeInstance, msg) {
  var f, obj = {
    outOfProvince: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    odomFaulty: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    accidentRepair: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    noFactoryWarranty: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasExtendedWarranty: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasProtectionPackages: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    previousRentalLeaseTaxiEmergency: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure}
 */
proto.entity.vehicle.VehiclePaveDisclosure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.VehiclePaveDisclosure;
  return proto.entity.vehicle.VehiclePaveDisclosure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.VehiclePaveDisclosure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure}
 */
proto.entity.vehicle.VehiclePaveDisclosure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutOfProvince(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOdomFaulty(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccidentRepair(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoFactoryWarranty(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasExtendedWarranty(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasProtectionPackages(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPreviousRentalLeaseTaxiEmergency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.VehiclePaveDisclosure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.VehiclePaveDisclosure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehiclePaveDisclosure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutOfProvince();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOdomFaulty();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAccidentRepair();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getNoFactoryWarranty();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasExtendedWarranty();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasProtectionPackages();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPreviousRentalLeaseTaxiEmergency();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional bool out_of_province = 1;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getOutOfProvince = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setOutOfProvince = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool odom_faulty = 2;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getOdomFaulty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setOdomFaulty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool accident_repair = 3;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getAccidentRepair = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setAccidentRepair = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool no_factory_warranty = 4;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getNoFactoryWarranty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setNoFactoryWarranty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_extended_warranty = 5;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getHasExtendedWarranty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setHasExtendedWarranty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_protection_packages = 6;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getHasProtectionPackages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setHasProtectionPackages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool previous_rental_lease_taxi_emergency = 7;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.getPreviousRentalLeaseTaxiEmergency = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveDisclosure} returns this
 */
proto.entity.vehicle.VehiclePaveDisclosure.prototype.setPreviousRentalLeaseTaxiEmergency = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.VehiclePaveAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.VehiclePaveAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehiclePaveAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    titlePresent: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    allKeysPresent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    booksPresent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    discsPresent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    rimsTires: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    otherAnnouncements: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.VehiclePaveAnnouncement;
  return proto.entity.vehicle.VehiclePaveAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.VehiclePaveAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitlePresent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllKeysPresent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBooksPresent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDiscsPresent(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRimsTires(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherAnnouncements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.VehiclePaveAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.VehiclePaveAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehiclePaveAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitlePresent();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllKeysPresent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBooksPresent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDiscsPresent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRimsTires();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOtherAnnouncements();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool title_present = 1;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.getTitlePresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement} returns this
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.setTitlePresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string all_keys_present = 2;
 * @return {string}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.getAllKeysPresent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement} returns this
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.setAllKeysPresent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool books_present = 3;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.getBooksPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement} returns this
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.setBooksPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool discs_present = 4;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.getDiscsPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement} returns this
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.setDiscsPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool rims_tires = 5;
 * @return {boolean}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.getRimsTires = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement} returns this
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.setRimsTires = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string other_announcements = 6;
 * @return {string}
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.getOtherAnnouncements = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehiclePaveAnnouncement} returns this
 */
proto.entity.vehicle.VehiclePaveAnnouncement.prototype.setOtherAnnouncements = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.VehicleCarFaxInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.VehicleCarFaxInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleCarFaxInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isStolen: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isSalvage: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hasUsaHistory: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasFrameDamage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasOpenRecalls: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    recentVhrOrder: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hasRebuiltTitle: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hasBcRegistration: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    hasDeployedAirbag: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isExportedVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isInactiveVehicle: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    potentialVinClone: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    serviceLast60Days: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    inspectionRequired: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasFireOrFloodDamage: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    firstRegistrationDate: jspb.Message.getFieldWithDefault(msg, 16, ""),
    latestRegistrationDate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    sourceSystemReferenceId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    hasInterprovincialHistory: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    hasPoliceReportedAccident: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hasPotentialOdometerProblem: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    currentRegistrationJurisdiction: jspb.Message.getFieldWithDefault(msg, 22, ""),
    hasMultipleCurrentRegistrations: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    ownershipType: jspb.Message.getFieldWithDefault(msg, 24, ""),
    latestDamageDate: jspb.Message.getFieldWithDefault(msg, 25, ""),
    latestOdometerKm: jspb.Message.getFieldWithDefault(msg, 26, 0),
    latestOdometerReadingDate: jspb.Message.getFieldWithDefault(msg, 27, ""),
    hasDamageOverThreshold: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    numberOfReportedOwners: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo}
 */
proto.entity.vehicle.VehicleCarFaxInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.VehicleCarFaxInfo;
  return proto.entity.vehicle.VehicleCarFaxInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.VehicleCarFaxInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo}
 */
proto.entity.vehicle.VehicleCarFaxInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStolen(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSalvage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUsaHistory(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFrameDamage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOpenRecalls(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecentVhrOrder(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRebuiltTitle(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBcRegistration(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeployedAirbag(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExportedVehicle(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInactiveVehicle(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPotentialVinClone(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServiceLast60Days(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionRequired(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFireOrFloodDamage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstRegistrationDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestRegistrationDate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceSystemReferenceId(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInterprovincialHistory(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPoliceReportedAccident(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPotentialOdometerProblem(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentRegistrationJurisdiction(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMultipleCurrentRegistrations(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnershipType(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestDamageDate(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestOdometerKm(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestOdometerReadingDate(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDamageOverThreshold(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfReportedOwners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.VehicleCarFaxInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.VehicleCarFaxInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleCarFaxInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsStolen();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsSalvage();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHasUsaHistory();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasFrameDamage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasOpenRecalls();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRecentVhrOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasRebuiltTitle();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHasBcRegistration();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHasDeployedAirbag();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsExportedVehicle();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsInactiveVehicle();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPotentialVinClone();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getServiceLast60Days();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getInspectionRequired();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasFireOrFloodDamage();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getFirstRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLatestRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSourceSystemReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getHasInterprovincialHistory();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getHasPoliceReportedAccident();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHasPotentialOdometerProblem();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCurrentRegistrationJurisdiction();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getHasMultipleCurrentRegistrations();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getOwnershipType();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getLatestDamageDate();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLatestOdometerKm();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getLatestOdometerReadingDate();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getHasDamageOverThreshold();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getNumberOfReportedOwners();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
};


/**
 * optional bool is_stolen = 1;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getIsStolen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setIsStolen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_salvage = 2;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getIsSalvage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setIsSalvage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool has_usa_history = 3;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasUsaHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasUsaHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_frame_damage = 4;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasFrameDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasFrameDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_open_recalls = 5;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasOpenRecalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasOpenRecalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool recent_vhr_order = 6;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getRecentVhrOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setRecentVhrOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool has_rebuilt_title = 7;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasRebuiltTitle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasRebuiltTitle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool has_bc_registration = 8;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasBcRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasBcRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool has_deployed_airbag = 9;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasDeployedAirbag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasDeployedAirbag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_exported_vehicle = 10;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getIsExportedVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setIsExportedVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_inactive_vehicle = 11;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getIsInactiveVehicle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setIsInactiveVehicle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool potential_vin_clone = 12;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getPotentialVinClone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setPotentialVinClone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool service_last_60_days = 13;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getServiceLast60Days = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setServiceLast60Days = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool inspection_required = 14;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getInspectionRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setInspectionRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool has_fire_or_flood_damage = 15;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasFireOrFloodDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasFireOrFloodDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string first_registration_date = 16;
 * @return {string}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getFirstRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setFirstRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string latest_registration_date = 17;
 * @return {string}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getLatestRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setLatestRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 source_system_reference_id = 18;
 * @return {number}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getSourceSystemReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setSourceSystemReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool has_interprovincial_history = 19;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasInterprovincialHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasInterprovincialHistory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool has_police_reported_accident = 20;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasPoliceReportedAccident = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasPoliceReportedAccident = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool has_potential_odometer_problem = 21;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasPotentialOdometerProblem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasPotentialOdometerProblem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string current_registration_jurisdiction = 22;
 * @return {string}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getCurrentRegistrationJurisdiction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setCurrentRegistrationJurisdiction = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool has_multiple_current_registrations = 23;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasMultipleCurrentRegistrations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasMultipleCurrentRegistrations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional string ownership_type = 24;
 * @return {string}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getOwnershipType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setOwnershipType = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string latest_damage_date = 25;
 * @return {string}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getLatestDamageDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setLatestDamageDate = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int64 latest_odometer_km = 26;
 * @return {number}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getLatestOdometerKm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setLatestOdometerKm = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string latest_odometer_reading_date = 27;
 * @return {string}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getLatestOdometerReadingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setLatestOdometerReadingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional bool has_damage_over_threshold = 28;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getHasDamageOverThreshold = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setHasDamageOverThreshold = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional int32 number_of_reported_owners = 29;
 * @return {number}
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.getNumberOfReportedOwners = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.VehicleCarFaxInfo} returns this
 */
proto.entity.vehicle.VehicleCarFaxInfo.prototype.setNumberOfReportedOwners = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.Vehicles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.entity.vehicle.Vehicle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.Vehicles}
 */
proto.entity.vehicle.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.Vehicles;
  return proto.entity.vehicle.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.Vehicles}
 */
proto.entity.vehicle.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.vehicle.Vehicle;
      reader.readMessage(value,proto.entity.vehicle.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.vehicle.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vehicle vehicles = 1;
 * @return {!Array<!proto.entity.vehicle.Vehicle>}
 */
proto.entity.vehicle.Vehicles.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.entity.vehicle.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.Vehicle, 1));
};


/**
 * @param {!Array<!proto.entity.vehicle.Vehicle>} value
 * @return {!proto.entity.vehicle.Vehicles} returns this
*/
proto.entity.vehicle.Vehicles.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.vehicle.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.Vehicle}
 */
proto.entity.vehicle.Vehicles.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.vehicle.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicles} returns this
 */
proto.entity.vehicle.Vehicles.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.VehicleOption.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.VehicleOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.VehicleOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    highValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.VehicleOption}
 */
proto.entity.vehicle.VehicleOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.VehicleOption;
  return proto.entity.vehicle.VehicleOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.VehicleOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.VehicleOption}
 */
proto.entity.vehicle.VehicleOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHighValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.VehicleOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.VehicleOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.VehicleOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHighValue();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.vehicle.VehicleOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleOption} returns this
 */
proto.entity.vehicle.VehicleOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.entity.vehicle.VehicleOption.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleOption} returns this
 */
proto.entity.vehicle.VehicleOption.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.entity.vehicle.VehicleOption.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleOption} returns this
 */
proto.entity.vehicle.VehicleOption.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool high_value = 4;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleOption.prototype.getHighValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleOption} returns this
 */
proto.entity.vehicle.VehicleOption.prototype.setHighValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.Vehicle.repeatedFields_ = [22,26,34,35,36,44,48];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    make: jspb.Message.getFieldWithDefault(msg, 6, ""),
    model: jspb.Message.getFieldWithDefault(msg, 7, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mileage: jspb.Message.getFieldWithDefault(msg, 10, 0),
    interiorColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    interiorType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    exteriorColor: jspb.Message.getFieldWithDefault(msg, 13, ""),
    carfaxUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    engine: jspb.Message.getFieldWithDefault(msg, 15, ""),
    engineSize: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fuelType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    transmission: jspb.Message.getFieldWithDefault(msg, 18, ""),
    driveTrain: jspb.Message.getFieldWithDefault(msg, 19, ""),
    inspectionId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    paveReportUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    vehicleOptionsList: jspb.Message.toObjectList(msg.getVehicleOptionsList(),
    proto.entity.vehicle.VehicleOption.toObject, includeInstance),
    location: (f = msg.getLocation()) && proto.entity.vehicle.VehicleLocation.toObject(includeInstance, f),
    vehicleCarfaxInfo: (f = msg.getVehicleCarfaxInfo()) && proto.entity.vehicle.VehicleCarFaxInfo.toObject(includeInstance, f),
    isLowMileage: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.entity.vehicle.VehicleImage.toObject, includeInstance),
    mlInspectionUrl: jspb.Message.getFieldWithDefault(msg, 27, ""),
    vehicleClass: jspb.Message.getFieldWithDefault(msg, 28, 0),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    autoGradeRating: jspb.Message.getFieldWithDefault(msg, 30, ""),
    vehicleOrigin: jspb.Message.getFieldWithDefault(msg, 31, 0),
    vehiclePaveDisclosure: (f = msg.getVehiclePaveDisclosure()) && proto.entity.vehicle.VehiclePaveDisclosure.toObject(includeInstance, f),
    vehiclePaveAnnouncement: (f = msg.getVehiclePaveAnnouncement()) && proto.entity.vehicle.VehiclePaveAnnouncement.toObject(includeInstance, f),
    carInspectionQuestionsList: jspb.Message.toObjectList(msg.getCarInspectionQuestionsList(),
    proto.entity.vehicle.CarInspectionQuestion.toObject, includeInstance),
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.entity.vehicle.Bid.toObject, includeInstance),
    auctionGroupsList: jspb.Message.toObjectList(msg.getAuctionGroupsList(),
    proto.entity.vehicle.AuctionGroup.toObject, includeInstance),
    paveSession: jspb.Message.getFieldWithDefault(msg, 37, ""),
    paveReportHtml: jspb.Message.getFieldWithDefault(msg, 38, ""),
    trimLevel: jspb.Message.getFieldWithDefault(msg, 39, ""),
    userGivenName: jspb.Message.getFieldWithDefault(msg, 40, ""),
    userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 41, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 42, ""),
    carfaxLienUrl: jspb.Message.getFieldWithDefault(msg, 43, ""),
    carfaxAnnouncementsList: jspb.Message.toObjectList(msg.getCarfaxAnnouncementsList(),
    proto.entity.vehicle.CarfaxAnnouncement.toObject, includeInstance),
    mileageUom: jspb.Message.getFieldWithDefault(msg, 45, 0),
    leaseInformation: (f = msg.getLeaseInformation()) && proto.entity.vehicle.LeaseInformation.toObject(includeInstance, f),
    dealerId: jspb.Message.getFieldWithDefault(msg, 47, ""),
    priceHistoryList: jspb.Message.toObjectList(msg.getPriceHistoryList(),
    proto.entity.vehicle.PriceHistory.toObject, includeInstance),
    source: jspb.Message.getFieldWithDefault(msg, 49, 0),
    vehicleAvailability: jspb.Message.getFieldWithDefault(msg, 50, 0),
    externalId: jspb.Message.getFieldWithDefault(msg, 51, ""),
    inStock: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 53, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 54, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.Vehicle}
 */
proto.entity.vehicle.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.Vehicle;
  return proto.entity.vehicle.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.Vehicle}
 */
proto.entity.vehicle.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.vehicle.VehicleStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMileage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorColor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInteriorType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExteriorColor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngine(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEngineSize(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFuelType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransmission(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriveTrain(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveReportUrl(value);
      break;
    case 22:
      var value = new proto.entity.vehicle.VehicleOption;
      reader.readMessage(value,proto.entity.vehicle.VehicleOption.deserializeBinaryFromReader);
      msg.addVehicleOptions(value);
      break;
    case 23:
      var value = new proto.entity.vehicle.VehicleLocation;
      reader.readMessage(value,proto.entity.vehicle.VehicleLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 24:
      var value = new proto.entity.vehicle.VehicleCarFaxInfo;
      reader.readMessage(value,proto.entity.vehicle.VehicleCarFaxInfo.deserializeBinaryFromReader);
      msg.setVehicleCarfaxInfo(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLowMileage(value);
      break;
    case 26:
      var value = new proto.entity.vehicle.VehicleImage;
      reader.readMessage(value,proto.entity.vehicle.VehicleImage.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlInspectionUrl(value);
      break;
    case 28:
      var value = /** @type {!proto.entity.vehicle.VehicleClass} */ (reader.readEnum());
      msg.setVehicleClass(value);
      break;
    case 29:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoGradeRating(value);
      break;
    case 31:
      var value = /** @type {!proto.entity.vehicle.VehicleOrigin} */ (reader.readEnum());
      msg.setVehicleOrigin(value);
      break;
    case 32:
      var value = new proto.entity.vehicle.VehiclePaveDisclosure;
      reader.readMessage(value,proto.entity.vehicle.VehiclePaveDisclosure.deserializeBinaryFromReader);
      msg.setVehiclePaveDisclosure(value);
      break;
    case 33:
      var value = new proto.entity.vehicle.VehiclePaveAnnouncement;
      reader.readMessage(value,proto.entity.vehicle.VehiclePaveAnnouncement.deserializeBinaryFromReader);
      msg.setVehiclePaveAnnouncement(value);
      break;
    case 34:
      var value = new proto.entity.vehicle.CarInspectionQuestion;
      reader.readMessage(value,proto.entity.vehicle.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.addCarInspectionQuestions(value);
      break;
    case 35:
      var value = new proto.entity.vehicle.Bid;
      reader.readMessage(value,proto.entity.vehicle.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 36:
      var value = new proto.entity.vehicle.AuctionGroup;
      reader.readMessage(value,proto.entity.vehicle.AuctionGroup.deserializeBinaryFromReader);
      msg.addAuctionGroups(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveSession(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveReportHtml(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimLevel(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGivenName(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPhoneNumber(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarfaxLienUrl(value);
      break;
    case 44:
      var value = new proto.entity.vehicle.CarfaxAnnouncement;
      reader.readMessage(value,proto.entity.vehicle.CarfaxAnnouncement.deserializeBinaryFromReader);
      msg.addCarfaxAnnouncements(value);
      break;
    case 45:
      var value = /** @type {!proto.entity.vehicle.MileageUOM} */ (reader.readEnum());
      msg.setMileageUom(value);
      break;
    case 46:
      var value = new proto.entity.vehicle.LeaseInformation;
      reader.readMessage(value,proto.entity.vehicle.LeaseInformation.deserializeBinaryFromReader);
      msg.setLeaseInformation(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 48:
      var value = new proto.entity.vehicle.PriceHistory;
      reader.readMessage(value,proto.entity.vehicle.PriceHistory.deserializeBinaryFromReader);
      msg.addPriceHistory(value);
      break;
    case 49:
      var value = /** @type {!proto.entity.vehicle.Source} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 50:
      var value = /** @type {!proto.entity.vehicle.VehicleAvailability} */ (reader.readEnum());
      msg.setVehicleAvailability(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInStock(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 54:
      var value = /** @type {!proto.entity.vehicle.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMileage();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getInteriorColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInteriorType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getExteriorColor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCarfaxUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEngine();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getEngineSize();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFuelType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTransmission();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDriveTrain();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPaveReportUrl();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getVehicleOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.entity.vehicle.VehicleOption.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.entity.vehicle.VehicleLocation.serializeBinaryToWriter
    );
  }
  f = message.getVehicleCarfaxInfo();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.entity.vehicle.VehicleCarFaxInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsLowMileage();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.entity.vehicle.VehicleImage.serializeBinaryToWriter
    );
  }
  f = message.getMlInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAutoGradeRating();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getVehicleOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getVehiclePaveDisclosure();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.entity.vehicle.VehiclePaveDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getVehiclePaveAnnouncement();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.entity.vehicle.VehiclePaveAnnouncement.serializeBinaryToWriter
    );
  }
  f = message.getCarInspectionQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      34,
      f,
      proto.entity.vehicle.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.entity.vehicle.Bid.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.entity.vehicle.AuctionGroup.serializeBinaryToWriter
    );
  }
  f = message.getPaveSession();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getPaveReportHtml();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getTrimLevel();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getUserGivenName();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getUserPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getCarfaxLienUrl();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getCarfaxAnnouncementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      44,
      f,
      proto.entity.vehicle.CarfaxAnnouncement.serializeBinaryToWriter
    );
  }
  f = message.getMileageUom();
  if (f !== 0.0) {
    writer.writeEnum(
      45,
      f
    );
  }
  f = message.getLeaseInformation();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.entity.vehicle.LeaseInformation.serializeBinaryToWriter
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getPriceHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      48,
      f,
      proto.entity.vehicle.PriceHistory.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      49,
      f
    );
  }
  f = message.getVehicleAvailability();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getInStock();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      53,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      54,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vin = 2;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.entity.vehicle.Vehicle.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string make = 6;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string model = 7;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 seats = 8;
 * @return {number}
 */
proto.entity.vehicle.Vehicle.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional VehicleStatus status = 9;
 * @return {!proto.entity.vehicle.VehicleStatus}
 */
proto.entity.vehicle.Vehicle.prototype.getStatus = function() {
  return /** @type {!proto.entity.vehicle.VehicleStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.vehicle.VehicleStatus} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 mileage = 10;
 * @return {number}
 */
proto.entity.vehicle.Vehicle.prototype.getMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string interior_color = 11;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getInteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setInteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string interior_type = 12;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getInteriorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setInteriorType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string exterior_color = 13;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getExteriorColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setExteriorColor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string carfax_url = 14;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getCarfaxUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setCarfaxUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string engine = 15;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getEngine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setEngine = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string engine_size = 16;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getEngineSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setEngineSize = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string fuel_type = 17;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getFuelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setFuelType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string transmission = 18;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getTransmission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setTransmission = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string drive_train = 19;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getDriveTrain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setDriveTrain = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string inspection_id = 20;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string pave_report_url = 21;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getPaveReportUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setPaveReportUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated VehicleOption vehicle_options = 22;
 * @return {!Array<!proto.entity.vehicle.VehicleOption>}
 */
proto.entity.vehicle.Vehicle.prototype.getVehicleOptionsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.VehicleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.VehicleOption, 22));
};


/**
 * @param {!Array<!proto.entity.vehicle.VehicleOption>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setVehicleOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.entity.vehicle.VehicleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.VehicleOption}
 */
proto.entity.vehicle.Vehicle.prototype.addVehicleOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.entity.vehicle.VehicleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearVehicleOptionsList = function() {
  return this.setVehicleOptionsList([]);
};


/**
 * optional VehicleLocation location = 23;
 * @return {?proto.entity.vehicle.VehicleLocation}
 */
proto.entity.vehicle.Vehicle.prototype.getLocation = function() {
  return /** @type{?proto.entity.vehicle.VehicleLocation} */ (
    jspb.Message.getWrapperField(this, proto.entity.vehicle.VehicleLocation, 23));
};


/**
 * @param {?proto.entity.vehicle.VehicleLocation|undefined} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional VehicleCarFaxInfo vehicle_carfax_info = 24;
 * @return {?proto.entity.vehicle.VehicleCarFaxInfo}
 */
proto.entity.vehicle.Vehicle.prototype.getVehicleCarfaxInfo = function() {
  return /** @type{?proto.entity.vehicle.VehicleCarFaxInfo} */ (
    jspb.Message.getWrapperField(this, proto.entity.vehicle.VehicleCarFaxInfo, 24));
};


/**
 * @param {?proto.entity.vehicle.VehicleCarFaxInfo|undefined} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setVehicleCarfaxInfo = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearVehicleCarfaxInfo = function() {
  return this.setVehicleCarfaxInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.hasVehicleCarfaxInfo = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool is_low_mileage = 25;
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.getIsLowMileage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setIsLowMileage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * repeated VehicleImage images = 26;
 * @return {!Array<!proto.entity.vehicle.VehicleImage>}
 */
proto.entity.vehicle.Vehicle.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.entity.vehicle.VehicleImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.VehicleImage, 26));
};


/**
 * @param {!Array<!proto.entity.vehicle.VehicleImage>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.entity.vehicle.VehicleImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.VehicleImage}
 */
proto.entity.vehicle.Vehicle.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.entity.vehicle.VehicleImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string ml_inspection_url = 27;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getMlInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setMlInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional VehicleClass vehicle_class = 28;
 * @return {!proto.entity.vehicle.VehicleClass}
 */
proto.entity.vehicle.Vehicle.prototype.getVehicleClass = function() {
  return /** @type {!proto.entity.vehicle.VehicleClass} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.entity.vehicle.VehicleClass} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 29;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.vehicle.Vehicle.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 29));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string auto_grade_rating = 30;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getAutoGradeRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setAutoGradeRating = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional VehicleOrigin vehicle_origin = 31;
 * @return {!proto.entity.vehicle.VehicleOrigin}
 */
proto.entity.vehicle.Vehicle.prototype.getVehicleOrigin = function() {
  return /** @type {!proto.entity.vehicle.VehicleOrigin} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.entity.vehicle.VehicleOrigin} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setVehicleOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional VehiclePaveDisclosure vehicle_pave_disclosure = 32;
 * @return {?proto.entity.vehicle.VehiclePaveDisclosure}
 */
proto.entity.vehicle.Vehicle.prototype.getVehiclePaveDisclosure = function() {
  return /** @type{?proto.entity.vehicle.VehiclePaveDisclosure} */ (
    jspb.Message.getWrapperField(this, proto.entity.vehicle.VehiclePaveDisclosure, 32));
};


/**
 * @param {?proto.entity.vehicle.VehiclePaveDisclosure|undefined} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setVehiclePaveDisclosure = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearVehiclePaveDisclosure = function() {
  return this.setVehiclePaveDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.hasVehiclePaveDisclosure = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional VehiclePaveAnnouncement vehicle_pave_announcement = 33;
 * @return {?proto.entity.vehicle.VehiclePaveAnnouncement}
 */
proto.entity.vehicle.Vehicle.prototype.getVehiclePaveAnnouncement = function() {
  return /** @type{?proto.entity.vehicle.VehiclePaveAnnouncement} */ (
    jspb.Message.getWrapperField(this, proto.entity.vehicle.VehiclePaveAnnouncement, 33));
};


/**
 * @param {?proto.entity.vehicle.VehiclePaveAnnouncement|undefined} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setVehiclePaveAnnouncement = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearVehiclePaveAnnouncement = function() {
  return this.setVehiclePaveAnnouncement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.hasVehiclePaveAnnouncement = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * repeated CarInspectionQuestion car_inspection_questions = 34;
 * @return {!Array<!proto.entity.vehicle.CarInspectionQuestion>}
 */
proto.entity.vehicle.Vehicle.prototype.getCarInspectionQuestionsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.CarInspectionQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.CarInspectionQuestion, 34));
};


/**
 * @param {!Array<!proto.entity.vehicle.CarInspectionQuestion>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setCarInspectionQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 34, value);
};


/**
 * @param {!proto.entity.vehicle.CarInspectionQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.CarInspectionQuestion}
 */
proto.entity.vehicle.Vehicle.prototype.addCarInspectionQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 34, opt_value, proto.entity.vehicle.CarInspectionQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearCarInspectionQuestionsList = function() {
  return this.setCarInspectionQuestionsList([]);
};


/**
 * repeated Bid bids = 35;
 * @return {!Array<!proto.entity.vehicle.Bid>}
 */
proto.entity.vehicle.Vehicle.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.Bid, 35));
};


/**
 * @param {!Array<!proto.entity.vehicle.Bid>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.entity.vehicle.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.Bid}
 */
proto.entity.vehicle.Vehicle.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.entity.vehicle.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * repeated AuctionGroup auction_groups = 36;
 * @return {!Array<!proto.entity.vehicle.AuctionGroup>}
 */
proto.entity.vehicle.Vehicle.prototype.getAuctionGroupsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.AuctionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.AuctionGroup, 36));
};


/**
 * @param {!Array<!proto.entity.vehicle.AuctionGroup>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setAuctionGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.entity.vehicle.AuctionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.AuctionGroup}
 */
proto.entity.vehicle.Vehicle.prototype.addAuctionGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.entity.vehicle.AuctionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearAuctionGroupsList = function() {
  return this.setAuctionGroupsList([]);
};


/**
 * optional string pave_session = 37;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getPaveSession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setPaveSession = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string pave_report_html = 38;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getPaveReportHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setPaveReportHtml = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string trim_level = 39;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getTrimLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setTrimLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string user_given_name = 40;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getUserGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setUserGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string user_phone_number = 41;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getUserPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setUserPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string user_id = 42;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string carfax_lien_url = 43;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getCarfaxLienUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setCarfaxLienUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * repeated CarfaxAnnouncement carfax_announcements = 44;
 * @return {!Array<!proto.entity.vehicle.CarfaxAnnouncement>}
 */
proto.entity.vehicle.Vehicle.prototype.getCarfaxAnnouncementsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.CarfaxAnnouncement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.CarfaxAnnouncement, 44));
};


/**
 * @param {!Array<!proto.entity.vehicle.CarfaxAnnouncement>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setCarfaxAnnouncementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 44, value);
};


/**
 * @param {!proto.entity.vehicle.CarfaxAnnouncement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.CarfaxAnnouncement}
 */
proto.entity.vehicle.Vehicle.prototype.addCarfaxAnnouncements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 44, opt_value, proto.entity.vehicle.CarfaxAnnouncement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearCarfaxAnnouncementsList = function() {
  return this.setCarfaxAnnouncementsList([]);
};


/**
 * optional MileageUOM mileage_uom = 45;
 * @return {!proto.entity.vehicle.MileageUOM}
 */
proto.entity.vehicle.Vehicle.prototype.getMileageUom = function() {
  return /** @type {!proto.entity.vehicle.MileageUOM} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {!proto.entity.vehicle.MileageUOM} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setMileageUom = function(value) {
  return jspb.Message.setProto3EnumField(this, 45, value);
};


/**
 * optional LeaseInformation lease_information = 46;
 * @return {?proto.entity.vehicle.LeaseInformation}
 */
proto.entity.vehicle.Vehicle.prototype.getLeaseInformation = function() {
  return /** @type{?proto.entity.vehicle.LeaseInformation} */ (
    jspb.Message.getWrapperField(this, proto.entity.vehicle.LeaseInformation, 46));
};


/**
 * @param {?proto.entity.vehicle.LeaseInformation|undefined} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setLeaseInformation = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearLeaseInformation = function() {
  return this.setLeaseInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.hasLeaseInformation = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional string dealer_id = 47;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * repeated PriceHistory price_history = 48;
 * @return {!Array<!proto.entity.vehicle.PriceHistory>}
 */
proto.entity.vehicle.Vehicle.prototype.getPriceHistoryList = function() {
  return /** @type{!Array<!proto.entity.vehicle.PriceHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.PriceHistory, 48));
};


/**
 * @param {!Array<!proto.entity.vehicle.PriceHistory>} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
*/
proto.entity.vehicle.Vehicle.prototype.setPriceHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 48, value);
};


/**
 * @param {!proto.entity.vehicle.PriceHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.PriceHistory}
 */
proto.entity.vehicle.Vehicle.prototype.addPriceHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 48, opt_value, proto.entity.vehicle.PriceHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.clearPriceHistoryList = function() {
  return this.setPriceHistoryList([]);
};


/**
 * optional Source source = 49;
 * @return {!proto.entity.vehicle.Source}
 */
proto.entity.vehicle.Vehicle.prototype.getSource = function() {
  return /** @type {!proto.entity.vehicle.Source} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {!proto.entity.vehicle.Source} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 49, value);
};


/**
 * optional VehicleAvailability vehicle_availability = 50;
 * @return {!proto.entity.vehicle.VehicleAvailability}
 */
proto.entity.vehicle.Vehicle.prototype.getVehicleAvailability = function() {
  return /** @type {!proto.entity.vehicle.VehicleAvailability} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.entity.vehicle.VehicleAvailability} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setVehicleAvailability = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional string external_id = 51;
 * @return {string}
 */
proto.entity.vehicle.Vehicle.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional bool in_stock = 52;
 * @return {boolean}
 */
proto.entity.vehicle.Vehicle.prototype.getInStock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setInStock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * optional double price = 53;
 * @return {number}
 */
proto.entity.vehicle.Vehicle.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 53, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 53, value);
};


/**
 * optional Currency currency = 54;
 * @return {!proto.entity.vehicle.Currency}
 */
proto.entity.vehicle.Vehicle.prototype.getCurrency = function() {
  return /** @type {!proto.entity.vehicle.Currency} */ (jspb.Message.getFieldWithDefault(this, 54, 0));
};


/**
 * @param {!proto.entity.vehicle.Currency} value
 * @return {!proto.entity.vehicle.Vehicle} returns this
 */
proto.entity.vehicle.Vehicle.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 54, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.AuctionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.AuctionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.AuctionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.AuctionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auctionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vehicles: (f = msg.getVehicles()) && proto.entity.vehicle.Vehicles.toObject(includeInstance, f),
    auctionStatus: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.AuctionGroup}
 */
proto.entity.vehicle.AuctionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.AuctionGroup;
  return proto.entity.vehicle.AuctionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.AuctionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.AuctionGroup}
 */
proto.entity.vehicle.AuctionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = new proto.entity.vehicle.Vehicles;
      reader.readMessage(value,proto.entity.vehicle.Vehicles.deserializeBinaryFromReader);
      msg.setVehicles(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.vehicle.AuctionStatus} */ (reader.readEnum());
      msg.setAuctionStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.AuctionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.AuctionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.AuctionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.AuctionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuctionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVehicles();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.entity.vehicle.Vehicles.serializeBinaryToWriter
    );
  }
  f = message.getAuctionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.vehicle.AuctionGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
 */
proto.entity.vehicle.AuctionGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auction_id = 2;
 * @return {string}
 */
proto.entity.vehicle.AuctionGroup.prototype.getAuctionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
 */
proto.entity.vehicle.AuctionGroup.prototype.setAuctionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.entity.vehicle.AuctionGroup.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
 */
proto.entity.vehicle.AuctionGroup.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.entity.vehicle.AuctionGroup.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
 */
proto.entity.vehicle.AuctionGroup.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Vehicles vehicles = 5;
 * @return {?proto.entity.vehicle.Vehicles}
 */
proto.entity.vehicle.AuctionGroup.prototype.getVehicles = function() {
  return /** @type{?proto.entity.vehicle.Vehicles} */ (
    jspb.Message.getWrapperField(this, proto.entity.vehicle.Vehicles, 5));
};


/**
 * @param {?proto.entity.vehicle.Vehicles|undefined} value
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
*/
proto.entity.vehicle.AuctionGroup.prototype.setVehicles = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
 */
proto.entity.vehicle.AuctionGroup.prototype.clearVehicles = function() {
  return this.setVehicles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.AuctionGroup.prototype.hasVehicles = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AuctionStatus auction_status = 6;
 * @return {!proto.entity.vehicle.AuctionStatus}
 */
proto.entity.vehicle.AuctionGroup.prototype.getAuctionStatus = function() {
  return /** @type {!proto.entity.vehicle.AuctionStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.vehicle.AuctionStatus} value
 * @return {!proto.entity.vehicle.AuctionGroup} returns this
 */
proto.entity.vehicle.AuctionGroup.prototype.setAuctionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarInspectionSession.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarInspectionSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarInspectionSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inspectionUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paveSessionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    smsSent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarInspectionSession}
 */
proto.entity.vehicle.CarInspectionSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarInspectionSession;
  return proto.entity.vehicle.CarInspectionSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarInspectionSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarInspectionSession}
 */
proto.entity.vehicle.CarInspectionSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarInspectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaveSessionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmsSent(value);
      break;
    case 6:
      var value = /** @type {!proto.entity.vehicle.InspectionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarInspectionSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarInspectionSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarInspectionSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInspectionUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaveSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSmsSent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string car_inspection_id = 1;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getCarInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setCarInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inspection_url = 2;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getInspectionUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setInspectionUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pave_session_id = 3;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getPaveSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setPaveSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sms_sent = 5;
 * @return {boolean}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getSmsSent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setSmsSent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional InspectionStatus status = 6;
 * @return {!proto.entity.vehicle.InspectionStatus}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getStatus = function() {
  return /** @type {!proto.entity.vehicle.InspectionStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.entity.vehicle.InspectionStatus} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string user_id = 7;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string vehicle_id = 8;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string redirect_url = 9;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionSession.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionSession} returns this
 */
proto.entity.vehicle.CarInspectionSession.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.CarInspectionSessions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarInspectionSessions.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarInspectionSessions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarInspectionSessions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionSessions.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionSessionList: jspb.Message.toObjectList(msg.getCarInspectionSessionList(),
    proto.entity.vehicle.CarInspectionSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarInspectionSessions}
 */
proto.entity.vehicle.CarInspectionSessions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarInspectionSessions;
  return proto.entity.vehicle.CarInspectionSessions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarInspectionSessions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarInspectionSessions}
 */
proto.entity.vehicle.CarInspectionSessions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.vehicle.CarInspectionSession;
      reader.readMessage(value,proto.entity.vehicle.CarInspectionSession.deserializeBinaryFromReader);
      msg.addCarInspectionSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarInspectionSessions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarInspectionSessions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarInspectionSessions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionSessions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionSessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.vehicle.CarInspectionSession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionSession car_inspection_session = 1;
 * @return {!Array<!proto.entity.vehicle.CarInspectionSession>}
 */
proto.entity.vehicle.CarInspectionSessions.prototype.getCarInspectionSessionList = function() {
  return /** @type{!Array<!proto.entity.vehicle.CarInspectionSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.CarInspectionSession, 1));
};


/**
 * @param {!Array<!proto.entity.vehicle.CarInspectionSession>} value
 * @return {!proto.entity.vehicle.CarInspectionSessions} returns this
*/
proto.entity.vehicle.CarInspectionSessions.prototype.setCarInspectionSessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.vehicle.CarInspectionSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.CarInspectionSession}
 */
proto.entity.vehicle.CarInspectionSessions.prototype.addCarInspectionSession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.vehicle.CarInspectionSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.CarInspectionSessions} returns this
 */
proto.entity.vehicle.CarInspectionSessions.prototype.clearCarInspectionSessionList = function() {
  return this.setCarInspectionSessionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.CarInspectionAnswers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarInspectionAnswers.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarInspectionAnswers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarInspectionAnswers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionAnswers.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionAnswersList: jspb.Message.toObjectList(msg.getCarInspectionAnswersList(),
    proto.entity.vehicle.CarInspectionAnswer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarInspectionAnswers}
 */
proto.entity.vehicle.CarInspectionAnswers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarInspectionAnswers;
  return proto.entity.vehicle.CarInspectionAnswers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarInspectionAnswers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarInspectionAnswers}
 */
proto.entity.vehicle.CarInspectionAnswers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.vehicle.CarInspectionAnswer;
      reader.readMessage(value,proto.entity.vehicle.CarInspectionAnswer.deserializeBinaryFromReader);
      msg.addCarInspectionAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarInspectionAnswers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarInspectionAnswers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarInspectionAnswers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionAnswers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.vehicle.CarInspectionAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionAnswer car_inspection_answers = 1;
 * @return {!Array<!proto.entity.vehicle.CarInspectionAnswer>}
 */
proto.entity.vehicle.CarInspectionAnswers.prototype.getCarInspectionAnswersList = function() {
  return /** @type{!Array<!proto.entity.vehicle.CarInspectionAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.CarInspectionAnswer, 1));
};


/**
 * @param {!Array<!proto.entity.vehicle.CarInspectionAnswer>} value
 * @return {!proto.entity.vehicle.CarInspectionAnswers} returns this
*/
proto.entity.vehicle.CarInspectionAnswers.prototype.setCarInspectionAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.vehicle.CarInspectionAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.CarInspectionAnswer}
 */
proto.entity.vehicle.CarInspectionAnswers.prototype.addCarInspectionAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.vehicle.CarInspectionAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.CarInspectionAnswers} returns this
 */
proto.entity.vehicle.CarInspectionAnswers.prototype.clearCarInspectionAnswersList = function() {
  return this.setCarInspectionAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarInspectionAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarInspectionAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionOptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarInspectionAnswer}
 */
proto.entity.vehicle.CarInspectionAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarInspectionAnswer;
  return proto.entity.vehicle.CarInspectionAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarInspectionAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarInspectionAnswer}
 */
proto.entity.vehicle.CarInspectionAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionOptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarInspectionAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarInspectionAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionOptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionAnswer} returns this
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionAnswer} returns this
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question_option_id = 3;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.getQuestionOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionAnswer} returns this
 */
proto.entity.vehicle.CarInspectionAnswer.prototype.setQuestionOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.QuestionOption.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.QuestionOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.QuestionOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.QuestionOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    option: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasDetailField: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optionType: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.QuestionOption}
 */
proto.entity.vehicle.QuestionOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.QuestionOption;
  return proto.entity.vehicle.QuestionOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.QuestionOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.QuestionOption}
 */
proto.entity.vehicle.QuestionOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOption(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDetailField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOptionOrder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.QuestionOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.QuestionOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.QuestionOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.QuestionOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasDetailField();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptionType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string option = 1;
 * @return {string}
 */
proto.entity.vehicle.QuestionOption.prototype.getOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setOption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_detail_field = 2;
 * @return {boolean}
 */
proto.entity.vehicle.QuestionOption.prototype.getHasDetailField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setHasDetailField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.entity.vehicle.QuestionOption.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 option_order = 4;
 * @return {number}
 */
proto.entity.vehicle.QuestionOption.prototype.getOptionOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setOptionOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_required = 5;
 * @return {boolean}
 */
proto.entity.vehicle.QuestionOption.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.entity.vehicle.QuestionOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string option_type = 7;
 * @return {string}
 */
proto.entity.vehicle.QuestionOption.prototype.getOptionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.QuestionOption} returns this
 */
proto.entity.vehicle.QuestionOption.prototype.setOptionType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.CarInspectionQuestions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarInspectionQuestions.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarInspectionQuestions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarInspectionQuestions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionQuestions.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionQuestionsList: jspb.Message.toObjectList(msg.getCarInspectionQuestionsList(),
    proto.entity.vehicle.CarInspectionQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarInspectionQuestions}
 */
proto.entity.vehicle.CarInspectionQuestions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarInspectionQuestions;
  return proto.entity.vehicle.CarInspectionQuestions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarInspectionQuestions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarInspectionQuestions}
 */
proto.entity.vehicle.CarInspectionQuestions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.vehicle.CarInspectionQuestion;
      reader.readMessage(value,proto.entity.vehicle.CarInspectionQuestion.deserializeBinaryFromReader);
      msg.addCarInspectionQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarInspectionQuestions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarInspectionQuestions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarInspectionQuestions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionQuestions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.vehicle.CarInspectionQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CarInspectionQuestion car_inspection_questions = 1;
 * @return {!Array<!proto.entity.vehicle.CarInspectionQuestion>}
 */
proto.entity.vehicle.CarInspectionQuestions.prototype.getCarInspectionQuestionsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.CarInspectionQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.CarInspectionQuestion, 1));
};


/**
 * @param {!Array<!proto.entity.vehicle.CarInspectionQuestion>} value
 * @return {!proto.entity.vehicle.CarInspectionQuestions} returns this
*/
proto.entity.vehicle.CarInspectionQuestions.prototype.setCarInspectionQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.vehicle.CarInspectionQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.CarInspectionQuestion}
 */
proto.entity.vehicle.CarInspectionQuestions.prototype.addCarInspectionQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.vehicle.CarInspectionQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.CarInspectionQuestions} returns this
 */
proto.entity.vehicle.CarInspectionQuestions.prototype.clearCarInspectionQuestionsList = function() {
  return this.setCarInspectionQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.CarInspectionQuestion.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarInspectionQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarInspectionQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    carInspectionSessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.entity.vehicle.QuestionOption.toObject, includeInstance),
    category: jspb.Message.getFieldWithDefault(msg, 5, ""),
    carInspectionAnswersList: jspb.Message.toObjectList(msg.getCarInspectionAnswersList(),
    proto.entity.vehicle.CarInspectionAnswer.toObject, includeInstance),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    questionType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarInspectionQuestion}
 */
proto.entity.vehicle.CarInspectionQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarInspectionQuestion;
  return proto.entity.vehicle.CarInspectionQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarInspectionQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarInspectionQuestion}
 */
proto.entity.vehicle.CarInspectionQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarInspectionSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = new proto.entity.vehicle.QuestionOption;
      reader.readMessage(value,proto.entity.vehicle.QuestionOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 6:
      var value = new proto.entity.vehicle.CarInspectionAnswer;
      reader.readMessage(value,proto.entity.vehicle.CarInspectionAnswer.deserializeBinaryFromReader);
      msg.addCarInspectionAnswers(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 8:
      var value = /** @type {!proto.entity.vehicle.QuestionType} */ (reader.readEnum());
      msg.setQuestionType(value);
      break;
    case 9:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarInspectionQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarInspectionQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarInspectionQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarInspectionSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.entity.vehicle.QuestionOption.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCarInspectionAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.entity.vehicle.CarInspectionAnswer.serializeBinaryToWriter
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getQuestionType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string car_inspection_session_id = 1;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getCarInspectionSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.setCarInspectionSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string question_id = 2;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated QuestionOption options = 4;
 * @return {!Array<!proto.entity.vehicle.QuestionOption>}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.QuestionOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.QuestionOption, 4));
};


/**
 * @param {!Array<!proto.entity.vehicle.QuestionOption>} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
*/
proto.entity.vehicle.CarInspectionQuestion.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.entity.vehicle.QuestionOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.QuestionOption}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.entity.vehicle.QuestionOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CarInspectionAnswer car_inspection_answers = 6;
 * @return {!Array<!proto.entity.vehicle.CarInspectionAnswer>}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getCarInspectionAnswersList = function() {
  return /** @type{!Array<!proto.entity.vehicle.CarInspectionAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.CarInspectionAnswer, 6));
};


/**
 * @param {!Array<!proto.entity.vehicle.CarInspectionAnswer>} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
*/
proto.entity.vehicle.CarInspectionQuestion.prototype.setCarInspectionAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.entity.vehicle.CarInspectionAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.CarInspectionAnswer}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.addCarInspectionAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.entity.vehicle.CarInspectionAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.clearCarInspectionAnswersList = function() {
  return this.setCarInspectionAnswersList([]);
};


/**
 * optional bool is_required = 7;
 * @return {boolean}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional QuestionType question_type = 8;
 * @return {!proto.entity.vehicle.QuestionType}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getQuestionType = function() {
  return /** @type {!proto.entity.vehicle.QuestionType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.entity.vehicle.QuestionType} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 9;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 9));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
*/
proto.entity.vehicle.CarInspectionQuestion.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.CarInspectionQuestion} returns this
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.CarInspectionQuestion.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.LeaseInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.LeaseInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.LeaseInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.LeaseInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    leaseProvider: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthlyPayment: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    termRemaining: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.LeaseInformation}
 */
proto.entity.vehicle.LeaseInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.LeaseInformation;
  return proto.entity.vehicle.LeaseInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.LeaseInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.LeaseInformation}
 */
proto.entity.vehicle.LeaseInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaseProvider(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthlyPayment(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.vehicle.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTermRemaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.LeaseInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.LeaseInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.LeaseInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.LeaseInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeaseProvider();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTermRemaining();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string lease_provider = 1;
 * @return {string}
 */
proto.entity.vehicle.LeaseInformation.prototype.getLeaseProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.LeaseInformation} returns this
 */
proto.entity.vehicle.LeaseInformation.prototype.setLeaseProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double monthly_payment = 2;
 * @return {number}
 */
proto.entity.vehicle.LeaseInformation.prototype.getMonthlyPayment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.LeaseInformation} returns this
 */
proto.entity.vehicle.LeaseInformation.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Currency currency = 3;
 * @return {!proto.entity.vehicle.Currency}
 */
proto.entity.vehicle.LeaseInformation.prototype.getCurrency = function() {
  return /** @type {!proto.entity.vehicle.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.vehicle.Currency} value
 * @return {!proto.entity.vehicle.LeaseInformation} returns this
 */
proto.entity.vehicle.LeaseInformation.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 term_remaining = 4;
 * @return {number}
 */
proto.entity.vehicle.LeaseInformation.prototype.getTermRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.LeaseInformation} returns this
 */
proto.entity.vehicle.LeaseInformation.prototype.setTermRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.CarfaxAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.CarfaxAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarfaxAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    amountSpecified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    dateSpecified: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.CarfaxAnnouncement}
 */
proto.entity.vehicle.CarfaxAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.CarfaxAnnouncement;
  return proto.entity.vehicle.CarfaxAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.CarfaxAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.CarfaxAnnouncement}
 */
proto.entity.vehicle.CarfaxAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmountSpecified(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDateSpecified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.CarfaxAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.CarfaxAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.CarfaxAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAmountSpecified();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDateSpecified();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarfaxAnnouncement} returns this
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarfaxAnnouncement} returns this
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.CarfaxAnnouncement} returns this
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.CarfaxAnnouncement} returns this
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool amount_specified = 5;
 * @return {boolean}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.getAmountSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.CarfaxAnnouncement} returns this
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.setAmountSpecified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool date_specified = 6;
 * @return {boolean}
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.getDateSpecified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.CarfaxAnnouncement} returns this
 */
proto.entity.vehicle.CarfaxAnnouncement.prototype.setDateSpecified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.VehicleImage.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.VehicleImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.VehicleImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mlImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.VehicleImage}
 */
proto.entity.vehicle.VehicleImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.VehicleImage;
  return proto.entity.vehicle.VehicleImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.VehicleImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.VehicleImage}
 */
proto.entity.vehicle.VehicleImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlImageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    case 5:
      var value = /** @type {!proto.entity.vehicle.ImageSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.VehicleImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.VehicleImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.VehicleImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.VehicleImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMlImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string original_url = 1;
 * @return {string}
 */
proto.entity.vehicle.VehicleImage.prototype.getOriginalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleImage} returns this
 */
proto.entity.vehicle.VehicleImage.prototype.setOriginalUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ml_image_url = 2;
 * @return {string}
 */
proto.entity.vehicle.VehicleImage.prototype.getMlImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.VehicleImage} returns this
 */
proto.entity.vehicle.VehicleImage.prototype.setMlImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.entity.vehicle.VehicleImage.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.VehicleImage} returns this
 */
proto.entity.vehicle.VehicleImage.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool processed = 4;
 * @return {boolean}
 */
proto.entity.vehicle.VehicleImage.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.entity.vehicle.VehicleImage} returns this
 */
proto.entity.vehicle.VehicleImage.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional ImageSource source = 5;
 * @return {!proto.entity.vehicle.ImageSource}
 */
proto.entity.vehicle.VehicleImage.prototype.getSource = function() {
  return /** @type {!proto.entity.vehicle.ImageSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.entity.vehicle.ImageSource} value
 * @return {!proto.entity.vehicle.VehicleImage} returns this
 */
proto.entity.vehicle.VehicleImage.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.Bid.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.Bid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.Bid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Bid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    auctionGroupId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dealerName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.Bid}
 */
proto.entity.vehicle.Bid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.Bid;
  return proto.entity.vehicle.Bid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.Bid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.Bid}
 */
proto.entity.vehicle.Bid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.entity.vehicle.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuctionGroupId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.Bid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.Bid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.Bid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Bid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuctionGroupId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDealerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.vehicle.Bid.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double price = 2;
 * @return {number}
 */
proto.entity.vehicle.Bid.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Currency currency = 3;
 * @return {!proto.entity.vehicle.Currency}
 */
proto.entity.vehicle.Bid.prototype.getCurrency = function() {
  return /** @type {!proto.entity.vehicle.Currency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.entity.vehicle.Currency} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.entity.vehicle.Bid.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 5;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.vehicle.Bid.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 5));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.vehicle.Bid} returns this
*/
proto.entity.vehicle.Bid.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Bid.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string auction_group_id = 6;
 * @return {string}
 */
proto.entity.vehicle.Bid.prototype.getAuctionGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setAuctionGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.entity.vehicle.Bid.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dealer_name = 8;
 * @return {string}
 */
proto.entity.vehicle.Bid.prototype.getDealerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Bid} returns this
 */
proto.entity.vehicle.Bid.prototype.setDealerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.entity.vehicle.Bids.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.Bids.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.Bids.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.Bids} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Bids.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.entity.vehicle.Bid.toObject, includeInstance),
    totalBids: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalBidders: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.Bids}
 */
proto.entity.vehicle.Bids.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.Bids;
  return proto.entity.vehicle.Bids.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.Bids} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.Bids}
 */
proto.entity.vehicle.Bids.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.entity.vehicle.Bid;
      reader.readMessage(value,proto.entity.vehicle.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBids(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBidders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.Bids.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.Bids.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.Bids} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Bids.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.entity.vehicle.Bid.serializeBinaryToWriter
    );
  }
  f = message.getTotalBids();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalBidders();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Bid bids = 1;
 * @return {!Array<!proto.entity.vehicle.Bid>}
 */
proto.entity.vehicle.Bids.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.entity.vehicle.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.entity.vehicle.Bid, 1));
};


/**
 * @param {!Array<!proto.entity.vehicle.Bid>} value
 * @return {!proto.entity.vehicle.Bids} returns this
*/
proto.entity.vehicle.Bids.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.entity.vehicle.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.entity.vehicle.Bid}
 */
proto.entity.vehicle.Bids.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.entity.vehicle.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.entity.vehicle.Bids} returns this
 */
proto.entity.vehicle.Bids.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional int64 total_bids = 2;
 * @return {number}
 */
proto.entity.vehicle.Bids.prototype.getTotalBids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Bids} returns this
 */
proto.entity.vehicle.Bids.prototype.setTotalBids = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_bidders = 3;
 * @return {number}
 */
proto.entity.vehicle.Bids.prototype.getTotalBidders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Bids} returns this
 */
proto.entity.vehicle.Bids.prototype.setTotalBidders = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.entity.vehicle.Sale.prototype.toObject = function(opt_includeInstance) {
  return proto.entity.vehicle.Sale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.entity.vehicle.Sale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Sale.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    buyerReferenceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymentReferenceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    timeStamp: (f = msg.getTimeStamp()) && build_protos_common_pb.Timestamp.toObject(includeInstance, f),
    cancelReasonType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cancelNote: jspb.Message.getFieldWithDefault(msg, 8, ""),
    saleStatus: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.entity.vehicle.Sale}
 */
proto.entity.vehicle.Sale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.entity.vehicle.Sale;
  return proto.entity.vehicle.Sale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.entity.vehicle.Sale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.entity.vehicle.Sale}
 */
proto.entity.vehicle.Sale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerReferenceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentReferenceId(value);
      break;
    case 6:
      var value = new build_protos_common_pb.Timestamp;
      reader.readMessage(value,build_protos_common_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeStamp(value);
      break;
    case 7:
      var value = /** @type {!proto.entity.vehicle.CancelReasonType} */ (reader.readEnum());
      msg.setCancelReasonType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelNote(value);
      break;
    case 9:
      var value = /** @type {!proto.entity.vehicle.SaleStatus} */ (reader.readEnum());
      msg.setSaleStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.entity.vehicle.Sale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.entity.vehicle.Sale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.entity.vehicle.Sale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.entity.vehicle.Sale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBuyerReferenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPaymentReferenceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTimeStamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      build_protos_common_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCancelReasonType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCancelNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSaleStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.entity.vehicle.Sale.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string buyer_reference_id = 2;
 * @return {string}
 */
proto.entity.vehicle.Sale.prototype.getBuyerReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setBuyerReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.entity.vehicle.Sale.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.entity.vehicle.Sale.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string payment_reference_id = 5;
 * @return {string}
 */
proto.entity.vehicle.Sale.prototype.getPaymentReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setPaymentReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional entity.common.Timestamp time_stamp = 6;
 * @return {?proto.entity.common.Timestamp}
 */
proto.entity.vehicle.Sale.prototype.getTimeStamp = function() {
  return /** @type{?proto.entity.common.Timestamp} */ (
    jspb.Message.getWrapperField(this, build_protos_common_pb.Timestamp, 6));
};


/**
 * @param {?proto.entity.common.Timestamp|undefined} value
 * @return {!proto.entity.vehicle.Sale} returns this
*/
proto.entity.vehicle.Sale.prototype.setTimeStamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.clearTimeStamp = function() {
  return this.setTimeStamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.entity.vehicle.Sale.prototype.hasTimeStamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CancelReasonType cancel_reason_type = 7;
 * @return {!proto.entity.vehicle.CancelReasonType}
 */
proto.entity.vehicle.Sale.prototype.getCancelReasonType = function() {
  return /** @type {!proto.entity.vehicle.CancelReasonType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.entity.vehicle.CancelReasonType} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setCancelReasonType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string cancel_note = 8;
 * @return {string}
 */
proto.entity.vehicle.Sale.prototype.getCancelNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setCancelNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional SaleStatus sale_status = 9;
 * @return {!proto.entity.vehicle.SaleStatus}
 */
proto.entity.vehicle.Sale.prototype.getSaleStatus = function() {
  return /** @type {!proto.entity.vehicle.SaleStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.entity.vehicle.SaleStatus} value
 * @return {!proto.entity.vehicle.Sale} returns this
 */
proto.entity.vehicle.Sale.prototype.setSaleStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.entity.vehicle.Currency = {
  CURRENCY_INVALID: 0,
  CURRENCY_CURRENCY_CAD: 1,
  CURRENCY_CURRENCY_USD: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.VehicleStatus = {
  VEHICLE_STATUS_INVALID: 0,
  VEHICLE_STATUS_INSPECTION_CREATED: 1,
  VEHICLE_STATUS_INSPECTION_STARTED: 2,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE: 3,
  VEHICLE_STATUS_INSPECTION_COMPLETED: 4,
  VEHICLE_STATUS_AUCTION_INCLUDED: 5,
  VEHICLE_STATUS_AUCTION_READY: 6,
  VEHICLE_STATUS_AUCTION_ACTIVE: 7,
  VEHICLE_STATUS_AUCTION_COMPLETE: 8,
  VEHICLE_STATUS_AUCTION_ACCEPTED: 9,
  VEHICLE_STATUS_AUCTION_REJECTED: 10,
  VEHICLE_STATUS_INSPECTION_INCOMPLETE_MISSING_QUESTIONS: 11,
  VEHICLE_STATUS_NEW: 12,
  VEHICLE_STATUS_USED: 13,
  VEHICLE_STATUS_DEMO: 14
};

/**
 * @enum {number}
 */
proto.entity.vehicle.VehicleOrigin = {
  VEHICLE_ORIGIN_INVALID: 0,
  VEHICLE_ORIGIN_CONSUMER: 1,
  VEHICLE_ORIGIN_DEALER: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.VehicleClass = {
  VEHICLE_CLASS_INVALID: 0,
  VEHICLE_CLASS_REGULAR: 1,
  VEHICLE_CLASS_HIGH_LINE: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.VehicleAvailability = {
  VEHICLE_AVAILABILITY_INVALID: 0,
  VEHICLE_AVAILABILITY_FOR_SALE: 1,
  VEHICLE_AVAILABILITY_SOLD: 2,
  VEHICLE_AVAILABILITY_ON_HOLD: 3,
  VEHICLE_AVAILABILITY_FOR_SALE_NOT_READY: 4
};

/**
 * @enum {number}
 */
proto.entity.vehicle.Source = {
  SOURCE_INVALID: 0,
  SOURCE_TRAFFICDRIVEN: 1,
  SOURCE_CDK: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.AuctionStatus = {
  AUCTION_STATUS_INVALID: 0,
  AUCTION_STATUS_ACTIVE: 1,
  AUCTION_STATUS_COMPLETE: 2,
  AUCTION_STATUS_SCHEDULED: 3
};

/**
 * @enum {number}
 */
proto.entity.vehicle.InspectionStatus = {
  INSPECTION_STATUS_INVALID: 0,
  INSPECTION_STATUS_INCOMPLETE: 1,
  INSPECTION_STATUS_COMPLETE: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.QuestionType = {
  QUESTION_TYPE_INVALID: 0,
  QUESTION_TYPE_SINGLE_CHOICE: 1,
  QUESTION_TYPE_MULTIPLE_CHOICE: 2,
  QUESTION_TYPE_MULTIPLE_CHOICE_WITH_DETAILS: 3,
  QUESTION_TYPE_SLIDER: 4
};

/**
 * @enum {number}
 */
proto.entity.vehicle.ImageSource = {
  IMAGE_SOURCE_INVALID: 0,
  IMAGE_SOURCE_PAVE: 1,
  IMAGE_SOURCE_USER: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.MileageUOM = {
  MILEAGE_UOM_INVALID: 0,
  MILEAGE_UOM_MILES: 1,
  MILEAGE_UOM_KILOMETER: 2
};

/**
 * @enum {number}
 */
proto.entity.vehicle.CancelReasonType = {
  CANCEL_REASON_TYPE_INVALID: 0,
  CANCEL_REASON_TYPE_CONTACT: 1,
  CANCEL_REASON_TYPE_VEHICLE_CONDITION: 2,
  CANCEL_REASON_TYPE_CHANGE_MIND: 3,
  CANCEL_REASON_TYPE_OTHERS: 4
};

/**
 * @enum {number}
 */
proto.entity.vehicle.SaleStatus = {
  SALE_STATUS_INVALID: 0,
  SALE_STATUS_PENDING_WITHOUT_DEPOSIT: 1,
  SALE_STATUS_PENDING_WITH_DEPOSIT: 2,
  SALE_STATUS_CANCELED: 3,
  SALE_STATUS_COMPLETE: 4
};

goog.object.extend(exports, proto.entity.vehicle);
