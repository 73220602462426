import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import * as dealerEntityPb from '../../grpc/build/protos/dealer_pb';
import { useApiReady } from '../utils/useApiReady';
import { AutoGroup, UpdateAutoGroupProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useUpdateAutoGroup = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  async function updateAutoGroup(props: UpdateAutoGroupProps): Promise<AutoGroup | null> {
    if (!isConnectionReady) return {} as AutoGroup;
    const { name, provinceRegistrationNo, address, accountantEmail, subscriptionPlan, originAutoGroup } = props;

    const request = new adminApiv1Pb.UpdateAutoGroupRequest();
    const ag = new dealerEntityPb.AutoGroup();
    ag.setName(name);
    ag.setId(originAutoGroup.id);
    ag.setProvinceRegistrationNo(provinceRegistrationNo);
    ag.setAccountantEmail(accountantEmail);
    ag.setSubscriptionPlan(subscriptionPlan);

    const { street, city, postal, telephoneNumber, state, faxNumber } = address;
    const loc = new dealerEntityPb.AutoGroupLocation();
    loc.setStreet(street);
    loc.setCity(city);
    loc.setState(state);
    loc.setPostal(postal);
    loc.setTelephoneNumber(telephoneNumber);
    faxNumber && loc.setFaxNumber(faxNumber);

    ag.setAutoGroupLocation(loc);

    request.setAutoGroup(ag);

    const response = await client.updateAutoGroup(request, authHeader);
    return response.toObject().autoGroup || null;
  }

  return updateAutoGroup;
};
