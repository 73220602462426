import { FiUpload } from 'react-icons/fi';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import styles from '../../scss/AddFile.module.scss';
import FileUpload from '../upload/FileUpload';

// TODO Add Registration Address and Registered Owners
function AddFile({ vehicleId, uploadedCallback }: AddFileProps) {
  const { openUtilityDrawer } = useUtilityDrawer();

  const openFileUploadPanel = () => {
    openUtilityDrawer(<FileUpload vehicleId={vehicleId} uploadedCallback={uploadedCallback} />);
  };

  return (
    <button className={styles.addBtn} title="Upload" onClick={openFileUploadPanel}>
      <FiUpload />
      <span>Upload</span>
    </button>
  );
}

export default AddFile;

interface AddFileProps {
  vehicleId: string;
  uploadedCallback: () => unknown;
}
