import { useState } from 'react';

type ButtonTextProp = {
  initialText: string;
  confirmationText: string;
  completionText: string;
};
interface DoubleCheckProps {
  buttonTextProp: ButtonTextProp;
  fn: Function;
  useRestore?: boolean;
}
function useDoubleCheck({ buttonTextProp, fn, useRestore }: DoubleCheckProps) {
  const [confirmBtnText, setConfirmBtnText] = useState(buttonTextProp.initialText);
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(false);

  const doubleCheck = () => {
    const confirmProcessMap = {
      [buttonTextProp.initialText]: () => setConfirmBtnText(buttonTextProp.confirmationText),
      [buttonTextProp.confirmationText]: async () => {
        try {
          setIsConfirmBtnDisabled(true);
          await fn();

          setConfirmBtnText(buttonTextProp.completionText);

          if (useRestore) {
            setTimeout(() => {
              setConfirmBtnText(buttonTextProp.initialText);
            }, 1000);
          }
        } catch (err) {
          setConfirmBtnText(buttonTextProp.initialText);
        } finally {
          setIsConfirmBtnDisabled(false);
        }
      },
      [buttonTextProp.completionText]: () => {},
    };

    confirmProcessMap[confirmBtnText]();
  };

  return { confirmBtnText, doubleCheck, isConfirmBtnDisabled };
}

export default useDoubleCheck;
