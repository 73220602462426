import React from 'react';

import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import { WayOfSale } from '../../grpc/build/protos/enum_pb';
import BaseButtonOutline from '../base/BaseButtonOutline';
import CompleteSale from '../completeSale/CompleteConsumerSale';
import CompleteSaleForConsumerBuy from '../completeSale/CompleteDealerSale';

type CompleteSaleButtonProps = {
  vehicleId: string;
  wayOfSale: enumPb.WayOfSale;
};

function CompleteSaleButton({ vehicleId, wayOfSale }: CompleteSaleButtonProps) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const completeSale = async () => {
    if (wayOfSale == WayOfSale.WAY_OF_SALE_CONSUMER_BUY) {
      openUtilityDrawer(<CompleteSaleForConsumerBuy vehicleId={vehicleId} wayOfSale={wayOfSale} />);
    } else {
      openUtilityDrawer(<CompleteSale vehicleId={vehicleId} wayOfSale={wayOfSale} />);
    }
  };

  return <BaseButtonOutline small display="Complete Sale" onClick={completeSale} />;
}

export default CompleteSaleButton;
