import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export const useRemoveVehicleFromAuction = () => {
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();

  const [isConnectionReady] = useApiReady();

  function removeVehicleFromAuction(vehicleId: string): Promise<adminApiv1Pb.DeleteVehicleFromAuctionResponse> {
    const request = new adminApiv1Pb.DeleteVehicleFromAuctionRequest();
    request.setVehicleId(vehicleId);

    return client.deleteVehicleFromAuction(request, authHeader);
  }

  const removeVehicleFromAuctionFn = async (vehicleId: string) => {
    if (!isConnectionReady) return;
    return removeVehicleFromAuction(vehicleId);
  };

  return removeVehicleFromAuctionFn;
};
