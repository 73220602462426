import React, { useState } from 'react';

import { User } from '../../composables/api/types';
import { useGetConsumerByName } from '../../composables/api/useGetConsumerByName';
import styles from '../../scss/ChangeOwnership.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseSearchInput from '../base/BaseSearchInput';

interface SelectConsumerUserProps {
  onSelect: (user: User | null) => void;
  onReset: () => void;
}
function SelectConsumerUser({ onSelect, onReset }: SelectConsumerUserProps) {
  const { consumers, searchConsumer } = useGetConsumerByName();
  const [user, setUser] = useState<User | null>(null);

  function handleSelect(user: User) {
    setUser(user);
    onSelect(user);
  }

  function reset() {
    searchConsumer('');
    setUser(null);
    onSelect(null);
    onReset();
  }

  if (user) {
    return (
      <div className={styles.searchConsumerContainer}>
        <section>
          <div className={styles.listItem}>
            <h3>Name: {user.name}</h3>
            <p>ID: {user.id}</p>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
            <BaseButtonOutline display={'Reset'} onClick={() => reset()} />
          </div>
        </section>
      </div>
    );
  }
  return (
    <div className={styles.searchConsumerContainer}>
      <section>
        <BaseSearchInput placeholder={'type at least 3 characters'} onSearch={searchConsumer} />
      </section>
      <br />
      <section>
        {consumers.map((user, idx) => (
          <div key={`${idx}-${user.id}`} className={styles.listItem}>
            <h3>Name: {user.name}</h3>
            <p>ID: {user.id}</p>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
            <BaseButtonOutline display={'Select'} onClick={() => handleSelect(user)} />
          </div>
        ))}
      </section>
    </div>
  );
}

export default SelectConsumerUser;
