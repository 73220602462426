import { useState } from 'react';

import { useCreateBreakFee } from '../../composables/api/useCreateBreakFee';
import useDealTankVehicle from '../../composables/api/useDealTankVehicle';
import { useGetAcceptedOffer } from '../../composables/api/useGetAcceptedOffer';
import useModal from '../../composables/utils/useModal';
import * as enumPb from '../../grpc/build/protos/enum_pb';
import styles from '../../scss/DealTankInfo.module.scss';
import BaseButtonOutline from '../base/BaseButtonOutline';
import BaseCheckbox from '../base/BaseCheckbox';
import CreateBreakFee from '../utils/CreateBreakFee';
import DateTimeSelector from '../utils/DateTimeSelector';
import NumInput from '../utils/inputs/NumInput';
import TextInput from '../utils/inputs/TextInput';

type ComponentProps = {
  vehicleId: string;
  onCommit?: () => Promise<void>;
};

export default function DealTankInfo({ vehicleId, onCommit }: ComponentProps) {
  const [hasErr, setHasErr] = useState(false);

  const {
    fetching,
    askingPrice,
    setAskingPrice,
    isTradeIn,
    setIsTradeIn,
    addToDealTank,
    adding,
    added,
    reactivate,
    active,
    update,
    updating,
    deactivate,
    removing,
    rejectListing,
    rejecting,
    timeNano,
    setTimeNano,
    accepted,
  } = useDealTankVehicle(vehicleId);
  const { createBreakFee } = useCreateBreakFee();
  const { offer: currentAcceptedOffer, loading: acceptedOfferLoading } = useGetAcceptedOffer({
    vehicleId,
    wayOfSale: enumPb.WayOfSale.WAY_OF_SALE_DEAL_TANK,
  });

  const isSomethingPending = removing || fetching || adding || updating || rejecting;
  const isValid = askingPrice > 0;

  const expirationTimeString = new Date(timeNano / 10 ** 6).toLocaleString(undefined, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  async function handleRejectListing() {
    await rejectListing();

    if (currentAcceptedOffer) {
      openCreateBreakFee();
    }
  }

  async function handleSubmit(fn: () => Promise<void>) {
    try {
      setHasErr(false);
      await fn();
      onCommit && (await onCommit());
    } catch (err) {
      setHasErr(true);
      console.error(err);
    }
  }

  const { openModal, closeModal } = useModal();

  async function handleCreateBreakFee() {
    try {
      await createBreakFee({
        offerId: currentAcceptedOffer?.id || '',
        wayOfSale: enumPb.WayOfSale.WAY_OF_SALE_DEAL_TANK,
      });

      alert('Break Fee Created');
      closeModal();
    } catch (error) {
      alert('Create break fee failed');
    }
  }

  const openCreateBreakFee = () => {
    openModal(<CreateBreakFee onCreateBreakFee={handleCreateBreakFee} closeModel={closeModal} />, {
      contentLabel: `Break Fee`,
      shouldCloseOnOverlayClick: false,
    });
  };

  if (acceptedOfferLoading) return <p>Loading...</p>;
  return (
    <div className={styles.wrapper}>
      <h3>Edit Deal Tank Vehicle</h3>

      <TextInput name="vehicleId" label="Vehicle Id" value={vehicleId} disabled />

      <section className={styles.priceSection}>
        <NumInput
          defaultMin={0}
          value={`${askingPrice}`}
          label="Asking Price"
          invalid={askingPrice === 0}
          handleChange={(e) => handlePriceChange(e.target.valueAsNumber)}
        />
        <p className={styles.valueWithComma}>${askingPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
      </section>

      <section>
        <label htmlFor="startTime">Expiration time</label>
        <div className={styles.dateContainer}>
          <DateTimeSelector nano={timeNano} onChange={({ nano }) => setTimeNano(nano)} />
        </div>
        <p>{expirationTimeString}</p>
      </section>

      <BaseCheckbox
        checked={isTradeIn}
        label="Trade-In"
        onChange={() => setIsTradeIn((prev) => !prev)}
        fontSize=".8rem"
      />

      {hasErr && <p style={{ color: 'red' }}>Something went wrong</p>}

      {!added && (
        <BaseButtonOutline
          display={adding ? 'Adding...' : 'Add To Deal Tank'}
          onClick={() => handleSubmit(addToDealTank)}
          disabled={!isValid}
        />
      )}

      {added && !active && !accepted && (
        <>
          <p className={styles.deactivatedNotice}>Vehicle is inactive</p>
          <BaseButtonOutline
            display={adding ? 'Reactivating...' : 'Reactivate'}
            onClick={() => handleSubmit(reactivate)}
            disabled={!isValid}
          />
        </>
      )}

      {added && active && (
        <>
          <p className={styles.addedNotice}>Vehicle is currently in deal tank</p>
          <BaseButtonOutline
            display={updating ? 'Updating...' : 'Update'}
            onClick={() => handleSubmit(update)}
            disabled={!isValid}
          />
          <BaseButtonOutline
            className={styles.dangerButton}
            display={removing ? 'Deactivating...' : 'Deactivate'}
            onClick={() => handleSubmit(deactivate)}
          />
        </>
      )}

      <div className={isSomethingPending || accepted ? `${styles.disabledOverlay}` : ''}></div>
      {accepted && (
        <div className={styles.acceptedSection}>
          <h2>Listing has been accepted</h2>
          <BaseButtonOutline
            className={styles.dangerButton}
            display={rejecting ? 'Rejecting...' : 'Reject Listing'}
            onClick={handleRejectListing}
          />
        </div>
      )}
    </div>
  );

  function handlePriceChange(val: number) {
    if (!isNaN(val)) {
      setAskingPrice(val);
    } else {
      setAskingPrice(0);
    }
  }
}
