import useUtilityDrawer from '../../composables/utils/useUtilityDrawer';
import BaseButtonOutline from '../base/BaseButtonOutline';
import UpdateCredentialsForCarfax from '../utils/UpdateCredentialsForCarfax';

function UpdateCarfaxCredentialsButton({
  dealershipId,
  accountId,
  token,
  onUpdate,
}: {
  dealershipId: string;
  accountId?: string;
  token?: string;
  onUpdate: (accountId: string, token: string) => void;
}) {
  const { openUtilityDrawer } = useUtilityDrawer();
  const updateCarfax = async () => {
    openUtilityDrawer(
      <UpdateCredentialsForCarfax
        dealershipId={dealershipId}
        accountId={accountId || ''}
        token={token || ''}
        onUpdate={onUpdate}
      />,
    );
  };

  return <BaseButtonOutline small display="Update Carfax Credentials" onClick={updateCarfax} />;
}

export default UpdateCarfaxCredentialsButton;
