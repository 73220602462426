import { useState } from 'react';

import { Currency } from '../../grpc/build/protos/enum_pb';

const useCommonCurrency = () => {
  const defaultCurrency = Currency.CURRENCY_CURRENCY_CAD;
  const [currency, setCurrency] = useState(defaultCurrency);

  const currencyOptions = [
    { display: 'CAD', value: Currency.CURRENCY_CURRENCY_CAD },
    { display: 'USD', value: Currency.CURRENCY_CURRENCY_USD },
  ];

  function resetCurrency() {
    setCurrency(defaultCurrency);
  }
  return {
    currencyOptions,
    currency,
    setCurrency,
    resetCurrency,
  };
};

export default useCommonCurrency;
