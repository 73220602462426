import moment from 'moment';

import {
  AUCTION_VEHICLE_STATUS_MAP,
  AUCTION_VEHICLE_STATUS_MAP_TO_STRING,
  CONSUMER_VEHICLE_STATUS_MAP_TO_STRING,
  DEALTANK_VEHICLE_LISTING_STATUS_MAP,
  DEALTANK_VEHICLE_LISTING_STATUS_MAP_FROM_STRING,
  VEHICLE_STATUS_MAP,
} from '../constants/vehicles';
import * as adminApiv1Pb from '../grpc/build/protos/adminapiv1_pb';
import * as enumPb from '../grpc/build/protos/enum_pb';
import { MileageUOM } from '../grpc/build/protos/vehicle_pb';

export const joinClasses = (...classes: string[]) => classes.join(' ');

export function getReadableDate(time: number | string, format = 'MMM DD, YYYY'): string {
  if (!time) return '';
  const numericTime = typeof time === 'string' ? parseInt(time) : time;
  if (!numericTime) return '';
  const timeDenominator = 10 ** 9;
  return moment.unix(numericTime / timeDenominator).format(format);
}

export function convertAuctionStatus(status: adminApiv1Pb.AuctionStatus): string {
  return AUCTION_VEHICLE_STATUS_MAP[status] || 'Unknown status';
}

export function convertConsumerAuctionStatus(status: string): adminApiv1Pb.AuctionStatus {
  return AUCTION_VEHICLE_STATUS_MAP_TO_STRING[status] || adminApiv1Pb.AuctionStatus.AUCTION_STATUS_INVALID;
}

export function convertVehicleStatus(status: adminApiv1Pb.VehicleStatus): string {
  return VEHICLE_STATUS_MAP[status] || 'Unknown status';
}

export function convertConsumerVehicleStatus(status: string): adminApiv1Pb.VehicleStatus {
  return CONSUMER_VEHICLE_STATUS_MAP_TO_STRING[status] || adminApiv1Pb.VehicleStatus.VEHICLE_STATUS_INVALID;
}

export function converListingStatus(status: enumPb.ListingStatus): string {
  return DEALTANK_VEHICLE_LISTING_STATUS_MAP[status];
}

export function converListingStatusFromString(status: string): enumPb.ListingStatus {
  return DEALTANK_VEHICLE_LISTING_STATUS_MAP_FROM_STRING[status] || enumPb.ListingStatus.INVALID;
}

export function convertMileageUom(uom: adminApiv1Pb.MileageUOM): string {
  switch (uom) {
    case adminApiv1Pb.MileageUOM.MILEAGE_UOM_MILES:
      return 'mi';

    case adminApiv1Pb.MileageUOM.MILEAGE_UOM_KILOMETER:
      return 'km';

    default:
      return 'Unknown unit';
  }
}

export function convertVehicleOrigin(origin: enumPb.VehicleOrigin): string {
  switch (origin) {
    case enumPb.VehicleOrigin.VEHICLE_ORIGIN_CONSUMER:
      return 'Mintlist';
    case enumPb.VehicleOrigin.VEHICLE_ORIGIN_DEALER:
      return 'Dealer';
    case enumPb.VehicleOrigin.VEHICLE_ORIGIN_BCAA:
      return 'BCAA';
    default:
      return 'invalid';
  }
}

export const getNumberWithComma = function (input: number | string): string {
  const commaRegex = /\B(?=(\d{3})+(?!\d))/g;
  const removeLeadingZeroRegex = new RegExp('^0+(?!$)', 'g');

  const stringInput = typeof input === 'number' ? input.toString() : input;
  const [integers, decimals] = stringInput.toString().split('.');
  const commaSeparatedIntegers = integers
    .replaceAll(removeLeadingZeroRegex, '')
    .replace(/\D/g, '')
    .replace(commaRegex, ',');

  return [commaSeparatedIntegers, decimals].filter((elm) => !!elm).join('.');
};

interface MileageInfo {
  toKmWithComma: () => string;
  toMilesWithComma: () => string;
  toKm: () => number;
  wasInMiles: boolean;
}
const EMPTY_ATTRIBUTE = '--';

export function createMileage(mileage: number, unit: MileageUOM): MileageInfo {
  const wasInMiles = unit === MileageUOM.MILEAGE_UOM_MILES;

  function toKmWithComma() {
    const mileageInKm = wasInMiles ? milesToKm(mileage) : mileage;
    const number = getNumberWithComma(mileageInKm);

    return number !== '' ? `${number} km` : EMPTY_ATTRIBUTE;
  }

  function toMilesWithComma() {
    if (wasInMiles) return getNumberWithComma(mileage);
    const mileageeInMiles = getNumberWithComma(kmToMiles(mileage));
    return mileageeInMiles ? [mileageeInMiles, 'miles'].join(' ') : EMPTY_ATTRIBUTE;
  }

  function toKm() {
    return wasInMiles ? milesToKm(mileage) : mileage;
  }

  function milesToKm(miles: number) {
    return Math.round(1.60934 * miles);
  }
  function kmToMiles(km: number) {
    return Math.round(km / 1.60934);
  }

  return {
    toKmWithComma,
    toMilesWithComma,
    toKm,
    wasInMiles,
  };
}

export function parseLocation(location: { city: string; state: string }): string {
  return [location.city, location.state].filter((elm) => !!elm).join(', ') || EMPTY_ATTRIBUTE;
}

export function nanoToIsoStringDate(timeInNanoseconds: number) {
  if (!timeInNanoseconds) return 'N/A';
  return new Date(timeInNanoseconds / 1000000).toISOString().split('T')[0];
}
