import { FiEdit3 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

type EditAuctionVehicleProps = {
  vehicleId: string;
};
function EditAuctionVehicle({ vehicleId }: EditAuctionVehicleProps) {
  return (
    <Link to={`/vehicle-details/1/${vehicleId}`}>
      <FiEdit3 /> Edit vehicle
    </Link>
  );
}

export default EditAuctionVehicle;
