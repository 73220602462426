import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useState } from 'react';

import { ConsumerSignup } from '../composables/api/types';
import { useGetConsumerSignups } from '../composables/api/useGetConsumerSignups';
import styles from '../scss/ConsumerSignups.module.scss';
import DateRangeSelector from './utils/DateRangeSelector';
import Loading from './utils/Loading';

function ConsumerSignups() {
  const getConsumerSignups = useGetConsumerSignups();

  const [consumers, setConsumers] = useState<ConsumerSignup[] | null>(null);

  const handleSearch = async (startTime: number, endTime: number) => {
    try {
      const signups = await getConsumerSignups({
        startTime: +startTime,
        endTime: +endTime,
      });

      setConsumers(signups);
    } catch (error) {
      console.error('getConsumerSignups: ', error);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Consumer Signups</h2>
      <p>Please select a date range for the sign-ups</p>

      <div>
        <DateRangeSelector onSearch={handleSearch} />

        {consumers && consumers.length > 0 && (
          <section className={styles.results}>
            {consumers.map((consumer, index) => {
              return (
                <div key={`${index}-consumer-signup`} className={styles.listItem}>
                  <p>Name: {consumer.userName || '--'}</p>
                  <p>
                    Email :{' '}
                    <a href={`mailto:${consumer.userEmail}`} target="_blank" rel="noreferrer">
                      {consumer.userEmail}
                    </a>
                  </p>
                  <p>Phone: {consumer.userPhone || '--'}</p>
                  {consumer.vehicle && (
                    <>
                      <p>
                        Vehicle:{' '}
                        <mark>{[consumer.vehicle.year, consumer.vehicle.title].filter((elm) => !!elm).join(' ')}</mark>
                      </p>
                      <p>Vehicle Id: {consumer.vehicle.id}</p>
                    </>
                  )}

                  <small>{consumer.createdDate}</small>
                </div>
              );
            })}
          </section>
        )}
        {consumers && consumers.length === 0 && (
          <section className={styles.results}>
            <p>No results.</p>
          </section>
        )}
      </div>
    </div>
  );
}

export default withAuthenticationRequired(ConsumerSignups, {
  onRedirecting: () => <Loading />,
});
