import React from 'react';

import style from '../../scss/NoMatchRoute.module.scss';

function NoMatchRoute() {
  return (
    <div className={style.noMatchRouteContainer}>
      <p>{"Oops... There's nothing here."}</p>
    </div>
  );
}

export default NoMatchRoute;
