import React, { createContext, useContext, useState } from 'react';

export type NavDrawerContent = {
  isDrawerOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsDrawerOpen: (c: boolean) => void;
};

export const NavDrawerContext = createContext<NavDrawerContent>({
  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
});

export const NavDrawerProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return <NavDrawerContext.Provider value={{ isDrawerOpen, setIsDrawerOpen }}>{children}</NavDrawerContext.Provider>;
};

export const useNavDrawer = () => useContext(NavDrawerContext);
