import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export interface UpdateCarfaxProps {
  dealershipId: string;
  accountId: string;
  token: string;
}

export const useUpdateCarfax = () => {
  const [pending, setPending] = useState(false);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const updateCarfax = async ({ dealershipId, accountId, token }: UpdateCarfaxProps) => {
    if (!isConnectionReady || pending) return;

    try {
      setPending(true);
      const request = new adminApiv1Pb.UpdateCarfaxCredentialsRequest();

      request.setDealershipId(dealershipId);
      request.setAccountId(accountId);
      request.setToken(token);

      const response = await client.updateCarfaxCredentials(request, authHeader);

      return response.toObject();
    } finally {
      setPending(false);
    }
  };

  return { updateCarfax, pending };
};
