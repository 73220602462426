import { useEffect, useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import { DeleteAuctionRequest, ListAuctionsRequest } from '../../grpc/build/protos/adminapiv1_pb';
import { useApiReady } from '../utils/useApiReady';
import { useAuthHeader } from './useAuthHeader';

export type AuctionSummary = {
  auctionId: string;
  auctionTitle: string;
  startTimeNano: number;
  numberOfVehicles: number;
};

export default function () {
  const [auctions, setAuctions] = useState<AuctionSummary[]>([]);
  const [pending, setPending] = useState(true);

  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  useEffect(() => {
    fetchAuctions();
  }, [isConnectionReady]);

  async function fetchAuctions() {
    if (!isConnectionReady) return;

    try {
      setPending(true);

      const res = await client.listAuctions(new ListAuctionsRequest(), authHeader);
      const obj = res.toObject();

      if (obj.auctions?.auctionsList) {
        const _auctions = obj.auctions.auctionsList.map(({ auctionId, auctionTitle, startTime, numberOfVehicles }) => ({
          auctionId,
          auctionTitle,
          startTimeNano: startTime,
          numberOfVehicles,
        }));

        setAuctions(_auctions);
      }
    } finally {
      setPending(false);
    }
  }

  async function deleteAuction(auctionId: string) {
    try {
      setPending(true);
      const request = new DeleteAuctionRequest();
      request.setAuctionId(auctionId);
      await client.deleteAuction(request, authHeader);
      await fetchAuctions();
    } finally {
      setPending(false);
    }
  }

  return {
    auctions,
    pending,
    fetchAuctions,
    deleteAuction,
  };
}
