import React, { createContext, useContext, useEffect, useState } from 'react';

type ViewPortContent = {
  screenWidth: number;
  screenHeight: number;
  isMobile: boolean;
  isTablet: boolean;
};

const SCREEN_WIDTH = {
  MOBILE: 480,
  TABLET: 768,
};
const IPHONE_DIMENSION = {
  WIDTH: 375,
  HEIGHT: 812,
};

const viewportContext = createContext<ViewPortContent>({
  screenWidth: IPHONE_DIMENSION.WIDTH,
  screenHeight: IPHONE_DIMENSION.HEIGHT,
  isMobile: false,
  isTablet: false,
});

export const ViewportProvider: React.FC = ({ children }) => {
  const [screenWidth, setWidth] = useState(window.innerWidth);
  const [screenHeight, setHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    if (screenWidth > SCREEN_WIDTH.TABLET) {
      setIsMobile(false);
      setIsTablet(false);
    } else if (screenWidth > SCREEN_WIDTH.MOBILE) {
      setIsMobile(false);
      setIsTablet(true);
    } else {
      setIsMobile(true);
      setIsTablet(false);
    }
  }, [screenWidth]);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ screenWidth, screenHeight, isMobile, isTablet }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => useContext(viewportContext);
