import stylesDealerVehicles from '../../scss/InspectionQuestions.module.scss';

export default function InspectionQuestionInstructions() {
  return (
    <div className={stylesDealerVehicles.instructions}>
      <h2>Instructions</h2>
      <h3>General</h3>
      <p>
        During consumer onboarding, a series of questions are presented to gather consumer provided details about the
        state of their vehicle.
      </p>
      <p>This onboarding process consists of questions, which in turn consist of available options to choose from.</p>
      <p>
        Options, in turn, may include a detail field (called `question`), and may even have follow-up questions (which
        are represented as using an array of strings which represent different question categories).
      </p>
      <h3>Questions</h3>
      <div>
        <h4>Type (question_type):</h4>
        <p>Either Single Choice or Multiple Choice with Details.</p>
        <h4>Initial Option Id (initial_option_id):</h4>
        <p>The option id of the option that should be selected by default. ???</p>
        <h4>Question (question):</h4>
        <p>The question text.</p>
        <h4>Category (category):</h4>
        <p>Currently one of the following.</p>
        <ul className={stylesDealerVehicles.categoryList}>
          <li>manuals</li>
          <li>keys</li>
          <li>financial</li>
          <li>condition</li>
          <li>damage</li>
          <li>titleStatus</li>
          <li>
            factoryWheels:
            <ul>
              <li>factoryWheels</li>
              <li>factoryWheels_condition</li>
              <li>factoryWheels_tireType</li>
              <li>factoryWheels_tireSet</li>
            </ul>
          </li>
          <li>
            aftermarketWheels:
            <ul>
              <li>aftermarketWheels</li>
              <li>aftermarketWheels_condition</li>
              <li>aftermarketWheels_tireType</li>
              <li>aftermarketWheels_tireSet</li>
            </ul>
          </li>
          <li>
            extraTires:
            <ul>
              <li>extraTires</li>
              <li>extraTires_condition</li>
              <li>extraTires_tireType</li>
              <li>extraTires_tireSet</li>
            </ul>
          </li>
        </ul>
        <h4>Is Required (is_required):</h4>
        <p>A boolean value representing whether or not the question is required.</p>
        <h4>Order (question_order):</h4>
        <p>Order in which the question will appear in the inspection.</p>
        <h4>Is Last Question (is_last_question):</h4>
        <p>A boolean value representing whether or not the question is the last question.</p>
      </div>
      <h3>Options</h3>
      <div>
        <h4>Option (option):</h4>
        <p>Option text.</p>
        <h4>Option Type (option_type):</h4>
        <p>A more succinct representation of the option text.</p>
        <h4>Order (option_order):</h4>
        <p>Order in which the option will appear in the question.</p>
        <h4>Has Detail Field (has_detail_field):</h4>
        <p>A boolean value representing whether or not the option has a detail field.</p>
        <h4>Question (question):</h4>
        <p>The contents of the detail field.</p>
        <h4>Is Required (is_required):</h4>
        <p>A boolean value representing whether or not the option is required.</p>
        <h4>Description (description):</h4>
        <p>A description of the option. Can be null.</p>
        <h4>Disabled By Option Id (disabled_by_option_id):</h4>
        <p>A string representing the option id of the option that disables this option. Can be null.</p>
        <h4>Follow Up Questions (follow_up_question_categories):</h4>
        <p>
          An array of strings representing the categories of follow up questions. These are used to determine which
          follow up questions to display when an option is selected.{' '}
          <i>Note: In order to select multiple you will need to shift or ctrl click (command click on mac)</i>
        </p>
      </div>
      <p></p>
    </div>
  );
}
