import { VehicleReferrer } from '../../grpc/build/protos/entities_pb';
import { BasicFieldProps } from './types';

export default function ReferrerField(props: BasicFieldProps<VehicleReferrer.AsObject>) {
  const { viewValue: referrer } = props;
  return (
    <tr key="referrer">
      <td>referrer</td>
      <td>
        {referrer.email} ({referrer.source})
      </td>
      <td></td>
    </tr>
  );
}
