import styles from '../../scss/RadioButtons.module.scss';

type RadioButtonOption<T> = {
  display: string;
  value: T;
};
type RadioButtonsProps<T> = {
  options: RadioButtonOption<T>[];
  // eslint-disable-next-line no-unused-vars
  onClick: (c: T) => void;
  currentSelected: T;
};

function RadioButtons<T>({ options, onClick, currentSelected }: RadioButtonsProps<T>) {
  const handlClick = (currency: T) => {
    onClick(currency);
  };

  return (
    <>
      {options.map((option) => (
        <button
          onClick={() => handlClick(option.value)}
          key={option.display}
          className={currentSelected === option.value ? styles.selectedOption : styles.deselectedOption}>
          {option.display}
        </button>
      ))}
    </>
  );
}

export default RadioButtons;
