import { useState } from 'react';

import { useAdminClient } from '../../contexts/AdminClientContext';
import * as adminApiv1Pb from '../../grpc/build/protos/adminapiv1_pb';
import { VehicleReferrer } from '../../grpc/build/protos/entities_pb';
import { ReferrerSource } from '../../grpc/build/protos/enum_pb';
import { useApiReady } from '../utils/useApiReady';
import { UpdateReferrerProps } from './types';
import { useAuthHeader } from './useAuthHeader';

export const useEditReferrer = () => {
  const [pending, setPending] = useState(false);
  const { client } = useAdminClient();
  const [authHeader] = useAuthHeader();
  const [isConnectionReady] = useApiReady();

  const updateReferrer = async ({ vehicleId, email, deleteReferrer }: UpdateReferrerProps) => {
    if (!isConnectionReady || pending) return;

    try {
      setPending(true);

      const request = new adminApiv1Pb.UpdateVehicleReferrerRequest();
      const referrer = new VehicleReferrer();
      referrer.setEmail(email);
      referrer.setVehicleId(vehicleId);
      referrer.setSource(ReferrerSource.REFERRER_SOURCE_INTERNAL);
      request.setReferrer(referrer);
      request.setDeletereferrer(deleteReferrer);

      const response = await client.updateVehicleReferrer(request, authHeader);
      return response.toObject();
    } finally {
      setPending(false);
    }
  };

  return { updateReferrer, pending };
};
